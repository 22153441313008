import { makeRequest } from 'features/entry-form-v2/data/makeRequest';

type Response = {
	uploadURL: string;
	postRequestFields: Record<string, string>;
	fileId: string;
};

export const getProjectFilePostURL = async (args: { fileName: string; projectId: string }) => {
	return makeRequest<Response>({
		method: 'getProjectFilePostURL',
		service: 'projects',
		data: {
			fileName: args.fileName,
			project: {
				projectId: args.projectId
			}
		}
	});
};
