import { FilterIcon } from 'components/UI/Icons';
import { useRef, useState } from 'react';

import styled from 'styled-components';
import { Filter } from './Filter';

interface Props {
	tableRef: React.RefObject<HTMLTableElement>;
	items: { label: string; value: string }[];
	selectedItems: string[];
	onApply: (values: string[]) => void;
	onClear: () => void;
}

export const FilterIconButton = (props: Props) => {
	const { items, onApply, onClear, selectedItems, tableRef } = props;

	const [visible, setVisible] = useState(false);

	const iconRef = useRef<HTMLImageElement>(null);

	const activeIcon = Boolean(visible || selectedItems.length > 0);

	return (
		<Container>
			<FilterIcon
				className="column-filter-icon"
				ref={iconRef}
				active={activeIcon}
				onClick={() => setVisible(value => !value)}
			/>

			{visible && (
				<Filter
					tableRef={tableRef}
					items={items}
					onApply={values => onApply(values)}
					onClear={onClear}
					selectedItems={selectedItems}
					setVisible={setVisible}
				/>
			)}
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	z-index: 9;
	margin-left: 1rem;
`;
