// exports

export const Colors = {
	login: {
		background: '#363a32',
		gray: { dark: '#808080', light: '#f2f2f2', lighter: '#d3d5d0' }
	},
	transparent: 'rgba(0, 0, 0, 0)',
	black: '#000',
	primary: {
		medium: '#F04E36',
		dark: '#EE3216',
		normal: '#1f70f1',
		hover: '#1759de',
		disabled: '#eef7ff'
	},
	gray: {
		brightest: '#eef2f2',
		lightest: '#EEF2F7',
		lighter: '#D2D4D8',
		light: '#A4A1AB',
		medium: '#748394',
		dark: '#5D7378',
		darkest: '#323E49'
	},
	silver: {
		lightest: '#F7F7F7',
		lighter: '#EAEBEC',
		light: '#D2D4D8'
	},
	red: {
		medium: '#E94747',
		dark: '#AE2926'
	},
	blue: {
		lightest: 'rgba(0, 131, 255, 0.1);',
		light: '#A3C6E9',
		medium: '#518EEE',
		dark: '#315698',
		faded: 'rgba(163, 198, 233, 0.5)',
		dodgerBlue: '#0083FF'
	},
	olive: '#F8FFE5',
	pink: '#E47EA0',
	purple: '#7C53A6',
	yellow: {
		dark: '#FFCC00',
		medium: '#F3CD46',
		faded: 'rgba(255, 206, 0, 0.5)'
	},
	green: {
		light: '#9ecd43',
		medium: '#72B443',
		faded: 'rgba(158, 205, 67, 0.5)'
	},
	teal: '#6BD0C8',
	orange: '#E98A3D',
	fades: {
		backdrop: 'rgba(50, 62, 73, 0.9)',
		two: 'rgba(0, 0, 0, 0.2)',
		three: 'rgba(0, 0, 0, 0.3)'
	},
	text: {
		main: '#1b1b1b',
		hint: '#6d6d6d',
		caption: '#888888',
		captionSecondary: '#757583',
		error: '#FF585D',
		errorHover: '#E64F54',
		disabled: '#b0b0b0',
		tableMain: '#25253a'
	},
	background: {
		disabled: '#f6f6f6',
		disabledSecondary: '#f2f6f8',
		loading: '#d6d6d6',
		error: '#ffeeee',
		errorDisable: '#FEEEEF'
	},
	vibrantGreen: '#26D07C',
	highlightGreen: '#e8faf1',
	pale: '#fef0d5',
	notificationBlue: '#4299e1',
	seriesGreen: '#007a58',
	groupOrange: '#ff7f41',

	white: '#ffffff',
	avatar: {
		pending: '#edf1f4',
		paleGreyLight: '#dae0e6',
		hospitalGreen: '#90e4af',
		mutedPink: '#d1799e',
		rosePink: '#f68496',
		lightSalmonMedium: '#ffa298',
		lightSalmon: '#ffb48b',
		darkCream: '#ffe892',
		lightBlue: ' #80d2ee',
		lightGreyBlue: '#7daaca',
		heather: '#9b92be',
		paleTeal: '#7ebfa4'
	},
	chart: {
		primary: { dark: '#63B4FF', medium: '#1F70F1', light: '#1949B4' },
		red: { dark: '#6350FC', medium: '#4F2DD5', light: '#7E78FF' },
		violet: { dark: '#FF8717', medium: '#FFC470', light: '#C74D07' },
		lightBlue: { dark: '#847A6C', medium: '#ACA698', light: '#5C524C' },
		lightGreen: { dark: '#0C8876', medium: '#115E55', light: '#2DD4B6' },
		darkGreen: { dark: '#D4C8B9', medium: '#B39A84', light: '#967360' },
		orange: { dark: '#30D282', medium: '#1A7B4B', light: '#8BEABA' },
		yellow: { dark: '#3B2CAA', medium: '#5340EB', light: '#7C80FD' },
		pink: { dark: '#E8D25E', medium: '#BDAB4C', light: '#8C7F39' },
		darkBlue: { dark: '#E32FDB', medium: '#F37AEE', light: '#7E1A77' }
	},
	lqleditor: {
		parenthesisAndOperatorColors: ['#25253a', '#ff7f41', '#00b5e2', '#e40046'],
		keywordColor: '#007a58'
	},
	numericEditor: {
		initial: '#b0bfc7',
		focused: '#1f70f1',
		error: '#ff585d',
		validated: '#007a58'
	},
	lightRed: '#ffefef',
	brownOrange: '#b54708',
	paleOrange: '#fffcf5',
	lightOrange: '#fec84b',
	infoBackgroundColor: '#f2e9ef',
	lightPurple: '#515161'
};
