import { useMemo, useCallback, useState } from 'react';
import {
	Dependency,
	useGetVariableDependenciesQuery
} from '../useGetVariableDependenciesQuery/useGetVariableDependenciesQuery';
import {
	DependencyRule,
	VariableGroup,
	Variable,
	VariableType,
	DateTimeVariable,
	DateVariable,
	FileVariable,
	FloatVariable,
	IntegerVariable,
	StringVariable,
	TimeDurationVariable,
	UserDefinedUniqueVariable,
	CategoryMultipleFixedVariable,
	CategoryFixedVariable,
	isCategoryFixedVariable,
	isCategoryMultipleFixedVariable,
	CategoryMultipleNonFixedVariable,
	CategoryNonFixedVariable,
	isCategoryMultipleVariable,
	CategoryMultipleVariable,
	CategoryVariable,
	isCategoryVariable,
	VariableSeries
} from '../../types';
import { toDependencyRules } from '../../utils/dependencyRules';
import {
	FormDefinition,
	FormElement,
	FrontendForm,
	GetFormsFrontendData,
	isGroupFormElement,
	isVariableFormElement,
	NonGroupFormElement,
	useGetFormsQuery
} from '../useGetFormsQuery/useGetFormsQuery';
import { isNotNullOrUndefined } from 'features/entry-form-v2/utils/isNotNullOrUndefined';
import { useVariablesQuery, VariablesData } from '../useVariablesQuery/useVariablesQuery';
import { isGroupOrderItem, isVariableOrderItem } from 'helpers/variables';

export const isVariableFormItem = (item: FormItem): item is VariableFormItem =>
	item.type === 'variable';

export type GroupFormItem = FormItemBase & {
	type: 'group';
	group: VariableGroup;
	formItems: NonGroupFormItem[];
};
export const isGroupFormItem = (item: FormItem): item is GroupFormItem => item.type === 'group';

export type SeriesFormItem = {
	type: 'series';
	name: string;
	label: string;
	mainLevelVariables: Variable[];
	hideFromUi?: boolean;
};
export const isSeriesFormItem = (item: FormItem): item is SeriesFormItem => item.type === 'series';

type ColumnSpan = 1 | 2;
type ColumnStart = 1 | 2;
type FormItemBase = {
	hideFromUi?: boolean;

	columnSpan: ColumnSpan;
	columnStart: ColumnStart;
};

export type SubtitleFormItem = FormItemBase & {
	type: 'subtitle';
	text: string;
};

export type TextFormItem = FormItemBase & {
	type: 'text';
	text: string;
};

export type SeparatorFormItem = FormItemBase & {
	type: 'separator';
};

export type NonGroupFormItem =
	| VariableFormItem
	| SubtitleFormItem
	| TextFormItem
	| SeparatorFormItem
	| SeriesFormItem;

export type FormItem = NonGroupFormItem | GroupFormItem;

export type ProjectData = {
	/** Form items represents the tree structure which we should render for this project and the order of the items */
	formItems: FormItem[];
	/** Variables are all the variables for this project */
	variables: Variable[];

	formTitle?: string;
};

export const toProjectData = ({
	variablesData,
	dependenciesData,
	activeForm
}: {
	variablesData: VariablesData;
	dependenciesData: {
		active: boolean;
		dependencies: Dependency[];
	};
	activeForm?: GetFormsFrontendData['forms'][0];
}): ProjectData => {
	const dependencyRules: DependencyRule[] = toDependencyRules(
		variablesData.variables,
		dependenciesData.active ? dependenciesData.dependencies : []
	);

	if (!activeForm) {
		return processWithoutCustomForm({ variablesData, dependencyRules });
	}

	const processedForm = processCustomForm({
		formDefinition: activeForm.formDefinition,
		variablesData,
		dependencyRules
	});

	const variablesWithoutFormItems = { ...variablesData.variables };

	// Delete already processed variables
	for (const variable of processedForm.variables) {
		delete variablesWithoutFormItems[variable.variableName];
	}

	// Delete variables that are in a series
	for (const set of Object.values(variablesData.sets)) {
		for (const orderItem of set.setOrder) {
			if (orderItem.variable) {
				delete variablesWithoutFormItems[orderItem.variable];
			}

			if (orderItem.group) {
				const group = variablesData.groups[orderItem.group];
				if (group) {
					for (const variableName of group.variablesBelongingToGroup) {
						delete variablesWithoutFormItems[variableName];
					}
				}
			}
		}
	}

	const remainingFormItems = Object.values(variablesWithoutFormItems)
		.map(variable =>
			backendVariableToVariableFormItem({
				dependencies: dependencyRules.filter(
					rule => rule.dependantVariable.variableName === variable.variableName
				),
				variable
			})
		)
		.map(formItem => ({
			...formItem,
			hideFromUi: true
		}));

	return {
		formItems: [...processedForm.formItems, ...remainingFormItems],
		variables: Object.values(variablesData.variables),
		formTitle: processedForm.formTitle
	};
};

const processWithoutCustomForm = ({
	dependencyRules,
	variablesData
}: {
	variablesData: VariablesData;
	dependencyRules: DependencyRule[];
}) => {
	const formItems: FormItem[] = [];

	for (const item of variablesData.order) {
		if (item.variable && variablesData.variables[item.variable]) {
			formItems.push(
				backendVariableToVariableFormItem({
					variable: variablesData.variables[item.variable],
					dependencies: dependencyRules.filter(
						dependency => dependency.dependantVariable.variableName === item.variable
					)
				})
			);
			continue;
		}

		if (item.group && variablesData.groups[item.group]) {
			formItems.push(
				backendGroupToGroupFormItem({
					variablesData,
					groupName: item.group,
					dependencyRules
				})
			);
			continue;
		}

		if (item.set) {
			formItems.push(
				backendSetToSeriesFormItem({
					name: item.set,
					variablesData
				})
			);
			continue;
		}

		console.error(new Error('Unknown item type'), { item });
	}

	return { formItems, variables: Object.values(variablesData.variables) };
};

export const processCustomForm = ({
	dependencyRules,
	formDefinition,
	variablesData
}: {
	dependencyRules: DependencyRule[];
	formDefinition: FormDefinition;
	variablesData: VariablesData;
}): ProjectData => {
	const formItems: FormItem[] = [];

	// 1-2 elements per row
	for (const row of formDefinition.formElements) {
		formItems.push(
			...row.map((formElement, index) =>
				backendFormElementToFormItem({
					formElement,
					variablesData,
					dependencyRules,
					overrideColumnSpan: row.length === 2 ? 1 : undefined,
					overrideColumnStart: index === 1 ? 2 : 1
				})
			)
		);
	}

	const variables = formItems.flatMap(topLevelItem => {
		if (topLevelItem.type === 'group') {
			return topLevelItem.formItems
				.map(formItem => {
					if (formItem.type === 'variable') {
						return formItem.variable;
					}
					return null;
				})
				.filter(isNotNullOrUndefined);
		}

		if (topLevelItem.type === 'variable') {
			return [topLevelItem.variable];
		}

		return [];
	});

	return {
		formItems: formItems,
		variables,
		formTitle: formDefinition.formTitle
	};
};

const backendFormElementToFormItem = ({
	formElement,
	variablesData,
	dependencyRules,
	overrideColumnSpan,
	overrideColumnStart
}: {
	formElement: FormElement;
	variablesData: VariablesData;
	dependencyRules: DependencyRule[];
	overrideColumnSpan?: ColumnSpan;
	overrideColumnStart?: ColumnStart;
}): FormItem => {
	if (isGroupFormElement(formElement)) {
		return {
			type: 'group',
			columnSpan: 2,
			columnStart: 1,
			group: {
				groupLabel: formElement.groupLabel,
				groupName: formElement.groupName,
				variablesBelongingToGroup: formElement.formElements
					.flat()
					.filter(isVariableFormElement)
					.map(element => element.variableRef)
			},
			formItems: formElement.formElements
				.map(row =>
					row.map((element, index) => {
						const result = backendNonGroupFormElementToFormItem({
							formElement: element,
							variablesData,
							dependencyRules,
							overrideColumnSpan: row.length === 2 ? 1 : undefined,
							overrideColumnStart: index === 1 ? 2 : 1
						});

						return result;
					})
				)
				.flat()
		};
	}

	return backendNonGroupFormElementToFormItem({
		formElement,
		variablesData,
		dependencyRules,
		overrideColumnSpan,
		overrideColumnStart
	});
};

const backendNonGroupFormElementToFormItem = ({
	formElement,
	variablesData,
	dependencyRules,
	overrideColumnSpan,
	overrideColumnStart
}: {
	formElement: NonGroupFormElement;
	variablesData: VariablesData;
	dependencyRules: DependencyRule[];
	overrideColumnSpan?: ColumnSpan;
	overrideColumnStart?: ColumnStart;
}): NonGroupFormItem => {
	const variables = variablesData.variables;

	switch (formElement.elementType) {
		case 'subtitle':
			return {
				type: 'subtitle',
				text: formElement.text,
				columnSpan: 2,
				columnStart: 1
			};

		case 'input': {
			const variable = variables[formElement.variableRef];

			if (!variable) {
				console.error(new Error('Variable not found'), {
					variableName: formElement.variableRef
				});
				return unexpectedFormStateVariableText(formElement.variableRef);
			}

			const dependencies = dependencyRules.filter(
				dependency => dependency.dependantVariable.variableName === formElement.variableRef
			);

			return backendVariableToVariableFormItem({
				variable,
				dependencies,
				overrideLabel: formElement.text,
				overrideColumnSpan,
				overrideColumnStart
			});
		}

		case 'radiobuttons': {
			const variable = variables[formElement.variableRef];
			if (!variable) {
				console.error(new Error('Variable not found'), {
					variableName: formElement.variableRef
				});
				return unexpectedFormStateVariableText(formElement.variableRef);
			}

			const dependencies = dependencyRules.filter(
				dependency => dependency.dependantVariable.variableName === formElement.variableRef
			);

			if (!isCategoryVariable(variable)) {
				console.warn('Expected category variable', {
					variable,
					formElement
				});

				return backendVariableToVariableFormItem({
					variable,
					dependencies,
					overrideLabel: formElement.text,
					overrideColumnSpan,
					overrideColumnStart
				});
			}

			return categoryVariableToCategoryFormItem({
				variable,
				dependencies,
				categoryValuesAsLabels: formElement.displayType === 'value',
				overrideLabel: formElement.text,
				overrideColumnSpan,
				overrideColumnStart,
				overrideDefaultLayoutType: formElement.orientation
			});
		}

		case 'checkboxes': {
			const variable = variables[formElement.variableRef];
			if (!variable) {
				console.error(new Error('Variable not found'), {
					variableName: formElement.variableRef
				});
				return unexpectedFormStateVariableText(formElement.variableRef);
			}

			const dependencies = dependencyRules.filter(
				dependency => dependency.dependantVariable.variableName === formElement.variableRef
			);

			if (!isCategoryMultipleVariable(variable)) {
				console.warn('Expected category multiple variable', {
					variable,
					formElement
				});

				return backendVariableToVariableFormItem({
					variable,
					dependencies,
					overrideLabel: formElement.text,
					overrideColumnSpan,
					overrideColumnStart
				});
			}

			return categoryMultipleVariableToCategoryMultipleFormItem({
				variable,
				dependencies,
				overrideLabel: formElement.text,
				categoryValuesAsLabels: formElement.displayType === 'value',
				overrideColumnSpan,
				overrideColumnStart,
				overrideDefaultLayoutType: formElement.orientation
			});
		}

		case 'dropdown': {
			const variable = variables[formElement.variableRef];
			if (!variable) {
				console.error(new Error('Variable not found'), {
					variableName: formElement.variableRef
				});
				return unexpectedFormStateVariableText(formElement.variableRef);
			}

			const dependencies = dependencyRules.filter(
				dependency => dependency.dependantVariable.variableName === formElement.variableRef
			);

			if (isCategoryVariable(variable)) {
				const formItem = categoryVariableToCategoryFormItem({
					variable,
					dependencies,
					overrideLabel: formElement.text,
					categoryValuesAsLabels: formElement.displayType === 'value',
					overrideColumnSpan,
					overrideColumnStart
				});

				return {
					...formItem,
					layoutType: 'dropdown'
				};
			}

			if (isCategoryMultipleVariable(variable)) {
				const formItem = categoryMultipleVariableToCategoryMultipleFormItem({
					variable,
					dependencies,
					overrideLabel: formElement.text,
					categoryValuesAsLabels: formElement.displayType === 'value',
					overrideColumnSpan,
					overrideColumnStart
				});

				return {
					...formItem,
					layoutType: 'dropdown'
				};
			}

			// We know this can happen e.g. if the variable has been changed from a category to a number, and the form item has not been updated
			console.warn(new Error('Expected category or category multiple variable'), {
				variable,
				formElement
			});

			return unexpectedFormStateVariableText(formElement.variableRef);
		}

		case 'file': {
			const variable = variables[formElement.variableRef];
			if (!variable) {
				console.error(new Error('Variable not found'), {
					variableName: formElement.variableRef
				});
				return unexpectedFormStateVariableText(formElement.variableRef);
			}

			const dependencies = dependencyRules.filter(
				dependency => dependency.dependantVariable.variableName === formElement.variableRef
			);

			if (variable.variableType !== 'file') {
				console.warn('Expected file variable', {
					variable,
					formElement
				});

				return backendVariableToVariableFormItem({
					variable,
					dependencies,
					overrideLabel: formElement.text,
					overrideColumnSpan,
					overrideColumnStart
				});
			}

			return fileVariableToFileFormItem({
				variable,
				dependencies,
				overrideLabel: formElement.text,
				overrideColumnSpan,
				overrideColumnStart
			});
		}

		case 'text':
			return { type: 'text', text: formElement.text, columnSpan: 2, columnStart: 1 };

		case 'separator':
			return { type: 'separator', columnSpan: 2, columnStart: 1 };

		case 'series':
			return backendSetToSeriesFormItem({
				name: formElement.setName,
				variablesData
			});
	}
};

export const useProjectData = ({
	projectId,
	selectedFormId,
	seriesName,
	allowPotentiallyStaleData
}: {
	projectId: string;
	selectedFormId?: string | null;
	seriesName?: string;

	/**
	 * In order to prevent caching issues, we usually want to ensure that all form, variables etc are loaded from the backend before rendering,
	 * but for e.g. the form preview this led to a lot of unecessary loading. Don't use this without good reason.
	 */
	allowPotentiallyStaleData?: boolean;
}) => {
	const getFormsQuery = useGetFormsQuery({
		projectId,
		seriesName
	});

	const variablesQuery = useVariablesQuery({
		projectId
	});

	const getVariablesDependenciesQuery = useGetVariableDependenciesQuery({
		projectId
	});

	const [processingError, setProcessingError] = useState<Error | null>(null);

	const allQueriesFetchedAfterMount =
		allowPotentiallyStaleData ||
		(variablesQuery.isFetchedAfterMount &&
			getVariablesDependenciesQuery.isFetchedAfterMount &&
			getFormsQuery.isFetchedAfterMount);

	const processedData = useMemo(() => {
		if (
			allQueriesFetchedAfterMount &&
			variablesQuery.data &&
			getVariablesDependenciesQuery.data
		) {
			const selectedForm = getFormsQuery.data?.activeForms.find(
				form => form.formId.toString() === selectedFormId
			);

			if (!seriesName) {
				return toProjectData({
					variablesData: {
						variables: extractTopLevelVariables(variablesQuery.data),
						order: variablesQuery.data.order,
						groups: variablesQuery.data.groups,
						sets: variablesQuery.data.sets
					},
					dependenciesData: {
						active: getVariablesDependenciesQuery.data.active,
						dependencies: getVariablesDependenciesQuery.data.dependencies
					},
					activeForm: selectedForm
						? ridFormOfMissingVariables(selectedForm, variablesQuery.data.variables)
						: undefined
				});
			}

			const series = variablesQuery.data.sets[seriesName];
			if (!series) {
				console.error(new Error('Series not found'), { seriesName });
				setProcessingError(new Error('Series not found'));

				return;
			}

			// It seems it will always be an entry in this map, even if we never created any dependencies for this series, so we can assume this wont be undefined
			const dependenciesForSeries =
				getVariablesDependenciesQuery.data.dependenciesBySetName[seriesName];

			return toProjectData({
				variablesData: extractVariablesDataForSeries({
					variablesData: variablesQuery.data,
					series
				}),
				dependenciesData: {
					active: dependenciesForSeries?.active ?? false,
					dependencies: dependenciesForSeries?.dependencies ?? []
				},
				activeForm: selectedForm
					? ridFormOfMissingVariables(selectedForm, variablesQuery.data.variables)
					: undefined
			});
		}
	}, [variablesQuery, getVariablesDependenciesQuery, selectedFormId, getFormsQuery]);

	const refetch = useCallback(async () => {
		await Promise.all([
			variablesQuery.refetch(),
			getVariablesDependenciesQuery.refetch(),
			getFormsQuery.refetch()
		]);
	}, [variablesQuery, getVariablesDependenciesQuery, getFormsQuery]);

	return {
		data: processedData,
		isLoading:
			variablesQuery.isLoading ||
			getVariablesDependenciesQuery.isLoading ||
			getFormsQuery.isLoading ||
			!allQueriesFetchedAfterMount,
		isFetching:
			variablesQuery.isFetching ||
			getVariablesDependenciesQuery.isFetching ||
			getFormsQuery.isFetching,
		error:
			variablesQuery.error ||
			getVariablesDependenciesQuery.error ||
			getFormsQuery.error ||
			processingError,
		refetch,
		isSuccess:
			variablesQuery.isSuccess &&
			getVariablesDependenciesQuery.isSuccess &&
			getFormsQuery.isSuccess
	};
};

const ridFormOfMissingVariables = (form: FrontendForm, variables: VariablesData['variables']) => {
	return {
		...form,
		formDefinition: {
			...form.formDefinition,
			formElements: removeFormElementsWithoutVariables(
				form.formDefinition.formElements,
				variables
			)
		}
	};
};

/**
 * e.g. when there are restricted variables, the backend will still return form elements for them.
 * for now the rule is to assume if a variable is not in the variables map, the related form element should be removed
 */
const removeFormElementsWithoutVariables = (
	rows: FormElement[][],
	variables: VariablesData['variables']
): FormElement[][] => {
	return rows.map(row =>
		row.filter(element => {
			// Handle group elements recursively
			if (isGroupFormElement(element)) {
				element.formElements = removeFormElementsWithoutVariables(
					element.formElements,
					variables
				) as NonGroupFormElement[][];

				// Keep group if it has any elements left after filtering
				return element.formElements.some(row => row.length > 0);
			}

			// Keep non-variable elements (text, subtitle, separator, series)
			if (!isVariableFormElement(element)) {
				return true;
			}

			// Keep variable elements only if their variableRef exists in variables
			return element.variableRef in variables;
		})
	);
};

const extractTopLevelVariables = (variablesData: VariablesData) => {
	const topLevelVariables: Record<string, Variable> = {};

	const groupNamesInSets = Object.values(variablesData.sets).flatMap(set =>
		set.setOrder.filter(isGroupOrderItem).map(item => item.group)
	);

	for (const group of Object.values(variablesData.groups)) {
		if (groupNamesInSets.includes(group.groupName)) {
			continue;
		}

		for (const variableName of group.variablesBelongingToGroup) {
			topLevelVariables[variableName] = variablesData.variables[variableName];
		}
	}

	for (const orderItem of variablesData.order) {
		if (isVariableOrderItem(orderItem)) {
			topLevelVariables[orderItem.variable] = variablesData.variables[orderItem.variable];
		}
	}

	return topLevelVariables;
};

const extractVariablesDataForSeries = ({
	variablesData,
	series
}: {
	variablesData: VariablesData;
	series: VariableSeries;
}) => {
	const seriesOrder = series.setOrder;

	const groupsForSeries: Record<string, VariableGroup> = {};
	const variablesForSeries: Record<string, Variable> = {};

	for (const seriesOrderItem of seriesOrder) {
		if (seriesOrderItem.group) {
			const group = variablesData.groups[seriesOrderItem.group];
			if (!group) {
				console.error(new Error('Group defined in seriesOrder not found in groups'), {
					group: seriesOrderItem.group
				});
				continue;
			}

			groupsForSeries[group.groupName] = group;

			for (const variableName of group.variablesBelongingToGroup) {
				variablesForSeries[variableName] = variablesData.variables[variableName];
			}
		}

		if (seriesOrderItem.variable) {
			const variable = variablesData.variables[seriesOrderItem.variable];
			if (!variable) {
				console.error(new Error('Variable defined in seriesOrder not found in variables'), {
					variable: seriesOrderItem.variable
				});
				continue;
			}

			variablesForSeries[variable.variableName] = variable;
		}
	}

	const variablesDataForSeries: VariablesData = {
		groups: groupsForSeries,
		variables: variablesForSeries,
		order: seriesOrder,
		sets: {}
	};

	return variablesDataForSeries;
};

type VariableFormItemBase = FormItemBase & {
	type: 'variable';
	variableType: VariableType;
	variable: Variable;
	dependencies: DependencyRule[];
};

export type StringFormItem = VariableFormItemBase & {
	variableType: 'string';
	variable: StringVariable;
	dependencies: DependencyRule[];
};
export const isStringFormItem = (item: FormItem): item is StringFormItem =>
	isVariableFormItem(item) && item.variableType === 'string';

export type IntegerFormItem = VariableFormItemBase & {
	variableType: 'integer';
	variable: IntegerVariable;
	dependencies: DependencyRule[];
};
export const isIntegerFormItem = (item: FormItem): item is IntegerFormItem =>
	isVariableFormItem(item) && item.variableType === 'integer';

export type FloatFormItem = VariableFormItemBase & {
	variableType: 'float';
	variable: FloatVariable;
	dependencies: DependencyRule[];
};
export const isFloatFormItem = (item: FormItem): item is FloatFormItem =>
	isVariableFormItem(item) && item.variableType === 'float';

export type NumericFormItem = IntegerFormItem | FloatFormItem;

export type DateFormItem = VariableFormItemBase & {
	variableType: 'date';
	variable: DateVariable;
	dependencies: DependencyRule[];
};
export const isDateFormItem = (item: FormItem): item is DateFormItem =>
	isVariableFormItem(item) && item.variableType === 'date';

export type DateTimeFormItem = VariableFormItemBase & {
	variableType: 'datetime';
	variable: DateTimeVariable;
	dependencies: DependencyRule[];
};
export const isDateTimeFormItem = (item: FormItem): item is DateTimeFormItem =>
	isVariableFormItem(item) && item.variableType === 'datetime';

export type FileFormItem = VariableFormItemBase & {
	variableType: 'file';
	variable: FileVariable;
	dependencies: DependencyRule[];
};
export const isFileFormItem = (item: FormItem): item is FileFormItem =>
	isVariableFormItem(item) && item.variableType === 'file';

export type UserDefinedUniqueFormItem = VariableFormItemBase & {
	variableType: 'userDefinedUnique';
	variable: UserDefinedUniqueVariable;
	dependencies: DependencyRule[];
};
export const isUserDefinedUniqueFormItem = (item: FormItem): item is UserDefinedUniqueFormItem =>
	isVariableFormItem(item) && item.variableType === 'userDefinedUnique';

export type TimeDurationFormItem = VariableFormItemBase & {
	variableType: 'timeDuration';
	variable: TimeDurationVariable;
	dependencies: DependencyRule[];
};
export const isTimeDurationFormItem = (item: FormItem): item is TimeDurationFormItem =>
	isVariableFormItem(item) && item.variableType === 'timeDuration';

export type CategoryNonFixedFormItem = VariableFormItemBase &
	CategoryBase & {
		variableType: 'category';
		fixedCategories: 'no';
		variable: CategoryNonFixedVariable;
	};
export const isCategoryNonFixedFormItem = (item: FormItem): item is CategoryNonFixedFormItem =>
	isVariableFormItem(item) && item.variableType === 'category' && item.fixedCategories === 'no';

export type CategoryFixedFormItem = VariableFormItemBase &
	CategoryBase & {
		variableType: 'category';
		fixedCategories: 'yes';
		variable: CategoryFixedVariable;
	};
export type CategoryFormItem = CategoryFixedFormItem | CategoryNonFixedFormItem;

export const isCategoryFixedFormItem = (item: FormItem): item is CategoryFixedFormItem =>
	isVariableFormItem(item) && item.variableType === 'category' && item.fixedCategories === 'yes';

type CategoryLayoutType = 'horizontal' | 'vertical' | 'dropdown';

type CategoryBase = {
	dependencies: DependencyRule[];
	layoutType: CategoryLayoutType;
};

export type CategoryMultipleNonFixedFormItem = VariableFormItemBase &
	CategoryBase & {
		variableType: 'categoryMultiple';
		fixedCategories: 'no';
		variable: CategoryMultipleNonFixedVariable;
	};
export const isCategoryMultipleNonFixedFormItem = (
	item: FormItem
): item is CategoryMultipleNonFixedFormItem =>
	isVariableFormItem(item) &&
	item.variableType === 'categoryMultiple' &&
	item.fixedCategories === 'no';

export type CategoryMultipleFormItem =
	| CategoryMultipleNonFixedFormItem
	| CategoryMultipleFixedFormItem;
export const isCategoryMultipleFormItem = (item: FormItem): item is CategoryMultipleFormItem =>
	isVariableFormItem(item) && item.variableType === 'categoryMultiple';

export type CategoryMultipleFixedFormItem = VariableFormItemBase &
	CategoryBase & {
		variableType: 'categoryMultiple';
		fixedCategories: 'yes';
		variable: CategoryMultipleFixedVariable;
	};
export const isCategoryMultipleFixedFormItem = (
	item: FormItem
): item is CategoryMultipleFixedFormItem =>
	isVariableFormItem(item) &&
	item.variableType === 'categoryMultiple' &&
	item.fixedCategories === 'yes';

export type VariableFormItem =
	| IntegerFormItem
	| FloatFormItem
	| DateFormItem
	| DateTimeFormItem
	| FileFormItem
	| UserDefinedUniqueFormItem
	| TimeDurationFormItem
	| CategoryNonFixedFormItem
	| CategoryFixedFormItem
	| CategoryMultipleNonFixedFormItem
	| CategoryMultipleFixedFormItem
	| StringFormItem;

export const backendVariableToVariableFormItem = ({
	dependencies,
	variable,
	overrideLabel,
	overrideColumnSpan,
	overrideColumnStart
}: {
	variable: Variable;
	dependencies: DependencyRule[];
	overrideLabel?: string;
	overrideColumnSpan?: ColumnSpan;
	overrideColumnStart?: ColumnStart;
}): VariableFormItem => {
	switch (variable.variableType) {
		case 'integer':
			return {
				type: 'variable',
				variableType: 'integer',
				variable: {
					...variable,
					variableLabel: overrideLabel || variable.variableLabel
				},
				dependencies,
				columnSpan: overrideColumnSpan || DEFAULT_NON_CATEGORY_COLUMN_SPAN,
				columnStart: overrideColumnStart || 1
			};

		case 'float':
			return {
				type: 'variable',
				variableType: 'float',
				variable: {
					...variable,
					variableLabel: overrideLabel || variable.variableLabel
				},
				dependencies,
				columnSpan: overrideColumnSpan || DEFAULT_NON_CATEGORY_COLUMN_SPAN,
				columnStart: overrideColumnStart || 1
			};

		case 'date':
			return {
				type: 'variable',
				variableType: 'date',
				variable: {
					...variable,
					variableLabel: overrideLabel || variable.variableLabel
				},
				dependencies,
				columnSpan: overrideColumnSpan || DEFAULT_NON_CATEGORY_COLUMN_SPAN,
				columnStart: overrideColumnStart || 1
			};

		case 'datetime':
			return {
				type: 'variable',
				variableType: 'datetime',
				variable: {
					...variable,
					variableLabel: overrideLabel || variable.variableLabel
				},
				dependencies,
				columnSpan: overrideColumnSpan || DEFAULT_NON_CATEGORY_COLUMN_SPAN,
				columnStart: overrideColumnStart || 1
			};

		case 'file':
			return fileVariableToFileFormItem({
				variable,
				dependencies,
				overrideLabel,
				overrideColumnSpan,
				overrideColumnStart
			});

		case 'userDefinedUnique':
			return {
				type: 'variable',
				variableType: 'userDefinedUnique',
				variable: {
					...variable,
					variableLabel: overrideLabel || variable.variableLabel
				},
				dependencies,
				columnSpan: overrideColumnSpan || DEFAULT_NON_CATEGORY_COLUMN_SPAN,
				columnStart: overrideColumnStart || 1
			};

		case 'timeDuration':
			return {
				type: 'variable',
				variableType: 'timeDuration',
				variable: {
					...variable,
					variableLabel: overrideLabel || variable.variableLabel
				},
				dependencies,
				columnSpan: overrideColumnSpan || DEFAULT_NON_CATEGORY_COLUMN_SPAN,
				columnStart: overrideColumnStart || 1
			};

		case 'category':
			return categoryVariableToCategoryFormItem({
				variable,
				dependencies,
				overrideLabel,
				overrideColumnSpan,
				overrideColumnStart
			});

		case 'categoryMultiple':
			return categoryMultipleVariableToCategoryMultipleFormItem({
				variable,
				dependencies,
				overrideLabel,
				overrideColumnSpan,
				overrideColumnStart
			});

		case 'string':
			return {
				type: 'variable',
				variableType: 'string',
				variable: {
					...variable,
					variableLabel: overrideLabel || variable.variableLabel
				},
				dependencies,
				columnSpan: overrideColumnSpan || DEFAULT_NON_CATEGORY_COLUMN_SPAN,
				columnStart: overrideColumnStart || 1
			};
	}
};

const backendGroupToGroupFormItem = ({
	variablesData,
	groupName,
	dependencyRules
}: {
	variablesData: VariablesData;
	groupName: string;
	dependencyRules: DependencyRule[];
}): GroupFormItem => {
	return {
		type: 'group',
		columnSpan: 2,
		columnStart: 1,
		group: variablesData.groups[groupName],
		formItems: variablesData.groups[groupName].variablesBelongingToGroup.map(variableName => {
			const variable = variablesData.variables[variableName];

			return backendVariableToVariableFormItem({
				variable,
				dependencies: dependencyRules.filter(
					dependency => dependency.dependantVariable.variableName === variableName
				)
			});
		})
	};
};

const backendSetToSeriesFormItem = ({
	name,
	variablesData
}: {
	name: string;
	variablesData: VariablesData;
}): SeriesFormItem => {
	const variables = Object.values(variablesData.variables);

	const setItem = variablesData.sets?.[name];

	const topLevelVariables = variables.filter(variable =>
		setItem.setOrder.find(setOrderItem => setOrderItem.variable === variable.variableName)
	);

	const groupsBelongingToSet = setItem.setOrder.filter(isGroupOrderItem);
	const variablesBelongingToGroupsInSet = groupsBelongingToSet.flatMap(groupOrderItem => {
		const variableNames = variablesData.groups[groupOrderItem.group].variablesBelongingToGroup;
		return variableNames.map(variableName => variablesData.variables[variableName]);
	});

	return {
		type: 'series',
		name,
		label: setItem.setLabel,
		mainLevelVariables: [...topLevelVariables, ...variablesBelongingToGroupsInSet]
	};
};

const fileVariableToFileFormItem = ({
	variable,
	dependencies,
	overrideLabel,
	overrideColumnSpan,
	overrideColumnStart
}: {
	variable: FileVariable;
	dependencies: DependencyRule[];
	overrideLabel?: string;
	overrideColumnSpan?: ColumnSpan;
	overrideColumnStart?: ColumnStart;
}): FileFormItem => {
	return {
		type: 'variable',
		variableType: 'file',
		variable: {
			...variable,
			variableLabel: overrideLabel || variable.variableLabel
		},
		dependencies,
		columnSpan: overrideColumnSpan || 2,
		columnStart: overrideColumnStart || 1
	};
};

const categoryVariableToCategoryFormItem = ({
	variable,
	dependencies,
	overrideLabel,
	categoryValuesAsLabels,
	overrideColumnSpan,
	overrideColumnStart,
	overrideDefaultLayoutType
}: {
	variable: CategoryVariable;
	dependencies: DependencyRule[];
	overrideLabel?: string;
	categoryValuesAsLabels?: boolean;
	overrideColumnSpan?: ColumnSpan;
	overrideColumnStart?: ColumnStart;
	overrideDefaultLayoutType?: CategoryLayoutType;
}): CategoryFormItem => {
	let layoutType: CategoryLayoutType;
	let columnSpan: ColumnSpan;

	if (variable.optimizeForManyValues) {
		layoutType = 'dropdown';
		columnSpan = 1;
	} else {
		layoutType = DEFAULT_CATEGORY_LAYOUT_TYPE;
		columnSpan = DEFAULT_CATEGORY_COLUMN_SPAN;
	}

	layoutType = overrideDefaultLayoutType ?? layoutType;
	columnSpan = overrideColumnSpan ?? columnSpan;

	if (isCategoryFixedVariable(variable)) {
		if (variable.allowedCategories.length > MAX_CATEGORIES_FOR_DROPDOWN) {
			layoutType = 'dropdown';
			columnSpan = 1;
		}

		return {
			type: 'variable',
			variableType: 'category',
			fixedCategories: 'yes',
			variable: {
				...variable,
				variableLabel: overrideLabel || variable.variableLabel,
				allowedCategories: categoryValuesAsLabels
					? variable.allowedCategories.map(category => ({
							...category,
							label: category.value
					  }))
					: variable.allowedCategories
			},
			dependencies,
			layoutType,
			columnSpan,
			columnStart: overrideColumnStart || 1
		} as CategoryFixedFormItem;
	}

	if (variable.categories.length > MAX_CATEGORIES_FOR_DROPDOWN) {
		layoutType = 'dropdown';
		columnSpan = 1;
	}

	return {
		type: 'variable',
		variableType: 'category',
		layoutType,
		fixedCategories: 'no',
		variable: {
			...variable,
			variableLabel: overrideLabel || variable.variableLabel
		},
		dependencies,
		columnSpan,
		columnStart: overrideColumnStart || 1
	};
};

const categoryMultipleVariableToCategoryMultipleFormItem = ({
	variable,
	dependencies,
	overrideLabel,
	categoryValuesAsLabels,
	overrideColumnSpan,
	overrideColumnStart,
	overrideDefaultLayoutType
}: {
	variable: CategoryMultipleVariable;
	dependencies: DependencyRule[];
	overrideLabel?: string;
	overrideDefaultLayoutType?: CategoryLayoutType;
	categoryValuesAsLabels?: boolean;
	overrideColumnSpan?: ColumnSpan;
	overrideColumnStart?: ColumnStart;
}): CategoryMultipleFormItem => {
	let layoutType: CategoryLayoutType;
	let columnSpan: ColumnSpan;

	if (variable.optimizeForManyValues) {
		layoutType = 'dropdown';
		columnSpan = 1;
	} else {
		layoutType = DEFAULT_CATEGORY_LAYOUT_TYPE;
		columnSpan = DEFAULT_CATEGORY_COLUMN_SPAN;
	}

	layoutType = overrideDefaultLayoutType ?? layoutType;
	columnSpan = overrideColumnSpan ?? columnSpan;

	if (isCategoryMultipleFixedVariable(variable)) {
		if (variable.allowedCategories.length > MAX_CATEGORIES_FOR_DROPDOWN) {
			layoutType = 'dropdown';
			columnSpan = 1;
		}

		return {
			type: 'variable',
			variableType: 'categoryMultiple',
			fixedCategories: 'yes',
			variable: {
				...variable,
				variableLabel: overrideLabel || variable.variableLabel,
				allowedCategories: categoryValuesAsLabels
					? variable.allowedCategories.map(category => ({
							...category,
							label: category.value
					  }))
					: variable.allowedCategories
			},
			dependencies,
			layoutType,
			columnSpan,
			columnStart: overrideColumnStart || 1
		} as CategoryMultipleFixedFormItem;
	}

	if (variable.categories.length > MAX_CATEGORIES_FOR_DROPDOWN) {
		layoutType = 'dropdown';
		columnSpan = 1;
	}

	return {
		type: 'variable',
		variableType: 'categoryMultiple',
		layoutType,
		fixedCategories: 'no',
		variable: {
			...variable,
			variableLabel: overrideLabel || variable.variableLabel
		},
		dependencies,
		columnSpan,
		columnStart: overrideColumnStart || 1
	} as CategoryMultipleNonFixedFormItem;
};

const DEFAULT_CATEGORY_LAYOUT_TYPE = 'horizontal';
const MAX_CATEGORIES_FOR_DROPDOWN = 100;

const unexpectedFormStateVariableText = (variableName: string) => {
	return {
		type: 'text',
		text: `WARNING: Can not show variable '${variableName}' as there has been a form misconfiguration. Please contact support at support@ledidi.no and we will help you fix it.`,
		columnSpan: 2,
		columnStart: 1
	} as const;
};

const DEFAULT_CATEGORY_COLUMN_SPAN = 2;
const DEFAULT_NON_CATEGORY_COLUMN_SPAN = 1;
