import { confirmFileUpload } from './confirmFileUpload';
import { getFileUploadUrl } from './getFileUploadUrl';
import { uploadFileToS3 } from './submit-files/submitFiles';
import { FileForUpload } from './useStoreBase64FileMutation';

export const submitFiles = async ({
	entryId,
	files,
	projectId,
	setName
}: {
	files: FileForUpload[];
	projectId: string;
	entryId: string;
	setName?: string;
}) => {
	const filePromises = files.map(async file => {
		const fileUploadUrl = await getFileUploadUrl({
			projectId,
			entryId,
			setName,
			fileName: file.fileName,
			fileVariableName: file.variableName
		});

		await uploadFileToS3({
			file: file.file,
			uploadUrl: fileUploadUrl.url,
			fields: fileUploadUrl.fields
		});

		await confirmFileUpload({
			projectId,
			fileId: fileUploadUrl.fileId
		});
	});

	return await Promise.all(filePromises);
};
