import { SeriesEntryTableVariable } from './useSeriesTablesDataQuery';
import { SortDirection } from './SeriesEntryBody';
import { SeriesEntryBodyRowField } from './SeriesEntryBody';

export const createEntriesSort = ({
	variable,
	direction
}: {
	variable: SeriesEntryTableVariable;
	direction?: SortDirection;
}) => {
	return (a: SeriesEntryBodyRowField, b: SeriesEntryBodyRowField) => {
		switch (variable.variableType) {
			case 'categoryMultiple': {
				const aValue = a.value;
				const bValue = b.value;

				if (aValue === null && bValue === null) {
					return 0;
				}

				if (aValue === null) {
					return direction === 'asc' ? 1 : -1;
				}

				if (bValue === null) {
					return direction === 'asc' ? -1 : 1;
				}

				if (!Array.isArray(aValue) || !Array.isArray(bValue)) {
					console.warn('Invalid type for categoryMultiple', {
						typeA: typeof aValue,
						typeB: typeof bValue
					});
					return 0;
				}

				const aFirst = aValue[0];
				const bFirst = bValue[0];

				if (aFirst === undefined && bFirst === undefined) {
					return 0;
				}

				if (aFirst === undefined) {
					return direction === 'asc' ? 1 : -1;
				}

				if (bFirst === undefined) {
					return direction === 'asc' ? -1 : 1;
				}

				if (typeof aFirst !== 'string' || typeof bFirst !== 'string') {
					console.warn('Invalid type for categoryMultiple', {
						aFirst,
						bFirst
					});
					return 0;
				}

				const firstCompare =
					direction === 'asc'
						? aFirst.localeCompare(bFirst)
						: bFirst.localeCompare(aFirst);

				if (firstCompare !== 0) {
					return firstCompare;
				}

				return direction === 'asc'
					? aValue.length - bValue.length
					: bValue.length - aValue.length;
			}

			case 'date':
			case 'datetime': {
				const aValue = a.value;
				const bValue = b.value;

				if (aValue === null && bValue === null) {
					return 0;
				}

				if (aValue === null) {
					return direction === 'asc' ? 1 : -1;
				}

				if (bValue === null) {
					return direction === 'asc' ? -1 : 1;
				}

				if (typeof aValue !== 'string' || typeof bValue !== 'string') {
					console.warn('Invalid type for date or datetime');
					return 0;
				}

				return direction === 'asc'
					? new Date(aValue).getTime() - new Date(bValue).getTime()
					: new Date(bValue).getTime() - new Date(aValue).getTime();
			}

			case 'category':
			case 'string': {
				const aValue = a.value;
				const bValue = b.value;

				if (aValue === null && bValue === null) {
					return 0;
				}

				if (aValue === null) {
					return direction === 'asc' ? 1 : -1;
				}

				if (bValue === null) {
					return direction === 'asc' ? -1 : 1;
				}

				if (typeof aValue !== 'string' || typeof bValue !== 'string') {
					console.warn('Invalid type for category or string');
					return 0;
				}

				return direction === 'asc'
					? aValue.localeCompare(bValue)
					: bValue.localeCompare(aValue);
			}

			case 'float':
			case 'integer': {
				const aValue = a.value;
				const bValue = b.value;

				if (aValue === null && bValue === null) {
					return 0;
				}

				if (aValue === null) {
					return direction === 'asc' ? 1 : -1;
				}

				if (bValue === null) {
					return direction === 'asc' ? -1 : 1;
				}

				if (typeof aValue !== 'number' || typeof bValue !== 'number') {
					console.warn('Invalid type for float or integer', {
						typeA: typeof aValue,
						typeB: typeof bValue
					});
					return 0;
				}

				return direction === 'asc' ? aValue - bValue : bValue - aValue;
			}

			case 'timeDuration': {
				const aValue = a.value;
				const bValue = b.value;

				if (aValue === null && bValue === null) {
					return 0;
				}

				if (aValue === null) {
					return direction === 'asc' ? 1 : -1;
				}

				if (bValue === null) {
					return direction === 'asc' ? -1 : 1;
				}

				if (typeof aValue !== 'number' || typeof bValue !== 'number') {
					console.warn('Invalid type for timeDuration');
					return 0;
				}

				return direction === 'asc' ? aValue - bValue : bValue - aValue;
			}

			case 'userDefinedUnique': {
				const aValue = a.value;
				const bValue = b.value;

				if (aValue === null && bValue === null) {
					return 0;
				}

				if (aValue === null) {
					return direction === 'asc' ? 1 : -1;
				}

				if (bValue === null) {
					return direction === 'asc' ? -1 : 1;
				}

				if (typeof aValue !== 'string' || typeof bValue !== 'string') {
					console.warn('Invalid type for userDefinedUnique', {
						type: typeof aValue
					});
					return 0;
				}

				return direction === 'asc'
					? aValue.localeCompare(bValue)
					: bValue.localeCompare(aValue);
			}

			case 'file':
				if (a.value === null && b.value === null) {
					return 0;
				}

				if (a.value === null) {
					return direction === 'asc' ? 1 : -1;
				}

				if (b.value === null) {
					return direction === 'asc' ? -1 : 1;
				}

				if (typeof a.value !== 'string' || typeof b.value !== 'string') {
					console.warn('Invalid type for file', {
						typeA: typeof a.value,
						typeB: typeof b.value
					});
					return 0;
				}

				return direction === 'asc'
					? a.value.localeCompare(b.value)
					: b.value.localeCompare(a.value);

			case 'system':
				switch (variable.variableName) {
					// These are all dates
					case 'lastmodifieddate':
					case 'creationdate': {
						const aValue = a.value;
						const bValue = b.value;

						if (aValue === null && bValue === null) {
							return 0;
						}

						if (aValue === null) {
							return direction === 'asc' ? 1 : -1;
						}

						if (bValue === null) {
							return direction === 'asc' ? -1 : 1;
						}

						if (typeof aValue !== 'string' || typeof bValue !== 'string') {
							console.warn('Invalid type for creationdate');
							return 0;
						}

						return direction === 'asc'
							? new Date(aValue).getTime() - new Date(bValue).getTime()
							: new Date(bValue).getTime() - new Date(aValue).getTime();
					}

					// These are all strings
					case 'datasetentryid':
					case 'enteredbyuser':
					case 'ownedbyuser': {
						const aLabel = a.label;
						const bLabel = b.label;

						if (aLabel === null && bLabel === null) {
							return 0;
						}

						if (aLabel === null) {
							return direction === 'asc' ? 1 : -1;
						}

						if (bLabel === null) {
							return direction === 'asc' ? -1 : 1;
						}

						if (typeof aLabel !== 'string' || typeof bLabel !== 'string') {
							console.warn('Invalid type for datasetentryid');
							return 0;
						}

						return direction === 'asc'
							? aLabel.localeCompare(bLabel)
							: bLabel.localeCompare(aLabel);
					}
				}
		}
	};
};
