import { useState } from 'react';
import format from 'date-fns/format';

import { ROUTES } from 'types/navigation';
import { Input } from 'components/UI/Inputs/Input';
import { EntryStatus, updateEntryStatus } from 'store/data/entries';
import { InputType, SetState } from 'types/index';

import { SetStatusModal } from './SetStatusModal';

import {
	Container,
	CreatableSelectStatuses,
	InputWrapper,
	SelectWrapper
} from './AddEditStatusInputs.style';
import { useRouteMatch } from 'hooks/navigation';
import {
	useTranslation,
	useStatuses,
	useRevision,
	useEntryWriteAccess,
	useStatusPermissions
} from 'hooks/store';
import { useDispatch } from 'hooks/utils';

interface Props {
	selectedStatus: EntryStatus;
	setSelectedStatus: SetState<EntryStatus>;
	isRevisionSelected?: boolean;
	setStayOnSamePage?: (flag: boolean) => void;
}

export function AddEditStatusInputs({
	selectedStatus,
	isRevisionSelected,
	setSelectedStatus,
	setStayOnSamePage
}: Props) {
	const { translate } = useTranslation();
	const dispatch = useDispatch();

	const isOnCreateEntryRoute = useRouteMatch([ROUTES.CreateEntry]);
	const isOnUpdateEntryRoute = useRouteMatch([ROUTES.UpdateEntry]);

	const isOnAddEditEntryPage = isOnCreateEntryRoute || isOnUpdateEntryRoute;

	const [
		{
			data: { statuses, statusesMap }
		}
	] = useStatuses({ lazy: true });

	const [{ data: revision }] = useRevision({ lazy: true });

	const entryWriteAccess = useEntryWriteAccess();
	const readOnly = (isOnCreateEntryRoute ? false : !entryWriteAccess) || isRevisionSelected;

	const { statusPermissionsMap } = useStatusPermissions();

	const [selectedStatusForUpdate, setSelectedStatusForUpdate] = useState<EntryStatus>(null);

	const statusesWithLabelAndValue = statuses.reduce<
		{
			label: string;
			value: string;
			isDisabled: boolean;
		}[]
	>((acc, status) => {
		const item = {
			label: status.label,
			value: status.name,
			isDisabled: false
		};

		if (statusPermissionsMap[status.name] && statusPermissionsMap[status.name].viewData) {
			// DISABLE ITEM
			if (!statusPermissionsMap[status.name].setStatus) item.isDisabled = true;

			acc.push(item);
		}

		return acc;
	}, []);

	function handleUpdateStatus(updatedStatus: EntryStatus) {
		setSelectedStatus(updatedStatus);
		setSelectedStatusForUpdate(null);
		setStayOnSamePage?.(true);
		dispatch(
			updateEntryStatus({
				entryStatus: updatedStatus
			})
		);
	}

	function onStatusChange(newValue: string | null = null) {
		// SELECTED SAME ITEM - DO NOTHING
		if (newValue === selectedStatus?.variableName) return;

		// CLEAR VALUE DIRECTLY
		if (newValue === null) {
			setSelectedStatus(null);
		}
		// TRIGGER MODAL TO SET COMMENT AS WELL
		else {
			const newEntryStatus: EntryStatus = {
				variableName: newValue,
				dueTimeStamp: null,
				comment: ''
			};

			setSelectedStatusForUpdate(newEntryStatus);
		}
	}

	const statusData =
		selectedStatus && statusesMap[selectedStatus.variableName]
			? {
					color: statusesMap[selectedStatus.variableName].statusColor ?? '',
					value: {
						label:
							statusesWithLabelAndValue.find(
								el => el.value === selectedStatus.variableName
							)?.label ?? '',
						value: selectedStatus.variableName
					},
					date: selectedStatus.dueTimeStamp
						? format(new Date(selectedStatus.dueTimeStamp), 'MMM dd, yyyy')
						: '',
					time: selectedStatus.dueTimeStamp
						? format(new Date(selectedStatus.dueTimeStamp), 'h:mm a')
						: ''
			  }
			: {
					color: '',
					value: null,
					date: '',
					time: ''
			  };

	const highlightChanges =
		!!(revision?.statusChanges.from || revision?.statusChanges.to) && !!isRevisionSelected;

	const allStatusesDisabled = statusesWithLabelAndValue.every(status => status.isDisabled);

	const canClear = (() => {
		if (selectedStatus && statusPermissionsMap[selectedStatus.variableName]) {
			return statusPermissionsMap[selectedStatus.variableName].setStatus;
		}

		return false;
	})();

	if (!isOnAddEditEntryPage || statuses.length === 0) return null;

	return (
		<Container hasChanges={highlightChanges}>
			<SelectWrapper>
				<CreatableSelectStatuses
					color={statusData.color}
					label={translate(dict => dict.statuses.statusEntryComponent.status)}
					placeholder={translate(
						dict => dict.dataset.addEditStatusInputs.noStatusSelected
					)}
					value={statusData.value}
					items={statusesWithLabelAndValue}
					onValueSelected={onStatusChange}
					readOnly={readOnly || allStatusesDisabled}
					canClear={canClear}
					scrollIntoView
				/>
			</SelectWrapper>

			<InputWrapper>
				<Input
					type={InputType.Text}
					label={translate(dict => dict.statuses.statusEntryComponent.statusDueTime)}
					value={statusData.date}
					readOnly
				/>
				<Input
					type={InputType.Text}
					label={translate(dict => dict.statuses.statusEntryComponent.statusTime)}
					value={statusData.time}
					readOnly
				/>
			</InputWrapper>

			{/* STATUS MODAL - SET STATUS */}
			{selectedStatusForUpdate && (
				<SetStatusModal
					entryStatus={selectedStatusForUpdate}
					statusesWithLabelAndValue={statusesWithLabelAndValue}
					handleUpdateStatus={handleUpdateStatus}
					onClose={() => setSelectedStatusForUpdate(null)}
				/>
			)}
		</Container>
	);
}
