import { useController } from 'react-hook-form';
import { FormControl } from '../EntryForm';
import { DateTimeFormItem } from '../data/useProjectData/useProjectData';
import { Input } from '../component/Input';

interface Props {
	control: FormControl;
	formItem: DateTimeFormItem;
}

export const DateTimeVariableInput = ({ control, formItem }: Props) => {
	const { field, fieldState } = useController({
		control,
		name: formItem.variable.variableName
	});

	return (
		<Input
			{...field}
			type="datetime-local"
			label={formItem.variable.variableLabel}
			error={fieldState.error?.message?.toString()}
			disabled={formItem.variable.entryType === 'calculated'}
			description={formItem.variable.description}
			obligatory={formItem.variable.obligatory}
			id={formItem.variable.variableName}
			hideFromUi={formItem.hideFromUi}
		/>
	);
};
