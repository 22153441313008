export const Statuses = () => {
	return (
		<div>
			<h3 className="text-base font-bold">Status change</h3>

			<p className="text-base mt-6">
				Statuses still work the same way they did before, when creating a new entry, but you
				now no longer have provide the comment in a separate dialog.
			</p>

			<p className="mt-6 text-base">
				When updating an entry, the status is moved up to the navigation bar, as shown in
				the image below. Clicking on the status will open a dialog allowing you to change it
				or see the current comment.
			</p>

			<img
				className="mt-6"
				src="/img/entry-form-1-5-change-log/statuses.png"
				alt="Showing how the status is moved up to the navigation bar when updating an entry"
			/>
		</div>
	);
};
