import { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { makeRequest } from 'features/entry-form-v2/data/makeRequest';

type RequestInput = {
	projectId: string;
	pageIndex: number;
	pageSize: number;
	filterOptions?: {
		userIds?: string[];
		actions?: string[];
		changedBys?: string[];
	};
};

export const useGetCollaboratorHistoryData = () => {
	const [data, setData] = useState<Result>();
	const [loaded, setLoaded] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [isError, setIsError] = useState(false);

	const { mutateAsync: mutateGetCollaboratorsAsync } = useMutation({
		mutationFn: async (projectId: number) => {
			return makeRequest<GetCollaboratorsResponse>({
				method: 'getHistoryFilters',
				service: 'projects',
				data: {
					project: {
						projectId
					}
				}
			});
		}
	});
	const { mutateAsync: mutateHistoryAsync } = useMutation({
		mutationFn: async (args: RequestInput) => {
			const { projectId, pageIndex, pageSize, filterOptions } = args;
			const startAt = pageIndex * pageSize;

			return makeRequest<CollaboratorHistoryResponse>({
				method: 'getCollaboratorHistoryFromProject',
				service: 'projects',
				data: { project: { projectId }, startAt, maxPageSize: pageSize, filterOptions }
			});
		}
	});

	const { mutateAsync: mutateNamesAsync } = useMutation({
		mutationFn: async (userIds: string[]) => {
			return makeRequest<{ namesFromUserIds: Record<string, string> }>({
				method: 'getNamesFromUserIds',
				service: 'user',
				data: { userIds }
			});
		}
	});

	const getHistory = useCallback(async (args: RequestInput) => {
		const { projectId, pageIndex, pageSize, filterOptions } = args;
		setIsFetching(true);

		try {
			const response = await mutateHistoryAsync({
				projectId,
				pageIndex,
				pageSize,
				filterOptions
			});
			const { collaboratorHistories } = response;
			const { collaboratorHistoryList, totalCount } = collaboratorHistories;

			const { historyFilterCollaboratorIds, historyFilterChangedByIds } =
				await mutateGetCollaboratorsAsync(Number(projectId));

			const allUserIds = new Set([
				...historyFilterCollaboratorIds,
				...historyFilterChangedByIds
			]);
			const names = (await mutateNamesAsync(Array.from(allUserIds))).namesFromUserIds;

			const userIdNames = Array.from(historyFilterCollaboratorIds).map(id => ({
				id,
				username: names[id]
			}));
			const changedByUserNames = Array.from(historyFilterChangedByIds).map(id => ({
				id,
				username: names[id]
			}));

			setLoaded(true);
			setData({
				collaboratorHistories: collaboratorHistoryList.map(history => ({
					...history,
					username: names[history.userid],
					changedByUserName: names[history.changedBy]
				})),
				pageIndex,
				pageSize,
				pageCount: Math.ceil(totalCount / pageSize),
				totalCount,
				filter: {
					users: userIdNames,
					changedBys: changedByUserNames
				}
			});
		} catch (error) {
			setIsError(true);
		} finally {
			setIsFetching(false);
		}
	}, []);

	return {
		data,
		isLoading: isFetching && !loaded,
		isFetching,
		isError,
		getHistory
	};
};

export type AuditEvent = CollaboratorPermissions & {
	changedBy: string;
	changedAction: ChangedAction;
	changedAt: string;
	userid: string;
	username: string;
	changedByUserName: string;
	statusTypeAccesses?: StatusTypeAccess[];
	projectroleid: number;
};

type CollaboratorPermissions = {
	accessVariablesWrite: boolean;
	accessVariablesRead: boolean;
	accessPersonalData: boolean;
	accessProjectWrite: boolean;
	accessProjectRead: boolean;
	accessModuleProjectDesign: boolean;
	accessModuleCollaborators: boolean;
	accessExport: boolean;
	accessEntriesSingleentryWriteEntryid: boolean;
	accessEntriesSingleentryWrite: boolean;
	accessEntriesOwnonlyWrite: boolean;
	accessEntriesOwnonlyRead: boolean;
	accessEntriesOwnonlyDelete: boolean;
	accessEntriesOrgWrite: boolean;
	accessEntriesOrgRead: boolean;
	accessEntriesOrgDelete: boolean;
	accessEntriesAllWrite: boolean;
	accessEntriesAllRead: boolean;
	accessEntriesAllDelete: boolean;
	accessAnalysisOwnonly: boolean;
	accessAnalysisOrg: boolean;
	accessAnalysisAll: boolean;
};

type StatusTypeAccess = {
	statusTypeVariableName: string;
	viewData: boolean;
	editData: boolean;
	setStatus: boolean;
};

type CollaboratorHistoryResponse = {
	collaboratorHistories: {
		collaboratorHistoryList: Array<Omit<AuditEvent, 'username' | 'changedByUserName'>>;
		totalCount: number;
	};
};

type GetCollaboratorsResponse = {
	historyFilterCollaboratorIds: string[];
	historyFilterChangedByIds: string[];
};

type Result = {
	collaboratorHistories: Array<AuditEvent>;
	totalCount: number;
	pageIndex: number;
	pageSize: number;
	pageCount: number;
	filter: {
		users: Array<{ id: string; username: string }>;
		changedBys: Array<{ id: string; username: string }>;
	};
};

export const CHANGED_ACTIONS = ['CREATE', 'UPDATE', 'DELETE'] as const;
export type ChangedAction = (typeof CHANGED_ACTIONS)[number];
