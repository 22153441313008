import { FormThumbnail } from 'components/Forms';
import { useEffect } from 'react';
import { Form, FormId } from 'store/data/forms';
import { Revision } from 'store/data/revisions';
import { VariablesData } from 'store/data/variables';
import { FormContainer, FormWrapper, FormName } from './FormsListDrawer.style';
import { useCurrentChangeOfRevision, useIsClickedHistoryPagination } from 'hooks/store';

interface Props {
	form: Form;
	lastForm: boolean;
	hasChanges: boolean;
	isFormSelected: (id: string) => boolean;
	handleFormClick: (id: string) => void;
	variablesData: VariablesData;
	revision: Revision | null;
	scopeFormId: FormId;
}

export function DefaultForm({
	form,
	hasChanges,
	lastForm,
	isFormSelected,
	handleFormClick,
	variablesData,
	revision,
	scopeFormId
}: Props) {
	const [currentChange] = useCurrentChangeOfRevision();
	const [isClicked] = useIsClickedHistoryPagination();

	useEffect(() => {
		if (revision?.changes.variableNames[currentChange - 1]) {
			const check = form.usedVariables.includes(
				revision?.changes.variableNames[currentChange - 1]
			);

			if (scopeFormId !== form.id && check && isClicked) {
				handleFormClick(form.id);
			}
		}
	}, [revision, currentChange]);

	return (
		<FormWrapper lastForm={lastForm}>
			<FormContainer
				selected={isFormSelected(form.id)}
				onClick={() => handleFormClick(form.id)}
				hasChanges={hasChanges}
			>
				<FormThumbnail form={form} variablesData={variablesData} usedInList />
			</FormContainer>

			<FormName title={form.name}>{form.name}</FormName>
		</FormWrapper>
	);
}
