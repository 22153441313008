import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetFormsQuery } from '../data/useGetFormsQuery/useGetFormsQuery';
import { ChevronDownIcon } from '@icons';
import { useProjectData } from '../data/useProjectData/useProjectData';
import { Skeleton } from '../component/Skeleton';
import { PreviewEntryForm } from '../PreviewEntryForm';

export const SelectForm = ({
	expanded,
	onFormSelected,
	selectedFormId,
	seriesName
}: {
	expanded: boolean;
	onFormSelected: (formId: string) => void;
	selectedFormId: string | null;
	seriesName?: string;
}) => {
	const params = useParams();
	const projectId = params.projectId as string;

	const getFormsQuery = useGetFormsQuery({
		projectId,
		seriesName
	});

	const activeFormRef = useRef<HTMLButtonElement>(null);
	const hasScrolledInitially = useRef(false);
	useEffect(() => {
		if (activeFormRef.current && !hasScrolledInitially.current) {
			activeFormRef.current.scrollIntoView({
				block: 'nearest',
				inline: 'start'
			});

			hasScrolledInitially.current = true;
		}
	}, [activeFormRef.current]);

	const { containerRef, canScrollLeft, canScrollRight, scrollLeft, scrollRight } =
		useScrollObserver();

	if (!getFormsQuery.data) {
		return null;
	}

	if (getFormsQuery.data.activeForms.length < 2) {
		return null;
	}

	if (!expanded) {
		return null;
	}

	return (
		<div className="bg-gray-300 flex flex-col items-center -mt-20 mb-20 py-10">
			<div className="flex flex-row gap-4">
				<button
					onClick={scrollLeft}
					className={clsx(
						'self-center p-2 bg-white rounded-full shadow-md hover:bg-gray-50',
						!canScrollLeft && 'opacity-0'
					)}
					aria-label="Scroll left"
				>
					<ChevronDownIcon className="text-icon-base rotate-90" />
				</button>

				<ul
					className="flex gap-4 px-4 md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1200px] overflow-x-scroll no-scrollbar"
					ref={containerRef}
				>
					{getFormsQuery.data.activeForms.map(form => {
						const isSelected = form.formId.toString() === selectedFormId;

						return (
							<li key={form.formId} className="flex flex-col gap-4">
								<button
									className="flex flex-col"
									onClick={() => onFormSelected(form.formId.toString())}
									ref={isSelected ? activeFormRef : null}
								>
									<div
										className={clsx(
											'border-2 w-[120px] h-[151px] rounded-md p-4 bg-white shadow-normal overflow-hidden ',
											isSelected && 'border-primary-700'
										)}
									>
										<FormPreview formId={form.formId.toString()} />
									</div>

									<p className="mt-4 text-ellipsis text-sm overflow-hidden whitespace-nowrap max-w-[120px]">
										{form.formDefinition.formName}
									</p>
								</button>
							</li>
						);
					})}
				</ul>

				<button
					onClick={scrollRight}
					className={clsx(
						'self-center p-2 bg-white rounded-full shadow-md hover:bg-gray-50',
						!canScrollRight && 'opacity-0'
					)}
					aria-label="Scroll right"
				>
					<ChevronDownIcon className="text-icon-base -rotate-90" />
				</button>
			</div>
		</div>
	);
};

const FormPreview = ({ formId }: { formId: string }) => {
	const params = useParams();
	const projectId = params.projectId as string;
	const seriesName = params.seriesName as string;

	const projectData = useProjectData({
		projectId,
		selectedFormId: formId,
		seriesName,
		allowPotentiallyStaleData: true
	});

	// For large projects with many forms rendering all items caused performance issues
	const firstTwentyItems = useMemo(() => {
		if (!projectData.data) {
			return [];
		}

		return [...projectData.data.formItems].splice(0, 20);
	}, [projectData.data]);

	if (!projectData.data) {
		return (
			<div className="grid grid-cols-2 gap-1">
				<div className="col-span-2">
					<Skeleton className="w-1/2 h-[6px] rounded-sm" />
				</div>

				{Array.from({ length: 6 }).map((_, index) => (
					<div key={index} className="flex flex-col gap-[1px]">
						<Skeleton className="h-[3px] w-1/3 rounded-sm" />
						<Skeleton className="h-[6px] w-full rounded-sm" />
					</div>
				))}
			</div>
		);
	}

	return (
		<PreviewEntryForm
			projectData={{
				...projectData.data,
				formItems: firstTwentyItems
			}}
		/>
	);
};

const useScrollObserver = () => {
	const [canScrollLeft, setCanScrollLeft] = useState(false);
	const [canScrollRight, setCanScrollRight] = useState(false);
	const containerRef = useRef<HTMLUListElement>(null);

	const checkScroll = () => {
		const element = containerRef.current;
		if (element) {
			setCanScrollLeft(element.scrollLeft > 0);
			setCanScrollRight(element.scrollLeft < element.scrollWidth - element.clientWidth);
		}
	};

	useEffect(() => {
		const element = containerRef.current;
		if (element) {
			checkScroll();
			element.addEventListener('scroll', checkScroll);
			window.addEventListener('resize', checkScroll);

			// Initial check after content loads
			const initialCheck = setTimeout(checkScroll, 100);

			return () => {
				element.removeEventListener('scroll', checkScroll);
				window.removeEventListener('resize', checkScroll);
				clearTimeout(initialCheck);
			};
		}
	}, []);

	const scrollLeft = () => {
		const element = containerRef.current;
		if (element) {
			element.scrollBy({ left: -240, behavior: 'smooth' });
		}
	};

	const scrollRight = () => {
		const element = containerRef.current;
		if (element) {
			element.scrollBy({ left: 240, behavior: 'smooth' });
		}
	};

	return {
		containerRef,
		canScrollLeft,
		canScrollRight,
		scrollLeft,
		scrollRight
	};
};

export const useFormSelector = ({
	projectId,
	seriesName
}: {
	projectId: string;
	seriesName?: string;
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const formId = searchParams.get('formId');
	const setSelectedFormId = (updatedFormId: string) => {
		setSearchParams({ formId: updatedFormId }, { replace: true });
	};

	const getFormsQuery = useGetFormsQuery({
		projectId,
		seriesName
	});
	const [shouldShowFormSelector, setShouldShowFormSelector] = useState<boolean>(false);
	useEffect(() => {
		if (getFormsQuery.data && getFormsQuery.data.activeForms.length >= 1) {
			if (!formId) {
				setSelectedFormId(getFormsQuery.data.activeForms[0].formId.toString());
			}

			if (getFormsQuery.data.activeForms.length > 1) {
				setShouldShowFormSelector(true);
			}
		}
	}, [getFormsQuery.data]);

	return {
		shouldShowFormSelector,
		toggleShowFormSelector: () => setShouldShowFormSelector(prev => !prev),
		formId,
		setSelectedFormId,
		getFormsQueryIsLoading: getFormsQuery.isLoading,

		defaultFormId: getFormsQuery.data?.activeForms[0]?.formId.toString() || null
	};
};
