import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';
import { AuditEvent } from '../useGetCollaboratorHistoryData';
import { Dialog } from 'features/entry-form-v2/component/Dialog';
import { DateTime } from 'luxon';
import { useQuery } from '@tanstack/react-query';
import { makeRequest } from 'features/entry-form-v2/data/makeRequest';
import { Tab as HeadlessUITab } from '@headlessui/react';
import clsx from 'clsx';
import { useState } from 'react';
import { StatusTypeAccess } from 'store/data/collaborators/types';

type Props = {
	onClose: () => void;
	auditEvent: AuditEvent;
	projectId: string;
};

type PermissionItemProps = {
	filled: boolean;
	label: string;
};

const PermissionItem = ({ filled, label }: PermissionItemProps) => (
	<div className="flex items-center gap-2 text-[14px]">
		<span
			className={`w-[12px] h-[12px] rounded-full inline-block ${
				filled ? 'bg-[#3b82f6]' : 'bg-[#d1d5db]'
			}`}
		/>
		<span>{label}</span>
	</div>
);

type PermissionGroupProps = {
	title: string;
	permissions: { key: keyof AuditEvent; label: string }[];
	values: AuditEvent;
	isGrid?: boolean;
};

const PermissionGroup = ({ title, permissions, values, isGrid = true }: PermissionGroupProps) => (
	<div className="mb-6 last:mb-0">
		<h3 className="text-[16px] mb-3 text-gray-700">{title}</h3>
		<div className={isGrid ? 'grid grid-cols-4 gap-3' : 'grid gap-2'}>
			{permissions.map(({ key, label }) => (
				<PermissionItem key={key} filled={Boolean(values[key])} label={label} />
			))}
		</div>
	</div>
);

const TABS = ['general', 'userInterface', 'statuses'] as const;

export function CollaboratorPermissionHistoryModal({ onClose, auditEvent, projectId }: Props) {
	const { translate } = useTranslation();
	const { changedAt, changedByUserName, statusTypeAccesses, projectroleid } = auditEvent;

	const [selectedTab, setSelectedTab] = useState<(typeof TABS)[number]>('general');
	const [modalHeight, setModalHeight] = useState<number>();

	const hasStatus = statusTypeAccesses && statusTypeAccesses.length > 0;
	const hasProjectRole = projectroleid !== NO_PROJECT_ROLE_ID;

	const statusQuery = useQuery<StatusResponse>({
		enabled: hasStatus,
		queryKey: ['statuses'],
		queryFn: () =>
			makeRequest<StatusResponse>({
				method: 'getStatuses',
				service: 'stats',
				data: {
					projectId: Number(projectId)
				}
			})
	});

	const projectRoleQuery = useQuery({
		enabled: hasProjectRole,
		queryKey: ['projectRoles'],
		queryFn: () =>
			makeRequest<{ projectRoles: { projectRoleId: number; projectRoleName: string }[] }>({
				method: 'getProjectRoles',
				service: 'projects',
				data: {
					project: {
						projectId: projectId
					}
				}
			})
	});

	const getDescription = (auditEvent: AuditEvent): string => {
		const user = auditEvent.username;
		const actions = {
			CREATE: 'added',
			UPDATE: 'updated',
			DELETE: 'removed'
		} as const;

		return translate(
			state => state.collaborators.history.modal[actions[auditEvent.changedAction]],
			false,
			{ user }
		);
	};

	const getStatusName = (statusTypeAccess: StatusTypeAccess, statusResponse: StatusResponse) => {
		if (statusTypeAccess.statusTypeVariableName === NO_STATUS_ID) {
			return 'No Status';
		}

		const status = statusResponse.statuses.find(
			status => status.statusName === statusTypeAccess.statusTypeVariableName
		);

		return status?.statusLabel ?? statusTypeAccess.statusTypeVariableName;
	};

	const getProjectRole = (projectRoleId: number) => {
		const projectRole = projectRoleQuery.data?.projectRoles.find(
			role => role.projectRoleId === projectRoleId
		);

		return projectRole?.projectRoleName ?? projectRoleId;
	};

	const formattedTime = DateTime.fromJSDate(new Date(changedAt)).toLocaleString(
		DateTime.DATETIME_MED_WITH_SECONDS
	);

	const generalPermissions: Array<{
		title: string;
		permissions: { key: keyof AuditEvent; label: string }[];
		isGrid?: boolean;
	}> = [
		{
			title: translate(state => state.collaborators.history.permissions.ownData),
			permissions: [
				{
					key: 'accessEntriesOwnonlyRead',
					label: translate(state => state.collaborators.history.permissions.view)
				},
				{
					key: 'accessEntriesOwnonlyWrite',
					label: translate(state => state.collaborators.history.permissions.edit)
				},
				{
					key: 'accessAnalysisOwnonly',
					label: translate(state => state.collaborators.history.permissions.analyse)
				},
				{
					key: 'accessEntriesOwnonlyDelete',
					label: translate(state => state.collaborators.history.permissions.delete)
				}
			]
		},
		{
			title: translate(state => state.collaborators.history.modal.groupPermissions),
			permissions: [
				{
					key: 'accessEntriesOrgRead',
					label: translate(state => state.collaborators.history.permissions.view)
				},
				{
					key: 'accessEntriesOrgWrite',
					label: translate(state => state.collaborators.history.permissions.edit)
				},
				{
					key: 'accessAnalysisOrg',
					label: translate(state => state.collaborators.history.permissions.analyse)
				},
				{
					key: 'accessEntriesOrgDelete',
					label: translate(state => state.collaborators.history.permissions.delete)
				}
			]
		},
		{
			title: translate(state => state.collaborators.history.permissions.allData),
			permissions: [
				{
					key: 'accessEntriesAllRead',
					label: translate(state => state.collaborators.history.permissions.view)
				},
				{
					key: 'accessEntriesAllWrite',
					label: translate(state => state.collaborators.history.permissions.edit)
				},
				{
					key: 'accessAnalysisAll',
					label: translate(state => state.collaborators.history.permissions.analyse)
				},
				{
					key: 'accessEntriesAllDelete',
					label: translate(state => state.collaborators.history.permissions.delete)
				}
			]
		},
		{
			title: translate(state => state.collaborators.history.permissions.additional),
			permissions: [
				{
					key: 'accessExport',
					label: translate(
						state => state.collaborators.history.permissions.allowExportData
					)
				},
				{
					key: 'accessPersonalData',
					label: translate(
						state => state.collaborators.history.permissions.allowAccessPersonalData
					)
				},
				{
					key: 'accessVariablesWrite',
					label: translate(
						state => state.collaborators.history.permissions.allowVariablesEditing
					)
				},
				{
					key: 'accessProjectWrite',
					label: translate(
						state => state.collaborators.history.permissions.allowProjectEditing
					)
				}
			],
			isGrid: false
		}
	];

	const userInterfacePermissions: Array<{
		title: string;
		permissions: { key: keyof AuditEvent; label: string }[];
		isGrid?: boolean;
	}> = [
		{
			title: translate(state => state.collaborators.history.modal.userInterfacePermissions),
			permissions: [
				{
					key: 'accessModuleProjectDesign',
					label: translate(
						state => state.collaborators.history.permissions.accessModuleProjectDesign
					)
				},
				{
					key: 'accessModuleCollaborators',
					label: translate(
						state => state.collaborators.history.permissions.accessModuleCollaborators
					)
				}
			],
			isGrid: false
		}
	];

	return (
		<Dialog
			open
			onClose={onClose}
			title={translate(state => state.collaborators.history.modal.title)}
		>
			<div className="flex flex-col w-full lg:min-w-[600px] min-w-[400px] p-5">
				<div className="bg-indigo-50 rounded-md p-4 mb-5">
					<Typography.H6>{getDescription(auditEvent)}</Typography.H6>
					<div className="text-[14px] text-gray-600">
						{formattedTime} •{' '}
						{translate(state => state.collaborators.history.modal.changedBy, false, {
							name: changedByUserName
						})}
					</div>
				</div>

				<div className="flex">
					<div className="flex flex-col justify-between">
						<HeadlessUITab.Group vertical selectedIndex={TABS.indexOf(selectedTab)}>
							<HeadlessUITab.List>
								<Tab onClick={() => setSelectedTab('general')}>
									{translate(
										state => state.collaborators.history.modal.tabs.general
									)}
								</Tab>
								<Tab onClick={() => setSelectedTab('userInterface')}>
									{translate(
										state =>
											state.collaborators.history.modal.tabs.userInterface
									)}
								</Tab>
								{hasStatus && (
									<Tab onClick={() => setSelectedTab('statuses')}>
										{translate(
											state => state.collaborators.history.modal.tabs.statuses
										)}
									</Tab>
								)}
							</HeadlessUITab.List>
						</HeadlessUITab.Group>

						<span className="text-[14px]">
							{translate(
								state => state.collaborators.history.modal.projectRole.label
							)}
							{': '}
							{hasProjectRole
								? getProjectRole(projectroleid)
								: translate(
										state =>
											state.collaborators.history.modal.projectRole.noRole
								  )}
						</span>
					</div>

					<div
						className="bg-gray-50 p-5 rounded-md flex-grow"
						style={{ minHeight: modalHeight }}
						ref={e => {
							if (e && !modalHeight) {
								setModalHeight(e.clientHeight);
							}
						}}
					>
						{selectedTab === 'general' &&
							generalPermissions.map((group, index) => (
								<PermissionGroup
									key={index}
									title={group.title}
									permissions={group.permissions}
									values={auditEvent}
									isGrid={group.isGrid}
								/>
							))}

						{selectedTab === 'userInterface' &&
							userInterfacePermissions.map((group, index) => (
								<PermissionGroup
									key={index}
									title={group.title}
									permissions={group.permissions}
									values={auditEvent}
									isGrid={group.isGrid}
								/>
							))}

						{selectedTab === 'statuses' && (
							<>
								{statusQuery.data &&
									statusTypeAccesses &&
									statusTypeAccesses.map(status => (
										<div
											className="mb-6 last:mb-0"
											key={status.statusTypeVariableName}
										>
											<h3 className="text-[16px] mb-3 text-gray-700">
												{getStatusName(status, statusQuery.data)}
											</h3>
											<div className="grid grid-cols-3 gap-3">
												<PermissionItem
													filled={status.viewData}
													label={translate(
														state =>
															state.collaborators.history.permissions
																.statuses.view
													)}
												/>
												<PermissionItem
													filled={status.editData}
													label={translate(
														state =>
															state.collaborators.history.permissions
																.statuses.edit
													)}
												/>
												<PermissionItem
													filled={status.setStatus}
													label={translate(
														state =>
															state.collaborators.history.permissions
																.statuses.setStatus
													)}
												/>
											</div>
										</div>
									))}
							</>
						)}
					</div>
				</div>
			</div>
		</Dialog>
	);
}

const Tab = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => (
	<HeadlessUITab
		className={({ selected }) =>
			clsx(
				'w-full text-left text-base p-3 border-l-2',
				selected ? 'border-indigo-500' : 'border-transparent'
			)
		}
		onClick={onClick}
	>
		{children}
	</HeadlessUITab>
);

type StatusResponse = {
	statuses: Status[];
};

type Status = {
	statusLabel: string;
	statusName: string;
};

/** When status are created in the system, a default 'No Status' is created with this id */
const NO_STATUS_ID = 'none_generated_by_ledidi';

/** This id indicates that the user has no project role */
const NO_PROJECT_ROLE_ID = 0;
