import { useParams } from 'react-router-dom';
import { useVariablesQuery } from '../data/useVariablesQuery/useVariablesQuery';
import { AggregationRuleType } from '../types';
import { useGetLatestDataEntryVersionQuery } from '../data/useGetLatestDataEntryVersionQuery';
import { useState } from 'react';
import { ChevronDownIcon } from '@icons';
import clsx from 'clsx';

export const AggregationRulesDetails = ({ className }: { className?: string }) => {
	const params = useParams();
	const projectId = params.projectId as string;
	const seriesName = params.seriesName as string;
	const entryId = params.entryId as string;

	const [expanded, setExpanded] = useState(false);

	const variablesDataQuery = useVariablesQuery({
		projectId
	});

	const getLatestDataEntryVersionQuery = useGetLatestDataEntryVersionQuery({
		entryId,
		projectId
	});

	const aggregationRulesForSeries = variablesDataQuery.data?.sets[seriesName]?.aggregationRules;

	if (
		!aggregationRulesForSeries ||
		!getLatestDataEntryVersionQuery.data ||
		aggregationRulesForSeries.length === 0
	) {
		return null;
	}

	return (
		<div
			className={clsx('p-6 col-span-full rounded-lg shadow-normal flex flex-col', className)}
		>
			<button
				className="text-start flex gap-6 items-center"
				onClick={() => setExpanded(state => !state)}
			>
				<ChevronDownIcon
					className={clsx('transition-all text-icon-base', expanded && 'rotate-180')}
				/>

				<h2 className="text-base">All aggregations</h2>
			</button>

			{expanded && (
				<table className="border-gray-700 mt-10">
					<thead className="border-2 border-gray-300">
						<tr className="text-start text-primary-500 font-semibold">
							<Th>All aggregations</Th>
							<Th>Aggregation variable</Th>
							<Th>Aggregation function</Th>
							<Th>Result</Th>
						</tr>
					</thead>

					<tbody>
						{aggregationRulesForSeries.map((rule, index) => {
							const variableLabel =
								variablesDataQuery.data?.variables[rule.aggregator.variableName]
									?.variableLabel;

							return (
								<tr
									key={rule.name}
									className={clsx(index % 2 === 0 && 'bg-gray-300')}
								>
									<Td>
										{variableLabel} (
										<span className="uppercase">
											{formatAggregationRuleType(rule.rule.type)}
										</span>
										)
									</Td>

									<Td>{variableLabel}</Td>

									<Td className="uppercase">
										{formatAggregationRuleType(rule.rule.type)}
									</Td>

									<Td>{getLatestDataEntryVersionQuery.data.entry[rule.name]}</Td>
								</tr>
							);
						})}
					</tbody>
				</table>
			)}
		</div>
	);
};

const Th = ({ children }: { children: React.ReactNode }) => {
	return (
		<th className="font-semibold text-primary-500 whitespace-nowrap p-4 text-sm text-start border border-gray-300">
			{children}
		</th>
	);
};

const Td = ({ children, className }: { children: React.ReactNode; className?: string }) => {
	return (
		<td className={clsx('p-4 text-sm text-start border border-gray-300', className)}>
			{children}
		</td>
	);
};

const formatAggregationRuleType = (type: AggregationRuleType) => {
	switch (type) {
		case 'median':
			return 'Median';

		case 'max':
			return 'Max';

		case 'min':
			return 'Min';

		case 'sum':
			return 'Sum';

		case 'average':
			return 'Mean';

		case 'earliest':
			return 'Earliest';

		case 'latest':
			return 'Latest';
	}
};
