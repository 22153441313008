import { EntryDifferenceOutput } from './../../../store/data/entries/types';
import { DATA_URL, sendRequest, STATISTICS_URL } from 'api/utils';
import { systemGeneratedVariables } from 'consts';
import { Dictionary } from 'environment';
import {
	LicenceLimitationErrorTypes,
	toggleFreeLicenceLimitationModalEvent
} from 'helpers/licences';
import { StatusObservationData, EntryStatus, EntryDraft } from 'store/data/entries';
import { LedidiStatusCode } from 'types/index';

import {
	DeleteEntryResponse,
	GetEntryFileResponse,
	FileToStore,
	GetEntryFilesResponse,
	StoreEntryFilesResponse,
	UpdateEntryStatusResponse,
	//
	GetEntriesInput,
	GetEntriesRequest,
	GetEntriesResponse,
	GetEntriesOutput,
	//
	CreateEntryInput,
	CreateEntryRequest,
	CreateEntryResponse,
	CreateEntryOutput,
	//
	UpdateEntryInput,
	UpdateEntryRequest,
	UpdateEntryResponse,
	UpdateEntryOutput,
	//
	GetNewEntryDraftInput,
	GetNewEntryDraftRequest,
	GetNewEntryDraftResponse,
	GetNewEntryDraftOutput,
	//
	GetEntryDraftInput,
	GetEntryDraftRequest,
	GetEntryDraftResponse,
	GetEntryDraftOutput,
	//
	SaveNewEntryDraftInput,
	SaveNewEntryDraftRequest,
	SaveNewEntryDraftResponse,
	SaveNewEntryDraftOutput,
	//
	SaveEntryDraftInput,
	SaveEntryDraftRequest,
	SaveEntryDraftResponse,
	SaveEntryDraftOutput,
	//
	GetSeriesEntriesInput,
	GetSeriesEntriesRequest,
	GetSeriesEntriesResponse,
	GetSeriesEntriesOutput,
	//
	CreateSeriesEntryInput,
	CreateSeriesEntryRequest,
	CreateSeriesEntryResponse,
	CreateSeriesEntryOutput,
	//
	UpdateSeriesEntryInput,
	UpdateSeriesEntryRequest,
	UpdateSeriesEntryResponse,
	UpdateSeriesEntryOutput,
	//
	DeleteSeriesEntryInput,
	DeleteSeriesEntryRequest,
	DeleteSeriesEntryResponse,
	//
	GetSeriesEntriesCountInput,
	GetSeriesEntriesCountRequest,
	GetSeriesEntriesCountResponse,
	GetSeriesEntriesCountOutput,
	//
	TransferEntriesOwnershipInput,
	TransferEntriesOwnershipRequest,
	TransferEntriesOwnershipResponse,
	//
	GetLatestEntryInput,
	GetLatestEntryRequest,
	GetLatestEntryResponse,
	GetLatestEntryOutput,
	//
	GetEntryInput,
	GetEntryRequest,
	GetEntryResponse,
	GetEntryOutput,
	GetDownloadableDatasetInput,
	GetDownloadableDatasetOutput,
	GetDownloadableDatasetRequest,
	GetDownloadableDatasetResponse
} from './types';
import {
	parseApiEntries,
	parseApiEntry,
	parseApiEntryStatus,
	parseApiEntryValue
} from 'helpers/entries';
import { AxiosRequestConfig } from 'axios';
import { VariableUniquenessType } from '../variables';
import { VariableType } from 'types/data/variables/constants';

export const methods = {
	getEntries: 'getDatasetRowsV2',
	entriesCSV: 'getDatasetRowsCSV',
	getDownloadableDataset: 'exportDatasets',
	createEntry: 'insertDataEntry',
	updateEntry: 'updateDataEntry',
	deleteEntry: 'deleteDataEntry',
	getEntry: 'getDataEntry',
	getLatestEntry: 'getLatestDataEntryVersion',
	editStatus: 'updateEntryStatus',
	storeFiles: 'storeBase64Files',
	storeFile: 'storeBase64File',
	getFile: 'getFileInfo',
	getFiles: 'getFilesInfo',
	// DRAFTS,
	getNewEntryDraft: 'getDraftForInsert',
	getEntryDraft: 'getDraftForUpdate',
	// SERIES
	getSeriesEntries: 'getRepeatingSetRowsV2',
	getSeriesEntriesCount: 'getSeriesNumberOfEntries',
	// TRANSFER ENTRIES OWNERSHIP
	transferEntryDataOwnership: 'transferEntryDataOwnership',
	// EXPORT WIZARD
	getSeriesCSV: 'getSeriesRowsCSV'
};

export default () => ({
	async getEntries(
		input: GetEntriesInput,
		config?: AxiosRequestConfig
	): Promise<GetEntriesOutput> {
		// map enhanced column names to original ones;
		const variableMap: Record<string, string> = {
			enteredbyuserwithname: 'enteredbyuser',
			ownedbyuserwithname: 'ownedbyuser'
		};

		// TODO: this is required since BE does not support userDefinedUnique
		input.filters.forEach(f => {
			if (f.filterType === VariableType.Unique) {
				if (f.filterSubType && f.filterSubType === VariableUniquenessType.Sequence) {
					return (f.filterType = VariableType.Integer);
				}
				if (f.filterSubType && f.filterSubType === VariableUniquenessType.UUID) {
					return (f.filterType = VariableType.String);
				}
				if (f.filterSubType && f.filterSubType === VariableUniquenessType.Manual) {
					return (f.filterType = VariableType.String);
				}
			}
		});

		const { data } = await sendRequest<GetEntriesRequest, GetEntriesResponse>(
			STATISTICS_URL,
			{
				method: methods.getEntries,
				...input,
				...(input.sorting &&
					variableMap[input.sorting.variableName] && {
						sorting: {
							...input.sorting,
							variableName:
								variableMap[input.sorting.variableName as keyof typeof variableMap]
						}
					})
			},
			config
		);

		if (!data.dataRows) {
			throw new Error(Dictionary.errors.api.entries.couldNotLoadDataset);
		}

		const { dataRows: apiEntries, statuses: apiStatuses, moreData, errors, totalCount } = data;

		const statuses: GetEntriesOutput['statuses'] = {};

		if (apiStatuses) {
			for (const entryId in apiStatuses) {
				const entryStatus = apiStatuses[entryId];

				const { variableName, dueTimeStamp = null, comment = '' } = entryStatus;

				statuses[entryId] = {
					variableName,
					dueTimeStamp,
					comment
				};
			}
		}

		const parsedStatuses = apiStatuses ? statuses : undefined;

		const output: GetEntriesOutput = {
			entries: parseApiEntries(apiEntries, parsedStatuses),
			statuses: parsedStatuses,
			moreData,
			totalCount
		};

		if (errors) {
			const { varErrors: columnErrors, rowErrors } = errors;

			if (!output.errors) {
				output.errors = {
					columns: [],
					rows: {}
				};
			}

			output.errors.columns = columnErrors;

			rowErrors &&
				rowErrors.forEach(rowError => {
					const { datasetEntryId, variablesWithErrors } = rowError;

					if (output.errors) {
						output.errors.rows[datasetEntryId] = variablesWithErrors;
					}
				});
		}

		return output;
	},

	async getDownloadableDataset(
		input: GetDownloadableDatasetInput
	): Promise<GetDownloadableDatasetOutput> {
		const {
			projectId,
			filters,
			exportFormat,
			datasets,
			amountOfData,
			removeLineShifts = false,
			categoryLabels,
			variables
		} = input;
		const response = await sendRequest<
			GetDownloadableDatasetRequest,
			GetDownloadableDatasetResponse
		>(STATISTICS_URL, {
			projectId,
			filters,
			variables,
			method: methods.getDownloadableDataset,
			removeLineShifts,
			amountOfData,
			datasets,
			exportFormat,
			categoryLabels
		});

		const { data } = response;

		return {
			url: data.singedUrl,
			emptyDataset: data.emptyDataset,
			populatedDataset: data.populatedDataset
		};
	},

	async createEntry(
		input: CreateEntryInput,
		callbacks?: {
			onUniqueError?: (variableName: string[]) => void;
		}
	): Promise<CreateEntryOutput> {
		const { data } = await sendRequest<CreateEntryRequest, CreateEntryResponse>(DATA_URL, {
			method: methods.createEntry,
			...input
		});

		if (data.ledidiStatusCode === LedidiStatusCode.ErrorLicence) {
			toggleFreeLicenceLimitationModalEvent().dispatch(
				LicenceLimitationErrorTypes.createEntry
			);
			throw new Error();
		}

		if (data.ledidiStatusCode === 'error.licence.other') {
			toggleFreeLicenceLimitationModalEvent().dispatch(
				LicenceLimitationErrorTypes.collaboratorCreateEntry
			);
			throw new Error();
		}

		if (data.ledidiStatusCode === 'error.uniqueFieldValue') {
			const variableNames = data.variables?.map(v => v.variableName) ?? [];
			callbacks?.onUniqueError?.(variableNames);
			throw new Error();
		}

		if (data.httpStatusCode !== 200) {
			throw new Error(Dictionary.errors.api.entries.couldNotCreateNewEntry);
		}

		const { datasetentryid, insertedEntry } = data;

		// TODO: remove when BE does this
		insertedEntry.datasetentryid = datasetentryid;

		return {
			entry: parseApiEntry(insertedEntry)
		};
	},

	async updateEntry(input: UpdateEntryInput): Promise<UpdateEntryOutput> {
		const { data } = await sendRequest<UpdateEntryRequest, UpdateEntryResponse>(DATA_URL, {
			method: methods.updateEntry,
			...input
		});

		if (data.ledidiStatusCode === 'error.uniqueFieldValue') {
			// TODO: implement same pattern for createEntry
			return {
				entry: null,
				uniqueErrorVariableNames: data.variables?.map(v => v.variableName) ?? []
			};
		}

		if (data.httpStatusCode !== 200) {
			throw new Error(Dictionary.errors.api.entries.couldNotEditEntry);
		}

		const { newDatasetentryid, updatedEntry } = data;

		// TODO: remove when BE does this
		updatedEntry.datasetentryid = newDatasetentryid;

		return {
			entry: parseApiEntry(updatedEntry)
		};
	},

	async updateEntryStatus(
		projectId: number,
		entryId: string | number,
		input: StatusObservationData
	): Promise<{
		newEntryId: string;
		updatedEntryStatus: EntryStatus;
	}> {
		const { data }: UpdateEntryStatusResponse = await sendRequest(DATA_URL, {
			method: methods.editStatus,
			projectId,
			datasetentryid: Number(entryId) || entryId,
			observationData: input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error(Dictionary.errors.api.entries.couldNotEditEntry);
		}

		const { newDatasetentryid, statusObject } = data;

		const status = Object.entries(statusObject).find(([_, value]) => value.value);

		const parsedData: {
			newEntryId: string;
			updatedEntryStatus: EntryStatus;
		} = {
			newEntryId: newDatasetentryid,
			updatedEntryStatus: null
		};

		if (status) {
			const [variableName, apiEntryStatus] = status;

			parsedData.updatedEntryStatus = parseApiEntryStatus(apiEntryStatus, variableName);
		}

		return parsedData;
	},

	async deleteEntry(projectId: number, entryId: string | number) {
		const { data }: DeleteEntryResponse = await sendRequest(DATA_URL, {
			method: methods.deleteEntry,
			projectId,
			datasetentryid: Number(entryId) || entryId
		});

		if (data.httpStatusCode !== 200) {
			throw new Error(Dictionary.errors.api.entries.couldNotDeleteEntry);
		}
	},

	async getLatestEntry(
		input: GetLatestEntryInput,
		callbacks?: {
			errorCallback?: (e: string) => void;
		}
	): Promise<GetLatestEntryOutput> {
		try {
			const { data } = await sendRequest<GetLatestEntryRequest, GetLatestEntryResponse>(
				DATA_URL,
				{
					method: methods.getLatestEntry,
					...input
				}
			);

			if (data.httpStatusCode !== 200) {
				throw new Error('', { cause: data.httpStatusCode });
			}

			const { entry: apiEntry, latestentryid, statuses = {} } = data;

			// TODO: remove when BE does this
			apiEntry.datasetentryid = latestentryid;

			const output: GetLatestEntryOutput = {
				entry: parseApiEntry(apiEntry),
				entryStatus: null
			};

			const status = Object.entries(statuses).find(([_, value]) => value.value);

			if (status) {
				const [variableName, apiEntryStatus] = status;

				output.entryStatus = parseApiEntryStatus(apiEntryStatus, variableName);
			}

			return output;
		} catch (e: any) {
			if (e && e.cause) {
				const code = e.cause as number;
				if (code === 404) {
					callbacks?.errorCallback?.('`Entry does not exist anymore`');
				}
				if (code === 500) {
					callbacks?.errorCallback?.('There was an error getting the entry');
				}
			}
			throw new Error(e?.message ?? '');
		}
	},

	async getEntryFile(fileId: string, projectId: string, datasetentryid: string) {
		const { data }: GetEntryFileResponse = await sendRequest(DATA_URL, {
			method: methods.getFile,
			projectId,
			fileId,
			datasetentryid
		});

		if (data.httpStatusCode !== 200 || !data.doc) {
			throw new Error();
		}

		return data.doc;
	},

	async getEntryFiles(fileIds: string[], projectId: string, datasetentryid: string) {
		const { data }: GetEntryFilesResponse = await sendRequest(DATA_URL, {
			method: methods.getFiles,
			fileIds,
			projectId,
			datasetentryid
		});

		if (data.httpStatusCode !== 200 || !data.doc) {
			throw new Error();
		}

		return data.doc;
	},

	async storeFile(projectId: number, entryId: string | number, file: FileToStore) {
		try {
			const { data } = await sendRequest(DATA_URL, {
				method: methods.storeFile,
				projectId,
				datasetentryid: Number(entryId) || entryId,
				file
			});

			if (data.httpStatusCode !== 200) {
				throw new Error(Dictionary.errors.api.entriesFiles.couldNotStoreEntryFile);
			}

			return data.doc;
		} catch (e) {
			throw new Error(Dictionary.errors.api.entriesFiles.couldNotStoreEntryFile);
		}
	},

	async storeFiles(projectId: number, entryId: string | number, files: FileToStore[]) {
		const errorMessage =
			files.length > 1
				? Dictionary.errors.api.entriesFiles.couldNotStoreEntryFiles
				: Dictionary.errors.api.entriesFiles.couldNotStoreEntryFile;

		try {
			const { data }: StoreEntryFilesResponse = await sendRequest(DATA_URL, {
				method: methods.storeFiles,
				projectId,
				datasetentryid: Number(entryId) || entryId,
				files
			});

			if (data.httpStatusCode !== 200) {
				throw new Error(errorMessage);
			}

			return data.doc;
		} catch (e) {
			throw new Error(errorMessage);
		}
	},

	/**
	 * ENTRY DRAFT
	 */

	async getNewEntryDraft(input: GetNewEntryDraftInput): Promise<GetNewEntryDraftOutput> {
		const { data } = await sendRequest<GetNewEntryDraftRequest, GetNewEntryDraftResponse>(
			DATA_URL,
			{
				method: methods.getNewEntryDraft,
				...input
			}
		);

		if (data.httpStatusCode !== 200) throw new Error();

		const { draft } = data;

		let entryDraft: EntryDraft = null;

		if (draft) {
			const { draftEntryId, creationDate, draftValues } = draft;

			const values = parseApiEntry(draftValues);

			// delete system generated from values
			systemGeneratedVariables.forEach(variableName => delete values[variableName]);

			entryDraft = {
				draftEntryId,
				creationDate,
				values
			};
		}

		return {
			entryDraft
		};
	},

	async getEntryDraft(input: GetEntryDraftInput): Promise<GetEntryDraftOutput> {
		const { data } = await sendRequest<GetEntryDraftRequest, GetEntryDraftResponse>(DATA_URL, {
			method: methods.getEntryDraft,
			...input
		});

		if (data.httpStatusCode !== 200) throw new Error();

		const { draft, differenceFromActiveEntry } = data;
		const parsedDifferences = differenceFromActiveEntry?.reduce<EntryDifferenceOutput>(
			(acc, curr) => {
				const key = curr.variableName as string;
				return {
					...acc,
					[key]: { from: parseApiEntryValue(curr.from), to: parseApiEntryValue(curr.to) }
				};
			},
			{}
		);

		let entryDraft: EntryDraft = null;

		if (draft) {
			const { draftEntryId, creationDate, draftValues } = draft;

			const values = parseApiEntry(draftValues);

			// delete system generated from values
			systemGeneratedVariables.forEach(variableName => delete values[variableName]);

			entryDraft = {
				draftEntryId,
				creationDate,
				values,
				differenceFromActiveEntry: parsedDifferences
			};
		}

		return {
			entryDraft
		};
	},

	async saveNewEntryDraft(
		input: SaveNewEntryDraftInput,
		callbacks?: {
			onUniqueError?: (variableName: string[]) => void;
		}
	): Promise<SaveNewEntryDraftOutput> {
		const { data } = await sendRequest<SaveNewEntryDraftRequest, SaveNewEntryDraftResponse>(
			DATA_URL,
			{
				method: methods.createEntry,
				draft: true,
				...input
			}
		);

		if (data.ledidiStatusCode === 'error.uniqueFieldValue') {
			const variableNames = data.variables?.map(v => v.variableName) ?? [];
			callbacks?.onUniqueError?.(variableNames);
			throw new Error();
		}

		if (data.ledidiStatusCode === LedidiStatusCode.ErrorLicence) {
			toggleFreeLicenceLimitationModalEvent().dispatch(
				LicenceLimitationErrorTypes.createEntry
			);
			throw new Error();
		}

		if (data.ledidiStatusCode === LedidiStatusCode.ErrorLicenceOther) {
			toggleFreeLicenceLimitationModalEvent().dispatch(
				LicenceLimitationErrorTypes.collaboratorCreateEntry
			);
			throw new Error();
		}

		if (data.httpStatusCode !== 200) throw new Error();

		const { datasetentryid: draftEntryId, insertedEntry } = data;

		const creationDate = insertedEntry['creationdate'] as string;

		const entryDraft: EntryDraft = {
			draftEntryId,
			creationDate,
			values: {}
		};

		return {
			entryDraft
		};
	},

	async saveEntryDraft(
		input: SaveEntryDraftInput,
		callbacks?: {
			onUniqueError?: (variableName: string[]) => void;
		}
	): Promise<SaveEntryDraftOutput> {
		const { data } = await sendRequest<SaveEntryDraftRequest, SaveEntryDraftResponse>(
			DATA_URL,
			{
				method: methods.updateEntry,
				draft: true,
				...input
			}
		);
		if (data.ledidiStatusCode === 'error.uniqueFieldValue') {
			const variableNames = data.variables?.map(v => v.variableName) ?? [];
			callbacks?.onUniqueError?.(variableNames);
			throw new Error();
		}

		if (data.httpStatusCode !== 200) throw new Error();

		const { newDatasetentryid: draftEntryId, updatedEntry } = data;

		const creationDate = updatedEntry['creationdate'] as string;

		const entryDraft: EntryDraft = {
			draftEntryId,
			creationDate,
			values: {}
		};

		return {
			entryDraft
		};
	},

	/**
	 * SERIES
	 */

	async getSeriesEntries(input: GetSeriesEntriesInput): Promise<GetSeriesEntriesOutput> {
		const { data } = await sendRequest<GetSeriesEntriesRequest, GetSeriesEntriesResponse>(
			STATISTICS_URL,
			{
				method: methods.getSeriesEntries,
				...input
			}
		);

		if (data.ledidiStatusCode === LedidiStatusCode.ErrorLicence) {
			toggleFreeLicenceLimitationModalEvent().dispatch(
				LicenceLimitationErrorTypes.createEntry
			);
			throw new Error();
		}

		if (data.ledidiStatusCode === LedidiStatusCode.ErrorLicenceOther) {
			toggleFreeLicenceLimitationModalEvent().dispatch(
				LicenceLimitationErrorTypes.collaboratorCreateEntry
			);
			throw new Error();
		}

		if (data.httpStatusCode !== 200) throw new Error();

		const { dataRows: apiEntries } = data;

		return {
			entries: parseApiEntries(apiEntries)
		};
	},

	async createSeriesEntry(
		input: CreateSeriesEntryInput,
		callbacks?: {
			onUniqueError?: (variableName: string[]) => void;
		}
	): Promise<CreateSeriesEntryOutput> {
		const { data } = await sendRequest<CreateSeriesEntryRequest, CreateSeriesEntryResponse>(
			DATA_URL,
			{
				method: methods.createEntry,
				...input
			}
		);

		if (data.ledidiStatusCode === 'error.uniqueFieldValue') {
			const variableNames = data.variables?.map(v => v.variableName) ?? [];
			callbacks?.onUniqueError?.(variableNames);
			throw new Error();
		}

		if (data.httpStatusCode !== 200) throw new Error();

		const { datasetentryid, insertedEntry } = data;

		// TODO: remove when BE does this
		insertedEntry.datasetentryid = datasetentryid;

		return {
			entry: parseApiEntry(insertedEntry)
		};
	},

	async updateSeriesEntry(
		input: UpdateSeriesEntryInput,
		callbacks?: {
			onUniqueError?: (variableName: string[]) => void;
		}
	): Promise<UpdateSeriesEntryOutput> {
		const { data } = await sendRequest<UpdateSeriesEntryRequest, UpdateSeriesEntryResponse>(
			DATA_URL,
			{
				method: methods.updateEntry,
				...input
			}
		);

		if (data.ledidiStatusCode === 'error.uniqueFieldValue') {
			// TODO: feed with list returned from the BE
			callbacks?.onUniqueError?.([]);
			throw new Error();
		}

		if (data.httpStatusCode !== 200) throw new Error();

		const { newDatasetentryid, updatedEntry } = data;

		// TODO: remove when BE does this
		updatedEntry.datasetentryid = newDatasetentryid;

		return {
			entry: parseApiEntry(updatedEntry)
		};
	},

	async deleteSeriesEntry(input: DeleteSeriesEntryInput): Promise<void> {
		const { data } = await sendRequest<DeleteSeriesEntryRequest, DeleteSeriesEntryResponse>(
			DATA_URL,
			{
				method: methods.deleteEntry,
				...input
			}
		);

		if (data.httpStatusCode !== 200) throw new Error();
	},

	async getSeriesEntriesCount(
		input: GetSeriesEntriesCountInput
	): Promise<GetSeriesEntriesCountOutput> {
		const { data } = await sendRequest<
			GetSeriesEntriesCountRequest,
			GetSeriesEntriesCountResponse
		>(DATA_URL, {
			method: methods.getSeriesEntriesCount,
			...input
		});

		if (data.httpStatusCode !== 200) throw new Error();

		const { numberOfEntries } = data;

		return {
			entriesCount: numberOfEntries
		};
	},

	/**
	 * TRANSFER ENTRIES OWNERSHIP
	 */
	async transferEntriesOwnership(input: TransferEntriesOwnershipInput): Promise<void> {
		const { data } = await sendRequest<
			TransferEntriesOwnershipRequest,
			TransferEntriesOwnershipResponse
		>(DATA_URL, {
			method: methods.transferEntryDataOwnership,
			...input
		});

		if (data.httpStatusCode !== 200) throw new Error();
	},

	async getEntry(input: GetEntryInput): Promise<GetEntryOutput> {
		try {
			const { data } = await sendRequest<GetEntryRequest, GetEntryResponse>(DATA_URL, {
				method: methods.getEntry,
				...input
			});

			const { entry: apiEntry, statuses = {} } = data;

			// TODO: remove when BE does this
			apiEntry.datasetentryid = input.datasetentryid;

			const output: GetEntryOutput = {
				entry: parseApiEntry(apiEntry),
				entryStatus: null
			};

			const status = Object.entries(statuses).find(([_, value]) => value.value);

			if (status) {
				const [variableName, apiEntryStatus] = status;

				output.entryStatus = parseApiEntryStatus(apiEntryStatus, variableName);
			}

			return output;
		} catch (e) {
			throw new Error(Dictionary.errors.api.entries.couldNotGetDataEntry);
		}
	}
});
