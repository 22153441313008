export const Introduction = () => {
	return (
		<div>
			<h3 className="text-base font-bold">We have made changes!</h3>

			<p className="text-base mt-6">
				We have recently made some changes to the entry form. This is a step by step
				walkthrough of these changes. Each step is brief, and going through the whole
				changelog will not take many minutes.
			</p>

			<p className="text-base mt-6">
				You can skip this at any time by clicking the &quot;Close&quot; button in the top
				right corner.
			</p>

			<p className="text-base italic mt-10">- The Ledidi team</p>
		</div>
	);
};
