import { useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import { BooleanMap, RequireOnlyOne } from 'types/index';
import { buildBooleanMap } from 'helpers/maps';
import {
	buildVariablesDataLocation,
	getVariableSetVariableNames,
	getVariableSetGroupNames
} from 'helpers/variables';
import { useMutableState, usePrevious } from 'hooks/utils';
import { useVariables } from './useVariables';
import { useVariablesData } from './useVariablesData';
import { systemGeneratedVariables } from 'consts';

export interface VariablesTableCheckedState {
	all: boolean;
	partial: {
		main: boolean;
		groups: BooleanMap;
		variableSets: BooleanMap;
	};
	one: {
		variable: boolean;
		group: boolean;
		variableSet: boolean;
	};
	checked: {
		variables: string[];
		groups: string[];
		variableSets: string[];
	};
	notPartOfGroup: boolean;
	partOfMainLevel: boolean;
	partOfSameGroup: boolean;
	partOfSameVariableSet: boolean;
	systemGenerated: boolean;
	inTotal: number;
}

interface CheckedMapState {
	variables: BooleanMap;
	groups: BooleanMap;
	variableSets: BooleanMap;
}

export function useVariablesTableCheckedData() {
	const [
		{
			data: {
				variableNames,
				groupNames,
				variableSetNames,
				groups,
				groupedVariableNames,
				groupsMap,
				variableSets,
				variableSetsMap
			}
		}
	] = useVariables({ initial: true, lazy: true });

	const variablesData = useVariablesData({ initial: true });

	const { variablesLocation } = useMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	const initialCheckedMap: CheckedMapState = useMemo(
		() => ({
			variables: buildBooleanMap(variableNames, false),
			groups: buildBooleanMap(groupNames, false),
			variableSets: buildBooleanMap(variableSetNames, false)
		}),
		[variableNames, groupNames, variableSetNames]
	);

	const [checkedMap, setCheckedMap] = useMutableState<CheckedMapState>(initialCheckedMap);

	const checkedState = useMemo(() => getCheckedState(checkedMap), [checkedMap]);

	// SYNC `checkedMap` STATE
	const variablesDataNames = useMemo(
		() => [variableNames, groupNames, variableSetNames],
		[variableNames, groupNames, variableSetNames]
	);
	const prevVariablesDataNames = usePrevious(variablesDataNames);
	useEffect(() => {
		if (isEqual(prevVariablesDataNames, variablesDataNames)) return;

		const checkedVariables = variableNames.reduce<BooleanMap>((acc, variableName) => {
			acc[variableName] = checkedMap.variables[variableName] ?? false;

			return acc;
		}, {});
		const checkedGroups = groupNames.reduce<BooleanMap>((acc, groupName) => {
			acc[groupName] = checkedMap.variables[groupName] ?? false;

			return acc;
		}, {});
		const checkedVariableSets = variableSetNames.reduce<BooleanMap>((acc, setName) => {
			acc[setName] = checkedMap.variableSets[setName] ?? false;

			return acc;
		}, {});

		const newCheckedMap: CheckedMapState = {
			variables: checkedVariables,
			groups: checkedGroups,
			variableSets: checkedVariableSets
		};

		const hasChanges = !isEqual(checkedMap, newCheckedMap);

		if (hasChanges) setCheckedMap(newCheckedMap);
	}, [variablesDataNames]);

	/**
	 * Resets all checked values to `false`
	 */
	function resetChecked() {
		setCheckedMap(state => {
			Object.keys(state.variables).forEach(
				variableName => (state.variables[variableName] = false)
			);
			Object.keys(state.groups).forEach(groupName => (state.groups[groupName] = false));
			Object.keys(state.variableSets).forEach(
				setName => (state.variableSets[setName] = false)
			);
		});
	}

	/**
	 * Toggles a variable / group / variable set - `checked` / `un-checked`
	 *
	 * @param input `variableName` / `groupName` / `setName`
	 */
	function toggleChecked(
		input: RequireOnlyOne<{
			variableName?: string;
			groupName?: string;
			setName?: string;
		}>
	) {
		const { variableName, groupName, setName } = input;

		if (variableName) {
			setCheckedMap(state => {
				state.variables[variableName] = !state.variables[variableName];
			});
		}
		if (groupName) {
			const group = groupsMap[groupName];

			setCheckedMap(state => {
				const flag = !state.groups[groupName];

				state.groups[groupName] = flag;

				group.variablesBelongingToGroup.forEach(variableName => {
					state.variables[variableName] = flag;
				});
			});
		}
		if (setName) {
			const variableSet = variableSetsMap[setName];

			const setVariableNames = getVariableSetVariableNames(variableSet, {
				groupsMap
			});
			const setGroupNames = getVariableSetGroupNames(variableSet);

			setCheckedMap(state => {
				const flag = !state.variableSets[setName];

				state.variableSets[setName] = flag;

				setVariableNames.forEach(variableName => {
					state.variables[variableName] = flag;
				});
				setGroupNames.forEach(groupName => {
					state.groups[groupName] = flag;
				});
			});
		}
	}

	/**
	 * Toggles all - `checked` / `un-checked`
	 */
	function toggleAllChecked() {
		setCheckedMap(state => {
			const flag = !getCheckedState(state).all;

			Object.keys(state.variables).forEach(
				variableName => (state.variables[variableName] = flag)
			);
			Object.keys(state.groups).forEach(groupName => (state.groups[groupName] = flag));
			Object.keys(state.variableSets).forEach(
				setName => (state.variableSets[setName] = flag)
			);
		});
	}

	/**
	 * Returns helpful flags to differ between checked states
	 *
	 * @param state `checkedMap` state
	 *
	 * @returns all - all variables and groups checked
	 * @returns partial - at least one variable or group checked
	 * @returns one.variable - exactly one variable checked
	 * @returns one.group - exactly one group checked
	 * @returns one.variableSet - exactly one variable set checked
	 */
	function getCheckedState(state: CheckedMapState): VariablesTableCheckedState {
		const variablesCheckedValues = Object.values(state.variables);
		const groupsCheckedValues = Object.values(state.groups);
		const variableSetsCheckedValues = Object.values(state.variableSets);

		const allVariablesChecked = variablesCheckedValues.every(v => v);
		const allGroupsChecked = groupsCheckedValues.every(v => v);
		const allVariableSetsChecked = variableSetsCheckedValues.every(v => v);
		const someVariablesChecked = variablesCheckedValues.some(v => v);
		const someGroupsChecked = groupsCheckedValues.some(v => v);
		const someVariableSetsChecked = variableSetsCheckedValues.some(v => v);

		const allChecked = allVariablesChecked && allGroupsChecked && allVariableSetsChecked;
		const partiallyChecked =
			(someVariablesChecked || someGroupsChecked || someVariableSetsChecked) && !allChecked;
		const oneVariableChecked =
			variablesCheckedValues.filter(v => v).length === 1 &&
			!(someGroupsChecked || someVariableSetsChecked);
		const oneGroupChecked =
			groupsCheckedValues.filter(v => v).length === 1 &&
			!(someVariablesChecked || someVariableSetsChecked);
		const oneVariableSetChecked =
			variableSetsCheckedValues.filter(v => v).length === 1 &&
			!(someVariablesChecked || someGroupsChecked);

		const checkedVariableNames = Object.entries(state.variables)
			.filter(([, checked]) => checked)
			.map(([variableName]) => variableName);

		const checkedGroupNames = Object.entries(state.groups)
			.filter(([, checked]) => checked)
			.map(([groupName]) => groupName);

		const checkedVariableSetNames = Object.entries(state.variableSets)
			.filter(([, checked]) => checked)
			.map(([setName]) => setName);

		const checkedInTotal = [
			...checkedVariableNames,
			...checkedGroupNames,
			...checkedVariableSetNames
		].length;

		/*
			TODO: WILL BE REMOVED IN THE FUTURE WHEN BACKEND WILL 
			CATER FOR MOVING VARIABLES FROM MULTIPLE LOCATIONS TO GROUP (EXISTING OR NEW)
			
			=== START ===
		*/

		const noGroupsChecked = checkedGroupNames.length === 0;
		const noVariableSetsChecked = checkedVariableSetNames.length === 0;

		const checkedNotPartOfGroup =
			noGroupsChecked &&
			checkedVariableNames.reduce((acc, variableName) => {
				if (groupedVariableNames.includes(variableName)) acc = false;

				return acc;
			}, true);

		const checkedPartOfGroup = checkedVariableNames.reduce((acc, variableName) => {
			if (!groupedVariableNames.includes(variableName)) acc = false;

			return acc;
		}, true);

		const checkedPartOfSameGroup =
			noGroupsChecked &&
			checkedPartOfGroup &&
			checkedVariableNames.reduce<string[]>((acc, variableName) => {
				groups.forEach(group => {
					if (group.variablesBelongingToGroup.includes(variableName)) {
						if (!acc.includes(group.groupName)) acc.push(group.groupName);
					}
				});

				return acc;
			}, []).length === 1;

		const checkedPartOfMainLevel =
			noGroupsChecked &&
			noVariableSetsChecked &&
			checkedVariableNames.length > 0 &&
			checkedVariableNames.every(variableName => {
				if (variableName in variablesLocation) return false;

				return true;
			});

		const checkedPartOfSameVariableSet =
			noGroupsChecked &&
			noVariableSetsChecked &&
			checkedVariableNames.length > 0 &&
			checkedVariableNames.every(variableName => {
				if (variableName in variablesLocation) {
					const location = variablesLocation[variableName];

					const { setName } = location;

					return setName !== undefined;
				}

				return false;
			});

		const checkedSystemGenerated = systemGeneratedVariables.some(systemGenerated =>
			checkedVariableNames.includes(systemGenerated)
		);

		/* === END === */

		/**
		 * Partially checked by name logic
		 *
		 * === START ===
		 */

		const partiallyCheckedGroups: BooleanMap = {};

		groups.forEach(group => {
			const { groupName, variablesBelongingToGroup } = group;

			let partial = false;

			variablesBelongingToGroup.forEach(variableName => {
				if (checkedVariableNames.includes(variableName)) partial = true;
			});

			partiallyCheckedGroups[groupName] = partial;
		});

		const partiallyCheckedVariableSets: BooleanMap = {};

		variableSets.forEach(variableSet => {
			const { setName } = variableSet;

			const setVariableNames = getVariableSetVariableNames(variableSet, {
				groupsMap
			});
			const setGroupNames = getVariableSetGroupNames(variableSet);

			let partial = false;

			setVariableNames.forEach(variableName => {
				if (checkedVariableNames.includes(variableName)) partial = true;
			});
			setGroupNames.forEach(groupName => {
				if (checkedGroupNames.includes(groupName)) partial = true;
			});

			partiallyCheckedVariableSets[setName] = partial;
		});

		/**
		 * Partially checked by name logic
		 *
		 * === END ===
		 */

		return {
			all: allChecked,
			partial: {
				main: partiallyChecked,
				groups: partiallyCheckedGroups,
				variableSets: partiallyCheckedVariableSets
			},
			one: {
				variable: oneVariableChecked,
				group: oneGroupChecked,
				variableSet: oneVariableSetChecked
			},
			checked: {
				variables: checkedVariableNames,
				groups: checkedGroupNames,
				variableSets: checkedVariableSetNames
			},
			notPartOfGroup: checkedNotPartOfGroup,
			partOfMainLevel: checkedPartOfMainLevel,
			partOfSameGroup: checkedPartOfSameGroup,
			partOfSameVariableSet: checkedPartOfSameVariableSet,
			systemGenerated: checkedSystemGenerated,
			inTotal: checkedInTotal
		};
	}

	return {
		checkedMap,
		checkedState,
		resetChecked,
		toggleChecked,
		toggleAllChecked
	};
}
