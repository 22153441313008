export const SeriesEntryOrdering = () => {
	return (
		<div>
			<h3 className="text-base font-bold">Series entry ordering</h3>

			<p className="text-base mt-6">
				Series entries are now always ordered by their creation date, with the earliest
				created series entry first.
			</p>

			<img
				className="mt-6"
				src="/img/entry-form-1-5-change-log/series_entry_ordering.png"
				alt="Showing the series entry ordering"
			/>
		</div>
	);
};
