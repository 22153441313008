import { useNavigate } from 'react-router-dom';

import { StorageKeys } from 'types/index';

const routes = {
	logout: '/logout',
	login: '/login',
	templateRoles: '/roles',
	projects: {
		list: '/projects',
		create: '/projects/create',
		import: (projectId: string) => `/projects/${projectId}/import`,
		analysis: (projectId: string) => `/projects/${projectId}/analysis`,
		dataset: {
			view: (projectId: string) => `/projects/${projectId}/dataset`,
			create: (projectId: string) => `/projects/${projectId}/dataset/create`,
			update: (projectId: string, entryId: string) =>
				`/projects/${projectId}/dataset/update/${entryId}`,
			conflicts: (projectId: string, entryId: string) =>
				`/projects/${projectId}/dataset/update/${entryId}/conflicts`,
			history: (projectId: string) => `/projects/${projectId}/dataset/history`
		},
		statuses: (projectId: string) => `/projects/${projectId}/statuses`,
		variables: {
			view: (projectId: string) => `/projects/${projectId}/variables`,
			create: (projectId: string) => `/projects/${projectId}/variables/create`,
			update: (projectId: string) => `/projects/${projectId}/variables/update`
		},
		templates: (projectId: string) => `/projects/${projectId}/templates`,
		dependencies: (projectId: string) => `/projects/${projectId}/dependencies`,
		forms: {
			list: (projectId: string) => `/projects/${projectId}/forms`,
			form: (projectId: string, formId: string) => `/projects/${projectId}/forms/${formId}`
		},
		documents: {
			list: (projectId: string) => `/projects/${projectId}/documents`,
			view: (projectId: string) => `/projects/${projectId}/documents/view`
		},
		collaborators: {
			view: (projectId: string) => `/projects/${projectId}/collaborators`,
			invite: (projectId: string) => `/projects/${projectId}/collaborators/invite`
		},
		roles: (projectId: string) => `/projects/${projectId}/roles`
	},
	help: '/help/',
	accountUM: {
		subscription: '/account/subscription',
		payment: '/account/payment',
		billingHistory: '/account/billing'
	},
	admin: {
		view: `/${process.env.REACT_APP_ADMIN_PATH_ROOT}/users`,
		create: `/${process.env.REACT_APP_ADMIN_PATH_ROOT}/users/create`,
		details: (userId: string) =>
			`/${process.env.REACT_APP_ADMIN_PATH_ROOT}/users/details/${userId}`,
		enterprise: `/${process.env.REACT_APP_ADMIN_PATH_ROOT}/enterprise`,
		roles: `/${process.env.REACT_APP_ADMIN_PATH_ROOT}/roles`
	},
	enterpriseAdmin: {
		users: `/${process.env.REACT_APP_ENTERPRISE_ADMIN_PATH_ROOT}/users`,
		projects: `/${process.env.REACT_APP_ENTERPRISE_ADMIN_PATH_ROOT}/projects`,
		subscription: `/${process.env.REACT_APP_ENTERPRISE_ADMIN_PATH_ROOT}/subscription`,
		roles: `/${process.env.REACT_APP_ENTERPRISE_ADMIN_PATH_ROOT}/roles`,
		settings: `/${process.env.REACT_APP_ENTERPRISE_ADMIN_PATH_ROOT}/settings`
	}
};

export type NavigationRoutes = typeof routes;

export function useNavigation() {
	const _navigate = useNavigate();

	function navigate(route: string) {
		_navigate(route);
	}

	function replace(route: string) {
		_navigate(route, { replace: true });
	}

	function goBack() {
		const prevPathname = localStorage.getItem(StorageKeys.PrevPathname);
		if (prevPathname !== null) {
			navigate(prevPathname);
		}
	}

	return {
		routes,
		navigate,
		replace,
		goBack
	};
}
