import { ActionPayload, Thunk } from 'store/types';
import {
	ActionTypes,
	DeactivateUserAccountAction,
	GetAllEnterpriseOwnedProjectsAction,
	ReactivateUserAccountAction,
	UpdateEnterpriseUserAction,
	SetOrganizationAccessRights
} from './types';
import { UpdateApiEnterpriseUser } from 'api/account/enterprise';
import { createActivity } from 'store/ui/activities';
import { UpdateEnterpriseUser } from '../subscription';
import { getMessageFromError, unknownErrorMessage } from 'store/utils';

export const updateEnterpriseUserAction = (
	payload: ActionPayload<UpdateEnterpriseUserAction>
): UpdateEnterpriseUserAction => ({
	type: ActionTypes.UPDATE_ENTERPRISE_USER,
	payload
});

export const updateEnterpriseUser =
	(enterpriseUser: UpdateEnterpriseUser): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_ENTERPRISE_USER,
			dispatch
		});

		try {
			activity.begin();
			const { licenceModel, ...userToUpdate } = enterpriseUser;
			const apiEnterpriseUser: UpdateApiEnterpriseUser = {
				...userToUpdate,
				licenseModel: licenceModel
			};

			const user = await context.api.account
				.enterprise()
				.updateEnterpriseUser(apiEnterpriseUser);

			// we still need to parse the response to replace license with licence.
			// In the future the api should return licence or we should keep it as it comes
			const { licenseModel: licenceModelRes, ...apiUser } = user;

			// since the local user contains more data than the received api user keep the unmodified data
			const localUser = getState().account.subscription.subscriptionUsers.byId[user.userid];
			dispatch(
				updateEnterpriseUserAction({
					user: {
						...localUser,
						...apiUser,
						licenceModel: licenceModelRes
					}
				})
			);
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

const deactivateUserAccountAction = (
	payload: ActionPayload<DeactivateUserAccountAction>
): DeactivateUserAccountAction => ({
	type: ActionTypes.DEACTIVATE_USER_ACCOUNT,
	payload
});

export const deactivateUserAccount =
	(userId: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.DEACTIVATE_USER_ACCOUNT,
			dispatch
		});

		try {
			activity.begin();
			const deactivated = await context.api.account
				.enterprise()
				.deactivateUserAccount(userId);

			if (deactivated) dispatch(deactivateUserAccountAction({ userId }));
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

const reactivateUserAccountAction = (
	payload: ActionPayload<ReactivateUserAccountAction>
): ReactivateUserAccountAction => ({
	type: ActionTypes.REACTIVATE_USER_ACCOUNT,
	payload
});

export const reactivateUserAccount =
	(userId: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.REACTIVATE_USER_ACCOUNT,
			dispatch
		});

		try {
			activity.begin();
			const reactivated = await context.api.account
				.enterprise()
				.reactivateUserAccount(userId);

			if (reactivated) dispatch(reactivateUserAccountAction({ userId }));
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

export const getAllEnterpriseOwnedProjectsAction = (
	payload: ActionPayload<GetAllEnterpriseOwnedProjectsAction>
): GetAllEnterpriseOwnedProjectsAction => ({
	type: ActionTypes.GET_ALL_ENTERPRISE_OWNED_PROJECTS,
	payload
});

export const getAllEnterpriseOwnedProjects = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_ALL_ENTERPRISE_OWNED_PROJECTS,
		dispatch
	});

	try {
		activity.begin();
		const projects = await context.api.account.enterprise().getAllEnterpriseOwnedProjects();

		dispatch(getAllEnterpriseOwnedProjectsAction({ projects }));
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
		});
	} finally {
		activity.end();
	}
};

export const setOrganizationAccessRightsAction = (
	payload: ActionPayload<SetOrganizationAccessRights>
): SetOrganizationAccessRights => ({
	type: ActionTypes.SET_ORGANIZATION_ACCESS_RIGHTS,
	payload
});
