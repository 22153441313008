import styled, { css } from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

import { Typography } from 'components/UI/Typography';
import { Colors } from 'environment';

export const Container = styled.div<{
	fullWidth?: boolean;
}>`
	/* STICKY TO TOP */
	position: sticky;
	top: 0;
	z-index: 999;

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	min-height: 6rem;
	background-color: ${Colors.background.disabled};

	/* SPACING LIKE GRID SYSTEM */
	padding-left: 4%;
	padding-right: 4%;

	@media print {
		display: none;
	}

	${({ fullWidth }) =>
		fullWidth &&
		css`
			width: 100%;
		`}
`;

export const LeftContainer = styled.div`
	flex: 1;
`;

export const Title = styled(Typography.H6)`
	flex: 1;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

export const Tabs = styled.div`
	display: flex;
`;

interface TabProps {
	$active: boolean;
}
export const Tab = styled(Link)<TabProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 6rem;
	min-width: 10.1rem;
	width: 10.1rem;
	position: relative;
	cursor: pointer;

	:hover .navigation-tab {
		color: ${Colors.primary.normal};
	}

	::after {
		display: block;
		content: '';
		width: 10.1rem;
		height: 0.2rem;
		border-radius: 0 0 0.4rem 0.4rem;
		background-color: ${Colors.primary.normal};
		position: absolute;
		bottom: 0;
		opacity: 0;
	}

	${({ $active }) =>
		$active &&
		css`
			.navigation-tab {
				color: ${Colors.primary.normal};
			}

			::after {
				opacity: 1;
			}
		`}

	@media print {
		display: none;
	}
`;
