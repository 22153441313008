import { CHANGELOG_DISMISSED_KEY } from 'features/entry-form-v2/changelog/ChangelogDialog';
import { SAFARI_USER_WARNING_KEY } from 'features/entry-form-v2/smart-components/SafariUserWarning';

const KEYS_TO_KEEP = [CHANGELOG_DISMISSED_KEY, SAFARI_USER_WARNING_KEY] as const;

/**
 * Because the old logout clears localstorage, and we're not really sure what keys we need to clear, we're using this method to keep the keys we need.
 */
export const safeClearLocalStorage = () => {
	const keyValuesToKeep: Record<string, string> = {};

	for (const key of KEYS_TO_KEEP) {
		const value = localStorage.getItem(key);

		if (value) {
			keyValuesToKeep[key] = value;
		}
	}

	localStorage.clear();

	for (const key in keyValuesToKeep) {
		localStorage.setItem(key, keyValuesToKeep[key]);
	}
};
