import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Button } from 'components/UI/Interactables/Button';
import { Typography } from 'components/UI/Typography';
import { Svgs } from 'environment';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useFormHasChanges,
	usePermissions,
	useProjectId,
	useUpdateForm,
	useForm
} from 'hooks/store';
import { useFormErrors } from 'hooks/store/data/forms/useFormErrors';
import { useKeyPress } from 'hooks/utils';

interface Props {
	loading: boolean;
	deleteFormModal: {
		visible: boolean;
	};
	onDelete: () => void;
	onPreview: () => void;
}

export function FormDesignerHeader({ loading, deleteFormModal, onDelete, onPreview }: Props) {
	const { translate } = useTranslation();
	const { navigate, routes } = useNavigation();

	const { hasErrors: hasFormErrors } = useFormErrors();

	const hasUnsavedChanges = useFormHasChanges();

	const [{ data: form }] = useForm({ lazy: true });

	const { hasVariablesWriteAccess } = usePermissions();

	const [projectId] = useProjectId();

	const [{ loading: updatingForm }, updateForm] = useUpdateForm();

	useKeyPress(
		{
			onEnterKeyPress: handleSubmit,
			onEscapeKeyPress: goToFormsList,
			onDeleteKeyPress: hasVariablesWriteAccess ? onDelete : undefined
		},
		{ noModalsOpened: true, listen: !loading }
	);

	function handleSubmit() {
		// LOADING HAPPENING -> DO NOTHING
		if (updatingForm || hasFormErrors()) return;

		// READONLY -> GO TO FORMS LIST
		if (!hasVariablesWriteAccess) return goToFormsList();

		// UPDATE FORM & NO CHANGES -> GO TO FORMS LIST
		if (!hasUnsavedChanges) return goToFormsList();

		updateForm();
	}

	function goToFormsList() {
		if (projectId) navigate(routes.projects.forms.list(projectId));
	}

	function getButtonTitle(): string {
		// READONLY
		if (!hasVariablesWriteAccess) translate(({ buttons }) => buttons.done);

		// UPDATE FORM
		return translate(({ formDesigner, buttons }) =>
			hasUnsavedChanges ? formDesigner.updateForm : buttons.done
		);
	}

	function canPreview() {
		if (!form) return;

		const { title, elements, groups } = form;

		return title !== '' || !!Object.values(elements).length || !!Object.values(groups).length;
	}

	return (
		<>
			<Header.Main
				leftComponent={
					<Flex align={a => a.center}>
						<Icon
							svg={Svgs.ArrowLeft}
							marginOffset={{ right: 0.8 }}
							variant={v => v.buttonActive}
							onClick={updatingForm ? undefined : goToFormsList}
						/>
						<Typography.H6>
							{translate(dict => dict.formDesigner.editing)} {form?.name}
						</Typography.H6>
					</Flex>
				}
				rightComponent={
					<Flex>
						{/* DELETE */}
						{hasVariablesWriteAccess && (
							<Icon
								svg={Svgs.Delete}
								variant={v => v.buttonActive}
								marginOffset={{ right: 0.8 }}
								active={deleteFormModal.visible}
								disabled={loading}
								onClick={updatingForm ? undefined : onDelete}
							/>
						)}
						{/* PREVIEW */}
						<Icon
							svg={Svgs.PreviewOn}
							disabled={!canPreview() || loading}
							variant={v => v.buttonActive}
							onClick={() => canPreview() && onPreview()}
						/>
						{/* CREATE / UPDATE */}
						<Button
							title={getButtonTitle()}
							loading={updatingForm}
							marginOffset={{ left: 2.4 }}
							onClick={handleSubmit}
						/>
					</Flex>
				}
			/>
		</>
	);
}
