import { createActivity } from 'store/ui/activities';
import { Thunk, ActionPayload } from 'store/types';

import {
	ActionTypes,
	GetEntryRevisionsAction,
	SetRevisionIdAction,
	GetEntryRevisionDataAction,
	GetSetEntryRevisionsAction,
	SetCurrentChangeAction,
	SetIsClickedAction,
	SetCurrentRevisionAction,
	SetSetRevisionIdAction,
	GetSetEntryRevisionDataAction
} from './types';
import { parseEntries } from 'helpers/entries';
import { getMessageFromError, unknownErrorMessage } from 'store/utils';

const getEntryRevisionsAction = (
	payload: ActionPayload<GetEntryRevisionsAction>
): GetEntryRevisionsAction => ({
	type: ActionTypes.GET_ENTRY_REVISIONS,
	payload
});

export const getEntryRevisions = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_ENTRY_REVISIONS,
		dispatch
	});

	try {
		activity.begin();

		const state = getState();

		// SWITCH FOR datasetentryid vs set: {setName: 'setName'};
		const projectId = state.data.projects.projectId;
		const entryId = state.data.entries.metadata.entryId;

		if (projectId && entryId) {
			const { revisions } = await context.api.data.revisions().getRevisions({
				projectId: Number(projectId),
				datasetentryid: entryId
			});

			const { entryId: latestEntryId } = state.data.entries.metadata;

			if (entryId) {
				if (entryId === latestEntryId) {
					dispatch(getEntryRevisionsAction({ entryId, revisions }));
				}
			}
		}
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
		});
	} finally {
		activity.end();
	}
};

//
const getSetEntryRevisionsAction = (
	payload: ActionPayload<GetSetEntryRevisionsAction>
): GetSetEntryRevisionsAction => ({
	type: ActionTypes.GET_SET_ENTRY_REVISIONS,
	payload
});

export const getSetEntryRevisions = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_SET_ENTRY_REVISIONS,
		dispatch
	});

	try {
		activity.begin();

		const state = getState();

		const projectId = state.data.projects.projectId;
		const selectedSetName = state.data.entries.subEntries.selected.setName;
		const subEntryId = state.data.entries.subEntries.selected.subEntryId;

		if (projectId && selectedSetName && subEntryId) {
			const { revisions } = await context.api.data.revisions().getRevisions({
				projectId: Number(projectId),
				datasetentryid: subEntryId,
				set: {
					setName: selectedSetName
				}
			});
			dispatch(
				getSetEntryRevisionsAction({
					setName: selectedSetName,
					entryId: subEntryId,
					revisions
				})
			);
		}
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
		});
	} finally {
		activity.end();
	}
};

const getEntryRevisionDataAction = (
	payload: ActionPayload<GetEntryRevisionDataAction>
): GetEntryRevisionDataAction => ({
	type: ActionTypes.GET_ENTRY_REVISION_DATA,
	payload
});

export const getEntryRevisionData = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_ENTRY_REVISION_DATA,
		dispatch
	});

	try {
		activity.begin();

		const {
			projects: { projectId },
			entries: {
				subEntries: { selected: selectedSet }
			},
			revisions: {
				metadata: { revisionId: mainRevisionId, setRevisionId }
			}
		} = getState().data;

		const revisionId = selectedSet.setName ? setRevisionId : mainRevisionId;

		if (projectId && revisionId) {
			const { entry, entryStatus } = await context.api.data.entries().getEntry({
				projectId: Number(projectId),
				datasetentryid: revisionId,
				...(selectedSet.setName !== null && { set: { setName: selectedSet.setName } })
			});

			const { variablesData } = await context.api.data.variables().getVariables({
				projectId: Number(projectId)
			});

			const [parsedEntry] = parseEntries([entry], variablesData.variablesMap);

			dispatch(getEntryRevisionDataAction({ entry: parsedEntry, entryStatus }));
		}
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
		});
	} finally {
		activity.end();
	}
};

const getSetEntryRevisionDataAction = (
	payload: ActionPayload<GetSetEntryRevisionDataAction>
): GetSetEntryRevisionDataAction => ({
	type: ActionTypes.GET_SET_ENTRY_REVISION_DATA,
	payload
});

export const getSetEntryRevisionData = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_SET_ENTRY_REVISION_DATA,
		dispatch
	});

	try {
		activity.begin();

		const {
			projects: { projectId },
			entries: {
				subEntries: { selected: selectedSet }
			},
			revisions: {
				metadata: { setRevisionId }
			}
		} = getState().data;

		if (projectId && setRevisionId) {
			const { entry, entryStatus } = await context.api.data.entries().getEntry({
				projectId: Number(projectId),
				datasetentryid: setRevisionId,
				...(selectedSet.setName !== null && { set: { setName: selectedSet.setName } })
			});

			dispatch(getSetEntryRevisionDataAction({ entry, entryStatus }));
		}
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
		});
	} finally {
		activity.end();
	}
};

// LOCAL
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const setRevisionId = (
	payload: ActionPayload<SetRevisionIdAction>
): SetRevisionIdAction => ({
	type: ActionTypes.SET_REVISION_ID,
	payload
});

export const setSetRevisionId = (
	payload: ActionPayload<SetSetRevisionIdAction>
): SetSetRevisionIdAction => ({
	type: ActionTypes.SET_SET_REVISION_ID,
	payload
});

export const setCurrentChange = (
	payload: ActionPayload<SetCurrentChangeAction>
): SetCurrentChangeAction => ({
	type: ActionTypes.SET_CURRENT_CHANGE,
	payload
});

export const setCurrentRevision = (
	payload: ActionPayload<SetCurrentRevisionAction>
): SetCurrentRevisionAction => ({
	type: ActionTypes.SET_CURRENT_REVISION,
	payload
});

export const setIsClicked = (payload: ActionPayload<SetIsClickedAction>): SetIsClickedAction => ({
	type: ActionTypes.SET_IS_CLICKED,
	payload
});
