import { DraggedFile } from 'components/UI/DragAndDrop/DragAndDrop';
import { isOnMac } from 'helpers/generic';
import { OperationResult } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { isUndefined } from 'lodash';
import { nanoid as generate } from 'nanoid';
import { useCallback, useMemo } from 'react';
import { DropzoneInputProps, DropzoneRootProps, useDropzone } from 'react-dropzone';
import { ActionTypes } from 'store/data/entries/types';
import { setError } from 'store/ui/activities';
import { ACCEPTED_EXCEL_FILE_TYPES } from 'types/index';
import { useTranslation } from '..';
import { useUploadDocument } from '../data/documents/useUploadDocument';
import { useProjectId } from '../data/projects/useProjectId';

interface Data {
	getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
	getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps;
	isDragActive: boolean;
}

export interface OnFileDropOptions {
	readerResult: string;
	file: DraggedFile;
	isBinaryFile: boolean;
}

interface Error {
	code: string;
	message: string;
}

interface RejectedFile {
	file: DraggedFile;
	errors: Error[];
}

interface Props {
	acceptedFileTypes?: string[];
	maxFileSize?: number;
	readerType?: ReaderType;
	dropzoneType?: DropzoneType;
	disabled?: boolean;
	onFileDialogCancel?: () => void;
	onFileDrop?: (options: OnFileDropOptions) => void;
	setImportFileName?: (fileName: string) => void;
}

export enum ReaderType {
	BINARY,
	STRING,
	BASE64
}

export enum DropzoneType {
	ENTRY,
	DOCUMENT,
	DATASET
}

export function useImportDropzone({
	acceptedFileTypes,
	maxFileSize,
	readerType,
	dropzoneType,
	disabled,
	onFileDialogCancel,
	onFileDrop,
	setImportFileName
}: Props): OperationResult<Data, (acceptedFiles: any, rejectedFiles: any) => void> {
	const dispatch = useDispatch();
	const { translate } = useTranslation();
	const [projectId] = useProjectId();
	const [, uploadProjectFile] = useUploadDocument();

	const maxFileSizeString = useMemo(() => {
		if (maxFileSize === undefined) return '';
		if (maxFileSize < 1000000) {
			// if smaller than 1MB then disply KB
			return `${maxFileSize / 1000} KB`;
		} else {
			// display MB
			return `${maxFileSize / 1000000} MB`;
		}
	}, [maxFileSize]);

	function onDropError(errorMessage: string) {
		dispatch(
			setError({
				type: ActionTypes.ADD_LOCAL_ENTRY_FILE,
				error: errorMessage,
				uuid: generate(),
				toast: { display: errorMessage.length > 0, message: errorMessage }
			})
		);
	}

	function onFileAccepted(file: File) {
		const reader = new FileReader();

		const fileExtension = file.name.split('.').pop() ?? '';

		const isBinaryFile = ACCEPTED_EXCEL_FILE_TYPES.includes(
			isOnMac() ? file.type : fileExtension
		);

		(dropzoneType === DropzoneType.DATASET || dropzoneType === DropzoneType.DOCUMENT) &&
			setImportFileName &&
			setImportFileName(file.name);

		// UPLOADING THE DIRECT BINARY DATA WITHOUT THE READER
		// FOR DOCUMENTS ON PROJECT LEVEL
		if (dropzoneType === DropzoneType.DOCUMENT) {
			if (projectId) {
				uploadProjectFile(file);
			}
			return;
		}

		// Binary
		if (isBinaryFile) {
			if (readerType === ReaderType.BINARY || isUndefined(readerType)) {
				reader.readAsBinaryString(file);
			} else {
				reader.readAsDataURL(file);
			}
		}
		// Base64
		else if (readerType === ReaderType.BASE64) reader.readAsDataURL(file);
		// Text / Default
		else reader.readAsText(file);

		reader.onload = async () => {
			let readerResult = reader.result as string;

			// Remove unecessary string in front of the base64 content
			if (readerType === ReaderType.BASE64)
				readerResult = readerResult.slice(readerResult.indexOf('base64,') + 7);

			onFileDrop &&
				onFileDrop({
					readerResult,
					file,
					isBinaryFile
				});
		};

		let readerErrorMessage = '';
		reader.onabort = () =>
			(readerErrorMessage = translate(dict => dict.dragAndDropFile.abortedFileReading));
		reader.onerror = () =>
			(readerErrorMessage = translate(dict => dict.dragAndDropFile.failedFileReading));
		readerErrorMessage && onDropError && onDropError(readerErrorMessage);
	}

	/**
	 * Applies validation on given file.
	 * Triggers onDropError with the errors found
	 * @param file
	 */
	function onFilesRejected(files: RejectedFile[]) {
		let errorMessage = '';
		if (files.length > 1) {
			errorMessage = translate(dict => dict.dragAndDropFile.tooManyFiles);
		} else if (files.length === 1) {
			const { file } = files[0];

			if (maxFileSize && file.size > maxFileSize) {
				errorMessage = translate(dict => dict.dragAndDropFile.errorTooLarge, false, {
					maxSize: maxFileSizeString
				});
			}

			if (acceptedFileTypes && !acceptedFileTypes.includes(file.type)) {
				const fileType = file.name.split('.').pop();
				if (fileType) {
					const message = translate(
						dict => dict.dragAndDropFile.errorNotSupportedType,
						false,
						{
							fileType: fileType
						}
					);
					errorMessage = errorMessage ? errorMessage + '\n' + message : message;
				}
			}
		}

		errorMessage && onDropError(errorMessage);
	}

	const onDrop = useCallback(
		// TODO TYPE THESE THINGS
		(acceptedFiles, rejectedFiles) => {
			if (rejectedFiles.length > 0) {
				onFilesRejected(rejectedFiles);
			} else if (acceptedFiles.length > 0) {
				onFileAccepted(acceptedFiles[0]);
			}
		},
		[onFileDrop]
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		multiple: false,
		noKeyboard: true,
		// TODO: Properly validate accept attr that blocks file types for react-dropzone lib
		// accept: -RESTRUCTURE-
		maxSize: maxFileSize,
		disabled: disabled,
		onDrop,
		onFileDialogCancel
	});

	return [
		{
			data: { getRootProps, getInputProps, isDragActive },
			loading: false,
			error: false
		},
		onDrop
	];
}
