import { useQuery } from '@tanstack/react-query';
import { makeRequest } from './makeRequest';

export type GetFreeSizeResponse = {
	/** Bytes */
	freeSize: number;
};

export const useGetFreeSizeQuery = ({ projectId }: { projectId: string }) => {
	return useQuery<GetFreeSizeResponse>({
		queryKey: ['free-size', projectId],

		queryFn: () => {
			return makeRequest({
				method: 'getFreeSize',
				service: 'cde',
				data: {
					projectId
				}
			});
		}
	});
};
export const NO_SIZE_LIMIT = -1;
