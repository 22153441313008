import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { Button } from '../component/Button';
import { useProjectData } from '../data/useProjectData/useProjectData';
import { FileForUpload } from '../data/useStoreBase64FileMutation';
import { ROUTE_MAP } from '../utils/routeMap';
import { ErrorModal } from '../ErrorModal';
import { Entry } from '../types';
import { FormFile, isFrontendFormFile } from '../utils/zodUtils';
import { useEntries } from 'hooks/store';
import { useTracking } from 'app/tracking/TrackingProvider';
import { useState, useEffect, StrictMode, useRef } from 'react';
import { EntryHeader } from '../component/EntryHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetFormsQuery } from '../data/useGetFormsQuery/useGetFormsQuery';
import { useGetProjectQuery } from '../data/useGetProjectQuery';
import { useInsertDataEntryMutation } from '../data/useInsertDataEntryMutation';
import { createSubmitEvent, EntryForm, NextAction } from '../EntryForm';
import { SelectForm, useFormSelector } from '../smart-components/SelectForm';
import { SplitButton } from '../component/SplitButton';
import { useUpdateEntryStatusMutation } from '../data/statuses/useUpdateEntryStatusMutation';
import {
	SelectEntryStatus,
	useStatusesWithAccessQuery
} from '../smart-components/SelectEntryStatus';
import { NONE_STATUS_NAME, useGetStatusesQuery } from '../data/statuses/useGetStatusesQuery';
import { computeStatusDueDate } from '../smart-components/SelectStatusButton';
import {
	EntryFormSkeleton,
	ErrorLoadingEntry,
	OptionsMenuButton
} from '../update-entry/UpdateEntryPageV1_5';
import { V1_5_ChangelogDialog } from '../changelog/ChangelogDialog';
import { Menu } from '@headlessui/react';
import { ParsedEntry } from '../utils/parse-backend-values/parseBackendValues';
import { SelectGroup } from '../smart-components/select-group/SelectGroup';
import { MoreIcon } from '@icons';
import { toast } from 'react-toastify';

export const CreateEntryPageV1_5 = () => {
	const navigate = useNavigate();
	const params = useParams();

	// Used to reset EntryForm
	const [formKeyManualIdentifier, setFormKeyManualIdentifier] = useState(0);

	/**
	 * This is used to allow the user to leave the page without the diff modal.
	 * Used to override the behavior of usePrompt in EntryForm
	 */
	const allowLeavePageOverrideRef = useRef<boolean>(false);

	const projectId = params.projectId as string;

	const { track } = useTracking();

	const [_, fetchEntries] = useEntries({ lazy: true });

	const {
		shouldShowFormSelector: showFormSelector,
		toggleShowFormSelector,
		getFormsQueryIsLoading,
		formId,
		setSelectedFormId
	} = useFormSelector({
		projectId
	});

	const statusesWithAccessQuery = useStatusesWithAccessQuery();

	const [selectedGroupId, setSelectedGroupId] = useState<string>();

	const projectDataQuery = useProjectData({
		projectId,
		selectedFormId: formId
	});

	const insertDataEntryMutation = useInsertDataEntryMutation();

	const [selectedStatusName, setSelectedStatusName] = useState<string>();
	const [statusComment, setStatusComment] = useState<string>('');
	const [statusDueDate, setStatusDueDate] = useState<Date>();
	const updateEntryStatusMutation = useUpdateEntryStatusMutation();

	const getStatusesQuery = useGetStatusesQuery({ projectId });
	const defaultStatusDefinition = getStatusesQuery.data?.statuses.find(
		status => status.default === true
	);

	const hasInitializedStatusRef = useRef(false);
	useEffect(() => {
		if (defaultStatusDefinition && !hasInitializedStatusRef.current) {
			setSelectedStatusName(defaultStatusDefinition.statusName);
			setStatusDueDate(computeStatusDueDate(defaultStatusDefinition));
			hasInitializedStatusRef.current = true;
		}
	}, [defaultStatusDefinition]);

	const loading = projectDataQuery.isLoading || getFormsQueryIsLoading;
	const error = projectDataQuery.error;

	// Storing scroll location in UpdateEntryPage seems to prevent this from auto scrolling to the top somehow,
	// I assume we always want to start on the top when filling a new form, anyways
	useEffect(() => {
		if (!loading) {
			document.body.scrollTo({
				top: 0
			});
		}
	}, [loading]);

	const [submitting, setSubmitting] = useState(false);

	if (error) {
		return (
			<ErrorLoadingEntry
				isFetching={projectDataQuery.isFetching}
				refetch={projectDataQuery.refetch}
			/>
		);
	}

	const submitEntry = async (args: { nextAction: NextAction; dirtyData: ParsedEntry }) => {
		try {
			setSubmitting(true);

			if (!projectDataQuery.data) {
				console.error(
					"onSubmit called but projectDataQuery.data doesn't exist, this is a no-op"
				);
				return;
			}

			const insertDataEntryResult = await insertDataEntryMutation.mutateAsync({
				projectId,
				entry: args.dirtyData,
				organizationGroupId: selectedGroupId
			});

			if (selectedStatusName) {
				await updateEntryStatusMutation.mutateAsync({
					projectId,
					entryId: insertDataEntryResult.insertedEntry.datasetentryid,
					oldStatusName: defaultStatusDefinition?.statusName,
					newStatusName: selectedStatusName,
					comment: statusComment
				});
			}

			fetchEntries();

			if (args.nextAction.type === 'saveAndNavigate') {
				const nextFormId = getQueryParam({
					url: args.nextAction.nextNavLocation,
					paramName: 'formId'
				});

				// If the formId changes it is safe to assume that the user is navigating to a different form
				if (nextFormId && nextFormId !== formId) {
					allowLeavePageOverrideRef.current = true;

					return navigate(
						ROUTE_MAP.project.byId.dataset.update.createPath({
							projectId,
							entryId: insertDataEntryResult.insertedEntry.datasetentryid,
							formId: nextFormId
						}),
						{ replace: true }
					);
				}

				setFormKeyManualIdentifier(prev => prev + 1);
				return navigate(args.nextAction.nextNavLocation);
			}

			if (args.nextAction.type === 'saveAndClose') {
				allowLeavePageOverrideRef.current = true;

				return navigate(ROUTE_MAP.project.byId.dataset.createPath({ projectId }), {
					replace: true
				});
			}
			if (args.nextAction.type === 'saveAndCreateNew') {
				return setFormKeyManualIdentifier(prev => prev + 1);
			}

			// If the user don't have view access to the status they are creating the entry for, they should not be redirected to the update entry page
			const projectHasStatuses =
				getStatusesQuery.data && getStatusesQuery.data.statuses.length > 0;
			const statusesWithViewDataAccess = statusesWithAccessQuery.data?.withViewDataAccess;
			const userHasViewAccessToCreatedEntryBasedOnStatus = statusesWithViewDataAccess?.find(
				status => {
					if (selectedStatusName === undefined) {
						return status.statusName === NONE_STATUS_NAME;
					}

					return status.statusName === selectedStatusName;
				}
			);

			if (projectHasStatuses && !userHasViewAccessToCreatedEntryBasedOnStatus) {
				toast.success('Entry created', {
					position: 'top-center'
				});
				return setFormKeyManualIdentifier(prev => prev + 1);
			}

			allowLeavePageOverrideRef.current = true;
			navigate(
				ROUTE_MAP.project.byId.dataset.update.createPath({
					projectId,
					entryId: insertDataEntryResult.insertedEntry.datasetentryid,
					formId
				}),
				{ replace: true }
			);
		} catch (_e) {
			// Error is handled in the ErrorModal
		} finally {
			setSubmitting(false);

			track({
				eventName: 'entry_created',
				data: {
					entryHasStatus: !!selectedStatusName,
					action: args.nextAction.type
				}
			});

			allowLeavePageOverrideRef.current = false;
		}
	};

	return (
		<StrictMode>
			<V1_5_ChangelogDialog />

			{/** Same height as the header */}
			<div className="h-[60px]" />

			<div className="flex flex-col mt-20">
				{!loading && (
					<SelectForm
						expanded={showFormSelector}
						onFormSelected={setSelectedFormId}
						selectedFormId={formId}
					/>
				)}

				<div className="flex flex-col lg:w-[756px] lg:mx-auto">
					<ErrorModal
						onClose={insertDataEntryMutation.reset}
						error={insertDataEntryMutation.error}
					/>

					{!selectedGroupId && (
						<SelectGroup onGroupSelected={setSelectedGroupId} projectId={projectId} />
					)}

					{loading && <EntryFormSkeleton />}

					{!loading && projectDataQuery.data && (
						<EntryForm
							allowLeavePageOverrideRef={allowLeavePageOverrideRef}
							key={`${formId}-${formKeyManualIdentifier}`}
							Header={
								<>
									<Header
										isSubmittingEntry={submitting}
										formSelectorIsShowing={showFormSelector}
										onShowFormSelectorClicked={toggleShowFormSelector}
									/>

									<SelectEntryStatus
										selectedStatusName={selectedStatusName}
										onStatusNameChanged={statusName => {
											const statusDefinition =
												getStatusesQuery.data?.statuses.find(
													status => status.statusName === statusName
												);

											if (statusDefinition) {
												setStatusDueDate(
													computeStatusDueDate(statusDefinition)
												);
												setSelectedStatusName(statusName);
												setStatusComment('');
											} else if (defaultStatusDefinition) {
												setSelectedStatusName(
													defaultStatusDefinition.statusName
												);
												setStatusDueDate(
													computeStatusDueDate(defaultStatusDefinition)
												);
												setStatusComment('');
											} else {
												setSelectedStatusName(undefined);
												setStatusDueDate(undefined);
												setStatusComment('');
											}
										}}
										comment={statusComment}
										onCommentChanged={setStatusComment}
										dueDate={statusDueDate}
										statusColor={
											getStatusesQuery.data?.statuses.find(
												status => status.statusName === selectedStatusName
											)?.statusColor
										}
										error={
											updateEntryStatusMutation.error
												? 'Error updating status'
												: undefined
										}
									/>
								</>
							}
							projectData={projectDataQuery.data}
							onSubmitForConfirmation={submitEntry}
							onSubmitForSubmission={submitEntry}
							submitting={submitting}
						/>
					)}
				</div>
			</div>
		</StrictMode>
	);
};

const Header = ({
	isSubmittingEntry,
	onShowFormSelectorClicked,
	formSelectorIsShowing
}: {
	isSubmittingEntry: boolean;
	onShowFormSelectorClicked: () => void;
	formSelectorIsShowing: boolean;
}) => {
	const params = useParams();
	const projectId = params.projectId as string;

	const { formId } = useFormSelector({
		projectId
	});

	const getFormsQuery = useGetFormsQuery({
		projectId
	});

	const getProjectQuery = useGetProjectQuery({ projectId });

	const submitToForm = (e: React.MouseEvent<HTMLButtonElement>) => {
		const target = e.target as HTMLElement;
		const form = target.closest('form');

		const submitEvent = createSubmitEvent(target);

		form?.dispatchEvent(submitEvent);
	};

	return (
		<EntryHeader
			breadCrumbs={[
				{
					title: getProjectQuery.data?.project.projectName,
					url: ROUTE_MAP.project.byId.dataset.createPath({
						projectId
					})
				}
			]}
			title="Create Entry"
		>
			<div className="flex gap-4 items-center">
				{getFormsQuery.data && getFormsQuery.data.activeForms.length > 1 && (
					<button onClick={onShowFormSelectorClicked} className="self-end" type="button">
						<Icon
							svg={Svgs.FileText}
							active={formSelectorIsShowing}
							propagate
							variant={v => v.button}
						/>
					</button>
				)}

				<OptionsMenu
					handlePrint={() => {
						window.open(
							ROUTE_MAP.project.byId.dataset.create.print.createPath({
								projectId,
								formId
							}),
							'_blank'
						);
					}}
				/>

				<div className="flex gap-[1px]">
					<Button
						loading={isSubmittingEntry}
						type="submit"
						className="col-start-4 col-span-2 rounded-r-none"
						data-testid="entry-form-header_save-button"
						data-action="save"
						title="Save"
						variant="primary"
					/>

					<SplitButton className="rounded-l-none">
						<SplitButton.Item
							data-action="saveAndClose"
							title="Save and close"
							onClick={submitToForm}
						/>
						<SplitButton.Item
							data-action="saveAndCreateNew"
							title="Save and create new"
							onClick={submitToForm}
						/>
					</SplitButton>
				</div>

				<Link to={ROUTE_MAP.project.byId.dataset.createPath({ projectId })}>
					<Icon
						svg={Svgs.Close}
						variant={v => v.buttonActive}
						dataTestId="entry-form-header_close-icon"
						propagate
					/>
				</Link>
			</div>
		</EntryHeader>
	);
};

export const extractFilesFromEntry = ({ entry: originalEntry }: { entry: Entry }) => {
	const entry = { ...originalEntry };

	const files: FileForUpload[] = [];
	Object.entries(entry).forEach(([variableName, value]) => {
		if (isFrontendFormFile(value)) {
			const file: FormFile = value;

			if (file === null) {
				// file should be sent as null to backend for deletion, leave it in
				return;
			}

			if (file?.type !== 'frontend-file') {
				console.error(new Error('File is not a frontend file, this is a no-op'));
				return;
			}

			files.push({
				file: file.file,
				fileName: file.fileName,
				variableName: variableName
			});
		}
	});

	return {
		files,
		entryWithoutFiles: entry
	};
};

export const OptionsMenu = ({ handlePrint }: { handlePrint: () => void }) => {
	const { track } = useTracking();

	return (
		<div className="relative">
			<Menu>
				<Menu.Button className="hover:bg-gray-700/10 rounded-full p-2">
					<MoreIcon className="text-icon-base" aria-label="More options" />
				</Menu.Button>

				<Menu.Items className="absolute overflow-hidden top-14 right-0 w-48 bg-white rounded-lg z-50 shadow-normal flex flex-col items-stretch">
					<Menu.Item>
						<OptionsMenuButton
							ariaLabel="Print entry"
							onClick={() => {
								handlePrint();
								track({
									eventName: 'print_entry_clicked'
								});
							}}
						>
							Print entry
						</OptionsMenuButton>
					</Menu.Item>
				</Menu.Items>
			</Menu>
		</div>
	);
};

export function getQueryParam({ url, paramName }: { url: string; paramName: string }) {
	try {
		// Try the URL constructor with a base URL first
		const parsedUrl = new URL(url, 'https://example.com');
		return parsedUrl.searchParams.get(paramName);
	} catch (error) {
		// Fallback for environments that don't fully support URL API
		const queryString = url.split('?')[1] || '';
		const urlParams = new URLSearchParams(queryString);
		return urlParams.get(paramName);
	}
}
