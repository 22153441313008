import { useController } from 'react-hook-form';
import { FormControl } from '../EntryForm';
import { NumericFormItem } from '../data/useProjectData/useProjectData';
import { Input } from '../component/Input';

interface Props {
	control: FormControl;
	formItem: NumericFormItem;
}

export const NumericVariableInput = ({ control, formItem }: Props) => {
	const { field, fieldState } = useController({
		name: formItem.variable.variableName,
		control
	});

	return (
		<Input
			{...field}
			onChange={e => {
				field.onChange(e);
			}}
			id={formItem.variable.variableName}
			obligatory={formItem.variable.obligatory}
			value={field.value ?? ''}
			label={formItem.variable.variableLabel}
			error={fieldState.error?.message?.toString()}
			description={formItem.variable.description}
			required={formItem.variable.obligatory}
			disabled={formItem.variable.entryType === 'calculated'}
			hideFromUi={formItem.hideFromUi}
		/>
	);
};
