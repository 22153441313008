import { isEqual, isInteger } from 'lodash';
import { batch } from 'react-redux';

import {
	AddVariableToSetInput,
	AggregationRule,
	NewGroup,
	NewVariableSet,
	PartialVariableSet,
	RemoveVariableFromGroupInput,
	RemoveVariableFromSetInput,
	UpdatedGroup,
	Variable,
	VariableCategory,
	VariableSet
} from 'api/data/variables';
import { asyncForEach } from 'api/utils/helpers';
import {
	groupCardPulse,
	groupCountPulse,
	variableCardPulse,
	variableSetCardPulse,
	variableSetCountPulse
} from 'events/variables';
import { rebuildAnalyses } from 'store/data/analyses';
import { getDependencies, setRefetchDependencies } from 'store/data/dependencies';
import {
	ActionTypes as EntriesActionTypes,
	rebuildEntries,
	setRefetchEntries
} from 'store/data/entries';
import { rebuildFilters } from 'store/data/filters';
import { getForms, setRefetchForms } from 'store/data/forms';
import { ActionPayload, Thunk } from 'store/types';
import { createActivity, doAfterTransaction } from 'store/ui/activities';
import {
	executePromisesInSequence,
	getMessageFromError,
	throttleActivity,
	unknownErrorMessage
} from 'store/utils';
import { RequireOnlyOne } from 'types/index';

import { track } from 'app/tracking/TrackingProvider';
import { objectDifference } from 'helpers/objects';
import {
	buildVariablesDataFromStoreData,
	getActionTypesWithHydrateData,
	getVariablesDataDifferences,
	isVariableGroupInSet,
	isVariableInSet,
	parseVariableCategories,
	parseVariableCategory,
	prepareApiVariable,
	prepareApiVariablesDataForBulkDelete,
	prepareApiVariablesDataForMove,
	preparePartialApiVariable,
	preparePartialApiVariableSet,
	preparePartialVariableCategory,
	prepareVariablesDataForBulkDelete,
	prepareVariablesDataForMove
} from 'helpers/variables';
import { getTranslation } from 'hooks/store/ui/useTranslation';
import { EntryVariableType, FixedCategories, VariableType } from 'types/data/variables/constants';
import {
	ActionTypes,
	ActivateVariableCategoriesAction,
	AddVariableGroupToSetAction,
	AddVariablesToGroupAction,
	AddVariableToGroupAction,
	AddVariableToSetAction,
	CreateGroupAction,
	CreateVariableAction,
	CreateVariableCategoryValueAction,
	CreateVariableCategoryValueLocalAction,
	CreateVariableCategoryValuesAction,
	CreateVariableSetAction,
	CreateVariableSetAggregationRuleAction,
	DeactivateVariableCategoriesAction,
	DeleteBulkVariablesDataAction,
	DeleteGroupAction,
	DeleteVariableAction,
	DeleteVariableCategoryValueAction,
	DeleteVariableCategoryValuesAction,
	DeleteVariableSetAction,
	DeleteVariableSetAggregationRuleAction,
	GetVariableAction,
	GetVariablesAction,
	MergeVariableCategoryValuesAction,
	MoveGroupAction,
	MoveVariableAction,
	MoveVariableBetweenGroupsAction,
	MoveVariableBetweenSetsAction,
	MoveVariableCategoryValueAction,
	MoveVariableCategoryValuesAction,
	MoveVariableGroupBetweenSetsAction,
	MoveVariableInsideGroupAction,
	MoveVariablesBetweenGroupsAction,
	MoveVariableSetAction,
	MoveVariableSetAggregationRuleAction,
	MoveVariablesOrGroupsToRootListAction,
	MoveVariablesOrGroupsToSetAction,
	RebuildVariablesDataCategoriesAction,
	RemoveVariableFromGroupAction,
	RemoveVariableFromSetAction,
	RemoveVariableGroupFromSetAction,
	SetDestinationGroupNameAction,
	SetDestinationSetNameAction,
	SetDraggingVariableNameAction,
	SetVariableNameAction,
	SetVariablesFiltersAction,
	SetVariablesSearchTermAction,
	SetVariablesViewOptionAction,
	UpdateGroupAction,
	UpdateVariableAction,
	UpdateVariableCategoryValueAction,
	UpdateVariableSetAction,
	UpdateVariableSetAggregationRuleAction
} from './types';

/*
	THE AMOUNT OF TIME IN MILLISECONDS TO CALL `callback` FUNCTION AFTER AN ACTION HAS FINISHED
	IF NO OTHER SIMILAR ACTION HAS FIRED WITHIN THE TIME IN MILLISECONDS
	--- NOTE: TO BE ADJUSTED ACCORDING TO UX FEEDBACK ---
*/
const throttleTimeouts = {
	general: 1000
};

const throttledActivity = throttleActivity({ timeout: throttleTimeouts.general });

//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

/**
 * ===================
 * 	CRUD - VARIABLES
 * ===================
 */

const getVariableAction = (payload: ActionPayload<GetVariableAction>): GetVariableAction => ({
	type: ActionTypes.GET_VARIABLE,
	payload
});

export const getVariable = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_VARIABLE, dispatch });

	try {
		activity.begin();

		const {
			projectId,
			metadata: { variableName }
		} = getState().data.variables;

		if (projectId && variableName) {
			const { variable } = await context.api.data.variables().getVariable({
				projectId: Number(projectId),
				variableName
			});

			dispatch(getVariableAction({ variable }));
		}
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
		});
	} finally {
		activity.end();
	}
};

export const getVariablesAction = (
	payload: ActionPayload<GetVariablesAction>
): GetVariablesAction => ({
	type: ActionTypes.GET_VARIABLES,
	payload
});

export const getVariables = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_VARIABLES, dispatch });

	const { projectId } = getState().data.variables;

	try {
		activity.begin({ payload: { projectId } });

		if (projectId) {
			const { variablesData } = await context.api.data.variables().getVariables({
				projectId: Number(projectId)
			});

			dispatch(
				getVariablesAction({
					projectId,
					variablesData
				})
			);
		}
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
			payload: { projectId }
		});
	} finally {
		activity.end();
	}
};

const createVariableAction = (
	payload: ActionPayload<CreateVariableAction>
): CreateVariableAction => ({
	type: payload.localChanges ? ActionTypes.CREATE_VARIABLE_LOCAL : ActionTypes.CREATE_VARIABLE,
	payload
});

export const createVariable =
	(variable: Variable): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.CREATE_VARIABLE, dispatch });

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const {
				projectId,
				metadata: { destinationSetName, destinationGroupName }
			} = getState().data.variables;

			if (projectId) {
				const apiVariable = prepareApiVariable(variable);

				const { variable: newVariable, transactionId } = await context.api.data
					.variables()
					.createVariable({
						projectId: Number(projectId),
						variable: apiVariable,
						...(destinationSetName !== null && {
							set: {
								setName: destinationSetName
							}
						}),
						...(destinationGroupName !== null && {
							destinationGroup: {
								groupName: destinationGroupName
							}
						})
					});

				track({
					eventName: 'variable_created',
					data: {
						type: variable.type,
						entry_type: variable.entryType,
						obligatory: variable.obligatory,
						part_of_series: !!destinationSetName
					}
				});

				const actionPayload: ActionPayload<CreateVariableAction> = {
					variable: newVariable,
					...(destinationSetName && { destinationSetName }),
					...(destinationGroupName && { destinationGroupName })
				};

				dispatch(createVariableAction({ ...actionPayload, localChanges: true }));
				dispatch(createVariableAction(actionPayload));

				// REBUILD ENTRIES
				dispatch(rebuildEntries());
				// REBUILD INVALID ANALYSES
				dispatch(rebuildAnalyses());

				// ASYNC CALL -> TRACK PROGRESS WITH `transactionId`
				if (transactionId) {
					await dispatch(
						doAfterTransaction({
							transactionId,
							actionType: EntriesActionTypes.RECALCULATING_ENTRIES,
							callback: () => {
								// MARK ENTRIES FOR REFETCH
								dispatch(setRefetchEntries());
							}
						})
					);
				}

				setTimeout(() => {
					const card = document.querySelector(`[data-scroll-id="${newVariable.name}"]`);

					card?.scrollIntoView();
					variableCardPulse().dispatch({ variableName: newVariable.name });
				}, 250);
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

const updateVariableAction = (
	payload: ActionPayload<UpdateVariableAction>
): UpdateVariableAction => ({
	type: ActionTypes.UPDATE_VARIABLE,
	payload
});

export const updateVariable =
	(input: {
		variable: Variable;
		sourceTimeUnit?: string;
		patchDependencies?: boolean;
		callbacks?: {
			onPatchDependencies?(): void;
		};
	}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.UPDATE_VARIABLE, dispatch });

		const { variable, patchDependencies, callbacks, sourceTimeUnit } = input;

		let shouldHydrateData = true;

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const setName = isVariableInSet({ variableName: variable.name, variablesData });

				const initialVariable = storeVariablesData.variables.byName[variable.name];
				const partialApiVariable = preparePartialApiVariable(initialVariable, variable);

				const { variable: updatedVariable, transactionId } = await context.api.data
					.variables()
					.updateVariable(
						{
							projectId: Number(projectId),
							variable: partialApiVariable,
							...(setName !== undefined && { set: { setName } }),
							...(patchDependencies !== undefined && { patchDependencies }),
							...(sourceTimeUnit && { sourceTimeUnit })
						},
						{
							onPatchDependencies: () => {
								shouldHydrateData = false;
								callbacks?.onPatchDependencies?.();
							}
						}
					);

				dispatch(updateVariableAction({ variable }));

				dispatch(
					handleVariableChanges(
						{ initialVariable, variable: updatedVariable },
						{ omitRefetchEntries: transactionId !== undefined }
					)
				);

				// ASYNC CALL -> TRACK PROGRESS WITH `transactionId`
				if (transactionId) {
					await dispatch(
						doAfterTransaction({
							transactionId,
							actionType: EntriesActionTypes.RECALCULATING_ENTRIES,
							callback: () => {
								// MARK ENTRIES FOR REFETCH
								dispatch(setRefetchEntries());
							}
						})
					);
				}
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			if (shouldHydrateData) {
				// THROTTLE ACTIVITY
				throttledActivity.throttle({
					activityId: activity.id,
					callback: () => dispatch(hydrateData())
				});
			}
		}
	};

const deleteVariableAction = (
	payload: ActionPayload<DeleteVariableAction>
): DeleteVariableAction => ({
	type: payload.localChanges ? ActionTypes.DELETE_VARIABLE_LOCAL : ActionTypes.DELETE_VARIABLE,
	payload
});

export const deleteVariable =
	(variableName: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.DELETE_VARIABLE, dispatch });

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId) {
				const variablesData = buildVariablesDataFromStoreData(
					byProjectId[projectId].initial
				);

				const setName = isVariableInSet({ variableName, variablesData });

				const { transactionId } = await context.api.data.variables().deleteVariable({
					projectId: Number(projectId),
					variable: {
						variableName
					},
					...(setName !== undefined && { set: { setName } })
				});

				dispatch(deleteVariableAction({ variableName, setName, localChanges: true }));
				dispatch(deleteVariableAction({ variableName, setName }));

				// REBUILD ENTRIES
				dispatch(rebuildEntries());
				// REBUILD FILTERS
				dispatch(rebuildFilters());
				// REBUILD INVALID ANALYSES
				dispatch(rebuildAnalyses());
				// MARK DEPENDENCIES FOR REFETCH
				dispatch(setRefetchDependencies());

				// ASYNC CALL -> TRACK PROGRESS WITH `transactionId`
				if (transactionId) {
					await dispatch(
						doAfterTransaction({
							transactionId,
							actionType: EntriesActionTypes.RECALCULATING_ENTRIES,
							callback: () => {
								// MARK ENTRIES FOR REFETCH
								dispatch(setRefetchEntries());
							}
						})
					);
				}
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

/**
 * ================
 * 	CRUD - GROUPS
 * ================
 */

const createGroupAction = (payload: ActionPayload<CreateGroupAction>): CreateGroupAction => ({
	type: payload.localChanges ? ActionTypes.CREATE_GROUP_LOCAL : ActionTypes.CREATE_GROUP,
	payload
});

export const createGroup =
	(
		group: NewGroup,
		options: {
			variableNames?: string[];
			destinationIndex?: number;
			setName?: string;
			from?: RequireOnlyOne<{
				set?: {
					setName: string;
					variableName: string;
					parentGroup?: string;
				};
				group?: {
					groupName: string;
					variableName: string;
				};
				mainList?: {
					variableName: string;
				};
			}>;
		} = {}
	): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.CREATE_GROUP, dispatch });

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const {
				projectId,
				byProjectId,
				metadata: { destinationSetName }
			} = getState().data.variables;

			if (projectId) {
				const apiGroupNew: NewGroup = {
					groupLabel: group.groupLabel
				};

				const { variableNames, destinationIndex, setName, from } = options;

				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const variablesDataForMove = prepareVariablesDataForMove({
					selected: { variableNames: variableNames ?? [], groupNames: [] },
					variablesData
				});

				const dataToMove = prepareApiVariablesDataForMove(variablesDataForMove);

				if (dataToMove.mainList.hasData && setName !== undefined) {
					const { variables } = dataToMove.mainList;

					await asyncForEach(variables, async variable => {
						const addVariableToSetInput: AddVariableToSetInput = {
							projectId: Number(projectId),
							variable: { variableName: variable.variableName },
							set: { setName }
						};
						const { transactionId } = await context.api.data
							.variables()
							.addVariableToSet(addVariableToSetInput);

						await dispatch(
							doAfterTransaction({
								transactionId,
								actionType: ActionTypes.CREATE_GROUP
							})
						);
					});
				}

				await asyncForEach(dataToMove.variableSets, async variableSetMove => {
					const { variables } = variableSetMove;

					await asyncForEach(variables, async variable => {
						// VARIABLE IS INSIDE A SET - NEEDS REMOVAL INTO MAIN LIST
						if (
							variable.from?.set &&
							(!setName || (setName && setName !== variable.from?.set.setName))
						) {
							const { group } = variable.from.set;

							// VARIABLE IS INSIDE GROUP - REMOVE IT INTO VARIABLE SET
							if (group !== undefined && group.groupName !== undefined) {
								const removeVariableFromGroupInput: RemoveVariableFromGroupInput = {
									projectId: Number(projectId),
									variable: { variableName: variable.variableName },
									group: { groupName: group.groupName },
									set: { setName: variable.from.set.setName }
								};

								await context.api.data
									.variables()
									.removeVariableFromGroup(removeVariableFromGroupInput);
							}

							// REMOVE VARIABLE FROM SET INTO MAIN LIST

							const removeVariableFromSetInput: RemoveVariableFromSetInput = {
								projectId: Number(projectId),
								variable: { variableName: variable.variableName },
								set: { setName: variable.from.set.setName }
							};

							const { transactionId } = await context.api.data
								.variables()
								.removeVariableFromSet(removeVariableFromSetInput);

							await dispatch(
								doAfterTransaction({
									transactionId,
									actionType: ActionTypes.CREATE_GROUP
								})
							);

							if (setName) {
								const addVariableToSetInput: AddVariableToSetInput = {
									projectId: Number(projectId),
									variable: { variableName: variable.variableName },
									set: { setName }
								};

								await context.api.data
									.variables()
									.addVariableToSet(addVariableToSetInput);
							}
						}
					});
				});

				const { group: newGroup } = await context.api.data.variables().createGroup({
					projectId: Number(projectId),
					group: apiGroupNew,
					...(variableNames && {
						variables: variableNames.map(variableName => ({ variableName }))
					}),
					...(destinationIndex !== undefined && { destinationIndex }),
					...(setName !== undefined && { set: { setName } }),
					// USED TO CREATE EMPTY GROUP DIRECTLY IN SET
					...(destinationSetName !== null && { set: { setName: destinationSetName } })
				});

				const actionPayload: ActionPayload<CreateGroupAction> = {
					group: newGroup,
					destinationIndex,
					setName,
					from,
					// USED TO CREATE EMPTY GROUP DIRECTLY IN SET
					...(destinationSetName !== null && { setName: destinationSetName })
				};

				dispatch(createGroupAction({ ...actionPayload, localChanges: true }));

				dispatch(createGroupAction(actionPayload));

				setTimeout(() => {
					const card = document.querySelector(`[data-scroll-id="${newGroup.groupName}"]`);

					card?.scrollIntoView();
					groupCardPulse().dispatch({ groupName: newGroup.groupName });
				}, 250);
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

const updateGroupAction = (payload: ActionPayload<UpdateGroupAction>): UpdateGroupAction => ({
	type: ActionTypes.UPDATE_GROUP,
	payload
});

export const updateGroup =
	(group: UpdatedGroup): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.UPDATE_GROUP, dispatch });

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const setName = isVariableGroupInSet({ groupName: group.groupName, variablesData });

				const apiGroupUpdated: UpdatedGroup = {
					groupName: group.groupName,
					groupLabel: group.groupLabel
				};

				const { group: updatedGroup } = await context.api.data.variables().updateGroup({
					projectId: Number(projectId),
					group: apiGroupUpdated,
					...(setName !== undefined && { set: { setName } })
				});

				dispatch(updateGroupAction({ group: updatedGroup }));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

const deleteGroupAction = (payload: ActionPayload<DeleteGroupAction>): DeleteGroupAction => ({
	type: payload.localChanges ? ActionTypes.DELETE_GROUP_LOCAL : ActionTypes.DELETE_GROUP,
	payload
});

export const deleteGroup =
	(groupName: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.DELETE_GROUP, dispatch });

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const setName = isVariableGroupInSet({ groupName, variablesData });

				await context.api.data.variables().deleteGroup({
					projectId: Number(projectId),
					group: {
						groupName
					},
					...(setName !== undefined && { set: { setName } })
				});

				dispatch(deleteGroupAction({ groupName, setName, localChanges: true }));
				dispatch(deleteGroupAction({ groupName, setName }));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

/**
 * =======================
 * 	CRUD - VARIABLE SETS
 * =======================
 */

const createVariableSetAction = (
	payload: ActionPayload<CreateVariableSetAction>
): CreateVariableSetAction => ({
	type: payload.localChanges
		? ActionTypes.CREATE_VARIABLE_SET_LOCAL
		: ActionTypes.CREATE_VARIABLE_SET,
	payload
});

export const createVariableSet =
	(input: {
		variableSet: NewVariableSet;
		group?: NewGroup;
		options?: {
			variableNames?: string[];
			groupNames?: string[];
			destinationIndex?: number;
		};
		callbacks?: {
			successCallback?: (variableSet: VariableSet) => void;
		};
	}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.CREATE_VARIABLE_SET, dispatch });

		const { variableSet, group, options = {}, callbacks = {} } = input;

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId) {
				const { variableNames, groupNames, destinationIndex } = options;

				const { variableSet: newVariableSet } = await context.api.data
					.variables()
					.createVariableSet({
						projectId: Number(projectId),
						set: {
							setLabel: variableSet.setLabel
						},
						...(destinationIndex !== undefined && { destinationIndex })
					});

				track({
					eventName: 'variable_series_created',
					data: {
						series_name: variableSet.setLabel
					}
				});

				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const variablesDataForMove = prepareVariablesDataForMove({
					selected: { variableNames: variableNames ?? [], groupNames: groupNames ?? [] },
					variablesData
				});

				const dataToMove = prepareApiVariablesDataForMove(variablesDataForMove);

				await asyncForEach(dataToMove.variableSets, async variableSetMove => {
					const { variables, groups } = variableSetMove;

					await asyncForEach(groups, async group => {
						if (group.from?.set) {
							const { setName } = group.from.set;

							// GROUP IS INSIDE SET - REMOVE IT INTO ROOT LIST
							const { transactionId } = await context.api.data
								.variables()
								.removeVariableGroupFromSet({
									projectId: Number(projectId),
									group: { groupName: group.groupName },
									set: { setName }
								});

							await dispatch(
								doAfterTransaction({
									transactionId,
									actionType: ActionTypes.MOVE_VARIABLE_BETWEEN_SETS
								})
							);
						}
						const { transactionId } = await context.api.data
							.variables()
							.addVariableGroupToSet({
								projectId: Number(projectId),
								group: { groupName: group.groupName },
								set: {
									setName: newVariableSet.setName
								}
							});

						await dispatch(
							doAfterTransaction({
								transactionId,
								actionType: ActionTypes.MOVE_VARIABLE_BETWEEN_SETS
							})
						);
					});

					await asyncForEach(variables, async variable => {
						// VARIABLE IS INSIDE A SET - NEEDS REMOVAL INTO MAIN LIST
						if (variable.from?.set) {
							const { setName, group } = variable.from.set;
							// VARIABLE IS INSIDE GROUP - REMOVE IT INTO VARIABLE SET
							if (group && group?.groupName !== undefined) {
								await context.api.data.variables().removeVariableFromGroup({
									projectId: Number(projectId),
									variable: { variableName: variable.variableName },
									group: { groupName: group.groupName },
									set: { setName }
								});
							}

							// REMOVE VARIABLE FROM SET INTO MAIN LIST
							const { transactionId } = await context.api.data
								.variables()
								.removeVariableFromSet({
									projectId: Number(projectId),
									variable: { variableName: variable.variableName },
									set: { setName }
								});

							await dispatch(
								doAfterTransaction({
									transactionId,
									actionType: ActionTypes.CREATE_VARIABLE_SET
								})
							);
						}

						if (variable.from?.group) {
							await context.api.data.variables().removeVariableFromGroup({
								projectId: Number(projectId),
								variable: { variableName: variable.variableName },
								group: variable.from?.group
							});
						}
						const { transactionId } = await context.api.data
							.variables()
							.addVariableToSet({
								projectId: Number(projectId),
								variable: { variableName: variable.variableName },
								set: {
									setName: newVariableSet.setName
								}
							});

						await dispatch(
							doAfterTransaction({
								transactionId,
								actionType: ActionTypes.CREATE_VARIABLE_SET
							})
						);
					});
				});

				if (dataToMove.mainList.hasData) {
					const { variables, groups } = dataToMove.mainList;
					await asyncForEach(groups, async group => {
						const { transactionId } = await context.api.data
							.variables()
							.addVariableGroupToSet({
								projectId: Number(projectId),
								group: { groupName: group.groupName },
								set: {
									setName: newVariableSet.setName
								}
							});

						await dispatch(
							doAfterTransaction({
								transactionId,
								actionType: ActionTypes.CREATE_VARIABLE_SET
							})
						);
					});

					await asyncForEach(variables, async variable => {
						const { transactionId } = await context.api.data
							.variables()
							.addVariableToSet({
								projectId: Number(projectId),
								variable: { variableName: variable.variableName },
								set: {
									setName: newVariableSet.setName
								}
							});

						await dispatch(
							doAfterTransaction({
								transactionId,
								actionType: ActionTypes.CREATE_VARIABLE_SET
							})
						);
					});
				}

				if (group && group.groupLabel) {
					const { group: newGroup } = await context.api.data.variables().createGroup({
						projectId: Number(projectId),
						group,
						...(variableNames && {
							variables: variableNames.map(variableName => ({ variableName }))
						}),
						...(newVariableSet !== undefined && {
							set: { setName: newVariableSet.setName }
						})
					});

					const actionPayload: ActionPayload<CreateVariableSetAction> = {
						variableSet: newVariableSet,
						group: newGroup,
						variableNames,
						groupNames,
						...(isInteger(destinationIndex) ? { destinationIndex } : {})
					};

					dispatch(createVariableSetAction({ ...actionPayload, localChanges: true }));
					dispatch(createVariableSetAction(actionPayload));
				} else {
					const actionPayload: ActionPayload<CreateVariableSetAction> = {
						variableSet: newVariableSet,
						variableNames,
						groupNames,
						...(isInteger(destinationIndex) ? { destinationIndex } : {})
					};

					dispatch(createVariableSetAction({ ...actionPayload, localChanges: true }));
					dispatch(createVariableSetAction(actionPayload));
				}

				// REBUILD ENTRIES
				dispatch(rebuildEntries());
				// REBUILD INVALID ANALYSES
				dispatch(rebuildAnalyses());

				setTimeout(() => {
					const card = document.querySelector(
						`[data-scroll-id="${newVariableSet.setName}"]`
					);

					card?.scrollIntoView();
					variableSetCardPulse().dispatch({
						setName: newVariableSet.setName
					});
				}, 250);

				callbacks.successCallback?.(newVariableSet);
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

const updateVariableSetAction = (
	payload: ActionPayload<UpdateVariableSetAction>
): UpdateVariableSetAction => ({
	type: ActionTypes.UPDATE_VARIABLE_SET,
	payload
});

export const updateVariableSet =
	(variableSet: PartialVariableSet): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.UPDATE_VARIABLE_SET, dispatch });

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId) {
				const storeVariablesData = byProjectId[projectId].initial;
				const initialVariableSet =
					storeVariablesData.variableSets.byName[variableSet.setName];

				const partialApiVariableSet = preparePartialApiVariableSet(
					initialVariableSet,
					variableSet
				);

				const { variableSet: updatedVariableSet, transactionId } = await context.api.data
					.variables()
					.updateVariableSet({
						projectId: Number(projectId),
						set: partialApiVariableSet
					});

				/**
				 * WORKAROUND BECAUSE BACKEND DOES NOT RETURN `setOrder` FROM `updateVariableSet` METHOD
				 * TODO: REMOVE WHEN FIXED (IF EVER)
				 */
				updatedVariableSet.setOrder = initialVariableSet.setOrder;

				dispatch(updateVariableSetAction({ variableSet: updatedVariableSet }));

				// REBUILD FILTERS
				dispatch(rebuildFilters());
				// REBUILD INVALID ANALYSES
				dispatch(rebuildAnalyses());

				// ASYNC CALL -> TRACK PROGRESS WITH `transactionId`
				if (transactionId) {
					await dispatch(
						doAfterTransaction({
							transactionId,
							actionType: EntriesActionTypes.RECALCULATING_ENTRIES,
							callback: () => {
								// MARK ENTRIES FOR REFETCH
								dispatch(setRefetchEntries());
							}
						})
					);
				} else {
					/*
					 * Concurrency case:
					 * someone else modified the aggregation rules leading to dataset re-calculation
					 */
					const aggregationRulesChanged = !isEqual(
						initialVariableSet.aggregationRules,
						updatedVariableSet.aggregationRules
					);

					if (aggregationRulesChanged) {
						// MARK ENTRIES FOR REFETCH
						dispatch(setRefetchEntries());
					}
				}
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

const deleteVariableSetAction = (
	payload: ActionPayload<DeleteVariableSetAction>
): DeleteVariableSetAction => ({
	type: payload.localChanges
		? ActionTypes.DELETE_VARIABLE_SET_LOCAL
		: ActionTypes.DELETE_VARIABLE_SET,
	payload
});

export const deleteVariableSet =
	(setName: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.DELETE_VARIABLE_SET, dispatch });

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId } = getState().data.variables;

			if (projectId) {
				await context.api.data.variables().deleteVariableSet({
					projectId: Number(projectId),
					set: {
						setName
					}
				});

				dispatch(deleteVariableSetAction({ setName, localChanges: true }));
				dispatch(deleteVariableSetAction({ setName }));

				// REBUILD ENTRIES
				dispatch(rebuildEntries());
				// REBUILD FILTERS
				dispatch(rebuildFilters());
				// REBUILD INVALID ANALYSES
				dispatch(rebuildAnalyses());
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

/**
 * ==========================
 * 	CRUD - AGGREGATION RULES
 * ==========================
 */

const createVariableSetAggregationRuleAction = (
	payload: ActionPayload<CreateVariableSetAggregationRuleAction>
): CreateVariableSetAggregationRuleAction => ({
	type: ActionTypes.CREATE_VARIABLE_SET_AGGREGATION_RULE,
	payload
});

export const createVariableSetAggregationRule =
	(input: { setName: string; aggregationRule: AggregationRule }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.CREATE_VARIABLE_SET_AGGREGATION_RULE,
			dispatch
		});

		const { setName, aggregationRule } = input;

		try {
			activity.begin({ payload: { ruleName: aggregationRule.name } });

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId) {
				const storeVariablesData = byProjectId[projectId].initial;
				const initialVariableSet = storeVariablesData.variableSets.byName[setName];

				const { name, ...newAggregationRule } = aggregationRule;

				const { variableSet: newVariableSet, transactionId } = await context.api.data
					.variables()
					.createVariableSetAggregationRule({
						projectId: Number(projectId),
						set: {
							setName
						},
						rule: newAggregationRule
					});

				/**
				 * WORKAROUND BECAUSE BACKEND DOES NOT RETURN `setOrder` FROM `updateVariableSet` METHOD
				 * TODO: REMOVE WHEN FIXED (IF EVER)
				 */
				newVariableSet.setOrder = initialVariableSet.setOrder;

				dispatch(createVariableSetAggregationRuleAction({ variableSet: newVariableSet }));

				// REBUILD ENTRIES
				dispatch(rebuildEntries());
				// REBUILD FILTERS
				dispatch(rebuildFilters());
				// REBUILD INVALID ANALYSES
				dispatch(rebuildAnalyses());

				// ASYNC CALL -> TRACK PROGRESS WITH `transactionId`
				if (transactionId) {
					await dispatch(
						doAfterTransaction({
							transactionId,
							actionType: EntriesActionTypes.RECALCULATING_ENTRIES,
							callback: () => {
								// MARK ENTRIES FOR REFETCH
								dispatch(setRefetchEntries());
							}
						})
					);
				}
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: { ruleName: aggregationRule.name }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

const updateVariableSetAggregationRuleAction = (
	payload: ActionPayload<UpdateVariableSetAggregationRuleAction>
): UpdateVariableSetAggregationRuleAction => ({
	type: ActionTypes.UPDATE_VARIABLE_SET_AGGREGATION_RULE,
	payload
});

export const updateVariableSetAggregationRule =
	(input: { setName: string; aggregationRule: AggregationRule }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_VARIABLE_SET_AGGREGATION_RULE,
			dispatch
		});

		const { setName, aggregationRule } = input;

		try {
			activity.begin({ payload: { ruleName: aggregationRule.name } });

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId) {
				const storeVariablesData = byProjectId[projectId].initial;
				const initialVariableSet = storeVariablesData.variableSets.byName[setName];

				const { variableSet: updatedVariableSet, transactionId } = await context.api.data
					.variables()
					.updateVariableSetAggregationRule({
						projectId: Number(projectId),
						set: {
							setName
						},
						rule: aggregationRule
					});

				/**
				 * WORKAROUND BECAUSE BACKEND DOES NOT RETURN `setOrder` FROM `updateVariableSet` METHOD
				 * TODO: REMOVE WHEN FIXED (IF EVER)
				 */
				updatedVariableSet.setOrder = initialVariableSet.setOrder;

				dispatch(
					updateVariableSetAggregationRuleAction({ variableSet: updatedVariableSet })
				);

				// REBUILD FILTERS
				dispatch(rebuildFilters());
				// REBUILD INVALID ANALYSES
				dispatch(rebuildAnalyses());

				// ASYNC CALL -> TRACK PROGRESS WITH `transactionId`
				if (transactionId) {
					await dispatch(
						doAfterTransaction({
							transactionId,
							actionType: EntriesActionTypes.RECALCULATING_ENTRIES,
							callback: () => {
								// MARK ENTRIES FOR REFETCH
								dispatch(setRefetchEntries());
							}
						})
					);
				}
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: { ruleName: aggregationRule.name }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

const deleteVariableSetAggregationRuleAction = (
	payload: ActionPayload<DeleteVariableSetAggregationRuleAction>
): DeleteVariableSetAggregationRuleAction => ({
	type: ActionTypes.DELETE_VARIABLE_SET_AGGREGATION_RULE,
	payload
});

export const deleteVariableSetAggregationRule =
	(payload: ActionPayload<DeleteVariableSetAggregationRuleAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.DELETE_VARIABLE_SET_AGGREGATION_RULE,
			dispatch
		});

		const { setName, ruleName } = payload;

		try {
			activity.begin({ payload: { ruleName } });

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId } = getState().data.variables;

			if (projectId) {
				await context.api.data.variables().deleteVariableSetAggregationRule({
					projectId: Number(projectId),
					set: {
						setName
					},
					rule: {
						name: ruleName
					}
				});

				dispatch(deleteVariableSetAggregationRuleAction({ setName, ruleName }));

				// REBUILD ENTRIES
				dispatch(rebuildEntries());
				// REBUILD FILTERS
				dispatch(rebuildFilters());
				// REBUILD INVALID ANALYSES
				dispatch(rebuildAnalyses());
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: { ruleName }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

/**
 * =========================================
 * 	ACTIONS: MOVE
 * 	SOURCE: VARIABLE(S) / GROUP(S) / SET(S)
 * 	TARGET: ROOT LIST
 * =========================================
 */

export const moveVariableAction = (
	payload: ActionPayload<MoveVariableAction>
): MoveVariableAction => ({
	type: payload.localChanges ? ActionTypes.MOVE_VARIABLE_LOCAL : ActionTypes.MOVE_VARIABLE,
	payload
});

export const moveVariable =
	(payload: ActionPayload<MoveVariableAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.MOVE_VARIABLE, dispatch });

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				// PREDICTIVE UPDATES (IMPROVE UX)
				dispatch(moveVariableAction({ ...payload, localChanges: true }));

				const { variableName, sourceIndex, destinationIndex, setName } = payload;

				await context.api.data.variables().moveVariable({
					projectId: Number(projectId),
					variable: { variableName },
					sourceIndex,
					destinationIndex,
					...(setName !== undefined && { set: { setName } })
				});

				// APPLY CHANGES
				dispatch(moveVariableAction(payload));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const moveGroupAction = (payload: ActionPayload<MoveGroupAction>): MoveGroupAction => ({
	type: payload.localChanges ? ActionTypes.MOVE_GROUP_LOCAL : ActionTypes.MOVE_GROUP,
	payload
});

export const moveGroup =
	(payload: ActionPayload<MoveGroupAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.MOVE_GROUP, dispatch });

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				// PREDICTIVE UPDATES (IMPROVE UX)
				dispatch(moveGroupAction({ ...payload, localChanges: true }));

				const { groupName, sourceIndex, destinationIndex, setName } = payload;

				await context.api.data.variables().moveGroup({
					projectId: Number(projectId),
					group: { groupName },
					sourceIndex,
					destinationIndex,
					...(setName !== undefined && { set: { setName } })
				});

				// APPLY CHANGES
				dispatch(moveGroupAction(payload));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const moveVariableSetAction = (
	payload: ActionPayload<MoveVariableSetAction>
): MoveVariableSetAction => ({
	type: payload.localChanges
		? ActionTypes.MOVE_VARIABLE_SET_LOCAL
		: ActionTypes.MOVE_VARIABLE_SET,
	payload
});

export const moveVariableSet =
	(payload: ActionPayload<MoveVariableSetAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.MOVE_VARIABLE_SET, dispatch });

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				// PREDICTIVE UPDATES (IMPROVE UX)
				dispatch(moveVariableSetAction({ ...payload, localChanges: true }));

				const { setName, sourceIndex, destinationIndex } = payload;

				await context.api.data.variables().moveVariableSet({
					projectId: Number(projectId),
					set: { setName },
					sourceIndex,
					destinationIndex
				});

				// APPLY CHANGES
				dispatch(moveVariableSetAction(payload));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const moveVariableSetAggregationRuleAction = (
	payload: ActionPayload<MoveVariableSetAggregationRuleAction>
): MoveVariableSetAggregationRuleAction => ({
	type: payload.localChanges
		? ActionTypes.MOVE_VARIABLE_SET_AGGREGATION_RULE_LOCAL
		: ActionTypes.MOVE_VARIABLE_SET_AGGREGATION_RULE,
	payload
});

export const moveVariableSetAggregationRule =
	(payload: ActionPayload<MoveVariableSetAggregationRuleAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.MOVE_VARIABLE_SET_AGGREGATION_RULE,
			dispatch
		});

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				// PREDICTIVE UPDATES (IMPROVE UX)
				dispatch(moveVariableSetAggregationRuleAction({ ...payload, localChanges: true }));

				const { setName, ruleName, sourceIndex, destinationIndex } = payload;

				await context.api.data.variables().moveVariableSetAggregationRule({
					projectId: Number(projectId),
					set: { setName },
					rule: { name: ruleName },
					sourceIndex,
					destinationIndex
				});

				// APPLY CHANGES
				dispatch(moveVariableSetAggregationRuleAction(payload));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

/**
 * ==========================================
 * 	ACTIONS: ADD/MOVE/REMOVE
 * 	SOURCE: VARIABLE(S)
 * 	TARGET: FROM/TO GROUP(S)
 * ==========================================
 */

export const addVariableToGroupAction = (
	payload: ActionPayload<AddVariableToGroupAction>
): AddVariableToGroupAction => ({
	type: payload.localChanges
		? ActionTypes.ADD_VARIABLE_TO_GROUP_LOCAL
		: ActionTypes.ADD_VARIABLE_TO_GROUP,
	payload
});

export const addVariableToGroup =
	(payload: ActionPayload<AddVariableToGroupAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.ADD_VARIABLE_TO_GROUP, dispatch });

		const { variableName, groupName, destinationIndex, from, setName } = payload;

		const toOrFromSet = !!setName || !!from?.set;

		try {
			activity.begin({ payload: { variableName, toOrFromSet } });

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				// PREDICTIVE UPDATES (IMPROVE UX)
				dispatch(addVariableToGroupAction({ ...payload, localChanges: true }));

				groupCountPulse().dispatch({ groupName });

				// BACKEND NEEDS THE VARIABLE TO BE IN THE SAME SET IN ORDER TO `addVariableToGroup`;
				if (setName !== undefined && from?.mainList) {
					const addVariableToSetInput: AddVariableToSetInput = {
						projectId: Number(projectId),
						variable: { variableName },
						set: { setName }
					};

					const { transactionId } = await context.api.data
						.variables()
						.addVariableToSet(addVariableToSetInput);

					await dispatch(
						doAfterTransaction({
							transactionId,
							actionType: ActionTypes.ADD_VARIABLE_TO_GROUP
						})
					);
				}

				// VARIABLE IS INSIDE A SET - NEEDS REMOVAL INTO MAIN LIST
				if (from?.set) {
					const { setName, parentGroup } = from.set;

					// VARIABLE IS INSIDE GROUP - REMOVE IT INTO VARIABLE SET
					if (parentGroup !== undefined) {
						const removeVariableFromGroupInput: RemoveVariableFromGroupInput = {
							projectId: Number(projectId),
							variable: { variableName },
							group: { groupName: parentGroup },
							set: { setName }
						};

						await context.api.data
							.variables()
							.removeVariableFromGroup(removeVariableFromGroupInput);
					}

					// REMOVE VARIABLE FROM SET INTO MAIN LIST
					const removeVariableFromSetInput: RemoveVariableFromSetInput = {
						projectId: Number(projectId),
						variable: { variableName },
						set: { setName }
					};

					const { transactionId } = await context.api.data
						.variables()
						.removeVariableFromSet(removeVariableFromSetInput);

					await dispatch(
						doAfterTransaction({
							transactionId,
							actionType: ActionTypes.ADD_VARIABLE_TO_GROUP
						})
					);
				}

				await context.api.data.variables().addVariableToGroup({
					projectId: Number(projectId),
					variable: { variableName },
					group: { groupName },
					...(destinationIndex !== undefined && { destinationIndex }),
					...(setName !== undefined && { set: { setName } })
				});

				// APPLY CHANGES
				dispatch(addVariableToGroupAction(payload));

				// REBUILD ENTRIES
				dispatch(rebuildEntries());
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: { variableName, toOrFromSet }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const addVariablesToGroupAction = (
	payload: ActionPayload<AddVariablesToGroupAction>
): AddVariablesToGroupAction => ({
	type: payload.localChanges
		? ActionTypes.ADD_VARIABLES_TO_GROUP_LOCAL
		: ActionTypes.ADD_VARIABLES_TO_GROUP,
	payload
});

export const addVariablesToGroup =
	(payload: ActionPayload<AddVariablesToGroupAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.ADD_VARIABLES_TO_GROUP, dispatch });

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				if (payload.predictiveUpdates) {
					// PREDICTIVE UPDATES (IMPROVE UX)
					dispatch(addVariablesToGroupAction({ ...payload, localChanges: true }));
				}

				const { variableNames, groupName, destinationIndex, setName } = payload;
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const variablesDataForMove = prepareVariablesDataForMove({
					selected: { variableNames, groupNames: [] },
					variablesData
				});

				await asyncForEach(variableNames, async variable => {
					const location = variablesDataForMove.variables.find(
						item => item.variableName === variable
					);

					// if current variable is inside another group we need to remove it to main level first
					if (location?.from?.group && !location?.from?.set) {
						await context.api.data.variables().removeVariableFromGroup({
							projectId: Number(projectId),
							group: location.from.group,
							variable: { variableName: variable }
						});
						dispatch(
							removeVariableFromGroupAction({
								groupName: location.from.group.groupName,
								variableName: variable
							})
						);
					}

					// BACKEND NEEDS THE VARIABLE TO BE IN THE SAME SET IN ORDER TO `addVariableToGroup`;
					// if variable is in the same set this is not necessary
					if (setName !== undefined && !location?.from?.group && !location?.from?.set) {
						const addVariableToSetInput: AddVariableToSetInput = {
							projectId: Number(projectId),
							variable: { variableName: variable },
							set: { setName }
						};

						const { transactionId } = await context.api.data
							.variables()
							.addVariableToSet(addVariableToSetInput);

						await dispatch(
							doAfterTransaction({
								transactionId,
								actionType: ActionTypes.ADD_VARIABLES_TO_GROUP
							})
						);
					}

					// VARIABLE IS INSIDE A SET - NEEDS REMOVAL INTO MAIN LIST
					if (location?.from?.set?.setName && location?.from?.set?.setName !== setName) {
						const { group } = location.from.set;

						// VARIABLE IS INSIDE GROUP - REMOVE IT INTO VARIABLE SET
						if (group !== undefined) {
							const removeVariableFromGroupInput: RemoveVariableFromGroupInput = {
								projectId: Number(projectId),
								variable: { variableName: variable },
								group,
								set: { setName: location.from.set.setName }
							};

							await context.api.data
								.variables()
								.removeVariableFromGroup(removeVariableFromGroupInput);
						}

						// REMOVE VARIABLE FROM SET INTO MAIN LIST
						const removeVariableFromSetInput: RemoveVariableFromSetInput = {
							projectId: Number(projectId),
							variable: { variableName: variable },
							set: { setName: location.from.set.setName }
						};

						const { transactionId } = await context.api.data
							.variables()
							.removeVariableFromSet(removeVariableFromSetInput);

						await dispatch(
							doAfterTransaction({
								transactionId,
								actionType: ActionTypes.ADD_VARIABLES_TO_GROUP
							})
						);

						if (setName !== undefined) {
							const addVariableToSetInput: AddVariableToSetInput = {
								projectId: Number(projectId),
								variable: { variableName: variable },
								set: { setName }
							};
							const { transactionId } = await context.api.data
								.variables()
								.addVariableToSet(addVariableToSetInput);

							await dispatch(
								doAfterTransaction({
									transactionId,
									actionType: ActionTypes.ADD_VARIABLES_TO_GROUP
								})
							);
						}
					}
				});

				await context.api.data.variables().addVariablesToGroup({
					projectId: Number(projectId),
					variables: variableNames.map(variableName => ({ variableName })),
					group: { groupName },
					...(destinationIndex !== undefined && { destinationIndex }),
					...(setName !== undefined && { set: { setName } })
				});

				// APPLY CHANGES
				dispatch(addVariablesToGroupAction(payload));

				// REBUILD ENTRIES
				dispatch(rebuildEntries());

				groupCountPulse().dispatch({ groupName });
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

const removeVariableFromGroupAction = (
	payload: ActionPayload<RemoveVariableFromGroupAction>
): RemoveVariableFromGroupAction => ({
	type: payload.localChanges
		? ActionTypes.REMOVE_VARIABLE_FROM_GROUP_LOCAL
		: ActionTypes.REMOVE_VARIABLE_FROM_GROUP,
	payload
});

export const removeVariableFromGroup =
	(payload: ActionPayload<RemoveVariableFromGroupAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.REMOVE_VARIABLE_FROM_GROUP, dispatch });

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				const { variableName, groupName, destinationIndex } = payload;

				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const setName = isVariableGroupInSet({ groupName, variablesData });

				// REGISTER `setName` IN `payload` TO USE IT IN REDUCER
				if (setName !== undefined) payload.setName = setName;

				// PREDICTIVE UPDATES (IMPROVE UX)
				dispatch(removeVariableFromGroupAction({ ...payload, localChanges: true }));

				groupCountPulse().dispatch({ groupName });

				await context.api.data.variables().removeVariableFromGroup({
					projectId: Number(projectId),
					variable: { variableName },
					group: { groupName },
					...(destinationIndex !== undefined && { destinationIndex }),
					...(setName !== undefined && { set: { setName } })
				});

				// APPLY CHANGES
				dispatch(removeVariableFromGroupAction(payload));

				// REBUILD ENTRIES
				dispatch(rebuildEntries());
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const moveVariableInsideGroupAction = (
	payload: ActionPayload<MoveVariableInsideGroupAction>
): MoveVariableInsideGroupAction => ({
	type: payload.localChanges
		? ActionTypes.MOVE_VARIABLE_INSIDE_GROUP_LOCAL
		: ActionTypes.MOVE_VARIABLE_INSIDE_GROUP,
	payload
});

export const moveVariableInsideGroup =
	(payload: ActionPayload<MoveVariableInsideGroupAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.MOVE_VARIABLE_INSIDE_GROUP, dispatch });

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				// PREDICTIVE UPDATES (IMPROVE UX)
				dispatch(moveVariableInsideGroupAction({ ...payload, localChanges: true }));

				const { variableName, sourceIndex, destinationIndex, groupName } = payload;

				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const setName = isVariableGroupInSet({ groupName, variablesData });

				await context.api.data.variables().moveVariableInsideGroup({
					projectId: Number(projectId),
					variable: { variableName },
					group: { groupName },
					sourceIndex,
					destinationIndex,
					...(setName !== undefined && { set: { setName } })
				});

				// APPLY CHANGES
				dispatch(moveVariableInsideGroupAction(payload));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const moveVariableBetweenGroupsAction = (
	payload: ActionPayload<MoveVariableBetweenGroupsAction>
): MoveVariableBetweenGroupsAction => ({
	type: payload.localChanges
		? ActionTypes.MOVE_VARIABLE_BETWEEN_GROUPS_LOCAL
		: ActionTypes.MOVE_VARIABLE_BETWEEN_GROUPS,
	payload
});

export const moveVariableBetweenGroups =
	(payload: ActionPayload<MoveVariableBetweenGroupsAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.MOVE_VARIABLE_BETWEEN_GROUPS,
			dispatch
		});

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				// PREDICTIVE UPDATES (IMPROVE UX)
				dispatch(moveVariableBetweenGroupsAction({ ...payload, localChanges: true }));

				groupCountPulse().dispatch({ groupName: payload.sourceGroupName });
				groupCountPulse().dispatch({ groupName: payload.destinationGroupName });

				const { variableName, destinationIndex, sourceGroupName, destinationGroupName } =
					payload;

				await context.api.data.variables().moveVariableBetweenGroups({
					projectId: Number(projectId),
					variable: { variableName },
					sourceGroup: {
						groupName: sourceGroupName
					},
					destinationGroup: {
						groupName: destinationGroupName
					},
					...(destinationIndex !== undefined && { destinationIndex })
				});

				// APPLY CHANGES
				dispatch(moveVariableBetweenGroupsAction(payload));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const moveVariablesBetweenGroupsAction = (
	payload: ActionPayload<MoveVariablesBetweenGroupsAction>
): MoveVariablesBetweenGroupsAction => ({
	type: payload.localChanges
		? ActionTypes.MOVE_VARIABLES_BETWEEN_GROUPS_LOCAL
		: ActionTypes.MOVE_VARIABLES_BETWEEN_GROUPS,
	payload
});

export const moveVariablesBetweenGroups =
	(payload: ActionPayload<MoveVariablesBetweenGroupsAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.MOVE_VARIABLES_BETWEEN_GROUPS,
			dispatch
		});

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				if (payload.predictiveUpdates) {
					// PREDICTIVE UPDATES (IMPROVE UX)
					dispatch(moveVariablesBetweenGroupsAction({ ...payload, localChanges: true }));
				}

				const {
					variableNames,
					sourceGroupName,
					destinationGroupName,
					destinationIndex,
					setName
				} = payload;

				await context.api.data.variables().moveVariablesBetweenGroups({
					projectId: Number(projectId),
					variables: variableNames.map(variableName => ({ variableName })),
					sourceGroup: {
						groupName: sourceGroupName
					},
					destinationGroup: {
						groupName: destinationGroupName
					},
					...(destinationIndex !== undefined && { destinationIndex }),
					...(setName !== undefined && { set: { setName } })
				});

				// APPLY CHANGES
				dispatch(moveVariablesBetweenGroupsAction(payload));

				// REBUILD ENTRIES
				dispatch(rebuildEntries());

				groupCountPulse().dispatch({ groupName: payload.sourceGroupName });
				groupCountPulse().dispatch({ groupName: payload.destinationGroupName });
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: {
					display: true,
					message: getTranslation(dict => dict.variables.move.someElementsNotMoved)
				}
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

/**
 * ==========================================
 * 	ACTIONS: ADD/MOVE/REMOVE
 * 	SOURCE: VARIABLE(S) / GROUP(S)
 * 	TARGET: FROM/TO SET(S)
 * ==========================================
 */

export const addVariableToSetAction = (
	payload: ActionPayload<AddVariableToSetAction>
): AddVariableToSetAction => ({
	type: payload.localChanges
		? ActionTypes.ADD_VARIABLE_TO_SET_LOCAL
		: ActionTypes.ADD_VARIABLE_TO_SET,
	payload
});

export const addVariableToSet =
	(payload: ActionPayload<AddVariableToSetAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.ADD_VARIABLE_TO_SET, dispatch });

		const { variableName, setName, destinationIndex } = payload;

		try {
			activity.begin({ payload: { variableName } });

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				// IN CERTAIN CASES WE KNOW THE MOVE SHOULDN'T HAPPEN
				// i.e: moving entry/calculated variables into series
				const movedVariable = byProjectId[projectId].current.variables.byName[variableName];
				if (
					!(
						movedVariable.entryType === EntryVariableType.Calculated ||
						movedVariable.type === VariableType.Unique
					)
				) {
					// PREDICTIVE UPDATES (IMPROVE UX)
					dispatch(addVariableToSetAction({ ...payload, localChanges: true }));
				}

				variableSetCountPulse().dispatch({ setName });

				const { transactionId } = await context.api.data.variables().addVariableToSet({
					projectId: Number(projectId),
					variable: { variableName },
					set: { setName },
					...(destinationIndex !== undefined && { destinationIndex })
				});

				// APPLY CHANGES
				await dispatch(
					doAfterTransaction({
						transactionId,
						actionType: ActionTypes.ADD_VARIABLE_TO_SET,
						callback: () => {
							dispatch(addVariableToSetAction(payload));
							// REBUILD ENTRIES
							dispatch(rebuildEntries());
						}
					})
				);
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: { variableName }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

const removeVariableFromSetAction = (
	payload: ActionPayload<RemoveVariableFromSetAction>
): RemoveVariableFromSetAction => ({
	type: payload.localChanges
		? ActionTypes.REMOVE_VARIABLE_FROM_SET_LOCAL
		: ActionTypes.REMOVE_VARIABLE_FROM_SET,
	payload
});

export const removeVariableFromSet =
	(payload: ActionPayload<RemoveVariableFromSetAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.REMOVE_VARIABLE_FROM_SET, dispatch });

		const { variableName, setName, destinationIndex, parentGroup } = payload;

		try {
			activity.begin({ payload: { variableName } });

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				// PREDICTIVE UPDATES (IMPROVE UX)
				dispatch(removeVariableFromSetAction({ ...payload, localChanges: true }));

				variableSetCountPulse().dispatch({ setName });

				// BACKEND NEEDS THE VARIABLE TO BE IN THE SAME SET IN ORDER TO `removeVariableFromSet`;
				if (parentGroup) {
					const removeVariableFromGroupInput: RemoveVariableFromGroupInput = {
						projectId: Number(projectId),
						variable: { variableName },
						group: { groupName: parentGroup },
						set: { setName }
					};

					await context.api.data
						.variables()
						.removeVariableFromGroup(removeVariableFromGroupInput);
				}

				const { transactionId } = await context.api.data.variables().removeVariableFromSet({
					projectId: Number(projectId),
					variable: { variableName },
					set: { setName },
					...(destinationIndex !== undefined && { destinationIndex })
				});

				// APPLY CHANGES
				await dispatch(
					doAfterTransaction({
						transactionId,
						actionType: ActionTypes.REMOVE_VARIABLE_FROM_SET,
						callback: () => {
							dispatch(removeVariableFromSetAction(payload));
							// REBUILD ENTRIES
							dispatch(rebuildEntries());
						}
					})
				);
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: { variableName }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const addVariableGroupToSetAction = (
	payload: ActionPayload<AddVariableGroupToSetAction>
): AddVariableGroupToSetAction => ({
	type: payload.localChanges
		? ActionTypes.ADD_VARIABLE_GROUP_TO_SET_LOCAL
		: ActionTypes.ADD_VARIABLE_GROUP_TO_SET,
	payload
});

export const addVariableGroupToSet =
	(payload: ActionPayload<AddVariableGroupToSetAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.ADD_VARIABLE_GROUP_TO_SET, dispatch });

		const { groupName, setName, destinationIndex } = payload;

		try {
			activity.begin({ payload: { groupName } });

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				// PREDICTIVE UPDATES (IMPROVE UX)
				dispatch(addVariableGroupToSetAction({ ...payload, localChanges: true }));

				variableSetCountPulse().dispatch({ setName });

				const { transactionId } = await context.api.data.variables().addVariableGroupToSet({
					projectId: Number(projectId),
					group: { groupName },
					set: { setName },
					...(destinationIndex !== undefined && { destinationIndex })
				});

				// APPLY CHANGES
				await dispatch(
					doAfterTransaction({
						transactionId,
						actionType: ActionTypes.MOVE_VARIABLE_BETWEEN_SETS,
						callback: () => addVariableGroupToSetAction(payload)
					})
				);

				// REBUILD ENTRIES
				dispatch(rebuildEntries());
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: { groupName }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const removeVariableGroupFromSetAction = (
	payload: ActionPayload<RemoveVariableGroupFromSetAction>
): RemoveVariableGroupFromSetAction => ({
	type: payload.localChanges
		? ActionTypes.REMOVE_VARIABLE_GROUP_FROM_SET_LOCAL
		: ActionTypes.REMOVE_VARIABLE_GROUP_FROM_SET,
	payload
});

export const removeVariableGroupFromSet =
	(payload: ActionPayload<RemoveVariableGroupFromSetAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.REMOVE_VARIABLE_GROUP_FROM_SET,
			dispatch
		});

		const { groupName, setName, destinationIndex } = payload;

		try {
			activity.begin({ payload: { groupName } });

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				// PREDICTIVE UPDATES (IMPROVE UX)
				dispatch(removeVariableGroupFromSetAction({ ...payload, localChanges: true }));

				variableSetCountPulse().dispatch({ setName });

				const { transactionId } = await context.api.data
					.variables()
					.removeVariableGroupFromSet({
						projectId: Number(projectId),
						group: {
							groupName
						},
						set: {
							setName
						},
						...(destinationIndex !== undefined && { destinationIndex })
					});

				await dispatch(
					doAfterTransaction({
						transactionId,
						actionType: ActionTypes.REMOVE_VARIABLE_GROUP_FROM_SET,
						callback: () => dispatch(removeVariableGroupFromSetAction(payload))
					})
				);

				// REBUILD ENTRIES
				dispatch(rebuildEntries());
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: { groupName }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const moveVariableBetweenSetsAction = (
	payload: ActionPayload<MoveVariableBetweenSetsAction>
): MoveVariableBetweenSetsAction => ({
	type: payload.localChanges
		? ActionTypes.MOVE_VARIABLE_BETWEEN_SETS_LOCAL
		: ActionTypes.MOVE_VARIABLE_BETWEEN_SETS,
	payload
});

export const moveVariableBetweenSets =
	(payload: ActionPayload<MoveVariableBetweenSetsAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.MOVE_VARIABLE_BETWEEN_SETS, dispatch });

		const { variableName, destinationIndex, sourceSetName, destinationSetName } = payload;

		try {
			activity.begin({ payload: { variableName } });

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				// PREDICTIVE UPDATES (IMPROVE UX)
				dispatch(moveVariableBetweenSetsAction({ ...payload, localChanges: true }));

				const { transactionId } = await context.api.data
					.variables()
					.moveVariableBetweenSets({
						projectId: Number(projectId),
						variable: { variableName },
						sourceSet: {
							setName: sourceSetName
						},
						destinationSet: {
							setName: destinationSetName
						},
						...(destinationIndex !== undefined && { destinationIndex })
					});

				// APPLY CHANGES
				await dispatch(
					doAfterTransaction({
						transactionId,
						actionType: ActionTypes.MOVE_VARIABLE_BETWEEN_SETS,
						callback: () => moveVariableBetweenSetsAction(payload)
					})
				);

				// REBUILD ENTRIES
				dispatch(rebuildEntries());
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: { variableName }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const moveVariablesOrGroupsToSetAction = (
	payload: ActionPayload<MoveVariablesOrGroupsToSetAction>
): MoveVariablesOrGroupsToSetAction => ({
	type: payload.localChanges
		? ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_SET_LOCAL
		: ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_SET,
	payload
});

export const moveVariablesOrGroupsToSet =
	(
		input: {
			variableNames: string[];
			groupNames: string[];
		},
		destinationSetName: string,
		destinationGroupName?: string
	): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_SET,
			dispatch
		});

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const variablesDataForMove = prepareVariablesDataForMove({
					selected: input,
					variablesData
				});

				const dataToMove = prepareApiVariablesDataForMove(variablesDataForMove);

				if (dataToMove.mainList.hasData) {
					const { variables, groups } = dataToMove.mainList;
					await asyncForEach(groups, async group => {
						if (!destinationGroupName) {
							const { transactionId } = await context.api.data
								.variables()
								.addVariableGroupToSet({
									projectId: Number(projectId),
									group: { groupName: group.groupName },
									set: {
										setName: destinationSetName
									}
								});

							await dispatch(
								doAfterTransaction({
									transactionId,
									actionType: ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_SET
								})
							);
						}
					});

					await asyncForEach(variables, async variable => {
						const { transactionId } = await context.api.data
							.variables()
							.addVariableToSet({
								projectId: Number(projectId),
								variable: { variableName: variable.variableName },
								set: {
									setName: destinationSetName
								}
							});

						await dispatch(
							doAfterTransaction({
								transactionId,
								actionType: ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_SET
							})
						);

						if (destinationGroupName) {
							await context.api.data.variables().addVariableToGroup({
								projectId: Number(projectId),
								variable: { variableName: variable.variableName },
								group: { groupName: destinationGroupName },
								set: {
									setName: destinationSetName
								}
							});
						}
					});
				}

				await asyncForEach(dataToMove.variableSets, async variableSetMove => {
					const { setName, variables, groups } = variableSetMove;

					await asyncForEach(groups, async group => {
						if (setName !== destinationSetName) {
							const { transactionId } = await context.api.data
								.variables()
								.moveVariableGroupBetweenSets({
									projectId: Number(projectId),
									group: { groupName: group.groupName },
									sourceSet: { setName: setName },
									destinationSet: { setName: destinationSetName }
								});

							await dispatch(
								doAfterTransaction({
									transactionId,
									actionType: ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_SET
								})
							);
						}
					});

					await asyncForEach(variables, async variable => {
						if (setName === destinationSetName && variable.from?.set?.group) {
							await context.api.data.variables().removeVariableFromGroup({
								projectId: Number(projectId),
								variable: { variableName: variable.variableName },
								group: variable.from?.set?.group,
								set: { setName }
							});
						} else if (setName !== destinationSetName) {
							const { transactionId } = await context.api.data
								.variables()
								.moveVariableBetweenSets({
									projectId: Number(projectId),
									variable: { variableName: variable.variableName },
									sourceSet: { setName: setName },
									destinationSet: { setName: destinationSetName }
								});

							await dispatch(
								doAfterTransaction({
									transactionId,
									actionType: ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_SET
								})
							);
						}
					});
				});

				// APPLY CHANGES
				dispatch(
					moveVariablesOrGroupsToSetAction({
						destinationSetName,
						data: dataToMove,
						localChanges: true
					})
				);
				dispatch(
					moveVariablesOrGroupsToSetAction({
						destinationSetName,
						data: dataToMove
					})
				);

				// REBUILD ENTRIES
				dispatch(rebuildEntries());
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const moveVariablesOrGroupsToRootListAction = (
	payload: ActionPayload<MoveVariablesOrGroupsToRootListAction>
): MoveVariablesOrGroupsToRootListAction => ({
	type: payload.localChanges
		? ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_ROOT_LIST_LOCAL
		: ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_ROOT_LIST,
	payload
});

export const moveVariablesOrGroupsToRootList =
	(input: { variableNames: string[]; groupNames: string[] }, destinationIndex?: number): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_ROOT_LIST,
			dispatch
		});

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const variablesDataForMove = prepareVariablesDataForMove({
					selected: input,
					variablesData
				});

				const dataToMove = prepareApiVariablesDataForMove(variablesDataForMove);

				if (dataToMove.mainList.hasData) {
					const { variables } = dataToMove.mainList;

					await asyncForEach(variables, async variable => {
						if (variable.from?.group) {
							await context.api.data.variables().removeVariableFromGroup({
								projectId: Number(projectId),
								variable: { variableName: variable.variableName },
								group: { groupName: variable.from.group.groupName },
								...(destinationIndex !== undefined && { destinationIndex })
							});
						}
					});
				}

				await asyncForEach(dataToMove.variableSets, async variableSetMove => {
					const { variables, groups } = variableSetMove;

					await asyncForEach(groups, async group => {
						if (group.from?.set) {
							const { transactionId } = await context.api.data
								.variables()
								.removeVariableGroupFromSet({
									projectId: Number(projectId),
									group: { groupName: group.groupName },
									set: group.from?.set,
									...(destinationIndex !== undefined && { destinationIndex })
								});

							await dispatch(
								doAfterTransaction({
									transactionId,
									actionType: ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_ROOT_LIST
								})
							);
						}
					});

					await asyncForEach(variables, async variable => {
						// REMOVE VARIABLE FROM SET
						if (variable.from?.set) {
							const { setName, group } = variable.from.set;
							// VARIABLE IS INSIDE GROUP - REMOVE IT INTO VARIABLE SET
							if (group !== undefined) {
								await context.api.data.variables().removeVariableFromGroup({
									projectId: Number(projectId),
									variable: { variableName: variable.variableName },
									group,
									set: { setName }
								});
							}
							// REMOVE VARIABLE FROM SET INTO MAIN LIST
							const { transactionId } = await context.api.data
								.variables()
								.removeVariableFromSet({
									projectId: Number(projectId),
									variable: { variableName: variable.variableName },
									set: { setName },
									...(destinationIndex !== undefined && { destinationIndex })
								});

							await dispatch(
								doAfterTransaction({
									transactionId,
									actionType: ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_ROOT_LIST
								})
							);
						}
						// REMOVE VARIABLE FROM GROUP
						if (variable.from?.group) {
							const { groupName } = variable.from.group;
							await context.api.data.variables().removeVariableFromGroup({
								projectId: Number(projectId),
								variable: { variableName: variable.variableName },
								group: { groupName },
								...(destinationIndex !== undefined && { destinationIndex })
							});
						}
					});
				});

				// APPLY CHANGES
				dispatch(
					moveVariablesOrGroupsToRootListAction({
						data: dataToMove,
						localChanges: true
					})
				);
				dispatch(
					moveVariablesOrGroupsToRootListAction({
						data: dataToMove
					})
				);

				// REBUILD ENTRIES
				dispatch(rebuildEntries());
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const moveVariableGroupBetweenSetsAction = (
	payload: ActionPayload<MoveVariableGroupBetweenSetsAction>
): MoveVariableGroupBetweenSetsAction => ({
	type: payload.localChanges
		? ActionTypes.MOVE_VARIABLE_GROUP_BETWEEN_SETS_LOCAL
		: ActionTypes.MOVE_VARIABLE_GROUP_BETWEEN_SETS,
	payload
});

export const moveVariableGroupBetweenSets =
	(payload: ActionPayload<MoveVariableGroupBetweenSetsAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.MOVE_VARIABLE_GROUP_BETWEEN_SETS,
			dispatch
		});

		const { groupName, destinationIndex, sourceSetName, destinationSetName } = payload;

		try {
			activity.begin({ payload: { groupName } });

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				// PREDICTIVE UPDATES (IMPROVE UX)
				dispatch(moveVariableGroupBetweenSetsAction({ ...payload, localChanges: true }));

				const { transactionId } = await context.api.data
					.variables()
					.moveVariableGroupBetweenSets({
						projectId: Number(projectId),
						group: { groupName },
						sourceSet: {
							setName: sourceSetName
						},
						destinationSet: {
							setName: destinationSetName
						},
						...(destinationIndex !== undefined && { destinationIndex })
					});

				await dispatch(
					doAfterTransaction({
						transactionId,
						actionType: ActionTypes.MOVE_VARIABLE_GROUP_BETWEEN_SETS,
						callback: () => dispatch(moveVariableGroupBetweenSetsAction(payload))
					})
				);

				// APPLY CHANGES

				// REBUILD ENTRIES
				dispatch(rebuildEntries());
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: { groupName }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

/**
 * ================
 * 	BULK DELETE
 * ================
 */

export const deleteBulkVariablesDataAction = (
	payload: ActionPayload<DeleteBulkVariablesDataAction>
): DeleteBulkVariablesDataAction => ({
	type: ActionTypes.DELETE_BULK_VARIABLES_DATA,
	payload
});

export const deleteBulkVariablesData =
	(input: { variableNames: string[]; groupNames: string[]; variableSetNames: string[] }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.DELETE_BULK_VARIABLES_DATA,
			dispatch
		});

		try {
			activity.begin();

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const variablesDataForDeleteBulk = prepareVariablesDataForBulkDelete({
					selected: input,
					variablesData
				});

				const dataToDelete = prepareApiVariablesDataForBulkDelete(
					variablesDataForDeleteBulk
				);

				let transactionId: number | undefined;

				if (dataToDelete.mainList.hasData) {
					const { variables, groups } = dataToDelete.mainList;

					const output = await context.api.data.variables().deleteBulkVariablesData({
						projectId: Number(projectId),
						variables: variables.map(({ variableName }) => ({
							variableName
						})),
						groups: groups.map(({ groupName }) => ({
							groupName
						}))
					});

					transactionId = output.transactionId;

					// APPLY CHANGES
					dispatch(
						deleteBulkVariablesDataAction({
							variables,
							groups,
							variableSets: []
						})
					);
				}

				await asyncForEach(dataToDelete.variableSets, async variableSetDeleteBulkData => {
					const { setName, variables, groups, deleteSet } = variableSetDeleteBulkData;

					if (deleteSet) {
						await context.api.data.variables().deleteVariableSet({
							projectId: Number(projectId),
							set: {
								setName
							}
						});

						// APPLY CHANGES
						dispatch(deleteVariableSetAction({ setName, localChanges: true }));
						dispatch(deleteVariableSetAction({ setName }));
					} else {
						const output = await context.api.data.variables().deleteBulkVariablesData({
							projectId: Number(projectId),
							variables: variables.map(({ variableName }) => ({
								variableName
							})),
							groups: groups.map(({ groupName }) => ({
								groupName
							})),
							set: {
								setName
							}
						});

						transactionId = output.transactionId;

						// APPLY CHANGES
						dispatch(
							deleteBulkVariablesDataAction({
								variables,
								groups,
								variableSets: []
							})
						);
					}
				});

				// REBUILD ENTRIES
				dispatch(rebuildEntries());
				// REBUILD FILTERS
				dispatch(rebuildFilters());
				// REBUILD INVALID ANALYSES
				dispatch(rebuildAnalyses());

				// ASYNC CALL -> TRACK PROGRESS WITH `transactionId`
				if (transactionId) {
					await dispatch(
						doAfterTransaction({
							transactionId,
							actionType: EntriesActionTypes.RECALCULATING_ENTRIES,
							callback: () => {
								// MARK ENTRIES FOR REFETCH
								dispatch(setRefetchEntries());
							}
						})
					);
				}
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			dispatch(hydrateData());
		}
	};

///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////

/**
 * ===================
 * 	CRUD - CATEGORIES
 * ===================
 */

const createVariableCategoryValueAction = (
	payload: ActionPayload<CreateVariableCategoryValueAction>
): CreateVariableCategoryValueAction => ({
	type: ActionTypes.CREATE_VARIABLE_CATEGORY_VALUE,
	payload
});

const createVariableCategoryValueLocalAction = (
	payload: ActionPayload<CreateVariableCategoryValueLocalAction>
): CreateVariableCategoryValueLocalAction => ({
	type: ActionTypes.CREATE_VARIABLE_CATEGORY_VALUE_LOCAL,
	payload
});

export const createVariableCategoryValue =
	(input: {
		variableName: string;
		categoryValue: VariableCategory;
		predictiveUpdates?: boolean;
	}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.CREATE_VARIABLE_CATEGORY_VALUE,
			dispatch
		});

		const { variableName, categoryValue, predictiveUpdates } = input;

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const setName = isVariableInSet({ variableName, variablesData });

				const initialVariable = storeVariablesData.variables.byName[variableName];

				const { id, ...parsedCategoryValue } = parseVariableCategory(categoryValue);

				if (predictiveUpdates) {
					// PREDICTIVE UPDATES (IMPROVE UX)
					dispatch(
						createVariableCategoryValueLocalAction({ variableName, categoryValue })
					);
				}

				const { variable: updatedVariable } = await context.api.data
					.variables()
					.createVariableCategoryValue({
						projectId: Number(projectId),
						variable: { variableName },
						value: parsedCategoryValue,
						...(setName !== undefined && { set: { setName } })
					});

				// APPLY CHANGES
				dispatch(createVariableCategoryValueAction({ variable: updatedVariable }));

				dispatch(handleVariableChanges({ initialVariable, variable: updatedVariable }));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

const createVariableCategoryValuesAction = (
	payload: ActionPayload<CreateVariableCategoryValuesAction>
): CreateVariableCategoryValuesAction => ({
	type: ActionTypes.CREATE_VARIABLE_CATEGORY_VALUES,
	payload
});

export const createVariableCategoryValues =
	(input: { variableName: string; categoryValues: VariableCategory[] }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.CREATE_VARIABLE_CATEGORY_VALUES,
			dispatch
		});

		const { variableName, categoryValues } = input;

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const setName = isVariableInSet({ variableName, variablesData });

				const initialVariable = storeVariablesData.variables.byName[variableName];

				const parsedCategoryValues = parseVariableCategories(categoryValues);

				const { variable: updatedVariable } = await context.api.data
					.variables()
					.createVariableCategoryValues({
						projectId: Number(projectId),
						variable: { variableName },
						values: parsedCategoryValues,
						...(setName !== undefined && { set: { setName } })
					});

				dispatch(createVariableCategoryValuesAction({ variable: updatedVariable }));

				dispatch(handleVariableChanges({ initialVariable, variable: updatedVariable }));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

const updateVariableCategoryValueAction = (
	payload: ActionPayload<UpdateVariableCategoryValueAction>
): UpdateVariableCategoryValueAction => ({
	type: ActionTypes.UPDATE_VARIABLE_CATEGORY_VALUE,
	payload
});

export const updateVariableCategoryValue =
	(input: { variableName: string; categoryValue: VariableCategory }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_VARIABLE_CATEGORY_VALUE,
			dispatch
		});

		const { variableName, categoryValue } = input;

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const setName = isVariableInSet({ variableName, variablesData });

				const initialVariable = storeVariablesData.variables.byName[variableName];
				const { categories } = initialVariable;

				const initialCategoryValue =
					categories[categories.findIndex(c => c.id === categoryValue.id)];

				const partialCategoryValue = preparePartialVariableCategory(
					initialCategoryValue,
					parseVariableCategory(categoryValue)
				);

				const {
					variable: updatedVariable,
					transactionId,
					transactionIds
				} = await context.api.data.variables().updateVariableCategoryValue({
					projectId: Number(projectId),
					variable: { variableName },
					value: partialCategoryValue,
					...(setName !== undefined && { set: { setName } })
				});

				dispatch(updateVariableCategoryValueAction({ variable: updatedVariable }));

				dispatch(
					handleVariableChanges(
						{ initialVariable, variable: updatedVariable },
						{ omitRefetchEntries: transactionId !== undefined }
					)
				);

				// ASYNC CALL -> TRACK PROGRESS WITH `transactionId`
				if (transactionId) {
					await dispatch(
						doAfterTransaction({
							transactionId,
							actionType: EntriesActionTypes.RECALCULATING_ENTRIES,
							callback: () => {
								// MARK ENTRIES FOR REFETCH
								dispatch(setRefetchEntries());
							}
						})
					);
				}

				if (transactionIds) {
					const transactions = transactionIds.map(
						(id, idx, tIds) => async () =>
							dispatch(
								doAfterTransaction({
									transactionId: id,
									actionType: EntriesActionTypes.RECALCULATING_ENTRIES,
									callback: () => {
										if (idx === tIds.length - 1) {
											// MARK ENTRIES FOR REFETCH
											dispatch(setRefetchEntries());
										}
									},
									errorCallback: e => {
										throw e;
									}
								})
							)
					);

					await executePromisesInSequence<void>(transactions);
				}
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

const deleteVariableCategoryValueAction = (
	payload: ActionPayload<DeleteVariableCategoryValueAction>
): DeleteVariableCategoryValueAction => ({
	type: ActionTypes.DELETE_VARIABLE_CATEGORY_VALUE,
	payload
});

export const deleteVariableCategoryValue =
	(input: {
		variableName: string;
		categoryValueId: string;
		removeDatasetValues: boolean;
	}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.DELETE_VARIABLE_CATEGORY_VALUE,
			dispatch
		});

		const { variableName, categoryValueId, removeDatasetValues } = input;
		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const setName = isVariableInSet({ variableName, variablesData });

				const initialVariable = storeVariablesData.variables.byName[variableName];

				const {
					variable: updatedVariable,
					transactionId,
					transactionIds
				} = await context.api.data.variables().deleteVariableCategoryValue({
					projectId: Number(projectId),
					variable: { variableName },
					value: { id: categoryValueId },
					...(removeDatasetValues && { updateDataset: true }),
					...(setName !== undefined && { set: { setName } })
				});

				dispatch(deleteVariableCategoryValueAction({ variable: updatedVariable }));

				dispatch(
					handleVariableChanges(
						{ initialVariable, variable: updatedVariable },
						{ omitRefetchEntries: transactionId !== undefined }
					)
				);

				// ASYNC CALL -> TRACK PROGRESS WITH `transactionId`
				if (transactionId) {
					await dispatch(
						doAfterTransaction({
							transactionId,
							actionType: EntriesActionTypes.RECALCULATING_ENTRIES,
							callback: () => {
								// MARK ENTRIES FOR REFETCH
								dispatch(setRefetchEntries());
							}
						})
					);
				}

				if (transactionIds) {
					const transactions = transactionIds.map(
						(id, idx, tIds) => async () =>
							dispatch(
								doAfterTransaction({
									transactionId: id,
									actionType: EntriesActionTypes.RECALCULATING_ENTRIES,
									callback: () => {
										if (idx === tIds.length - 1) {
											// MARK ENTRIES FOR REFETCH
											dispatch(setRefetchEntries());
										}
									},
									errorCallback: e => {
										throw e;
									}
								})
							)
					);

					await executePromisesInSequence<void>(transactions);
				}
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

const deleteVariableCategoryValuesAction = (
	payload: ActionPayload<DeleteVariableCategoryValuesAction>
): DeleteVariableCategoryValuesAction => ({
	type: ActionTypes.DELETE_VARIABLE_CATEGORY_VALUES,
	payload
});

export const deleteVariableCategoryValues =
	(input: {
		variableName: string;
		categoryValueIds: string[];
		removeDatasetValues: boolean;
	}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.DELETE_VARIABLE_CATEGORY_VALUES,
			dispatch
		});

		const { variableName, categoryValueIds, removeDatasetValues } = input;

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const setName = isVariableInSet({ variableName, variablesData });

				const initialVariable = storeVariablesData.variables.byName[variableName];

				const {
					variable: updatedVariable,
					transactionId,
					transactionIds
				} = await context.api.data.variables().deleteVariableCategoryValues({
					projectId: Number(projectId),
					variable: { variableName },
					values: categoryValueIds.map(categoryValueId => ({ id: categoryValueId })),
					...(removeDatasetValues && { updateDataset: true }),
					...(setName !== undefined && { set: { setName } })
				});

				dispatch(deleteVariableCategoryValuesAction({ variable: updatedVariable }));

				dispatch(
					handleVariableChanges(
						{ initialVariable, variable: updatedVariable },
						{ omitRefetchEntries: transactionId !== undefined }
					)
				);

				// ASYNC CALL -> TRACK PROGRESS WITH `transactionId`
				if (transactionId) {
					await dispatch(
						doAfterTransaction({
							transactionId,
							actionType: EntriesActionTypes.RECALCULATING_ENTRIES,
							callback: () => {
								// MARK ENTRIES FOR REFETCH
								dispatch(setRefetchEntries());
							}
						})
					);
				}

				if (transactionIds) {
					const transactions = transactionIds.map(
						(id, idx, tIds) => async () =>
							dispatch(
								doAfterTransaction({
									transactionId: id,
									actionType: EntriesActionTypes.RECALCULATING_ENTRIES,
									callback: () => {
										if (idx === tIds.length - 1) {
											// MARK ENTRIES FOR REFETCH
											dispatch(setRefetchEntries());
										}
									},
									errorCallback: e => {
										throw e;
									}
								})
							)
					);

					await executePromisesInSequence<void>(transactions);
				}
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const moveVariableCategoryValueAction = (
	payload: ActionPayload<MoveVariableCategoryValueAction>
): MoveVariableCategoryValueAction => ({
	type: payload.localChanges
		? ActionTypes.MOVE_VARIABLE_CATEGORY_VALUE_LOCAL
		: ActionTypes.MOVE_VARIABLE_CATEGORY_VALUE,
	payload
});

export const moveVariableCategoryValue =
	(payload: ActionPayload<MoveVariableCategoryValueAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.MOVE_VARIABLE_CATEGORY_VALUE,
			dispatch
		});

		const { variableName, categoryValueId, sourceIndex, destinationIndex } = payload;

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const setName = isVariableInSet({ variableName, variablesData });

				const initialVariable = storeVariablesData.variables.byName[variableName];

				// PREDICTIVE UPDATES (IMPROVE UX)
				dispatch(moveVariableCategoryValueAction({ ...payload, localChanges: true }));

				const { variable: updatedVariable } = await context.api.data
					.variables()
					.moveVariableCategoryValue({
						projectId: Number(projectId),
						variable: { variableName },
						value: { id: categoryValueId },
						sourceIndex,
						destinationIndex,
						...(setName !== undefined && { set: { setName } })
					});

				// APPLY CHANGES
				dispatch(moveVariableCategoryValueAction(payload));

				dispatch(
					handleVariableChanges(
						{ initialVariable, variable: updatedVariable },
						{ omitRefetchEntries: true, omitRebuildEntries: true }
					)
				);
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData({ omitRebuildEntries: true }))
			});
		}
	};

export const moveVariableCategoryValuesAction = (
	payload: ActionPayload<MoveVariableCategoryValuesAction>
): MoveVariableCategoryValuesAction => ({
	type: payload.localChanges
		? ActionTypes.MOVE_VARIABLE_CATEGORY_VALUES_LOCAL
		: ActionTypes.MOVE_VARIABLE_CATEGORY_VALUES,
	payload
});

export const moveVariableCategoryValues =
	(payload: ActionPayload<MoveVariableCategoryValuesAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.MOVE_VARIABLE_CATEGORY_VALUES,
			dispatch
		});

		const { variableName, categoryValueIds, destination } = payload;

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const setName = isVariableInSet({ variableName, variablesData });

				const initialVariable = storeVariablesData.variables.byName[variableName];

				if (payload.predictiveUpdates) {
					// PREDICTIVE UPDATES (IMPROVE UX)
					dispatch(moveVariableCategoryValuesAction({ ...payload, localChanges: true }));
				}

				const { variable: updatedVariable } = await context.api.data
					.variables()
					.moveVariableCategoryValues({
						projectId: Number(projectId),
						variable: { variableName },
						values: categoryValueIds.map(categoryValueId => ({ id: categoryValueId })),
						destination,
						...(setName !== undefined && { set: { setName } })
					});

				// APPLY CHANGES
				dispatch(
					moveVariableCategoryValuesAction({ ...payload, variable: updatedVariable })
				);

				dispatch(handleVariableChanges({ initialVariable, variable: updatedVariable }));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

const mergeVariableCategoryValuesAction = (
	payload: ActionPayload<MergeVariableCategoryValuesAction>
): MergeVariableCategoryValuesAction => ({
	type: ActionTypes.MERGE_VARIABLE_CATEGORY_VALUES,
	payload
});

export const mergeVariableCategoryValues =
	(input: {
		variableName: string;
		categoryValueIds: string[];
		targetCategoryValue: Pick<VariableCategory, 'id'> | Omit<VariableCategory, 'id'>;
	}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.MERGE_VARIABLE_CATEGORY_VALUES,
			dispatch
		});

		const { variableName, categoryValueIds, targetCategoryValue } = input;

		try {
			activity.begin();

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const setName = isVariableInSet({ variableName, variablesData });

				const initialVariable = storeVariablesData.variables.byName[variableName];

				const {
					variable: updatedVariable,
					transactionId,
					transactionIds
				} = await context.api.data.variables().mergeVariableCategoryValues({
					projectId: Number(projectId),
					variable: { variableName },
					values: categoryValueIds.map(categoryValueId => ({ id: categoryValueId })),
					targetValue: targetCategoryValue,
					...(setName !== undefined && { set: { setName } })
				});

				dispatch(mergeVariableCategoryValuesAction({ variable: updatedVariable }));

				dispatch(
					handleVariableChanges(
						{ initialVariable, variable: updatedVariable },
						{ omitRefetchEntries: transactionId !== undefined }
					)
				);

				// ASYNC CALL -> TRACK PROGRESS WITH `transactionId`
				if (transactionId) {
					await dispatch(
						doAfterTransaction({
							transactionId,
							actionType: EntriesActionTypes.RECALCULATING_ENTRIES,
							callback: () => {
								// MARK ENTRIES FOR REFETCH
								dispatch(setRefetchEntries());
							}
						})
					);
				}

				if (transactionIds) {
					const transactions = transactionIds.map(
						(id, idx, tIds) => async () =>
							dispatch(
								doAfterTransaction({
									transactionId: id,
									actionType: EntriesActionTypes.RECALCULATING_ENTRIES,
									callback: () => {
										if (idx === tIds.length - 1) {
											// MARK ENTRIES FOR REFETCH
											dispatch(setRefetchEntries());
										}
									},
									errorCallback: e => {
										throw e;
									}
								})
							)
					);

					await executePromisesInSequence<void>(transactions);
				}
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const activateVariableCategoriesAction = (
	payload: ActionPayload<ActivateVariableCategoriesAction>
): ActivateVariableCategoriesAction => ({
	type: ActionTypes.ACTIVATE_VARIABLE_CATEGORIES,
	payload
});

export const activateVariableCategories =
	(input: { variableName: string }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.ACTIVATE_VARIABLE_CATEGORIES,
			dispatch
		});

		const { variableName } = input;

		try {
			activity.begin({ payload: { variableName } });

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const setName = isVariableInSet({ variableName, variablesData });

				const initialVariable = storeVariablesData.variables.byName[variableName];

				const output = await context.api.data.variables().updateVariable({
					projectId: Number(projectId),
					variable: {
						variableName,
						fixedCategories: FixedCategories.Yes
					},
					...(setName !== undefined && { set: { setName } })
				});

				let updatedVariable = output.variable;

				if (initialVariable.categories.length) {
					const output = await context.api.data.variables().createVariableCategoryValues({
						projectId: Number(projectId),
						variable: { variableName },
						values: initialVariable.categories,
						...(setName !== undefined && { set: { setName } })
					});

					updatedVariable = output.variable;
				}

				dispatch(activateVariableCategoriesAction({ variable: updatedVariable }));

				dispatch(handleVariableChanges({ initialVariable, variable: updatedVariable }));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: { variableName }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const deactivateVariableCategoriesAction = (
	payload: ActionPayload<DeactivateVariableCategoriesAction>
): DeactivateVariableCategoriesAction => ({
	type: ActionTypes.DEACTIVATE_VARIABLE_CATEGORIES,
	payload
});

export const deactivateVariableCategories =
	(input: { variableName: string; removeDatasetValues: boolean }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.DEACTIVATE_VARIABLE_CATEGORIES,
			dispatch
		});

		const { variableName, removeDatasetValues } = input;

		try {
			activity.begin({ payload: { variableName } });

			// REGISTER ACTIVITY TO THROTTLE
			throttledActivity.register({ activityId: activity.id });

			const { projectId, byProjectId } = getState().data.variables;

			if (projectId && byProjectId[projectId]) {
				const storeVariablesData = byProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const setName = isVariableInSet({ variableName, variablesData });

				const initialVariable = storeVariablesData.variables.byName[variableName];

				const { variable: updatedVariable } = await context.api.data
					.variables()
					.updateVariable({
						projectId: Number(projectId),
						variable: {
							variableName,
							fixedCategories: FixedCategories.No
						},
						patchDependencies: true,
						...(removeDatasetValues && { updateDataset: true }),
						...(setName !== undefined && { set: { setName } })
					});

				dispatch(deactivateVariableCategoriesAction({ variable: updatedVariable }));

				dispatch(handleVariableChanges({ initialVariable, variable: updatedVariable }));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: { variableName }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////

const rebuildVariablesDataCategoriesAction = (
	payload: ActionPayload<RebuildVariablesDataCategoriesAction>
): RebuildVariablesDataCategoriesAction => ({
	type: ActionTypes.REBUILD_VARIABLES_DATA_CATEGORIES,
	payload
});

export const rebuildVariablesDataCategories =
	(payload: ActionPayload<RebuildVariablesDataCategoriesAction>): Thunk =>
	dispatch => {
		dispatch(rebuildVariablesDataCategoriesAction(payload));

		// REBUILD FILTERS
		dispatch(rebuildFilters());
		// REBUILD INVALID ANALYSES
		dispatch(rebuildAnalyses());
	};

export const setVariableName = (
	payload: ActionPayload<SetVariableNameAction>
): SetVariableNameAction => ({
	type: ActionTypes.SET_VARIABLE_NAME,
	payload
});

export const setDestinationSetName = (
	payload: ActionPayload<SetDestinationSetNameAction>
): SetDestinationSetNameAction => ({
	type: ActionTypes.SET_DESTINATION_SET_NAME,
	payload
});

export const setDestinationGroupName = (
	payload: ActionPayload<SetDestinationGroupNameAction>
): SetDestinationGroupNameAction => ({
	type: ActionTypes.SET_DESTINATION_GROUP_NAME,
	payload
});

// FILTERS
export const setVariablesFilters = (
	payload: ActionPayload<SetVariablesFiltersAction>
): SetVariablesFiltersAction => ({
	type: ActionTypes.SET_VARIABLES_FILTERS,
	payload
});

// VIEW OPTION
export const setVariablesViewOption = (
	payload: ActionPayload<SetVariablesViewOptionAction>
): SetVariablesViewOptionAction => ({
	type: ActionTypes.SET_VARIABLES_VIEW_OPTION,
	payload
});

// SEARCH TERM
export const setVariablesSearchTerm = (
	payload: ActionPayload<SetVariablesSearchTermAction>
): SetVariablesSearchTermAction => ({
	type: ActionTypes.SET_VARIABLES_SEARCH_TERM,
	payload
});

// DRAGGING
export const setDraggingVariableName = (
	payload: ActionPayload<SetDraggingVariableNameAction>
): SetDraggingVariableNameAction => ({
	type: ActionTypes.SET_DRAGGING_VARIABLE_NAME,
	payload
});

///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////

/**
 * Smart checks if:
 * - dataset table needs rebuild
 * - dataset rows need refetch
 * - forms need refetch
 *
 * & rebuilds filters if needed
 */
export const hydrateData =
	(options?: { omitRebuildEntries: boolean }): Thunk =>
	async (dispatch, getState, context) => {
		const { omitRebuildEntries = false } = options ?? {};
		const {
			variables: { projectId, byProjectId },
			forms: { byProjectId: formsByProjectId },
			dependencies: { byProjectId: dependenciesByProjectId },
			entries: {
				errors: { data: entriesErrors }
			}
		} = getState().data;

		if (projectId && byProjectId[projectId]) {
			const { variablesData } = await context.api.data.variables().getVariables({
				projectId: Number(projectId)
			});

			const { activities } = getState().ui.activities;

			if (activities.length) {
				const actionTypesWithHydrateData = getActionTypesWithHydrateData();

				const hydrateDataWillFireAgain = activities.some(activity =>
					actionTypesWithHydrateData.includes(activity.type as ActionTypes)
				);

				// another `hydrateData()` will fire - discard current action
				if (hydrateDataWillFireAgain) return;
			}

			const initialVariablesData = buildVariablesDataFromStoreData(
				byProjectId[projectId].initial
			);

			const variablesDataDifferences = getVariablesDataDifferences(
				initialVariablesData,
				variablesData
			);

			const shouldRefetch = {
				forms: false,
				entries: false,
				dependencies: false
			};

			/**
			 * if [variables | groups | variableSets] got [created | deleted] => refetch forms
			 */
			if (
				variablesDataDifferences.variables.created.length ||
				variablesDataDifferences.variables.deleted.length ||
				variablesDataDifferences.groups.created.length ||
				variablesDataDifferences.groups.deleted.length ||
				variablesDataDifferences.variableSets.created.length ||
				variablesDataDifferences.variableSets.deleted.length
			) {
				shouldRefetch.forms = true;
			}

			/**
			 * mark `dependencies` for refetch if variables got [created | deleted]
			 */
			if (
				variablesDataDifferences.variables.created.length ||
				variablesDataDifferences.variables.deleted.length
			) {
				shouldRefetch.dependencies = true;
			}

			/**
			 * order changed
			 */
			if (variablesDataDifferences.order.changed) {
				shouldRefetch.forms = true;
			}

			/**
			 * created variables
			 */
			for (
				let index = 0;
				index < variablesDataDifferences.variables.created.length;
				index++
			) {
				const variable = variablesDataDifferences.variables.created[index];

				const isCalculated = variable.entryType === EntryVariableType.Calculated;
				const hasCases = variable.cases.length > 0;

				if (isCalculated && hasCases) {
					shouldRefetch.entries = true;
					break;
				}
			}

			/**
			 * updated variables
			 */
			for (
				let index = 0;
				index < variablesDataDifferences.variables.updated.length;
				index++
			) {
				const { from, to } = variablesDataDifferences.variables.updated[index];

				const typeChanged = from.type !== to.type;
				const obligatoryChanged = from.obligatory !== to.obligatory;
				const personalDataChanged = from.personalData !== to.personalData;
				const categoriesChanged = !isEqual(from.categories, to.categories);

				if (typeChanged) {
					shouldRefetch.forms = true;
					shouldRefetch.entries = true;
					shouldRefetch.dependencies = true;
				} else {
					const isCalculated = to.entryType === EntryVariableType.Calculated;

					if (isCalculated) {
						const calculationCasesChanged = !isEqual(from.cases, to.cases);

						if (calculationCasesChanged) shouldRefetch.entries = true;
					}
				}

				if (from.label !== to.label) shouldRefetch.forms = true;
				if (categoriesChanged || obligatoryChanged || personalDataChanged) {
					shouldRefetch.dependencies = true;
				}
			}

			/**
			 * deleted variables
			 */
			for (
				let index = 0;
				index < variablesDataDifferences.variables.deleted.length;
				index++
			) {
				const variable = variablesDataDifferences.variables.deleted[index];

				const wasCalculated = variable.entryType === EntryVariableType.Calculated;
				const hadCases = variable.cases.length > 0;

				if (wasCalculated && hadCases) {
					shouldRefetch.entries = true;
					break;
				}
			}

			/**
			 * updated groups
			 */
			for (let index = 0; index < variablesDataDifferences.groups.updated.length; index++) {
				const { from, to } = variablesDataDifferences.groups.updated[index];

				if (from.groupLabel !== to.groupLabel) {
					shouldRefetch.forms = true;
					break;
				}
			}

			/**
			 * updated variableSets
			 */
			for (
				let index = 0;
				index < variablesDataDifferences.variableSets.updated.length;
				index++
			) {
				const { from, to } = variablesDataDifferences.variableSets.updated[index];

				const aggregationRulesChanged = !isEqual(
					from.aggregationRules,
					to.aggregationRules
				);

				if (aggregationRulesChanged) shouldRefetch.entries = true;
				if (from.setLabel !== to.setLabel) shouldRefetch.forms = true;
			}

			const { fetched: areFormsFetched, refetch: refetchForms } = formsByProjectId[projectId];
			const { fetched: areDependenciesFetched, refetch: refetchDependencies } =
				dependenciesByProjectId[projectId];

			const shouldRefetchForms =
				areFormsFetched && (refetchForms || shouldRefetch.forms || shouldRefetch.entries);

			const shouldRefetchDependencies =
				areDependenciesFetched && (refetchDependencies || shouldRefetch.dependencies);

			batch(() => {
				const variablesDataChanged = !isEqual(initialVariablesData, variablesData);

				if (variablesDataChanged)
					dispatch(getVariablesAction({ projectId, variablesData }));

				// REBUILD ENTRIES
				!omitRebuildEntries && dispatch(rebuildEntries());
				// REBUILD FILTERS
				dispatch(rebuildFilters());
				// REBUILD INVALID ANALYSES
				dispatch(rebuildAnalyses());

				if (shouldRefetchForms) dispatch(getForms());

				if (shouldRefetch.entries || !!entriesErrors) {
					// MARK ENTRIES FOR REFETCH
					dispatch(setRefetchEntries());
				}

				if (shouldRefetchDependencies) dispatch(getDependencies());
			});
		}
	};

/**
 * Rebuilds
 * - entries table
 * - filters
 * - analyses
 *
 * Mark to refetch
 * - forms
 * - entries
 * - dependencies
 */
export const handleVariableChanges =
	(
		input: {
			initialVariable: Variable;
			variable: Variable;
		},
		options?: {
			omitRefetchEntries?: boolean;
			omitRebuildEntries?: boolean;
		}
	): Thunk =>
	async dispatch => {
		const { initialVariable, variable } = input;
		const { omitRefetchEntries = false, omitRebuildEntries = false } = options ?? {};

		batch(() => {
			// REBUILD ENTRIES
			!omitRebuildEntries && dispatch(rebuildEntries());
			// REBUILD FILTERS
			dispatch(rebuildFilters());
			// REBUILD INVALID ANALYSES
			dispatch(rebuildAnalyses());

			const updatedFields = objectDifference(initialVariable, variable);

			const typeChanged = updatedFields.type !== undefined;
			const labelChanged = updatedFields.label !== undefined;
			const categoriesChanged = updatedFields.categories !== undefined;
			const calculationCasesChanged = updatedFields.cases !== undefined;
			const targetTypeChanged = initialVariable.targetType !== variable.targetType;
			const obligatoryChanged = updatedFields.obligatory !== undefined;
			const personalDataChanged = updatedFields.personalData !== undefined;

			const shouldRefetch = {
				forms: false,
				entries: false,
				dependencies: false
			};

			if (typeChanged) {
				shouldRefetch.forms = true;
				shouldRefetch.entries = true;
				shouldRefetch.dependencies = true;
			}
			if (categoriesChanged || obligatoryChanged || personalDataChanged) {
				shouldRefetch.dependencies = true;
			}
			if (labelChanged) shouldRefetch.forms = true;
			if (calculationCasesChanged || targetTypeChanged) shouldRefetch.entries = true;

			if (shouldRefetch.forms) {
				// MARK FORMS FOR REFETCH
				dispatch(setRefetchForms());
			}
			if (shouldRefetch.entries && !omitRefetchEntries) {
				// MARK ENTRIES FOR REFETCH
				dispatch(setRefetchEntries());
			}
			if (shouldRefetch.dependencies) {
				// MARK DEPENDENCIES FOR REFETCH
				dispatch(setRefetchDependencies());
			}
		});
	};
