import { useController } from 'react-hook-form';
import { TimeDurationFormItem } from '../data/useProjectData/useProjectData';
import { FormControl } from '../EntryForm';
import { TimeDurationInput } from './time-duration/TimeDurationInput';

interface Props {
	formItem: TimeDurationFormItem;
	control: FormControl;
	onError: (name: string, error: string | undefined) => void;
}

export const TimeDurationVariableInput = ({ control, formItem, onError }: Props) => {
	const { field, fieldState } = useController({
		name: formItem.variable.variableName,
		control
	});

	return (
		<TimeDurationInput
			hideFromUi={formItem.hideFromUi}
			value={field.value}
			maxTimeUnit={formItem.variable.durationFormat.maxTimeUnit}
			minTimeUnit={formItem.variable.durationFormat.minTimeUnit}
			label={formItem.variable.variableLabel}
			obligatory={formItem.variable.obligatory}
			onChange={field.onChange}
			onBlur={field.onBlur}
			onError={error => onError(field.name, error)}
			error={fieldState.error?.message}
			ref={field.ref}
			description={formItem.variable.description}
			variableName={formItem.variable.variableName}
		/>
	);
};
