import { useController } from 'react-hook-form';
import { FormControl } from '../EntryForm';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { OptionalDescriptionTooltip } from '../component/OptionalDescriptionTooltip';
import { CheckboxGroup } from '../component/CheckboxGroup';
import { useFilteringDependencies } from './useFilteringDependencies';
import { CategoryMultipleFixedFormItem } from '../data/useProjectData/useProjectData';
import { useEffect, useMemo } from 'react';
import { intersection } from '../utils/setUtils';
import clsx from 'clsx';

interface Props {
	control: FormControl;
	item: CategoryMultipleFixedFormItem;
}

export const CategoryMultipleFixedVariableInput = ({ item, control }: Props) => {
	const variable = item.variable;

	const { field, fieldState } = useController({
		name: item.variable.variableName,
		control
	});

	const filteredCategories = useFilteringDependencies({
		variable,
		dependencies: item.dependencies,
		control
	});

	const options = useMemo(
		() =>
			variable.allowedCategories
				.filter(category => filteredCategories.has(category.value))
				.map(category => ({
					label: category.label,
					value: category.value
				})),
		[variable.allowedCategories, filteredCategories]
	);

	useEffect(() => {
		if (variable.entryType === 'calculated') {
			return;
		}
		const selectedValues = new Set(field.value);
		const allowedValues = new Set(options.map(option => option.value));
		const legallySelectedValues = intersection(selectedValues, allowedValues);
		if (selectedValues.size !== legallySelectedValues.size) {
			field.onChange(Array.from(legallySelectedValues));
		}
	}, [options, field.value]);

	if (item.layoutType === 'dropdown') {
		const selectedOptions = options.filter(option =>
			(field.value || []).includes(option.value)
		);

		return (
			<CreatableSelect
				className={clsx(item.hideFromUi && 'hidden')}
				label={item.variable.variableLabel}
				placeholder={variable.obligatory ? 'Select at least one' : 'Select'}
				values={selectedOptions}
				items={options}
				error={fieldState.error?.message?.toString()}
				onBlur={field.onBlur}
				LabelSuffix={<OptionalDescriptionTooltip description={variable.description} />}
				onValuesSelected={values => {
					field.onChange(values);
				}}
				required={variable.obligatory}
				hasMultipleValues
				allowCreate={false}
			/>
		);
	}

	return (
		<CheckboxGroup
			className={clsx(item.hideFromUi && 'hidden')}
			categories={options}
			allowCreate={false}
			control={control}
			label={item.variable.variableLabel}
			variableName={variable.variableName}
			description={variable.description}
			obligatory={variable.obligatory}
			orientation={item.layoutType}
		/>
	);
};
