import { Svgs } from 'environment';
import { CollaboratorsViewOptions } from 'store/data/collaborators';

import { CollaboratorsTableCheckedState } from 'pages/Collaborators/CollaboratorsPage.controller';
import { Header } from 'components/Header';
import { Dropdown } from 'components/UI/Dropdown';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Button } from 'components/UI/Interactables/Button';
import { Typography } from 'components/UI/Typography';
import {
	useTranslation,
	useCollaboratorsSearchTerm,
	useCollaboratorsViewOption,
	usePermissions,
	useIsProjectOwner,
	useSubscription
} from 'hooks/store';

interface Props {
	isDataFetched: boolean;
	checkedState: CollaboratorsTableCheckedState;
	onEditCollaborator: (userId: string) => void;
	onInviteCollaborator: () => void;
	onCreateOrganization: () => void;
	onMoveSelected: () => void;
	onDeleteSelected: () => void;
	projectId?: string | null;
	setShowHistory: (value: boolean) => void;
	showHistory: boolean;
	isHistoryFeatureFlagEnabled: boolean;
}

export function CollaboratorsHeader({
	isDataFetched,
	checkedState,
	onEditCollaborator,
	onInviteCollaborator,
	onCreateOrganization,
	onMoveSelected,
	onDeleteSelected,
	projectId,
	setShowHistory,
	showHistory,
	isHistoryFeatureFlagEnabled
}: Props) {
	const { translate } = useTranslation();

	const { canAddCollaborator } = usePermissions(projectId ? { projectId } : undefined);

	const [searchTerm, setSearchTerm] = useCollaboratorsSearchTerm();

	const [viewOption, setViewOption] = useCollaboratorsViewOption();

	const isGridView = viewOption === CollaboratorsViewOptions.GRID;
	const isTableView = viewOption === CollaboratorsViewOptions.TABLE;

	const isProjectOwner = useIsProjectOwner();
	const [subscription] = useSubscription();
	const { isOrganizationAdmin, isSuperAdmin, isAdmin } =
		subscription.data.userTypesAndRoles.ledidiEnterprise;

	const canViewHistory =
		isHistoryFeatureFlagEnabled &&
		(isProjectOwner || isOrganizationAdmin || isSuperAdmin || isAdmin);

	function toggleView() {
		setShowHistory(false);
		setViewOption(isGridView ? CollaboratorsViewOptions.TABLE : CollaboratorsViewOptions.GRID);
	}

	function clearFilters() {
		if (searchTerm !== '') setSearchTerm('');
	}

	const canAddSelectedRows = checkedState.checked.collaborators.length === 1;
	const canEditRow = checkedState.checked.collaborators.length === 1;
	const canDeleteSelectedRows =
		!checkedState.isSelf && !checkedState.isDefaultOrganization && checkedState.inTotal > 0;

	const areFiltersActive = searchTerm.trim().length > 0;

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={({ addButton }) =>
					isDataFetched && (
						<Flex>
							<SearchInput
								usedInHeader
								placeholder={translate(
									({ collaborators }) =>
										collaborators.list.header.inputPlaceholder.project
								)}
								term={searchTerm}
								onChangeTerm={setSearchTerm}
							/>

							{canAddCollaborator && (
								<Flex>
									<Dropdown
										toggleComponent={({ ref, toggle }) => (
											<div ref={ref}>
												{addButton({
													label: translate(
														dict =>
															dict.collaborators.collaboratorsHeader
																.new
													),
													action: toggle
												})}
											</div>
										)}
										width={16}
										offset={{ top: 0, right: 0 }}
									>
										<Dropdown.Item
											dataTestId="addCollaborators"
											title={translate(
												dict =>
													dict.collaborators.collaboratorsHeader
														.addCollaborators
											)}
											onClick={onInviteCollaborator}
										/>
										<Dropdown.Item
											dataTestId="newGroup"
											title={translate(
												dict =>
													dict.collaborators.collaboratorsHeader.newGroup
											)}
											onClick={onCreateOrganization}
										/>
									</Dropdown>
								</Flex>
							)}
						</Flex>
					)
				}
			/>
			<Header.Title
				title={
					showHistory
						? translate(({ collaborators }) => collaborators.list.historyTitle)
						: translate(({ collaborators }) => collaborators.list.title)
				}
				component={
					isDataFetched && (
						<Flex>
							{/* CLEAR FILTERS */}
							{areFiltersActive && (
								<Button
									title={translate(
										dict => dict.collaborators.collaboratorsHeader.clearFilters
									)}
									variant={v => v.link}
									marginOffset={{ right: 2.4 }}
									onClick={clearFilters}
								/>
							)}

							{isTableView && checkedState.inTotal > 0 && (
								<Flex align={a => a.center} marginOffset={{ right: 5.6 }}>
									{/* NUMBER OF ROWS SELECTED */}
									<Typography.Paragraph marginOffset={{ right: 2.4 }}>
										{checkedState.inTotal}{' '}
										{translate(
											dict => dict.collaborators.collaboratorsHeader.selected
										)}
									</Typography.Paragraph>

									{canEditRow && (
										<Icon
											title={translate(dict => dict.buttons.edit)}
											svg={Svgs.Edit}
											variant={v => v.buttonActive}
											marginOffset={{ right: 0.8 }}
											onClick={() =>
												onEditCollaborator(
													checkedState.checked.collaborators[0]
												)
											}
										/>
									)}
									{/*
										ADD SELECTED COLLABORATORS TO ORGANIZATION (EXISTING OR NEW)
									*/}
									{canAddSelectedRows && (
										<Icon
											title={translate(
												dict =>
													dict.collaborators.collaboratorsHeader.moveTo
											)}
											svg={Svgs.FolderAdd}
											variant={v => v.buttonActive}
											marginOffset={{ right: 0.8 }}
											onClick={onMoveSelected}
										/>
									)}

									{/*
										DELETE ONE OR MANY
									*/}
									{canDeleteSelectedRows && (
										<Icon
											svg={Svgs.Delete}
											variant={v => v.buttonActive}
											onClick={onDeleteSelected}
										/>
									)}
								</Flex>
							)}

							<Icon
								title={
									isGridView
										? translate(
												dict =>
													dict.collaborators.collaboratorsHeader.viewTable
										  )
										: translate(
												dict =>
													dict.collaborators.collaboratorsHeader.viewCards
										  )
								}
								svg={isGridView ? Svgs.ViewList : Svgs.ViewGrid}
								variant={v => v.buttonActive}
								onClick={toggleView}
							/>

							{canViewHistory && (
								<Icon
									title={translate(
										dict => dict.collaborators.collaboratorsHeader.viewHistory
									)}
									svg={Svgs.Clock}
									variant={v => v.buttonActive}
									active={showHistory}
									onClick={() => setShowHistory(!showHistory)}
								/>
							)}
						</Flex>
					)
				}
			/>
		</>
	);
}
