import { StringMap } from 'types/index';

export { sendRequest, RequestTypes } from './network';
export * from './Contentful';

export const {
	REACT_APP_API_URL_PROJECT: PROJECTS_URL,
	REACT_APP_API_URL_STATS: STATISTICS_URL,
	REACT_APP_API_URL_REPORT: ANALYSIS_URL,
	REACT_APP_API_URL_USER: USER_URL,
	REACT_APP_API_URL_DATAENTRY: DATA_URL,
	REACT_APP_API_URL_USER_ACCOUNT: USER_ACCOUNT_URL,
	REACT_APP_API_URL_NOTIFICATIONS: NOTIFICATIONS_URL,
	REACT_APP_API_URL_XLSXCSV: EXCEL_TO_CSV_URL,
	REACT_APP_API_URL_TEMPLATES: TEMPLATES_URL,
	REACT_APP_API_URL_UPLOAD_FILE: UPLOAD_FILE
} = process.env as StringMap;
