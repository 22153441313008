import type {
	AnalysisV2,
	CompareNumericResultsV2,
	TukeyResultsV2,
	KruskalResultsV2,
	TwoWayManovaResultsV2,
	TwoWayAnovaResultsV2,
	MannWhitneyResultsV2,
	IndependentResultsV2,
	ShapiroResultsV2,
	OneWayManovaResultsV2,
	CorrelationsStatisticsResults,
	LinearRegressionResults,
	AnalysisChartState,
	TimeCourseResultsV2,
	MannWhitneyResultsV1,
	OneWayAnovaResultsV2,
	PlotNumericColumnsV2,
	PlotNumericBoxPlotsV2,
	PlotNumericScatterV2,
	GetLogisticRegressionOutputV2,
	FisherResultsV2,
	ChiSquareResultsV2,
	McNemarResultsV2,
	KaplanMeierResultsV2,
	LogRankResultsV2,
	ExploreResultsV2,
	DensityPlotResultsV2,
	CorrelationsResultsV2,
	CrosstabResultsV2,
	AnalysisOutputData,
	FrequenciesDecodedResultsV2,
	ComparePairedResultsV2,
	PairedTTestResultsV2,
	PairedWilcoxonResultsV2
} from 'api/data/analyses';
import type { Action } from 'types/store/types';
import type {
	GenericMap,
	StringArrayMap,
	StringMap,
	SelectItem,
	SelectItemOrGroup
} from 'types/index';

export interface State {
	projectId: string | null;
	byId: AnalysesById;
	byProjectId: AnalysesByProjectId;
	bySnapshotId: AnalysesBySnapshotId;
	refetch: boolean;
	activeColumn: number;
	fullscreen: string;
	plots: AnalysisChartState;
}

export type AnalysesById = GenericMap<AnalysisV2>;

type AnalysesByProjectId = GenericMap<{ active: string[] }>;

type AnalysesBySnapshotId = StringArrayMap;

export type VariablesDataSelectItems = {
	selectItemsMap: GenericMap<SelectItem>;
	selectItems: SelectItemOrGroup[];
	numericSelectItems: SelectItemOrGroup[];
	durationSelectItems: SelectItemOrGroup[];
	integerSelectItems: SelectItemOrGroup[];
	categorySelectItems: SelectItemOrGroup[];
	categoryWithoutAggregationsSelectItems: SelectItemOrGroup[];
	categoryMultipleSelectItems: SelectItemOrGroup[];
	allCategorySelectItems: SelectItemOrGroup[];
	dateSelectItems: SelectItemOrGroup[];
	stringSelectItems: SelectItemOrGroup[];
	uniqueSelectItems: SelectItemOrGroup[];
	selectItemsLabelMap: StringMap;
};

export enum ActionTypes {
	// LOCAL
	CREATE_ANALYSIS = 'data/analyses/CREATE_ANALYSIS',
	UPDATE_ANALYSIS = 'data/analyses/UPDATE_ANALYSIS',
	DELETE_ANALYSIS = 'data/analyses/DELETE_ANALYSIS',
	DELETE_ANALYSES = 'data/analyses/DELETE_ANALYSES',
	DELETE_PROJECT_ANALYSES = 'data/analysis/DELETE_PROJECT_ANALYSES',
	SET_ANALYSIS_ACTIVE_TAB = 'data/analysis/SET_ANALYSIS_ACTIVE_TAB',
	SET_ANALYSIS_CONFIG_PANEL = 'data/analysis/SET_ANALYSIS_CONFIG_PANEL',
	SET_ANALYSIS_FORMATTING = 'data/analysis/SET_ANALYSIS_FORMATTING',
	SET_ANALYSIS_PARAMETERS = 'data/analysis/SET_ANALYSIS_PARAMETERS',
	SET_ANALYSIS_CHART_TYPES = 'data/analysis/SET_ANALYSIS_CHART_TYPES',
	SET_ANALYSIS_OPEN_STATE = 'data/analysis/SET_ANALYSIS_OPEN_STATE',
	SET_REFETCH_ANALYSES = 'data/analysis/SET_REFETCH_ANALYSES',
	SET_REFETCHED_ANALYSES = 'data/analysis/SET_REFETCHED_ANALYSES',

	REBUILD_ANALYSES = 'data/analysis/REBUILD_ANALYSES',
	SET_ANALYSES_COLUMN = 'data/analysis/SET_ANALYSES_COLUMN',
	SET_ANALYSIS_FULLSCREEN = 'data/analysis/SET_ANALYSIS_FULLSCREEN',
	TOGGLE_CHART_PLOT = 'data/analysis/TOGGLE_CHART_PLOT',
	// ASYNC - ANALYSES
	GET_COMPARE_NUMERIC_V1 = 'data/analyses/GET_COMPARE_NUMERIC_V1',
	GET_COMPARE_NUMERIC_V2 = 'data/analyses/GET_COMPARE_NUMERIC_V2',
	GET_CORRELATIONS_V1 = 'data/analyses/GET_CORRELATIONS_V1',
	GET_CORRELATIONS_V2 = 'data/analyses/GET_CORRELATIONS_V2',
	GET_CROSSTAB = 'data/analyses/GET_CROSSTAB',
	GET_CROSSTAB_V2 = 'data/analyses/GET_CROSSTAB_V2',
	GET_EXPLORE = 'data/analyses/GET_EXPLORE',
	GET_EXPLORE_V2 = 'data/analyses/GET_EXPLORE_V2',
	GET_FREQUENCIES = 'data/analyses/GET_FREQUENCIES',
	GET_FREQUENCIES_V2 = 'data/analyses/GET_FREQUENCIES_V2',
	GET_KAPLAN_MEIER = 'data/analyses/GET_KAPLAN_MEIER',
	GET_KAPLAN_MEIER_V2 = 'data/analyses/GET_KAPLAN_MEIER_V2',
	GET_PLOT_NUMERIC_COLUMNS = 'data/analyses/GET_PLOT_NUMERIC_COLUMNS',
	GET_PLOT_NUMERIC_COLUMNS_V2 = 'data/analyses/GET_PLOT_NUMERIC_COLUMNS_V2',
	GET_PLOT_NUMERIC_BOXPLOT = 'data/analyses/GET_PLOT_NUMERIC_BOXPLOT',
	GET_PLOT_NUMERIC_BOXPLOT_V2 = 'data/analyses/GET_PLOT_NUMERIC_BOXPLOT_V2',
	GET_PLOT_NUMERIC_SCATTER = 'data/analyses/GET_PLOT_NUMERIC_SCATTER',
	GET_PLOT_NUMERIC_SCATTER_V2 = 'data/analyses/GET_PLOT_NUMERIC_SCATTER_V2',
	GET_DENSITY_PLOT = 'data/analyses/GET_DENSITY_PLOT',
	GET_DENSITY_PLOT_V2 = 'data/analyses/GET_DENSITY_PLOT_V2',
	GET_TIME_COURSE_V1 = 'data/analyses/GET_TIME_COURSE_V1',
	GET_TIME_COURSE_V2 = 'data/analyses/GET_TIME_COURSE_V2',
	GET_COMPARE_PAIRED = 'data/analyses/GET_COMPARE_PAIRED',
	GET_COMPARE_PAIRED_V2 = 'data/analyses/GET_COMPARE_PAIRED_V2',
	GET_LOGISTIC_REGRESSION = 'data/analyses/GET_LOGISTIC_REGRESSION',
	GET_LOGISTIC_REGRESSION_V2 = 'data/analyses/GET_LOGISTIC_REGRESSION_V2',
	GET_NUMBER_PLOT_XY = 'data/analyses/GET_NUMBER_PLOT_XY',
	GET_COMPARE_PAIRED_USING_SERIES = 'data/analyses/GET_COMPARE_PAIRED_USING_SERIES',
	// ASYNC - STATISTICS
	GET_FISHER_STATISTICS = 'data/analyses/GET_FISHER_STATISTICS',
	GET_CHI_SQUARE_STATISTICS = 'data/analyses/GET_CHI_SQUARE_STATISTICS',
	GET_FISHER_STATISTICS_V2 = 'data/analyses/GET_FISHER_STATISTICS_V2',
	GET_CHI_SQUARE_STATISTICS_V2 = 'data/analyses/GET_CHI_SQUARE_STATISTICS_V2',
	GET_SHAPIRO_STATISTICS_V1 = 'data/analyses/GET_SHAPIRO_STATISTICS_V1',
	GET_SHAPIRO_STATISTICS_V2 = 'data/analyses/GET_SHAPIRO_STATISTICS_V2',
	GET_MANN_WHITNEY_STATISTICS_V1 = 'data/analyses/GET_MANN_WHITNEY_STATISTICS_V1',
	GET_MANN_WHITNEY_STATISTICS_V2 = 'data/analyses/GET_MANN_WHITNEY_STATISTICS_V2',
	GET_INDEPENDENT_STATISTICS_V1 = 'data/analyses/GET_INDEPENDENT_STATISTICS_V1',
	GET_INDEPENDENT_STATISTICS_V2 = 'data/analyses/GET_INDEPENDENT_STATISTICS_V2',
	GET_ONE_WAY_ANOVA_STATISTICS_V1 = 'data/analyses/GET_ONE_WAY_ANOVA_STATISTICS_V1',
	GET_ONE_WAY_ANOVA_STATISTICS_V2 = 'data/analyses/GET_ONE_WAY_ANOVA_STATISTICS_V2',
	GET_PEARSON_STATISTICS_V1 = 'data/analyses/GET_PEARSON_STATISTICS',
	GET_PEARSON_STATISTICS_V2 = 'data/analyses/GET_PEARSON_STATISTICS_V2',
	GET_SPEARMAN_STATISTICS_V1 = 'data/analyses/GET_SPEARMAN_STATISTICS',
	GET_SPEARMAN_STATISTICS_V2 = 'data/analyses/GET_SPEARMAN_STATISTICS_V2',
	GET_LINEAR_REGRESSION_STATISTICS_V1 = 'data/analyses/GET_LINEAR_REGRESSION_STATISTICS',
	GET_LINEAR_REGRESSION_STATISTICS_V2 = 'data/analyses/GET_LINEAR_REGRESSION_STATISTICS_V2',
	GET_ONE_WAY_ANOVA_STATISTICS = 'data/analyses/GET_ONE_WAY_ANOVA_STATISTICS',
	GET_LOG_RANK_TEST_STATISTICS = 'data/analyses/GET_LOG_RANK_TEST_STATISTICS',
	GET_LOG_RANK_TEST_STATISTICS_V2 = 'data/analyses/GET_LOG_RANK_TEST_STATISTICS_V2',
	GET_TUKEY_STATISTICS_V1 = 'data/analyses/GET_TUKEY_STATISTICS_V1',
	GET_TUKEY_STATISTICS_V2 = 'data/analyses/GET_TUKEY_STATISTICS_V2',
	GET_PAIRED_TTEST_STATISTICS = 'data/analyses/GET_PAIRED_TTEST_STATISTICS',
	GET_PAIRED_TTEST_STATISTICS_V2 = 'data/analyses/GET_PAIRED_TTEST_STATISTICS_V2',
	GET_PAIRED_WILCOXON_STATISTICS = 'data/analyses/GET_PAIRED_WILCOXON_STATISTICS',
	GET_PAIRED_WILCOXON_STATISTICS_V2 = 'data/analyses/GET_PAIRED_WILCOXON_STATISTICS_V2',
	GET_KRUSKAL_STATISTICS_V1 = 'data/analyses/GET_KRUSKAL_STATISTICS_V1',
	GET_KRUSKAL_STATISTICS_V2 = 'data/analyses/GET_KRUSKAL_STATISTICS_V2',
	GET_MCNEMAR_STATISTICS = 'data/analyses/GET_MCNEMAR_STATISTICS',
	GET_MCNEMAR_STATISTICS_V2 = 'data/analyses/GET_MCNEMAR_STATISTICS_V2',
	GET_TWO_WAY_MANOVA_STATISTICS_V1 = 'data/analyses/GET_TWO_WAY_MANOVA_STATISTICS_V1',
	GET_TWO_WAY_MANOVA_STATISTICS_V2 = 'data/analyses/GET_TWO_WAY_MANOVA_STATISTICS_V2',
	GET_TWO_WAY_ANOVA_STATISTICS_V1 = 'data/analyses/GET_TWO_WAY_ANOVA_STATISTICS_V1',
	GET_TWO_WAY_ANOVA_STATISTICS_V2 = 'data/analyses/GET_TWO_WAY_ANOVA_STATISTICS_V2',
	GET_ONE_WAY_MANOVA_STATISTICS_V1 = 'data/analyses/GET_ONE_WAY_MANOVA_STATISTICS_V1',
	GET_ONE_WAY_MANOVA_STATISTICS_V2 = 'data/analyses/GET_ONE_WAY_MANOVA_STATISTICS_V2'
}

/*
	============================
				LOCAL
	============================
*/

export type CreateAnalysisAction = Action<
	ActionTypes.CREATE_ANALYSIS,
	{
		analysis: AnalysisV2;
	}
>;

export type UpdateAnalysisAction = Action<
	ActionTypes.UPDATE_ANALYSIS,
	{
		analysis: AnalysisV2;
	}
>;

export type DeleteAnalysisAction = Action<
	ActionTypes.DELETE_ANALYSIS,
	{
		projectId: string;
		analysisId: string;
		snapshotId: string | null;
	}
>;

export type DeleteAnalysesAction = Action<
	ActionTypes.DELETE_ANALYSES,
	{
		projectId: string;
		analysisIds: string[];
	}
>;

export type SetAnalysisActiveTabAction = Action<
	ActionTypes.SET_ANALYSIS_ACTIVE_TAB,
	{
		analysisId: string;
		activeTab: number;
	}
>;

export type SetAnalysisConfigPanelAction = Action<
	ActionTypes.SET_ANALYSIS_CONFIG_PANEL,
	{
		analysisId: string;
		openConfig: boolean;
	}
>;

export type SetAnalysisFormattingAction = Action<
	ActionTypes.SET_ANALYSIS_FORMATTING,
	{
		analysisId: string;
		formatting: boolean;
	}
>;

export type SetAnalysisChartTypesAction = Action<
	ActionTypes.SET_ANALYSIS_CHART_TYPES,
	{
		analysisId: string;
		chartType: boolean;
	}
>;

export type SetAnalysisOpenStateAction = Action<
	ActionTypes.SET_ANALYSIS_OPEN_STATE,
	{
		analysisId: string;
		open: boolean;
	}
>;

export type SetAnalysisParametersAction = Action<
	ActionTypes.SET_ANALYSIS_PARAMETERS,
	{
		analysisId: string;
		parameters: boolean;
	}
>;

export type SetRefetchAnalysesAction = Action<ActionTypes.SET_REFETCH_ANALYSES>;

export type SetRefetchedAnalysesAction = Action<ActionTypes.SET_REFETCHED_ANALYSES>;

export type RebuildAnalysesAction = Action<ActionTypes.REBUILD_ANALYSES, { byId: AnalysesById }>;

export type SetAnalysesColumnAction = Action<
	ActionTypes.SET_ANALYSES_COLUMN,
	{
		column: number;
	}
>;

export type SetAnalysisFullscreenAction = Action<
	ActionTypes.SET_ANALYSIS_FULLSCREEN,
	{
		fullscreen: string;
	}
>;

export type ToggleChartPlotAction = Action<
	ActionTypes.TOGGLE_CHART_PLOT,
	{ id: string; plotIndex: number }
>;

/*
	============================
		  ASYNC - ANALYSES
	============================
*/

export type GetCompareNumericActionV2 = Action<
	ActionTypes.GET_COMPARE_NUMERIC_V2,
	{
		dataset: CompareNumericResultsV2;
		analysisId: string;
	}
>;

export type GetCorrelationsV2Action = Action<
	ActionTypes.GET_CORRELATIONS_V2,
	{
		dataset: CorrelationsResultsV2;
		analysisId: string;
	}
>;

export type GetCrosstabActionV2 = Action<
	ActionTypes.GET_CROSSTAB_V2,
	{
		dataset: CrosstabResultsV2;
		analysisId: string;
	}
>;

export type GetExploreActionV2 = Action<
	ActionTypes.GET_EXPLORE_V2,
	{
		dataset: ExploreResultsV2;
		analysisId: string;
	}
>;

export type GetFrequenciesActionV2 = Action<
	ActionTypes.GET_FREQUENCIES_V2,
	{
		dataset: AnalysisOutputData<FrequenciesDecodedResultsV2>;
		analysisId: string;
	}
>;

export type GetKaplanMeierActionV2 = Action<
	ActionTypes.GET_KAPLAN_MEIER_V2,
	{
		dataset: KaplanMeierResultsV2;
		analysisId: string;
	}
>;

export type GetPlotNumericColumnsActionV2 = Action<
	ActionTypes.GET_PLOT_NUMERIC_COLUMNS_V2,
	{
		dataset: AnalysisOutputData<PlotNumericColumnsV2>;
		analysisId: string;
	}
>;

export type GetPlotNumericBoxplotActionV2 = Action<
	ActionTypes.GET_PLOT_NUMERIC_BOXPLOT_V2,
	{
		dataset: AnalysisOutputData<PlotNumericBoxPlotsV2>;
		analysisId: string;
	}
>;

export type GetPlotNumericScatterActionV2 = Action<
	ActionTypes.GET_PLOT_NUMERIC_SCATTER_V2,
	{
		dataset: AnalysisOutputData<PlotNumericScatterV2>;
		analysisId: string;
	}
>;

export type GetDensityPlotActionV2 = Action<
	ActionTypes.GET_DENSITY_PLOT_V2,
	{
		dataset: DensityPlotResultsV2;
		analysisId: string;
	}
>;

export type GetTimeCourseActionV2 = Action<
	ActionTypes.GET_TIME_COURSE_V2,
	{
		dataset: TimeCourseResultsV2;
		analysisId: string;
	}
>;

export type GetComparePairedActionV2 = Action<
	ActionTypes.GET_COMPARE_PAIRED_V2,
	{
		dataset: ComparePairedResultsV2;
		analysisId: string;
	}
>;

export type GetLogisticRegressionActionV2 = Action<
	ActionTypes.GET_LOGISTIC_REGRESSION_V2,
	{
		dataset: GetLogisticRegressionOutputV2;
		analysisId: string;
	}
>;

/*
	============================
		ASYNC - STATISTICS
	============================
*/

export type GetFisherStatisticsActionV2 = Action<
	ActionTypes.GET_FISHER_STATISTICS_V2,
	{
		values: FisherResultsV2;
		analysisId: string;
	}
>;

export type GetChiSquareStatisticsActionV2 = Action<
	ActionTypes.GET_CHI_SQUARE_STATISTICS_V2,
	{
		values: ChiSquareResultsV2;
		analysisId: string;
	}
>;

export type GetShapiroStatisticsActionV2 = Action<
	ActionTypes.GET_SHAPIRO_STATISTICS_V2,
	{
		values: ShapiroResultsV2;
		analysisId: string;
	}
>;

export type GetMannWhitneyStatisticsActionV1 = Action<
	ActionTypes.GET_MANN_WHITNEY_STATISTICS_V1,
	{
		values: MannWhitneyResultsV1;
		analysisId: string;
	}
>;
export type GetMannWhitneyStatisticsActionV2 = Action<
	ActionTypes.GET_MANN_WHITNEY_STATISTICS_V2,
	{
		values: MannWhitneyResultsV2;
		analysisId: string;
	}
>;

export type GetIndependentStatisticsActionV2 = Action<
	ActionTypes.GET_INDEPENDENT_STATISTICS_V2,
	{
		values: IndependentResultsV2;
		analysisId: string;
	}
>;

export type GetPearsonStatisticsV2Action = Action<
	ActionTypes.GET_PEARSON_STATISTICS_V2,
	{
		values: CorrelationsStatisticsResults;
		analysisId: string;
	}
>;

export type GetSpearmanStatisticsV2Action = Action<
	ActionTypes.GET_SPEARMAN_STATISTICS_V2,
	{
		values: CorrelationsStatisticsResults;
		analysisId: string;
	}
>;

export type GetLinearRegressionStatisticsV2Action = Action<
	ActionTypes.GET_LINEAR_REGRESSION_STATISTICS_V2,
	{
		values: LinearRegressionResults;
		analysisId: string;
	}
>;

export type GetOneWayAnovaStatisticsActionV2 = Action<
	ActionTypes.GET_ONE_WAY_ANOVA_STATISTICS_V2,
	{
		values: OneWayAnovaResultsV2;
		analysisId: string;
	}
>;

export type GetLogRankTestStatisticsActionV2 = Action<
	ActionTypes.GET_LOG_RANK_TEST_STATISTICS_V2,
	{
		values: LogRankResultsV2;
		analysisId: string;
	}
>;

export type GetTukeyStatisticsActionV2 = Action<
	ActionTypes.GET_TUKEY_STATISTICS_V2,
	{
		values: TukeyResultsV2;
		analysisId: string;
	}
>;

export type GetPairedTTestStatisticsActionV2 = Action<
	ActionTypes.GET_PAIRED_TTEST_STATISTICS_V2,
	{
		values: PairedTTestResultsV2;
		analysisId: string;
	}
>;

export type GetPairedWilcoxonStatisticsActionV2 = Action<
	ActionTypes.GET_PAIRED_WILCOXON_STATISTICS_V2,
	{
		values: PairedWilcoxonResultsV2;
		analysisId: string;
	}
>;

export type GetKruskalStatisticsActionV2 = Action<
	ActionTypes.GET_KRUSKAL_STATISTICS_V2,
	{
		values: KruskalResultsV2;
		analysisId: string;
	}
>;

export type GetMcNemarStatisticsActionV2 = Action<
	ActionTypes.GET_MCNEMAR_STATISTICS_V2,
	{
		values: McNemarResultsV2;
		analysisId: string;
	}
>;

export type GetTwoWayManovaStatisticsActionV2 = Action<
	ActionTypes.GET_TWO_WAY_MANOVA_STATISTICS_V2,
	{
		values: TwoWayManovaResultsV2;
		analysisId: string;
	}
>;

export type GetTwoWayAnovaStatisticsActionV2 = Action<
	ActionTypes.GET_TWO_WAY_ANOVA_STATISTICS_V2,
	{
		values: TwoWayAnovaResultsV2;
		analysisId: string;
	}
>;

export type GetOneWayManovaStatisticsActionV2 = Action<
	ActionTypes.GET_ONE_WAY_MANOVA_STATISTICS_V2,
	{
		values: OneWayManovaResultsV2;
		analysisId: string;
	}
>;

export type Actions =
	// LOCAL
	| CreateAnalysisAction
	| UpdateAnalysisAction
	| DeleteAnalysisAction
	| DeleteAnalysesAction
	| SetAnalysisActiveTabAction
	| SetAnalysisConfigPanelAction
	| SetAnalysisFormattingAction
	| SetAnalysisParametersAction
	| SetAnalysisChartTypesAction
	| SetAnalysisOpenStateAction
	| SetRefetchAnalysesAction
	| SetRefetchedAnalysesAction
	| RebuildAnalysesAction
	| SetAnalysesColumnAction
	| SetAnalysisFullscreenAction
	| ToggleChartPlotAction
	// ASYNC - ANALYSES
	| GetCompareNumericActionV2
	| GetCorrelationsV2Action
	| GetCrosstabActionV2
	| GetExploreActionV2
	| GetFrequenciesActionV2
	| GetKaplanMeierActionV2
	| GetPlotNumericColumnsActionV2
	| GetPlotNumericBoxplotActionV2
	| GetPlotNumericScatterActionV2
	| GetDensityPlotActionV2
	| GetTimeCourseActionV2
	| GetComparePairedActionV2
	| GetLogisticRegressionActionV2
	// ASYNC - STATISTICS
	| GetFisherStatisticsActionV2
	| GetChiSquareStatisticsActionV2
	| GetMannWhitneyStatisticsActionV1
	| GetShapiroStatisticsActionV2
	| GetMannWhitneyStatisticsActionV2
	| GetIndependentStatisticsActionV2
	| GetOneWayAnovaStatisticsActionV2
	| GetPearsonStatisticsV2Action
	| GetSpearmanStatisticsV2Action
	| GetLinearRegressionStatisticsV2Action
	| GetLogRankTestStatisticsActionV2
	| GetTukeyStatisticsActionV2
	| GetPairedTTestStatisticsActionV2
	| GetPairedWilcoxonStatisticsActionV2
	| GetKruskalStatisticsActionV2
	| GetMcNemarStatisticsActionV2
	| GetTwoWayManovaStatisticsActionV2
	| GetTwoWayAnovaStatisticsActionV2
	| GetOneWayManovaStatisticsActionV2;
