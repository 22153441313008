import { useMemo } from 'react';
import { Select } from 'components/UI/Interactables/Select';
import { MediaQueries } from 'environment';
import { Container, NavTab as Tab, NormalTab, Text } from '../Tab.style';
import { useNavigation } from 'hooks/navigation';
import { useTranslation, useSubscription } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';

const tabsProps = {
	width: 19.1,
	min_width: 19.1,
	underline_width: 11.9
};

export function AccountUMTabs() {
	const { translate } = useTranslation();
	const { routes, navigate } = useNavigation();

	const [
		{
			data: {
				subscriptionTypes: { isLedidiCore, isLedidiCollaborator },
				userTypesAndRoles: { isSubscriptionOwner }
			}
		}
	] = useSubscription();

	const showPaymentsTab = useMemo(
		() => isSubscriptionOwner && (isLedidiCore || isLedidiCollaborator),
		[isSubscriptionOwner, isLedidiCore, isLedidiCollaborator]
	);

	const showBillingHistoryTab = useMemo(
		() => isSubscriptionOwner && (isLedidiCore || isLedidiCollaborator),
		[isSubscriptionOwner, isLedidiCore, isLedidiCollaborator]
	);

	const showTabs = useMemo(
		() => showPaymentsTab || showBillingHistoryTab,
		[showPaymentsTab, showBillingHistoryTab]
	);

	const isPhone = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	const billingHistoryTab = [
		{
			label: translate(dict => dict.accountUM.tabs.billingHistory),
			value: routes.accountUM.billingHistory
		}
	];

	const paymentTab = [
		{
			label: translate(dict => dict.accountUM.tabs.payment),
			value: routes.accountUM.payment
		}
	];

	const menuTabs = [
		{
			label: translate(dict => dict.accountUM.tabs.subscription),
			value: routes.accountUM.subscription
		},
		...(showPaymentsTab ? paymentTab : []),
		...(showBillingHistoryTab ? billingHistoryTab : [])
	];
	if (!showTabs) return null;

	return (
		<Container>
			{isPhone ? (
				<NormalTab>
					<Select
						title={translate(({ accountUM }) => accountUM.userDrawer.headers.profile)}
						className="dropdown-tab"
						items={[]}
						type={s => s.Tab(true)}
						// activeItem={item => isActive(item.value as Partial<ProjectOwnership>)}
						onSelect={item => navigate(item.value as string)}
					/>
				</NormalTab>
			) : (
				menuTabs.map(item => (
					<Tab to={item.value as string} key={item.label} {...tabsProps}>
						<div>
							<Text>{item.label}</Text>
						</div>
					</Tab>
				))
			)}
		</Container>
	);
}
