import { OperationResult } from 'hooks/store/types';
import { ProjectOwnership } from 'types/data/projects/constants';
import { setActiveOwnership, ActionTypes } from 'store/data/projects';
import { useMatchProjects } from 'hooks/navigation';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';

export function useActiveProjectOwnership(): OperationResult<
	ProjectOwnership,
	(ownership: ProjectOwnership) => void
> {
	const matchProjects = useMatchProjects();
	const dispatch = useDispatch();
	const data = useSelector(state => state.data.projects.filters.projects.ownership);

	const [{ loading, error }] = useActivity(ActionTypes.SET_ACTIVE_STATUS);

	function handler(ownership: ProjectOwnership) {
		dispatch(setActiveOwnership({ ownership, isProject: matchProjects }));
	}

	return [{ loading, error, data }, handler];
}
