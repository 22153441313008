import { useEffect, useMemo } from 'react';
import { buildVariablesRichData } from 'helpers/variables';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	getVariables,
	selectAreVariablesFetched,
	VariablesRichData
} from 'store/data/variables';
import { RequireAtLeastOne } from 'types/index';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';
import { useProjectId } from '../projects/useProjectId';
import { useVariablesData } from './useVariablesData';
import { VariableType } from 'types/data/variables/constants';

interface UseVariablesOptions {
	initial?: boolean;
	lazy?: boolean;
	omit?: RequireAtLeastOne<{
		/**
		 * restricted variables (old: personal data)
		 */
		restricted?: boolean;
		/**
		 * variable type(s)
		 */
		types?: VariableType[];
		/**
		 * variable names
		 */
		names?: string[];
	}>;
}

export function useVariables(
	options?: RequireAtLeastOne<UseVariablesOptions>
): OperationResult<VariablesRichData> {
	const dispatch = useDispatch();

	const { initial = false, lazy = false, omit } = options ?? {};

	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const fetched = useSelector(state => selectAreVariablesFetched(state.data.variables));

	const variablesData = useVariablesData({ initial });

	const data = useMemo(
		() => buildVariablesRichData(variablesData, { omit }),
		[variablesData, omit]
	);

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_VARIABLES, {
		projectId
	});

	useEffect(() => {
		if (!error && !loading && !fetched && !lazy && isProjectValid) {
			handler();
		}
	}, [error, loading, fetched, lazy, isProjectValid]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getVariables());
	}

	return [{ data, loading, error, fetched }, handler];
}
