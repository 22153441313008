import { ApiProject } from 'api/data/projects';
import {
	parseApiCollaboratorPermissions,
	parseApiOrganization
} from 'store/data/collaborators/parsers';

import {
	Project,
	ProjectMetadata,
	ProjectMetadataDefinition,
	ProjectMetadataParameter
} from './types';
import { ApiOrganization } from 'api/data/collaborators';
import { PROJECT_METADATA_LOCAL_IDENTIFIER } from 'consts';

export function parseApiProject(apiProject: ApiProject): Project {
	const {
		userAccess: apiUserAccess,
		organization: apiOrganization,
		projectOwnerHasAccessEntriesAllTransferOwnership,
		...rest
	} = apiProject;

	const project: Omit<Project, 'organization'> = {
		...rest
	};

	if (apiUserAccess) {
		const permissions = parseApiCollaboratorPermissions({
			...apiUserAccess,
			projectOwnerHasAccessEntriesAllTransferOwnership
		});

		project.userAccess = permissions;
	}

	return {
		...project,
		...(apiOrganization && {
			organization: parseApiOrganization(apiOrganization as ApiOrganization)
		})
	} as Project;
}

export function parseApiProjects(apiProjects: ApiProject[]): Project[] {
	return apiProjects.map(parseApiProject);
}

/**
 * Changes the id of the parameters to contain "metadata_" in front, to keep them
 * unique among the other's project parameters
 * @param metadataDefinition
 * @returns
 */
export function parseApiMetadataDefinition(
	metadataDefinition: ProjectMetadataDefinition
): ProjectMetadataDefinition {
	const parsedMetadataDefinition: ProjectMetadataParameter[] = [];
	metadataDefinition.metadataDefinition.forEach(element =>
		parsedMetadataDefinition.push({
			...element,
			name: PROJECT_METADATA_LOCAL_IDENTIFIER + element.name
		})
	);
	return {
		...metadataDefinition,
		metadataDefinition: parsedMetadataDefinition
	};
}

/**
 * Substracts "metadata_" from the id of the metadata
 * @param metadataDefinition
 * @returns
 */
export function parseToApiMetadata(metadata: ProjectMetadata | undefined) {
	if (!metadata) return undefined;
	let apiProjectMetadata: ProjectMetadata = {};
	Object.keys(metadata).forEach(
		key => (apiProjectMetadata = { ...apiProjectMetadata, [key.slice(9)]: metadata[key] })
	);
	return apiProjectMetadata;
}

/**
 * Adds "metadata_" in the id of the metadata
 * @param metadataDefinition
 * @returns
 */
export function parseFromApiMetadata(metadata: ProjectMetadata | undefined) {
	if (!metadata) return undefined;
	let parsedProjectMetadata: ProjectMetadata = {};
	Object.keys(metadata).forEach(
		key =>
			(parsedProjectMetadata = {
				...parsedProjectMetadata,
				[PROJECT_METADATA_LOCAL_IDENTIFIER + key]: metadata[key]
			})
	);
	return parsedProjectMetadata;
}
