import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { useGetProjectQuery } from '../data/useGetProjectQuery';
import { useParams } from 'react-router-dom';

export const PersonalDataWarning = () => {
	const params = useParams();
	const projectId = params.projectId as string;

	const getProjectQuery = useGetProjectQuery({ projectId }, { enabled: !!projectId });

	const forbidPersonalData =
		getProjectQuery.data?.project.projectOwnerDetails.organization === 'OUSHF' &&
		(!getProjectQuery.data.project.metadata ||
			!getProjectQuery.data.project.metadata['archiveNumber']);

	if (!forbidPersonalData) {
		return null;
	}

	return (
		<div className="col-span-full flex gap-6 px-8 py-4 rounded-md items-center bg-gray-300">
			<Icon svg={Svgs.Pdna} />
			<p className="text-base text-gray-700">Personal data not allowed in this project</p>
		</div>
	);
};
