export const SeriesEntryFormOverhaul = () => {
	return (
		<div>
			<h3 className="text-base font-bold">Series entry form</h3>

			<p className="mt-6 text-base">
				We have cleaned up this view, removing some less useful UI elements. It now looks
				and feels similar to how the top level entry form does.
			</p>

			<p className="mt-6 text-base">
				Instead of the large left/right arrows to navigate between entries, you now need to
				use the numbers/arrows above the entry, or the series entry table, as described on
				the previous slide.
			</p>

			<p className="mt-6 text-base">
				Creating series entry is now a separate page you can access from the series
				overview, or by clicking the &quot;Create series entry&quot; button in the series
				entry form.
			</p>

			<img
				className="mt-10"
				src="/img/entry-form-1-5-change-log/series_entry_form_overhaul.gif"
				alt="Showing the series entry form overhaul"
			/>
		</div>
	);
};
