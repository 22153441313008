import { useState, useRef, useMemo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Variable } from 'api/data/variables';
import { FormThumbnail } from 'components/Forms';
import { Svgs } from 'environment';
import { Form } from 'store/data/forms';
import { VariablesData } from 'store/data/variables';
import {
	Card,
	Container,
	MoreMenu,
	FormPreviewContainer,
	ActiveDisplay,
	HoverMenu,
	DropToLeft,
	DropToRight,
	LineMarker,
	CardReplacement
} from './FormCard.style';
import { Dropdown } from 'components/UI/Dropdown';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { areMissingVariablesRequired } from 'helpers/forms';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	usePermissions,
	useProjectId,
	useMoveForm,
	useToggleFormActive
} from 'hooks/store';
import { useOutsideClick } from 'hooks/utils';

interface DraggableItem {
	index: number;
	formId: string;
}

enum DraggableItemType {
	Form = 'form',
	FormSeries = 'form_series'
}

interface Props {
	form: Form;
	index: number;
	preventDrag: boolean;
	variables: Variable[];
	variablesData: VariablesData;
	onClick: (formId: string, newTab?: boolean) => void;
	onRename: (formId: string) => void;
	onDelete: (formId: string) => void;
}

export function FormCard({
	form,
	index,
	preventDrag,
	variables,
	variablesData,
	onClick,
	onRename,
	onDelete
}: Props) {
	const { translate } = useTranslation();
	const { routes } = useNavigation();

	const invisRefRight = useRef<HTMLDivElement>(null);
	const invisRefLeft = useRef<HTMLDivElement>(null);

	const { hasVariablesWriteAccess } = usePermissions();

	const [projectId] = useProjectId();

	const [, moveForm] = useMoveForm();

	const [{ loading: togglingForm }, toggleFormActive] = useToggleFormActive(form.id);

	const [menuVisible, setMenuVisible] = useState(false);

	const elementValueRef = useRef<HTMLDivElement>(null);

	const missingVariablesRequired = useMemo(
		() => areMissingVariablesRequired(variables, form.usedVariables ?? []),
		[variables, form.usedVariables]
	);

	const canBeActivated = !missingVariablesRequired;

	const draggableItemType = form.setName
		? `${DraggableItemType.Form}-${form.setName}`
		: DraggableItemType.Form;

	useOutsideClick(() => setMenuVisible(false), [elementValueRef]);

	const [{ isDragging }, drag] = useDrag({
		canDrag: !preventDrag,
		type: draggableItemType,
		item: (): DraggableItem => ({ index, formId: form.id }),
		collect: monitor => ({
			isDragging: monitor.isDragging()
		})
	});

	drag(elementValueRef);

	// DROP FOR LEFT
	const [{ leftHandlerId, isOverCurrentLeft }, dropLeft] = useDrop<any, any, any>({
		accept: draggableItemType,
		collect: monitor => ({
			isOverLeft: monitor.isOver(),
			isOverCurrentLeft: monitor.isOver({ shallow: true }),
			leftHandlerId: monitor.getHandlerId()
		}),
		drop(item: DraggableItem, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			const { index: sourceIndex, formId } = item;

			const destinationIndex = index;

			moveForm({ formId, sourceIndex, destinationIndex });
		}
	});

	// DROP FOR RIGHT
	const [{ rightHandlerId, isOverCurrentRight }, dropRight] = useDrop<any, any, any>({
		accept: draggableItemType,
		collect: monitor => ({
			isOverRight: monitor.isOver(),
			isOverCurrentRight: monitor.isOver({ shallow: true }),
			rightHandlerId: monitor.getHandlerId()
		}),
		drop(item: DraggableItem, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			const { index: sourceIndex, formId } = item;

			const destinationIndex = index < sourceIndex ? index + 1 : index;

			moveForm({ formId, sourceIndex, destinationIndex });
		}
	});

	dropLeft(invisRefLeft);
	dropRight(invisRefRight);

	const isFirstItem = index === 0;

	const commonChildren = (
		<>
			{isFirstItem && (
				<DropToLeft
					ref={invisRefLeft}
					data-handler-id={leftHandlerId}
					isHovered={isOverCurrentLeft}
				>
					<LineMarker />
				</DropToLeft>
			)}

			<DropToRight
				ref={invisRefRight}
				data-handler-id={rightHandlerId}
				isHovered={isOverCurrentRight}
			>
				<LineMarker />
			</DropToRight>
		</>
	);

	function handleToggleFormActive(formId: string) {
		if (canBeActivated && !togglingForm) toggleFormActive(formId);
	}

	/**
	 * Handles the new-tab-click
	 */
	function handleOnClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
		// forward / backward mouse buttons
		if ([3, 4].includes(e.button)) return;

		const newTab =
			// CTRL / CMD + click
			e.metaKey ||
			e.ctrlKey ||
			// mouse middle click
			[1].includes(e.button);

		onClick(form.id, newTab);
	}

	const labels = {
		Active: translate(({ formDesigner }) => formDesigner.active),
		Inactive: translate(({ formDesigner }) => formDesigner.inactive),
		MakeActive: translate(({ formDesigner }) => formDesigner.activateForm),
		Deactivate: translate(({ formDesigner }) => formDesigner.deActivateForm)
	};

	return (
		<Container ref={elementValueRef}>
			{isDragging ? (
				<CardReplacement>
					<DropToLeft isHovered={true}>
						<LineMarker />
					</DropToLeft>
				</CardReplacement>
			) : (
				<>
					<Card
						writeAccess={hasVariablesWriteAccess}
						labels={labels}
						canBeActivated={canBeActivated}
						active={form.active}
					>
						{hasVariablesWriteAccess && (
							<MoreMenu visible={menuVisible}>
								<Dropdown
									offset={{ top: 20, left: -10 }}
									width={12}
									toggleComponent={({ ref, toggle }) => (
										<Icon
											ref={ref}
											variant={v => v.button}
											svg={Svgs.More}
											onClick={() => {
												setMenuVisible(state => !state);
												toggle();
											}}
											active={menuVisible}
										/>
									)}
								>
									<Dropdown.Item
										onClick={() => {
											setMenuVisible(false);
											onRename(form.id);
										}}
									>
										{translate(dict => dict.buttons.rename)}
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => {
											setMenuVisible(false);
											onDelete(form.id);
										}}
									>
										{translate(dict => dict.buttons.delete)}
									</Dropdown.Item>
								</Dropdown>
							</MoreMenu>
						)}

						<HoverMenu
							href={routes.projects.forms.form(projectId ?? '', form.id)}
							onMouseUp={handleOnClick}
						>
							<Icon svg={Svgs.Edit} propagate />
							{missingVariablesRequired && (
								<Typography.Paragraph>
									{translate(
										({ formDesigner }) => formDesigner.activateFormTooltip
									)}
								</Typography.Paragraph>
							)}
						</HoverMenu>

						<FormPreviewContainer>
							<FormThumbnail form={form} variablesData={variablesData} />
						</FormPreviewContainer>

						<ActiveDisplay
							active={form.active}
							canBeActivated={canBeActivated}
							labels={labels}
							writeAccess={hasVariablesWriteAccess}
							onClick={() =>
								hasVariablesWriteAccess && handleToggleFormActive(form.id)
							}
						>
							{togglingForm ? <p>...</p> : <p />}
						</ActiveDisplay>

						{/* DRAG N DROP LINE INDICATOR */}
						{commonChildren}
					</Card>

					<Typography.Paragraph fontweight={w => w.medium} marginOffset={{ top: 0.8 }}>
						{form.name}
					</Typography.Paragraph>
				</>
			)}
		</Container>
	);
}
