import { Amplify, Auth, Hub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { StorageKeys } from 'types/index';

const {
	REACT_APP_REGION,
	REACT_APP_USER_POOL_ID,
	REACT_APP_IDENTITY_POOL_ID,
	REACT_APP_PUBSUB_ENDPOINT,
	REACT_APP_CLIENT_ID,
	REACT_APP_FEDERATED_CLIENT_IDS,
	REACT_APP_SSO_URL_BASE
} = process.env;

// Map from ssoParam to providerName and clientId
const FEDERATION_CONFIG: { [key: string]: { clientId: string; providerName: string } } = JSON.parse(
	REACT_APP_FEDERATED_CLIENT_IDS ?? '{}'
);

let SIGN_IN_FAILURES: any[] = [];
Hub.listen('auth', ({ payload: { event, data } }) => {
	switch (event) {
		case 'signIn_failure':
			SIGN_IN_FAILURES.push(data);
			break;
	}
});

function getFederatedProviderName() {
	const params = new URLSearchParams(window.location.search);
	const ssoParam = params.get('sso');
	if (ssoParam) {
		// If the sso query parameter is set, we look up the provider name in the configuration
		return FEDERATION_CONFIG[ssoParam.toLowerCase()]?.providerName ?? null;
	}
	return localStorage.getItem('SSOProvider') ?? null;
}

function getClientId(providerName: string) {
	return (
		Object.values(FEDERATION_CONFIG).find(config => config.providerName === providerName)
			?.clientId ?? ''
	);
}

function configureAmplifyForFederatedLogin(ssoProviderName: string) {
	// we set the SSOProvider in localStorage so we know which config to use
	// when we return from the federated login page
	localStorage.setItem('SSOProvider', ssoProviderName);
	Amplify.configure({
		Auth: {
			mandatorySignIn: true,
			region: REACT_APP_REGION,
			userPoolId: REACT_APP_USER_POOL_ID,
			identityPoolId: REACT_APP_IDENTITY_POOL_ID,
			userPoolWebClientId: getClientId(ssoProviderName),
			oauth: {
				domain: REACT_APP_SSO_URL_BASE ?? 'sso.ledidi.no',
				scopes: ['email', 'phone', 'openid', 'aws.cognito.signin.user'],
				redirectSignIn: `${window.location.origin}/login/`,
				redirectSignOut: `${window.location.origin}/login/`,
				responseType: 'code'
			}
		},
		Analytics: {
			disabled: true
		}
	});
}

function configureAmplifyForDirectLogin() {
	// We remove the SSOProvider from localStorage, this signifies that we should use the direct login config
	localStorage.removeItem('SSOProvider');
	Amplify.configure({
		Auth: {
			mandatorySignIn: true,
			region: REACT_APP_REGION,
			userPoolId: REACT_APP_USER_POOL_ID,
			identityPoolId: REACT_APP_IDENTITY_POOL_ID,
			userPoolWebClientId: REACT_APP_CLIENT_ID
		},
		Analytics: {
			disabled: true
		}
	});
}

async function getCurrentUser() {
	try {
		return await Auth.currentAuthenticatedUser();
	} catch (e) {
		if (e === 'The user is not authenticated') {
			return null; // no user is logged in
		}
		throw e; // unknown error
	}
}

async function handleFederatedLoginFlow(ssoProviderName: string) {
	const user = await getCurrentUser();
	if (!user) {
		// One out of two things might have happened.
		// 1. The user is not logged in, we therefore redirect to the federated login page
		// 2. The federated login flow has failed, and we need to display an error message.
		// 		- To detect this case, we check if the SIGN_IN_FAILURES array is not empty
		if (SIGN_IN_FAILURES.length > 0) {
			// The federated login flow has failed, we display the error message
			console.error('Federated login failed. Resetting the amplify login flow.');
			SIGN_IN_FAILURES = [];
			// We remove the SSOProvider from localStorage so we don't get stuck in a loop
			localStorage.removeItem('SSOProvider');
			alert('Login failed, please try again.\nOr contact support if the problem persists.');
			init(); // reinitialize the amplify configuration so the user can try again.
			return;
		}
		localStorage.setItem('SSOProvider', ssoProviderName); // Maybe this makes sense, but I'm not sure why
		await Auth.federatedSignIn({ customProvider: ssoProviderName });
		return; // return is never reached, but here for clarity
	}

	// If the user is on the login page and is logged in, we have just returned from the federated login page
	// we therefore set the necessary flags and redirect to the projects page
	localStorage.removeItem(StorageKeys.Username);
	localStorage.removeItem(StorageKeys.FederatedUILogin);
	localStorage.removeItem(StorageKeys.LoginCompleted);
	localStorage.removeItem(StorageKeys.LoginStep);
	localStorage.setItem(StorageKeys.Username, user.username);
	localStorage.setItem(StorageKeys.FederatedUILogin, 'true');
	localStorage.setItem(StorageKeys.LoginCompleted, 'true');
	localStorage.setItem(StorageKeys.IdToken, user.signInUserSession.idToken.jwtToken);
	localStorage.setItem(StorageKeys.FederatedLoginClientID, getClientId(ssoProviderName));

	window.location.href = '/projects';
}

// This function is called when the app is loaded.
// It is responsible for configuring Amplify with either federated login or direct login.
// If we are using federated login, it will also handle the federated login flow.
function init() {
	const federatedProviderName = getFederatedProviderName();
	if (!federatedProviderName) {
		// NO SSO provider found, configure for direct login
		configureAmplifyForDirectLogin();
		return;
	}
	// Federated login provider found, configure for federated login
	configureAmplifyForFederatedLogin(federatedProviderName);

	Amplify.addPluggable(
		new AWSIoTProvider({
			aws_pubsub_region: REACT_APP_REGION,
			aws_pubsub_endpoint: REACT_APP_PUBSUB_ENDPOINT
		})
	);

	if (['/', '/login', '/login/', ''].includes(window.location.pathname)) {
		handleFederatedLoginFlow(federatedProviderName);
	}
}

init();
