import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { Svgs, Colors } from 'environment';
import { groupCountPulse, groupCardPulse } from 'events/variables';
import { DraggableVariableCardType } from 'store/data/variables';
import { DragProps, VariableCardPayload } from '../CommonCard';
import { VariableSetConfirmDragAndDropActionModal } from '../../ConfirmDragAndDropActionModal';
import {
	LineMarker,
	VariableCardContainer,
	ColorIndicator,
	SelectedCardIndicator,
	LoadingContainer
} from '../VariableCard/VariableCard.style';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Loader } from 'components/UI/Loader';
import { Typography } from 'components/UI/Typography';
import { useCustomEventListener } from 'helpers/events';
import {
	useTranslation,
	useAddVariableToGroup,
	useMoveVariableBetweenGroups,
	useRemoveVariableGroupFromSet
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';

interface Props {
	title: string;
	name: string;
	selected: boolean;
	numberOfVariables: number;
	dragMainProps: DragProps;
	children: React.ReactNode;
	hasError?: boolean;
	onClick: () => void;
}

export function VariableGroupCard({
	title,
	name,
	selected,
	numberOfVariables,
	dragMainProps: { ref, isDragging, drag },
	children,
	hasError,
	onClick
}: Props) {
	const { setError: setErrorNotification } = useAlerts();
	const { translate } = useTranslation();
	const [, addVariableToGroup] = useAddVariableToGroup();
	const [, moveVariableBetweenGroups] = useMoveVariableBetweenGroups();
	const [{ loading: removingVariableGroupFromSet }] = useRemoveVariableGroupFromSet({
		groupName: name
	});

	const loading = removingVariableGroupFromSet;

	const [confirmAction, setConfirmAction] = useState<VariableCardPayload | null>(null);
	const confirmActionModal = {
		open: (item: VariableCardPayload) => setConfirmAction(item),
		close: () => setConfirmAction(null)
	};

	const [pulseCard, setPulseCard] = useState(false);
	const [pulseCount, setPulseCount] = useState(false);

	useCustomEventListener(groupCardPulse, {
		onListen: payload => {
			if (payload.groupName === name) setPulseCard(true);
		}
	});
	useCustomEventListener(groupCountPulse, {
		onListen: payload => {
			if (payload.groupName === name) setPulseCount(true);
		}
	});

	// DRAG - CARD
	const [{ handlerId, isOverCurrent, isSysGenVariable }, drop] = useDrop<any, any, any>({
		accept: DraggableVariableCardType.Variable,
		collect: monitor => {
			const draggedVariable: VariableCardPayload = monitor.getItem();
			const isSysGenVariable = draggedVariable && draggedVariable.systemGenerated;

			return {
				handlerId: monitor.getHandlerId(),
				isOverCurrent: monitor.isOver({ shallow: true }),
				isSysGenVariable
			};
		},
		drop: (item: VariableCardPayload, monitor) => {
			if (!monitor.isOver({ shallow: true })) return;

			const isParentGroupCurrent = item.parentGroup === name;

			if (isParentGroupCurrent) return;

			if (item.parentSet) return confirmActionModal.open(item);

			if (isSysGenVariable)
				return setErrorNotification({
					message: `${translate(
						dict => dict.variablesPage.variableCard.beforeVariable
					)} ${title} ${translate(dict => dict.terms.variableLowerCase)}`
				});

			handleDrop(item);
		}
	});

	function handleDrop(item: VariableCardPayload) {
		if (item.parentSet) {
			// DRAG `variable` CARD (VARIABLE SET DRAWER -> VARIABLE GROUP DRAWER) => DROP ON `group` CARD (MAIN LIST)
			if (item.parentGroup) {
				// PROCESS: 1. REMOVE VARIABLE FROM GROUP; 2. REMOVE VARIABLE FROM SET; 3. ADD VARIABLE TO GROUP
				return addVariableToGroup({
					variableName: item.name,
					groupName: name,
					from: {
						set: {
							setName: item.parentSet,
							parentGroup: item.parentGroup
						}
					}
				});
			}

			// DRAG `variable` CARD (VARIABLE SET DRAWER) => DROP ON `group` CARD (MAIN LIST)
			// PROCESS: 1. REMOVE VARIABLE FROM SET; 2. ADD VARIABLE TO GROUP
			return addVariableToGroup({
				variableName: item.name,
				groupName: name,
				from: { set: { setName: item.parentSet } }
			});
		}

		// DRAG `variable` CARD (VARIABLE GROUP DRAWER) => DROP ON `group` CARD (MAIN LIST)
		if (item.parentGroup) {
			return moveVariableBetweenGroups({
				variableName: item.name,
				sourceGroupName: item.parentGroup,
				destinationGroupName: name
			});
		}

		// DRAG `variable` CARD (MAIN LIST) => DROP ON `group` CARD (MAIN LIST)
		addVariableToGroup({
			variableName: item.name,
			groupName: name
		});
	}

	drop(drag(ref));

	const hasOneVariable = numberOfVariables === 1;

	const subtitle = `${numberOfVariables} ${
		hasOneVariable
			? translate(dict => dict.terms.variableLowerCase)
			: translate(dict => dict.terms.variables)
	}`;

	return (
		<>
			<VariableCardContainer
				{...(pulseCard && {
					className: 'pulse-vibrant-green'
				})}
				onAnimationEnd={() => setPulseCard(false)}
				//
				isGroup
				ref={ref}
				isDragging={isDragging}
				data-handler-id={handlerId}
				isHovered={isOverCurrent}
				onClick={onClick}
				id={title?.replaceAll(/ /g, '').toLowerCase()}
				disabled={loading}
				data-scroll-id={name}
			>
				<ColorIndicator type="group" />
				{selected && <SelectedCardIndicator />}

				<Flex align={a => a.center}>
					<Icon
						svg={Svgs.Folder}
						size={s => s.m}
						colors={{ color: Colors.groupOrange }}
						propagate
					/>
					<Typography.Paragraph fontweight={w => w.medium} title={title} ellipsis>
						{title}
					</Typography.Paragraph>
				</Flex>
				<Flex justify={j => j.between}>
					<Typography.Caption
						{...(pulseCount && {
							className: 'pulse-vibrant-green',
							style: {
								color: Colors.chart.darkGreen.dark
							}
						})}
						onAnimationEnd={() => setPulseCount(false)}
						//
						title={subtitle}
						ellipsis
					>
						{subtitle}
					</Typography.Caption>

					{hasError && (
						<Icon
							className="variable-card__error-indicator"
							svg={Svgs.Information}
							size={s => s.m}
							colors={{ color: Colors.text.error }}
						/>
					)}
				</Flex>

				<LineMarker />

				{children}

				{loading && (
					<LoadingContainer>
						<Loader />
					</LoadingContainer>
				)}
			</VariableCardContainer>

			{/* CONFIRM DRAG-N-DROP ACTION MODAL */}
			{confirmAction && (
				<VariableSetConfirmDragAndDropActionModal
					item={confirmAction}
					direction="from"
					onConfirm={() => handleDrop(confirmAction)}
					onClose={confirmActionModal.close}
				/>
			)}
		</>
	);
}
