import { useTracking } from 'app/tracking/TrackingProvider';
import clsx from 'clsx';
import { Button } from 'features/entry-form-v2/component/Button';
import { TextArea } from 'features/entry-form-v2/component/TextArea';
import { useState } from 'react';
import { ThumbDownIcon } from '@icons';

export const Summary = ({ onFinish, onBack }: { onFinish: () => void; onBack: () => void }) => {
	const tracking = useTracking();

	const [feedback, setFeedback] = useState<'useful' | 'not_useful'>();
	const [feedbackText, setFeedbackText] = useState('');
	return (
		<div className="flex flex-col grow">
			<h3 className="text-base font-semibold">Summary</h3>

			<p className="mt-6 text-base">
				Thank you for reading through this change log! We hope you found it useful.
			</p>

			<h4 className="text-base font-semibold self-center mt-[40px]">
				I found this changelog
			</h4>
			<div className="flex gap-6 mt-2">
				<button
					className={clsx(
						'flex-1 flex flex-col items-center gap-4 hover:bg-gray-300 py-6 rounded-md',
						feedback === 'not_useful' && 'bg-gray-500/25'
					)}
					onClick={() => setFeedback('not_useful')}
				>
					<ThumbDownIcon className="text-[40px]" />
					<span>Not useful</span>
				</button>

				<button
					className={clsx(
						'flex-1 flex flex-col items-center gap-8 hover:bg-gray-300 py-6 rounded-md',
						feedback === 'useful' && 'bg-gray-500/25'
					)}
					onClick={() => setFeedback('useful')}
				>
					<ThumbDownIcon className="rotate-180 text-[40px]" />
					<span>Useful</span>
				</button>
			</div>

			{feedback === 'not_useful' && (
				<TextArea
					id="feedback-text"
					label="Help us improve"
					placeholder="Anything you would like to add?"
					className="mt-4"
					value={feedbackText}
					onChange={e => setFeedbackText(e.target.value)}
				/>
			)}

			<div className="self-end flex flex-row items-end gap-4 mt-auto">
				<Button title="Back" variant="secondary" onClick={onBack} />

				<Button
					disabled={!feedback}
					title="Finish"
					onClick={() => {
						tracking.track({
							eventName: 'entry_form_v1.5_changelog_finished',
							data: {
								feedback,
								feedbackText
							}
						});

						onFinish();
					}}
				/>
			</div>
		</div>
	);
};
