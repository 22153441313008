// exports

export enum ROUTE_QUERY {
	Upgrade = 'upgrade'
}

export enum ROUTES {
	Root = '/',
	Login = '/login',
	Logout = '/logout',
	Help = '/help/',
	HelpTopic = '/help/:topicUrl',
	NotFoundPage = '*',
	TemplateRoles = '/roles/',

	// PROJECTS
	Projects = '/projects',
	CreateProject = '/projects/create',
	ImportDataset = '/projects/:id/import',
	Analysis = '/projects/:id/analysis',
	Dataset = '/projects/:id/dataset',
	EntryStatuses = '/projects/:id/statuses',
	CreateEntry = '/projects/:id/dataset/create',
	UpdateEntry = '/projects/:id/dataset/update/:entryId',
	DatasetHistory = '/projects/:id/dataset/history',
	Variables = '/projects/:id/variables',
	CreateVariable = '/projects/:id/variables/create',
	Dependencies = '/projects/:id/dependencies',
	Templates = '/projects/:id/templates',
	Forms = '/projects/:id/forms',
	Form = '/projects/:id/forms/:formId',
	Documents = '/projects/:id/documents',
	Collaborators = '/projects/:id/collaborators',
	AddCollaborators = '/projects/:id/collaborators/invite',
	Roles = '/projects/:id/roles',

	// ACCOUNT MANAGEMENT
	AccountUMSubscription = '/account/subscription',
	AccountUMPayment = '/account/payment',
	AccountUMBillingHistory = '/account/billing',

	// ADMIN
	AdminUsers = '/users',
	AdminEnterprise = '/enterprise',
	AdminUserCreate = '/users/create',
	AdminUserDetails = '/users/details/:userId',
	AdminRoles = '/roles',

	// ENTERPRISE ADMIN
	EnterpriseAdminSubscription = '/subscription',
	EnterpriseAdminUsers = '/users',
	EnterpriseAdminProjects = '/projects',
	EnterpriseAdminRoles = '/roles',
	EnterpriseAdminSettings = '/settings'
}
