import { Header } from 'components/Header';

export function HistoryPageHeader({ title }: { title: string }) {
	return (
		<>
			<Header.Main />
			<Header.Navigation />
			<Header.Title title={title} />
		</>
	);
}
