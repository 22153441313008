import { prepareApiStatus, preparePartialApiStatus } from 'helpers/statuses';
import { rebuildEntries, setRefetchEntries } from 'store/data/entries';
import { rebuildFilters } from 'store/data/filters';
import { Status } from 'store/data/statuses';
import { createActivity } from 'store/ui/activities';
import { Thunk, ActionPayload } from 'store/types';

import {
	ActionTypes,
	GetStatusesAction,
	CreateStatusAction,
	UpdateStatusAction,
	DeleteStatusAction,
	CreateStatusesAction,
	STATUS_COLORS
} from './types';
import { setRefetchCollaborators } from '../collaborators';
import { track } from 'app/tracking/TrackingProvider';
import { getMessageFromError, unknownErrorMessage } from 'store/utils';

export const getStatusesAction = (
	payload: ActionPayload<GetStatusesAction>
): GetStatusesAction => ({
	type: ActionTypes.GET_STATUSES,
	payload
});

export const getStatuses =
	(abortController?: AbortController): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_STATUSES, dispatch });

		const { projectId } = getState().data.statuses;

		try {
			activity.begin({ payload: { projectId } });

			if (projectId) {
				const { statuses } = await context.api.data.statuses().getStatuses(
					{
						projectId: Number(projectId)
					},
					{
						signal: abortController?.signal
					}
				);

				dispatch(getStatusesAction({ projectId, statuses }));
				dispatch(rebuild());
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: { projectId }
			});
		} finally {
			activity.end();
		}
	};

const createStatusAction = (payload: ActionPayload<CreateStatusAction>): CreateStatusAction => ({
	type: ActionTypes.CREATE_STATUS,
	payload
});

export const createStatus =
	(status: Status, updateExistingEntries?: boolean): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.CREATE_STATUS, dispatch });

		try {
			activity.begin();

			const { projectId } = getState().data.statuses;

			if (projectId) {
				const apiStatus = prepareApiStatus(status);

				// OMIT `statusName` WHEN CREATING NEW STATUS TO MATCH `ApiNewStatus` TYPE
				const { statusName, ...apiNewStatus } = apiStatus;

				const { status: newStatus } = await context.api.data.statuses().createStatus({
					projectId: Number(projectId),
					status: apiNewStatus,
					...(updateExistingEntries && {
						updateExistingEntries
					})
				});

				dispatch(createStatusAction({ status: newStatus }));

				dispatch(rebuild());
				// MARK ENTRIES FOR REFETCH
				dispatch(setRefetchEntries());
				// MARK COLLABORATORS FOR REFETCH
				dispatch(setRefetchCollaborators());

				track({
					eventName: 'project_status_created'
				});
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// GET FRESH LIST
			dispatch(getStatuses());
		}
	};

const updateStatusAction = (payload: ActionPayload<UpdateStatusAction>): UpdateStatusAction => ({
	type: ActionTypes.UPDATE_STATUS,
	payload
});

export const updateStatus =
	(status: Status, updateExistingEntries?: boolean): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.UPDATE_STATUS, dispatch });

		try {
			activity.begin();

			const { projectId, byProjectId } = getState().data.statuses;

			if (projectId && byProjectId[projectId]) {
				const initialStatus = byProjectId[projectId].byName[status.name];

				const partialApiStatus = preparePartialApiStatus(initialStatus, status);

				const { status: updatedStatus } = await context.api.data.statuses().updateStatus({
					projectId: Number(projectId),
					status: partialApiStatus,
					...(updateExistingEntries && {
						updateExistingEntries
					})
				});

				dispatch(updateStatusAction({ status: updatedStatus }));

				if (updateExistingEntries) {
					// MARK ENTRIES FOR REFETCH
					dispatch(setRefetchEntries());
				}

				// MARK COLLABORATORS TO REFETCH
				dispatch(setRefetchCollaborators());
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// GET FRESH LIST
			dispatch(getStatuses());
		}
	};

const deleteStatusAction = (payload: ActionPayload<DeleteStatusAction>): DeleteStatusAction => ({
	type: ActionTypes.DELETE_STATUS,
	payload
});

export const deleteStatus =
	(statusName: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.DELETE_STATUS, dispatch });

		try {
			activity.begin();

			const { projectId } = getState().data.statuses;

			if (projectId) {
				await context.api.data.statuses().deleteStatus({
					projectId: Number(projectId),
					status: {
						statusName
					}
				});

				dispatch(deleteStatusAction({ statusName }));

				dispatch(rebuild());
				// MARK ENTRIES FOR REFETCH
				dispatch(setRefetchEntries());

				// MARK COLLABORATORS TO REFETCH
				dispatch(setRefetchCollaborators());
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// GET FRESH LIST
			dispatch(getStatuses());
		}
	};

const createStatusesAction = (
	payload: ActionPayload<CreateStatusesAction>
): CreateStatusesAction => ({
	type: ActionTypes.CREATE_STATUSES,
	payload
});

export const createStatuses =
	(labels: string[]): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.CREATE_STATUSES,
			dispatch
		});

		try {
			activity.begin();

			const { projectId } = getState().data.statuses;

			if (projectId) {
				const { statuses } = await context.api.data.statuses().createStatuses({
					projectId: Number(projectId),
					statuses: labels.map((label, index) => ({
						statusLabel: label,
						statusColor: STATUS_COLORS[index % STATUS_COLORS.length]
					}))
				});

				dispatch(createStatusesAction({ statuses }));

				dispatch(rebuild());
				// MARK ENTRIES FOR REFETCH
				dispatch(setRefetchEntries());
				// MARK COLLABORATORS FOR REFETCH
				dispatch(setRefetchCollaborators());

				track({
					eventName: 'project_statuses_created_from_category_variables'
				});
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// GET FRESH LIST
			dispatch(getStatuses());
		}
	};

/**
 * Rebuilds dataset entries table and filters
 */
const rebuild = (): Thunk => dispatch => {
	// REBUILD ENTRIES
	dispatch(rebuildEntries());
	// REBUILD FILTERS
	dispatch(rebuildFilters());
};
