import { InlineTextInputForm } from './InlineTextInputForm';
import clsx from 'clsx';
import { useState } from 'react';
import { FormControl } from '../EntryForm';
import { useController } from 'react-hook-form';
import { OptionalDescriptionTooltip } from './OptionalDescriptionTooltip';
import { Asterisk } from 'components/UI/Asterisk';
import { CategoryOrientation } from '../data/useGetFormsQuery/useGetFormsQuery';

type Props = {
	categories: { label: string; value: string }[];
	allowCreate?: boolean;
	control: FormControl;
	className?: string;
	label: string;
	variableName: string;
	obligatory?: boolean;
	description?: string;
	orientation?: CategoryOrientation;

	disableLabelIds?: boolean;
};

export const CheckboxGroup = ({
	categories,
	allowCreate,
	control,
	className,
	obligatory,
	description,
	variableName,
	label,
	orientation,

	disableLabelIds
}: Props) => {
	const {
		field: { value: selectedOptions, onChange },
		fieldState
	} = useController({ control, name: variableName });

	const [addedOptions, setAddedOptions] = useState<Props['categories']>([]);

	const error = fieldState.error?.message?.toString();

	const allCategories = [...categories, ...addedOptions];

	return (
		<fieldset className={clsx('flex flex-col gap-3 flex-wrap mt-4', className)}>
			<div className="flex gap-1">
				<legend className="text-sm font-semibold">{label}</legend>

				{obligatory && <Asterisk />}

				<OptionalDescriptionTooltip description={description} />
			</div>

			<div
				className={clsx(
					'mt-3 flex gap-4 flex-wrap',
					orientation === 'vertical' && 'flex-col'
				)}
			>
				{allCategories.map(category => {
					const inputId = variableName + '_' + category.value;

					return (
						<div key={category.value} className="flex gap-2 items-center">
							<input
								{...control.register(variableName)}
								type="checkbox"
								value={category.value}
								id={!disableLabelIds ? inputId : undefined}
							/>

							<label
								className="relative flex gap-2 items-center text-sm"
								htmlFor={!disableLabelIds ? inputId : undefined}
							>
								{category.label}
							</label>
						</div>
					);
				})}

				{allowCreate && (
					<InlineTextInputForm
						addNewButtonClassName={clsx(orientation === 'vertical' && 'self-start')}
						onNewOptionSubmitted={addedOption => {
							if (addedOption === '') {
								return;
							}

							onChange([...(selectedOptions || []), addedOption]);

							setAddedOptions([
								...addedOptions,
								{ value: addedOption, label: addedOption }
							]);
						}}
						bannedOptions={allCategories.map(c => c.value)}
					/>
				)}
			</div>

			{error && <div className="text-error-500 text-sm">{error}</div>}
		</fieldset>
	);
};
