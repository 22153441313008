import { Combobox } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { InputError } from 'components/UI/Inputs/InputError';
import { InputLabel } from 'components/UI/Inputs/InputLabel';
import { Tag } from 'components/UI/Tags/Tag';
import { makeRequest } from 'features/entry-form-v2/data/makeRequest';
import { debounce } from 'lodash';
import { useCallback, useState, KeyboardEvent, useRef } from 'react';
import { UserCard } from './UserCard';
import { useSelector } from 'hooks/utils';
import { LanguageType } from 'types';

type Props = {
	users: string[];
	setUsers: (users: string[]) => void;
	organizationId: string;
	excludeEmailAddresses: string[];
	error?: string;
	label?: string;
	placeholder?: string;
};

export function UserSearchInput({
	users,
	setUsers,
	error,
	organizationId,
	excludeEmailAddresses,
	label,
	placeholder
}: Props) {
	const [inputValue, setInputValue] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const inputRef = useRef<HTMLInputElement>(null);
	const language = useSelector(state => state.ui.i18n.language);

	const updateAutocompleteQuery = useCallback(
		debounce((value: string) => {
			setSearchQuery(value);
		}, 500),
		[]
	);

	const autocompleteSuggestions = useQuery({
		enabled: searchQuery.length > 0,
		queryKey: ['autocompleteSuggestions', organizationId, searchQuery, excludeEmailAddresses],
		queryFn: async () => {
			const result = await makeRequest<{
				users: UserResult[];
			}>({
				method: 'searchUsersInOrganization',
				service: 'user',
				data: {
					userRequestByOrganization: {
						organizationId,
						queryString: searchQuery,
						filter: {
							excludeEmailAddresses
						}
					}
				}
			});

			return result;
		},
		retry: false,
		staleTime: 1000 * 60
	});

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Backspace' && inputRef.current?.value === '' && users.length > 0) {
			const newUsers = [...users];
			newUsers.pop();
			setUsers(newUsers);
		}
	};

	const unaddedUsers = autocompleteSuggestions.data?.users.filter(
		user => !users.includes(user.emailAddress)
	);

	return (
		<div className="relative text-base">
			{label && <InputLabel label={label} />}
			<Combobox
				value={users}
				onChange={users => {
					setInputValue('');
					setSearchQuery('');

					setUsers(users);
				}}
				multiple
			>
				<div
					className={`flex flex-wrap w-full border rounded-md p-2 min-h-[4rem] gap-2 transition-all duration-200 ${
						error ? 'border-red-500' : 'border-gray-300 focus-within:border-blue-500'
					}`}
				>
					{users.length > 0 && (
						<div className="flex flex-wrap gap-2 items-center">
							{users.map(user => (
								<Tag
									key={user}
									title={user}
									active
									onDelete={() => {
										setUsers(users.filter(p => p !== user));
									}}
								/>
							))}
						</div>
					)}

					<Combobox.Input
						className="min-w-[200px] flex-1 border-none outline-none px-[0.8rem]"
						ref={inputRef}
						placeholder={placeholder}
						value={inputValue}
						onChange={e => {
							setInputValue(e.target.value);
							return updateAutocompleteQuery(e.target.value);
						}}
						onKeyDown={handleKeyDown}
						autoFocus
					/>
				</div>
				<Combobox.Options className="absolute z-[1000] mt-1 w-full bg-white shadow-lg rounded-md overflow-auto focus:outline-none sm:text-sm max-h-[500px]">
					{unaddedUsers &&
						unaddedUsers.length > 0 &&
						unaddedUsers.map(user => (
							<Combobox.Option
								key={user.userid}
								value={user.emailAddress}
								className={({ active }) => `
                                ${active ? 'bg-blue-50 text-blue-900' : 'text-gray-900'}
                            `}
							>
								<UserCard user={user} />
							</Combobox.Option>
						))}

					{unaddedUsers && unaddedUsers.length === 0 && searchQuery.length > 0 && (
						<div className="cursor-default select-none relative py-2 pl-3 pr-9 text-gray-500 text-base">
							{DICTIONARY[language].emptyResult}
						</div>
					)}
				</Combobox.Options>
			</Combobox>
			<InputError error={error} />
		</div>
	);
}

export type UserResult = {
	userid: string;
	userFirstName: string;
	userSirName: string;
	emailAddress: string;
	department: string;
	phoneNumber: string;
	organization: string;
};

const DICTIONARY: Record<LanguageType, Record<string, string>> = {
	[LanguageType.English]: {
		emptyResult: 'No results found.'
	},
	[LanguageType.Norwegian]: {
		emptyResult: 'Ingen brukere funnet.'
	}
} as const;
