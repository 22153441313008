import { useEffect, useMemo, useState } from 'react';

import { ROUTES } from 'types/navigation';
import { Colors, Svgs } from 'environment';

import { Entry } from 'store/data/entries';
import { FormSet } from 'store/data/forms';
import { VariableSetData } from 'store/data/variables';
import {
	Body,
	Container,
	EmptySeriesStateWrapper,
	EntriesCount,
	Header,
	Wrapper
} from './AddEditInputSet.style';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { isVariableSetData } from 'helpers/variables';
import { SeriesEntryTable } from 'components/Dataset/AddEditForm/SeriesEntryTable/SeriesEntryTable';
import { SeriesViewType } from 'types/index';
import { updateFiltersSeriesName } from 'store/data/filters';
import { setSeriesViews, setUpdateColumnsSettingsSeriesName } from 'store/data/series';
import { TableWrapper } from 'components/Dataset/AddEditForm/SeriesEntryTable/SeriesEntryTable.style';
import { Spacer } from 'components/UI/Spacer';

import { getActiveForms, getFirstActiveForm } from 'helpers/forms';
import { isVariable } from 'helpers/variables';
import { useRouteMatch } from 'hooks/navigation';
import {
	useTranslation,
	useFormsBySetName,
	useSeriesEntries,
	useSelectedSeriesEntry,
	useVariables
} from 'hooks/store';
import { useDispatch, useSelector } from 'hooks/utils';
import { useParams } from 'react-router-dom';

interface Props {
	variableSetData: VariableSetData;
	entriesCountData: {
		count: number | null;
		fetched: boolean;
	};
	mainEntry: Entry | null;
	formSet?: FormSet;
	readOnly?: boolean;
	type?: string;
	isRevisionSelected?: boolean | undefined;
	isFormActive?: boolean;
}

export function AddEditInputSet({
	variableSetData,
	formSet,
	entriesCountData,
	isRevisionSelected,
	isFormActive
}: Props) {
	const dispatch = useDispatch();
	const { translate } = useTranslation();

	const { entryId } = useParams();
	const fullScreenSeriesName = useSelector(state => state.data.series.fullScreenSeriesName);

	const { setName, setLabel, setData } = variableSetData;

	const isEdit = useRouteMatch([ROUTES.UpdateEntry]);

	const [expandedLocal, setExpandedLocal] = useState(false);
	const [shouldRedirectToEntry, setShouldRedirectToEntry] = useState(false);

	const formsBySetName = useFormsBySetName();

	const forms = useMemo(() => formsBySetName[setName] ?? [], [formsBySetName, setName]);
	const activeForms = useMemo(() => getActiveForms(forms), [forms]);
	const firstActiveForm = useMemo(() => getFirstActiveForm(forms), [activeForms]);

	const [
		{
			data: { entries },
			fetched: entriesFetched,
			loading: loadingSeriesEntries
		},
		fetchSeriesEntries
	] = useSeriesEntries(setName, {
		lazy: true
	});

	const entriesCount = entriesFetched ? entries.length : entriesCountData.count!;

	const [_, setSelectedSeriesEntry] = useSelectedSeriesEntry();

	const hasVariables = useMemo(
		() =>
			setData.some(item => {
				if (isVariable(item)) return true;

				return item.groupVariables.length > 0;
			}),
		[setData]
	);

	useEffect(() => {
		if (shouldRedirectToEntry && entries?.length > 0) {
			goToSubEntry(entries[0].datasetentryid);
			setShouldRedirectToEntry(false);
		}
	}, [entries, shouldRedirectToEntry]);

	useEffect(() => {
		if (expandedLocal && !entriesFetched) {
			fetchSeriesEntries();
		}
	}, [expandedLocal, entriesFetched]);

	function handleExpandedState() {
		const newValue = !expandedLocal;

		setExpandedLocal(newValue);

		// UPDATE MASTER STATE
		// TODO: FIX
		// setGroupExpanded(groupName, newValue);
	}

	function goToSubEntry(subEntryId: string) {
		if (isRevisionSelected) return;

		setSelectedSeriesEntry({
			setName,
			subEntryId,
			subFormId: getFirstFormId()
		});

		dispatch(
			setSeriesViews({
				seriesViews: {
					gridView: true,
					narrowTableView: false,
					tableFullSizeView: false
				}
			})
		);
	}

	function createSubEntry() {
		if (isRevisionSelected) return;

		setSelectedSeriesEntry({
			setName,
			subEntryId: null,
			subFormId: getFirstFormId()
		});

		dispatch(
			setSeriesViews({
				seriesViews: {
					gridView: true,
					narrowTableView: false,
					tableFullSizeView: false
				}
			})
		);
	}

	function getFirstFormId() {
		return firstActiveForm?.id ?? null;
	}

	const hasOneEntry = entriesCount === 1;
	const entriesCountMessage = `${entriesCount !== null ? entriesCount : '0'} ${
		hasOneEntry
			? translate(dict => dict.dataset.addEditInputSet.entry)
			: translate(dict => dict.dataset.addEditInputSet.entries)
	}`;

	const [
		{
			data: { variablesDataArray },
			loading: loadingVariables
		}
	] = useVariables({
		initial: true,
		lazy: true
	});

	const seriesData = useMemo(() => {
		const seriesDataArray: VariableSetData[] = [];
		variablesDataArray.forEach(item => {
			if (isVariableSetData(item) && item.setName === setName)
				return seriesDataArray.push(item);
		});

		return seriesDataArray[0];
	}, [loadingVariables, setData]);

	function handleFullSizeTableNavigation(subEntryId: string) {
		if (isRevisionSelected) return;

		dispatch(updateFiltersSeriesName({ seriesName: setName }));
		dispatch(setUpdateColumnsSettingsSeriesName({ seriesName: setName }));

		setSelectedSeriesEntry({
			setName,
			subEntryId,
			subFormId: getFirstFormId()
		});

		dispatch(
			setSeriesViews({
				seriesViews: {
					gridView: false,
					narrowTableView: false,
					tableFullSizeView: true
				}
			})
		);
	}

	//FULL SCREEN SERIES TABLE VIEW TRIGGERED FROM DATASET TABLE
	useEffect(() => {
		function handleFullScreenSeriesTableView() {
			if (fullScreenSeriesName && entryId) {
				dispatch(updateFiltersSeriesName({ seriesName: fullScreenSeriesName }));
				dispatch(setUpdateColumnsSettingsSeriesName({ seriesName: fullScreenSeriesName }));

				setSelectedSeriesEntry({
					setName: fullScreenSeriesName,
					subEntryId: entryId,
					subFormId: getFirstFormId()
				});

				//TABLE VIEW

				dispatch(
					setSeriesViews({
						seriesViews: {
							tableFullSizeView: true,
							gridView: false,
							narrowTableView: false
						}
					})
				);
			}
		}

		handleFullScreenSeriesTableView();
	}, []);

	if (!hasVariables) return null;

	return (
		<Wrapper isFormActive={isFormActive}>
			<Container isFormActive={isFormActive}>
				{/* HEADER */}
				<Header onClick={handleExpandedState}>
					<Icon svg={Svgs.ChevronDown} rotate={expandedLocal ? 180 : 0} propagate />

					<Icon svg={Svgs.SetBig} marginOffset={{ x: 0.8 }} propagate />

					<Typography.Paragraph
						title={formSet?.setLabel ?? setLabel}
						style={{ flex: 1, minWidth: '4.8rem' }}
						paddingOffset={{ top: 0.2 }}
						fontweight={w => w.medium}
						ellipsis
					>
						{formSet?.setLabel ?? setLabel}
					</Typography.Paragraph>

					<EntriesCount>{entriesCountMessage}</EntriesCount>
					{!!entryId && (
						<Icon
							svg={Svgs.AddSeries}
							size={s => s.l}
							onClick={createSubEntry}
							colors={{
								hoverBackground: Colors.white,
								hover: Colors.primary.hover,
								color: Colors.primary.normal
							}}
							marginOffset={entriesCount < 1 && { right: 1.6 }}
						/>
					)}

					{entriesCount > 0 && (
						<>
							<Icon
								dataTestId="goto-series-entry-icon"
								svg={Svgs.ViewGrid}
								size={s => s.m}
								colors={{
									background: Colors.white
								}}
								marginOffset={{ left: 0.8 }}
								variant={v => v.buttonActive}
								onClick={() => goToSubEntry(entries[0].datasetentryid)}
								disabled={entries.length < 1}
							/>

							<Icon
								dataTestId="goto-series-table-icon"
								svg={Svgs.Maximize}
								size={s => s.m}
								colors={{
									background: Colors.white
								}}
								marginOffset={{ left: 0.8, right: 1.6 }}
								variant={v => v.buttonActive}
								disabled={entries.length < 1}
								onClick={() =>
									handleFullSizeTableNavigation(entries[0].datasetentryid)
								}
							/>
						</>
					)}
				</Header>

				{/* BODY */}

				{/* ON CREATE NEW ENTRY VIEW */}
				{expandedLocal && !isEdit && (
					<Body>
						<Typography.Paragraph
							marginOffset={{ top: 1.6, left: 1 }}
							color={Colors.text.caption}
						>
							{translate(dict => dict.dataset.addEditInputSet.pleaseSave)}
						</Typography.Paragraph>
					</Body>
				)}

				{/*SERIES LOADING VIEW */}
				{expandedLocal && loadingSeriesEntries && (
					<Typography.Paragraph
						marginOffset={{ top: 1.6, left: 1 }}
						paddingOffset={{ bottom: 2, left: 2 }}
						color={Colors.text.caption}
					>
						{translate(dict => dict.dataset.addEditInputSet.loadingData)}
					</Typography.Paragraph>
				)}

				{/*SERIES AS TABLES VIEW */}
				{expandedLocal && isEdit && !loadingSeriesEntries && (
					<>
						{entries.length > 0 ? (
							<SeriesEntryTable
								variableSetData={seriesData}
								tableType={SeriesViewType.EditEntryView}
								entriesData={entries}
								isRevisionSelected={isRevisionSelected}
							/>
						) : (
							<TableWrapper tableType={SeriesViewType.EditEntryView}>
								<EmptySeriesStateWrapper>
									<Svgs.EmptyStatesNoDataset height={120} />

									<Spacer size={s => s.s} />

									<Typography.Paragraph fontweight={f => f.bold}>
										{translate(dict => dict.seriesTable.emptyState.noEntries)}
									</Typography.Paragraph>

									<Spacer size={s => s.xs} />

									<Typography.Paragraph>
										{translate(
											dict => dict.seriesTable.emptyState.createFirstEntry
										)}
									</Typography.Paragraph>
								</EmptySeriesStateWrapper>
							</TableWrapper>
						)}
					</>
				)}
			</Container>
		</Wrapper>
	);
}
