import { RefCallback, ForwardedRef } from 'react';

export const mergeRefs =
	<T>(...refs: (RefCallback<T> | ForwardedRef<T>)[]) =>
	(element: T) => {
		refs.forEach(ref => {
			if (typeof ref === 'function') {
				ref(element);
			} else if (ref !== null) {
				(ref as React.MutableRefObject<T>).current = element;
			}
		});
	};
