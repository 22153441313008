export const SeriesOverviewWithData = () => {
	return (
		<div>
			<h3 className="text-base font-bold">Series overview</h3>

			<p className="mt-6 text-base">
				When there are series entries in the project, the series overview looks like this
			</p>

			<img
				className="mt-10"
				src="/img/entry-form-1-5-change-log/series_overview_with_data.png"
				alt="Showing the series overview with data"
			/>

			<p className="text-base font-semibold">
				We recommend using this table view to find relevant series entries if you need to
				edit or delete them
			</p>
		</div>
	);
};
