import { DependencyRule } from '../types';
import { variableToZodSchema } from './zodUtils';
import { backendValueToFormValue } from './parse-backend-values/backendValueToFormValue';

import {
	DependencyData,
	FilteringDependant,
	FilteringDependency,
	VisibilityDependant,
	VisibilityDependency
} from '../data/useGetVariableDependenciesQuery/useGetVariableDependenciesQuery';
import { FilteringDependencyRule, Variable, VisibilityDependencyRule } from '../types';

/**
 * isRuleSatisfied is a function that checks if a dependency rule is satisfied.
 *
 * @param rule - The dependency rule to check.
 * @param supplierValue - The value of the supplier variable.
 * @param invalidRuleReturnValue - The value to return if the rule is invalid, corrupted or otherwise not supported.
 * @returns true if the rule is satisfied, false otherwise.
 * If the rule is invalid, corrupted or otherwise not supported, we return the value of `invalidRuleReturnValue`.
 */
export function isRuleSatisfied({
	rule,
	supplierValue,
	invalidRuleReturnValue = true
}: {
	rule: DependencyRule;
	supplierValue: string | string[];
	invalidRuleReturnValue?: boolean;
}) {
	const { value: ruleValue, operator } = rule;
	const zodSchema = variableToZodSchema(rule.supplierVariable);

	if (['userDefinedUnique', 'file'].includes(rule.supplierVariable.variableType)) {
		// We don't have functionality to create rules for userDefinedUnique or file variables.
		console.error(new Error('User defined unique or file variable is not supported'), {
			rule
		});
		return invalidRuleReturnValue;
	}

	if (rule.supplierVariable.variableType === 'timeDuration') {
		// the time duration variable is not implemented as of now because it is not stored consistently
		// in the backend. Which means it is very hard to implement a sensible behavior for it.
		console.warn('Time duration variable is not implemented as of now', rule);
		return invalidRuleReturnValue;
	}

	const normalizedRuleValue = backendValueToFormValue({
		value: ruleValue,
		variable: rule.supplierVariable
	});

	const currentSupplierValue = zodSchema.safeParse(supplierValue);
	const criteriaSupplierValue = zodSchema.safeParse(normalizedRuleValue);

	if (!criteriaSupplierValue.success) {
		// If a rule is invalid, we deem it satisfied so we don't hide and or clear the field.
		// This is safest as then the user isn't stuck with a rule that is not working.
		console.error(new Error('Criteria value is not valid'), {
			rule,
			currentSupplierValue,
			criteriaSupplierValue
		});
		return invalidRuleReturnValue;
	}

	if (!currentSupplierValue.success || currentSupplierValue.data === null) {
		// When the current value is not valid or empty, we deem the rule not satisfied.
		return false;
	}

	if (rule.supplierVariable.variableType === 'categoryMultiple') {
		if (operator !== '=') {
			console.error(new Error('Operator is not = for category multiple variable'), {
				rule
			});
			return invalidRuleReturnValue;
		}
		if (!Array.isArray(currentSupplierValue.data)) {
			console.error(new Error('Current value is not an array, that should not happen'), {
				rule,
				currentSupplierValue
			});
			return invalidRuleReturnValue;
		}

		return criteriaSupplierValue.data.every((value: string) =>
			currentSupplierValue.data.includes(value)
		);
	}

	switch (operator) {
		case '=':
			if (rule.supplierVariable.variableType === 'string') {
				return currentSupplierValue.data?.includes(criteriaSupplierValue.data) ?? false;
			}
			return currentSupplierValue.data === criteriaSupplierValue.data;
		case '>':
			return currentSupplierValue.data > criteriaSupplierValue.data;
		case '<':
			return currentSupplierValue.data < criteriaSupplierValue.data;
		case '>=':
			return currentSupplierValue.data >= criteriaSupplierValue.data;
		case '<=':
			return currentSupplierValue.data <= criteriaSupplierValue.data;
		default:
			console.error(new Error('Unhandled operator'), {
				operator
			});
			return invalidRuleReturnValue;
	}
}

export function toDependencyRules(
	variables: Record<string, Variable>,
	dependencies: DependencyData['dependencies']
): DependencyRule[] {
	return dependencies
		.flatMap(dependency => dependantsToDependencyRules(dependency, variables))
		.filter((rule): rule is DependencyRule => rule !== null);
}

function dependantsToDependencyRules(
	dependency: FilteringDependency | VisibilityDependency,
	variables: Record<string, Variable>
): (DependencyRule | null)[] {
	const type = dependency.dependencyType;
	switch (type) {
		case 'Filtering':
			return dependency.dependantVariables.map((dependantVariable: FilteringDependant) =>
				mapFilteringDependencyRule(dependency, dependantVariable, variables)
			);
		case 'Visibility':
			return dependency.dependantVariables.map((dependantVariable: VisibilityDependant) =>
				mapVisibilityDependencyRule(dependency, dependantVariable, variables)
			);
		default:
			console.error(`Unknown dependency type ${type}`);
			return [];
	}
}

function mapFilteringDependencyRule(
	dependency: FilteringDependency,
	dependant: FilteringDependant,
	variables: Record<string, Variable>
): FilteringDependencyRule | null {
	const supplierVariable: Variable | undefined = variables[dependency.supplierVariableName];

	if (!supplierVariable) {
		console.error(
			`Supplier variable ${dependency.supplierVariableName} not found for dependency ${dependency.dependencyType}`
		);
		return null;
	}

	const dependantVariable: Variable | undefined = variables[dependant.dependantVariableName];

	if (!dependantVariable) {
		console.error(
			`Dependant variable ${dependant.dependantVariableName} not found for supplier variable ${dependency.supplierVariableName}`
		);
		return null;
	}

	return {
		dependencyType: 'Filtering',
		supplierVariable,
		dependantVariable,
		operator: dependant.operator,
		value: dependant.supplierValueCondition,
		filteredValues: dependant.filteredValues
	};
}

function mapVisibilityDependencyRule(
	dependency: VisibilityDependency,
	dependant: VisibilityDependant,
	variables: Record<string, Variable>
): VisibilityDependencyRule | null {
	const supplierVariable: Variable | undefined = variables[dependency.supplierVariableName];

	if (!supplierVariable) {
		console.error(
			`Supplier variable ${dependency.supplierVariableName} not found for dependency ${dependency.dependencyType}`
		);
		return null;
	}

	const dependantVariable: Variable | undefined = variables[dependant.dependantVariableName];

	if (!dependantVariable) {
		console.error(
			`Dependant variable ${dependant.dependantVariableName} not found for supplier variable ${dependency.supplierVariableName}`
		);
		return null;
	}

	return {
		dependencyType: 'Visibility',
		supplierVariable: supplierVariable,
		dependantVariable: dependantVariable,
		operator: dependant.operator,
		value: dependant.supplierValueCondition
	};
}
