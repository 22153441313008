import { useParams } from 'react-router-dom';
import { useGetSeriesNumberOfEntries } from '../data/useGetSeriesNumberOfEntriesQuery';
import { Skeleton } from '../component/Skeleton';

export const SeriesEntryCount = ({ seriesName }: { seriesName: string }) => {
	const params = useParams();
	const projectId = params['projectId'] as string;
	const entryId = params['entryId'] as string;

	const getSeriesNumberOfEntriesQuery = useGetSeriesNumberOfEntries({
		entryId,
		projectId
	});

	if (!getSeriesNumberOfEntriesQuery.data) {
		return <Skeleton className="w-20 h-8" />;
	}

	const entryCount = getSeriesNumberOfEntriesQuery.data.numberOfEntries[seriesName];

	return (
		<div className="bg-success-500/10 flex items-center justify-center px-2 py-1 rounded-md text-success-700 font-semibold">
			{entryCount} {pluralize(getSeriesNumberOfEntriesQuery.data.numberOfEntries[seriesName])}
		</div>
	);
};

const pluralize = (count: number) => {
	return count === 1 ? 'entry' : 'entries';
};
