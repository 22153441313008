import { Svgs } from 'environment';
import { EntriesOptions } from 'components/Dataset/Entries/EntriesTable/EntriesOptions';
import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { SearchInputBE } from 'components/UI/Inputs/SearchInput/SearchInputBE';
import { Button } from 'components/UI/Interactables/Button';
import { Typography } from 'components/UI/Typography';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	usePermissions,
	useProjectId,
	useEntriesSearchTerm,
	useEntriesTableVisibleColumns,
	useEntries,
	useVariables,
	useTransferEntriesOwnershipContext,
	useEntriesErrors,
	useFilters,
	useRefetchAnalyses,
	useForbidPersonalData
} from 'hooks/store';
import { Switch } from 'components/UI/Interactables/Switch';
import { useState } from 'react';
import PersonalDataWarning from 'components/Projects/PersonalDataWarning/PersonalDataWarning';
import { useShouldUseV1_5Routing } from 'features/entry-form-v2/utils/isV1_5project';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';

export function DatasetPageHeader() {
	const { translate } = useTranslation();
	const { navigate, routes } = useNavigation();
	const [, refetchAnalyses] = useRefetchAnalyses();

	const { hasDatasetWriteAccess } = usePermissions();

	const [projectId] = useProjectId();

	const { forbidPersonalData } = useForbidPersonalData(projectId ?? '');
	const [searchTerm, setSearchTerm] = useEntriesSearchTerm();
	const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm || '');

	const useV_15Routing = useShouldUseV1_5Routing();

	const [visibleColumnNames, setVisibleColumnNames] = useEntriesTableVisibleColumns();
	const [{ data: entries, loading: loadingEntries }, refreshEntries] = useEntries({
		lazy: true,
		filtered: true
	});

	const [
		{
			data: { hasVariables, hasVariableSets }
		}
	] = useVariables({ initial: true, lazy: true });
	const {
		actions: {
			getIsTransferOwnership,
			setIsTransferOwnership,
			getSelectedEntries,
			setCancelModal,
			setPreviewModal
		}
	} = useTransferEntriesOwnershipContext();

	const [{ errors: entriesErrors }] = useEntriesErrors();
	const [{ areFiltersOpen, areFiltersActive }, { toggleOpenFilters, clearFilters }] =
		useFilters();
	const hasEntries = entries.length > 0;
	const hasVariablesAndEntries = hasVariables && hasEntries;

	const areAnyFiltersActive =
		searchTerm.trim().length > 0 || visibleColumnNames.length > 0 || areFiltersActive;

	function handleCreateEntry() {
		if (!projectId) {
			console.error("No project id found, can't create entry");
			return;
		}

		if (useV_15Routing) {
			navigate(ROUTE_MAP.project.byId.dataset.create.createPath({ projectId, formId: null }));
			return;
		}

		navigate(routes.projects.dataset.create(projectId));
	}

	function cancelTransferOwnership() {
		if (getSelectedEntries().length !== 0) {
			setCancelModal(true);
		} else {
			setIsTransferOwnership(false);
		}
	}

	function handleClearAllFilters() {
		clearFilters();
		refetchAnalyses();
		if (searchTerm !== '') {
			setSearchTerm({ term: '' });
			setLocalSearchTerm('');
		}
		if (visibleColumnNames.length) setVisibleColumnNames([]);
	}

	function handlerSearchInfoTooltip() {
		navigate(`${routes.help}search-entries`);
	}

	function handleSubmitSearch(term: string) {
		if (localSearchTerm === '') return;
		setSearchTerm({ term: term });
	}

	function handleChangeTerm(term: string) {
		setLocalSearchTerm(term);
	}

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={({ addButton }) =>
					hasVariablesAndEntries && (
						<Flex>
							<SearchInputBE
								usedInHeader
								placeholder={translate(dict => dict.entriesHeader.searchEntries)}
								term={localSearchTerm}
								onChangeTerm={term => handleChangeTerm(term)}
								infoTooltipOptions={{
									tooltipText: translate(
										dict => dict.entriesHeader.searchEntriesTooltip
									),
									buttonText: translate(dict => dict.buttons.learnMore),
									buttonHandler: handlerSearchInfoTooltip
								}}
								handleSubmit={handleSubmitSearch}
								handleClear={handleClearAllFilters}
								hasSubmitButton={true}
							/>

							{hasDatasetWriteAccess &&
								!getIsTransferOwnership() &&
								entriesErrors === null &&
								addButton({
									label: translate(dict => dict.entriesHeader.createNewEntry),
									action: handleCreateEntry
								})}
						</Flex>
					)
				}
			/>
			{getIsTransferOwnership() ? (
				<Header.Title
					title={translate(dict => dict.dataset.entries.transferOwnership.pageTitle)}
					component={
						<Flex align={a => a.center}>
							<Typography.Paragraph alignCenter>
								{getSelectedEntries().length}&nbsp;
								{translate(dict => dict.dataset.entries.transferOwnership.selected)}
							</Typography.Paragraph>
							<Button
								title={translate(({ buttons }) => buttons.cancel)}
								variant={v => v.secondary}
								marginOffset={{ left: 2.4 }}
								onClick={cancelTransferOwnership}
							/>
							<Button
								title={translate(({ buttons }) => buttons.continue)}
								marginOffset={{ left: 2.4 }}
								disabled={getSelectedEntries().length === 0}
								onClick={() => {
									setPreviewModal(true);
								}}
							/>
						</Flex>
					}
				/>
			) : (
				<Header.Title
					title={
						!forbidPersonalData ? (
							translate(dict => dict.dataset.pageHeader.title)
						) : (
							<Flex>
								<Typography.H3 marginOffset={{ right: 1.9 }}>
									{translate(dict => dict.dataset.pageHeader.title)}
								</Typography.H3>
								<PersonalDataWarning bgType={t => t.grey} />
							</Flex>
						)
					}
					component={
						hasVariablesAndEntries ? (
							<Flex>
								{/* CLEAR FILTERS */}
								<Switch
									center
									label={translate(({ filters }) => filters.showFilters)}
									onChange={toggleOpenFilters}
									on={areFiltersOpen}
									marginOffset={{ right: 0.8 }}
								/>
								{areAnyFiltersActive && (
									<Button
										title={translate(dict => dict.entriesOptions.clearFilters)}
										variant={v => v.link}
										onClick={handleClearAllFilters}
									/>
								)}
								<Icon
									svg={Svgs.Refresh}
									variant={v => v.button}
									marginOffset={{ left: 2.4 }}
									onClick={() => refreshEntries()}
									disabled={loadingEntries}
								/>
								<EntriesOptions hasVariableSets={hasVariableSets} />
							</Flex>
						) : (
							<Flex>
								<Switch
									center
									label={translate(({ filters }) => filters.showFilters)}
									onChange={toggleOpenFilters}
									on={areFiltersOpen}
									marginOffset={{ right: 0.8 }}
								/>
							</Flex>
						)
					}
				/>
			)}
		</>
	);
}
