import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, CreateProject, createProject } from 'store/data/projects';

export function useCreateProject(): Operation<(project: CreateProject) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.CREATE_PROJECT);

	function handler(project: CreateProject) {
		if (loading) return;
		if (error) resetError();

		dispatch(createProject(project));
	}

	return [{ loading, error }, handler];
}
