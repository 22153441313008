import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';

import { FormElement, FormGroup, FormVariable } from 'components/Forms';
import {
	DynamicFormValue,
	DynamicFormValues,
	FormFieldPageLocationByName,
	FormGroupPaginationDataByname,
	SetGroupPaginationDataInput,
	VariableFilteringMap
} from 'store/data/entries';
import { Form } from 'store/data/forms';
import { Revision } from 'store/data/revisions';
import { VariablesData, VariableSetData, VariableSetDataArray } from 'store/data/variables';
import { BooleanMap, GetMutableState, SetMutableState } from 'types/index';

import { AddEditInputSet } from '../../AddEditInputsAndGroups/AddEditInputs/AddEditInputSet';

import { Container, Divider, VariableWrapper } from './AddEditInputsFormDesigner.style';
import { Flex } from 'components/UI/Flex';
import { Pagination } from 'components/UI/Pagination';
import { Spacer } from 'components/UI/Spacer';
import { entryFormChangePageSetEvent, entryFormChangePageEvent } from 'helpers/entries';
import { useCustomEventListener } from 'helpers/events';
import {
	isVariableVisible,
	buildVariableSetVariablesData,
	buildVariablesRichData
} from 'helpers/variables';
import { useSeriesEntriesCount, useEntryId, useEntry, useEntriesErrors } from 'hooks/store';
import { usePaginate, usePrevious } from 'hooks/utils';

interface Props {
	tooltipContainer?: HTMLDivElement;
	form: Form;
	variablesData: VariablesData;
	groupsExpanded: BooleanMap;
	openCustomsMap: BooleanMap;
	variableVisibilityMap: BooleanMap;
	variableFilteringMap: VariableFilteringMap;
	initialValues: Record<string, DynamicFormValue>;
	revision?: Revision;
	currentChange?: number;
	readOnly?: boolean;
	isRevisionSelected?: boolean;
	uniqueFieldsError?: string[];
	usedAsThumbnail?: boolean;
	dataTestIdEntryNumber?: number;
	setGroupExpanded: (groupName: string, value?: boolean) => void;
	setFormFieldPageLocationByName: SetMutableState<FormFieldPageLocationByName>;
	getGroupPaginationDataByName: GetMutableState<FormGroupPaginationDataByname>;
	setGroupPaginationData: (input: SetGroupPaginationDataInput) => void;
	/**
	 * Provided for scope
	 */
	setName?: string;
}

export function AddEditInputsFormDesigner({
	tooltipContainer,
	dataTestIdEntryNumber,
	form,
	variablesData,
	groupsExpanded,
	openCustomsMap,
	variableVisibilityMap,
	variableFilteringMap,
	initialValues,
	revision,
	currentChange,
	readOnly,
	isRevisionSelected,
	uniqueFieldsError,
	usedAsThumbnail,
	setGroupExpanded,
	setFormFieldPageLocationByName,
	getGroupPaginationDataByName,
	setGroupPaginationData,
	/**
	 * Provided for scope
	 */
	setName
}: Props) {
	const formContext = useFormContext() as UseFormReturn<DynamicFormValues> | undefined;

	const [{ data: entriesCount, fetched: isEntriesCountFetched }] = useSeriesEntriesCount({
		lazy: true
	});

	const [entryId] = useEntryId();
	const [{ data: entry }] = useEntry();
	const [{ errors: entriesErrors }] = useEntriesErrors();

	const { elements, groups, sets, elementsOrder } = form;
	const { variablesMap, variableSetsMap } = variablesData;

	const {
		pageIndex,
		pageSize,
		pagesCount,
		shouldPaginate,
		page,
		pages,
		changePage,
		changePageSize
	} = usePaginate(elementsOrder, {
		threshold: 50,
		pageSize: 50
	});

	// TODO: REFACTOR
	const prevPages = usePrevious(pages);
	useMemo(() => {
		if (isEqual(prevPages, pages)) return;

		const formFieldPageLocationByName: FormFieldPageLocationByName = {};

		pages.forEach((page, pageIndex) => {
			page.forEach(row => {
				// TWO COLUMNS - [FORM ELEMENT, FORM ELEMENT]
				if (Array.isArray(row)) {
					row.forEach(elementId => {
						const element = elements[elementId];

						if (!element) return;

						const { variableRef } = element;

						if (variableRef)
							formFieldPageLocationByName[variableRef] = {
								pageIndex
							};

						return;
					});
				}
				// ONE COLUMN - (FORM ELEMENT / FORM GROUP / FORM SET)
				else {
					const elementId = row;

					const formSet = sets[elementId];

					// FORM SET - OMIT
					if (formSet) return;

					const group = groups[elementId];

					// FORM GROUP
					if (group) {
						group.elementsOrder.forEach(row => {
							// TWO COLUMNS - [FORM ELEMENT, FORM ELEMENT]
							if (Array.isArray(row)) {
								row.forEach(elementId => {
									const element = elements[elementId];

									if (!element) return;

									const { variableRef } = element;

									if (variableRef) {
										formFieldPageLocationByName[variableRef] = {
											pageIndex,
											group: {
												groupName: group.groupName
											}
										};
									}

									return;
								});
							}
							// ONE COLUMN - FORM ELEMENT
							else {
								const elementId = row;

								// FORM ELEMENT
								const element = elements[elementId];

								const { variableRef } = element;

								// FORM VARIABLE
								if (variableRef) {
									formFieldPageLocationByName[variableRef] = {
										pageIndex,
										group: { groupName: group.groupName }
									};
								}
							}
						});

						return;
					}

					// FORM ELEMENT
					const element = elements[elementId];

					const { variableRef } = element;

					// FORM VARIABLE
					if (variableRef)
						formFieldPageLocationByName[variableRef] = {
							pageIndex
						};
				}
			});
		});

		setFormFieldPageLocationByName(formFieldPageLocationByName);
	}, [pages]);

	// CHANGE PAGE EVENT LISTENER
	useCustomEventListener(
		setName ? entryFormChangePageSetEvent : entryFormChangePageEvent,
		{
			onListen: ({ pageIndex }) => changePage(pageIndex)
		},
		[]
	);

	function variableHasChanges(variableName: string) {
		return revision?.changes.variableNames.includes(variableName);
	}

	function getPagination(params?: { bottom: boolean }) {
		return (
			<Pagination
				className="pagination"
				totalCountLabel="form elements"
				pageIndex={pageIndex}
				pageSize={pageSize}
				pagesCount={pagesCount}
				changePage={changePage}
				changePageSize={changePageSize}
				showCounts={false}
				simpleVersion={params?.bottom}
			/>
		);
	}

	function isVariableErrored(variableName: string) {
		if (!(entryId && entriesErrors)) return;

		const entryErrors = entriesErrors.rows[entryId] ?? [];

		return entryErrors.includes(variableName);
	}

	function isUniqueError(variableName: string) {
		if (uniqueFieldsError?.includes(variableName)) {
			return `Value must be unique`;
		}
	}

	const groupPaginationDataByName = getGroupPaginationDataByName();

	function dataTestIdValue(variableName: string) {
		if (!usedAsThumbnail && dataTestIdEntryNumber) {
			return variableName.replace(/\s/g, '').toLowerCase() + dataTestIdEntryNumber;
		}

		return variableName.replace(/\s/g, '').toLowerCase();
	}

	return (
		<Container>
			{/* UPPER PAGINATION */}
			{shouldPaginate && (
				<Flex column fullWidth>
					{getPagination()}
					<Divider />
					<Spacer size={s => s.xs} />
				</Flex>
			)}

			{page.map(row => {
				// TWO COLUMNS - [FORM ELEMENT, FORM ELEMENT]
				if (Array.isArray(row)) {
					return (
						<Flex key={`two-column-${row[0]}-${row[1]}}`} className="two-column-row">
							{row.map(elementId => {
								const element = elements[elementId];

								if (!element) return null;

								const { variableRef } = element;

								if (!variableRef) return null;

								if (!isVariableVisible(variableRef, variableVisibilityMap)) {
									return null;
								}

								return (
									<VariableWrapper
										key={elementId}
										id={usedAsThumbnail ? undefined : variableRef}
										data-test-id={dataTestIdValue(variableRef)}
										hasChanges={
											variableHasChanges(variableRef) && !!isRevisionSelected
										}
										halfWidth
									>
										<FormVariable
											initialValue={initialValues[variableRef]}
											tooltipContainer={tooltipContainer ?? undefined}
											dataTestIdEntryNumber={dataTestIdEntryNumber}
											element={element}
											variable={variablesMap[variableRef]}
											formContext={formContext}
											revision={revision}
											openCustomsMap={openCustomsMap}
											variableFilteringMap={variableFilteringMap}
											isRevisionSelected={isRevisionSelected}
											// disabled={readOnly}
											readOnly={readOnly}
											borderError={isVariableErrored(variableRef)}
											uniqueError={isUniqueError(variableRef)}
										/>
									</VariableWrapper>
								);
							})}
						</Flex>
					);
				}
				// ONE COLUMN - (FORM ELEMENT / FORM GROUP / FORM SET)
				else {
					const elementId = row;

					const formSet = sets[elementId];

					// FORM SET
					if (formSet) {
						const { setName } = formSet;

						const variableSet = variableSetsMap[setName];

						const variableSetVariablesData = buildVariableSetVariablesData({
							setName,
							variablesData
						});

						const { variablesDataArray } =
							buildVariablesRichData(variableSetVariablesData);

						const variableSetData: VariableSetData = {
							setName: variableSet.setName,
							setLabel: variableSet.setLabel,
							setData: variablesDataArray as VariableSetDataArray,
							identifier: {
								variableName: variableSet.identifier.variableName
							},
							aggregationRules: variableSet.aggregationRules
						};

						return (
							<AddEditInputSet
								isFormActive={!!form.id}
								key={`variable_set-${variableSetData.setName}`}
								variableSetData={variableSetData}
								entriesCountData={{
									count: entriesCount[variableSetData.setName] ?? null,
									fetched: !!isEntriesCountFetched
								}}
								mainEntry={entry}
								formSet={formSet}
								readOnly={readOnly}
							/>
						);
					}

					const group = groups[elementId];

					// FORM GROUP
					if (group) {
						return (
							<FormGroup
								key={elementId}
								formGroup={group}
								elements={elements}
								revision={revision}
								isRevisionSelected={isRevisionSelected}
								expanded={
									groupsExpanded[group.groupName] ||
									(Object.values(elements).filter(el => {
										const { variableRef } = el;

										return (
											currentChange &&
											variableRef &&
											revision?.changes.variableNames.includes(variableRef) &&
											revision?.changes.variableNames.indexOf(variableRef) ===
												currentChange - 1
										);
									}).length > 0 &&
										!!isRevisionSelected)
								}
								setGroupExpanded={setGroupExpanded}
								groupPaginationData={groupPaginationDataByName[group.groupName]}
								setGroupPaginationData={setGroupPaginationData}
								renderElement={(element, options) => {
									const { elementId, variableRef } = element;

									if (!variableRef) return null;

									if (!isVariableVisible(variableRef, variableVisibilityMap)) {
										return null;
									}

									return (
										<VariableWrapper
											key={elementId}
											id={usedAsThumbnail ? undefined : variableRef}
											data-test-id={dataTestIdValue(variableRef)}
											hasChanges={
												variableHasChanges(variableRef) &&
												!!isRevisionSelected
											}
											halfWidth={options?.grouped}
										>
											<FormVariable
												initialValue={initialValues[variableRef]}
												tooltipContainer={tooltipContainer ?? undefined}
												dataTestIdEntryNumber={dataTestIdEntryNumber}
												element={element}
												variable={variablesMap[variableRef]}
												formContext={formContext}
												revision={revision}
												openCustomsMap={openCustomsMap}
												variableFilteringMap={variableFilteringMap}
												isRevisionSelected={isRevisionSelected}
												// disabled={readOnly}
												readOnly={readOnly}
												borderError={isVariableErrored(variableRef)}
												uniqueError={isUniqueError(variableRef)}
											/>
										</VariableWrapper>
									);
								}}
							/>
						);
					}

					// FORM ELEMENT
					const element = elements[elementId];

					if (!element) return null;

					const { variableRef } = element;

					// FORM VARIABLE
					if (variableRef) {
						if (!isVariableVisible(variableRef, variableVisibilityMap)) return null;

						return (
							<VariableWrapper
								key={elementId}
								id={usedAsThumbnail ? undefined : variableRef}
								hasChanges={variableHasChanges(variableRef) && !!isRevisionSelected}
								data-test-id={dataTestIdValue(variableRef)}
							>
								<FormVariable
									initialValue={initialValues[variableRef]}
									tooltipContainer={tooltipContainer ?? undefined}
									dataTestIdEntryNumber={dataTestIdEntryNumber}
									element={element}
									variable={variablesMap[variableRef]}
									formContext={formContext}
									revision={revision}
									openCustomsMap={openCustomsMap}
									variableFilteringMap={variableFilteringMap}
									isRevisionSelected={isRevisionSelected}
									// disabled={readOnly}
									readOnly={readOnly}
									borderError={isVariableErrored(variableRef)}
									uniqueError={isUniqueError(variableRef)}
								/>
							</VariableWrapper>
						);
					}
					// FORM ELEMENT (ex: SUBTITLE / TEXT / SEPARATOR)
					else {
						return <FormElement key={elementId} element={element} />;
					}
				}
			})}

			{/* LOWER PAGINATION */}
			{shouldPaginate && (
				<Flex column fullWidth>
					<Spacer size={s => s.xs} />
					<Divider />
					<Flex
						css={`
							.pagination {
								width: unset;
							}
						`}
						fullWidth
					>
						<Flex fullWidth />
						{getPagination({ bottom: true })}
					</Flex>
				</Flex>
			)}
		</Container>
	);
}
