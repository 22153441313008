import { useState, useMemo, useEffect } from 'react';
import { Flex } from 'components/UI/Flex';
import { AggregationRulesTable } from '../SeriesEntryForm/AggregationRulesTable';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import {
	useEntry,
	useSeriesEntries,
	useTranslation,
	useVariables,
	useVariablesData
} from 'hooks/store';

import { buildVariableSetVariablesData } from 'helpers/variables';
import { buildVariablesRichData } from 'helpers/variables';
import { getSeriesRows } from 'helpers/series';
import { isVariableSetData } from 'helpers/variables';
import { SeriesEntryTable } from '../SeriesEntryTable/SeriesEntryTable';
import { VariableSet } from 'api/data/variables';
import { VariableSetData } from 'store/data/variables';
import { Container } from './SeriesTableView.style';
import { ROUTES, SeriesViewType } from 'types/index';
import { Header } from 'components/Header';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { SeriesOptions } from '../SeriesOptions/SeriesOptions';
import { useSeriesFilters } from 'hooks/store/data/useSeriesFilters/useSeriesFilters';
import { useRouteMatch } from 'hooks/navigation';
import { useSelector } from 'hooks/utils';
import { useTracking } from 'app/tracking/TrackingProvider';
import { debounce } from 'lodash';

interface Props {
	variableSet: VariableSet;
	currentSeriesIndex: number;
}

interface ExportSeriesProps {
	label: string;
	value: string;
	checked: boolean;
}
export function SeriesTableView({ variableSet }: Props) {
	const { track } = useTracking();

	const { translate } = useTranslation();
	const [searchTerm, setSearchTerm] = useState('');

	const debouncedTrack = useMemo(
		() =>
			debounce(() => {
				track({
					eventName: 'series_table_searched'
				});
			}, 300),
		[] // Empty dependency array since we only want to create this once
	);
	useEffect(() => {
		if (searchTerm) {
			// Only track if there's actually a search term
			debouncedTrack();
		}

		// Cleanup function to cancel any pending debounced calls
		return () => {
			debouncedTrack.cancel();
		};
	}, [searchTerm, debouncedTrack]);

	const { seriesViews } = useSelector(state => state.data.series);

	const { setName, aggregationRules, setLabel } = variableSet;

	const seriesCurrentExportItems: ExportSeriesProps[] = useMemo(() => {
		const seriesData = [
			{
				label: setLabel,
				value: setName,
				checked: true
			}
		];

		return seriesData;
	}, []);

	const [{ data: mainEntry }] = useEntry();

	const isEdit = useRouteMatch([ROUTES.UpdateEntry]);

	const [
		{
			data: { entries },
			loading: loadingEntries
		},
		refreshEntries
	] = useSeriesEntries(setName, {
		lazy: !isEdit
	});

	const variablesData = useVariablesData({ initial: true });

	const variableSetVariablesData = useMemo(
		() =>
			buildVariableSetVariablesData({
				setName,
				variablesData
			}),
		[setName, variablesData]
	);

	const { variablesMap } = useMemo(
		() => buildVariablesRichData(variableSetVariablesData),
		[variableSetVariablesData]
	);

	const [
		{
			data: { variablesDataArray },
			loading: loadingVriablesData
		}
	] = useVariables({
		initial: true,
		lazy: true
	});

	const seriesData = useMemo(() => {
		const seriesData: VariableSetData[] = [];

		variablesDataArray.forEach(item => {
			if (isVariableSetData(item) && item.setName === setName) return seriesData.push(item);
		});

		return seriesData[0];
	}, [loadingVriablesData]);

	//SERIES FILTERS
	const [{ seriesFilters }] = useSeriesFilters();

	const filteredEntriesRows = useMemo(
		() =>
			getSeriesRows({
				entries: entries,
				searchTerm,
				variablesMap,
				seriesFilters
			}),
		[entries, searchTerm, variablesMap, seriesFilters]
	);

	function theNumberOfEntriesShown() {
		if (filteredEntriesRows.length <= 1) {
			return `${translate(dict => dict.seriesTable.showing)} ${
				filteredEntriesRows.length
			} ${translate(dict => dict.seriesTable.entry)}`;
		}

		return `${translate(dict => dict.seriesTable.showing)} ${
			filteredEntriesRows.length
		} ${translate(dict => dict.seriesTable.entries)}`;
	}

	return (
		<>
			{seriesViews.tableFullSizeView && (
				<Header.Navigation
					rightComponent={
						<Flex>
							{/* CLEAR FILTERS */}
							<SearchInput
								usedInHeader
								placeholder={translate(
									dict => dict.seriesTable.seriesHeader.searchEntries
								)}
								term={searchTerm}
								onChangeTerm={e => setSearchTerm(e)}
							/>

							<Icon
								svg={Svgs.Refresh}
								variant={v => v.button}
								marginOffset={{ left: 2.4 }}
								onClick={refreshEntries}
								disabled={loadingEntries}
							/>

							{/* MORE BUTTON */}
							<SeriesOptions
								seriesData={seriesData}
								currentSeriesItems={seriesCurrentExportItems}
							/>
						</Flex>
					}
					leftComponent={false}
				/>
			)}

			<Container seriesView={seriesViews}>
				<Flex paddingOffset={{ x: 1 }} column style={{ height: '100%' }}>
					{!seriesViews.gridView && (
						<>
							{aggregationRules.length > 0 ? (
								<>
									{seriesViews.tableFullSizeView ? (
										<Spacer size={s => s.m} />
									) : (
										<Spacer size={s => s.xl} />
									)}

									<AggregationRulesTable
										aggregationRules={aggregationRules}
										mainEntry={mainEntry}
										variablesMap={variablesMap}
									/>

									<Spacer size={s => s.l} />
								</>
							) : (
								<Spacer size={s => s.xl} />
							)}

							{!seriesViews.tableFullSizeView && (
								<Flex marginOffset={{ bottom: 0.8 }}>
									<Typography.Caption>
										{theNumberOfEntriesShown()}
									</Typography.Caption>
								</Flex>
							)}

							<SeriesEntryTable
								variableSetData={seriesData}
								tableType={SeriesViewType.NarrowTableView}
								entriesData={filteredEntriesRows}
							/>
						</>
					)}
				</Flex>
			</Container>
		</>
	);
}
