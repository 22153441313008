import { get, isEmpty, isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import {
	useDeepCompareCallback,
	useDispatch,
	useEffectOnce,
	usePrevious,
	useSelector
} from 'hooks/utils';
import {
	AnalysisV2,
	CompareNumericAnalysisV2,
	CorrelationsAnalysis,
	KaplanMeierAnalysisV2,
	PlotNumericAnalysis,
	PlotNumericAnalysisV2
} from 'api/data/analyses';
import {
	ActionTypes,
	getCompareNumericV2,
	getCorrelationsV2,
	getDensityPlotV2,
	getExploreV2,
	getIndependentStatisticsV2,
	getKruskalStatisticsV2,
	getLinearRegressionStatisticsV2,
	getMannWhitneyStatisticsV2,
	getOneWayAnovaStatisticsV2,
	getOneWayManovaStatisticsV2,
	getShapiroStatisticsV2,
	getPearsonStatisticsV2,
	getSpearmanStatisticsV2,
	getTukeyStatisticsV2,
	getTwoWayAnovaStatisticsV2,
	getTwoWayManovaStatisticsV2,
	getTimeCourseV2,
	selectIsAnalysisFetched,
	getLogisticRegressionV2,
	getCrosstabV2,
	getFisherStatisticsV2,
	getChiSquareStatisticsV2,
	getMcNemarStatisticsV2,
	getFrequenciesV2,
	getComparePairedV2,
	getKaplanMeierV2,
	getLogRankStatisticsV2,
	getPairedTTestStatisticsV2,
	getPairedWilcoxonStatisticsV2
} from 'store/data/analyses';
import { AnalysisResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import { canFetchAnalysis, isAnalysisInputValid } from 'helpers/analysis';

import { useAnalysisById } from './useAnalysisById';
import { useAreFiltersValid } from '../filters/useAreFiltersValid';
import { useRefetchAnalyses } from './useRefetchAnalyses';
import { useAnalysisActiveTab } from './useAnalysisActiveTab';
import { useRefetchStatistics } from './useRefetchStatistics';
import { clearError } from 'store/ui/activities';
import { useGetErrorByType } from 'hooks/store/utils/useGetErrorByType';
import { AnalysisType, AnalysisTypeV2 } from 'api/data/analyses/constants';

interface Props {
	id: string;
	actionType: ActionTypes;
	refetchDependencies?: string[];
	initialFetchDependency?: string | null;
}

// exclude PlotNumeric - it would be too difficult and confusing to abstract the logic for that one;
// exclude NumberPlotXY - deprecated!
type AnalysesType = Exclude<
	AnalysisTypeV2,
	AnalysisType.PlotNumeric | AnalysisType.PlotNumericV2 | AnalysisType.NumberPlotXY
>;
export type Analyses = Exclude<AnalysisV2, PlotNumericAnalysis & PlotNumericAnalysisV2>;

export const handlersMap: Record<AnalysesType, (analysis?: Analyses) => void> = {
	[AnalysisType.TimeCourseV2]: analysis => analysis && getTimeCourseV2(analysis.id),
	[AnalysisType.ExploreV2]: analysis => analysis && getExploreV2(analysis.id),
	[AnalysisType.CompareNumericV2]: analysis => analysis && getCompareNumericV2(analysis.id),
	// here for backwards compatibility with snapshots that have old names!
	// [AnalysisType.CompareNumericInit]: () => undefined,
	[AnalysisType.CrosstabV2]: analysis => analysis && getCrosstabV2(analysis.id),
	[AnalysisType.FrequenciesV2]: analysis => analysis && getFrequenciesV2(analysis.id),
	[AnalysisType.KaplanV2]: analysis => analysis && getKaplanMeierV2(analysis.id),
	[AnalysisType.CorrelationsV2]: analysis => analysis && getCorrelationsV2(analysis.id),
	[AnalysisType.DensityPlotV2]: analysis => analysis && getDensityPlotV2(analysis.id),
	[AnalysisType.ComparePairedV2]: analysis => analysis && getComparePairedV2(analysis.id),
	[AnalysisType.LogisticRegressionV2]: analysis =>
		analysis && getLogisticRegressionV2(analysis.id)
};

const statisticsMap: Record<string, (id: string) => void> = {
	/**
	 * CORRELATIONS STATISTICS
	 */
	pearsonV2: (id: string) => getPearsonStatisticsV2(id),
	spearmanV2: (id: string) => getSpearmanStatisticsV2(id),
	linearRegressionV2: (id: string) => getLinearRegressionStatisticsV2(id),
	/**
	 * COMPARE NUMERIC
	 */
	shapiroV2: (id: string) => getShapiroStatisticsV2(id),
	mannWhitneyV2: (id: string) => getMannWhitneyStatisticsV2(id),
	independentV2: (id: string) => getIndependentStatisticsV2(id),
	oneWayAnovaV2: (id: string) => getOneWayAnovaStatisticsV2(id),
	twoWayAnovaV2: (id: string) => getTwoWayAnovaStatisticsV2(id),
	tukeyV2: (id: string) => getTukeyStatisticsV2(id),
	kruskalV2: (id: string) => getKruskalStatisticsV2(id),
	twoWayManovaV2: (id: string) => getTwoWayManovaStatisticsV2(id),
	oneWayManovaV2: (id: string) => getOneWayManovaStatisticsV2(id),
	/**
	 * KAPLAN MEIER STATISTICS
	 */
	logRankV2: (id: string) => getLogRankStatisticsV2(id),
	confidenceIntervalsV2: (id: string) => id && { type: '' },
	patientsV2: (id: string) => id && { type: '' },
	/**
	 * CROSSTAB STATISTICS
	 */
	fisherV2: (id: string) => getFisherStatisticsV2(id),
	chiSquareV2: (id: string) => getChiSquareStatisticsV2(id),
	mcNemarV2: (id: string) => getMcNemarStatisticsV2(id),
	/**
	 * COMPARE PAIRED STATISTICS
	 */
	pairedTTestV2: (id: string) => getPairedTTestStatisticsV2(id),
	pairedWilcoxonV2: (id: string) => getPairedWilcoxonStatisticsV2(id)
};

/**
 *
 *  @refetchDependencies
 * 	path of value(s) in analysis object that will be compared to previous instance and trigger refetch if different;
 *  (default: [input.variables])
 *	@initialFetchDependency
 *  path of value in analysis object that will be checked for emptiness to trigger initial fetch;
 *  (default: output.dataset)
 */

export function useFetchAnalysis({
	id,
	actionType,
	refetchDependencies = ['input.variables'],
	initialFetchDependency = 'output.dataset'
}: Props): AnalysisResult {
	const analysis = useAnalysisById(id) as Exclude<AnalysisV2, PlotNumericAnalysis>;
	const {
		options: { open }
	} = analysis;

	const [activeTab] = useAnalysisActiveTab(id);

	const [activeTabType, setActiveTabType] = useState(activeTab ?? 0);

	const canDispatch = isAnalysisInputValid(analysis);
	const dispatch = useDispatch(canDispatch);

	const areFiltersValid = useAreFiltersValid();
	const [shouldRefetchAllAnalyses] = useRefetchAnalyses();
	const [{ loading, error }] = useActivity(actionType, id);
	const errorId = useGetErrorByType(actionType, id)?.uuid;
	const canFetch = canFetchAnalysis(open, loading, areFiltersValid, canDispatch);
	const fetched = useSelector(state => selectIsAnalysisFetched(state.data.analyses, id));

	// AUTO SELECT ACTIVE TAB EFFECT
	useEffect(() => {
		if (activeTab !== undefined && activeTabType !== activeTab) {
			setActiveTabType(activeTab);
		}
	}, [activeTab, activeTabType]);

	// FETCH ANALYSIS ON FIRST MOUNT
	useEffectOnce(() => {
		if (!canFetch || fetched || !initialFetchDependency) return;

		if (isEmpty(get(analysis, initialFetchDependency))) {
			handler();
		}
	});

	// REFETCH ANALYSIS LOGIC
	const prevAnalysis = usePrevious(analysis);
	useEffect(() => {
		if (!canFetch) return;

		if (shouldRefetchAllAnalyses) return handler();

		// check for dependencies changes and refetch;
		let shouldRefetchAnalysis = false;
		if (!!prevAnalysis && refetchDependencies) {
			const triggerRefetch = refetchDependencies.some(dependency => {
				return !isEqual(get(analysis, dependency), get(prevAnalysis, dependency));
			});
			shouldRefetchAnalysis = triggerRefetch;
		}

		if (shouldRefetchAnalysis) handler();
	}, [canFetch, shouldRefetchAllAnalyses, analysis]);

	// REFETCH STATISTICS LOGIC
	const statisticsObject =
		(analysis as CorrelationsAnalysis | CompareNumericAnalysisV2 | KaplanMeierAnalysisV2).input
			.statistics ?? [];

	const statisticsFlags = Object.values(statisticsObject).map(f => !!f);

	const statisticsCallbacks = Object.keys(statisticsObject).map(key => {
		return () => dispatch(statisticsMap[key](id));
	});
	useRefetchStatistics(statisticsFlags, statisticsCallbacks, {
		condition: canFetch
	});

	const handler = useDeepCompareCallback(() => {
		const handler = handlersMap[analysis.type as AnalysesType];
		dispatch(handler(analysis));
		if (errorId) dispatch(clearError({ uuid: errorId, type: actionType }));
	}, [analysis, handlersMap, errorId]);

	return { loading, error: error || !areFiltersValid };
}
