import { ProjectType, ProjectStatus } from 'types/data/projects/constants';
import { Title, NarrowContainer } from 'components/Projects/CreateAndImport';
import { InputType, SetState } from 'types/index';
import {
	createProject,
	CreateProject,
	ProjectFormValues,
	ProjectMetadata,
	ProjectMetadataType
} from 'store/data/projects';
import { Column, Row } from './CreateProjectForm.style';
import { Input } from 'components/UI/Inputs/Input';
import { Spacer } from 'components/UI/Spacer';
import { RequiredFields } from 'components/UI/RequiredFields';
import { StickyFooter } from 'components/UI/StickyFooter';
import { useNavigation } from 'hooks/navigation';
import { useProjectMetadataDefinition, useTranslation } from 'hooks/store';
import { useCompletedAction, useEffectOnce } from 'hooks/utils';
import {
	getInitialMetadataFormValues,
	parseFormValuesToProject,
	transforMetadataParametersToFormRows,
	validateProjectForm
} from 'helpers/projects/projectMetadata';
import { InfoTooltip } from 'components/UI/Interactables/InfoTooltip';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { parseFromApiMetadata } from 'store/data/projects/parsers';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'hooks/ui/useReactForm';
import { useState } from 'react';
import { InfoMessage } from 'components/UI/InfoMessage';

interface Props {
	draftProject: CreateProject | null;
	setDraftProject: SetState<CreateProject | null>;
	createProjectController: {
		loading: boolean;
		error: boolean;
		create: (...input: Parameters<typeof createProject>) => void;
	};
	onFinish: () => void;
}

export function CreateProjectForm({ draftProject, createProjectController, onFinish }: Props) {
	const { routes, navigate } = useNavigation();
	const { translate } = useTranslation();

	const [endDateError, setEndDateError] = useState<string>('');

	const [{ data: projectMetadataDefinition }] = useProjectMetadataDefinition();

	const {
		loading: creatingProject,
		error: errorCreatingProject,
		create: createProject
	} = createProjectController;

	useCompletedAction(creatingProject, errorCreatingProject, onFinish);

	// APPLY DRAFT PROJECT (IF EXISTS)
	useEffectOnce(() => {
		if (draftProject) {
			const { metadata, ...draftProjectValues } = draftProject;
			Object.keys(draftProjectValues).forEach(key => {
				setValue(
					key as keyof Omit<CreateProject, 'metadata'>,
					draftProjectValues[key as keyof Omit<CreateProject, 'metadata'>] ?? '',
					{
						shouldDirty: true,
						shouldValidate: true
					}
				);
			});

			const parsedMetadata = parseFromApiMetadata(metadata);

			if (parsedMetadata)
				Object.keys(parsedMetadata).forEach(key =>
					setValue(
						key as keyof ProjectMetadata,
						parsedMetadata[key as keyof ProjectMetadata],
						{
							shouldDirty: true,
							shouldValidate: true
						}
					)
				);
		}
	});

	const initialMetadataValues = getInitialMetadataFormValues(projectMetadataDefinition);

	const initialValues: ProjectFormValues = {
		projectName: '',
		givenProjectNumber: '',
		status: ProjectStatus.Ongoing,
		projectType: ProjectType.CORE,
		projectEndDate: '',
		description: '',
		slideFolderURL: '',
		...initialMetadataValues
	};

	const {
		register,
		setValue,
		getValues,
		handleSubmit,
		formState: { isDirty, touchedFields, isValid },
		control,
		watch
	} = useForm({
		defaultValues: initialValues,
		reValidateMode: 'onChange',
		mode: 'onChange',
		criteriaMode: 'all'
	});

	const { errors, requiredFields } = validateProjectForm(
		projectMetadataDefinition,
		watch,
		translate
	);

	const canSubmitForm = isDirty && isValid && Object.keys(errors).length === 0;

	const handleTrimOnBlur = useCallback(
		(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			const { name, value } = e.target;

			setValue(name as any, value.trim() as any);
		},
		[]
	);

	const handleFormSubmit = handleSubmit(() => {
		if (isDirty && !creatingProject) {
			trimFields();

			const values = { ...getValues() };

			const parsedValues = { ...values };

			if (!['1', '2'].includes(String(values['metadata_projectType']))) {
				parsedValues['metadata_archiveNumber'] = '';
			}

			createProject(parseFormValuesToProject(parsedValues, projectMetadataDefinition));
		}
	});

	function trimFields() {
		const values = { ...getValues() };

		setValue('projectName', values.projectName.toString().trim());
		setValue('description', values.description.toString().trim());
		setValue('givenProjectNumber', values.givenProjectNumber.toString().trim());

		projectMetadataDefinition.metadataDefinition &&
			projectMetadataDefinition.metadataDefinition.forEach(metadataParameter => {
				if (values[metadataParameter.name])
					setValue(
						`${metadataParameter.name}`,
						values[metadataParameter.name].toString().trim()
					);
			});
	}

	function checkProjectEndDate(dateStr: string) {
		if (dateStr === '' && endDateError !== '') {
			setEndDateError('');
			return;
		}
		const date = new Date(dateStr);

		date.setHours(0, 0, 0, 0);

		const now = new Date(new Date().setHours(0, 0, 0, 0));

		if (date < now) {
			setEndDateError(translate(dict => dict.createProject.projectEndDateError));
		} else {
			if (endDateError.length > 0) {
				setEndDateError('');
			}
		}
	}

	const formMetadataParameters = transforMetadataParametersToFormRows(projectMetadataDefinition);

	return (
		<>
			<NarrowContainer>
				<Form onSubmit={handleFormSubmit}>
					<Title>
						{translate(({ projects }) => projects.createAndImport.title.create)}
					</Title>

					<Input
						{...register('projectName', {
							onBlur: handleTrimOnBlur
						})}
						type={InputType.Text}
						label={translate(dict => dict.createProject.projectTitle)}
						error={
							touchedFields.projectName ? (errors.projectName?.message as string) : ''
						}
						dataTestId="project-name-input"
						required
						autoFocus
					/>
					<Spacer size={s => s.s} />
					<Input
						{...register('description', {
							onBlur: handleTrimOnBlur
						})}
						type={InputType.Textarea}
						label={translate(dict => dict.createProject.projectDescription)}
						dataTestId="project-description-input"
						rows={4}
					/>

					<Spacer size={s => s.s} />
					<Row>
						<Column>
							<Input
								{...register('givenProjectNumber', {
									onBlur: handleTrimOnBlur
								})}
								type={InputType.Text}
								label={translate(dict => dict.createProject.projectNumber)}
							/>
						</Column>
						<Column>
							<Controller
								control={control}
								name="projectEndDate"
								render={({ field: { value } }) => (
									<Input
										type={InputType.Date}
										value={value as number}
										label={translate(dict => dict.createProject.projectEndDate)}
										error={endDateError}
										onDateChange={({ formattedDate }) => {
											checkProjectEndDate(formattedDate);
											setValue('projectEndDate', formattedDate, {
												shouldDirty: true,
												shouldValidate: true
											});
										}}
									/>
								)}
							/>
						</Column>
					</Row>

					{formMetadataParameters.length > 0 && (
						<>
							<Spacer size={s => s.s} />
							{formMetadataParameters.map((metadataParametersRow, i) => {
								return (
									<div key={i}>
										<Spacer size={s => s.s} />
										<Row>
											{metadataParametersRow.map(metadataParameter => (
												<Column key={metadataParameter.name}>
													{metadataParameter.type ===
														ProjectMetadataType.String && (
														<Input
															{...register(
																`${metadataParameter.name}`,
																{
																	onBlur: handleTrimOnBlur
																}
															)}
															placeholder={translate(
																() => metadataParameter.placeholder
															)}
															type={InputType.Text}
															label={translate(
																() => metadataParameter.label
															)}
															error={
																touchedFields[
																	metadataParameter.name
																]
																	? (errors[
																			metadataParameter.name
																	  ]?.message as string)
																	: undefined
															}
															tooltipComponent={
																<InfoTooltip
																	marginOffset={{
																		left: 0.4,
																		bottom: 0.4
																	}}
																	iconVisible
																	text={translate(
																		() =>
																			metadataParameter.description
																	)}
																/>
															}
															required={requiredFields.has(
																metadataParameter.name
															)}
														/>
													)}

													{metadataParameter.type ===
														ProjectMetadataType.Dropdown && (
														<Controller
															name={`${metadataParameter.name}`}
															control={control}
															render={({
																field: { value, onBlur }
															}) => (
																<CreatableSelect
																	label={translate(
																		() =>
																			metadataParameter.label
																	)}
																	tooltipPlace="top"
																	tooltipComponent={
																		<InfoTooltip
																			marginOffset={{
																				left: 0.4,
																				bottom: 0.4
																			}}
																			iconVisible
																			text={translate(
																				() =>
																					metadataParameter.description
																			)}
																		/>
																	}
																	placeholder={translate(
																		() =>
																			metadataParameter.placeholder
																	)}
																	value={
																		value
																			? {
																					value: value.toString(),
																					label: translate(
																						() =>
																							metadataParameter.categories.find(
																								p =>
																									p.id ===
																									value
																							)
																								?.name ??
																							''
																					)
																			  }
																			: undefined
																	}
																	items={metadataParameter.categories.map(
																		option => ({
																			label: translate(
																				() => option.name
																			),
																			value: `${option.id}`
																		})
																	)}
																	error={
																		touchedFields[
																			metadataParameter.name
																		]
																			? (errors[
																					metadataParameter
																						.name
																			  ]?.message as string)
																			: undefined
																	}
																	onClear={() => {
																		setValue(
																			`${metadataParameter.name}`,
																			'',
																			{
																				shouldDirty: true
																			}
																		);
																	}}
																	borderError={
																		touchedFields[
																			metadataParameter.name
																		] &&
																		!!errors[
																			metadataParameter.name
																		]?.message
																	}
																	required={requiredFields.has(
																		metadataParameter.name
																	)}
																	onValueSelected={selectedValue =>
																		selectedValue &&
																		setValue(
																			`${metadataParameter.name}`,
																			selectedValue,
																			{
																				shouldDirty: true
																			}
																		)
																	}
																	onBlur={onBlur}
																	hint={translate(
																		() =>
																			metadataParameter.categories.find(
																				p => p.id === value
																			)?.description ?? ''
																	)}
																	scrollIntoView
																/>
															)}
														/>
													)}
												</Column>
											))}
										</Row>
									</div>
								);
							})}
						</>
					)}
					<Spacer size={s => s.s} />
					<InfoMessage
						message={
							<div>
								<p>{translate(dict => dict.projectMetadata.hso.info)}</p>
								<p>{translate(dict => dict.projectMetadata.hso.byProceeding)}</p>
							</div>
						}
					/>

					<Spacer size={s => s.m} />
					<RequiredFields />
				</Form>
			</NarrowContainer>

			<StickyFooter
				primary={{
					label: translate(({ buttons }) => buttons.continue),
					loading: creatingProject,
					disabled: !canSubmitForm,
					onClick: handleFormSubmit
				}}
				neutral={{
					label: translate(({ buttons }) => buttons.cancel),
					onClick: creatingProject ? undefined : () => navigate(routes.projects.list)
				}}
				maxWidth={65.2}
			/>
		</>
	);
}
