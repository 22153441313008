import { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { makeRequest } from 'features/entry-form-v2/data/makeRequest';

type FilterInput =
	| {
			columnName: 'user';
			values: string[];
	  }
	| {
			columnName: 'userAction';
			values: number[];
	  };

type RequestInput = {
	projectId: number;
	pageIndex: number;
	pageSize: number;
	filterOptions: {
		users: string[];
		actions: string[];
	};
};

export const useGetHistoryData = () => {
	const [data, setData] = useState<HistoryResponse>();
	const [loaded, setLoaded] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [isError, setIsError] = useState(false);

	const { mutateAsync: mutateHistoryAsync } = useMutation({
		mutationFn: async (args: RequestInput) => {
			const { projectId, pageIndex, pageSize, filterOptions } = args;
			const { users, actions } = filterOptions;

			const startAt = pageIndex * pageSize;

			const userFilters: FilterInput | undefined =
				users.length > 0
					? {
							columnName: 'user',
							values: users.map(user => user)
					  }
					: undefined;

			const actionFilters: FilterInput | undefined =
				actions.length > 0
					? {
							columnName: 'userAction',
							values: filterOptions.actions.map(action => Number(action))
					  }
					: undefined;

			const allFilters = [userFilters, actionFilters];
			const filters: FilterInput[] = allFilters.filter(
				(filter): filter is FilterInput => filter !== undefined
			);

			return makeRequest<{
				auditLog: Array<AuditEvent>;
				users: Array<string>;
				series: Array<{ name: string; label: string }>;
				totalCount: number;
			}>({
				method: 'getAuditLog',
				service: 'cde',
				data: {
					projectId,
					startAt,
					maxPageSize: pageSize,
					filters
				}
			});
		}
	});

	const { mutateAsync: mutateNamesAsync } = useMutation({
		mutationFn: async (userIds: string[]) => {
			return makeRequest<GetNamesFromUserIdsResponse>({
				method: 'getNamesFromUserIds',
				service: 'user',
				data: { userIds }
			});
		}
	});

	const getHistory = useCallback(
		async (args: RequestInput) => {
			const { projectId, pageIndex, pageSize, filterOptions } = args;
			setIsFetching(true);

			try {
				const response = await mutateHistoryAsync({
					projectId,
					pageIndex,
					pageSize,
					filterOptions
				});

				const usernames = (await mutateNamesAsync(response.users)).namesFromUserIds;
				const seriesLabelsBySeriesName = response.series.reduce((acc, series) => {
					acc[series.name] = series.label;
					return acc;
				}, {} as Record<string, string>);

				setLoaded(true);
				setData({
					pageIndex,
					pageSize,
					pageCount: Math.ceil(response.totalCount / pageSize),
					totalCount: response.totalCount,
					auditLog: response.auditLog.map(item => {
						const seriesLabel = isSeries(item)
							? seriesLabelsBySeriesName[item.datasetName] ?? item.datasetName
							: undefined;

						return {
							...item,
							username: usernames[item.user],
							seriesLabel
						};
					}),
					users: response.users.map(user => ({
						id: user,
						username: usernames[user]
					}))
				});
			} catch (error) {
				setIsError(true);
			} finally {
				setIsFetching(false);
			}
		},
		[mutateHistoryAsync, mutateNamesAsync]
	);

	return {
		data,
		isLoading: isFetching && !loaded,
		isFetching,
		isError,
		getHistory
	};
};

type AuditEvent = GenericAuditEvent | SeriesAuditEvent;

type GenericAuditEvent = {
	logId: number;
	user: string;
	entryId: string;
	originalId: string;
	parentEntryId: string;
	entryAction: EntryAction;
	userAction: UserAction;
	datasetName: string;
	timestamp: string;
};

type SeriesAuditEvent = GenericAuditEvent & {
	seriesLabel: string;
};

export enum UserAction {
	DEFAULT = 0,
	ENTRY_CREATION = 1,
	ENTRY_UPDATE = 2,
	ENTRY_DELETION = 3,
	PROJECT_COPY = 4,
	STATUS_UPDATE = 5,
	AGGREGATION_RECALCULATION = 6,
	DEFAULT_STATUS_SET = 7,
	IMPORT_FULL = 8,
	IMPORT_ADD_ENTRIES = 9,
	CALCULATED_VALUES_UPDATE = 10,
	TIME_DURATION_RECALCULATION = 11,
	UNIQUE_VALUES_SET = 12,
	CATEGORY_VALUES_UPDATE = 13,
	IMPORT_ADD_COLUMNS = 14,
	VARIABLE_TYPE_UPDATE = 15,
	ENTRY_OWNERSHIP_TRANSFER = 16,
	UNIQUE_VALUES_REGENERATION = 17,
	FILE_COPY = 18,
	SERIES_DELETE = 19
}

export const UserActions = Object.values(UserAction).filter(
	action => typeof action === 'number'
) as UserAction[];

export enum EntryAction {
	DEFAULT = 0,
	CREATED = 1,
	UPDATED = 2,
	UPDATED_INLINE = 3,
	DELETED = 4
}

export type HistoryResponse = {
	pageIndex: number;
	pageSize: number;
	pageCount: number;
	totalCount: number;
	auditLog: Array<AuditEvent & { username: string; seriesLabel: string | undefined }>;
	users: Array<{ id: string; username: string }>;
};

type NamesFromUserIds = Record<string, string>;
type GetNamesFromUserIdsResponse = {
	namesFromUserIds: NamesFromUserIds;
};

export function isSeries(auditEvent: AuditEvent): auditEvent is SeriesAuditEvent {
	return auditEvent.datasetName !== null;
}
