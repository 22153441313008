import { ActionPayload, Thunk } from 'store/types';
import {
	ActionTypes,
	AddSubscriptionPaymentMethodAction,
	BillingAddress,
	CreateChargebeeCustomerAction,
	CreateCustomerSubscriptionAction,
	DowngradeSubscriptionToFreeAction,
	GetSubscriptionBillingInfoAction,
	GetSubscriptionPaymentMethodsAction,
	GetSubscriptionPaymentsAction,
	MakePaymentSourcePrimaryAction,
	RemoveSubscriptionPaymentMethodAction,
	SetCBInstantiatedAction,
	SetSelectedPaymentMethodAction,
	UpdateCustomerSubscriptionAction,
	UpdateUserBillingAddressAction
} from './types';
import { createActivity } from 'store/ui/activities';
import { mapApiBillingInfoToBillingInfo } from 'helpers/subscription';
import { SubscriptionName } from '../subscription';
import { getMessageFromError, unknownErrorMessage } from 'store/utils';

export const getSubscriptionPaymentsAction = (
	payload: ActionPayload<GetSubscriptionPaymentsAction>
): GetSubscriptionPaymentsAction => ({
	type: ActionTypes.GET_SUBSCRIPTION_PAYMENTS,
	payload
});

export const getSubscriptionPayments = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_SUBSCRIPTION_PAYMENTS,
		dispatch
	});

	try {
		activity.begin();

		const { invoices, selectedPaymentMethod } = await context.api.account
			.payments()
			.getSubscriptionPayments();

		dispatch(
			getSubscriptionPaymentsAction({
				subscriptionPayments: invoices,
				selectedPaymentMethod
			})
		);
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
		});
	} finally {
		activity.end();
	}
};

const getSubscriptionBillingInfoAction = (
	payload: ActionPayload<GetSubscriptionBillingInfoAction>
): GetSubscriptionBillingInfoAction => ({
	type: ActionTypes.GET_USER_BILLING_INFO,
	payload
});

export const getSubscriptionBillingInfo = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_USER_BILLING_INFO,
		dispatch
	});

	try {
		activity.begin();

		const { subscriptionDetails } = await context.api.account
			.payments()
			.getSubscriptionBillingInfo();

		const billingInfo = subscriptionDetails
			? mapApiBillingInfoToBillingInfo(subscriptionDetails)
			: null;

		dispatch(getSubscriptionBillingInfoAction({ billingInfo }));
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
		});
	} finally {
		activity.end();
	}
};

const updateUserBillingAdressAction = (
	payload: ActionPayload<UpdateUserBillingAddressAction>
): UpdateUserBillingAddressAction => ({
	type: ActionTypes.UPDATE_USER_BILLING_ADDRESS,
	payload
});

export const updateUserBillingAddress =
	(billingAddress: BillingAddress): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_USER_BILLING_ADDRESS,
			dispatch
		});

		try {
			activity.begin();

			await context.api.account.payments().updateUserBillingInfo(billingAddress);

			dispatch(updateUserBillingAdressAction({ billingAddress }));
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

const getSubscriptionPaymentMethodsAction = (
	payload: ActionPayload<GetSubscriptionPaymentMethodsAction>
): GetSubscriptionPaymentMethodsAction => ({
	type: ActionTypes.GET_SUBSCRIPTION_PAYMENT_METHODS,
	payload
});

export const getSubscriptionPaymentMethods = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_SUBSCRIPTION_PAYMENT_METHODS,
		dispatch
	});

	try {
		activity.begin();

		const { paymentMethods, selectedPaymentMethod } = await context.api.account
			.payments()
			.getSubscriptionPaymentMethods();

		dispatch(
			getSubscriptionPaymentMethodsAction({
				subscriptionPaymentMethods: paymentMethods,
				selectedPaymentMethod
			})
		);
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
		});
	} finally {
		activity.end();
	}
};

const addSubscriptionPaymentMethodAction = (
	payload: ActionPayload<AddSubscriptionPaymentMethodAction>
): AddSubscriptionPaymentMethodAction => ({
	type: ActionTypes.ADD_SUBSCRIPTION_PAYMENT_METHOD,
	payload
});

export const addSubscriptionPaymentMethod =
	(authorizedPaymentIntentId: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.ADD_SUBSCRIPTION_PAYMENT_METHOD,
			dispatch
		});

		try {
			activity.begin();

			const { paymentMethods, selectedPaymentMethod } = await context.api.account
				.payments()
				.addPaymentSubscriptionMethod(authorizedPaymentIntentId);

			dispatch(
				addSubscriptionPaymentMethodAction({
					subscriptionPaymentMethods: paymentMethods,
					selectedPaymentMethod
				})
			);
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

const removeSubscriptionPaymentMethodAction = (
	payload: ActionPayload<RemoveSubscriptionPaymentMethodAction>
): RemoveSubscriptionPaymentMethodAction => ({
	type: ActionTypes.REMOVE_SUBSCRIPTION_PAYMENT_METHOD,
	payload
});

export const removeSubscriptionPaymentMethod =
	(cardNumber: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.REMOVE_SUBSCRIPTION_PAYMENT_METHOD,
			dispatch
		});

		try {
			activity.begin();

			const isRemoveSuccessful = await context.api.account
				.payments()
				.removeSubscriptionCardPaymentSource(cardNumber);

			if (isRemoveSuccessful) {
				dispatch(removeSubscriptionPaymentMethodAction({ cardNumber }));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

export const setSelectedPaymentMethodAction = (
	payload: ActionPayload<SetSelectedPaymentMethodAction>
): SetSelectedPaymentMethodAction => ({
	type: ActionTypes.SET_SELECTED_PAYMENT_METHOD,
	payload
});

const makePaymentSourcePrimaryAction = (
	payload: ActionPayload<MakePaymentSourcePrimaryAction>
): MakePaymentSourcePrimaryAction => ({
	type: ActionTypes.MAKE_PAYMENT_SOURCE_PRIMARY,
	payload
});

export const makePaymentSourcePrimary =
	(cardNumber: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.MAKE_PAYMENT_SOURCE_PRIMARY,
			dispatch
		});

		try {
			activity.begin();
			const response = await context.api.account
				.payments()
				.makePaymentSourcePrimary(cardNumber);

			if (response === '200') {
				dispatch(makePaymentSourcePrimaryAction({ cardNumber }));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

const createCustomerSubscriptionAction = (
	payload: ActionPayload<CreateCustomerSubscriptionAction>
): CreateCustomerSubscriptionAction => ({
	type: ActionTypes.CREATE_CUSTOMER_SUBSCRIPTION,
	payload
});

export const createCustomerSubscription =
	(payload: ActionPayload<CreateCustomerSubscriptionAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.CREATE_CUSTOMER_SUBSCRIPTION,
			dispatch
		});

		try {
			activity.begin();

			const { newCustomerSubscription } = payload;

			await context.api.account
				.payments()
				.createCustomerSubscription(newCustomerSubscription);

			const {
				account: {
					subscription: { subscriptionDetails }
				}
			} = getState();
			if (subscriptionDetails?.subscriptionName !== SubscriptionName.LedidiCore) {
				dispatch(createCustomerSubscriptionAction(payload));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

const updateCustomerSubscriptionAction = (
	payload: ActionPayload<UpdateCustomerSubscriptionAction>
): UpdateCustomerSubscriptionAction => ({
	type: ActionTypes.UPDATE_CUSTOMER_SUBSCRIPTION,
	payload
});

export const updateCustomerSubscription =
	(payload: ActionPayload<UpdateCustomerSubscriptionAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_CUSTOMER_SUBSCRIPTION,
			dispatch
		});

		try {
			activity.begin();

			const { customerSubscription } = payload;

			await context.api.account.payments().updateCustomerSubscription(customerSubscription);

			const {
				account: {
					subscription: { subscriptionDetails }
				}
			} = getState();

			if (subscriptionDetails?.subscriptionName !== SubscriptionName.LedidiCore) {
				dispatch(updateCustomerSubscriptionAction(payload));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

export const downgradeSubscriptionToFreeAction = (): DowngradeSubscriptionToFreeAction => ({
	type: ActionTypes.DOWNGRADE_SUBSCRIPTION_TO_FREE
});

export const downgradeSubscriptionToFree = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.DOWNGRADE_SUBSCRIPTION_TO_FREE,
		dispatch
	});

	try {
		activity.begin();

		const downgraded = await context.api.account.payments().downgradeSubscriptionToFree();
		if (downgraded) {
			dispatch(downgradeSubscriptionToFreeAction());
		}
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
		});
	} finally {
		activity.end();
	}
};

export const createChargebeeCustomer =
	(payload: ActionPayload<CreateChargebeeCustomerAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.CREATE_CHARGEBEE_CUSTOMER,
			dispatch
		});

		try {
			activity.begin();

			const {
				account: {
					subscription: { isNonSubscriber }
				}
			} = getState();

			const { customerData } = payload;
			const response = await context.api.account
				.payments()
				.createChargebeeCustomer(customerData);

			// In case user is already Customer, fetch cards and billing info
			if (
				isNonSubscriber &&
				response.statusText === 'You are already a customer of Chargebee'
			) {
				dispatch(getSubscriptionBillingInfo());
				dispatch(getSubscriptionPaymentMethods());
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

export const setCBInstantiated = (): SetCBInstantiatedAction => ({
	type: ActionTypes.SET_CB_INSTANTIATED
});
