import { makeRequest } from './makeRequest';
import { Entry, EntryAccess, EntryStatus } from '../types';
import { useQuery } from '@tanstack/react-query';

export type GetLatesetDataEntryVersionResponse = {
	entry: Entry;
	entryAccess: EntryAccess;
	httpStatusCode: number;
	statuses?: Record<string, EntryStatus>;
	latestEntryId: string;
};

export const useGetLatestDataEntryVersionQuery = (
	args: {
		entryId: string;
		projectId: string;
		setName?: string;
	},

	options?: {
		enabled?: boolean;
	}
) => {
	return useQuery<GetLatesetDataEntryVersionResponse>({
		queryKey: ['latestDataEntryVersion', args.entryId],
		queryFn: () =>
			getLatestEntryVersion({
				entryId: args.entryId,
				projectId: args.projectId,
				setName: args.setName
			}),
		enabled: options?.enabled ?? true
	});
};

export const getLatestEntryVersion = async ({
	entryId,
	projectId,
	setName
}: {
	entryId: string;
	projectId: string;
	setName?: string;
}): Promise<GetLatesetDataEntryVersionResponse> => {
	return makeRequest({
		method: 'getLatestDataEntryVersion',
		service: 'cde',
		data: {
			datasetentryid: entryId,
			projectId,

			...(setName ? { set: { setName } } : {})
		}
	});
};
