import { combineReducers } from 'redux';

import { State as Auth, reducer as auth } from './auth';

import { State as Subscription, reducer as subscription } from './account/subscription';
import { State as Payments, reducer as payments } from './account/payments';
import { State as Enterprise, reducer as enterprise } from './account/enterprise';

import { State as Accounts, reducer as accounts } from './data/accounts';
import { State as Admin, reducer as admin } from './account/admin';
import { State as Analyses, reducer as analyses } from './data/analyses';
import { State as Collaborators, reducer as collaborators } from './data/collaborators';
import { State as Dependencies, reducer as dependencies } from './data/dependencies';
import { State as Documents, reducer as documents } from './data/documents';
import { State as Entries, reducer as entries } from './data/entries';
import { State as Series, reducer as series } from './data/series';
import { State as ExportWizard, reducer as exportWizard } from './data/exportWizard';
import { State as Filters, reducer as filters } from './data/filters';
import { State as Forms, reducer as forms } from './data/forms';
import { State as Help, reducer as help } from './data/help';
import { State as Notifications, reducer as notifications } from './data/notifications';
import { State as Projects, reducer as projects } from './data/projects';
import { State as Revisions, reducer as revisions } from './data/revisions';
import { State as Roles, reducer as roles } from './data/roles';
import { State as Snapshots, reducer as snapshots } from './data/snapshots';
import { State as Statuses, reducer as statuses } from './data/statuses';
import { State as Templates, reducer as templates } from './data/templates';
import { State as Variables, reducer as variables } from './data/variables';
import { State as VariablesMapping, reducer as variablesMapping } from './data/variablesMapping';

import { State as Activities, reducer as activities } from './ui/activities';
import { State as I18N, reducer as i18n } from './ui/i18n';
import { State as States, reducer as states } from './ui/states';
import type { State as Tables } from './ui/tables/types';
import { reducer as tables } from './ui/tables';

export interface ApplicationState {
	account: {
		subscription: Subscription;
		admin: Admin;
		enterprise: Enterprise;
		payments: Payments;
	};
	auth: Auth;
	data: {
		accounts: Accounts;
		analyses: Analyses;
		collaborators: Collaborators;
		dependencies: Dependencies;
		documents: Documents;
		entries: Entries;
		series: Series;
		exportWizard: ExportWizard;
		filters: Filters;
		forms: Forms;
		help: Help;
		notifications: Notifications;
		projects: Projects;
		revisions: Revisions;
		roles: Roles;
		snapshots: Snapshots;
		statuses: Statuses;
		templates: Templates;
		variables: Variables;
		variablesMapping: VariablesMapping;
	};
	ui: {
		activities: Activities;
		i18n: I18N;
		states: States;
		tables: Tables;
	};
}

export default combineReducers<ApplicationState>({
	account: combineReducers({
		subscription,
		admin,
		enterprise,
		payments
	}),
	auth,
	data: combineReducers({
		accounts,
		analyses,
		collaborators,
		dependencies,
		documents,
		entries,
		series,
		exportWizard,
		filters,
		forms,
		help,
		notifications,
		projects,
		revisions,
		roles,
		snapshots,
		statuses,
		templates,
		variables,
		variablesMapping
	}),
	ui: combineReducers({
		activities,
		i18n,
		states,
		tables
	})
});
