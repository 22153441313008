import { forwardRef, useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { MediaQueries, Svgs } from 'environment';
import { Notification, NotificationBodyType } from 'store/data/notifications';
import { EventType } from 'types/index';

import { NotificationsList } from './NotificationsList';
import { NotificationsSettings } from './NotificationsSettings';

import {
	IconContainer,
	NotificationContainer,
	NotificationTitle,
	Slide,
	Slider
} from './NotificationDrawer.style';
import { Icon } from 'components/UI/Icons';
import { Dropdown } from 'components/UI/Dropdown';
import { Typography } from 'components/UI/Typography';
import { openBillingPage, openNewTabSubscriptionPage } from 'helpers/navigation';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useShowOnlyUnreadNotifications,
	useNotifications,
	useSetNotificationsAsRead,
	useProjectId
} from 'hooks/store';
import { useWindowSize } from 'hooks/ui';
import { useMediaQuery, useOutsideBoundsClick, useKeyPress } from 'hooks/utils';

enum NotificationPage {
	Notifications,
	Settings
}

interface Props {
	open: boolean;
	onClose: () => void;
}

export const NotificationDrawer = forwardRef<HTMLDivElement, Props>(({ open, onClose }, ref) => {
	const { translate } = useTranslation();

	const [notificationPage, setNotitificationPage] = useState(NotificationPage.Notifications);

	const [showOnlyUnread, setShowOnlyUnread] = useShowOnlyUnreadNotifications();

	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	const [headerHeight, setHeaderHeight] = useState(0);
	const [headerCoordonates, setHeaderCoordonates] = useState({
		top: 0,
		bottom: 0,
		left: 0,
		right: 0
	});

	const { width, height } = useWindowSize();

	useEffect(() => {
		setHeaderCoordonates(prevState => ({
			...prevState,
			left: width - 370,
			right: width
		}));
	}, [width]);

	useEffect(() => {
		setHeaderCoordonates(prevState => ({
			...prevState,
			top: headerHeight,
			bottom: height
		}));
	}, [height, headerHeight]);

	useOutsideBoundsClick(onClose, headerCoordonates, {
		listen: open,
		eventType: EventType.Click
	});

	const [{ data: notifications }] = useNotifications();

	const hasNotifications = notifications && notifications.length > 0;

	//calculate dinamically HeaderMain's height, to show the drawer under it
	useEffect(() => {
		const resizeObserver = new ResizeObserver(entries => {
			for (const entry of entries) {
				setHeaderHeight(entry.contentRect.height);
			}
		});
		// Observe the header element for resize changes
		const headerElement = document.querySelector('.header-main');
		if (headerElement !== null) resizeObserver.observe(headerElement);

		return () => resizeObserver.disconnect();
	}, []);

	useKeyPress(
		{
			onEscapeKeyPress: () => {
				onClose();
			}
		},
		{
			noModalsOpened: true,
			listen: open
		}
	);

	useEffect(() => {
		if (!open) setNotitificationPage(NotificationPage.Notifications);
	}, [open]);

	// Click on CARD
	const { navigate, routes } = useNavigation();

	const [, setNotificationsAsRead] = useSetNotificationsAsRead();
	const [, setProjectId] = useProjectId();

	function onCardClick(notification: Notification) {
		const { notificationId, type, body, isRead } = notification;
		if (!isRead) setNotificationsAsRead([notificationId]);

		switch (type) {
			case NotificationBodyType.ProjectEntryAdd: {
				if (body.projectId && body.datasetEntryId) {
					onClose();
					setProjectId(body.projectId);
					navigate(routes.projects.dataset.update(body.projectId, body.datasetEntryId));
				}
				break;
			}
			case NotificationBodyType.ProjectEntryAddBatch: {
				if (body.projectId) {
					onClose();
					setProjectId(body.projectId);
					navigate(routes.projects.dataset.view(body.projectId));
				}
				break;
			}
			case NotificationBodyType.ProjectOwnershipTransferred: {
				if (body.projectId) {
					onClose();
					setProjectId(body.projectId);
					navigate(routes.projects.dataset.view(body.projectId));
				}
				break;
			}
			case NotificationBodyType.ProjectOwnershipTransferredMulti: {
				onClose();
				navigate(routes.projects.list);
				break;
			}
			case NotificationBodyType.SubscriptionPlanCreation:
			case NotificationBodyType.SubscriptionPlanUpdate:
			case NotificationBodyType.SubscriptionPlanUpdateBatch:
			case NotificationBodyType.SubscriptionPlanChange:
			case NotificationBodyType.SubscriptionInvitation:
			case NotificationBodyType.SubscriptionInvitationUserAnswer:
			case NotificationBodyType.SubscriptionInvitationOwnerAnswer:
			case NotificationBodyType.SubscriptionInvitationRemoved: {
				openNewTabSubscriptionPage();
				break;
			}
			case NotificationBodyType.SubscriptionPaymentFailed:
			case NotificationBodyType.SubscriptionCancellation: {
				openBillingPage();
				break;
			}
			case NotificationBodyType.SubscriptionUserRemoval:
			case NotificationBodyType.SubscriptionUserRemovalByDowngrade: {
				openNewTabSubscriptionPage(true);
				break;
			}
		}
	}

	return (
		<NotificationContainer visible={open} top={headerHeight} ref={ref}>
			<NotificationTitle>
				<Typography.H6 style={{ flex: 1 }}>
					{notificationPage === NotificationPage.Notifications &&
						translate(dict => dict.notifications.drawerTitle)}
					{notificationPage === NotificationPage.Settings &&
						translate(dict => dict.notifications.settings.drawerTitle)}
				</Typography.H6>
				{notificationPage === NotificationPage.Notifications && (
					<div>
						<Dropdown
							toggleComponent={({ ref, open, toggle }) => (
								<Icon
									ref={ref}
									svg={Svgs.More}
									active={open}
									variant={v => v.button}
									onClick={toggle}
								/>
							)}
							width={18}
							offset={{ top: 20, right: 0 }}
						>
							{hasNotifications && (
								<Dropdown.Item
									title={translate(dict => dict.notifications.onlyShowUnread)}
									active={showOnlyUnread}
									onClick={() => {
										setShowOnlyUnread(!showOnlyUnread);
									}}
								/>
							)}
							<Dropdown.Item
								title={translate(dict => dict.notifications.notificationSettings)}
								onClick={() => {
									setNotitificationPage(NotificationPage.Settings);
								}}
							/>
						</Dropdown>
					</div>
				)}

				<IconContainer visible={open && notificationPage === NotificationPage.Settings}>
					{notificationPage === NotificationPage.Settings && (
						<Icon
							svg={Svgs.ArrowLeft}
							variant={v => v.button}
							onClick={() => setNotitificationPage(NotificationPage.Notifications)}
						/>
					)}
				</IconContainer>

				{isMobileDevice && (
					<Icon
						svg={Svgs.Close}
						variant={v => v.button}
						onClick={onClose}
						size={s => s.l}
						marginOffset={{ left: 2 }}
					/>
				)}
			</NotificationTitle>

			<Slider>
				<Slide active={notificationPage === NotificationPage.Notifications}>
					<NotificationsList onCardClick={onCardClick} />
				</Slide>
				<Slide active={notificationPage === NotificationPage.Settings}>
					<NotificationsSettings />
				</Slide>
			</Slider>
		</NotificationContainer>
	);
});
