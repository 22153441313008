import { useCallback, useState, useEffect, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { Group } from 'api/data/variables';
import { Colors, Svgs } from 'environment';
import { GroupData, DraggableVariableCardType } from 'store/data/variables';
import { InputType, SetState } from 'types/index';
import { VariableCardPayload } from '../Cards/CommonCard';
import { VariableCardInGroup } from './VariableCardInGroup';
import { VariableSetConfirmDragAndDropActionModal } from '../ConfirmDragAndDropActionModal';
import { LineMarkerHorizontal } from '../Cards/VariableCard/VariableCard.style';
import { DeleteWrapper, DrawerBody, VariablesWrapper, Divider } from './VariableGroupDrawer.style';
import { Input } from 'components/UI/Inputs/Input';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { translateVariableTypeMap } from 'helpers/generic';
import {
	isGroupData,
	isVariableSetData,
	groupHasForbiddenVariableType,
	itemIsType
} from 'helpers/variables';
import {
	useTranslation,
	useVariables,
	useFilteredVariablesDataArray,
	useUpdateGroup,
	useMoveVariableInsideGroup,
	useDestinationSetName,
	useDestinationGroupName,
	useAddVariableToGroup
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useKeyPress } from 'hooks/utils';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	group: Group;
	parentSet?: string;
	isVariableModalOpened: boolean;
	readOnly: boolean;
	preventDrag: boolean;
	hasErrors?: string[];
	onCreateVariable: () => void;
	onVariableClick: (variableName: string) => void;
	onGroupDelete: (groupName: string) => void;
	onClose: () => void;
}

export function VariableGroupDrawer({
	group,
	parentSet,
	isVariableModalOpened,
	readOnly,
	preventDrag,
	hasErrors,
	onCreateVariable,
	onVariableClick,
	onGroupDelete,
	onClose
}: Props) {
	const { translate } = useTranslation();

	const { groupName, groupLabel } = group;

	const [
		{
			data: { groups }
		}
	] = useVariables({ initial: true, lazy: true });

	const [
		{
			data: { variablesDataArray }
		}
	] = useVariables();

	const filteredVariablesDataArray = useFilteredVariablesDataArray({ variablesDataArray });

	/**
	 * USE THE GROUP WHERE FILTERS ARE APPLIED
	 *
	 * EX CASE: GROUP HAS 3 VARIABLES BUT ONLY 1 IS SHOWN AFTER FILTERS ARE APPLIED
	 */
	const groupVariables = useMemo(() => {
		let filteredGroup: GroupData | undefined;

		for (const item of filteredVariablesDataArray) {
			if (isGroupData(item)) {
				const match = item.groupName === group.groupName;

				if (match) {
					filteredGroup = item;
					break;
				}
			}

			if (isVariableSetData(item)) {
				const variableSetData = item;

				let found = false;

				for (const setItem of variableSetData.setData) {
					if (isGroupData(setItem)) {
						const match = setItem.groupName === group.groupName;

						if (match) {
							filteredGroup = setItem;
							found = true;
							break;
						}
					}
				}

				if (found) break;
			}
		}

		if (filteredGroup) return filteredGroup.groupVariables;

		return [];
	}, [filteredVariablesDataArray, group.groupName]);

	const [isOverCurrent, setIsOverCurrent] = useState(false);
	const [labelError, setLabelError] = useState(getGroupLabelError(groups, groupLabel));
	const [draftGroupLabel, setDraftGroupLabel] = useState(groupLabel);
	const [showEmptyGroupHover, setShowEmptyGroupHover] = useState(false);

	const [{ loading: updatingGroup }, updateGroup] = useUpdateGroup();
	const [, moveVariableInsideGroup] = useMoveVariableInsideGroup();

	const [, setDestinationSetName] = useDestinationSetName();
	const [, setDestinationGroupName] = useDestinationGroupName();

	useKeyPress(
		{ onEscapeKeyPress: onClose },
		{ noModalsOpened: true, listen: !isVariableModalOpened }
	);

	useKeyPress({ onDeleteKeyPress: handleDelete }, { noModalsOpened: true, listen: !readOnly });

	useEffect(() => {
		if (group.groupLabel !== draftGroupLabel) setDraftGroupLabel(group.groupLabel);
	}, [group]);

	function handleSubmit() {
		const hasChanges = group.groupLabel.trim() !== draftGroupLabel.trim();

		if (!(hasChanges && !labelError)) return;

		updateGroup({ groupName, groupLabel: draftGroupLabel.trim() });
	}

	function handleDelete() {
		if (!(!readOnly && !updatingGroup)) return;

		onGroupDelete(groupName);
	}

	function getGroupLabelError(groups: Group[], newLabel: string) {
		let labelError = '';

		newLabel = newLabel.trim();

		if (newLabel) {
			// if group has a name make sure it is unique
			groups.forEach(group => {
				if (group.groupLabel.trim() === newLabel && group.groupName !== groupName) {
					labelError = translate(dict => dict.variables.duplicateGroupName);
				}
			});
		} else {
			labelError = translate(dict => dict.variables.groupNameRequired);
		}

		return labelError;
	}

	const handleMoveVariableInsideGroup = useCallback(
		(input: { variableName: string; sourceIndex: number; destinationIndex: number }) => {
			const { variableName, sourceIndex, destinationIndex } = input;

			moveVariableInsideGroup({
				variableName,
				sourceIndex,
				destinationIndex,
				groupName
			});
		},
		[group]
	);

	function resetLabel() {
		setDraftGroupLabel(groupLabel);
	}

	function handleCreateVariable() {
		if (parentSet !== undefined) setDestinationSetName(parentSet);

		setDestinationGroupName(groupName);
		onCreateVariable();
	}

	function trimFields() {
		setDraftGroupLabel(state => state.trim());
	}

	useEffect(() => {
		if (!isOverCurrent) {
			showEmptyGroupHover && setShowEmptyGroupHover(false);
		}
	}, [isOverCurrent]);

	const [renderDropzone, setRenderDropzone] = useState(true);

	useEffect(() => {
		setRenderDropzone(false);
	}, [groupName]);

	useEffect(() => {
		setRenderDropzone(true);
	}, [renderDropzone]);

	const translatedVariableTypeMap = translateVariableTypeMap(translate);

	return (
		<DrawerBody>
			<Spacer size={s => s.s} />

			<Flex align={a => a.center} justify={j => j.between} paddingOffset={{ x: 1.6 }}>
				<Flex align={a => a.center}>
					{parentSet && (
						<Icon
							svg={Svgs.ArrowLeft}
							size={s => s.l}
							colors={{ color: Colors.text.main }}
							marginOffset={{ right: 0.8 }}
							onClick={onClose}
						/>
					)}
					<Typography.H6>{translate(dict => dict.variablesPage.editGroup)}</Typography.H6>
				</Flex>
				<Icon
					svg={Svgs.Close}
					size={s => s.m}
					colors={{ color: Colors.text.main }}
					onClick={onClose}
				/>
			</Flex>

			<Spacer size={s => s.s} />
			<Spacer size={s => s.xs} />

			<Flex paddingOffset={{ x: 1.6 }}>
				<Input
					type={InputType.Text}
					label={translate(dict => dict.variablesPage.updateVariableGroupModal.label)}
					value={draftGroupLabel}
					error={labelError}
					readOnly={readOnly}
					onChange={e => {
						setDraftGroupLabel(e.target.value);
						setLabelError(getGroupLabelError(groups, e.target.value));
					}}
					onSubmit={handleSubmit}
					onBlur={() => {
						trimFields();
						handleSubmit();
					}}
					onCancel={resetLabel}
				/>
			</Flex>

			<Spacer size={s => s.m} />

			<Divider />

			<Flex style={{ height: `calc(100% - ${!readOnly ? 22.8 : 15.7}rem)` }} column>
				{!readOnly && (
					<>
						<Spacer size={s => s.s} />

						<Flex paddingOffset={{ x: 1.6 }}>
							<Typography.Caption
								onClick={handleCreateVariable}
								primaryColor
								clickable
							>
								+ {translate(dict => dict.variablesPage.newVariable)}
							</Typography.Caption>
						</Flex>
					</>
				)}

				<VariablesWrapper>
					{groupVariables.map((variable, index) => {
						const translatedVariableType = translatedVariableTypeMap[variable.type];
						const subtitle = `${translatedVariableType} ${
							variable.uniquenessType ?? ''
						}`.trim();

						return (
							<VariableCardInGroup
								key={index}
								index={index}
								name={variable.name}
								title={variable.label}
								subtitle={subtitle}
								required={variable.obligatory}
								parentGroup={groupName}
								parentSet={parentSet}
								readOnly={readOnly}
								preventDrag={preventDrag}
								groupVariableNames={groupVariables.map(v => v.name)}
								hasError={hasErrors && hasErrors.includes(variable.name)}
								moveCard={handleMoveVariableInsideGroup}
								onClick={() => onVariableClick(variable.name)}
							/>
						);
					})}

					{showEmptyGroupHover && (
						<LineMarkerHorizontal style={{ opacity: 1, marginTop: '-1rem' }} />
					)}
				</VariablesWrapper>

				{renderDropzone && (
					<Dropzone
						group={group}
						setShowEmptyGroupHover={setShowEmptyGroupHover}
						setIsOverCurrent={setIsOverCurrent}
						parentSet={parentSet}
					/>
				)}
			</Flex>

			{!readOnly && (
				<>
					<Divider />

					<DeleteWrapper>
						<Spacer size={s => s.m} />

						<Typography.Paragraph
							style={{ cursor: 'pointer' }}
							color={Colors.primary.normal}
							onClick={handleDelete}
						>
							{translate(dict => dict.variablesPage.deleteGroup)}
						</Typography.Paragraph>

						<Spacer size={s => s.m} />
					</DeleteWrapper>
				</>
			)}
		</DrawerBody>
	);
}

interface DropzoneProps {
	group: Group;
	setShowEmptyGroupHover: SetState<boolean>;
	setIsOverCurrent: SetState<boolean>;
	parentSet?: string;
}

// Need to fully remove this component from the DOM and add it back in order
// for it's ref to fully reset

function Dropzone({ group, setShowEmptyGroupHover, setIsOverCurrent, parentSet }: DropzoneProps) {
	const { groupName } = group;
	const { setError: setErrorNotification } = useAlerts();
	const { translate } = useTranslation();
	const [
		{
			data: { variablesMap, groupsMap }
		}
	] = useVariables();

	const [, addVariableToGroup] = useAddVariableToGroup();

	const [confirmAction, setConfirmAction] = useState<VariableCardPayload | null>(null);
	const confirmActionModal = {
		open: (item: VariableCardPayload) => setConfirmAction(item),
		close: () => setConfirmAction(null)
	};

	const [{ isOverCurrent, isSysGenVariable }, drop] = useDrop<any, any, any>({
		accept: DraggableVariableCardType.Variable,
		collect: monitor => {
			const draggedVariable: VariableCardPayload = monitor.getItem();
			const isSysGenVariable = draggedVariable && draggedVariable.systemGenerated;
			return {
				isOverCurrent: monitor.isOver({ shallow: true }),
				isSysGenVariable
			};
		},
		drop: (item: VariableCardPayload, monitor) => {
			if (!monitor.isOver({ shallow: true })) return;

			if (item.parentGroup) return;
			if (isSysGenVariable)
				return setErrorNotification({
					message: translate(dict => dict.variablesPage.errorVariableToGroup)
				});

			if (parentSet) {
				// PRJCTS-7037: https://ledidi.atlassian.net/browse/PRJCTS-7037
				if (item.isGroup) {
					const preventDrop = groupHasForbiddenVariableType(
						groupsMap[item.name],
						variablesMap,
						[VariableType.File]
					);
					if (preventDrop) {
						return setErrorNotification({
							message: translate(dict => dict.variables.forbiddenTypeInSeries)
						});
					}
				} else {
					const preventDrop = itemIsType(variablesMap[item.name], [VariableType.File]);
					if (preventDrop) {
						return setErrorNotification({
							message: translate(dict => dict.variables.forbiddenTypeInSeries)
						});
					}
				}
				return confirmActionModal.open(item);
			}

			handleDrop(item);
		},
		hover: (item: VariableCardPayload, monitor) => {
			if (!monitor.isOver({ shallow: true })) return;

			if (item.parentGroup) return;

			setShowEmptyGroupHover(true);
		}
	});

	useEffect(() => {
		setIsOverCurrent(isOverCurrent);
	}, [isOverCurrent]);

	function handleDrop(item: VariableCardPayload) {
		if (item.parentGroup) return;

		const fromMainList = !(item.parentSet || item.parentGroup);

		addVariableToGroup({
			variableName: item.name,
			groupName,
			setName: parentSet,
			// USED TO: 1. ADD THE VARIABLE INSIDE THE SET - `addVariableToSet`; 2. THEN ADD VARIABLE TO GROUP `addVariableToGroup`
			from: { mainList: fromMainList }
		});
	}

	return (
		<>
			<div ref={drop} style={{ flex: 1 }} />

			{/* CONFIRM DRAG-N-DROP ACTION MODAL */}
			{confirmAction && (
				<VariableSetConfirmDragAndDropActionModal
					item={confirmAction}
					direction="to"
					onConfirm={() => handleDrop(confirmAction)}
					onClose={confirmActionModal.close}
				/>
			)}
		</>
	);
}
