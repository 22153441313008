import { Control, useController, useForm } from 'react-hook-form';
import {
	Variable,
	Entry,
	CategoryNonFixedVariable,
	CategoryMultipleNonFixedVariable,
	CategoryFixedVariable,
	CategoryMultipleFixedVariable
} from './types';
import clsx from 'clsx';
import { FormItem, NonGroupFormItem, ProjectData } from './data/useProjectData/useProjectData';
import {
	defaultEmptyValueForVariables,
	parseBackendValues
} from './utils/parse-backend-values/parseBackendValues';
import { Asterisk } from 'components/UI/Asterisk';
import { TimeDurationInput } from './inputs/time-duration/TimeDurationInput';
import { Label } from './component/Label';
import { InputType } from 'types';
import { Input } from './component/Input';
import { CheckboxGroup } from './component/CheckboxGroup';

interface Props {
	initialEntry?: Entry;
	projectData: ProjectData;
	contentRef?: React.RefObject<HTMLDivElement>;
}

export const PrintEntryForm = ({ projectData, initialEntry, contentRef }: Props) => {
	const { control } = useForm<Entry>({
		mode: 'onBlur',
		defaultValues: initialEntry
			? parseBackendValues({ variables: projectData.variables, entry: initialEntry })
			: defaultEmptyValueForVariables(projectData.variables)
	});

	return (
		<div
			className="grid grid-cols-2 px-10 gap-10 relative mb-52 lg:w-[756px] lg:mx-auto"
			ref={contentRef}
		>
			{projectData.formTitle && (
				<h1 className="text-2xl font-semibold col-span-full">{projectData.formTitle}</h1>
			)}

			{projectData.formItems.map((item, index) => (
				<FormItemComponent key={index} item={item} control={control} />
			))}
		</div>
	);
};

const FormItemComponent = ({ item, control }: { item: FormItem; control: FormControl }) => {
	switch (item.type) {
		case 'variable': {
			return (
				<div
					className={clsx(
						'col-span-full break-inside-avoid',
						item.columnSpan === 1 && 'md:col-span-1',
						item.columnSpan === 2 && 'md:col-span-2',
						item.columnStart === 1 && 'md:col-start-1',
						item.columnStart === 2 && 'md:col-start-2',

						item.hideFromUi && 'hidden'
					)}
				>
					<VariableInput control={control} variable={item.variable} />
				</div>
			);
		}

		case 'group': {
			return (
				<GroupContainer
					groupName={item.group.groupName}
					groupLabel={item.group.groupLabel}
					formItems={item.formItems}
					control={control}
				/>
			);
		}

		case 'separator':
			return <hr className="col-span-full" />;

		case 'subtitle':
			return <h2 className="text-base font-semibold col-span-full">{item.text}</h2>;

		case 'text':
			return <p className="col-span-full">{item.text}</p>;

		case 'series': {
			// We currently don't support printing nested series
			return null;
		}
	}
};

const GroupContainer = ({
	groupName,
	groupLabel,
	formItems,
	control
}: {
	groupName: string;
	groupLabel: string;
	formItems: NonGroupFormItem[];
	control: FormControl;
}) => {
	return (
		<div key={groupName} className="p-6 col-span-full rounded-lg shadow-normal flex flex-col">
			<h2 className="text-base font-semibold">{groupLabel}</h2>

			<div className={clsx('grid grid-cols-2 py-[10px] gap-10 lg:gap-y-10')}>
				{formItems.map(item => {
					switch (item.type) {
						case 'variable': {
							return (
								<FormItemComponent
									key={item.variable.variableName}
									item={item}
									control={control}
								/>
							);
						}
						case 'subtitle':
							return (
								<h2 className="text-lg font-semibold col-span-full">{item.text}</h2>
							);

						case 'text':
							return <p className="col-span-full text-base">{item.text}</p>;

						case 'separator':
							return <hr className="col-span-full" />;
					}
				})}
			</div>
		</div>
	);
};

export type FormControl = Control<Entry, any>;

const CREATE_NEW_OPTION = { label: '______________', value: '', description: '' };

const VariableInput = ({
	variable: _variable,
	control
}: {
	variable: Variable;
	control: FormControl;
}) => {
	const variable: Variable = { ..._variable, description: '' };
	const { field } = useController({ control, name: variable.variableName });

	switch (variable.variableType) {
		case 'string':
			return (
				<div className="flex flex-col col-span-full">
					<div className="flex items-center gap-1">
						<Label
							htmlFor={variable.variableName}
							label={variable.variableLabel}
							required={variable.obligatory}
						/>
					</div>

					<p className="rounded-md border border-gray-400 p-3 pt-[12px] text-base break-words">
						{field.value}
					</p>
				</div>
			);

		case 'float':
		case 'integer': {
			return (
				<Input
					value={field.value}
					className="col-span-full md:col-span-1"
					onChange={doNothing}
					label={variable.variableLabel}
					required={variable.obligatory}
					disabled={variable.entryType === 'calculated'}
					obligatory={variable.obligatory}
					id={variable.variableName}
				/>
			);
		}

		case 'category': {
			const options = getCategoryOptions(variable);

			return (
				<fieldset className="flex flex-col">
					<div className="flex gap-1">
						<legend className="font-semibold text-sm">{variable.variableLabel}</legend>

						{variable.obligatory && <Asterisk />}
					</div>

					<div className="flex gap-3 flex-wrap mt-4">
						{options.map(option => {
							return (
								<div key={option.value} className="flex gap-1 items-center">
									<input
										value={option.value}
										onChange={() => {
											// do nothing, handled in onClick
										}}
										onClick={() => {
											if (field.value === option.value) {
												field.onChange('');
												return;
											}

											field.onChange(option.value);
										}}
										checked={field.value === option.value}
										type="radio"
									/>

									<label className="text-base">{option.label}</label>
								</div>
							);
						})}
					</div>
				</fieldset>
			);
		}

		case 'categoryMultiple': {
			if (field.value && !Array.isArray(field.value)) {
				// While testing I got an error related to .includes not being a property of field.value. I assume this has to do with the same issue
				// we have in the `addTimeUnits` utility function, where the entry data is out of sync with the variable data for a brief moment while changing forms
				console.warn(new Error('categoryMultiple value is not an array'), {
					fieldValue: field.value,
					variableName: variable.variableName,
					variableType: variable.variableType
				});

				return null;
			}

			return (
				<CheckboxGroup
					categories={getCategoryOptions(variable)}
					control={control}
					label={variable.variableLabel}
					variableName={variable.variableName}
					disableLabelIds
				/>
			);
		}

		case 'date': {
			return (
				<Input
					type="date"
					label={variable.variableLabel}
					obligatory={variable.obligatory}
					id={variable.variableName}
					disabled={variable.entryType === 'calculated'}
					description=""
					value={field.value}
					onChange={doNothing}
				/>
			);
		}

		case 'datetime': {
			return (
				<Input
					value={field.value}
					type="datetime-local"
					label={variable.variableLabel}
					disabled={variable.entryType === 'calculated'}
					obligatory={variable.obligatory}
					id={variable.variableName}
					description=""
					onChange={doNothing}
				/>
			);
		}

		case 'file': {
			return null;
		}

		case 'userDefinedUnique': {
			return (
				<Input
					value={field.value}
					type={InputType.Text}
					label={variable.variableLabel}
					readOnly={variable.uniquenessType !== 'Manual'}
					onChange={doNothing}
					obligatory={variable.obligatory}
					id={variable.variableName}
				/>
			);
		}

		case 'timeDuration': {
			return (
				<TimeDurationInput
					value={field.value}
					maxTimeUnit={variable.durationFormat.maxTimeUnit}
					minTimeUnit={variable.durationFormat.minTimeUnit}
					label={variable.variableLabel}
					obligatory={variable.obligatory}
					onChange={doNothing}
					onBlur={doNothing}
					onError={doNothing}
					variableName={variable.variableName}
				/>
			);
		}
	}

	console.error(
		new Error('Unhandled variable type:', {
			// @ts-ignore
			variableType: variable.variableType
		})
	);
};

// Helper function to avoid eslint errors
function doNothing(): void {
	/* do nothing */
}

function getCategoryOptions(
	variable:
		| CategoryNonFixedVariable
		| CategoryFixedVariable
		| CategoryMultipleNonFixedVariable
		| CategoryMultipleFixedVariable
): { label: string; value: string }[] {
	if (variable.fixedCategories === 'no') {
		return [
			...variable.categories.map(category => ({ label: category, value: category })),
			CREATE_NEW_OPTION
		];
	}

	return variable.allowedCategories.map(category => ({
		label: category.label,
		value: category.value
	}));
}
