import { zodResolver } from '@hookform/resolvers/zod';
import { useTracking } from 'app/tracking/TrackingProvider';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Input } from '../component/Input';
import { useDeleteDataEntryMutation } from '../data/deleteDataEntryMutation';
import { Dialog } from '../component/Dialog';
import { Button } from '../component/Button';

const CONFIRMATION_MESSAGE = 'Delete entry permanently';
const deleteEntrySchema = z.object({
	confirmationMessage: z.literal(CONFIRMATION_MESSAGE)
});
export const DeleteEntryModal = ({
	show,
	onClose,
	entryId,
	projectId,
	onEntryDeleted,
	seriesName,
	title,
	description
}: {
	show: boolean;
	onClose: () => void;
	onEntryDeleted: () => void;
	entryId: string;
	projectId: string;
	seriesName?: string;
	title: string;
	description: string;
}) => {
	const deleteDataEntryMutation = useDeleteDataEntryMutation();
	const { track } = useTracking();

	const form = useForm({
		resolver: zodResolver(deleteEntrySchema)
	});

	return (
		<Dialog open={show} onClose={onClose} title={title}>
			<form
				className="flex flex-col gap-10 w-[400px]"
				onSubmit={e => {
					e.stopPropagation();
					form.handleSubmit(async () => {
						try {
							await deleteDataEntryMutation.mutate({
								entryId,
								projectId,
								setName: seriesName
							});
							track({
								eventName: 'entry_deleted',
								data: {
									type: 'series-entry'
								}
							});

							onEntryDeleted();
						} catch (e) {
							console.error(e);
						}
					})(e);
				}}
			>
				<p className="text-base">{description}</p>

				<p className="font-bold text-error-500 text-base">
					Warning! This action cannot be undone!
				</p>

				<Input
					{...form.register('confirmationMessage')}
					label={`Type "${CONFIRMATION_MESSAGE}" to confirm deletion`}
					id="confirmation_message"
				/>

				{deleteDataEntryMutation.error && (
					<p className="text-error-500 font-semibold text-base">
						Failed to delete entry, please try again.
					</p>
				)}

				<div className="flex justify-end gap-3">
					<Button
						variant="secondary"
						className="flex-1"
						type="button"
						onClick={onClose}
						title="Cancel"
					/>

					<Button
						variant="danger"
						className="flex-1"
						disabled={form.watch('confirmationMessage') !== CONFIRMATION_MESSAGE}
						type="submit"
						title="Delete entry"
						loading={deleteDataEntryMutation.loading}
					/>
				</div>
			</form>
		</Dialog>
	);
};
