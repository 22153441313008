import { useEffect, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { ROUTES } from 'types/navigation';
import { supportsTouch } from 'consts';
import { ActionTypes } from 'store/data/templates';
import { VariablesViewOptions } from 'store/data/variables';
import { BooleanMap, NumberMap } from 'types/index';
import {
	NoVariables,
	VariablesGrid,
	VariablesHeader,
	VariablesSubHeader,
	VariablesTable
} from 'components/Variables';
import { useVariablesPageModalsController } from './useVariablesPageModalsController';
import { VariablesMapping } from './VariablesMapping';
import { Grid } from 'components/UI/Grid';
import { Spacer } from 'components/UI/Spacer';
import { Suspend } from 'components/UI/Suspend';
import { WarningWithFilters } from 'components/UI/WarningWithFilters';
import { variablesDataArrayIterator } from 'helpers/variables';
import { DisableableContainer } from 'helpers/cssGenerators';
import { useNavigation, useRouteMatch } from 'hooks/navigation';
import {
	useTranslation,
	useProjectId,
	usePermissions,
	useDependencies,
	useTransactionActivity,
	useVariables,
	useFilteredVariablesDataArray,
	useProject,
	useCreateGroup,
	useUpdateGroup,
	useAddVariablesToGroup,
	useMoveVariablesBetweenGroups,
	useCreateVariableSet,
	useMoveVariablesOrGroupsToRootList,
	useMoveVariablesOrGroupsToSet,
	useVariablesViewOption,
	useEntriesErrors,
	useEntries,
	useRefetchEntries,
	useVariablesFilters,
	useVariablesTableCheckedData
} from 'hooks/store';
import { useModalState } from 'hooks/ui';
import { useMutableState, useCompletedAction, useKeyPress } from 'hooks/utils';
import { useGroupDrawer } from './useGroupDrawer';
import { useVariableSetDrawer } from './useVariableSetDrawer';

export function VariablesPage() {
	const { routes, replace } = useNavigation();
	const isCreateRoute = useRouteMatch([ROUTES.CreateVariable]);
	const { translate } = useTranslation();

	const [projectId] = useProjectId();

	const {
		hasVariablesWriteAccess,
		fetched: arePermissionsFetched,
		loading: loadingPermissions
	} = usePermissions();

	const [{ loading: importingTemplate }] = useTransactionActivity(ActionTypes.IMPORT_TEMPLATE);

	const [
		{
			data: { hasVariables, hasGroups, hasVariableSets, variablesDataArray },
			loading: loadingVariables,
			fetched: areVariablesFetched
		}
	] = useVariables();

	// FETCH DEPENDENCIES IN THE BACKGROUND - IMPROVE UX - USED FOR UPDATE CATEGORY VARIABLE FIXED VALUES
	useDependencies({
		lazy: !areVariablesFetched
	});

	const filteredVariablesDataArray = useFilteredVariablesDataArray({ variablesDataArray });

	const [{ loading: loadingProject }] = useProject();

	const [{ loading: creatingGroup, error: errorCreatingGroup }] = useCreateGroup();
	const [{ loading: updatingGroup, error: errorUpdatingGroup }] = useUpdateGroup();
	const [{ loading: addingVariablesToGroup, error: errorAddingVariablesToGroup }] =
		useAddVariablesToGroup();
	const [{ loading: movingVariablesBetweenGroups, error: errorMovingVariablesBetweenGroups }] =
		useMoveVariablesBetweenGroups();

	const [{ loading: creatingVariableSet, error: errorCreatingVariableSet }] =
		useCreateVariableSet();
	const [
		{
			loading: movingVariablesOrGroupsToRootList,
			error: errorMovingVariablesOrGroupsToRootList
		}
	] = useMoveVariablesOrGroupsToRootList();

	const [{ loading: movingVariablesOrGroupsToSet, error: errorMovingVariablesOrGroupsToSet }] =
		useMoveVariablesOrGroupsToSet();

	const [viewOption] = useVariablesViewOption();

	const [{ errors: entriesErrors }] = useEntriesErrors();
	const [{ fetched: areEntriesFetched }, getEntries] = useEntries();

	const [shouldRefetchEntries] = useRefetchEntries();

	const [{ draftFilters }, { toggleErroredFilter, clearErroredFilter }] = useVariablesFilters();

	// TABLE VIEW STATES
	const { checkedMap, checkedState, resetChecked, toggleChecked, toggleAllChecked } =
		useVariablesTableCheckedData();
	const [expandedRows, setExpandedRows] = useMutableState<BooleanMap>({});
	// TABLE VIEW STATES - END

	const {
		modals: {
			createGroupModal,
			updateGroupModal,
			deleteGroupModal,
			deleteVariableModal,
			updateVariableSetModal,
			createVariableSetModal,
			deleteVariableSetModal,
			deleteSelectedModal,
			moveSelectedVariablesModal,
			variableModal
		},
		modalsComponent
	} = useVariablesPageModalsController({ checkedState });

	const variablesMappingModal = useModalState();

	// RESETS SELECTED VARIABLES / GROUPS AFTER VALID API CALL
	useCompletedAction(creatingGroup, errorCreatingGroup, resetChecked);
	useCompletedAction(updatingGroup, errorUpdatingGroup, resetChecked);
	useCompletedAction(addingVariablesToGroup, errorAddingVariablesToGroup, resetChecked);
	useCompletedAction(
		movingVariablesBetweenGroups,
		errorMovingVariablesBetweenGroups,
		resetChecked
	);
	useCompletedAction(creatingVariableSet, errorCreatingVariableSet, resetChecked);
	useCompletedAction(
		movingVariablesOrGroupsToRootList,
		errorMovingVariablesOrGroupsToRootList,
		resetChecked
	);
	useCompletedAction(
		movingVariablesOrGroupsToSet,
		errorMovingVariablesOrGroupsToSet,
		resetChecked
	);

	/**
	 * Trigger create-variable-modal based on URL param
	 */
	useEffect(() => {
		if (!(projectId && isCreateRoute)) return;

		variableModal.open();
		replace(routes.projects.variables.view(projectId));
	}, [isCreateRoute, projectId]);

	/**
	 * Refetch entries on refetch flag
	 */
	useEffect(() => {
		if (areEntriesFetched && shouldRefetchEntries) getEntries();
	}, [areEntriesFetched, shouldRefetchEntries]);

	const rowNumberByName = useMemo(() => {
		const numberMap: {
			variables: NumberMap;
			groups: NumberMap;
			variableSets: NumberMap;
		} = {
			variables: {},
			groups: {},
			variableSets: {}
		};

		variablesDataArrayIterator(
			variablesDataArray,
			(variable, index) => {
				numberMap.variables[variable.name] = index + 1;
			},
			(group, index) => {
				numberMap.groups[group.groupName] = index + 1;
			},
			(variableSet, index) => {
				numberMap.variableSets[variableSet.setName] = index + 1;
			}
		);

		return numberMap;
	}, [variablesDataArray]);

	function toggleExpandedRow(rowName: string) {
		setExpandedRows(state => {
			state[rowName] = !state[rowName];
		});
	}

	function expandRows(rowNames: string[]) {
		setExpandedRows(state => {
			rowNames.forEach(rowName => {
				state[rowName] = true;
			});
		});
	}

	function handleDeleteSelected() {
		// 1 VARIABLE SELECTED
		if (checkedState.one.variable) {
			const variableName = checkedState.checked.variables[0];

			return deleteVariableModal.open(variableName);
		}

		// 1 GROUP SELECTED
		if (checkedState.one.group) {
			const groupName = checkedState.checked.groups[0];

			return deleteGroupModal.open(groupName);
		}

		// 1 VARIABLE SET SELECTED
		if (checkedState.one.variableSet) {
			const setName = checkedState.checked.variableSets[0];

			return deleteVariableSetModal.open(setName);
		}

		// 2 OR MORE SELECTED - TRIGGER DELETE SELECTED MODAL
		deleteSelectedModal.open();
	}

	const hasData = hasVariables || hasGroups || hasVariableSets;

	const isTableView = viewOption === VariablesViewOptions.TABLE;

	const { group: selectedGroup, openGroupDrawer, closeGroupDrawer } = useGroupDrawer();
	const {
		variableSet: selectedVariableSet,
		openVariableSetDrawer,
		closeVariableSetDrawer
	} = useVariableSetDrawer();
	const drawerOpen = !!(selectedGroup || selectedVariableSet);

	const loadingVariablesInitial = loadingVariables && !areVariablesFetched;

	const loading = loadingVariablesInitial || loadingProject || loadingPermissions;
	const immediate = !areVariablesFetched || !arePermissionsFetched;

	useKeyPress(
		{
			onEscapeKeyPress: () => {
				if (checkedState.inTotal > 0) resetChecked();
			},
			onDeleteKeyPress: () => {
				if (checkedState.inTotal > 0 && !checkedState.systemGenerated) {
					handleDeleteSelected();
				}
			}
		},
		{ noModalsOpened: true, listen: isTableView }
	);

	return (
		<>
			<VariablesHeader
				hasData={hasData}
				disabled={importingTemplate}
				checkedState={checkedState}
				variableActions={{
					onCreateVariable: variableModal.open,
					onUpdateVariable: variableModal.open
				}}
				groupActions={{
					onCreateGroup: createGroupModal.open,
					onUpdateGroup: updateGroupModal.open
				}}
				variableSetActions={{
					onCreateVariableSet: createVariableSetModal.open,
					onUpdateVariableSet: updateVariableSetModal.open
				}}
				onMoveSelected={moveSelectedVariablesModal.open}
				onDeleteSelected={handleDeleteSelected}
				onAPIFieldsClick={variablesMappingModal.open}
			/>

			<Suspend loading={loading} immediate={immediate}>
				<Grid.Container>
					{entriesErrors?.columns && (
						<>
							<WarningWithFilters
								message={translate(
									dict => dict.variablesPage.warningWithFilters.message
								)}
								actions={[
									{
										onChange: toggleErroredFilter,
										label: translate(
											dict => dict.variablesPage.warningWithFilters.label
										),
										description: translate(
											dict =>
												dict.variablesPage.warningWithFilters.description
										),
										on: draftFilters.errored
									}
								]}
								onClearFilters={clearErroredFilter}
							/>
							<Spacer size={s => s.s} />
						</>
					)}

					<DisableableContainer disabled={importingTemplate}>
						{hasData ? (
							<>
								<VariablesSubHeader drawerOpen={isTableView ? false : drawerOpen} />

								<Spacer size={s => s.xs} />

								<DndProvider backend={supportsTouch ? TouchBackend : HTML5Backend}>
									{/* GRID VIEW */}
									{viewOption === VariablesViewOptions.GRID && (
										<VariablesGrid
											selectedGroup={selectedGroup}
											selectedVariableSet={selectedVariableSet}
											openVariableSetDrawer={openVariableSetDrawer}
											closeVariableSetDrawer={closeVariableSetDrawer}
											openGroupDrawer={openGroupDrawer}
											closeGroupDrawer={closeGroupDrawer}
											variablesDataArray={filteredVariablesDataArray}
											onGroupDelete={deleteGroupModal.open}
											onVariableSetDelete={deleteVariableSetModal.open}
											isVariableModalOpened={variableModal.visible}
											readOnly={!hasVariablesWriteAccess}
											hasErrors={entriesErrors?.columns}
											onVariableClick={variableModal.open}
											onCreateVariable={variableModal.open}
											onCreateGroup={createGroupModal.open}
										/>
									)}

									{/* TABLE VIEW */}
									{viewOption === VariablesViewOptions.TABLE && (
										<VariablesTable
											variablesDataArray={filteredVariablesDataArray}
											expandedRowsData={{
												expandedRows,
												toggleExpandedRow,
												expandRows
											}}
											checkedRowsData={{
												checkedMap,
												checkedState,
												toggleChecked,
												toggleAllChecked
											}}
											rowNumberByName={rowNumberByName}
											readOnly={!hasVariablesWriteAccess}
											hasErrors={entriesErrors?.columns}
											onVariableClick={variableModal.open}
											onGroupClick={updateGroupModal.open}
											onVariableSetClick={updateVariableSetModal.open}
										/>
									)}
								</DndProvider>
							</>
						) : (
							<NoVariables onCreateVariable={variableModal.open} />
						)}

						<Spacer size={s => s.s} />
					</DisableableContainer>
				</Grid.Container>
			</Suspend>

			{/* MODALS */}
			{modalsComponent}

			{variablesMappingModal.visible && (
				<VariablesMapping onClose={variablesMappingModal.close} />
			)}
		</>
	);
}
