import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';
import { Menu } from '@headlessui/react';
import { Svgs } from 'environment';
import { Icon } from 'components/UI/Icons';

type Props = {
	children: React.ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const SplitButton = ({ className, children, ...props }: Props) => {
	return (
		<Menu as="div" className="relative" role="menu">
			<Menu.Button
				type="button"
				aria-label="Toggle options"
				className={clsx(
					'text-sm font-semibold min-h-16 px-4 flex items-center justify-center bg-primary-500 text-white rounded-md hover:bg-primary-700',
					className
				)}
				{...props}
			>
				<Icon propagate svg={Svgs.ChevronDown} colors={{ color: 'white' }} />
			</Menu.Button>

			<Menu.Items className="absolute top-full right-0 min-w-16 my-2 bg-white rounded-md shadow-lg p-2">
				{children}
			</Menu.Items>
		</Menu>
	);
};

const Item = ({
	title,
	...buttonProps
}: {
	title: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
	return (
		<Menu.Item key={title}>
			{({ close }) => {
				return (
					<button
						{...buttonProps}
						className={`w-full text-left bg-white hover:bg-primary-500 hover:text-white rounded-md text-sm font-semibold min-h-16 px-6 whitespace-nowrap ${
							buttonProps.className || ''
						}`}
						onClick={e => {
							buttonProps.onClick?.(e);
							close();
						}}
					>
						{title}
					</button>
				);
			}}
		</Menu.Item>
	);
};

SplitButton.Item = Item;
