import { FormControl } from '../EntryForm';
import { InputType } from 'types';
import { useController } from 'react-hook-form';
import { UserDefinedUniqueFormItem } from '../data/useProjectData/useProjectData';
import clsx from 'clsx';
import { Input } from '../component/Input';

interface Props {
	control: FormControl;
	formItem: UserDefinedUniqueFormItem;
}

export const UserDefinedUniqueVariableInput = ({ control, formItem }: Props) => {
	const { field, fieldState } = useController({
		name: formItem.variable.variableName,
		control
	});

	return (
		<Input
			{...field}
			id={formItem.variable.variableName}
			className={clsx(formItem.hideFromUi && 'hidden')}
			type={InputType.Text}
			label={formItem.variable.variableLabel}
			error={fieldState.error?.message?.toString()}
			description={formItem.variable.description}
			required={formItem.variable.obligatory}
			readOnly={formItem.variable.uniquenessType !== 'Manual'}
			obligatory={formItem.variable.obligatory}
		/>
	);
};
