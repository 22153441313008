export const SeriesOverviewRevamp = () => {
	return (
		<div>
			<h3 className="text-base font-bold">Series overview</h3>

			<p className="mt-6 text-base">
				This is techincally not a new feature but we know not all of our users are aware of
				the series overview page.
			</p>

			<p className="mt-6 text-base">
				You can access the series overview by clicking the &quot;Expand&quot; icon on a
				series in the top level entry form
			</p>

			<div className="flex flex-col mt-10">
				<img
					src="/img/entry-form-1-5-change-log/table_from_series.png"
					alt='Showing the "Expand" icon on a series in the top level entry form'
				/>
			</div>
		</div>
	);
};
