import { OperationResult } from 'hooks/store/types';
import { useSelector } from 'hooks/utils';
import { selectEntry, Entry } from 'store/data/entries';

export function useEntry(): OperationResult<Entry | null> {
	const data = useSelector(state => selectEntry(state.data.entries));
	// We disable the data fetching as it did only fetch data based on the patient login
	return [
		{ data, loading: false, error: false, fetched: true },
		() => {
			/*noop*/
		}
	];
}
