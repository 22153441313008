import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { methods as accountMethods } from 'api/account/subscription/api';
import { RequestError } from 'api/types';
import { Dictionary } from 'environment';
import { LedidiStatusCode, StorageKeys } from 'types/index';

import { DEFAULT_ACTIVITY_TIMEOUT } from 'consts';
import { authLogoutOrManualCleanup } from 'helpers/autoLogout';
import { SignOutStatus } from 'helpers/signOutStatus';
import { getTranslation } from 'hooks/store/ui/useTranslation';
import { toast } from 'react-toastify';

import { isServerErrorCode } from './helpers';

const DEFAULT_REQUEST_TIMEOUT = 30000;
const CANCELED_REQUEST_MESSAGE = 'canceled';

export enum RequestTypes {
	GET,
	POST,
	PUT
}
export class CanceledRequestError extends Error {
	static message = CANCELED_REQUEST_MESSAGE;
}

function Error400(message: string) {
	const error = new Error(message);
	error.name = '400';
	return error;
}

Error400.prototype = Object.create(Error.prototype);

export const signedOutToast = () =>
	toast.error('You got signed out', {
		autoClose: DEFAULT_ACTIVITY_TIMEOUT,
		position: 'top-center',
		closeOnClick: false,
		toastId: 'signed-out-toast'
	});

axios.interceptors.request.use(async (requestConfig: AxiosRequestConfig) => {
	const { withCredentials = true } = requestConfig;
	const abortController = new AbortController();

	if (withCredentials) {
		try {
			const session = await Auth.currentSession();
			const idToken = session.getIdToken().getJwtToken();
			if (requestConfig.data.method === accountMethods.updateAccount) {
				requestConfig.data.userAccessTokenStr = session.getAccessToken().getJwtToken();
			}
			requestConfig.data.accessTokenStr = idToken;
			SignOutStatus.setStatusStarted(false);
		} catch (e: any) {
			const cfg = {
				...requestConfig,
				signal: abortController.signal
			};
			const isFederatedUser = localStorage.getItem(StorageKeys.FederatedUILogin) === 'true';
			if (!SignOutStatus.getStatusStarted()) {
				SignOutStatus.setStatusStarted(true);
				await authLogoutOrManualCleanup(isFederatedUser);
				signedOutToast();
				abortController.abort();
				return cfg;
			} else {
				console.log(
					'Skip SignOut at phase. Was started:',
					SignOutStatus.getStatusStarted()
				);
				abortController.abort();
				return cfg;
			}
		}
	}
	return requestConfig;
});

type AxiosResponseWithErrors = AxiosResponse<RequestError>;

axios.interceptors.response.use(
	async (response: AxiosResponseWithErrors): Promise<AxiosResponseWithErrors> => {
		const { data } = response;

		/**
		 * BYPASS SHOWING SERVER ERROR MESSAGE AND THROWING OF GENERAL ERROR
		 * THIS WILL BE ADJUSTED IN THE FUTURE - AN EPIC WILL BE CREATED FOR API RESPONSES HARMONIZATION
		 */

		if (
			data.ledidiStatusCode === LedidiStatusCode.UniqueFieldValue ||
			data.ledidiStatusCode === LedidiStatusCode.DependenciesViolation
		) {
			return response;
		}

		if (data.ledidiStatusCode && data.ledidiStatusCode === LedidiStatusCode.CorruptedData) {
			throw new Error(getTranslation(({ analysis }) => analysis.errors.corruptedData));
		}

		const is403ServerError = isServerErrorCode(data, 403);
		const is400ServerError = isServerErrorCode(data, 400);

		if (is403ServerError) {
			if (
				data.ledidiStatusCode === LedidiStatusCode.ErrorLicence ||
				data.ledidiStatusCode === LedidiStatusCode.ErrorLicenceOther
			)
				return response;
			throw new Error(data.message || data.errorMessage || Dictionary.errors.api.noAccess);
		} else if (is400ServerError && (data.message || data.errorMessage)) {
			if (data.errors) return response;
			// throw custom 400 error with message
			throw Error400(data.message || data.errorMessage || '');
		}

		return response;
	},
	error => Promise.reject(error)
);

export async function sendRequest<Request = any, Response = any>(
	url: string,
	body: Request,
	config?: AxiosRequestConfig,
	requestType?: RequestTypes
): Promise<Response> {
	const conf = {
		timeout: DEFAULT_REQUEST_TIMEOUT,
		headers: {
			'Content-Type': 'application/json'
		},
		...config
	};

	/**
	 * Handles throttling API priority on the server (3rd party users vs ledidi users)
	 */
	if (process.env.REACT_APP_X_API_KEY) {
		conf.headers['x-api-key'] = process.env.REACT_APP_X_API_KEY;
	}
	if (requestType === RequestTypes.PUT) {
		return axios.put(url, body, conf);
	}

	return axios.post(url, body, conf);
}
