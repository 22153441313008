import { Variable } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import { formElementsIterator } from 'helpers/forms';
import { buildVariablesRichData, variablesDataArrayIterator } from 'helpers/variables';
import { Entry } from 'store/data/entries';
import { Form, FormElement } from 'store/data/forms';
import { Project } from 'store/data/projects';
import { VariablesData } from 'store/data/variables';
import { ElementType, OrientationType } from 'types/index';

import { icons } from './icons';
import { pageStyles } from './style';
import { TIME_DURATION_OPTIONS_PREFIX_KEY_MAP } from 'timeDurationConsts';

function buildEntryHTML(
	entry: Entry | null,
	project: Project | null,
	variablesData: VariablesData
): string {
	const { variablesDataArray } = buildVariablesRichData(variablesData);

	const printTile = `
		<div class="cell_full">
			<h1>${project?.projectName ?? 'Entry'}</h1>
		</div>
	`;

	const entryHTML: string[] = [printTile];

	variablesDataArrayIterator(
		variablesDataArray,
		variable => entryHTML.push(buildVariableHTML(variable)),
		group => {
			entryHTML.push(`
				<div class="cell_full">
					<p class="title">
						${icons.group}
						${group.groupLabel}
					</p>
				</div>
			`);

			group.groupVariables.forEach(variable => entryHTML.push(buildVariableHTML(variable)));

			entryHTML.push(`
				<div class="cell_full">
					<div class="end_group"></div>
				</div>
			`);
		},
		() => null
		// TODO: enable when there's a plan for this
		// variableSet => {
		// 	entryHTML.push(`
		// 		<div class="cell_full">
		// 			<p class="title">
		// 				${icons.series}
		// 				${variableSet.setLabel}
		// 			</p>
		// 		</div>
		// 	`);
		// }
	);

	function buildVariableHTML(variable: Variable): string {
		const { label, name, type, categories, fixedCategories } = variable;
		const isTimeDuration = variable?.type === VariableType.TimeDuration;

		const entryValue = entry?.[name] ?? '';

		if ([VariableType.String].includes(type)) {
			entryValue.length > 35
				? entryHTML.push(`
				<div class="cell_full">
					<label>${label}</label>
					<div class="textarea">${entryValue}</div>
				</div>
			`)
				: entryHTML.push(`
			<div class="cell_half">
				<label>${label}</label>
				<div class="textarea">${entryValue}</div>
			</div>
		`);
		}

		if (
			[
				VariableType.Date,
				VariableType.DateTime,
				VariableType.Float,
				VariableType.Integer,
				VariableType.Unique,
				VariableType.TimeDuration
			].includes(type)
		) {
			entryHTML.push(`
				<div class="cell_half">
					<label>${label} ${
				isTimeDuration
					? `<span>(${variable.durationFormat
							?.map(timeKey => TIME_DURATION_OPTIONS_PREFIX_KEY_MAP[timeKey])
							.join(':')})</span>`
					: ''
			}</label>
					<input type="text" value="${entryValue}" />
				</div>
			`);
		}
		if (type === VariableType.Category) {
			entryHTML.push(`
				<div class="cell_full">
					<label>${label}</label>
					<div class="categories_wrapper">
						${categories
							.map((category, i) =>
								i < 30
									? `
										<span class="category">
											<div class="radio ${entryValue === category.value && 'selected'}"></div>
											<span class="paragraph">
												${category.label || category.value}
											</span>
										</span>
									`
									: ''
							)
							.join('')}
						${
							!fixedCategories
								? `
									<span class="category">
										<div class="radio"></div>
										<div class="type_placeholder"></div>
									</span>
								`
								: ''
						}
						${
							fixedCategories && categories.length > 30
								? `
									<span class="category">
										<div class="radio"></div>
										<div class="type_placeholder"></div>
									</span>
								`
								: ''
						}
					</div>
				</div>
			`);
		}
		if (type === VariableType.CategoryMultiple) {
			entryHTML.push(`
				<div class="cell_full">
					<label>${label}</label>
					<div class="categories_wrapper">
						${categories
							.map((category, i) =>
								i < 30
									? `
										<span class="category">
											<div class="checkbox ${entryValue.includes(category.value) ? 'selected' : ''}">
												${entryValue.includes(category.value) ? icons.checkmark : ''}
											</div>
											<span class="paragraph">
												${category.label || category.value}
											</span>
										</span>
									`
									: ''
							)
							.join('')}
						${
							!fixedCategories
								? `
									<span class="category">
										<div class="checkbox"></div>
										<div class="type_placeholder"></div>
									</span>
								`
								: ''
						}
						${
							fixedCategories && categories.length > 30
								? `
									<span class="category">
										<div class="radio"></div>
										<div class="type_placeholder"></div>
									</span>
								`
								: ''
						}
					</div>
				</div>
			`);
		}

		return '';
	}

	return entryHTML.join('');
}

function buildEntryFormHTML(
	entry: Entry | null,
	project: Project | null,
	variablesData: VariablesData,
	form: Form
): string {
	const { variablesMap } = variablesData;

	const { elements, title } = form;

	const printTile = `
		<div class="cell_full">
			<h1>${project?.projectName ?? 'Entry'}</h1>
		</div>
		<div class="cell_full">
			<h1>${title}</h1>
		</div>
	`;

	const entryHTML: string[] = [printTile];

	formElementsIterator(form, {
		element: element => entryHTML.push(buildFormElementHTML(element)),
		variable: element => entryHTML.push(buildFormElementHTML(element)),
		formGroup: formGroup => {
			const { groupLabel, elementsOrder } = formGroup;

			entryHTML.push(`
				<div class="cell_full">
					<p class="title">
						${icons.group}
						${groupLabel}
					</p>
				</div>
			`);

			elementsOrder.forEach(elementId => {
				if (Array.isArray(elementId)) {
					const [firstElementId, secondElementId] = elementId;

					const first = elements[firstElementId];
					const second = elements[secondElementId];

					const firstHTML = buildFormElementHTML(first, { twoColumns: true });
					const secondHTML = buildFormElementHTML(second, { twoColumns: true });

					const row = `
						<div class="row">
							${firstHTML}
							${secondHTML}
						</div>
					`;

					entryHTML.push(row);
				} else {
					const element = elements[elementId];

					entryHTML.push(buildFormElementHTML(element));
				}
			});

			entryHTML.push(`
				<div class="cell_full">
					<div class="end_group"></div>
				</div>
			`);
		},
		// TODO: enable when there's a plan for this
		formSet: () => null,
		twoColumn: ({ first, second }) => {
			const firstHTML = buildFormElementHTML(first, { twoColumns: true });
			const secondHTML = buildFormElementHTML(second, { twoColumns: true });

			const row = `
				<div class="row">
					${firstHTML}
					${secondHTML}
				</div>
			`;

			entryHTML.push(row);
		}
	});

	function buildFormElementHTML(
		element: FormElement,
		options?: {
			twoColumns?: boolean;
		}
	): string {
		const {
			elementType,
			text,
			label,
			variableRef: variableName = '',
			orientation,
			sliderValues,
			displaySelectedValue,
			mapValuesWithMoods,
			scaleInterval
		} = element;

		const variable = variablesMap[variableName];
		const entryValue = entry?.[variableName] ?? '';

		const cellWidth = `cell_${options?.twoColumns ? 'half' : 'full'}`;
		const isVertical = orientation === OrientationType.Vertical;
		const isCategory = variable?.type === VariableType.Category;
		const isCategoryMultiple = variable?.type === VariableType.CategoryMultiple;
		const isTimeDuration = variable?.type === VariableType.TimeDuration;

		const sliderValuesAssigned = sliderValues ?? [];
		const minValue = parseFloat(sliderValuesAssigned[0]?.value) ?? 0;
		const maxValue =
			parseFloat(sliderValuesAssigned[sliderValuesAssigned.length - 1]?.value) ?? 0;

		const getPercentageWidth = (value: number) => {
			return ((value - minValue) / (maxValue - minValue)) * 100;
		};

		const getSliderDots = () => {
			const interval =
				scaleInterval !== 0 && scaleInterval ? scaleInterval : (maxValue - minValue) / 20;

			const dots: number[] = [];

			if (interval && (minValue !== 0 || maxValue !== 0)) {
				for (let value = minValue; value <= maxValue; value += interval) {
					dots.push(value);
				}
			}

			if (!dots.includes(maxValue)) dots.push(maxValue);

			return dots;
		};

		const getMappings = () => {
			if (!element || !element.sliderValues) return [];

			return element.sliderValues;
		};
		const mappings = getMappings().map((item, index) => {
			const { value, mapping } = item;
			const parsedValue = parseFloat(value);

			const percentage = ((parsedValue - minValue) / (maxValue - minValue)) * 100;

			return `
				<div class="slider_mapping" key="${index}" style="left:${percentage}%">
					${mapping}
				</div>
			`;
		});

		const values = getMappings().map((item, index) => {
			const value = parseFloat(item.value);
			const percentage = ((value - minValue) / (maxValue - minValue)) * 100;

			return `
				<div class="slider_value" key="${index}" style="left:${percentage}%">
					${value}
				</div>
			`;
		});

		if (elementType === ElementType.Subtitle) {
			return `
				<div class="${cellWidth}">
					<h6>${text}</h6>
				</div>
			`;
		}
		if (elementType === ElementType.Text) {
			return `
				<div class="${cellWidth}">
					<p class="paragraph">${text}</p>
				</div>
			`;
		}
		if (elementType === ElementType.Separator) {
			return `
				<div class="${cellWidth}">
					<div class="separator"></div>
				</div>
			`;
		}
		if (elementType === ElementType.Input) {
			const field =
				entryValue.length > 35
					? `
				<div class="cell_full">
					<label>${label} ${
							isTimeDuration
								? `<span>(${variable.durationFormat
										?.map(
											timeKey => TIME_DURATION_OPTIONS_PREFIX_KEY_MAP[timeKey]
										)
										.join(':')})</span>`
								: ''
					  }</label>
					<div class="textarea">${entryValue}</div>
				</div>
			`
					: `
			<div class="cell_half">
				<label>${label} ${
							isTimeDuration
								? `<span>(${variable.durationFormat
										?.map(
											timeKey => TIME_DURATION_OPTIONS_PREFIX_KEY_MAP[timeKey]
										)
										.join(':')})</span>`
								: ''
					  }</label>
				<div class="textarea">${entryValue}</div>
			</div>`;

			if (options?.twoColumns) return field;

			return `
				<div class="cell_full">
					${field}
				</div>
			`;
		}

		if (elementType === ElementType.Slider) {
			const sliderInput = `
				<div class="cell_full">
					<label>${label}</label>
					<div class="slider_main_container">
						<div class="slider_content_wrapper">
							<div class="mood_mappings_container">
								${mapValuesWithMoods ? `<div class="mood_mappings_content">${mappings.join('')}</div>` : ``}
							</div>
							<div class="slider_input_wrapper">
								<input type="range" value="${entryValue}" min="${minValue}" max="${maxValue}">
							</div>
							<div class="slider_dots_container">
							${getSliderDots()
								.map(
									(dot, index) =>
										`
										<div class="slider_dot" key={${index}} style="left: ${getPercentageWidth(dot)}%;">
										</div>
									`
								)
								.join('')}
							</div>
							<div class="slider_values_container">
								${mapValuesWithMoods ? `<div class="slider_values_wrapper">${values.join('')}</div>` : ``}
							</div>
						</div>
						${
							displaySelectedValue
								? `
									<div class="value_container">
										${entryValue}
									</div>
								`
								: ''
						}
					</div>
				</div>
			`;

			if (options?.twoColumns) return sliderInput;

			return `
				<div class="cell_full">
					${sliderInput}
				</div>
			`;
		}

		if (
			elementType === ElementType.Radiobuttons ||
			(elementType === ElementType.Dropdown && isCategory)
		) {
			return `
				<div class="${cellWidth}">
					<label>${label}</label>
					<div class="categories_wrapper ${isVertical ? 'vertical' : ''}">
						${variable.categories
							.map((category, i) =>
								i < 30
									? `
										<span class="category">
											<div class="radio ${category.value === entryValue ? 'selected' : ''}"></div>
											<span class="paragraph">
												${category.label || category.value}
											</span>
										</span>
									`
									: ''
							)
							.join('')}
						${
							!variable.fixedCategories
								? `
									<span class="category">
										<div class="radio"></div>
										<div class="type_placeholder"></div>
									</span>
								`
								: ''
						}
						${
							variable.fixedCategories && variable.categories.length > 30
								? `
									<span class="category">
										<div class="radio"></div>
										<div class="type_placeholder"></div>
									</span>
								`
								: ''
						}
					</div>
				</div>
			`;
		}
		if (
			elementType === ElementType.Checkboxes ||
			(elementType === ElementType.Dropdown && isCategoryMultiple)
		) {
			return `
				<div class="${cellWidth}">
					<label>${label}</label>
					<div class="categories_wrapper ${isVertical ? 'vertical' : ''}">
						${variable.categories
							.map((category, i) =>
								i < 30
									? `
										<span class="category">
											<div class="checkbox ${entryValue.includes(category.value) ? 'selected' : ''}">
												${entryValue.includes(category.value) ? icons.checkmark : ''}
											</div>
											<span class="paragraph">
												${category.label || category.value}
											</span>
										</span>
									`
									: ''
							)
							.join('')}
						${
							!variable.fixedCategories
								? `
									<span class="category">
										<div class="checkbox"></div>
										<div class="type_placeholder"></div>
									</span>
								`
								: ''
						}
						${
							variable.fixedCategories && variable.categories.length > 30
								? `
									<span class="category">
										<div class="radio"></div>
										<div class="type_placeholder"></div>
									</span>
								`
								: ''
						}
					</div>
				</div>
			`;
		}

		return '';
	}

	return entryHTML.join('');
}

function buildHTMLPage(entryHTML: string): string {
	return `
        <html>
            <head>
                <title>Print</title>
                ${pageStyles}
            </head>
            <body>
				${entryHTML}
            </body>
        </html>
    `;
}

interface PrintEntryInput {
	entry: Entry | null;
	form?: Form;
	project: Project | null;
	variablesData: VariablesData;
}

export function printEntry({ entry, form, project, variablesData }: PrintEntryInput) {
	let entryHTML = '';

	if (form) {
		entryHTML = buildEntryFormHTML(entry, project, variablesData, form);
	} else {
		entryHTML = buildEntryHTML(entry, project, variablesData);
	}

	const newWindow = window.open('');

	if (newWindow) {
		newWindow.document.body.innerHTML = buildHTMLPage(entryHTML);
		newWindow.print();

		// setTimeout required to allow the print dialog to open
		setTimeout(() => newWindow.close(), 100);
	}
}
