import React, { useEffect, useMemo, useRef } from 'react';
import { ResponsiveWrapper, TableWrapper } from './SeriesEntryTable.style';
import { Table } from 'components/UI/Table';
import {
	useTable,
	useSortBy,
	Column,
	CellProps,
	ColumnGroup,
	ColumnInstance,
	usePagination,
	Row
} from 'react-table';
import { Entries, Entry, EntryValue } from 'store/data/entries/types';

import {
	useCollaborators,
	useEntriesErrors,
	useExpandGroupsContext,
	useFormsBySetName,
	useSelectedSeriesEntry,
	useSeriesEntries,
	useSeriesEntriesCount,
	useTableSort,
	useTimeDurationEntries,
	useTranslation,
	useVariablesData,
	useVariablesDataSelectItems
} from 'hooks/store';

import { VariableSetData, VariablesDataArrayItem } from 'store/data/variables';

import { ColumnFilter } from 'components/Dataset/Entries/EntriesTable/ColumnFilter';
import { format } from 'date-fns';
import { Variable } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Colors, Svgs } from 'environment';
import { DATE_FORMAT, dateTimeFormatMap, systemGeneratedVariables } from 'consts';
import { IconWrapper } from 'components/Projects/ProjectsTable/ProjectsTable.style';
import { selectUserDateTimeFormat } from 'store/account/subscription';
import {
	ColorIndicator,
	ColumnsPaginationControllers
} from 'components/Dataset/Entries/EntriesTable/EntriesTable.style';
import { Pagination } from 'components/UI/Pagination';
import { Spacer } from 'components/UI/Spacer';

import { ActiveSort, SortingType, TableSort } from 'store/ui/tables/types';
import { ROUTES, SeriesViewType, TableName } from 'types/index';
import { selectSeriesTableParams, setSeriesTableParams, setSeriesViews } from 'store/data/series';
import { ColumnSeriesSettings } from 'components/Dataset/Entries/EntriesTable/ColumnSettings/ColumnSeriesSettings';
import { useSeriesTableVisibleColumns } from 'hooks/store/data/entries/useSeriesTableVisibleColumns';
import { without } from 'lodash';
import { getActiveForms, getFirstActiveForm } from 'helpers/forms';
import {
	buildVariablesRichData,
	isGroupData,
	isVariable,
	mapVariableCategoryValueToLabel,
	mapVariableCategoryValuesToLabels
} from 'helpers/variables';
import { getEntriesTableColumnSortType } from 'helpers/entries';
import { Typography } from 'components/UI/Typography';
import { useDispatch, usePaginate, usePrevious, useSelector } from 'hooks/utils';
import { useRouteMatch } from 'hooks/navigation';
import { countDecimals } from '../helpers';
import { formatScientificNumberEntryValue } from 'store/data/entries/parsers';
const SERIES_DEFAULT_SORT: ActiveSort = {
	column: 'creationdate',
	order: SortingType.Desc
};
interface Props {
	variableSetData: VariableSetData;
	tableType?: string;
	entriesData: Entries;
	isRevisionSelected?: boolean | undefined;
}
export function SeriesEntryTable({
	variableSetData,
	tableType,
	entriesData,
	isRevisionSelected
}: Props) {
	const dispatch = useDispatch();

	const { translate } = useTranslation();
	const isEdit = useRouteMatch([ROUTES.UpdateEntry]);

	const formsBySetName = useFormsBySetName();
	const { setName, setData } = variableSetData;
	const [, setSelectedSeriesEntry] = useSelectedSeriesEntry();
	const forms = useMemo(() => formsBySetName[setName] ?? [], [formsBySetName, setName]);
	const activeForms = useMemo(() => getActiveForms(forms), [forms]);
	const firstActiveForm = useMemo(() => getFirstActiveForm(forms), [activeForms]);
	const { parseTimeDurationEntryCell } = useTimeDurationEntries({
		withTranslation: true
	});

	const { seriesViews } = useSelector(state => state.data.series);

	const [
		{
			data: { entries },
			loading: loadingSeriesEntries,
			fetched: areSeriesEntriesFetched
		}
	] = useSeriesEntries(setName, {
		lazy: !isEdit
	});

	function getFirstFormId() {
		return firstActiveForm?.id ?? null;
	}

	function goToSubEntry(subEntryId: string) {
		if (isRevisionSelected) return;

		setSelectedSeriesEntry({
			setName,
			subEntryId,
			subFormId: getFirstFormId()
		});

		dispatch(
			setSeriesViews({
				seriesViews: {
					gridView: true
				}
			})
		);
	}

	const variablesData = useVariablesData({ initial: true });
	const variablesDataSelectItems = useVariablesDataSelectItems(variablesData);
	const { groupsMap } = buildVariablesRichData(variablesData);

	const tableRef = useRef<HTMLTableElement>(null);
	const tableResponsiveRef = useRef<HTMLDivElement>(null);

	const { expandedSeriesGroups, toggleGroup } = useExpandGroupsContext();

	const [{ errors: entriesErrors, filter: entriesErrorsFilter }] = useEntriesErrors();

	const dateTimeFormat = useSelector(state =>
		selectUserDateTimeFormat(state.account.subscription)
	);

	const [visibleColumnNames] = useSeriesTableVisibleColumns();
	const { handleSort } = useTableSort(TableName.SeriesEntries);

	const { pageIndex, pageSize } = useSelector(state => {
		return selectSeriesTableParams(state.data.series);
	});
	const [{ data: seriesEntriesCount }] = useSeriesEntriesCount({
		lazy: true
	});

	const [
		{
			data: { organizationsMap }
		}
	] = useCollaborators();

	const entriesCountData = seriesEntriesCount[variableSetData.setName]
		? seriesEntriesCount[variableSetData.setName]
		: null;

	const lastEntriesCount = areSeriesEntriesFetched ? entries.slice(-7).length : entriesCountData;

	const hasGroupWithVariables = setData.find(
		variableOrGroup => isGroupData(variableOrGroup) && variableOrGroup.groupVariables.length
	);

	useEffect(() => {
		//IF WE DO NOT HAVE ENTRIES DATA, REDIRECT TO SERIES GRID VIEW
		if (entriesData.length < 1 && areSeriesEntriesFetched) {
			dispatch(
				setSeriesViews({
					seriesViews: {
						gridView: true,
						narrowTableView: false,
						tableFullSizeView: false
					}
				})
			);
			//GRID VIEW
			setSelectedSeriesEntry({
				setName,
				subEntryId: null,
				subFormId: getFirstFormId()
			});
		}
	}, [areSeriesEntriesFetched]);

	const tableColumns = useMemo<Column<Entry>[]>(() => {
		const tableColumns: Column<Entry>[] = [];

		setData.forEach((value: VariablesDataArrayItem) => {
			if (isVariable(value)) {
				tableColumns.push({
					Header: ({ column }) =>
						buildHeader({
							title: value.label,
							column,
							filter:
								tableType !== SeriesViewType.EditEntryView ? (
									<ColumnFilter
										tableRef={tableRef}
										columnName={column.id}
										variablesData={variablesData}
										variablesDataSelectItems={variablesDataSelectItems}
										computePosition
										isSeriesFilter
									/>
								) : null
						}),
					accessor: value.name,
					Cell: ({ value: cellValue }: CellProps<Entry, EntryValue>) => {
						const parsedValue = parseVariableCell(cellValue, value);
						return <>{parsedValue}</>;
					},
					customCellProps: {
						style: {
							position: 'relative'
						}
					},

					customHeaderProps: {
						style: {
							top: !hasGroupWithVariables ? 0 : '2.5rem'
						}
					}
				});
			}
			if (isGroupData(value)) {
				const groupTableColumn: ColumnGroup<Entry> = {
					id: value.groupName,
					Header: buildGroupHeader({
						id: value.groupName,
						title: value.groupLabel,
						customComponent: <ColorIndicator type="group" />,
						icon: (
							<Icon
								onClick={() => toggleGroup(value.groupName)}
								style={{ cursor: 'pointer' }}
								size={size => size.m}
								svg={Svgs.ChevronRight}
								rotate={expandedSeriesGroups[value.groupName] ? 90 : 0}
							/>
						)
					}),
					columns:
						!expandedSeriesGroups[value.groupName] &&
						value.groupName in expandedSeriesGroups
							? [
									{
										accessor: `${value.groupName}-placeholder`,
										Cell: () => <>-</>
									}
							  ]
							: value.groupVariables.map((variable: any) =>
									buildVariableTableColumn(variable)
							  )
				};

				// INSERT TABLE COLUMN ONLY IF IT HAS SUB-COLUMNS;
				if (groupTableColumn.columns.length) tableColumns.push(groupTableColumn);
			}
		});

		// ADD SYSTEM GENERATED VARIABLES
		systemGeneratedVariables.forEach(variableName => {
			const label = translate(
				dict =>
					dict.variables.systemGeneratedVariables[
						variableName as keyof typeof dict.variables.systemGeneratedVariables
					]
			);

			const isGroupName = variableName === 'userProjectOrgId';
			const dateSystemGeneratedVariables = ['creationdate', 'lastmodifieddate'];
			const isDate = dateSystemGeneratedVariables.includes(variableName);

			tableColumns.push({
				Header: ({ column }) =>
					buildHeader({
						title: label,
						column
					}),
				accessor: variableName,
				...(isGroupName && {
					Cell: ({ value }: CellProps<Entry, EntryValue>) => (
						<>
							{value &&
							typeof value === 'string' &&
							organizationsMap &&
							value in organizationsMap
								? organizationsMap[value].name
								: ''}
						</>
					),
					sortType: getEntriesTableColumnSortType
				}),
				...(isDate && {
					Cell: ({ value }: CellProps<Entry, EntryValue>) => {
						return (
							<>
								{format(
									new Date(
										value && typeof value === 'string'
											? value.replace(/\s/g, 'T')
											: (value as string)
									),
									dateTimeFormatMap[dateTimeFormat]
								)}
							</>
						);
					},
					sortType: getEntriesTableColumnSortType
				}),
				customHeaderProps: {
					style: {
						top: !hasGroupWithVariables ? 0 : '2.5rem'
					}
				}
			});
		});

		return tableColumns;
	}, [variableSetData, organizationsMap, expandedSeriesGroups]);

	// ENTRIES COLUMNS NAMES
	const seriesColumnsName: string[] = useMemo(() => {
		const entriesColumns: string[] = [];
		setData.forEach((value: VariablesDataArrayItem) => {
			if (isVariable(value)) {
				entriesColumns.push(value.name);
			}
			if (isGroupData(value)) {
				value.groupVariables.forEach(variable => entriesColumns.push(variable.name));
			}
		});

		// ADD SYSTEM GENERATED VARIABLES
		systemGeneratedVariables.forEach(variableName => entriesColumns.push(variableName));

		return entriesColumns;
	}, []);

	// HERE IS VISIBLE COLUMNS FROM COLUMN SETTINGS
	const filteredColumns = useMemo<string[]>(() => {
		let filtered = [...seriesColumnsName];

		if (visibleColumnNames.length) {
			filtered = filtered.filter(column => visibleColumnNames.includes(column));
		}

		if (
			entriesErrors &&
			entriesErrors.columns &&
			entriesErrors.columns.length &&
			entriesErrorsFilter.columns
		) {
			filtered = filtered.filter(column => entriesErrors.columns.includes(column));
		}

		const collapsedVariables = Object.entries(expandedSeriesGroups)
			.filter(([, expandedSeriesGroups]) => !expandedSeriesGroups)
			.map(([key]) => groupsMap[key].variablesBelongingToGroup)
			.flat();

		filtered = filtered.filter(column => !collapsedVariables.includes(column));

		return filtered;
	}, [
		seriesColumnsName,
		visibleColumnNames,
		entriesErrors,
		entriesErrorsFilter.columns,
		setData,
		expandedSeriesGroups
	]);

	const {
		shouldPaginate: shouldPaginateColumns,
		page: paginatedColumns,
		canPrevPage: canPrevColumnsPage,
		canNextPage: canNextColumnsPage,
		firstPage: firstColumnsPage,
		lastPage: lastColumnsPage,
		prevPage: prevColumnsPage,
		nextPage: nextColumnsPage
	} = usePaginate(
		tableType === SeriesViewType.EditEntryView ? seriesColumnsName : filteredColumns,
		{
			threshold: 300,
			pageSize: 100
		}
	);

	/**
	 * Build table hidden columns based on visible columns (reverse thinking)
	 *
	 * @for `useTable` and table-column-pagination logic
	 */
	const hiddenColumns = useMemo<string[]>(() => {
		const columnNames: string[] = [];

		const hiddenColumnNames = without(seriesColumnsName, ...paginatedColumns);

		columnNames.push(...hiddenColumnNames);

		// Hide `userProjectOrgId` and the user ids
		columnNames.push(systemGeneratedVariables[6]);
		columnNames.push(systemGeneratedVariables[7]);

		return columnNames;
	}, [seriesColumnsName, paginatedColumns, expandedSeriesGroups]);

	function buildHeader(input: {
		title: string;
		column: ColumnInstance<Entry>;
		error?: React.ReactNode;
		filter?: React.ReactNode;
	}) {
		const { title, column, error, filter } = input;

		return (
			<>
				<Flex
					align={a => a.center}
					css={`
						width: 100%;
						white-space: nowrap;

						.column-filter-icon {
							visibility: hidden;
						}

						:hover .column-filter-icon {
							visibility: visible;
						}
					`}
				>
					<Flex fullWidth title={title}>
						{title.length > 20 ? title.substring(0, 20) + '...' : title}

						<Icon
							style={{ opacity: column.isSorted ? 1 : 0 }}
							svg={column.isSortedDesc ? Svgs.ArrowUp : Svgs.ArrowDown}
							size={s => s.m}
							active
							propagate
						/>

						{error}
					</Flex>

					{filter}
				</Flex>
			</>
		);
	}

	function buildGroupHeader(input: {
		id: string;
		title: string;
		customComponent?: React.ReactNode;
		icon?: React.ReactNode;
	}) {
		const { title, customComponent, id, icon } = input;

		return (
			<>
				<Flex
					title={title}
					css={`
						align-items: center;
						width: 100%;
						left: 0.4rem;
						z-index: 999;
						white-space: nowrap;
						cursor: pointer;
					`}
					{...(variablesData.groupsMap[id] && {
						onClick: () => toggleGroup(id)
					})}
				>
					{icon && icon}
					{title.length > 20 ? title.substring(0, 20) + '...' : title}
				</Flex>

				{customComponent}
			</>
		);
	}

	function buildVariableTableColumn(variable: Variable): Column<Entry> {
		const { name, label, type } = variable;

		function buildHeader(input: {
			title: string;
			column: ColumnInstance<Entry>;
			error?: React.ReactNode;
			filter?: React.ReactNode;
		}) {
			const { title, column, error, filter } = input;

			return (
				<>
					<Flex
						align={a => a.center}
						css={`
							width: 100%;
							white-space: nowrap;

							.column-filter-icon {
								visibility: hidden;
							}

							:hover .column-filter-icon {
								visibility: visible;
							}
						`}
					>
						<Flex fullWidth title={title}>
							{title.length > 20 ? title.substring(0, 20) + '...' : title}

							<Icon
								style={{ opacity: column.isSorted ? 1 : 0 }}
								svg={column.isSortedDesc ? Svgs.ArrowUp : Svgs.ArrowDown}
								size={s => s.m}
								active
								propagate
							/>

							{error}
						</Flex>

						{filter}
					</Flex>
				</>
			);
		}

		const isDate = [VariableType.Date, VariableType.DateTime].includes(type);
		const isCategoryMultiple = [VariableType.CategoryMultiple].includes(type);

		// FILTER HEADER
		return {
			Header: ({ column }) =>
				buildHeader({
					title: label,
					column,

					filter:
						tableType !== SeriesViewType.EditEntryView ? (
							<ColumnFilter
								tableRef={tableRef}
								columnName={column.id}
								variablesData={variablesData}
								variablesDataSelectItems={variablesDataSelectItems}
								computePosition
								isSeriesFilter
							/>
						) : null
				}),
			accessor: name,
			Cell: ({ value }: CellProps<Entry, EntryValue>) => {
				const parsedValue = parseVariableCell(value, variable);

				return <>{parsedValue}</>;
			},
			...(isDate && {
				sortType: getEntriesTableColumnSortType
			}),
			...(isCategoryMultiple && {
				sortType: (rowA: Row<Entry>, rowB: Row<Entry>, columnId: string) => {
					const valueA: string[] | undefined = rowA.values[columnId];
					const valueB: string[] | undefined = rowB.values[columnId];

					if (valueA && valueB) {
						const stringValueA = valueA.join(',');
						const stringValueB = valueB.join(',');

						return stringValueA.localeCompare(stringValueB);
					}

					if (valueA && !valueB) return 1;
					if (valueB && !valueA) return -1;

					return -1;
				}
			}),
			customHeaderProps: {
				style: {
					top: !hasGroupWithVariables ? 0 : '2.5rem'
				}
			}
		};
	}

	function parseVariableCell(
		value: EntryValue,
		variable: Variable,
		parseCategoryVariables = true
	): React.ReactNode {
		if (value === null) return translate(dict => dict.seriesTable.empty);

		// `categoryMultiple`
		if (Array.isArray(value)) {
			if (!parseCategoryVariables) return value.join(',');
			const labels = mapVariableCategoryValuesToLabels(value, variable);

			return labels.join(', ');
		}

		// `category`
		if (variable.type === VariableType.Category) {
			if (!parseCategoryVariables) return value;
			const label = mapVariableCategoryValueToLabel(value, variable);

			return label ?? translate(dict => dict.seriesTable.empty);
		}

		// `file`
		if (variable.type === VariableType.File) {
			return (
				<IconWrapper>
					<Icon svg={Svgs.File} size={s => s.m} />
				</IconWrapper>
			);
		}

		// `date`
		if (variable.type === VariableType.Date) {
			return format(new Date(value), DATE_FORMAT);
		}

		// `datetime`
		if (variable.type === VariableType.DateTime) {
			return format(new Date(value), dateTimeFormatMap[dateTimeFormat]);
		}

		// 'timeDuration'
		if (variable.type === VariableType.TimeDuration && variable.durationFormat?.length) {
			return parseTimeDurationEntryCell(value, variable.durationFormat);
		}

		let countedDecimals = 0;
		if (variable.type === VariableType.Float) {
			countedDecimals = countDecimals(Number(value));
		}

		if (variable.type === VariableType.Float) {
			if (variable.visiblePrecision) {
				if (value.includes('e')) {
					// VALUE WITH SCIENTIFICAL NOTATION & visiblePrecision selected
					// cut value to visiblePrecision selected decimals
					const converted = formatScientificNumberEntryValue(
						Number(value),
						variable.visiblePrecision
					);

					return converted;
				} else if (countedDecimals > variable.visiblePrecision) {
					//  VALUE WITH NO  SCIENTIFICAL NOTATION +visiblePrecision selected
					// check if the decimals number of the value is larger than the visiblePrecision  selected and
					// cut it to visiblePrecision selected decimals without rounding it
					const tenExponent = Math.pow(10, Number(variable.visiblePrecision));
					const truncatedNumber = Math.floor(Number(value) * tenExponent) / tenExponent;
					return truncatedNumber.toString();
				}
			} else {
				if (value.includes('e')) {
					// if scientifical notation & no visiblePrecision  cut to 2 decimals
					const converted = formatScientificNumberEntryValue(Number(value), 2);
					return converted;
				} else {
					// if no scientifical notation & no visiblePrecision  cut to 2 decimals as default
					const currentDecimals = countDecimals(Number(value));
					if (currentDecimals < 2) {
						return value;
					}
					const truncatedNumber = Math.floor(Number(value) * 100) / 100;
					return truncatedNumber.toString();
				}
			}
		}

		// LONG VALUE - add ellipsis effect
		if (value.length > 29) return value.substr(0, 30) + '...';

		return value;
	}

	function handleSeriesData() {
		if (tableType === SeriesViewType.EditEntryView) {
			return entriesData.slice(-7);
		}

		return entriesData;
	}

	//USE TABLE
	const {
		page,
		headerGroups,
		state,
		pageOptions,
		getTableProps,
		getTableBodyProps,
		prepareRow,
		gotoPage,
		setPageSize,
		setHiddenColumns,
		setSortBy
	} = useTable(
		{
			columns: tableColumns,
			data: handleSeriesData(),
			initialState: {
				hiddenColumns,
				...{
					pageIndex,
					pageSize,
					sortBy: getSortBy(SERIES_DEFAULT_SORT)
				}
			},
			autoResetPage: false,
			autoResetSortBy: false
		},

		useSortBy,
		usePagination
	);

	/**
	 * Sync `initialState.hiddenColumn` when `hiddenColumns` changes
	 */
	useEffect(() => setHiddenColumns(hiddenColumns), [hiddenColumns]);

	/**
	 * Sync local `state.pageIndex` state with controlled prop `pageIndex`
	 *
	 * NOTE: Not applicable when component is being used in transfer-ownership-preview-modal
	 */
	useEffect(() => {
		if (state.pageIndex !== pageIndex) gotoPage(pageIndex);

		if (pageOptions.length === 1) {
			gotoPage(0);
		}
	}, [
		state.pageIndex,
		pageIndex,
		seriesViews.narrowTableView,
		seriesViews.tableFullSizeView,
		pageOptions
	]);

	/**
	 * Sync local `state.sortBy` state with controlled prop `sortBy`
	 *
	 * NOTE: Not applicable when component is being used in transfer-ownership-preview-modal
	 */
	const prevSortBy = usePrevious(state.sortBy);

	useEffect(() => {
		if (!prevSortBy) return;

		const sortColumn = state.sortBy[0];

		if (sortColumn) handleSort(sortColumn.id);

		// HANDLE DEFAULT CASE => SORTING NULL = SORT DEFAULT ('lastmodifieddate' DESC)
		if (!sortColumn) {
			handleSort(prevSortBy[0].id);
			setSortBy(getSortBy(SERIES_DEFAULT_SORT));
		}
	}, [state.sortBy]);

	/**
	 * Reset horizontal scroll when column pagination changes
	 */

	useEffect(() => {
		const { current } = tableResponsiveRef;

		if (current) current.scrollLeft = 0;
	}, [paginatedColumns]);

	function handleChangePageIndex(pageIndex: number) {
		gotoPage(pageIndex);

		dispatch(
			setSeriesTableParams({
				pageIndex
			})
		);
	}

	function handleChangePageSize(pageSize: number) {
		setPageSize(pageSize);

		dispatch(
			setSeriesTableParams({
				pageSize,
				pageIndex: 0
			})
		);
	}

	function getSortBy(sortedVariable: TableSort) {
		if (sortedVariable) {
			return [
				{
					id: sortedVariable.column,
					desc: sortedVariable.order === SortingType.Desc
				}
			];
		}

		return [];
	}

	//DISPLAY ALL ENTRIES DATA ON NARROW SERIES TABLE VIEW
	useMemo(() => {
		if (seriesViews.narrowTableView) {
			return setPageSize(entriesData.length);
		}
		setPageSize(pageSize);
	}, [seriesViews.narrowTableView]);

	function theLastEntriesShown() {
		if (entries.length > 7 && lastEntriesCount && lastEntriesCount > 1) {
			return `${translate(dict => dict.seriesTable.last)} ${lastEntriesCount} ${translate(
				dict => dict.seriesTable.entries
			)}`;
		}
		if (entries.length < 7 && lastEntriesCount) {
			if (lastEntriesCount > 1) {
				return `${lastEntriesCount} ${translate(dict => dict.seriesTable.entries)}`;
			}
			if (lastEntriesCount <= 1) {
				return `${lastEntriesCount} ${translate(dict => dict.seriesTable.entry)}`;
			}
		}
	}

	return (
		<>
			{tableType !== SeriesViewType.EditEntryView && seriesViews.tableFullSizeView && (
				<>
					<Flex
						css={`
							.column-settings {
								margin-left: 1.6rem;
							}
						`}
					>
						{/* ROWS PAGINATION */}
						<Pagination
							totalCount={entries.length}
							totalCountLabel={translate(dict => dict.seriesTable.entries)}
							filteredCount={entriesData.length}
							pageIndex={state.pageIndex}
							pageSize={
								seriesViews.narrowTableView //ON NARROW TABLE SHOW ALWAYS ALL THE SERIES
									? entriesData.length
									: state.pageSize
							}
							pagesCount={pageOptions.length}
							changePage={handleChangePageIndex}
							changePageSize={handleChangePageSize}
						/>

						<ColumnSeriesSettings
							seriesColumns={seriesColumnsName}
							seriesData={setData}
							className="column-settings"
						/>
					</Flex>

					<Spacer size={s => s.xs} />
				</>
			)}

			<TableWrapper tableType={tableType}>
				{tableType === SeriesViewType.EditEntryView && (
					<>
						<Spacer size={s => s.s} />

						<Typography.Caption color={Colors.text.main}>
							{theLastEntriesShown()}
						</Typography.Caption>

						<Spacer size={s => s.xs} />
					</>
				)}

				<Flex style={{ height: '100%' }}>
					{shouldPaginateColumns && (
						<ColumnsPaginationControllers>
							<Icon
								svg={Svgs.ChevronLeftDouble}
								variant={v => v.buttonActive}
								size={s => s.m}
								disabled={!canPrevColumnsPage}
								onClick={firstColumnsPage}
							/>
							<Icon
								svg={Svgs.ChevronLeft}
								variant={v => v.buttonActive}
								size={s => s.m}
								disabled={!canPrevColumnsPage}
								onClick={prevColumnsPage}
							/>
						</ColumnsPaginationControllers>
					)}
					<ResponsiveWrapper _ref={tableResponsiveRef}>
						<Table
							{...getTableProps()}
							tableRef={tableRef}
							fullWidth
							stickyHead
							hoverEffect
						>
							<Table.Head>
								{headerGroups.map((headerGroup, index) => (
									<Table.Row
										disabled={loadingSeriesEntries}
										{...headerGroup.getHeaderGroupProps()}
										key={headerGroup.getHeaderGroupProps().key}
										clickable
									>
										{headerGroup.headers.map(columnData => (
											<Table.Column
												{...columnData.getHeaderProps([
													columnData.getSortByToggleProps(),
													{
														...columnData.customHeaderProps
													}
												])}
												key={columnData.getHeaderProps().key}
												css={`
													${
														/* Placeholder header for non-group headers */
														columnData.placeholderOf
															? `
                                    border-bottom: 1px !important;
                                    border-bottom-color: transparent !important;
                                    padding: 0.2rem 0.4rem !important;
                                `
															: ''
													}

													${
														/* Header */
														/* @ts-ignore */
														columnData.accessor && index !== 0
															? `
                                    border-top: 0 !important;
                                `
															: ''
													}

                            /* Group header */
                            ${columnData.columns
														? `
                                padding: 0.2rem 0.4rem !important;
                            `
														: ''}
												`}
											>
												{
													// Render the header
													columnData.render('Header')
												}
											</Table.Column>
										))}
									</Table.Row>
								))}
							</Table.Head>

							<Table.Body {...getTableBodyProps()}>
								{page.map(row => {
									row.cells;

									prepareRow(row);

									return (
										<Table.Row
											disabled={loadingSeriesEntries}
											onClick={() =>
												goToSubEntry(row.original.datasetentryid)
											}
											{...row.getRowProps()}
											key={row.getRowProps().key}
											css={'cursor:pointer'}
										>
											{row.cells.map(cell => {
												return (
													<Table.Cell
														{...cell.getCellProps([
															{
																...cell.column.customCellProps
															}
														])}
														key={cell.getCellProps().key}
														noWrap
													>
														{cell.render('Cell')}
													</Table.Cell>
												);
											})}
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
					</ResponsiveWrapper>

					{/* COLUMNS PAGINATION - TO-RIGHT CONTROLLER */}
					{shouldPaginateColumns && (
						<ColumnsPaginationControllers>
							<Icon
								svg={Svgs.ChevronRight}
								variant={v => v.buttonActive}
								size={s => s.m}
								disabled={!canNextColumnsPage}
								onClick={nextColumnsPage}
							/>
							<Icon
								svg={Svgs.ChevronRightDouble}
								variant={v => v.buttonActive}
								size={s => s.m}
								disabled={!canNextColumnsPage}
								onClick={lastColumnsPage}
							/>
						</ColumnsPaginationControllers>
					)}
				</Flex>
			</TableWrapper>
		</>
	);
}
