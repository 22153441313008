import { Svgs } from 'environment';
import { useTranslation } from 'hooks/store';
import { SvgComponent } from 'types/index';

interface IconMetadataProps {
	alt: string;
	tooltip?: string;
}

export function useIconMetadata(svg: SvgComponent): IconMetadataProps {
	const { translate } = useTranslation();

	switch (svg) {
		case Svgs.Add:
			return {
				alt: translate(dict => dict.icon.addAlt),
				tooltip: translate(dict => dict.iconTooltip.add)
			};

		case Svgs.AddSeries:
			return {
				alt: translate(dict => dict.icon.addAlt),
				tooltip: translate(dict => dict.iconTooltip.add)
			};

		case Svgs.ArrowDown:
			return {
				alt: translate(dict => dict.icon.arrowDownAlt)
			};

		case Svgs.ArrowLeft:
			return {
				alt: translate(dict => dict.icon.arrowLeftAlt)
			};

		case Svgs.Calendar:
			return {
				alt: translate(dict => dict.icon.calendarAlt),
				tooltip: translate(dict => dict.iconTooltip.calendar)
			};

		case Svgs.ChevronArrowCorner:
			return {
				alt: translate(dict => dict.icon.chevronArrowCornerAlt)
			};

		case Svgs.ChevronDown:
			return {
				alt: translate(dict => dict.icon.chevronDownAlt)
			};

		case Svgs.ChevronLeft:
			return {
				alt: translate(dict => dict.icon.chevronLeftAlt)
			};

		case Svgs.ChevronLeftDouble:
			return {
				alt: translate(dict => dict.icon.chevronLeftDoubleAlt)
			};

		case Svgs.ChevronRight:
			return {
				alt: translate(dict => dict.icon.chevronRightAlt)
			};

		case Svgs.ChevronRightDouble:
			return {
				alt: translate(dict => dict.icon.chevronRightDoubleAlt)
			};

		case Svgs.ChevronUp:
			return {
				alt: translate(dict => dict.icon.chevronUpAlt)
			};

		case Svgs.Clear:
			return {
				alt: translate(dict => dict.icon.clearAlt),
				tooltip: translate(dict => dict.iconTooltip.clear)
			};

		case Svgs.Clock:
			return {
				alt: translate(dict => dict.icon.clockAlt)
			};

		case Svgs.Close:
			return {
				alt: translate(dict => dict.icon.closeAlt),
				tooltip: translate(dict => dict.iconTooltip.close)
			};

		case Svgs.Delete:
			return {
				alt: translate(dict => dict.icon.deleteAlt),
				tooltip: translate(dict => dict.iconTooltip.delete)
			};
		case Svgs.Merge:
			return {
				alt: translate(dict => dict.icon.mergeAlt),
				tooltip: translate(dict => dict.iconTooltip.merge)
			};
		case Svgs.Import:
			return {
				alt: translate(dict => dict.icon.importAlt),
				tooltip: translate(dict => dict.iconTooltip.import)
			};

		case Svgs.Edit:
			return {
				alt: translate(dict => dict.icon.editAlt),
				tooltip: translate(dict => dict.iconTooltip.edit)
			};

		case Svgs.File:
			return {
				alt: translate(dict => dict.icon.fileAlt)
			};
		case Svgs.FilePlus:
			return {
				alt: translate(dict => dict.icon.filePlusAlt),
				tooltip: translate(dict => dict.iconTooltip.filePlus)
			};
		case Svgs.FileText:
			return {
				alt: translate(dict => dict.icon.fileTextAlt)
			};

		case Svgs.Filter:
			return {
				alt: translate(dict => dict.icon.filterAlt),
				tooltip: translate(dict => dict.iconTooltip.filter)
			};

		case Svgs.Folder:
			return {
				alt: translate(dict => dict.icon.folderAlt)
			};
		case Svgs.FormCheckboxSelected:
			return {
				alt: translate(dict => dict.icon.formCheckboxSelectedAlt)
			};
		case Svgs.FormCheckboxUnselected:
			return {
				alt: translate(dict => dict.icon.formCheckboxUnselectedAlt)
			};
		case Svgs.FormRadioSelected:
			return {
				alt: translate(dict => dict.icon.formRadioSelectedAlt)
			};
		case Svgs.FormRadioUnselected:
			return {
				alt: translate(dict => dict.icon.formRadioUnselectedAlt)
			};

		case Svgs.Help:
			return {
				alt: translate(dict => dict.icon.helpAlt),
				tooltip: translate(dict => dict.iconTooltip.help)
			};
		case Svgs.Information:
			return {
				alt: translate(dict => dict.icon.informationAlt),
				tooltip: translate(dict => dict.iconTooltip.info)
			};
		case Svgs.ExternalLink:
			return {
				alt: translate(dict => dict.icon.externalLinkAlt),
				tooltip: translate(dict => dict.iconTooltip.externalLink)
			};
		case Svgs.MenuProjects:
			return {
				alt: translate(dict => dict.icon.menuProjectsAlt),
				tooltip: translate(dict => dict.iconTooltip.menuProjects)
			};
		case Svgs.MenuProms:
			return {
				alt: translate(dict => dict.icon.menuPromsAlt),
				tooltip: translate(dict => dict.iconTooltip.menuProms)
			};
		case Svgs.Minus:
			return {
				alt: translate(dict => dict.icon.minusAlt)
			};
		case Svgs.More:
			return {
				alt: translate(dict => dict.icon.moreAlt),
				tooltip: translate(dict => dict.iconTooltip.more)
			};

		case Svgs.Notifications:
			return {
				alt: translate(dict => dict.icon.notificationsAlt),
				tooltip: translate(dict => dict.iconTooltip.notifications)
			};
		case Svgs.PreviewOff:
			return {
				alt: translate(dict => dict.icon.previewOffAlt),
				tooltip: translate(dict => dict.iconTooltip.previewOff)
			};
		case Svgs.PreviewOn:
			return {
				alt: translate(dict => dict.icon.previewOnAlt),
				tooltip: translate(dict => dict.iconTooltip.previewOn)
			};
		case Svgs.Print:
			return {
				alt: translate(dict => dict.icon.printAlt),
				tooltip: translate(dict => dict.iconTooltip.print)
			};
		case Svgs.Refresh:
			return {
				alt: translate(dict => dict.icon.refreshAlt),
				tooltip: translate(dict => dict.iconTooltip.refresh)
			};
		case Svgs.Repeat:
			return {
				alt: translate(dict => dict.icon.repeatAlt)
			};
		case Svgs.Search:
			return {
				alt: translate(dict => dict.icon.searchAlt),
				tooltip: translate(dict => dict.iconTooltip.search)
			};

		case Svgs.Settings:
			return {
				alt: translate(dict => dict.icon.settingsAlt),
				tooltip: translate(dict => dict.iconTooltip.settings)
			};
		case Svgs.User:
			return {
				alt: translate(dict => dict.icon.userAlt)
			};
		case Svgs.ViewGrid:
			return {
				alt: translate(dict => dict.icon.viewGridAlt),
				tooltip: translate(dict => dict.iconTooltip.gridView)
			};
		case Svgs.ViewList:
			return {
				alt: translate(dict => dict.icon.viewListAlt)
			};
		case Svgs.ViewTable:
			return {
				alt: translate(dict => dict.icon.viewTabelAlt),
				tooltip: translate(dict => dict.iconTooltip.tableView)
			};
		case Svgs.GroupedView:
			return {
				alt: translate(dict => dict.icon.viewGroupedAlt)
			};
		case Svgs.ChartPie:
			return {
				alt: translate(dict => dict.icon.pieChartAlt)
			};
		case Svgs.StackedView:
			return {
				alt: translate(dict => dict.icon.stackedViewChartAlt)
			};
		case Svgs.SunburstView:
			return {
				alt: translate(dict => dict.icon.sunburstViewChartAlt)
			};
		case Svgs.BoxPlotView:
			return {
				alt: translate(dict => dict.icon.boxPlotViewAlt)
			};
		case Svgs.ScatterView:
			return {
				alt: translate(dict => dict.icon.scatterViewAlt)
			};

		case Svgs.Workplace:
			return {
				alt: translate(dict => dict.icon.workplaceAlt)
			};
		case Svgs.ZoomOut:
			return {
				alt: translate(dict => dict.icon.zoomOutAlt),
				tooltip: translate(dict => dict.iconTooltip.zoomOut)
			};
		case Svgs.EmptyStatesCollaborators:
			return {
				alt: translate(dict => dict.icon.emptyStatesCollaboratorsAlt)
			};
		case Svgs.EmptyStatesNoAnalysis:
			return {
				alt: translate(dict => dict.icon.emptyStatesNoAnalysisAlt)
			};
		case Svgs.EmptyStatesNoDocuments:
			return {
				alt: translate(dict => dict.icon.emptyStatesNoDocumentsAlt)
			};
		case Svgs.EmptyStatesNoVariables:
			return {
				alt: translate(dict => dict.icon.emptyStatesNoVariablesAlt)
			};

		case Svgs.LedidiLogo:
			return {
				alt: translate(dict => dict.icon.ledidiLogoAlt),
				tooltip: translate(dict => dict.iconTooltip.ledidiLogo)
			};
		case Svgs.LedidiLogoColored:
			return {
				alt: translate(dict => dict.icon.ledidiLogoColoredAlt),
				tooltip: translate(dict => dict.iconTooltip.ledidiLogo)
			};
		case Svgs.Move:
			return {
				alt: translate(dict => dict.terms.move),
				tooltip: translate(dict => dict.terms.move)
			};

		case Svgs.Minimize:
			return {
				alt: translate(dict => dict.icon.minimizeTable),
				tooltip: translate(dict => dict.iconTooltip.minimizeTable)
			};
		case Svgs.Maximize:
			return {
				alt: translate(dict => dict.icon.maximizeTable),
				tooltip: translate(dict => dict.iconTooltip.maximizeTable)
			};

		default:
			return {
				alt: '',
				tooltip: ''
			};
	}
}
