import { createActivity } from 'store/ui/activities';
import { Thunk, ActionPayload } from 'store/types';
import {
	ActionTypes,
	GetDependenciesAction,
	UpdateDependencyAction,
	SetRefetchDependenciesAction,
	//////////////////////////////
	Dependency,
	SetDependenciesTablePageParamsAction
} from './types';
import {
	parseTimeDurationDependencies,
	parseTimeDurationDependenciesData
} from 'helpers/dependencies';
import { buildVariablesDataFromStoreData } from 'helpers/variables';
import { getMessageFromError, unknownErrorMessage } from 'store/utils';

export const getDependenciesAction = (
	payload: ActionPayload<GetDependenciesAction>
): GetDependenciesAction => ({
	type: ActionTypes.GET_DEPENDENCIES,
	payload
});

// PARAMS ( PAGE INDEX, PAGE SIZE )
export const setDependenciesTablePageParams = (
	payload: ActionPayload<SetDependenciesTablePageParamsAction>
): SetDependenciesTablePageParamsAction => ({
	type: ActionTypes.DEPENDENCIES_SET_TABLE_PAGE_PARAMS,
	payload
});

export const getDependencies =
	(abortController?: AbortController): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_DEPENDENCIES, dispatch });

		const {
			dependencies: { projectId },
			variables: { byProjectId: byProjectId }
		} = getState().data;

		try {
			activity.begin({ payload: { projectId } });

			if (projectId) {
				const { dependenciesData } = await context.api.data.dependencies().getDependencies(
					{
						projectId: Number(projectId)
					},
					{
						signal: abortController?.signal
					}
				);
				const storeVariablesData = byProjectId[projectId].initial;
				const { variablesMap } = buildVariablesDataFromStoreData(storeVariablesData);

				const parsedDependencies = parseTimeDurationDependenciesData(
					dependenciesData,
					variablesMap
				);

				dispatch(
					getDependenciesAction({
						projectId,
						dependenciesData: parsedDependencies
					})
				);
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: { projectId }
			});
		} finally {
			activity.end();
		}
	};

const updateDependenciesAction = (
	payload: ActionPayload<UpdateDependencyAction>
): UpdateDependencyAction => ({
	type: ActionTypes.UPDATE_DEPENDENCIES,
	payload
});

export const updateDependencies =
	(input: { active: boolean; dependencies: Dependency[]; setName?: string }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.UPDATE_DEPENDENCIES, dispatch });

		const { active, dependencies, setName } = input;

		const {
			dependencies: { projectId },
			variables: { byProjectId }
		} = getState().data;

		try {
			if (projectId) {
				activity.begin();

				const storeVariablesData = byProjectId[projectId].initial;
				const { variablesMap } = buildVariablesDataFromStoreData(storeVariablesData);

				if (projectId) {
					const { active: updatedActive, dependencies: updatedDependencies } =
						await context.api.data.dependencies().updateDependencies({
							projectId: Number(projectId),
							active,
							dependencies,
							...(setName !== undefined && { set: { setName } })
						});

					// TODO: Remove fallback when BE returns `updatedDependencies`
					const deps = updatedDependencies ? updatedDependencies : dependencies;

					const parsedDependencies = parseTimeDurationDependencies(deps, variablesMap);

					dispatch(
						updateDependenciesAction({
							projectId,
							// TODO: Remove fallback when BE returns `updatedActive`
							active: updatedActive ?? active,
							dependencies: parsedDependencies,
							...(setName !== undefined && { setName })
						})
					);
				}
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				payload: { projectId },
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

const setRefetchDependenciesAction = (): SetRefetchDependenciesAction => ({
	type: ActionTypes.SET_REFETCH_DEPENDENCIES
});

export const setRefetchDependencies = (): Thunk => (dispatch, getState) => {
	const { projectId, byProjectId } = getState().data.dependencies;

	if (projectId && byProjectId[projectId]) {
		const { fetched: areDependenciesFetched } = byProjectId[projectId];

		if (areDependenciesFetched) dispatch(setRefetchDependenciesAction());
	}
};
