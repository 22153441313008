import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Typography } from 'components/UI/Typography';
import { CloseIcon, Container, Label } from './FormDesignerDrawer.style';
import { Flex } from 'components/UI/Flex';
import { Colors, Svgs } from 'environment';
import {
	useForm,
	useFormElementDisplayType,
	useFormElementLabel,
	useFormElementOrientation,
	useFormElementText,
	useFormElementType,
	useTranslation
} from 'hooks/store';
import { Input } from 'components/UI/Inputs/Input';
import {
	ElementType,
	HTMLInput,
	InputType,
	Nullable,
	OrientationType,
	SelectItem
	// SliderType
} from 'types/index';
import { InfoMessage } from 'components/UI/InfoMessage';
import { ENTRY_FIELD_FORCE_DROPDOWN_THRESHOLD } from 'consts';
import { Variable } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import {
	FormElement,
	FormElementDisplayType,
	// FormElementSliderType,
	FormElementSliderValue,
	FormErrors
} from 'store/data/forms';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { useCompletedAction } from 'hooks/utils';
import { createPortal } from 'react-dom';
import { RadioGroupUncontrolled } from 'components/UI/Interactables/Uncontrolled';
import { Option } from 'components/UI/Interactables/Uncontrolled/RadioGroup/RadioGroupUncontrolled';
import { Switch } from 'components/UI/Interactables/Switch';
// import { useFormElementSliderType } from 'hooks/store/data/forms/useFormElementSiderType';
import { useFormElementScaleInterval } from 'hooks/store/data/forms/useFormElementScaleInterval';
import { useFormElementDisplaySelectedValue } from 'hooks/store/data/forms/useFormElementDisplaySelectedVaue';
import { useFormElementMapValuesWithMoods } from 'hooks/store/data/forms/useFormElementMapValuesWithMoods';
import { Spacer } from 'components/UI/Spacer';
import { useFormElementSliderValues } from 'hooks/store/data/forms/useFormElementSliderValues';
import SliderValuesEditor from './SliderValuesEditor';
import ValidationRangeSection from './ValidationRangeSection';
import { useFormErrors } from 'hooks/store/data/forms/useFormErrors';

enum FieldType {
	Input = 'input',
	Horizontal = 'horizontal',
	Vertical = 'vertical',
	Dropdown = 'dropdown',
	Slider = 'slider'
}

interface Props {
	variable: Variable;
	elementId: string;
	onClose: () => void;
}

export function FormDesignerDrawer({ variable, elementId, onClose }: Props) {
	const { translate } = useTranslation();

	const DISPLAY_VALUE_SELECT_ITEMS: Option[] = [
		{
			label: translate(dict => dict.formDesigner.showLabels),
			value: FormElementDisplayType.LABELS
		},
		{
			label: translate(dict => dict.formDesigner.showValues),
			value: FormElementDisplayType.VALUES
		}
	];

	const drawerRef = useRef<HTMLDivElement>(null);

	const {
		errors: formErrors,
		setErrors: setFormErrors,
		hasErrors: hasFormErrors
	} = useFormErrors();

	const [{ data: form }] = useForm();
	const setFormElementOrientation = useFormElementOrientation();
	const setFormElementDisplayType = useFormElementDisplayType();
	// const setFormElementSliderType = useFormElementSliderType();
	const setFormElementScaleInterval = useFormElementScaleInterval();
	const setFormElementDisplaySelectedValue = useFormElementDisplaySelectedValue();
	const setFormElementMapValuesWithMoods = useFormElementMapValuesWithMoods();
	const setFormElementSliderValues = useFormElementSliderValues();

	// VALUE -> LABEL SELECT ITEMS MAP
	const FieldTypeSelectItems: Record<FieldType, string> = {
		[FieldType.Input]: translate(dict => dict.terms.input),
		[FieldType.Horizontal]: translate(dict => dict.buttons.radioHorizontalLayout),
		[FieldType.Vertical]: translate(dict => dict.buttons.radioVerticalLayout),
		[FieldType.Dropdown]: translate(dict => dict.buttons.dropdown),
		[FieldType.Slider]: translate(dict => dict.terms.vas)
	};
	/*
	DISABLED UNTIL VERTICLAL SLIDE IS AVAILABLE

	const SliderTypeSelectItems: Record<SliderType, string> = {
		[SliderType.Horizontal]: 'Horizontal', // to add translations
		[SliderType.Vertical]: 'Vertical'
	};
	*/

	const element = useMemo(() => {
		if (!form || !elementId) return;
		return form.elements[elementId] as FormElement;
	}, [elementId, form]);

	const onDisplayTypeChange = useCallback(
		(value: string) => {
			const displayType = value as FormElementDisplayType;
			if (!element) return;
			const { elementId, elementType } = element;
			setFormElementDisplayType({
				elementId,
				elementType,
				displayType
			});
		},
		[variable, element]
	);

	const getFieldTypeSelectItemFromElement = useMemo<SelectItem | null>(() => {
		if (!element) return null;
		if (element.elementType === ElementType.Input) {
			return {
				value: FieldType.Input,
				label: FieldTypeSelectItems[FieldType.Input]
			};
		}
		if (element.elementType === ElementType.Slider) {
			return {
				value: FieldType.Slider,
				label: FieldTypeSelectItems[FieldType.Slider]
			};
		}
		if (element.elementType === ElementType.Dropdown) {
			return {
				value: FieldType.Dropdown,
				label: FieldTypeSelectItems[FieldType.Dropdown]
			};
		}
		if (
			element.elementType === ElementType.Radiobuttons ||
			element.elementType === ElementType.Checkboxes
		) {
			if (element.orientation === OrientationType.Horizontal) {
				return {
					label: FieldTypeSelectItems[FieldType.Horizontal],
					value: FieldType.Horizontal
				};
			} else {
				return {
					label: FieldTypeSelectItems[FieldType.Vertical],
					value: FieldType.Vertical
				};
			}
		}
		return null;
	}, [element]);

	const isBasicElement = element
		? [ElementType.Subtitle, ElementType.Text, ElementType.Separator].includes(
				element.elementType
		  )
		: false;

	const defaultLabel = useMemo(() => {
		if (element) {
			if (isBasicElement) {
				return element.text ?? '';
			} else {
				return element.label ?? '';
			}
		}
		return '';
	}, [element, isBasicElement, variable]);

	const [label, setLabel] = useState(defaultLabel);

	// SYNC label state with element;
	useEffect(() => {
		setLabel(defaultLabel);
	}, [defaultLabel]);

	// UNMOUNT
	useCompletedAction(!!element, false, () => {
		setLabel('');
	});

	const setFormElementText = useFormElementText();
	const setFormElementLabel = useFormElementLabel();
	const setFormElementType = useFormElementType();

	const onFieldTypeSelect = useCallback(
		(value: Nullable<string>) => {
			if (!element) return;
			switch (value) {
				case FieldType.Vertical: {
					(isDropdown || isHorizontal) &&
						setFormElementOrientation({
							elementId: element.elementId,
							elementType: hasMultipleValues
								? ElementType.Checkboxes
								: ElementType.Radiobuttons,
							orientation: OrientationType.Vertical
						});
					break;
				}
				case FieldType.Horizontal: {
					if (isDropdown || isVertical)
						setFormElementOrientation({
							elementId: element.elementId,
							elementType: hasMultipleValues
								? ElementType.Checkboxes
								: ElementType.Radiobuttons,
							orientation: OrientationType.Horizontal
						});
					break;
				}

				case FieldType.Dropdown: {
					!isDropdown &&
						setFormElementType({
							elementId: element.elementId,
							elementType: ElementType.Dropdown
						});
					break;
				}

				case FieldType.Slider: {
					!isSlider &&
						setFormElementType({
							elementId: element.elementId,
							elementType: ElementType.Slider
						});
					break;
				}

				case FieldType.Input: {
					!isInput &&
						setFormElementType({
							elementId: element.elementId,
							elementType: ElementType.Input
						});
					break;
				}
			}
		},
		[element]
	);

	/*
	DISABLED UNTIL VERTICLAL SLIDE IS AVAILABLE
	
	const onSliderTypeSelect = useCallback(
		(value: Nullable<string>) => {
			if (!element) return;
			switch (value) {
				case SliderType.Vertical: {
					setFormElementSliderType({
						elementId: element.elementId,
						elementType: ElementType.Slider,
						sliderType: FormElementSliderType.VERTICAL
					});
					break;
				}
				case SliderType.Horizontal: {
					setFormElementSliderType({
						elementId: element.elementId,
						elementType: ElementType.Slider,
						sliderType: FormElementSliderType.HORIZONTAL
					});
					break;
				}
			}
		},
		[element]
	);
	*/
	useEffect(() => {
		if (variable && element && !element?.sliderValues) {
			let newSliderValues: FormElementSliderValue[] = [
				{ value: '0', mapping: '' },
				{ value: '0', mapping: '' }
			];

			const { validationCases } = variable;
			if (validationCases?.minValue && validationCases?.maxValue) {
				newSliderValues = [
					{ value: validationCases.minValue, mapping: '' },
					{ value: validationCases.maxValue, mapping: '' }
				];
			}

			setFormElementSliderValues({
				elementId: element.elementId,
				sliderValues: newSliderValues
			});
		}
	}, [element]);

	const onClickDisplaySelectedValue = useCallback(() => {
		if (!element) return;
		setFormElementDisplaySelectedValue({
			elementId: element.elementId,
			displaySelectedValue: !element.displaySelectedValue
		});
	}, [element]);

	const onClickMapValuesWithMoods = useCallback(() => {
		if (!element) return;
		setFormElementMapValuesWithMoods({
			elementId: element.elementId,
			mapValuesWithMoods: !element.mapValuesWithMoods
		});
	}, [element]);

	const onClickAddNewValue = useCallback(() => {
		if (!element || hasFormErrors()) return;
		const newValue: FormElementSliderValue = {
			value: '0'
		};
		let newSliderValues = [];

		if (element.sliderValues && element.sliderValues.length > 0) {
			newSliderValues = [
				...element.sliderValues.slice(0, -1),
				newValue,
				element.sliderValues[element.sliderValues.length - 1]
			];
		} else {
			newSliderValues = [newValue];
		}

		setFormElementSliderValues({
			elementId: element.elementId,
			sliderValues: newSliderValues
		});
	}, [element]);

	const onDeleteValue = (index: number) => {
		if (!element || !element.sliderValues) return;

		const newSliderValues = [...element.sliderValues];

		newSliderValues.splice(index, 1);

		setFormElementSliderValues({
			elementId: element.elementId,
			sliderValues: newSliderValues
		});
	};

	const onChangeValue = useCallback(
		(index: number, value: string) => {
			if (!element || !element.sliderValues) return;

			const parsedValue = parseFloat(value);

			const newSliderValues = [...element.sliderValues];
			newSliderValues[index] = {
				...newSliderValues[index],
				value: value
			};

			if (element.scaleInterval) {
				if (parsedValue < element.scaleInterval && index === newSliderValues.length - 1) {
					setFormElementScaleInterval({
						elementId: element.elementId,
						scaleInterval: parsedValue
					});
				} else if (index === newSliderValues.length - 1) {
					const minValue: number = parseFloat(element.sliderValues[0].value);
					const range = parsedValue - minValue;

					const minimumStep =
						variable.type === VariableType.Float ? range / 20 : Math.ceil(range / 20);

					if (minimumStep > element.scaleInterval) {
						setFormElementScaleInterval({
							elementId: element.elementId,
							scaleInterval: minimumStep
						});
					}
				}
			}
			setFormElementSliderValues({
				elementId: element.elementId,
				sliderValues: newSliderValues
			});
		},
		[element, setFormElementSliderValues]
	);

	const onChangeMapping = useCallback(
		(index: number, mapping: Nullable<string>) => {
			if (!element || !element.sliderValues) return;
			const newSliderValues = [...element.sliderValues];
			newSliderValues[index] = {
				...newSliderValues[index],
				mapping: mapping !== null ? mapping : ''
			};

			setFormElementSliderValues({
				elementId: element.elementId,
				sliderValues: newSliderValues
			});
		},
		[element, setFormElementSliderValues]
	);

	/*
	DISABLED UNTIL VERTICLAL SLIDE IS AVAILABLE
	
	const getSliderTypeSelectItemFromElement = useMemo<SelectItem | null>(() => {
		if (!element) return null;
		if (element.sliderType === FormElementSliderType.VERTICAL) {
			return {
				value: SliderType.Vertical,
				label: SliderTypeSelectItems[SliderType.Vertical]
			};
		}

		// Default Horizontal
		return {
			value: SliderType.Horizontal,
			label: SliderTypeSelectItems[SliderType.Horizontal]
		};
	}, [element]);
	*/
	useEffect(() => {
		if (!element || !element.sliderValues || !element.scaleInterval) return;
		scaleIntervalCheckForErrors(element.scaleInterval);
	}, [element]);

	if (!variable || !element) return <></>;

	const isVertical = element.orientation === OrientationType.Vertical;
	const isHorizontal = element.orientation === OrientationType.Horizontal;
	const isDropdown = element.elementType === ElementType.Dropdown;
	const isSlider = element.elementType === ElementType.Slider;
	const isInput = element.elementType === ElementType.Input;
	const hasMultipleValues = variable.type === VariableType.CategoryMultiple;

	const forceDropdown = variable.categories.length > ENTRY_FIELD_FORCE_DROPDOWN_THRESHOLD;

	const onChange = (e: React.ChangeEvent<HTMLInput>) => {
		const { value } = e.target;

		setLabel(value);

		if ('label' in element) {
			setFormElementLabel({
				elementId: element.elementId,
				label: value
			});
		} else {
			setFormElementText({
				elementId: element.elementId,
				text: value
			});
		}
	};

	const updateFormErrors = (newErrors: FormErrors) => {
		const updatedFormErrors = { ...formErrors };

		Object.keys(newErrors).forEach(key => {
			updatedFormErrors[key] = newErrors[key];
		});

		setFormErrors(updatedFormErrors);
	};

	const scaleIntervalCheckForErrors = (scaleInterval: number | null) => {
		if (element?.sliderValues) {
			let scaleIntervalError = '';
			if (scaleInterval) {
				const minValue = parseFloat(element.sliderValues[0].value);
				const maxValue = parseFloat(
					element.sliderValues[element.sliderValues.length - 1].value
				);
				const range = maxValue - minValue;
				const divisions = range / scaleInterval;
				const minimumStep =
					variable.type === VariableType.Float ? range / 20 : Math.ceil(range / 20);

				if (scaleInterval <= 0) {
					scaleIntervalError = `${translate(
						dict => dict.validation.formVariables.greaterThan
					)} 0`;
				} else if (variable.type === VariableType.Integer && scaleInterval % 1 !== 0) {
					scaleIntervalError = translate(
						dict => dict.validation.formVariables.noDecimals
					);
				} else if (divisions > 40) {
					scaleIntervalError = `${translate(
						dict => dict.validation.formVariables.greaterThan
					)} ${minimumStep}`;
				} else if (divisions < 1) {
					scaleIntervalError = `${translate(
						dict => dict.validation.formVariables.lowerThan
					)} ${maxValue}`;
				}
			}
			const newErrors: FormErrors = {
				[elementId]: {
					...formErrors[elementId],
					scaleIntervalError: scaleIntervalError
				}
			};
			updateFormErrors(newErrors);
		}
	};

	const scaleIntervalOnChange = (e: React.ChangeEvent<HTMLInput>) => {
		const { value } = e.target;
		const parsedValue = parseFloat(value);

		// Check for NaN and set a default or handle it accordingly.
		const scaleInterval = isNaN(parsedValue) ? null : parsedValue;

		scaleIntervalCheckForErrors(scaleInterval);

		setFormElementScaleInterval({
			elementId: element.elementId,
			scaleInterval: scaleInterval
		});
	};

	const onBlur = () => {
		const value = label.trim();
		setLabel(value);
		if ('label' in element) {
			setFormElementLabel({
				elementId: element.elementId,
				label: value
			});
		} else {
			setFormElementText({
				elementId: element.elementId,
				text: value
			});
		}
	};

	return createPortal(
		<Container ref={drawerRef} sizes={{ l: 3, m: 0, xl: 3 }} offsets={{ l: 9, m: 0, xl: 9 }}>
			<Flex marginOffset={{ bottom: 3.2 }} justify={j => j.between} align={a => a.center}>
				<Typography.H6>
					{translate(dict => dict.formDesigner.variableOptionsDrawer.title)}
				</Typography.H6>
				<CloseIcon svg={Svgs.Close} onClick={onClose} size={s => s.m} />
			</Flex>

			<Label marginOffset={{ bottom: 0.4 }}>
				{translate(dict => dict.formDesigner.variableOptionsDrawer.variableLabel)}
			</Label>
			<Input readOnly type={InputType.Text} value={variable ? variable.label : ''} />

			<Label marginOffset={{ top: 1.6, bottom: 0.4 }}>
				{translate(dict => dict.formDesigner.variableOptionsDrawer.formVariableLabel)}
			</Label>
			<Input
				onChange={onChange}
				onBlur={onBlur}
				name="label"
				type={InputType.Text}
				value={label}
			/>

			{/* *
			 * CATEGORY TYPE VARIABLE OPTIONS
			 */}
			{(variable.type === VariableType.Category ||
				variable.type === VariableType.CategoryMultiple) && (
				<>
					{forceDropdown && (
						<Flex
							css={`
								padding: 0.4rem;
								margin: 0.8rem;
								margin-top: unset;
								pointer-events: none;
								border-radius: 0.4rem;
								background-color: ${Colors.background.disabled};

								> div > div {
									margin-right: 0.4rem;
									color: ${Colors.text.hint};
								}

								span {
									font-size: 1rem;
									line-height: 1.6;
									color: ${Colors.text.hint};
								}
							`}
						>
							<InfoMessage
								message={translate(
									dict => dict.validation.formVariables.variableAsDropdown
								)}
							/>
						</Flex>
					)}

					{!forceDropdown && (
						<>
							<Label marginOffset={{ top: 1.6, bottom: 0.4 }}>
								{translate(
									dict => dict.formDesigner.variableOptionsDrawer.fieldType
								)}
							</Label>
							<CreatableSelect
								canClear={false}
								value={getFieldTypeSelectItemFromElement}
								items={[
									{
										label: FieldTypeSelectItems[FieldType.Horizontal],
										value: FieldType.Horizontal
									},
									{
										label: FieldTypeSelectItems[FieldType.Vertical],
										value: FieldType.Vertical
									},
									{
										label: FieldTypeSelectItems[FieldType.Dropdown],
										value: FieldType.Dropdown
									}
								]}
								onValueSelected={onFieldTypeSelect}
							/>
						</>
					)}
					{variable.fixedCategories && (
						<>
							<Label marginOffset={{ top: 1.6, bottom: 0.4 }}>Category values</Label>
							<RadioGroupUncontrolled
								value={element.displayType ?? FormElementDisplayType.LABELS}
								name="form-element-display-type"
								options={DISPLAY_VALUE_SELECT_ITEMS}
								isVertical
								onChange={onDisplayTypeChange}
							/>
						</>
					)}
				</>
			)}

			{(variable.type === VariableType.Integer || variable.type === VariableType.Float) && (
				<>
					{getFieldTypeSelectItemFromElement?.value === FieldType.Slider && ( // Currently disabled VAS
						<>
							<Label marginOffset={{ top: 1.6, bottom: 0.4 }}>
								{translate(
									dict => dict.formDesigner.variableOptionsDrawer.displayAs
								)}
							</Label>
							<CreatableSelect
								canClear={false}
								value={getFieldTypeSelectItemFromElement}
								items={[
									{
										label: FieldTypeSelectItems[FieldType.Input],
										value: FieldType.Input
									},
									{
										label: FieldTypeSelectItems[FieldType.Slider],
										value: FieldType.Slider
									}
								]}
								onValueSelected={onFieldTypeSelect}
							/>
						</>
					)}
					{getFieldTypeSelectItemFromElement?.value === FieldType.Slider && (
						<>
							{/* DISABLED UNTIL VERTICLAL SLIDE IS AVAILABLE
							
							<Label marginOffset={{ top: 1.6, bottom: 0.4 }}>{'Slider type'}</Label>
							<CreatableSelect
								canClear={false}
								value={getSliderTypeSelectItemFromElement}
								items={[
									{
										label: SliderTypeSelectItems[SliderType.Horizontal],
										value: SliderType.Horizontal
									},
									{
										label: SliderTypeSelectItems[SliderType.Vertical],
										value: SliderType.Vertical
									}
								]}
								onValueSelected={onSliderTypeSelect}
							/> */}
							{variable.validationCases && (
								<ValidationRangeSection
									minValue={variable.validationCases.minValue}
									maxValue={variable.validationCases.maxValue}
								/>
							)}
							<Label marginOffset={{ top: 1.6, bottom: 0.4 }}>
								{translate(
									dict => dict.formDesigner.variableOptionsDrawer.scaleInterval
								)}
							</Label>
							<Input
								onChange={scaleIntervalOnChange}
								name="Scale interval"
								type={InputType.Number}
								value={element.scaleInterval ?? ''}
								error={formErrors[elementId]?.scaleIntervalError ?? ''}
							/>
							<Spacer size={s => s.s}></Spacer>
							<Switch
								label={translate(
									dict =>
										dict.formDesigner.variableOptionsDrawer.displaySelectedValue
								)}
								onChange={onClickDisplaySelectedValue}
								on={element.displaySelectedValue}
							/>
							<Spacer size={s => s.s}></Spacer>
							<Switch
								label={translate(
									dict =>
										dict.formDesigner.variableOptionsDrawer.mapValuesWithMoods
								)}
								onChange={onClickMapValuesWithMoods}
								on={element.mapValuesWithMoods}
							/>

							<Spacer size={s => s.m} />
							<InfoMessage
								message={translate(
									dict => dict.formDesigner.variableOptionsDrawer.infoMessage
								)}
							/>

							{element.sliderValues && (
								<SliderValuesEditor
									isInteger={variable.type === VariableType.Integer}
									sliderValues={element.sliderValues}
									variableValidationRange={variable.validationRange}
									mapValuesWithMoods={element.mapValuesWithMoods}
									onChangeValue={onChangeValue}
									onDeleteValue={onDeleteValue}
									onChangeMapping={onChangeMapping}
									onClickAddNewValue={onClickAddNewValue}
									elementId={elementId}
								/>
							)}
						</>
					)}
				</>
			)}

			{}
		</Container>,
		document.getElementById('grid-container') as HTMLElement
	);
}
