import { useTranslation } from 'hooks/store';
import { CollaboratorPermissions as CollaboratorPermissionsType } from 'store/data/collaborators';
import { Status } from 'store/data/statuses';

import { Title } from '../styles';
import { CollaboratorPermissions } from '../CollaboratorPermissions';

interface Props {
	statuses: Status[];
	permissions: CollaboratorPermissionsType;
	onChange: (permissions: CollaboratorPermissionsType) => void;
	projectId?: string;
}

export function SetPermissionsCollaborators({ statuses, permissions, onChange, projectId }: Props) {
	const { translate } = useTranslation();

	return (
		<>
			<Title>
				{translate(({ collaborators }) => collaborators.add.title.permissionsStep)}
			</Title>

			<CollaboratorPermissions
				metadata={{
					isSelf: false,
					// needs to be `true` so content is not disabled
					isProjectOwner: true,
					projectId: projectId
				}}
				statuses={statuses}
				permissions={permissions}
				onChange={onChange}
			/>
		</>
	);
}
