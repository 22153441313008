import {
	isStatusChanged,
	RevisionWithChanges,
	type StatusChange,
	useGetDataEntryHistoryQuery
} from '../data/useGetDataEntryHistory';
import { NamesByUserIds, useGetNamesFromUserIdsQuery } from '../data/useGetNamesFromUserIdsQuery';
import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Skeleton } from '../component/Skeleton';
import { StatusDefinition, Variable } from '../types';
import { backendValueToFormValue } from '../utils/parse-backend-values/backendValueToFormValue';
import { BackendFormFile } from '../utils/zodUtils';
import { Diff, ToValue, variableToFieldType } from '../confirm-diff-modal/ConfirmDiffModal';
import { useParams } from 'react-router-dom';
import { Loader } from 'components/UI/Loader';
import { logWithDetails } from 'sentry';
import { useVariablesQuery, VariablesData } from '../data/useVariablesQuery/useVariablesQuery';
import { parseBackendValues } from '../utils/parse-backend-values/parseBackendValues';
import { useGetDataEntryQuery } from '../data/useGetDataEntryQuery';
import { useTracking } from 'app/tracking/TrackingProvider';
import { Dialog } from '../component/Dialog';
import { Button } from '../component/Button';
import { DateTime } from 'luxon';
import { isNotNullOrUndefined } from '../utils/isNotNullOrUndefined';
import { useGetStatusesQuery } from '../data/statuses/useGetStatusesQuery';
import { v4 } from 'uuid';
import { ParsedEntryValue } from '../utils/formUtils';

interface Props {
	open: boolean;
	onClose: () => void;
	entryId: string;
	projectId: string;
	seriesName?: string;
}

export const EntryHistoryModal = ({ open, onClose, entryId, projectId, seriesName }: Props) => {
	const { track } = useTracking();
	useEffect(() => {
		if (open) {
			track({ eventName: 'entry_history_viewed' });
		}
	}, [open]);

	const processedEntryHistoryQuery = useProcessedEntryHistory({ entryId, projectId, seriesName });

	return (
		<Dialog
			title="Entry History"
			open={open}
			onClose={onClose}
			description="Click on a revision to see change details"
		>
			<div className="flex flex-col w-[600px] h-[720px]">
				{processedEntryHistoryQuery.loading && (
					<ul className="flex flex-col flex-1">
						{new Array(10).fill(null).map((_, index) => (
							<li key={index} className="flex flex-col p-4">
								<Skeleton className="w-40 h-6" />
								<Skeleton className="w-48 h-4 mt-2" />
							</li>
						))}
					</ul>
				)}

				{processedEntryHistoryQuery.data && (
					<ul className="flex flex-col flex-1 overflow-y-auto">
						{processedEntryHistoryQuery.data.history?.map((change, index) =>
							index === processedEntryHistoryQuery.data.history.length - 1 ? (
								<FirstRevisionEntry
									key={change.id}
									entryRevisionId={change.id}
									change={change as ProcessedEntryChange} // should be hardcoded to always be of this type
									darkBg={index % 2 == 1}
								/>
							) : (
								<ChangeItem
									key={change.id}
									change={change}
									darkBg={index % 2 == 1}
								/>
							)
						)}
					</ul>
				)}

				<Button title="Close" onClick={onClose} className="self-end mt-4" />
			</div>
		</Dialog>
	);
};

const ChangeItem = ({
	change,
	darkBg
}: {
	change: ProcessedEntryHistoryChange;
	darkBg: boolean;
}) => {
	if (change.type === 'entry-change') {
		return <EntryChange change={change} darkBg={darkBg} />;
	}

	return <StatusChange change={change} darkBg={darkBg} />;
};

const StatusChange = ({ change, darkBg }: { change: ProcessedStatusChange; darkBg: boolean }) => {
	return (
		<div className={clsx('flex flex-col p-4 text-start w-full', darkBg && 'bg-gray-100')}>
			<p className="text-sm">
				{DateTime.fromJSDate(new Date(change.changeDate)).toLocaleString(
					DateTime.DATETIME_MED
				)}
			</p>

			{isSingleStatusChange(change) && (
				<p className="text-base mt-2">
					<b>{change.userFullName}</b> {change.action} status <b>{change.statusLabel}</b>
				</p>
			)}

			{!isSingleStatusChange(change) && (
				<p className="text-base mt-2">
					<b>{change.userFullName}</b> changed status from <b>{change.oldStatusLabel}</b>{' '}
					to <b>{change.newStatusLabel}</b>
				</p>
			)}

			{statusChangeCanHaveComment(change) && change.comment && (
				<p className="text-sm italic mt-2">{change.comment}</p>
			)}
		</div>
	);
};

const FirstRevisionEntry = ({
	change,
	entryRevisionId,
	darkBg
}: {
	change: ProcessedEntryChange;
	entryRevisionId: string;
	darkBg: boolean;
}) => {
	const { track } = useTracking();

	const [expanded, setExpanded] = useState(false);

	return (
		<li>
			<button
				onClick={() => {
					setExpanded(!expanded);
					track({ eventName: 'entry_history_expanded' });
				}}
				className={clsx('flex flex-col p-4 text-start w-full', darkBg && 'bg-gray-100')}
			>
				<p className="text-sm">
					{DateTime.fromJSDate(new Date(change.changeDate)).toLocaleString(
						DateTime.DATETIME_MED
					)}
				</p>

				<p className="text-base mt-2">
					<b>{change.userFullName || change.userName}</b> created <b>data entry</b>
				</p>

				{expanded && <FirstRevisionEntryContent entryRevisionId={entryRevisionId} />}
			</button>
		</li>
	);
};

const FirstRevisionEntryContent = ({ entryRevisionId }: { entryRevisionId: string }) => {
	const params = useParams();
	const projectId = params.projectId as string;

	const dataEntryQuery = useGetDataEntryQuery({
		entryId: entryRevisionId,
		projectId
	});
	const variablesQuery = useVariablesQuery({ projectId });

	if (dataEntryQuery.isLoading || variablesQuery.isLoading) {
		return (
			<div className="w-full flex justify-center items-center p-4">
				<Loader />
			</div>
		);
	}

	if (!dataEntryQuery.data || !variablesQuery.data) {
		logWithDetails('Data entry not found', { projectId, entryRevisionId });

		return (
			<div className="w-full flex justify-center items-center p-4">
				<p className="text-base text-error-500 font-semibold">Error loading entry</p>
			</div>
		);
	}

	const variables = Object.values(variablesQuery.data.variables);
	const formValues = parseBackendValues({
		variables,
		entry: dataEntryQuery.data.entry
	});

	return (
		<ul className="flex flex-col mt-2 gap-4">
			{Object.entries(formValues)
				.filter(([_, value]) => !isEmptyFormValue(value))
				.map(([variableName, entryValue]) => {
					const variable = variables.find(
						variable => variable.variableName === variableName
					);

					if (!variable) {
						console.error(`Variable for ${variableName} not found`);
						return null;
					}

					const fieldType = variableToFieldType(variable);

					return (
						<li key={variable.variableName} className="flex flex-col gap-2">
							<p className="text-base">{variable.variableLabel}</p>

							<ToValue to={entryValue} fieldType={fieldType} variable={variable} />
						</li>
					);
				})}
		</ul>
	);
};

export const isEmptyFormValue = (value: ParsedEntryValue) => {
	if (Array.isArray(value)) {
		return value.length === 0;
	}

	return value === '' || value === null || value === undefined;
};

const EntryChange = ({ change, darkBg }: { change: ProcessedEntryChange; darkBg: boolean }) => {
	const { track } = useTracking();
	const [expanded, setExpanded] = useState(false);

	return (
		<li>
			<button
				onClick={() => {
					setExpanded(!expanded);
					track({ eventName: 'entry_history_expanded' });
				}}
				className={clsx('flex flex-col p-4 text-start w-full', darkBg && 'bg-gray-100')}
			>
				<p className="text-sm">
					{DateTime.fromJSDate(new Date(change.changeDate)).toLocaleString(
						DateTime.DATETIME_MED
					)}
				</p>

				<p className="text-base mt-2">
					<b>{change.userFullName || change.userName}</b> edited <b>data entry</b>
				</p>

				{expanded && change.changes.length > 0 && (
					<ul className="flex flex-col mt-2">
						{change.changes.map((change, index) => {
							return (
								<li key={index} className="flex flex-col mt-2">
									<p className="text-sm font-semibold">
										{change.variable.variableLabel}
									</p>

									<Diff
										changedFieldDiff={{
											from: change.from,
											to: change.to
										}}
										fieldType={variableToFieldType(change.variable)}
										variable={change.variable}
									/>
								</li>
							);
						})}
					</ul>
				)}

				{expanded && change.changes.length === 0 && (
					<p className="text-base italic mt-2">No changes</p>
				)}
			</button>
		</li>
	);
};

const useProcessedEntryHistory = ({
	entryId,
	projectId,
	seriesName
}: {
	entryId: string;
	projectId: string;
	seriesName?: string;
}) => {
	const getDataEntryHistoryQuery = useGetDataEntryHistoryQuery({
		entryId,
		projectId,
		setName: seriesName
	});

	const variablesQuery = useVariablesQuery({ projectId });

	const allUserIds =
		getDataEntryHistoryQuery.data?.revisionsWithChanges.map(revision => revision.userName) ??
		[];
	const userIdsSet = new Set(allUserIds);
	const userIds = Array.from(userIdsSet);

	const getNamesFromUserIdsQuery = useGetNamesFromUserIdsQuery(
		{
			userIds
		},
		{ enabled: userIds.length !== 0 }
	);

	const statusesQuery = useGetStatusesQuery({ projectId });

	const processedHistory: ProcessedEntryHistoryChange[] = useMemo(() => {
		if (
			!getDataEntryHistoryQuery.data ||
			!getNamesFromUserIdsQuery.data ||
			!variablesQuery.data ||
			!statusesQuery.data
		) {
			return [] as ProcessedEntryHistoryChange[];
		}

		const entryChanges = processEntryChanges({
			revisionsWithChanges: getDataEntryHistoryQuery.data.revisionsWithChanges,
			variablesData: variablesQuery.data,
			namesByUserIds: getNamesFromUserIdsQuery.data.namesFromUserIds
		});

		const statusChanges = processStatusChanges({
			statusChanges: getDataEntryHistoryQuery.data.statusChanges,
			statusDefinitions: statusesQuery.data?.statuses,
			userNamesByUserIds: getNamesFromUserIdsQuery.data.namesFromUserIds
		});

		return [...entryChanges, ...statusChanges].sort((a, b) => {
			return new Date(b.changeDate).getTime() - new Date(a.changeDate).getTime();
		});
	}, [getDataEntryHistoryQuery, getNamesFromUserIdsQuery, variablesQuery, statusesQuery]);

	return {
		loading: getDataEntryHistoryQuery.isLoading || getNamesFromUserIdsQuery.isLoading,
		data: {
			history: processedHistory
		}
	};
};

const processEntryChanges = ({
	revisionsWithChanges,
	variablesData,
	namesByUserIds
}: {
	revisionsWithChanges: RevisionWithChanges[];
	variablesData: VariablesData;
	namesByUserIds: NamesByUserIds;
}): ProcessedEntryChange[] => {
	const aggregationRuleNames = Object.values(variablesData.sets).flatMap(
		set => set.aggregationRules?.map(rule => rule.name) ?? []
	);

	const processed: ProcessedEntryChange[] = revisionsWithChanges
		.map((revision, index) => {
			// E.g. aggregation rules trigger new entry revisions without changes, these are not interesting for the history
			// But we want to keep the initial revision in the history
			if (index > 0 && revision.changes.length === 0) {
				return null;
			}

			const revisionIsForAggregationRule = revision.changes.some(change =>
				aggregationRuleNames.includes(change.variableName)
			);

			if (revisionIsForAggregationRule) {
				return null;
			}

			return {
				active: revision.active,
				changeDate: revision.creationDate,
				changes: backendRevisionToDiffedFields(revision, variablesData),
				id: revision.revisionId,
				type: 'entry-change',
				userName: revision.userName,
				userFullName: namesByUserIds[revision.userName]
			} as const;
		})
		.filter(isNotNullOrUndefined);

	return processed;
};

const processStatusChanges = ({
	statusChanges,
	statusDefinitions,
	userNamesByUserIds
}: {
	statusChanges: StatusChange[][];
	statusDefinitions: StatusDefinition[];
	userNamesByUserIds: NamesByUserIds;
}): ProcessedStatusChange[] => {
	return statusChanges
		.map(statusChange => {
			const id = v4();
			const relevant = statusChange.filter(isStatusChanged);

			if (relevant.length === 0) {
				return null;
			}

			if (relevant.length === 1) {
				// status was created or deleted
				const changedStatus = relevant[0];
				const statusDefinition = statusDefinitions.find(
					status => status.statusName === changedStatus.variableName
				);

				if (changedStatus.to?.value) {
					// status was created
					return {
						id,
						changeDate: changedStatus.to?.lastmodified ?? '',
						userFullName: userNamesByUserIds[changedStatus.to?.lastmodifiedbyuser],
						type: 'status-change',
						action: 'set',
						comment: changedStatus.to?.comment,
						statusLabel: statusDefinition?.statusLabel ?? ''
					} as StatusSet;
				}

				if (changedStatus.from?.value) {
					// status was deleted
					return {
						id,
						changeDate: changedStatus.to?.lastmodified ?? '',
						userFullName: userNamesByUserIds[changedStatus.from?.lastmodifiedbyuser],
						type: 'status-change',
						action: 'removed',
						statusLabel: statusDefinition?.statusLabel ?? ''
					} as StatusRemoved;
				}
			}

			if (relevant.length > 2) {
				// Should be noop
				console.error(new Error('More than 2 status changes in a single revision'), {
					statusChange
				});
			}

			// status was changed
			const newActiveStatus = relevant.find(change => change.to?.value);
			const deactivatedStatus = relevant.find(change => !change.to?.value);

			const newActiveStatusDefinition = statusDefinitions.find(
				status => status.statusName === newActiveStatus?.variableName
			);
			const deactivatedStatusDefinition = statusDefinitions.find(
				status => status.statusName === deactivatedStatus?.variableName
			);

			if (!newActiveStatusDefinition) {
				console.error(new Error('Status definition not found'), {
					newActiveStatusDefinition
				});
			}
			if (!deactivatedStatusDefinition) {
				console.error(new Error('Status definition not found'), {
					deactivatedStatusDefinition
				});
			}

			return {
				id,
				changeDate: newActiveStatus?.to?.lastmodified ?? '',
				userFullName: userNamesByUserIds[newActiveStatus?.to?.lastmodifiedbyuser ?? ''],
				type: 'status-change',
				action: 'changed',
				comment: newActiveStatus?.to?.comment,
				oldStatusLabel: deactivatedStatusDefinition?.statusLabel ?? '',
				newStatusLabel: newActiveStatusDefinition?.statusLabel ?? ''
			} as StatusChanged;
		})
		.filter(isNotNullOrUndefined);
};

const backendRevisionToDiffedFields = (
	revision: RevisionWithChanges,
	variablesData: VariablesData
): ProcessedEntryChange['changes'] => {
	const diffedFields = revision.changes.map(change => {
		const variable = variablesData.variables[change.variableName];
		const allAggregationRuleNames: string[] = [];
		for (const series of Object.values(variablesData.sets)) {
			allAggregationRuleNames.push(
				...(series.aggregationRules?.map(rule => rule.name) || [])
			);
		}

		if (!variable) {
			if (!allAggregationRuleNames.includes(change.variableName)) {
				console.error(new Error('Variable not found'), {
					variableName: change.variableName
				});
			}
			return;
		}

		return {
			variable,
			from: backendValueToFormValue({ value: change.from, variable }),
			to: backendValueToFormValue({ value: change.to, variable })
		};
	});

	return diffedFields.filter(isNotUndefined);
};

const isNotUndefined = <T,>(value: T | undefined): value is T => {
	return value !== undefined;
};

type ProcessedEntryChange = {
	type: 'entry-change';

	active: boolean;
	changes: {
		variable: Variable;
		from: string | BackendFormFile;
		to: string | BackendFormFile;
	}[];
	changeDate: string;
	id: string;
	userFullName?: string;
	userName: string;
};

type StatusChangeBase = {
	type: 'status-change';
	userFullName?: string;
	id: string;
	changeDate: string;
};

type StatusChanged = StatusChangeBase & {
	action: 'changed';
	comment?: string;
	oldStatusLabel: string;
	newStatusLabel: string;
};

type StatusSet = StatusChangeBase & {
	action: 'set';
	statusLabel: string;
	comment?: string;
};

type StatusRemoved = StatusChangeBase & {
	action: 'removed';
	statusLabel: string;
};

const statusChangeCanHaveComment = (
	status: ProcessedStatusChange
): status is StatusChanged | StatusSet => {
	return status.action === 'changed' || status.action === 'set';
};
const isSingleStatusChange = (
	status: ProcessedStatusChange
): status is StatusSet | StatusRemoved => {
	return status.action === 'set' || status.action === 'removed';
};

type ProcessedStatusChange = StatusChanged | StatusSet | StatusRemoved;

type ProcessedEntryHistoryChange = ProcessedEntryChange | ProcessedStatusChange;
