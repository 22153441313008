import { useMatchProjects } from 'hooks/navigation';
import { LocalOperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { setProjectsSearchTerm } from 'store/data/projects';

export function useProjectsSearchTerm(): LocalOperationResult<string, (term: string) => void> {
	const matchProjects = useMatchProjects();
	const dispatch = useDispatch();

	const projectsData = useSelector(state => state.data.projects.filters.projects.term);

	function handler(term: string) {
		dispatch(setProjectsSearchTerm({ term, isProject: matchProjects }));
	}

	return [projectsData, handler];
}
