import { useEffect } from 'react';
import { ROUTES } from 'types/navigation';
import { useNavigation, useRouteMatch } from 'hooks/navigation';
import { useAlerts } from 'hooks/ui';
import { useTranslation } from '..';
import { useIsProjectOwner } from '../data/projects/useIsProjectOwner';
import { useIsProjectValid } from '../data/projects/useIsProjectValid';
import { useProjectId } from '../data/projects/useProjectId';
import { usePermissions } from './usePermissions';

enum Pages {
	Dataset = 'Dataset',
	Statuses = 'Statuses',
	CreateEntry = 'Create entry',
	Analysis = 'Analysis',
	Variables = 'Variables',
	VariableTemplates = 'Templates',
	Dependencies = 'Dependencies',
	CreateVariable = 'Create variable',
	UpdateVariable = 'Update variable',
	Collaborators = 'Collaborators',
	AddCollaborators = 'Invite collaborators',
	ImportDataset = 'Import dataset',
	Documents = 'Documents',
	ProjectDesign = 'Project Design',
	Projects = 'Project Design'
}

export function usePermissionAccess() {
	const { setError } = useAlerts();
	const { translate } = useTranslation();
	const { replace, routes } = useNavigation();

	const [projectId] = useProjectId();
	const isProjectValid = useIsProjectValid();
	const isProjectOwner = useIsProjectOwner();

	const {
		hasAnalysisAccess,
		hasDatasetReadAccess,
		hasDatasetWriteAccess,
		hasVariablesAccess,
		hasVariablesWriteAccess,
		hasDatasetReadAllAccess,
		hasModulesAccess
	} = usePermissions();

	const isOnDatasetPage = useRouteMatch([ROUTES.Dataset]);
	const isOnStatusesPage = useRouteMatch([ROUTES.EntryStatuses]);
	const isOnAddEntryPage = useRouteMatch([ROUTES.CreateEntry]);
	const isOnAnalysisPage = useRouteMatch([ROUTES.Analysis]);
	const isOnVariablesPage = useRouteMatch([ROUTES.Variables]);
	const isOnVariableTemplatesPage = useRouteMatch([ROUTES.Templates]);
	const isOnVariableDependenciesPage = useRouteMatch([ROUTES.Dependencies]);
	const isOnImportDatasetPage = useRouteMatch([ROUTES.ImportDataset]);
	const isOnAddCollaboratorsPage = useRouteMatch([ROUTES.AddCollaborators]);
	const isOnDocumentsPage = useRouteMatch([ROUTES.Documents]);
	const isOnCollaboratorsPage = useRouteMatch([ROUTES.Collaborators]);
	const isOnFormsPage = useRouteMatch([ROUTES.Forms, ROUTES.Form]);
	const isOnProjectDesignPage =
		isOnVariablesPage ||
		isOnVariableTemplatesPage ||
		isOnVariableDependenciesPage ||
		isOnFormsPage ||
		isOnStatusesPage;

	useEffect(() => {
		if (!(isProjectValid && projectId)) return;

		let pageToRedirect: Pages | null = null;
		let pageError: Pages | null = null;

		// DATASET PAGE
		if (isOnDatasetPage && !hasDatasetReadAccess) {
			pageError = Pages.Dataset;

			if (hasAnalysisAccess) {
				pageToRedirect = Pages.Analysis;
			} else if (hasVariablesAccess) {
				pageToRedirect = Pages.Variables;
			} else {
				pageToRedirect = Pages.Collaborators;
			}
		}

		// STATUSES PAGE
		if (isOnStatusesPage && !isProjectOwner) {
			pageError = Pages.Statuses;

			if (hasDatasetReadAccess) {
				pageToRedirect = Pages.Dataset;
			} else if (hasAnalysisAccess) {
				pageToRedirect = Pages.Analysis;
			} else if (hasVariablesAccess) {
				pageToRedirect = Pages.Variables;
			} else {
				pageToRedirect = Pages.Collaborators;
			}
		}

		// CREATE ENTRY PAGE
		if (isOnAddEntryPage && !hasDatasetWriteAccess) {
			pageError = Pages.CreateEntry;

			if (hasDatasetReadAccess) {
				pageToRedirect = Pages.Dataset;
			} else if (hasAnalysisAccess) {
				pageToRedirect = Pages.Analysis;
			} else if (hasVariablesAccess) {
				pageToRedirect = Pages.Variables;
			} else {
				pageToRedirect = Pages.Collaborators;
			}
		}

		// ANALYSIS PAGE
		if (isOnAnalysisPage && !hasAnalysisAccess) {
			pageError = Pages.Analysis;

			if (hasDatasetReadAccess) {
				pageToRedirect = Pages.Dataset;
			} else if (hasVariablesAccess) {
				pageToRedirect = Pages.Variables;
			} else {
				pageToRedirect = Pages.Collaborators;
			}
		}

		// VARIABLES PAGE
		if (isOnVariablesPage && !hasVariablesAccess) {
			pageError = Pages.Variables;

			if (hasDatasetReadAccess) {
				pageToRedirect = Pages.Dataset;
			} else if (hasAnalysisAccess) {
				pageToRedirect = Pages.Analysis;
			} else {
				pageToRedirect = Pages.Collaborators;
			}
		}

		// VARIABLE TEMPLATES PAGE
		if (isOnVariableTemplatesPage && !hasVariablesAccess) {
			pageError = Pages.VariableTemplates;

			if (hasDatasetReadAccess) {
				pageToRedirect = Pages.Dataset;
			} else if (hasAnalysisAccess) {
				pageToRedirect = Pages.Analysis;
			} else {
				pageToRedirect = Pages.Collaborators;
			}
		}

		// CREATE / UPDATE DEPENDENCIES PAGE
		if (isOnVariableDependenciesPage && !hasVariablesWriteAccess) {
			pageError = Pages.Dependencies;

			if (hasVariablesAccess) {
				pageToRedirect = Pages.Variables;
			} else if (hasDatasetReadAccess) {
				pageToRedirect = Pages.Dataset;
			} else if (hasAnalysisAccess) {
				pageToRedirect = Pages.Analysis;
			} else {
				pageToRedirect = Pages.Collaborators;
			}
		}

		// IMPORT DATASET PAGE
		if (isOnImportDatasetPage && !(hasDatasetWriteAccess && hasVariablesWriteAccess)) {
			pageError = Pages.ImportDataset;

			if (hasDatasetReadAccess) {
				pageToRedirect = Pages.Dataset;
			} else if (hasAnalysisAccess) {
				pageToRedirect = Pages.Analysis;
			} else if (hasVariablesAccess) {
				pageToRedirect = Pages.Variables;
			} else {
				pageToRedirect = Pages.Collaborators;
			}
		}

		// DOCUMENTS PAGE
		if (isOnDocumentsPage && !hasDatasetReadAllAccess) {
			pageError = Pages.Documents;

			if (hasDatasetReadAccess) {
				pageToRedirect = Pages.Dataset;
			} else if (hasAnalysisAccess) {
				pageToRedirect = Pages.Analysis;
			} else if (hasVariablesAccess) {
				pageToRedirect = Pages.Variables;
			} else {
				pageToRedirect = Pages.Collaborators;
			}
		}

		// ADD COLLABORATOR PAGE
		if (isOnAddCollaboratorsPage && !isProjectOwner) {
			pageError = Pages.AddCollaborators;
			pageToRedirect = Pages.Collaborators;
		}

		// PROJECT DESIGN PAGE
		if (isOnProjectDesignPage && !hasModulesAccess.projectDesign) {
			pageError = Pages.ProjectDesign;

			if (hasDatasetReadAccess) {
				pageToRedirect = Pages.Dataset;
			} else if (hasAnalysisAccess) {
				pageToRedirect = Pages.Analysis;
			} else if (hasModulesAccess.collaborators) {
				pageToRedirect = Pages.Collaborators;
			} else if (hasDatasetReadAllAccess) {
				pageToRedirect = Pages.Documents;
			} else {
				pageToRedirect = Pages.Projects;
			}
		}

		// COLLABORATORS PAGE
		if (isOnCollaboratorsPage && !hasModulesAccess.collaborators) {
			pageError = Pages.Collaborators;

			if (hasDatasetReadAccess) {
				pageToRedirect = Pages.Dataset;
			} else if (hasAnalysisAccess) {
				pageToRedirect = Pages.Analysis;
			} else if (hasVariablesAccess) {
				pageToRedirect = Pages.Variables;
			} else if (hasDatasetReadAllAccess) {
				pageToRedirect = Pages.Documents;
			} else {
				pageToRedirect = Pages.Projects;
			}
		}

		if (pageToRedirect) handleRedirect(pageToRedirect, projectId);
		if (pageError) handleError(pageError);
	}, [isProjectValid]);

	function handleRedirect(pageName: Pages, projectId: string) {
		let route = '';

		if (pageName === Pages.Dataset) {
			route = routes.projects.dataset.view(projectId);
		}
		if (pageName === Pages.Analysis) {
			route = routes.projects.analysis(projectId);
		}
		if (pageName === Pages.Variables) {
			route = routes.projects.variables.view(projectId);
		}
		if (pageName === Pages.Collaborators) {
			route = routes.projects.collaborators.view(projectId);
		}

		replace(route);
	}

	function handleError(pageName: Pages) {
		setError({
			message: translate(({ noAccessMessage }) => noAccessMessage.message, false, {
				pageName
			})
		});
	}
}
