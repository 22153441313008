import { useEffect, useState } from 'react';
import { NoDocuments } from './NoDocuments';
import { DocumentsList } from './List/DocumentsList';
import { DocumentsHeader } from './Header/DocumentsHeader';
import { Grid } from 'components/UI/Grid';
import { Suspend } from 'components/UI/Suspend';
import { usePermissions, useProject, useProjectDocuments } from 'hooks/store';
import { Dialog } from 'features/entry-form-v2/component/Dialog';
import { LanguageType } from 'types';
import { useSelector } from 'hooks/utils';
import { useParams } from 'react-router-dom';
import { getProjectFilePostURL } from './api/getProjectFilePostURL';
import { S3_ERRORS, uploadFileToS3 } from 'features/entry-form-v2/data/submit-files/submitFiles';
import { Loader } from 'components/UI/Loader';
import { confirmFileUpload } from './api/confirmFileUpload';
import { InputError } from 'features/entry-form-v2/component/InputError';
import {
	NO_SIZE_LIMIT,
	useGetFreeSizeQuery
} from 'features/entry-form-v2/data/useGetFreeSizeQuery';
import { LedidiRequestError } from 'features/entry-form-v2/data/makeRequest';

export function DocumentsPage() {
	const {
		hasDatasetWriteAllAccess,
		fetched: arePermissionsFetched,
		loading: loadingPermissions
	} = usePermissions();

	const [{ loading: loadingProject }] = useProject();
	const [{ loading: loadingDocuments, data: documents, fetched: areDocumentsFetched }] =
		useProjectDocuments();

	const [uploadModalVisible, setUploadModalVisible] = useState(false);

	const hasDocuments = documents.length > 0;

	const loadingDocumentsInitial = loadingDocuments && !areDocumentsFetched;

	const loading = loadingDocumentsInitial || loadingProject || loadingPermissions;

	const immediate = !arePermissionsFetched || !areDocumentsFetched;

	return (
		<>
			<DocumentsHeader
				fetching={loadingDocuments}
				showAddFileButton={hasDatasetWriteAllAccess}
				hasDocuments={hasDocuments}
				onAddFileClick={() => setUploadModalVisible(true)}
			/>

			<Suspend loading={loading} immediate={immediate}>
				<Grid.Container>
					{documents.length ? (
						<DocumentsList documents={documents} />
					) : (
						<NoDocuments onAddFileClick={() => setUploadModalVisible(true)} />
					)}
				</Grid.Container>
			</Suspend>

			<UploadModal open={uploadModalVisible} onClose={() => setUploadModalVisible(false)} />
		</>
	);
}

const UploadModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
	const params = useParams();
	const projectId = params.id as string;

	const freesize = useGetFreeSizeQuery({
		projectId
	});
	const freeSizeInMb = (freesize.data?.freeSize ?? 0) / 1000 / 1000;

	const language = useSelector(state => state.ui.i18n.language);
	const translation = DICTIONARY[language];

	const [_, fetchProjectDocuments] = useProjectDocuments();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string>();
	useEffect(() => {
		if (error) {
			setError(undefined);
		}
	}, [open]);

	const uploadFile = async (file: File) => {
		try {
			setLoading(true);

			const getProjectFilePostURLResponse = await getProjectFilePostURL({
				fileName: file.name,
				projectId: projectId
			});

			await uploadFileToS3({
				file,
				uploadUrl: getProjectFilePostURLResponse.uploadURL,
				fields: getProjectFilePostURLResponse.postRequestFields
			});

			await confirmFileUpload({
				fileId: getProjectFilePostURLResponse.fileId,
				projectId: projectId
			});

			fetchProjectDocuments();
			onClose();
		} catch (error) {
			if (error instanceof LedidiRequestError) {
				if (error.errors[0].code === S3_ERRORS.FILE_TOO_LARGE) {
					setError(translation.fileTooLarge(freeSizeInMb.toFixed(2)));
				}
			} else {
				console.error(error);
				setError(translation.genericError);
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} title={translation.title}>
			<div className="relative w-[400px] h-[300px] flex flex-col gap-4">
				<label
					htmlFor="file-input"
					className="flex-1 flex justify-center items-center rounded-md bg-stone-100 border-dashed border-stone-300 border-2"
				>
					{loading && (
						<div className="absolute inset-0 flex items-center justify-center gap-4">
							<p className="text-base font-semibold">Uploading</p>
							<Loader color={c => c.primary} />
						</div>
					)}
					{!loading && <p className="text-base">{translation.clickToSelectFile}</p>}
				</label>

				<input
					id="file-input"
					hidden
					type="file"
					onChange={e => {
						if (!freesize.data) {
							console.error('No freesize data, this is a no-op');
						}

						if (e.target.files) {
							const fileSize = e.target.files[0].size;

							const remainingSpace = (freesize.data && freesize.data.freeSize) || 0;

							if (remainingSpace !== NO_SIZE_LIMIT) {
								if (fileSize > remainingSpace) {
									setError(translation.fileTooLarge(freeSizeInMb.toFixed(2)));
									return;
								}
							}

							uploadFile(e.target.files[0]);
						}
					}}
				/>

				{error && <InputError error={error} />}
			</div>
		</Dialog>
	);
};

const DICTIONARY = {
	[LanguageType.English]: {
		title: 'Upload file',
		uploadButton: 'Upload',
		clickToSelectFile: 'Click to select file',
		fileTooLarge: (freeSizeInMb: string) =>
			`Storage limit exceeded, you have ${freeSizeInMb}MB of free space left for this project. To get more storage space, contact us at support@ledidi.no`,
		genericError:
			'Something went wrong, please try again. If the problem persists, please contact support.'
	},

	[LanguageType.Norwegian]: {
		title: 'Last opp fil',
		uploadButton: 'Last opp',
		clickToSelectFile: 'Klikk for å velge fil',
		fileTooLarge: (freeSizeInMb: string) =>
			`Lagringsoverført, du har ${freeSizeInMb}MB ledig plass igjen for dette prosjektet. For å få mer lagringsplass, kontakt oss på support@ledidi.no`,
		genericError:
			'Noe gikk galt, vennligst prøv igjen. Hvis problemet vedvarer, vennligst kontakt support.'
	}
};
