import { ChevronDownIcon, CloseIcon, MoreIcon, PlusIcon } from '@icons';
import { useGetRepeatingSetRowsV2Query } from '../../data/useGetRepeatingSetRowsV2Query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ROUTE_MAP } from '../../utils/routeMap';
import clsx from 'clsx';
import { useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { useFormSelector } from '../SelectForm';

export const SeriesEntryNavigation = () => {
	const params = useParams();
	const entryId = params.entryId as string;
	const projectId = params.projectId as string;
	const seriesName = params.seriesName as string;
	const seriesEntryId = params.seriesEntryId as string;

	const navigate = useNavigate();

	const { formId } = useFormSelector({
		projectId,
		seriesName
	});

	const _getRepeatingDataSetRowsV2Query = useGetRepeatingSetRowsV2Query({
		entryId,
		projectId,
		setName: seriesName
	});

	const entriesOrderedByCreationDate = useMemo(
		() =>
			_getRepeatingDataSetRowsV2Query.data?.dataRows.sort(
				(a, b) => new Date(a.creationdate).getTime() - new Date(b.creationdate).getTime()
			) || [],
		[_getRepeatingDataSetRowsV2Query.data]
	);

	if (_getRepeatingDataSetRowsV2Query.isLoading) {
		return null;
	}

	if (
		!_getRepeatingDataSetRowsV2Query.data ||
		_getRepeatingDataSetRowsV2Query.data.dataRows.length === 0
	) {
		return null;
	}

	const currentEntryIndex = entriesOrderedByCreationDate.findIndex(
		row => row.datasetentryid === seriesEntryId
	);

	if (currentEntryIndex === undefined) {
		return null;
	}

	const nextEntryId =
		currentEntryIndex === entriesOrderedByCreationDate.length - 1
			? undefined
			: entriesOrderedByCreationDate[currentEntryIndex + 1].datasetentryid;
	const previousEntryId =
		currentEntryIndex === 0
			? undefined
			: entriesOrderedByCreationDate[currentEntryIndex - 1]?.datasetentryid;

	const menuItems = getMenuItems({
		totalCount: entriesOrderedByCreationDate.length,
		currentEntryIndex
	});

	const isCreatingEntry = !seriesEntryId;

	return (
		<div
			data-testid="series-entry-navigation"
			className="flex items-center justify-center w-full mb-6 gap-6"
		>
			{!previousEntryId ? (
				<div className="w-14 h-14" />
			) : (
				<StyledLink
					to={ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.update.createPath(
						{
							projectId,
							entryId,
							seriesName,
							seriesEntryId: previousEntryId,
							formId
						}
					)}
				>
					<ChevronDownIcon className="text-icon-600 rotate-90" />
				</StyledLink>
			)}

			<div className="flex items-center justify-center gap-2">
				{menuItems.map(menuItem => {
					if (menuItem.type === 'number') {
						const seriesEntryId =
							entriesOrderedByCreationDate[menuItem.entryIndex].datasetentryid;

						return (
							<NumberMenuItem
								key={menuItem.entryIndex}
								menuItem={menuItem}
								to={ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.update.createPath(
									{
										projectId,
										entryId,
										seriesName,
										seriesEntryId,
										formId
									}
								)}
								isActive={menuItem.entryIndex === currentEntryIndex}
							/>
						);
					}

					return (
						<DotsMenuItem
							key={menuItem.type + v4()}
							onPageSubmitted={pageNumber => {
								const seriesEntryId =
									entriesOrderedByCreationDate[pageNumber - 1].datasetentryid;

								navigate(
									ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.update.createPath(
										{
											projectId,
											entryId,
											seriesName,
											seriesEntryId,
											formId
										}
									)
								);
							}}
							maxIndex={entriesOrderedByCreationDate.length}
						/>
					);
				})}

				{isCreatingEntry && (
					<div className="w-14 h-14 text-base flex items-center justify-center rounded-full hover:bg-primary-700/10 bg-primary-500/10 text-primary-500">
						{entriesOrderedByCreationDate.length + 1}
					</div>
				)}

				{!isCreatingEntry && (
					<StyledLink
						to={ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.create.createPath(
							{
								projectId,
								entryId,
								seriesName,
								formId
							}
						)}
					>
						<PlusIcon className="text-icon-base" />
					</StyledLink>
				)}
			</div>

			{!nextEntryId || isCreatingEntry ? (
				<div className="w-14 h-14" />
			) : (
				<StyledLink
					to={ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.update.createPath(
						{
							projectId,
							entryId,
							seriesName,
							seriesEntryId: nextEntryId,
							formId
						}
					)}
				>
					<ChevronDownIcon className="text-icon-600 -rotate-90" />
				</StyledLink>
			)}
		</div>
	);
};

const NumberMenuItem = ({
	menuItem,
	to,
	isActive
}: {
	menuItem: NumberMenuItem;
	to: string;
	isActive: boolean;
}) => {
	return (
		<StyledLink
			to={to}
			className={clsx('text-base', isActive && 'bg-primary-500/10 text-primary-500')}
		>
			{menuItem.entryIndex + 1}
		</StyledLink>
	);
};

const DotsMenuItem = ({
	onPageSubmitted,
	maxIndex
}: {
	onPageSubmitted: (page: number) => void;
	maxIndex: number;
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const formRef = useRef<HTMLFormElement>(null);
	const [showInput, setShowInput] = useState(false);
	const [inputValue, setInputValue] = useState<number>();
	return (
		<form
			className="relative"
			ref={formRef}
			onSubmit={e => {
				e.preventDefault();

				onPageSubmitted(Number(inputRef.current?.value));
				setShowInput(false);

				if (inputRef.current) {
					inputRef.current.value = '';
				}
			}}
		>
			<button
				onClick={() => {
					setShowInput(true);
				}}
				type="button"
				className="w-14 h-14 flex items-center justify-center "
			>
				<MoreIcon className="text-icon-base" />
			</button>

			{showInput && (
				<div className="flex items-center flex-row gap-2 absolute top-0 bg-white shadow-normal z-10">
					<input
						className={clsx(
							'w-40 h-14 rounded-md  text-base px-4',
							!showInput && 'hidden'
						)}
						autoFocus
						max={maxIndex}
						min={1}
						type="number"
						onChange={e => {
							setInputValue(parseInt(e.target.value));
						}}
						value={inputValue}
						ref={inputRef}
					/>

					<button className="bg-primary-500 rounded-md p-4 text-white">Go</button>

					<button
						className="hover:bg-gray-300 rounded-full p-2"
						type="button"
						onClick={() => {
							setShowInput(false);
						}}
					>
						<CloseIcon className="text-icon-base" />
					</button>
				</div>
			)}
		</form>
	);
};

const StyledLink = ({
	to,
	children,
	className
}: {
	to: string;
	children: React.ReactNode;
	className?: string;
}) => {
	return (
		<Link
			to={to}
			className={clsx(
				'w-14 h-14 flex items-center justify-center rounded-full hover:bg-primary-700/10',
				className
			)}
			replace
		>
			{children}
		</Link>
	);
};

const MAX_MENU_ENTRIES = 5;
const getMenuItems = ({
	totalCount,
	currentEntryIndex
}: {
	totalCount: number;
	currentEntryIndex: number;
}): MenuItem[] => {
	if (totalCount <= MAX_MENU_ENTRIES) {
		return Array.from({ length: totalCount }, (_, index) => ({
			type: 'number',
			entryIndex: index
		}));
	}

	if (currentEntryIndex < 3) {
		return [
			{
				type: 'number',
				entryIndex: 0
			},
			{
				type: 'number',
				entryIndex: 1
			},
			{
				type: 'number',
				entryIndex: 2
			},

			{
				type: 'dots'
			},

			{
				type: 'number',
				entryIndex: totalCount - 2
			},
			{
				type: 'number',
				entryIndex: totalCount - 1
			}
		];
	}

	if (currentEntryIndex > totalCount - 3) {
		return [
			{
				type: 'number',
				entryIndex: 0
			},
			{
				type: 'number',
				entryIndex: 1
			},

			{
				type: 'dots'
			},

			{
				type: 'number',
				entryIndex: totalCount - 3
			},
			{
				type: 'number',
				entryIndex: totalCount - 2
			},
			{
				type: 'number',
				entryIndex: totalCount - 1
			}
		];
	}

	return [
		{
			type: 'number',
			entryIndex: 0
		},

		{
			type: 'dots'
		},

		{
			type: 'number',
			entryIndex: currentEntryIndex
		},

		{
			type: 'dots'
		},

		{
			type: 'number',
			entryIndex: totalCount - 1
		}
	];
};

type NumberMenuItem = { type: 'number'; entryIndex: number };
type DotsMenuItem = { type: 'dots' };
type MenuItem = NumberMenuItem | DotsMenuItem;
