import { useState, useEffect } from 'react';
import { Svgs } from 'environment';
import { ActionTypes, Entry } from 'store/data/entries';
import { Revision } from 'store/data/revisions';
import { Status, StatusesMap } from 'store/data/statuses';
import { DetailsView, HistoryView, StatusView } from './Views';
import { Container, Views, View, ViewHeader, ViewBody } from './EntryDrawer.style';
import { useSetRevisionId } from 'hooks/store/data/revisions/useSetRevisionId';
import { UnrestoredRevisionModal } from 'components/Dataset/Modals/UnrestoredRevisionModal/UnrestoredRevisionModal';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Tooltip } from 'components/UI/Interactables/Tooltip';
import { Loader } from 'components/UI/Loader';
import { Typography } from 'components/UI/Typography';
import { getHeaderHeight } from 'helpers/generic';
import {
	useTranslation,
	useUpdateEntry,
	useActivities,
	useRevisionId,
	useRevisions,
	useRevision,
	useSetRevision,
	useSetRevisions,
	useNamesFromUserIds,
	useCurrentRevision
} from 'hooks/store';
import { useCompletedAction, useKeyPress } from 'hooks/utils';
import { usePrevious } from 'hooks/utils/index';
import { EntryDrawerType } from 'types/index';

enum VIEWS {
	Details = 'details',
	History = 'revisions',
	Status = 'status'
}

interface Props {
	detailsView: {
		entry: Entry;
	};
	historyView: {
		revisions: Revision[];
		revisionId: string | null;
		loading: boolean;
		disabled: boolean;
		onSelect: (revisionId: string) => void;
		onOpen?: () => void;
		onClose?: () => void;
	};
	statusView: {
		statuses: Status[];
	};
	statusesMap: StatusesMap;
	onOpen?: () => void;
	onClose?: () => void;
	listenToEscape?: boolean;
	disableModal?: boolean;
	entryType: string;
}

export function EntryDrawer({
	detailsView,
	historyView,
	statusView,
	statusesMap,
	onOpen,
	onClose,
	listenToEscape = true,
	disableModal,
	entryType
}: Props) {
	const { translate } = useTranslation();

	const [, setCurrentRevision] = useCurrentRevision();

	const [view, setView] = useState<VIEWS | null>(null);
	const [prevView, setPrevView] = useState<VIEWS | null>(null);

	const [{ loading: updatingEntry, error: errorUpdatingEntry }] = useUpdateEntry();

	const [{ loading: updatingOrCreatingSeriesEntry, error: errorUpdatingOrCreatingSeriesEntry }] =
		useActivities([ActionTypes.UPDATE_SERIES_ENTRY, ActionTypes.CREATE_SERIES_ENTRY]);

	const namesFromUserIds = useNamesFromUserIds();

	const [, setRevisionId] = useRevisionId();
	const [, setSetRevisionId] = useSetRevisionId();

	const [modalVisible, setModalVisible] = useState(false);

	// Update revisionIds when updating main entry;
	useCompletedAction(updatingEntry, errorUpdatingEntry, () => {
		setRevisionId(null);
	});

	// Update revisionIds and setRevisionIds when updating or creating series entry;
	useCompletedAction(updatingOrCreatingSeriesEntry, errorUpdatingOrCreatingSeriesEntry, () => {
		setSetRevisionId(null);
		setRevisionId(null);
	});

	useEffect(() => {
		if (prevView === undefined) return;
		if (prevView && view) return;

		view ? onOpen?.() : onClose?.();
	}, [view]);

	const isEntryDrawer = true;

	function handleSetView(value: VIEWS) {
		if (value === view) return closeView();

		if (value === VIEWS.History) historyView.onOpen?.();
		setPrevView(view);
		setView(value);
	}

	const closeView = () => {
		historyView.onClose?.();
		setPrevView(view);
		setView(null);
		setCurrentRevision(null);
		if (modalVisible) {
			setModalVisible(false);
		}
	};

	const closeEntryDrawer = () => {
		if (!historyView.revisionId || modalVisible || disableModal) {
			closeView();
			setRevisionId(null);
			setSetRevisionId(null);
			return;
		}
		if (!modalVisible && !disableModal) {
			setModalVisible(true);
		}
	};

	const viewTitle = {
		[VIEWS.Details]: translate(dict => dict.dataset.entryDrawer.entryDetails),
		[VIEWS.History]: translate(dict => dict.dataset.entryDrawer.entryHistory),
		[VIEWS.Status]: translate(dict => dict.dataset.entryDrawer.status)
	};

	const viewItems = [
		{
			type: VIEWS.Details,
			icon: Svgs.Information,
			'data-testid': 'entry-drawer-details-view'
		},
		{
			type: VIEWS.History,
			icon: Svgs.Clock,
			'data-testid': 'entry-drawer-history-view'
		}
		// TODO: enable when implemented
		// {
		// 	type: VIEWS.Status,
		// 	icon: Svgs.Tag
		// }
	];

	const viewLoader = {
		[VIEWS.Details]: false,
		[VIEWS.History]: historyView.loading,
		[VIEWS.Status]: false
	};

	const viewDisabled = {
		[VIEWS.Details]: false,
		[VIEWS.History]: historyView.disabled,
		[VIEWS.Status]: false
	};

	useKeyPress(
		{ onEscapeKeyPress: closeEntryDrawer },
		{ noModalsOpened: true, listen: !!view && listenToEscape }
	);

	const [{ data: revisions, loading: loadingRevisions, fetched: hasRevisions }] = useRevisions({
		lazy: true
	});
	const [{ data: revision }] = useRevision({ lazy: true });
	const [{ data: setRevision }] = useSetRevision({ lazy: true });
	const [{ data: setRevisions }] = useSetRevisions({ lazy: true });

	const previousRevisions = usePrevious(revisions);

	// Automatically selects latest revision when opening history drawer
	useEffect(() => {
		if (entryType === EntryDrawerType.AddEditForm) {
			if (revisions?.length && !revision && view === VIEWS.History) {
				const lastRevisionId = revisions[revisions.length - 1].revisionId;
				setRevisionId(lastRevisionId);
			}
		}
	}, [revisions, revision, view]);

	useEffect(() => {
		if (
			loadingRevisions &&
			hasRevisions &&
			revision &&
			previousRevisions &&
			revisions &&
			previousRevisions.length !== revisions.length
		) {
			setRevisionId(null);
		}
	}, [loadingRevisions, hasRevisions, revisions, previousRevisions]);

	// Automatically selects latest set revision when opening history drawer
	useEffect(() => {
		if (entryType === EntryDrawerType.SeriesEntryForm) {
			if (setRevisions?.length && !setRevision && view === VIEWS.History) {
				const lastSetRevisionId = setRevisions[setRevisions.length - 1].revisionId;
				setSetRevisionId(lastSetRevisionId);
			}
		}
	}, [setRevisions, setRevision, view]);

	return (
		<Container heightOffset={getHeaderHeight(isEntryDrawer).rem}>
			<View isOpenedView={!!view}>
				<Views>
					{viewItems.map(viewItem => (
						<Flex
							key={viewItem.type}
							marginOffset={{ bottom: 2.4 }}
							// tooltip props
							data-tip={viewTitle[viewItem.type]}
							data-for={viewItem.type}
						>
							<Icon
								dataTestId={viewItem['data-testid']}
								title={viewTitle[viewItem.type]}
								svg={viewItem.icon}
								size={s => s.l}
								variant={v => v.buttonActive}
								active={view === viewItem.type}
								onClick={() => handleSetView(viewItem.type)}
							/>
							{view !== viewItem.type && (
								<Tooltip id={viewItem.type} delayShow={250} place="left" />
							)}
						</Flex>
					))}
				</Views>
				{view && (
					<Flex column fullWidth>
						<ViewHeader>
							<Flex>
								<Typography.H6 title={viewTitle[view]}>
									{viewTitle[view]}
								</Typography.H6>

								{viewLoader[view] && <Loader marginOffset={{ left: 0.4 }} />}
							</Flex>

							<Icon svg={Svgs.Close} size={s => s.m} onClick={closeEntryDrawer} />
						</ViewHeader>

						<ViewBody disabled={viewDisabled[view]}>
							{view === VIEWS.Details && <DetailsView entry={detailsView.entry} />}
							{view === VIEWS.History && (
								<HistoryView
									revisions={historyView.revisions}
									revisionId={historyView.revisionId}
									statusesMap={statusesMap}
									onSelect={historyView.onSelect}
									namesFromUserIds={namesFromUserIds}
								/>
							)}
							{view === VIEWS.Status && <StatusView />}
						</ViewBody>
					</Flex>
				)}
			</View>

			<UnrestoredRevisionModal
				visible={!!modalVisible}
				onPrimaryClick={closeView}
				onNeutralClick={() => setModalVisible(false)}
				latestRevisionDate={
					historyView.revisions[0]
						? historyView.revisions[historyView.revisions.length - 1].creationDate
						: ''
				}
			/>
		</Container>
	);
}
