import { InfoMessage } from 'components/UI/InfoMessage';
import { Spacer } from 'components/UI/Spacer';
import { TagInput } from 'components/UI/TagInput';
import { keyMaps } from 'consts';
import { SetState } from 'types/index';

import { Title } from '../styles';
import { useTranslation } from 'hooks/store';
import { useState } from 'react';
import { makeRequest } from 'features/entry-form-v2/data/makeRequest';
import { useQuery } from '@tanstack/react-query';
import { useGetUserQuery } from 'features/data/useGetUserQuery';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { UserSearchInput } from './UserSearchInput/UserSearchInput';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Skeleton } from 'features/entry-form-v2/component/Skeleton';

interface Props {
	newUsersState: {
		newUsers: string[];
		setNewUsers: SetState<string[]>;
	};
	areAllInputsEmails: boolean;
	onSubmit: () => void;
}

export function InviteCollaborators({
	newUsersState: { newUsers, setNewUsers },
	areAllInputsEmails,
	onSubmit
}: Props) {
	const { translate } = useTranslation();
	const getUserQuery = useGetUserQuery();

	const { inviteCollaboratorSearch } = useFlags();

	const outsideOrganization = {
		label: translate(
			({ collaborators }) => collaborators.add.selectOrganization.option.outside
		),
		value: 'outside'
	};

	const [selectedOrganization, setSelectedOrganization] = useState<{
		label: string;
		value: string;
	}>(outsideOrganization);

	const organizationsQuery = useQuery({
		enabled: inviteCollaboratorSearch,
		queryKey: ['getOrganizationTree'],
		queryFn: async () =>
			await makeRequest<{
				organizations: { organizationName: string; organizationId: string }[];
			}>({ method: 'getOrganizationTree', service: 'user' })
	});

	const organizations =
		organizationsQuery.data?.organizations.map(item => ({
			label: item.organizationName,
			value: item.organizationId
		})) ?? [];

	const organizationOptions = [outsideOrganization, ...organizations];

	const excludeEmailAddresses = getUserQuery.data?.user.emailAddress
		? [getUserQuery.data?.user.emailAddress, ...newUsers]
		: newUsers;

	return (
		<>
			<Title>{translate(({ collaborators }) => collaborators.add.title.addStep)}</Title>

			{inviteCollaboratorSearch && !organizationsQuery.isError && (
				<div className="mb-12">
					{organizationsQuery.isLoading ? (
						<div className="mb-4 max-w-fit">
							<div className="text-sm font-medium mb-1">
								{translate(
									({ collaborators }) =>
										collaborators.add.selectOrganization.label
								)}
							</div>
							<Skeleton className="h-10 w-64 rounded" />
						</div>
					) : (
						<CreatableSelect
							className="mb-4 max-w-fit z-[1001]"
							label={translate(
								({ collaborators }) => collaborators.add.selectOrganization.label
							)}
							value={selectedOrganization}
							items={organizationOptions}
							canClear={false}
							onValueSelected={(_a, _b, item) => {
								setNewUsers([]);
								return setSelectedOrganization({
									label: item?.label ?? '',
									value: item?.value ?? ''
								});
							}}
						/>
					)}
				</div>
			)}

			{selectedOrganization?.value === 'outside' && (
				<TagInput
					label={translate(({ collaborators }) => collaborators.add.input.label.project)}
					items={newUsers}
					placeholder={translate(
						({ collaborators }) => collaborators.add.input.placeholder
					)}
					error={
						!areAllInputsEmails &&
						translate(({ collaborators }) => collaborators.errors.emailInputError)
					}
					delimiters={[keyMaps.comma]}
					onChange={setNewUsers}
					onSubmit={onSubmit}
					toLowerCase
					autoFocus
				/>
			)}

			{selectedOrganization && selectedOrganization.value !== 'outside' && (
				<UserSearchInput
					key={selectedOrganization.value}
					label={translate(({ collaborators }) => collaborators.add.input.label.project)}
					placeholder={translate(
						({ collaborators }) => collaborators.add.input.placeholderSearch
					)}
					users={newUsers}
					setUsers={setNewUsers}
					organizationId={selectedOrganization.value}
					excludeEmailAddresses={excludeEmailAddresses}
					error={
						!areAllInputsEmails &&
						translate(({ collaborators }) => collaborators.errors.emailInputError)
					}
				/>
			)}

			{/* disable this until imports are ready */}
			{/* {translate(({ collaborators }) => collaborators.add.import)} */}
			<Spacer size={s => s.l} />
			<InfoMessage message={translate(({ collaborators }) => collaborators.add.notice)} />
		</>
	);
}
