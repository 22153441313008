import { useAccount, useCollaborators, useEntry, usePermissions } from 'hooks/store';

export function useEntryDeleteAccess(): boolean {
	const [{ data: entry }] = useEntry();

	const { hasDatasetDeleteAllAccess, hasDatasetDeleteOwnAccess, hasDatasetDeleteOrgAccess } =
		usePermissions();

	const [
		{
			data: { username: currentUserName }
		}
	] = useAccount();
	const [
		{
			data: { organizationsMap }
		}
	] = useCollaborators({ lazy: true });

	const ownedByUser = entry?.ownedbyuser ?? '';
	const organizationId = entry?.userProjectOrgId ?? '';
	const organizationUsers = organizationsMap[organizationId]?.collaborators ?? [];

	const deleteAccess =
		hasDatasetDeleteAllAccess ||
		(hasDatasetDeleteOwnAccess && ownedByUser === currentUserName) ||
		(hasDatasetDeleteOrgAccess && organizationUsers.includes(currentUserName ?? ''));

	return deleteAccess;
}
