import type { Action } from 'types/store/types';

export interface UiStatesInput {
	focus?: boolean;
	filterBreakdown?: boolean;
}

export interface State {
	focus: boolean;
	filterBreakdown: boolean;
	activeModals: string[];
}

export enum ActionTypes {
	SET_UI_STATES = 'ui/states/SET_UI_STATES',
	ADD_MODAL = 'ui/states/ADD_MODAL',
	REMOVE_MODAL = 'ui/states/REMOVE_MODAL'
}

export type SetUiStatesAction = Action<ActionTypes.SET_UI_STATES, UiStatesInput>;

export type AddRemoveModalAction = Action<
	ActionTypes.ADD_MODAL | ActionTypes.REMOVE_MODAL,
	{ modalId: string }
>;

export type Actions = SetUiStatesAction | AddRemoveModalAction;
