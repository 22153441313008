import { Dropdown } from 'components/UI/Dropdown';
import { buildAnalysis } from 'helpers/analysis';
import {
	useTranslation,
	useVariablesData,
	useCreateAnalysis,
	useVariablesDataSelectItems
} from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';
import { useTracking } from 'app/tracking/TrackingProvider';
import { AnalysisV2 } from 'api/data/analyses';
interface Props {
	addButton: (AddButtonParams: {
		label: string;
		disabled?: boolean;
		action: () => void;
	}) => React.ReactNode;
}

export function AddAnalysis({ addButton }: Props) {
	const { translate } = useTranslation();

	const { track } = useTracking();

	const analyses = [
		AnalysisType.FrequenciesV2,
		AnalysisType.ExploreV2,
		AnalysisType.CrosstabV2,
		AnalysisType.KaplanV2,
		AnalysisType.CorrelationsV2,
		AnalysisType.CompareNumericV2,
		AnalysisType.DensityPlotV2,
		AnalysisType.PlotNumericV2,
		AnalysisType.TimeCourseV2,
		AnalysisType.ComparePairedV2,
		AnalysisType.LogisticRegressionV2
	];

	const variablesData = useVariablesData({ initial: true });

	const createAnalysis = useCreateAnalysis();

	function handleSelect(type: AnalysisType) {
		const analysis = buildAnalysis({ type, variablesData });
		if (!analysis) return;

		createAnalysis(analysis as AnalysisV2);

		track({
			eventName: 'analysis_added',
			data: {
				analysisType: type
			}
		});
	}

	const variablesDataSelectItems = useVariablesDataSelectItems(variablesData);
	const hasCategoryOrNumberOrDurationVariable =
		variablesDataSelectItems.categorySelectItems.length > 0 ||
		variablesDataSelectItems.numericSelectItems.length > 0 ||
		variablesDataSelectItems.durationSelectItems.length > 0;

	function getSelectItemLabel(type: string) {
		return translate(() => type);
	}

	const selectItems = analyses.map(type => ({
		label: getSelectItemLabel(type),
		value: type
	}));

	return (
		<Dropdown
			toggleComponent={({ ref, toggle }) => {
				return (
					<div ref={ref}>
						{addButton({
							label: translate(dict => dict.analysis.actions.add),
							disabled: !hasCategoryOrNumberOrDurationVariable,
							action: toggle
						})}
					</div>
				);
			}}
			width={25}
			offset={{ top: 4, right: 0 }}
		>
			{selectItems.map((item, i) => (
				<Dropdown.Item
					close={() => undefined}
					onClick={() => {
						handleSelect(item.value as AnalysisType);
					}}
					title={item.label}
					key={`analisys_item_${i}`}
				/>
			))}
		</Dropdown>
	);
}
