import * as yup from 'yup';
import countries from 'i18n-iso-countries';
import countriesEnLocale from 'i18n-iso-countries/langs/en.json';

import { ApiBillingInfo } from 'api/account/payments';
import { PHONE_REGEX_WITH_PLUS_PREFIX } from 'consts';
import { TranslateFunction } from 'hooks/store/ui/useTranslation';
import {
	LedidiPlanCurrency,
	SupportedLedidiPlansPrices,
	SupportedLedidiPlans
} from 'store/account/payments';
import { BillingInfo, BillingAddress, BillingPeriodUnit } from 'store/account/payments';
import {
	LicenceModelQuantities,
	SubscriptionLicenceModel,
	UserLicenceModel,
	UserLicenceModelId
} from 'store/account/subscription';
import { GenericMap } from 'types';

countries.registerLocale(countriesEnLocale);
interface ValidationSchemaProps {
	translate: TranslateFunction;
}

export function getEmailValidationSchema({ translate }: ValidationSchemaProps) {
	return yup.object({
		emailAddress: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.emailAddressError))
			.email(translate(({ accountUM }) => accountUM.validation.emailAddressErrorValid))
	});
}

export function getAddUserValidationSchema({ translate }: ValidationSchemaProps) {
	return yup.object({
		userFirstName: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.firstNameError)),
		userSirName: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.lastNameError)),
		phoneNumber: yup.lazy(value =>
			value
				? yup
						.string()
						.min(
							8,
							translate(({ accountUM }) => accountUM.validation.phoneNumberErrorValid)
						)
						.max(
							15,
							translate(({ accountUM }) => accountUM.validation.phoneNumberErrorValid)
						)
						.matches(
							PHONE_REGEX_WITH_PLUS_PREFIX,
							translate(({ accountUM }) => accountUM.validation.phoneNumberErrorValid)
						)
				: yup.string()
		),
		workplace: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.organizationError))
	});
}

export function getCreditCardValidationSchema({ translate }: ValidationSchemaProps) {
	return yup.object({
		cardValid: yup
			.boolean()
			.oneOf([true])
			.required(translate(({ accountUM }) => accountUM.validation.firstNameError)),
		firstName: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.firstNameError)),
		lastName: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.lastNameError)),
		addressLine1: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.addressError)),
		addressLine2: yup.string().trim(),
		countryCode: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.countryError)),
		billingCity: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.cityError)),
		billingState: yup
			.string()
			.trim()
			.when('countryCode', {
				is: (val: any) => val === 'US',
				then: yup
					.string()
					.label('billingState')
					.required(translate(({ accountUM }) => accountUM.validation.stateError))
			}),
		billingZip: yup
			.string()
			.required(translate(({ accountUM }) => accountUM.validation.zipError))
	});
}

interface ValidationSchemaBillingProps extends ValidationSchemaProps {
	usePaymentInfo: boolean;
	useCompanyInfo: boolean;
}

export function getBillingAddressSchema({
	translate,
	usePaymentInfo,
	useCompanyInfo
}: ValidationSchemaBillingProps) {
	const userSchema = !usePaymentInfo ? getBillingUserSchema({ translate }) : {};
	const companySchema = useCompanyInfo ? getBillingCompanySchema({ translate }) : {};
	return yup.object({ ...userSchema, ...companySchema });
}

function getBillingUserSchema({ translate }: ValidationSchemaProps) {
	return {
		firstName: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.firstNameError)),
		lastName: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.lastNameError)),
		line1: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.addressError)),
		line2: yup.string().trim(),
		country: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.countryError)),
		city: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.cityError)),
		state: yup.string().trim(),
		zip: yup.string().trim()
	};
}

function getBillingCompanySchema({ translate }: ValidationSchemaProps) {
	return {
		company: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.companyError)),
		vatNumber: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.vatError))
	};
}

export function parseApiPriceIntoString(amount: number) {
	try {
		return ((Number(amount) / 100).toFixed(2) + '').replace(/\./g, ',');
	} catch (e: any) {
		console.error('Error parsing amount ', e.message);
		return '';
	}
}

export function mapApiBillingInfoToBillingInfo(apiBillingInfo: ApiBillingInfo): BillingInfo {
	return {
		billingAddress: {
			firstName: apiBillingInfo.billingAddress
				? apiBillingInfo.billingAddress.firstName ?? ''
				: '',
			lastName: apiBillingInfo.billingAddress
				? apiBillingInfo.billingAddress.lastName ?? ''
				: '',
			country: apiBillingInfo.billingAddress
				? apiBillingInfo.billingAddress.country ?? ''
				: '',
			city: apiBillingInfo.billingAddress ? apiBillingInfo.billingAddress.city ?? '' : '',
			zip: apiBillingInfo.billingAddress ? apiBillingInfo.billingAddress.zip ?? '' : '',
			state: apiBillingInfo.billingAddress ? apiBillingInfo.billingAddress.state ?? '' : '',
			line1: apiBillingInfo.billingAddress ? apiBillingInfo.billingAddress.line1 ?? '' : '',
			line2: apiBillingInfo.billingAddress ? apiBillingInfo.billingAddress.line2 ?? '' : '',
			company: apiBillingInfo.billingAddress
				? apiBillingInfo.billingAddress.company ?? ''
				: '',
			vatNumber: apiBillingInfo.billingAddress
				? apiBillingInfo.billingAddress.vatNumber ?? ''
				: ''
		},
		primaryPaymentSourceAddress: {
			firstName: apiBillingInfo.primaryPaymentSourceAddress
				? apiBillingInfo.primaryPaymentSourceAddress.firstName ?? ''
				: '',
			lastName: apiBillingInfo.primaryPaymentSourceAddress
				? apiBillingInfo.primaryPaymentSourceAddress.lastName ?? ''
				: '',
			country: apiBillingInfo.primaryPaymentSourceAddress
				? apiBillingInfo.primaryPaymentSourceAddress.country ?? ''
				: '',
			city: apiBillingInfo.primaryPaymentSourceAddress
				? apiBillingInfo.primaryPaymentSourceAddress.city ?? ''
				: '',
			zip: apiBillingInfo.primaryPaymentSourceAddress
				? apiBillingInfo.primaryPaymentSourceAddress.zip ?? ''
				: '',
			state: apiBillingInfo.primaryPaymentSourceAddress
				? apiBillingInfo.primaryPaymentSourceAddress.state ?? ''
				: '',
			line1: apiBillingInfo.primaryPaymentSourceAddress
				? apiBillingInfo.primaryPaymentSourceAddress.line1 ?? ''
				: '',
			line2: apiBillingInfo.primaryPaymentSourceAddress
				? apiBillingInfo.primaryPaymentSourceAddress.line2 ?? ''
				: ''
		},
		billingPeriodUnit: apiBillingInfo.billingPeriodUnit,
		cardBrand: apiBillingInfo.cardBrand ?? '',
		currencyCode: apiBillingInfo.currencyCode,
		maskedNumber: apiBillingInfo.maskedNumber ?? '',
		nextBillingAt: apiBillingInfo.nextBillingAt,
		unitPrice: apiBillingInfo.unitPrice,
		selectedPaymentMethod: apiBillingInfo.selectedPaymentMethod
	};
}

export function getBillingAddressString(billingAddress: BillingAddress) {
	const { firstName, lastName, company, line1, line2, zip, city, state, country } =
		billingAddress;
	if (
		!firstName &&
		!lastName &&
		!company &&
		!line1 &&
		!line2 &&
		!zip &&
		!city &&
		!state &&
		!country
	)
		return '';

	return `${firstName} ${lastName} ${firstName || lastName ? '\n' : ''} ${
		company ? company + '\n' : ''
	} 
	${line1 ? line1 + '\n' : ''} ${line2} ${zip} ${line2 || zip ? '\n' : ''} ${city} ${state} ${
		country ? countries.getName(country, 'en') : ''
	}`;
}

export function getPriceToDisplay(
	subscriptionName: SupportedLedidiPlans,
	payMonthly: boolean,
	currency: LedidiPlanCurrency
) {
	const { pricePerUnit } =
		SupportedLedidiPlansPrices[currency][subscriptionName][
			payMonthly ? BillingPeriodUnit.Month : BillingPeriodUnit.Year
		];

	const isNorwegianCurrency = currency === LedidiPlanCurrency.NOK;

	const priceForChosen = `${pricePerUnit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	return `${!isNorwegianCurrency ? '\u20AC' : ''} ${priceForChosen} ${
		isNorwegianCurrency ? 'NOK' : ''
	}`;
}

/**
 *
 * We receive from BE as keys the ids of each Licence Model and on FE we map it
 * to show the corect LicenceModel based on each ID
 * @returns
 */
export function mapLicenceModelIdQuantitiesToLicenceModelQuantities(
	apiLicenceQuantities: LicenceModelQuantities,
	subscriptionLicencesById: GenericMap<SubscriptionLicenceModel>
): LicenceModelQuantities {
	let licenceQuantities: LicenceModelQuantities = {};
	Object.keys(apiLicenceQuantities).forEach(key => {
		if (subscriptionLicencesById[key]) {
			const licenceModel = subscriptionLicencesById[key].licenceModel;
			licenceQuantities = {
				...licenceQuantities,
				[licenceModel]: apiLicenceQuantities[key as UserLicenceModelId]
			};
		}
	});
	return licenceQuantities;
}

/**
 * For now we only use Prime Subscription licences, so we don't need to check the subscription type.
 * In the future if we add more subscriptions with different licences pricer per subscription this algorithm might change.
 * @param currency
 * @param billingPeriod
 * @param licenceModels
 */
export function calculateLicenceQuantitiesPrice(
	licenceModels: LicenceModelQuantities,
	subscriptionLicencesById: GenericMap<SubscriptionLicenceModel>
) {
	let total = 0;
	try {
		Object.keys(licenceModels).forEach(licenceId => {
			const pricePerUnit = subscriptionLicencesById[licenceId]
				? subscriptionLicencesById[licenceId].price
				: null;
			if (pricePerUnit === null) throw Error();
			else total = total + pricePerUnit * licenceModels[licenceId];
		});
	} catch (e) {
		return null;
	}
	return total;
}

/**
 * Used for subscription Bundle
 * @param subscriptionType
 * @param currency
 * @param billingPeriod
 * @param licenceModel
 * @returns
 */
export function getLicenceModelPrice(
	currency: LedidiPlanCurrency,
	billingPeriod: BillingPeriodUnit,
	licenceModel: UserLicenceModel
) {
	switch (licenceModel) {
		case UserLicenceModel.OneOwnedOneShared:
		case UserLicenceModel.Trial:
			return 0;

		case UserLicenceModel.Full:
			if (currency === LedidiPlanCurrency.EUR)
				return billingPeriod === BillingPeriodUnit.Month ? 70 : 70 * 12;
			if (currency === LedidiPlanCurrency.NOK)
				return billingPeriod === BillingPeriodUnit.Month ? 840 : 840 * 12;
			return null;

		case UserLicenceModel.Collaborator:
			if (currency === LedidiPlanCurrency.EUR)
				return billingPeriod === BillingPeriodUnit.Month ? 40 : 40 * 12;
			if (currency === LedidiPlanCurrency.NOK)
				return billingPeriod === BillingPeriodUnit.Month ? 480 : 480 * 12;
			return null;
		default:
			return null;
	}
}

export function getLicenceModelIdFromSubscriptionQuantities(
	lincenceModel: UserLicenceModel,
	licenceQuantities: LicenceModelQuantities,
	metadata: GenericMap<SubscriptionLicenceModel>
) {
	// first check what ids have this licence model
	const ids: string[] = [];
	Object.keys(metadata).forEach(key => {
		if (metadata[key].licenceModel === lincenceModel) ids.push(key);
	});
	// then we look for the specific id in the licenceQuantities
	const id = Object.keys(licenceQuantities).find(id => ids.includes(id));
	return id;
}
