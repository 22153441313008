import { Menu } from '@headlessui/react';
import { Icon } from 'components/UI/Icons';
import { Svgs, Colors } from 'environment';
import {
	isSeriesEntryGroupColumn,
	SeriesEntryGroupColumn,
	SeriesEntryTableColumn,
	SeriesEntryTableVariable,
	SeriesEntryTableVariables,
	SeriesEntryVariableColumn
} from '../smart-components/series-entry-body/useSeriesTablesDataQuery';
import { forwardRef } from 'react';
import { VariableGroup } from '../types';
import clsx from 'clsx';

export const ColumnFilterDropdown = ({
	columns,
	variables,
	filteredVariables,
	onFilteredVariablesChange
}: {
	columns: SeriesEntryTableColumn[];
	variables: SeriesEntryTableVariables;
	filteredVariables: Set<string>;
	onFilteredVariablesChange: (variables: Set<string>) => void;
}) => {
	const groups = columns.filter(isSeriesEntryGroupColumn).map(column => column.group);

	const variablesWithoutGroups = Object.values(variables).filter(
		variable =>
			!groups.find(group => group.variablesBelongingToGroup.includes(variable.variableName))
	);

	return (
		<Menu>
			<div className="relative" onClick={e => e.stopPropagation()}>
				<Menu.Button className="flex gap-1 items-center justify-center">
					<Icon
						svg={Svgs.Settings}
						colors={{
							color: Colors.primary.normal
						}}
						propagate
					/>

					<Icon
						svg={Svgs.ArrowDown}
						colors={{
							color: Colors.primary.normal
						}}
						size={s => s.m}
						propagate
					/>
				</Menu.Button>

				<Menu.Items className="absolute overflow-hidden top-10 right-0 w-[240px] bg-white rounded-lg z-50 shadow-normal flex flex-col items-stretch p-4">
					<h2 className="text-base font-semibold">Filter columns</h2>

					{groups.length > 0 && (
						<div className="mt-6 flex flex-col gap-6">
							{groups.map(group => {
								const allVariablesSelected = group.variablesBelongingToGroup.every(
									variableName => filteredVariables.has(variableName)
								);

								return (
									<div key={group.groupName}>
										<Menu.Item>
											<ColumnFilterGroup
												className="mb-3"
												group={group}
												id={group.groupName}
												onChange={() => {
													if (allVariablesSelected) {
														// remove all variables from the set
														const updatedSet = new Set(
															filteredVariables
														);
														group.variablesBelongingToGroup.forEach(
															variableName => {
																updatedSet.delete(variableName);
															}
														);
														onFilteredVariablesChange(updatedSet);
														return;
													}

													// add all variables to the set
													const updatedSet = new Set(filteredVariables);
													group.variablesBelongingToGroup.forEach(
														variableName => {
															updatedSet.add(variableName);
														}
													);
													onFilteredVariablesChange(updatedSet);
												}}
												checked={allVariablesSelected}
											/>
										</Menu.Item>

										<VariablesContainer>
											{group.variablesBelongingToGroup.map(variableName => {
												const variable = variables[variableName];

												if (!variable) {
													console.error(new Error('Variable not found'), {
														variableName
													});
													return null;
												}

												const id = 'checkbox_' + variable.variableName;
												const variableIsSelected =
													filteredVariables.has(variableName);

												return (
													<Menu.Item key={variableName}>
														<ColumnFilterVariable
															variable={variable}
															id={id}
															onChange={() => {
																if (variableIsSelected) {
																	const updatedSet = new Set(
																		filteredVariables
																	);
																	updatedSet.delete(variableName);
																	onFilteredVariablesChange(
																		updatedSet
																	);
																} else {
																	onFilteredVariablesChange(
																		new Set([
																			...filteredVariables,
																			variableName
																		])
																	);
																}
															}}
															checked={variableIsSelected}
														/>
													</Menu.Item>
												);
											})}
										</VariablesContainer>
									</div>
								);
							})}
						</div>
					)}

					<VariablesWithoutGroups
						groups={groups}
						variablesWithoutGroups={variablesWithoutGroups}
						filteredVariables={filteredVariables}
						onFilteredVariablesChange={onFilteredVariablesChange}
					/>

					<button
						onClick={() => onFilteredVariablesChange(new Set())}
						className="text-start text-base font-bold text-primary-500 mt-6"
					>
						Clear filters
					</button>
				</Menu.Items>
			</div>
		</Menu>
	);
};

type ColumnFilterGroupProps = {
	group: SeriesEntryGroupColumn['group'];
	id: string;
	onChange: () => void;
	checked: boolean;
	className?: string;
};
const ColumnFilterGroup = forwardRef<HTMLDivElement, ColumnFilterGroupProps>(
	({ group, id, onChange, checked, className }: ColumnFilterGroupProps, ref) => {
		return (
			<div className={clsx('flex gap-2 items-center', className)} ref={ref}>
				<input id={id} type="checkbox" checked={checked} onChange={onChange} />
				<label htmlFor={id} className="text-base font-semibold">
					{group.groupLabel}
				</label>
			</div>
		);
	}
);

type ColumnFilterVariableProps = {
	variable: SeriesEntryVariableColumn['variable'];
	id: string;
	onChange: () => void;
	checked: boolean;
};

const ColumnFilterVariable = forwardRef<HTMLDivElement, ColumnFilterVariableProps>(
	({ variable: variable, id, onChange, checked }: ColumnFilterVariableProps, ref) => {
		return (
			<div className="flex gap-2 items-center" ref={ref}>
				<input id={id} type="checkbox" checked={checked} onChange={onChange} />
				<label className="text-base" htmlFor={id}>
					{variable.variableLabel}
				</label>
			</div>
		);
	}
);

const VariablesWithoutGroups = ({
	groups,
	variablesWithoutGroups,
	filteredVariables,
	onFilteredVariablesChange
}: {
	groups: VariableGroup[];
	variablesWithoutGroups: SeriesEntryTableVariable[];
	filteredVariables: Set<string>;
	onFilteredVariablesChange: (variables: Set<string>) => void;
}) => {
	return (
		<div className="mt-6">
			{groups.length > 0 && <p className="text-base font-semibold mb-3">Without group</p>}

			{variablesWithoutGroups.length > 0 && (
				<VariablesContainer>
					{variablesWithoutGroups.map(variable => {
						const variableIsSelected = filteredVariables.has(variable.variableName);

						return (
							<ColumnFilterVariable
								key={variable.variableName}
								variable={variable}
								id={variable.variableName}
								onChange={() => {
									if (variableIsSelected) {
										const updatedSet = new Set(filteredVariables);
										updatedSet.delete(variable.variableName);

										onFilteredVariablesChange(updatedSet);
									} else {
										onFilteredVariablesChange(
											new Set([...filteredVariables, variable.variableName])
										);
									}
								}}
								checked={variableIsSelected}
							/>
						);
					})}
				</VariablesContainer>
			)}
		</div>
	);
};

const VariablesContainer = ({ children }: { children: React.ReactNode }) => {
	return <div className="ml-4 flex flex-col gap-3">{children}</div>;
};
