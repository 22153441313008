export const ConsolidateActionbar = () => {
	return (
		<div>
			<h3 className="text-base font-bold">Tidied up navigation bar</h3>

			<p className="text-base mt-6">
				Delete entry, Entry Details and Entry History moved to the “more” menu
			</p>

			<img
				className="mt-6"
				src="/img/entry-form-1-5-change-log/consolidate_actionbar.png"
				alt="Showing how elements are moved from various places in the page into the more options menu"
			/>
		</div>
	);
};
