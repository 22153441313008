import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { Input } from '../component/Input';
import { TextArea } from '../component/TextArea';
import { useGetStatusesQuery, NONE_STATUS_NAME } from '../data/statuses/useGetStatusesQuery';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect/CreatableSelect';
import { InputError } from '../component/InputError';
import { useGetProjectQuery } from '../data/useGetProjectQuery';
import { useMemo, useState } from 'react';

interface Props {
	selectedStatusName?: string;
	onStatusNameChanged: (statusName?: string) => void;
	comment?: string;
	onCommentChanged: (comment: string) => void;
	error?: string;
	dueDate?: Date;
	statusColor?: string;
	statusCommentInitiallyExpanded?: boolean;
}

export const SelectEntryStatus = ({
	selectedStatusName,
	dueDate,
	onStatusNameChanged,
	comment,
	onCommentChanged,
	statusColor,
	error,
	statusCommentInitiallyExpanded = false
}: Props) => {
	const [statusCommentExpanded, setStatusCommentExpanded] = useState(
		statusCommentInitiallyExpanded
	);

	const statusesWithAccessQuery = useStatusesWithAccessQuery();

	const statusesWithSetStatusAccess = statusesWithAccessQuery.data?.withSetStatusAccess;

	if (!statusesWithSetStatusAccess || statusesWithSetStatusAccess.length === 0) {
		return null;
	}

	if (statusesWithAccessQuery.error) {
		return <p className="text-sm text-red-500">Error loading statuses</p>;
	}

	const options = statusesWithSetStatusAccess
		.filter(status => status.statusName !== NONE_STATUS_NAME)
		.map(status => ({
			label: status.statusLabel,
			value: status.statusName
		}));

	const dueDateTime = dueDate ? DateTime.fromJSDate(dueDate) : null;

	return (
		<div className="col-span-2 flex flex-col">
			<div className="flex-1 flex gap-2 items-end">
				<div className="flex gap-2 items-end flex-1">
					<div
						className={clsx('rounded-md aspect-square h-[38px] mb-[1px]')}
						style={{
							backgroundColor: statusColor,
							borderColor: !statusColor ? 'rgba(0, 0, 0, 0.1)' : undefined,
							borderWidth: !statusColor ? 1 : 0
						}}
					/>

					<CreatableSelect
						className="flex-1"
						items={options}
						label="Status"
						onValueSelected={option => {
							const status = statusesWithSetStatusAccess.find(
								s => s.statusName === option
							);

							onStatusNameChanged(status?.statusName);
						}}
						value={options.find(item => item.value === selectedStatusName)}
						placeholder="No status selected"
					/>
				</div>

				<div className="flex-1 flex gap-1 items-end">
					<Input
						label="Due date"
						id="due-date"
						disabled
						value={dueDateTime?.toLocaleString(DateTime.DATE_MED) || ''}
					/>
					<Input
						label="Due time"
						id="due-time"
						disabled
						value={dueDateTime?.toLocaleString(DateTime.TIME_SIMPLE) || ''}
					/>
				</div>
			</div>
			{error && <InputError error={error} />}

			{!statusCommentExpanded && (
				<button
					type="button"
					className="mt-4 self-start text-sm font-semibold"
					onClick={() => setStatusCommentExpanded(true)}
				>
					Add status comment
				</button>
			)}

			{statusCommentExpanded && (
				<div className="mt-4">
					<TextArea
						value={comment}
						onChange={e => onCommentChanged(e.target.value)}
						id="comment"
						label="Status comment (optional)"
					/>
				</div>
			)}
		</div>
	);
};

export const useStatusesWithAccessQuery = () => {
	const params = useParams();
	const projectId = params.projectId as string;

	const getStatusesQuery = useGetStatusesQuery({ projectId });
	const getProjectQuery = useGetProjectQuery({ projectId });

	const statusesWithAccess = useMemo(() => {
		if (!getStatusesQuery.data || !getProjectQuery.data) {
			return undefined;
		}

		const withSetStatusAccess = getStatusesQuery.data.statuses.filter(status => {
			const statusTypeAccess =
				getProjectQuery.data.project.userAccess.statusTypeAccesses?.find(
					access => access.statusTypeVariableName === status.statusName
				);

			return statusTypeAccess?.setStatus;
		});

		const withViewDataAccess = getStatusesQuery.data.statuses.filter(status => {
			const statusTypeAccess =
				getProjectQuery.data.project.userAccess.statusTypeAccesses?.find(
					access => access.statusTypeVariableName === status.statusName
				);

			return statusTypeAccess?.viewData;
		});

		return {
			withViewDataAccess,
			withSetStatusAccess
		};
	}, [getStatusesQuery.data, getProjectQuery.data]);

	return {
		data: statusesWithAccess,
		isLoading: getStatusesQuery.isLoading || getProjectQuery.isLoading,
		error: getStatusesQuery.error || getProjectQuery.error
	};
};
