import { selectLanguage } from 'store/ui/i18n';
import { useSelector } from 'hooks/utils';
import { LanguageType } from 'types';

interface Props {
	emails: string[];
}

export const DomainConstraintError = ({ emails }: Props) => {
	const language = getLanguage(useSelector(state => selectLanguage(state.ui.i18n)));

	const translation = DICTIONARY[language];

	return (
		<div
			data-testid="notAllowedDueToDomainConstraintViolationError"
			className="rounded-md shadow-normal p-6 flex flex-col bg-error-500/25"
		>
			<p className="text-base font-semibold">
				{emails.length === 1
					? translation.THE_FOLLOWING_EMAIL_ADDRESS_IS_NOT_CONNECTED_TO_YOUR_ORGANIZATION
					: translation.THE_FOLLOWING_EMAIL_ADDRESSES_ARE_NOT_CONNECTED_TO_YOUR_ORGANIZATION}
			</p>

			<ul className="list-disc list-inside text-base">
				{emails.map(email => (
					<li key={email}>{email}</li>
				))}
			</ul>

			<div className="mt-6">
				<p className="text-base font-semibold">
					{translation.FOR_COLLABORATORS_WITHIN_HSO}
				</p>
				<p className="text-base mt-1">{translation.THE_PERSON_YOU_INVITE}</p>
			</div>

			<div className="text-base flex flex-col mt-6">
				<p className="text-base font-semibold">{translation.FOR_EXTERNAL_COLLABORATORS}</p>
				<div className="flex flex-wrap mt-1">
					<p>{translation.IF_YOU_WANT_TO_ADD_THESE_USERS_TO_YOUR_PROJECT}</p>
					<a
						href="mailto:oushfpbregisterstott@ous-hf.no"
						className="text-primary-700 mx-1"
					>
						oushfpbregisterstott@ous-hf.no
					</a>
					<p>{translation.AND_PROVIDE_THE_FOLLOWING_INFORMATION}</p>
				</div>

				<ul className="list-disc pl-[16px]">
					<li>{translation.EMAIL_ADDRESS_OF_COLLABORATOR}</li>
					<li>{translation.PROJECT_NAME_IN_LEDIDI}</li>
					<li>{translation.CONTACT_THE_INSTITUTION}</li>
				</ul>
			</div>
		</div>
	);
};

const getLanguage = (language: LanguageType) => {
	if (language === 'EN') {
		return 'EN';
	}

	return 'NB';
};

const DICTIONARY = {
	EN: {
		THE_FOLLOWING_EMAIL_ADDRESS_IS_NOT_CONNECTED_TO_YOUR_ORGANIZATION:
			'The following email address is not connected to your organization',
		THE_FOLLOWING_EMAIL_ADDRESSES_ARE_NOT_CONNECTED_TO_YOUR_ORGANIZATION:
			'The following email addresses are not connected to your organization',

		FOR_COLLABORATORS_WITHIN_HSO: 'For collaborators within HSØ',
		THE_PERSON_YOU_INVITE:
			'The person(s) you invite to your project must create an account via BAT or SIKT (Ahus). They must have logged in before you can add them to the project.',

		FOR_EXTERNAL_COLLABORATORS: 'For external collaborators',
		IF_YOU_WANT_TO_ADD_THESE_USERS_TO_YOUR_PROJECT:
			'If you want to add these users to your project, contact',
		AND_PROVIDE_THE_FOLLOWING_INFORMATION: 'and provide the following information:',

		EMAIL_ADDRESS_OF_COLLABORATOR: 'Email address of the collaborator(s)',
		PROJECT_NAME_IN_LEDIDI: 'Project name in Ledidi',
		CONTACT_THE_INSTITUTION:
			"Contact the institution/company/enterprise where the collaborator is employed and request an email confirmation to confirm that the collaborator is an employee at the stated institution. The email must be sent from the company's email address, from a person other than the person whose identity is to be verified",

		IF_THE_USER_YOU_WANT_TO_ADD:
			'If the user(s) you want to add as a collaborator do belong to the organisation HSØ, the user must create an account in BAT (SIKT) and log into Ledidi to activate the account.'
	},

	NB: {
		THE_FOLLOWING_EMAIL_ADDRESS_IS_NOT_CONNECTED_TO_YOUR_ORGANIZATION:
			'Følgende e-postadresse er ikke tilknyttet din organisasjon',
		THE_FOLLOWING_EMAIL_ADDRESSES_ARE_NOT_CONNECTED_TO_YOUR_ORGANIZATION:
			'Følgende e-postadresser er ikke tilknyttet din organisasjon',

		FOR_COLLABORATORS_WITHIN_HSO: 'For samarbeidspartnere innenfor HSØ',
		THE_PERSON_YOU_INVITE:
			'Personen du inviterer til ditt prosjekt må opprette en konto via BAT eller SIKT (Ahus). De må ha logget inn før du kan legge dem til i prosjektet.',

		FOR_EXTERNAL_COLLABORATORS: 'For eksterne samarbeidspartnere',
		IF_YOU_WANT_TO_ADD_THESE_USERS_TO_YOUR_PROJECT:
			'Hvis du ønsker å legge til disse brukerne i ditt prosjekt, kontakt',
		AND_PROVIDE_THE_FOLLOWING_INFORMATION: 'og oppgi følgende informasjon:',

		EMAIL_ADDRESS_OF_COLLABORATOR: 'E-postadresse til samarbeidspartner(ene)',
		PROJECT_NAME_IN_LEDIDI: 'Prosjektnavn i Ledidi',
		CONTACT_THE_INSTITUTION:
			'Kontakt institusjonen/bedriften/bedriftsselskapet der samarbeidspartneren er ansatt og be om en e-postbekreftelse for å bekrefte at samarbeidspartneren er ansatt i den påståtte institusjonen. E-posten må sendes fra en e-post med bedriftens domene, fra en person som ikke er personen som skal identifiseres',

		IF_THE_USER_YOU_WANT_TO_ADD:
			'Hvis brukerne du ønsker å legge til som samarbeidspartner tilhører HSØ, må brukerne opprette en konto i BAT (SIKT) og logge seg inn i Ledidi for å aktivere kontoen.'
	}
} as const;
