import { Fragment, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Collaborator, Organization } from 'store/data/collaborators';
import { Typography } from 'components/UI/Typography';
import { Spacer } from 'components/UI/Spacer';
import { InfoMessage } from 'components/UI/InfoMessage';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useProjectId,
	useAccount,
	useEntriesById,
	useCollaborators,
	useOrganizationsByCollaborator,
	usePermissions
} from 'hooks/store';
import { NumberMap } from 'types/index';
import { BulletList } from 'components/UI/BulletList/BulletList';
import { RadioGroupUncontrolled } from 'components/UI/Interactables/Uncontrolled';
import { Option } from 'components/UI/Interactables/Uncontrolled/RadioGroup/RadioGroupUncontrolled';
import { TransferOption } from '../../TransferEntriesOwnershipModal';
import { InfoWrapper, RadioWrapper } from './styles';
import { Flex } from 'components/UI/Flex';
import { useEffectOnce } from 'hooks/utils';
import { Entries } from 'store/data/entries';
import { DropdownUsers } from 'components/UI/DropdownUsers';

interface Props {
	entryIds: string[];
	selectedEntriesCount: number;
	transferOption: TransferOption;
	setTransferOption: (option: TransferOption) => void;
	organizationsData: {
		organizationId: string | null;
		organizations: Organization[];
		onSelect: (organizationId: string | null) => void;
		onClear: () => void;
	};
	collaboratorsData: {
		collaboratorId: string | null;
		collaborators: Collaborator[];
		onSelect: (collaboratorId: string | null) => void;
	};
}

export function SelectStep({
	entryIds,
	selectedEntriesCount,
	organizationsData,
	collaboratorsData,
	transferOption,
	setTransferOption
}: Props) {
	const { translate } = useTranslation();
	const { routes } = useNavigation();

	const entries = useEntriesById(entryIds);

	const [projectId] = useProjectId();

	const { hasOwnershipAllAccess } = usePermissions();

	const [
		{
			data: { username }
		}
	] = useAccount();

	const {
		organizationId,
		organizations,
		onSelect: onSelectOrganization,
		onClear: onClearOrganization
	} = organizationsData;

	const { collaboratorId, collaborators, onSelect: onSelectCollaborator } = collaboratorsData;

	// clear previous selections on mount
	useEffectOnce(() => {
		onSelectCollaborator(null);
		onSelectOrganization(null);
		setTransferOption(TransferOption.Group);
	});

	const userOrganizations = useOrganizationsByCollaborator(collaboratorId ?? undefined);

	const onSetTransferOption = useCallback((option: TransferOption) => {
		switch (option) {
			case TransferOption.Group: {
				setTransferOption(option);
				onSelectCollaborator(null);
				break;
			}
			case TransferOption.GroupAndOwnership: {
				setTransferOption(option);
				return;
			}
			case TransferOption.Ownership: {
				setTransferOption(option);
				onSelectOrganization(null);
				break;
			}
		}
	}, []);

	function getInfoMessage() {
		const inviteCollaboratorsRoute = routes.projects.collaborators.invite(projectId ?? '');

		const splitInviteCollaboratorsMessage = translate(
			({ dataset }) => dataset.entries.transferOwnership.modal.fields.infoMessage.message
		).split(`\${${'component'}}`);

		return (
			<Fragment>
				{splitInviteCollaboratorsMessage[0]}
				<Link to={inviteCollaboratorsRoute}>
					{translate(
						({ dataset }) =>
							dataset.entries.transferOwnership.modal.fields.infoMessage.link
					)}
				</Link>
				{splitInviteCollaboratorsMessage[1]}
			</Fragment>
		);
	}

	const organizationsSelectItems = useMemo(() => {
		if (hasOwnershipAllAccess) {
			return organizations.map(org => ({
				label: org.name,
				value: org.id
			}));
		}

		const orgs =
			transferOption === TransferOption.GroupAndOwnership ? userOrganizations : organizations;
		const selectedCollaborator =
			transferOption === TransferOption.GroupAndOwnership ? collaboratorId : username;
		return orgs.flatMap(organization => {
			let omit = true;

			for (const currentCollaboratorId of organization.collaborators) {
				const collaborator = collaborators.find(c => c.userId === currentCollaboratorId);

				if (collaborator) {
					const match =
						collaborator.userId === selectedCollaborator && !collaborator.pending;

					if (match) {
						omit = false;
						break;
					}
				}
			}

			if (omit) return [];

			return {
				label: organization.name,
				value: organization.id
			};
		});
	}, [organizations, userOrganizations, collaboratorId, transferOption]);

	const filteredCollaborators = useMemo(() => {
		if (hasOwnershipAllAccess) {
			return collaborators.filter(c => {
				return c.status === 'ACTIVE';
			});
		}
		return collaborators.filter(c => {
			return c.userId !== username && c.status === 'ACTIVE';
		});
	}, [collaborators, organizationId]);

	const options: Option[] = [
		{
			value: TransferOption.Group,
			label: translate(
				dict => dict.dataset.entries.transferOwnership.modal.fields.changeGroup
			)
		},
		{
			value: TransferOption.GroupAndOwnership,
			label: translate(
				dict => dict.dataset.entries.transferOwnership.modal.fields.changeGroupAndOwnership
			)
		},
		{
			value: TransferOption.Ownership,
			label: translate(
				dict => dict.dataset.entries.transferOwnership.modal.fields.changeOwnership
			)
		}
	];

	return (
		<>
			<Typography.Paragraph fontweight={w => w.bold}>
				{translate(
					({ dataset }) => dataset.entries.transferOwnership.modal.fields.subtitle,
					false,
					{
						count: selectedEntriesCount,
						plural: selectedEntriesCount === 1 ? 'y' : 'ies'
					}
				)}
			</Typography.Paragraph>
			<Spacer size={s => s.xs} />
			<EntriesDetails entries={entries} />
			<Spacer size={s => s.m} />
			<Typography.Paragraph fontweight={f => f.bold}>
				{translate(dict => dict.dataset.entries.transferOwnership.modal.fields.options)}
			</Typography.Paragraph>
			<Spacer size={size => size.xs} />
			<RadioWrapper>
				<RadioGroupUncontrolled
					value={transferOption}
					onChange={value => onSetTransferOption(value as TransferOption)}
					name="transfer-option"
					options={options}
					isVertical={true}
				/>
			</RadioWrapper>
			<Spacer size={s => s.m} />
			{[TransferOption.Ownership, TransferOption.GroupAndOwnership].includes(
				transferOption
			) ? (
				<DropdownUsers
					users={filteredCollaborators}
					selectedUserId={collaboratorId}
					onSelect={collaboratorId => onSelectCollaborator(collaboratorId)}
					label={translate(
						({ dataset }) => dataset.entries.transferOwnership.modal.fields.newOwner
					)}
					searchPlaceholder={translate(
						({ dataset }) => dataset.entries.transferOwnership.modal.fields.collaborator
					)}
				/>
			) : (
				<></>
			)}
			<Spacer size={s => s.s} />
			{[TransferOption.Group, TransferOption.GroupAndOwnership].includes(transferOption) ? (
				<CreatableSelect
					disabled={
						transferOption === TransferOption.GroupAndOwnership && !collaboratorId
					}
					value={organizationsSelectItems.find(item => item.value === organizationId)}
					label={translate(
						({ dataset }) => dataset.entries.transferOwnership.modal.fields.newGroup
					)}
					items={organizationsSelectItems}
					onValueSelected={value => {
						if (!value) return onClearOrganization();

						onSelectOrganization(value);
					}}
				/>
			) : (
				<></>
			)}
			<Spacer size={s => s.s} />
			<InfoWrapper>
				<InfoMessage
					message={<Typography.Caption multiline>{getInfoMessage()}</Typography.Caption>}
				/>
			</InfoWrapper>
		</>
	);
}

function EntriesDetails({ entries }: { entries: Entries }) {
	const { translate } = useTranslation();

	const [
		{
			data: { organizationsMap }
		}
	] = useCollaborators();

	const organizationToEntriesCountMap = useMemo(() => {
		const hasNoGroup = (userProjectOrgId: string | null) => {
			return Number(userProjectOrgId) === -1 || userProjectOrgId === null;
		};
		return entries.reduce(
			(map, entry) => ({
				...map,
				[hasNoGroup(entry.userProjectOrgId) ? 'noGroup' : entry.userProjectOrgId]:
					hasNoGroup(entry.userProjectOrgId)
						? map['noGroup']
							? ++map['noGroup']
							: 1
						: map[entry.userProjectOrgId]
						? ++map[entry.userProjectOrgId]
						: 1
			}),
			{} as NumberMap
		);
	}, [entries]);

	const bulletPoints = useMemo(() => {
		return Object.entries(organizationToEntriesCountMap).map(([org, count], index) => (
			<Flex key={`org-bullet-point-${index}`}>
				<Typography.Paragraph>
					{translate(
						({ dataset }) =>
							dataset.entries.transferOwnership.modal.fields.entriesCount,
						false,
						{
							count: count,
							plural: count === 1 ? 'y' : 'ies'
						}
					)}
				</Typography.Paragraph>
				{organizationsMap[org]?.name ? (
					<>
						<Typography.Paragraph>&nbsp;in&nbsp;</Typography.Paragraph>
						<Typography.Paragraph fontweight={f => f.bold}>
							{organizationsMap[org].name}
						</Typography.Paragraph>
					</>
				) : (
					<Typography.Paragraph>
						&nbsp;
						{translate(dict => dict.dataset.entries.transferOwnership.modal.noGroup)}
					</Typography.Paragraph>
				)}
			</Flex>
		));
	}, [organizationToEntriesCountMap]);

	return <BulletList elements={bulletPoints} />;
}
