import type { Action } from 'types/store/types';
import { SubscriptionName } from '../subscription/types';
import { CreditCardBrands } from 'types/index';
import type {
	ApiPaymentIntent,
	CreateCustomerSubscription,
	CustomerSubscription,
	NewChargebeeCustomer
} from 'api/account/payments';

export enum ActionTypes {
	// PAYMENT METHODS
	GET_SUBSCRIPTION_PAYMENT_METHODS = 'data/account/payments/GET_SUBSCRIPTION_PAYMENT_METHODS',
	MAKE_PAYMENT_SOURCE_PRIMARY = 'data/account/payments/MAKE_PAYMENT_SOURCE_PRIMARY',
	ADD_SUBSCRIPTION_PAYMENT_METHOD = 'data/account/payments/ADD_SUBSCRIPTION_PAYMENT_METHOD',
	REMOVE_SUBSCRIPTION_PAYMENT_METHOD = 'data/account/payments/REMOVE_SUBSCRIPTION_PAYMENT_METHOD',
	SET_SELECTED_PAYMENT_METHOD = 'data/account/payments/SET_SELECTED_PAYMENT_METHOD',
	GET_SUBSCRIPTION_PAYMENTS = 'data/account/payments/GET_SUBSCRIPTION_PAYMENTS',
	DOWNLOAD_INVOICE = 'data/account/payments/DOWNLOAD_INVOICE',

	// BILLING
	GET_USER_BILLING_INFO = 'data/account/payments/GET_USER_BILLING_INFO',
	UPDATE_USER_BILLING_ADDRESS = 'data/account/payments/UPDATE_USER_BILLING_ADDRESS',

	// CHARGEBEE - SHOULD BE PART OF PAYMENT?
	SET_CB_INSTANTIATED = 'data/account/payments/SET_CB_INSTANTIATED',
	CREATE_SUBSCRIPTION_PAYMENT_INTENT = 'data/account/payments/CREATE_SUBSCRIPTION_PAYMENT_INTENT',
	CREATE_CUSTOMER_SUBSCRIPTION = 'data/account/payments/CREATE_CUSTOMER_SUBSCRIPTION',
	UPDATE_CUSTOMER_SUBSCRIPTION = 'data/account/payments/UPDATE_CUSTOMER_SUBSCRIPTION',
	GET_VAT_STATUS = 'data/account/payments/GET_VAT_STATUS',
	DOWNGRADE_SUBSCRIPTION_TO_FREE = 'data/account/payments/DOWNGRADE_SUBSCRIPTION_TO_FREE',
	CREATE_CHARGEBEE_CUSTOMER = 'data/account/payments/CREATE_CHARGEBEE_CUSTOMER'
}

export interface WindowDOMwithChargebee extends Window {
	Chargebee?: {
		registerAgain: () => void;
		getInstance: () => ChargebeeInstance;
		cardComponent: () => ChargebeeCardComponent;
		init: (args: any) => ChargebeeInstance;
		createComponent: (args: any) => ChargebeeCardComponent;
	};
}
export interface ChargebeeInstance extends Record<string, any> {
	setPortalCallbacks: (args: any) => void;
	setCheckoutCallbacks: (args: any) => void;
	// Payment card methods
	load: (args: any) => void;
	createComponent: (args: any) => ChargebeeCardComponent;
	authorizeWith3ds: (args: any, args2: any) => Promise<any>;
}

export interface ChargebeeCardComponent extends HTMLIFrameElement {
	authorizeWith3ds: (paymentIntent: any, additionalDetails: any) => Promise<any>;
}

export interface State {
	selectedPaymentMethod: PaymentMethod | null;
	subscriptionPaymentMethods: SubscriptionPaymentMethods;
	billingInfo: BillingInfoType;
	subscriptionPayments: SubscriptionPayments;
	cbInstantiated: boolean;
}

export interface BillingInfo {
	billingAddress: BillingAddress;
	primaryPaymentSourceAddress: PaymentAddress;
	currencyCode: LedidiPlanCurrency;
	unitPrice: number;
	billingPeriodUnit: BillingPeriodUnit;
	maskedNumber: string;
	cardBrand: string;
	nextBillingAt: string;
	selectedPaymentMethod: string;
}

export interface BillingAddress extends PaymentAddress {
	company: string;
	vatNumber: string;
}

export interface PaymentAddress {
	firstName: string;
	lastName: string;
	city: string;
	country: string;
	line1: string;
	line2: string;
	state: string;
	zip: string;
}

export type BillingInfoType = BillingInfo | null;

export enum BillingPeriodUnit {
	Month = 'MONTH',
	Year = 'YEAR'
}

export const BillingPeriodUnitLabel = {
	[BillingPeriodUnit.Month]: 'accountUM.billing.periodUnit.month',
	[BillingPeriodUnit.Year]: 'accountUM.billing.periodUnit.year'
};

export enum PaymentStatus {
	Paid = 'PAID',
	NotPaid = 'NOT_PAID',
	Posted = 'POSTED',
	PaymentDue = 'PAYMENT_DUE',
	Voided = 'VOIDED',
	Pending = 'PENDING'
}

export const PaymentStatusLabel = {
	[PaymentStatus.Paid]: 'accountUM.billing.paymentStatus.paid',
	[PaymentStatus.NotPaid]: 'accountUM.billing.paymentStatus.notPaid',
	[PaymentStatus.Posted]: 'accountUM.billing.paymentStatus.posted',
	[PaymentStatus.PaymentDue]: 'accountUM.billing.paymentStatus.paymentDue',
	[PaymentStatus.Voided]: 'accountUM.billing.paymentStatus.voided',
	[PaymentStatus.Pending]: 'accountUM.billing.paymentStatus.pending'
};

export interface SubscriptionPayment {
	id: string;
	amountPaid: number;
	status: PaymentStatus;
	currencyCode: string;
	quantity: number;
	paidAt: string;
	dueDate: string;
	pricingModel: string;
}

export type SubscriptionPayments = SubscriptionPayment[] | null;

export interface CreditCardInterface {
	id: string;
	brand: CreditCardBrands;
	maskedNumber: string;
	expiryMonth: number;
	expiryYear: number;
	firstName: string;
	lastName: string;
	isPrimary: boolean;
	addressLine1: string;
	addressLine2?: string;
	countryCode: string;
	billingCity: string;
	billingState?: string;
	billingZip: number | string;
	cardValid?: boolean;
}

export interface CreditCardInterfaceWithValidation extends CreditCardInterface {
	cardValid: boolean;
}

export type SubscriptionPaymentMethods = {
	cards: CreditCardInterface[];
} | null;

export enum PaymentMethod {
	CARD = 'CARD',
	INVOICE = 'INVOICE'
}

export enum LedidiPlanCurrency {
	EUR = 'EUR',
	NOK = 'NOK'
}

// Supported plans to UPGRADE / DOWNGRADE through the app
export type SupportedLedidiPlans =
	| SubscriptionName.LedidiFreeTrial
	| SubscriptionName.LedidiCollaborator
	| SubscriptionName.LedidiCore;

export const SupportedLedidiPlansPrices = {
	[LedidiPlanCurrency.EUR]: {
		[SubscriptionName.LedidiFreeTrial]: {
			[BillingPeriodUnit.Month]: {
				priceId: 'ledidi-free-trial-EUR-Monthly',
				pricePerUnit: 0
			},
			[BillingPeriodUnit.Year]: {
				priceId: 'ledidi-free-trial-EUR-Yearly',
				pricePerUnit: 0
			}
		},
		[SubscriptionName.LedidiCollaborator]: {
			[BillingPeriodUnit.Month]: {
				priceId: 'ledidi-collaborator-EUR-Monthly',
				pricePerUnit: 20
			},
			[BillingPeriodUnit.Year]: {
				priceId: 'ledidi-collaborator-EUR-Yearly',
				pricePerUnit: 240
			}
		},
		[SubscriptionName.LedidiCore]: {
			[BillingPeriodUnit.Month]: {
				priceId: 'ledidi-core-EUR-Monthly',
				pricePerUnit: 70
			},
			[BillingPeriodUnit.Year]: {
				priceId: 'ledidi-core-EUR-Yearly',
				pricePerUnit: 840
			}
		}
	},
	[LedidiPlanCurrency.NOK]: {
		[SubscriptionName.LedidiFreeTrial]: {
			[BillingPeriodUnit.Month]: {
				priceId: 'ledidi-free-trial-NOK-Monthly',
				pricePerUnit: 0
			},
			[BillingPeriodUnit.Year]: {
				priceId: 'ledidi-free-trial-NOK-Monthly',
				pricePerUnit: 0
			}
		},
		[SubscriptionName.LedidiCollaborator]: {
			[BillingPeriodUnit.Month]: {
				priceId: 'ledidi-collaborator-NOK-Monthly',
				pricePerUnit: 240
			},
			[BillingPeriodUnit.Year]: {
				priceId: 'ledidi-collaborator-NOK-Yearly',
				pricePerUnit: 2880
			}
		},
		[SubscriptionName.LedidiCore]: {
			[BillingPeriodUnit.Month]: {
				priceId: 'ledidi-core-NOK-Monthly',
				pricePerUnit: 840
			},
			[BillingPeriodUnit.Year]: {
				priceId: 'ledidi-core-NOK-Yearly',
				pricePerUnit: 10080
			}
		}
	}
};

export type GetSubscriptionPaymentMethodsAction = Action<
	ActionTypes.GET_SUBSCRIPTION_PAYMENT_METHODS,
	{
		subscriptionPaymentMethods: SubscriptionPaymentMethods;
		selectedPaymentMethod: PaymentMethod;
	}
>;

export type AddSubscriptionPaymentMethodAction = Action<
	ActionTypes.ADD_SUBSCRIPTION_PAYMENT_METHOD,
	{
		subscriptionPaymentMethods: SubscriptionPaymentMethods;
		selectedPaymentMethod: PaymentMethod;
	}
>;

export type RemoveSubscriptionPaymentMethodAction = Action<
	ActionTypes.REMOVE_SUBSCRIPTION_PAYMENT_METHOD,
	{
		cardNumber: string;
	}
>;

export type SetSelectedPaymentMethodAction = Action<
	ActionTypes.SET_SELECTED_PAYMENT_METHOD,
	{
		selectedPaymentMethod: PaymentMethod;
	}
>;

export type MakePaymentSourcePrimaryAction = Action<
	ActionTypes.MAKE_PAYMENT_SOURCE_PRIMARY,
	{
		cardNumber: string;
	}
>;

export type GetSubscriptionBillingInfoAction = Action<
	ActionTypes.GET_USER_BILLING_INFO,
	{
		billingInfo: BillingInfoType;
	}
>;

export type UpdateUserBillingAddressAction = Action<
	ActionTypes.UPDATE_USER_BILLING_ADDRESS,
	{
		billingAddress: BillingAddress;
	}
>;

export type GetSubscriptionPaymentsAction = Action<
	ActionTypes.GET_SUBSCRIPTION_PAYMENTS,
	{
		subscriptionPayments: SubscriptionPayment[];
		selectedPaymentMethod: PaymentMethod;
	}
>;

export type CreateCustomerSubscriptionAction = Action<
	ActionTypes.CREATE_CUSTOMER_SUBSCRIPTION,
	{
		newCustomerSubscription: CreateCustomerSubscription;
	}
>;

export type UpdateCustomerSubscriptionAction = Action<
	ActionTypes.UPDATE_CUSTOMER_SUBSCRIPTION,
	{
		customerSubscription: CustomerSubscription;
	}
>;

export type CreateSubscriptionPaymentIntentAction = Action<
	ActionTypes.CREATE_SUBSCRIPTION_PAYMENT_INTENT,
	{
		paymentIntent: ApiPaymentIntent;
	}
>;

export type CreateChargebeeCustomerAction = Action<
	ActionTypes.CREATE_CHARGEBEE_CUSTOMER,
	{
		customerData: NewChargebeeCustomer;
	}
>;

export type SetCBInstantiatedAction = Action<ActionTypes.SET_CB_INSTANTIATED>;

export type DowngradeSubscriptionToFreeAction = Action<ActionTypes.DOWNGRADE_SUBSCRIPTION_TO_FREE>;

export type Actions =
	| GetSubscriptionPaymentMethodsAction
	| AddSubscriptionPaymentMethodAction
	| RemoveSubscriptionPaymentMethodAction
	| CreateSubscriptionPaymentIntentAction
	| MakePaymentSourcePrimaryAction
	| SetSelectedPaymentMethodAction
	| GetSubscriptionPaymentsAction
	| UpdateUserBillingAddressAction
	| GetSubscriptionBillingInfoAction
	| CreateChargebeeCustomerAction
	| CreateCustomerSubscriptionAction
	| UpdateCustomerSubscriptionAction
	| DowngradeSubscriptionToFreeAction
	| SetCBInstantiatedAction;
