import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputType } from 'types/index';

import { UserRoleSelector } from '../../Utils';

import { AddFormFields } from './AddSubscriptionUserModal';

import { Row, RowElement } from './AddSubscriptionUserForm.style';
import { CountrySelector } from 'components/UI/Interactables/CountrySelector';
import { Input } from 'components/UI/Inputs/Input';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Spacer } from 'components/UI/Spacer';
import { HSpacer } from 'components/UI/HSpacer';
import { formatPhoneNumber } from 'helpers/auth';
import {
	useTranslation,
	useLicenceModels,
	useSubscription,
	useSubscriptionBundleFlag
} from 'hooks/store';

export function AddSubscriptionUserForm() {
	const { translate } = useTranslation();

	const {
		register,
		setValue,
		control,
		formState: { errors }
	} = useFormContext<AddFormFields>();

	const { subscriptionBundleFlag } = useSubscriptionBundleFlag();

	const [
		{
			data: { availableLicenceOptions, allLicenceOptions }
		}
	] = useLicenceModels();

	const [
		{
			data: {
				userTypesAndRoles: { ledidiEnterprise }
			}
		}
	] = useSubscription();

	const hasLedidiEnterpriseAdminRole =
		ledidiEnterprise.isOrganizationAdmin ||
		ledidiEnterprise.isSuperAdmin ||
		ledidiEnterprise.isAdmin;

	function getLicenceOption(value: string) {
		return allLicenceOptions.find(licence => licence.value === value);
	}

	function handleValue(name: keyof AddFormFields, value: string) {
		setValue(name, value, { shouldDirty: true });
	}

	const handlePhoneNumber = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			handleValue('phoneNumber', formatPhoneNumber(e.target.value));
		},
		[]
	);

	return (
		<>
			<Spacer size={s => s.s} />
			<Row>
				<Controller
					control={control}
					name="licenceModel"
					render={({ field: { value } }) => (
						<CreatableSelect
							value={getLicenceOption(value)}
							label={translate(
								({ accountUM }) => accountUM.userDetails.licenceModelSelect
							)}
							disabled={!subscriptionBundleFlag || !availableLicenceOptions.length}
							items={availableLicenceOptions}
							onValueSelected={value => handleValue('licenceModel', value ?? '')}
							canClear={false}
							scrollIntoView
							required
						/>
					)}
				/>
			</Row>
			<Spacer size={s => s.m} />
			<Row>
				<Input
					{...register('userFirstName')}
					type={InputType.Text}
					error={errors.userFirstName?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.firstName)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					required
					dataTestId="add-user-first-name-input"
				/>
				<HSpacer mobileHeight={h => h.s} />
				<Input
					{...register('userSirName')}
					type={InputType.Text}
					error={errors.userSirName?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.lastName)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					required
					dataTestId="add-user-last-name-input"
				/>
			</Row>
			<Spacer size={s => s.s} />

			<Row>
				<RowElement data-testid="add-user-country-input">
					<Controller
						control={control}
						name="country"
						render={({ field: { value } }) => (
							<CountrySelector
								value={value}
								label={translate(
									dict => dict.accountUM.userDetails.countryOfResidence
								)}
								placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
								onSelect={country => handleValue('country', country?.ISO_2 ?? '')}
							/>
						)}
					/>
				</RowElement>
				<HSpacer mobileHeight={h => h.s} />
				<RowElement>
					<Input
						{...register('phoneNumber')}
						type={InputType.Text}
						error={errors.phoneNumber?.message ?? ''}
						label={translate(dict => dict.accountUM.userDetails.phoneNumber)}
						placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
						onChange={handlePhoneNumber}
						dataTestId="add-user-phone-number-input"
					/>
				</RowElement>
			</Row>

			<Spacer size={s => s.m} />
			<Row>
				<Input
					{...register('workplace')}
					type={InputType.Text}
					error={errors.workplace?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.workplace)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					required
				/>
			</Row>
			<Spacer size={s => s.m} />
			<Row>
				<Input
					{...register('position')}
					type={InputType.Text}
					error={errors.position?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.position)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
				/>
				<HSpacer mobileHeight={h => h.s} removeOnMobile={false} />
				<Input
					{...register('department')}
					type={InputType.Text}
					error={errors.department?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.department)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
				/>
			</Row>
			{hasLedidiEnterpriseAdminRole && (
				<>
					<Spacer size={s => s.m} />
					<Row>
						<Controller
							control={control}
							name="userRole"
							render={({ field: { value: selectedUserRole } }) => (
								<>
									{selectedUserRole && (
										<UserRoleSelector
											selected={selectedUserRole}
											onSelect={selected => handleValue('userRole', selected)}
										/>
									)}
								</>
							)}
						/>
					</Row>
				</>
			)}

			<Spacer size={s => s.s} />
		</>
	);
}
