import { useQuery } from '@tanstack/react-query';
import { makeRequest } from './makeRequest';

export type GetFilesInfoResponse = {
	httpStatusCode: number;
	doc: Record<
		string,
		{
			fileURL: string;
			metadata: {
				fileLabel: string;
				fileName: string;
				mimeType: string;
			};
		}
	>;
};

export const useGetFilesInfoQuery = (
	args: {
		entryId: string;
		projectId: string;
		fileIds: string[];
	},
	{ enabled = true }
) => {
	const query = useQuery({
		queryKey: ['fileInfo', args],
		enabled,
		queryFn: () =>
			makeRequest<GetFilesInfoResponse>({
				method: 'getFilesInfo',
				service: 'cde',
				data: {
					datasetentryid: args.entryId,
					projectId: args.projectId,
					fileIds: args.fileIds
				}
			})
	});

	return {
		...query,
		// https://github.com/TanStack/query/issues/3584#issuecomment-1256986636
		// loading is apparently true with enabled: false, which I think is a mistake, this makes it easier to use
		isLoading: query.isLoading && query.fetchStatus !== 'idle'
	};
};
