import { useState, useEffect } from 'react';
import { Svgs } from 'environment';
import { ActionTypes, Entry } from 'store/data/entries';
import { Revision } from 'store/data/revisions';
import { Status, StatusesMap } from 'store/data/statuses';
import { DetailsView, HistoryView, StatusView } from './Views';
import {
	Container,
	Views,
	View,
	ViewHeader,
	ViewBody,
	ButtonWrapper
} from './EntryMobileDrawer.style';
import { useSetRevisionId } from 'hooks/store/data/revisions/useSetRevisionId';
import { UnrestoredRevisionModal } from 'components/Dataset/Modals/UnrestoredRevisionModal/UnrestoredRevisionModal';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Tooltip } from 'components/UI/Interactables/Tooltip';
import { Loader } from 'components/UI/Loader';
import { Typography } from 'components/UI/Typography';
import { getHeaderHeight } from 'helpers/generic';
import {
	useTranslation,
	useUpdateEntry,
	useActivities,
	useRevisionId,
	useRevisions,
	useRevision,
	useSetRevision,
	useSetRevisions,
	useNamesFromUserIds
} from 'hooks/store';
import { useCompletedAction, useKeyPress } from 'hooks/utils';
import { usePrevious } from 'hooks/utils/index';
import { DrawerHistoryType, EntryDrawerType } from 'types/index';
import { Button } from 'components/UI/Interactables/Button';

enum VIEWS {
	Details = 'details',
	History = 'revisions',
	Status = 'status'
}

interface FormsDrawerProps {
	enabled: boolean;
	visible: boolean;
	toggleVisible: () => void;
	component: JSX.Element | null;
}

interface Props {
	detailsView: {
		entry: Entry;
	};
	historyView: {
		revisions: Revision[];
		revisionId: string | null;
		loading: boolean;
		disabled: boolean;
		formsDrawer: FormsDrawerProps;
		view: VIEWS | null;
		setView: React.Dispatch<React.SetStateAction<VIEWS | null>>;

		onSelect: (revisionId: string) => void;
		onOpen?: () => void;
		onClose?: () => void;
	};
	statusView: {
		statuses: Status[];
	};
	statusesMap: StatusesMap;
	onOpen?: () => void;
	onClose?: () => void;
	listenToEscape?: boolean;
	disableModal?: boolean;
	entryType: string;
}

export function EntryMobileDrawer({
	detailsView,
	historyView,
	statusView,
	statusesMap,
	onOpen,
	onClose,
	listenToEscape = true,
	disableModal,
	entryType
}: Props) {
	const { translate } = useTranslation();

	const { view, setView } = historyView;

	const [prevView, setPrevView] = useState<VIEWS | null>(null);
	const [iconActive, setIconActive] = useState<VIEWS | null>(null);

	const [{ loading: updatingEntry, error: errorUpdatingEntry }] = useUpdateEntry();

	const [{ loading: updatingOrCreatingSeriesEntry, error: errorUpdatingOrCreatingSeriesEntry }] =
		useActivities([ActionTypes.UPDATE_SERIES_ENTRY, ActionTypes.CREATE_SERIES_ENTRY]);

	const [, setRevisionId] = useRevisionId();
	const [, setSetRevisionId] = useSetRevisionId();

	const namesFromUserIds = useNamesFromUserIds();

	const [modalVisible, setModalVisible] = useState(false);

	// Update revisionIds when updating main entry;
	useCompletedAction(updatingEntry, errorUpdatingEntry, () => {
		setRevisionId(null);
	});

	// Update revisionIds and setRevisionIds when updating or creating series entry;
	useCompletedAction(updatingOrCreatingSeriesEntry, errorUpdatingOrCreatingSeriesEntry, () => {
		setSetRevisionId(null);
		setRevisionId(null);
	});

	useEffect(() => {
		if (prevView === undefined) return;
		if (prevView && view) return;

		view ? onOpen?.() : onClose?.();
	}, [view]);

	function isEntryHistoryOpened() {
		if (historyView.revisionId || modalVisible || disableModal) {
			return true;
		}

		return false;
	}

	function hasChanges() {
		if (historyView.revisionId && !modalVisible && !disableModal) {
			return true;
		}

		return false;
	}

	const isEntryDrawer = true;

	function handleSetView(value: VIEWS) {
		if (value === view) return closeView();

		historyView.formsDrawer.visible && historyView.formsDrawer.toggleVisible();

		if (value === VIEWS.Details && !isEntryHistoryOpened()) {
			setPrevView(view);
			setView(value);
			setIconActive(value);
		}
		if (value === VIEWS.History) {
			historyView.onOpen?.();
			setPrevView(view);
			setView(value);
			setIconActive(value);
		}
	}

	const closeView = () => {
		historyView.onClose?.();
		setPrevView(view);
		setView(null);
		setIconActive(null);
		if (modalVisible) {
			setModalVisible(false);
		}
	};

	const closeEntryDrawer = () => {
		if (!historyView.revisionId || modalVisible || disableModal) {
			closeView();
			setRevisionId(null);
			setSetRevisionId(null);
			setIconActive(null);
			return;
		}
		if (!modalVisible && !disableModal) {
			setModalVisible(true);
		}
	};

	const viewTitle = {
		[VIEWS.Details]: translate(dict => dict.dataset.entryDrawer.entryDetails),
		[VIEWS.History]: translate(dict => dict.dataset.entryDrawer.entryHistory),
		[VIEWS.Status]: translate(dict => dict.dataset.entryDrawer.status)
	};

	const viewItems = [
		{
			type: VIEWS.Details,
			icon: Svgs.Information
		},
		{
			type: VIEWS.History,
			icon: Svgs.Clock
		}

		// TODO: enable when implemented
		// {
		// 	type: VIEWS.Status,
		// 	icon: Svgs.Tag
		// }
	];

	const viewLoader = {
		[VIEWS.Details]: false,
		[VIEWS.History]: historyView.loading,
		[VIEWS.Status]: false
	};

	const viewDisabled = {
		[VIEWS.Details]: false,
		[VIEWS.History]: historyView.disabled,
		[VIEWS.Status]: false
	};

	useKeyPress(
		{ onEscapeKeyPress: closeEntryDrawer },
		{ noModalsOpened: true, listen: !!view && listenToEscape }
	);

	const [{ data: revisions, loading: loadingRevisions, fetched: hasRevisions }] = useRevisions({
		lazy: true
	});
	const [{ data: revision }] = useRevision({ lazy: true });
	const [{ data: setRevision }] = useSetRevision({ lazy: true });
	const [{ data: setRevisions }] = useSetRevisions({ lazy: true });

	const previousRevisions = usePrevious(revisions);

	// Automatically selects latest revision when opening history drawer
	useEffect(() => {
		if (entryType === EntryDrawerType.AddEditForm) {
			if (revisions?.length && !revision && view === VIEWS.History) {
				const lastRevisionId = revisions[revisions.length - 1].revisionId;

				setRevisionId(lastRevisionId);
			}
		}
	}, [revisions, revision, view]);

	useEffect(() => {
		if (
			loadingRevisions &&
			hasRevisions &&
			revision &&
			previousRevisions &&
			revisions &&
			previousRevisions.length !== revisions.length
		) {
			setRevisionId(null);
		}
	}, [loadingRevisions, hasRevisions, revisions, previousRevisions]);

	// Automatically selects latest set revision when opening history drawer
	useEffect(() => {
		if (entryType === EntryDrawerType.SeriesEntryForm) {
			if (setRevisions?.length && !setRevision && view === VIEWS.History) {
				const lastSetRevisionId = setRevisions[setRevisions.length - 1].revisionId;
				setSetRevisionId(lastSetRevisionId);
			}
		}
	}, [setRevisions, setRevision, view]);

	function handleSelect(revisionId: string) {
		historyView.onSelect(revisionId);
	}

	function heightContainerOffset() {
		if (view) {
			return 0;
		}
		if (entryType === EntryDrawerType.SeriesEntryForm) {
			return getHeaderHeight(isEntryDrawer).rem - 6;
		}

		return getHeaderHeight(isEntryDrawer).rem;
	}

	return (
		<Container heightOffset={heightContainerOffset()}>
			{!view && (
				<Views>
					{viewItems.map(viewItem => (
						<Flex
							key={viewItem.type}
							marginOffset={{ right: 2.4 }}
							// tooltip props
							data-tip={viewTitle[viewItem.type]}
							data-for={viewItem.type}
						>
							<Icon
								title={viewTitle[viewItem.type]}
								svg={viewItem.icon}
								size={s => s.l}
								variant={v => v.buttonActive}
								active={hasChanges() && iconActive === viewItem.type}
								onClick={() => handleSetView(viewItem.type)}
							/>
							{view !== viewItem.type && (
								<Tooltip id={viewItem.type} delayShow={250} place="bottom" />
							)}
						</Flex>
					))}
				</Views>
			)}

			{view && (
				<View>
					<ViewHeader>
						<Icon
							marginOffset={{ left: 0.4 }}
							svg={Svgs.ArrowLeft}
							size={s => s.l}
							onClick={closeEntryDrawer}
						/>

						<Flex>
							<Typography.H6 title={viewTitle[view]}>{viewTitle[view]}</Typography.H6>

							{viewLoader[view] && <Loader marginOffset={{ left: 0.4 }} />}
						</Flex>

						<Icon
							marginOffset={{ right: 0.4 }}
							svg={Svgs.Close}
							size={s => s.l}
							onClick={closeEntryDrawer}
						/>
					</ViewHeader>

					<ViewBody isEntryHistoryChanged={hasChanges()} disabled={viewDisabled[view]}>
						{view === VIEWS.Details && <DetailsView entry={detailsView.entry} />}
						{view === VIEWS.History && (
							<HistoryView
								drawerType={DrawerHistoryType.Mobile}
								revisions={historyView.revisions}
								revisionId={historyView.revisionId}
								statusesMap={statusesMap}
								onSelect={handleSelect} //select
								namesFromUserIds={namesFromUserIds}
							/>
						)}
						{view === VIEWS.Status && <StatusView />}
						{hasChanges() && (
							<ButtonWrapper onClick={() => setView(null)}>
								<Button
									hasFullWidth
									variant={v => v.primary}
									title={translate(t => t.buttons.preview)}
								/>
							</ButtonWrapper>
						)}
					</ViewBody>
				</View>
			)}
			<UnrestoredRevisionModal
				visible={!!modalVisible}
				onPrimaryClick={closeView}
				onNeutralClick={() => setModalVisible(false)}
				latestRevisionDate={
					historyView.revisions[0]
						? historyView.revisions[historyView.revisions.length - 1].creationDate
						: ''
				}
			/>
		</Container>
	);
}
