import { RefObject, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useTable, useBlockLayout } from 'react-table';
import { FixedSizeList } from 'react-window';

import { StringMap } from 'types/index';

export interface Column {
	Header: string;
	accessor: string;
}

interface Props {
	columns: Column[];
	data: StringMap[];
	containerRef: RefObject<HTMLDivElement>;
}

export function CsvTable({ columns, data, containerRef }: Props) {
	const [tableHeight, setTableHeight] = useState(0);
	const [containerWidth, setContainerWidth] = useState(0);
	const headerRef = useRef<HTMLTableSectionElement>(null);

	// Update dimensions when container size changes
	useEffect(() => {
		if (!containerRef.current) return;

		const updateDimensions = () => {
			const container = containerRef.current;
			if (container) {
				const headerHeight = headerRef.current?.clientHeight ?? 0;
				setTableHeight(container.clientHeight - headerHeight);
				setContainerWidth(container.clientWidth);
			}
		};

		updateDimensions();
	}, [containerRef]);

	const defaultColumn = useMemo(
		() => ({
			// Calculate column width based on container width and number of columns
			width: Math.max(250, containerWidth / columns.length)
		}),
		[containerWidth, columns.length]
	);

	const { getTableProps, getTableBodyProps, headerGroups, rows, totalColumnsWidth, prepareRow } =
		useTable(
			{
				columns,
				data,
				defaultColumn
			},
			useBlockLayout
		);

	const RenderRow = useCallback(
		({ index, style }: { index: number; style: any }) => {
			const row = rows[index];
			prepareRow(row);
			return (
				<tr
					{...row.getRowProps({
						style
					})}
					className="flex"
				>
					{row.cells.map((cell: any) => {
						return (
							<td
								key={cell.getCellProps().key}
								{...cell.getCellProps()}
								className="py-2 truncate"
							>
								{cell.render('Cell')}
							</td>
						);
					})}
				</tr>
			);
		},
		[prepareRow, rows]
	);

	return (
		<div className="p-4">
			<table {...getTableProps()} className="inline-block text-lg border-spacing-0">
				<thead ref={headerRef}>
					{headerGroups.map((headerGroup: any) => (
						<tr
							key={headerGroup.getHeaderGroupProps().key}
							{...headerGroup.getHeaderGroupProps()}
							className="flex"
						>
							{headerGroup.headers.map((column: any) => (
								<th
									key={column.getHeaderProps().key}
									{...column.getHeaderProps()}
									className="font-bold py-2 truncate text-left"
								>
									{column.render('Header')}
								</th>
							))}
						</tr>
					))}
				</thead>

				<tbody {...getTableBodyProps()}>
					<FixedSizeList
						height={tableHeight}
						itemCount={rows.length}
						itemSize={35}
						width={totalColumnsWidth}
					>
						{RenderRow}
					</FixedSizeList>
				</tbody>
			</table>
		</div>
	);
}
