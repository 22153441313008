import { Control } from 'react-hook-form';
import {
	Entry,
	CategoryNonFixedVariable,
	CategoryMultipleNonFixedVariable,
	CategoryFixedVariable,
	CategoryMultipleFixedVariable
} from './types';
import clsx from 'clsx';
import {
	FormItem,
	NonGroupFormItem,
	ProjectData,
	VariableFormItem
} from './data/useProjectData/useProjectData';
import { ChevronDownIcon, SetIcon } from '@icons';

interface Props {
	projectData: ProjectData;
}

export const PreviewEntryForm = ({ projectData }: Props) => {
	return (
		<div className="grid grid-cols-2 text-start gap-[4px] items-end pointer-events-none">
			{projectData.formTitle && (
				<h1 className="text-[7px] text-start line-clamp-1 font-semibold col-span-full">
					{projectData.formTitle}
				</h1>
			)}

			{projectData.formItems.map((item, index) => (
				<FormItemComponent key={index} item={item} />
			))}
		</div>
	);
};

const FormItemComponent = ({ item }: { item: FormItem }) => {
	switch (item.type) {
		case 'variable': {
			return (
				<div
					className={clsx(
						'col-span-full break-inside-avoid',
						item.columnSpan === 1 && 'md:col-span-1',
						item.columnSpan === 2 && 'md:col-span-2',
						item.columnStart === 1 && 'md:col-start-1',
						item.columnStart === 2 && 'md:col-start-2',

						item.hideFromUi && 'hidden'
					)}
				>
					<VariableInput formItem={item} />
				</div>
			);
		}

		case 'group': {
			return (
				<GroupContainer
					groupName={item.group.groupName}
					groupLabel={item.group.groupLabel}
					formItems={item.formItems}
				/>
			);
		}

		case 'separator':
			return <hr className="col-span-full" />;

		case 'subtitle':
			return (
				<h2 className="text-[4px] font-semibold line-clamp-1 col-span-full">{item.text}</h2>
			);

		case 'text':
			return <p className="col-span-full text-[4px]">{item.text}</p>;

		case 'series': {
			return <SeriesContainer seriesName={item.name} seriesLabel={item.label} />;
		}
	}
};

const GroupContainer = ({
	groupName,
	groupLabel
}: {
	groupName: string;
	groupLabel: string;
	formItems: NonGroupFormItem[];
}) => {
	return (
		<div
			key={groupName}
			className="p-[2px] col-span-full rounded-sm shadow-normal flex gap[4px] items-center flex-row"
		>
			<ChevronDownIcon className="text-[6px]" />

			<h2 className="text-[4px] font-semibold">{groupLabel}</h2>
		</div>
	);
};

const SeriesContainer = ({
	seriesName,
	seriesLabel
}: {
	seriesName: string;
	seriesLabel: string;
}) => {
	return (
		<div
			key={seriesName}
			className="p-[2px] col-span-full rounded-sm shadow-normal flex gap-[2px] items-center flex-row"
		>
			<ChevronDownIcon className="text-[6px]" />

			<SetIcon className="text-[6px]" />

			<h2 className="text-[4px] font-semibold">{seriesLabel}</h2>
		</div>
	);
};

export type FormControl = Control<Entry, any>;

const VariableInput = ({ formItem }: { formItem: VariableFormItem }) => {
	switch (formItem.variableType) {
		case 'string':
		case 'timeDuration':
		case 'userDefinedUnique':
		case 'date':
		case 'datetime':
		case 'float':
		case 'integer': {
			return (
				<Input
					label={formItem.variable.variableLabel}
					disabled={formItem.variable.entryType === 'calculated'}
				/>
			);
		}

		case 'category': {
			if (formItem.layoutType === 'dropdown') {
				return <Dropdown />;
			}

			const options = getCategoryOptions(formItem.variable);

			return (
				<fieldset className="flex flex-col">
					<Label label={formItem.variable.variableLabel} />

					<div className="flex gap-[2px] flex-wrap mt-[1px]">
						{options.map(option => {
							return (
								<div key={option.value} className="flex gap-1 items-center">
									<div className="rounded-full border-[1px] border-gray-700 w-[4px] h-[4px]" />

									<label className="text-[4px]">{option.label}</label>
								</div>
							);
						})}

						{formItem.variable.fixedCategories === 'no' && (
							<div className="text-primary-500 text-[5px] ">+ Add new value</div>
						)}
					</div>
				</fieldset>
			);
		}

		case 'categoryMultiple': {
			const options = getCategoryOptions(formItem.variable);
			return (
				<fieldset className="flex flex-col">
					<Label label={formItem.variable.variableLabel} />

					<div className="flex gap-[2px] flex-wrap mt-[1px]">
						{options.map(option => {
							return (
								<div key={option.value} className="flex gap-1 items-center">
									<div className="rounded-sm border-[1px] border-gray-700 w-[4px] h-[4px]" />

									<label className="text-[4px]">{option.label}</label>
								</div>
							);
						})}
					</div>
				</fieldset>
			);
		}

		case 'file': {
			return null;
		}
	}

	console.error(
		new Error('Unhandled variable type:', {
			// @ts-ignore
			variableType: variable.variableType
		})
	);
};

function getCategoryOptions(
	variable:
		| CategoryNonFixedVariable
		| CategoryFixedVariable
		| CategoryMultipleNonFixedVariable
		| CategoryMultipleFixedVariable
): { label: string; value: string }[] {
	if (variable.fixedCategories === 'no') {
		return [...variable.categories.map(category => ({ label: category, value: category }))];
	}

	return variable.allowedCategories.map(category => ({
		label: category.label,
		value: category.value
	}));
}

const Input = ({ label, disabled }: { label: string; disabled?: boolean }) => {
	return (
		<div>
			<Label label={label} />
			<div
				className={clsx(
					'w-full border-[1px] h-[10px] rounded-sm',
					!disabled && 'border-gray-400',
					disabled && 'bg-gray-300 border-gray-300'
				)}
			/>
		</div>
	);
};

const Dropdown = () => {
	return (
		<div className="w-full border-[1px] h-[10px] border-gray-400 rounded-sm relative">
			<ChevronDownIcon className="absolute right-[1px] top-[1px] text-[6px]" />
		</div>
	);
};

const Label = ({ label }: { label: string }) => {
	return <div className="text-[4px] font-semibold text-start line-clamp-1">{label}</div>;
};
