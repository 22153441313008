import { Table } from 'components/UI/Table';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Pagination } from 'components/UI/Pagination';
import styled from 'styled-components';
import { Typography } from 'components/UI/Typography';
import { Spacer } from 'components/UI/Spacer';
import { usePagination, useTable, Column } from 'react-table';
import {
	AuditEvent,
	CHANGED_ACTIONS,
	ChangedAction,
	useGetCollaboratorHistoryData
} from './useGetCollaboratorHistoryData';
import { useProjectId } from 'hooks/store/data/projects/useProjectId';
import { Loader } from 'components/UI/Loader/Loader';
import { useTranslation } from 'hooks/store/ui/useTranslation';
import { FilterIconButton } from './Filter/FilterIconButton';
import { CollaboratorPermissionHistoryModal } from './CollaboratorPermissionHistoryModal/CollaboratorPermissionHistoryModal';
import { DateTime } from 'luxon';

export function CollaboratorsHistoryTable() {
	const tableRef = useRef<HTMLTableElement | null>(null);

	const [modalData, setModalData] = useState<AuditEvent | null>(null);
	const [pageSize, setPageSize] = useState(10);
	const [pageIndex, setPageIndex] = useState(0);

	const [filterOptions, setFilterOptions] = useState<{
		userIds: string[];
		actions: string[];
		changedBys: string[];
	}>({ userIds: [], actions: [], changedBys: [] });

	const setFilteredUserIds = (userIds: string[]) => {
		setFilterOptions(prev => ({ ...prev, userIds }));
		setPageIndex(0);
	};

	const setFilteredActions = (actions: string[]) => {
		setFilterOptions(prev => ({ ...prev, actions }));
		setPageIndex(0);
	};

	const setFilteredChangedBys = (changedBys: string[]) => {
		setFilterOptions(prev => ({ ...prev, changedBys }));
		setPageIndex(0);
	};

	const [projectId] = useProjectId();

	const { data, isError, isLoading, getHistory } = useGetCollaboratorHistoryData();

	const { translate } = useTranslation();

	useEffect(() => {
		if (!projectId) return;

		getHistory({
			projectId,
			pageIndex,
			pageSize,
			filterOptions
		});
	}, [projectId, pageIndex, pageSize, filterOptions]);

	const columns = useMemo<Column<AuditEvent>[]>(
		() => [
			{
				Header: translate(state => state.collaborators.history.columns.timestamp),
				accessor: 'changedAt'
			},
			{
				Header: () => {
					return (
						<FilterableColumn>
							<span>
								{translate(state => state.collaborators.history.columns.user)}
							</span>

							<FilterIconButton
								tableRef={tableRef}
								items={
									data?.filter.users.map(user => ({
										label: user.username,
										value: user.id
									})) ?? []
								}
								onApply={setFilteredUserIds}
								onClear={() => setFilteredUserIds([])}
								selectedItems={filterOptions.userIds}
							/>
						</FilterableColumn>
					);
				},
				accessor: 'username'
			},
			{
				Header: () => {
					return (
						<FilterableColumn>
							<span>
								{translate(state => state.collaborators.history.columns.action)}
							</span>

							<FilterIconButton
								tableRef={tableRef}
								items={CHANGED_ACTIONS.map(action => ({
									label: formatAction(action),
									value: action
								}))}
								onApply={setFilteredActions}
								onClear={() => setFilteredActions([])}
								selectedItems={filterOptions.actions}
							/>
						</FilterableColumn>
					);
				},
				accessor: 'changedAction'
			},
			{
				Header: () => {
					return (
						<FilterableColumn>
							<span>
								{translate(state => state.collaborators.history.columns.changedBy)}
							</span>

							<FilterIconButton
								tableRef={tableRef}
								items={
									data?.filter?.changedBys.map(user => ({
										label: user.username,
										value: user.id
									})) ?? []
								}
								onApply={setFilteredChangedBys}
								onClear={() => setFilteredChangedBys([])}
								selectedItems={filterOptions.changedBys}
							/>
						</FilterableColumn>
					);
				},
				accessor: 'changedBy'
			}
		],

		[filterOptions, data?.filter]
	);

	const formatAction = (action: ChangedAction): string => {
		switch (action) {
			case 'CREATE':
				return translate(state => state.collaborators.history.actions.create);
			case 'UPDATE':
				return translate(state => state.collaborators.history.actions.update);
			case 'DELETE':
				return translate(state => state.collaborators.history.actions.delete);
		}
	};

	const onRowClick = async (auditEvent: AuditEvent) => {
		setModalData(auditEvent);
	};

	const { page, headerGroups, pageOptions, getTableProps, getTableBodyProps, prepareRow, rows } =
		useTable(
			{
				columns,
				manualPagination: true,
				pageCount: data?.pageCount ?? 0,
				data: data?.collaboratorHistories ?? EMPTY_LIST
			},
			usePagination
		);

	return (
		<div>
			{modalData && projectId && (
				<CollaboratorPermissionHistoryModal
					onClose={() => setModalData(null)}
					auditEvent={modalData}
					projectId={projectId}
				/>
			)}

			{isLoading ? (
				<Loader primary />
			) : isError ? (
				<ErrorState />
			) : (
				<>
					<Pagination
						totalCount={data?.totalCount ?? 0}
						filteredCount={rows.length}
						pageIndex={data?.pageIndex ?? 0}
						pageSize={data?.pageSize ?? 0}
						pagesCount={pageOptions.length}
						changePage={pageIndex => setPageIndex(pageIndex)}
						changePageSize={pageSize => {
							setPageIndex(0);
							setPageSize(pageSize);
						}}
					/>
					<Spacer size={s => s.xs} />
					<Table.Responsive fullHeight>
						<Table fullWidth {...getTableProps()} hoverEffect tableRef={tableRef}>
							<Table.Head>
								{headerGroups.map(headerGroup => (
									<Table.Row
										{...headerGroup.getHeaderGroupProps()}
										key={headerGroup.getHeaderGroupProps().key}
									>
										{headerGroup.headers.map(column => (
											<Table.Column
												{...column.getHeaderProps()}
												key={column.getHeaderProps().key}
											>
												{column.render('Header')}
											</Table.Column>
										))}
									</Table.Row>
								))}
							</Table.Head>

							<Table.Body {...getTableBodyProps()}>
								{rows.length === 0 ? (
									<Table.Row>
										<Table.Cell colSpan={4}>
											{translate(
												state => state.collaborators.history.noEntries
											)}
										</Table.Cell>
									</Table.Row>
								) : (
									page.map(row => {
										prepareRow(row);
										const entry = row.original;
										const timestamp = DateTime.fromJSDate(
											new Date(entry.changedAt)
										).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);

										return (
											<Table.Row
												key={Math.random()}
												onClick={() => onRowClick(entry)}
												style={{ cursor: 'pointer' }}
											>
												<ShrinkedCell>{timestamp}</ShrinkedCell>
												<ShrinkedCell>{entry.username}</ShrinkedCell>
												<Cell>{formatAction(entry.changedAction)}</Cell>
												<Table.Cell>{entry.changedByUserName}</Table.Cell>
											</Table.Row>
										);
									})
								)}
							</Table.Body>
						</Table>
					</Table.Responsive>
				</>
			)}
		</div>
	);
}

const EMPTY_LIST: Array<AuditEvent & { username: string }> = [];

const ErrorState = () => {
	const { translate } = useTranslation();

	return (
		<Container>
			<Spacer size={s => s.s} />
			<Typography.H3>
				{translate(state => state.collaborators.history.pageError.title)}
			</Typography.H3>
			<Typography.Paragraph>
				{translate(state => state.collaborators.history.pageError.description)}
			</Typography.Paragraph>
			<Spacer size={s => s.s} />
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const FilterableColumn = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const ShrinkedCell = styled(Table.Cell)`
	min-width: 0;
	width: 1px;
	white-space: nowrap;
`;

const Cell = styled(Table.Cell)`
	white-space: nowrap;
`;
