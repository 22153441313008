import { useEffect } from 'react';
import { isEqual, cloneDeep } from 'lodash';
import { isAfter, isBefore, isEqual as isDateEqual } from 'date-fns';

import { ROUTES } from 'types/navigation';
import { VariableType } from 'types/data/variables/constants';
import { VariableFilteringMap, DynamicFormValues, DynamicFormValue } from 'store/data/entries';
import { BooleanMap, SetState, StringArrayMap, GenericMap } from 'types/index';
import { DependencyType, DependencyOperators, Dependency } from 'store/data/dependencies';
import {
	entryFormDependenciesCheckEvent,
	getMicrosecondsFromTimeDurationString
} from 'helpers/entries';
import { useCustomEventListener } from 'helpers/events';
import { useRouteMatch } from 'hooks/navigation';
import {
	useEntry,
	useSelectedSeriesEntry,
	useVariables,
	useDependencies,
	useRevision
} from 'hooks/store';
import { usePrevious } from 'hooks/utils';
import { TimeDurationKey } from 'timeDurationConsts';
import { buildDependencyNamesByVariableName, getDependantVariables } from 'helpers/dependencies';
import type { Variable } from 'api/data/variables';

type FilteringResult = {
	dependantVariableName: string;
	filteredValues: string[];
	currentValue: string | string[];
};

interface DependenciesMapCheckerProps {
	variableVisibilityMapState: {
		variableVisibilityMap: BooleanMap;
		setVariableVisibilityMap: SetState<BooleanMap>;
	};
	variableFilteringMapState: {
		variableFilteringMap: VariableFilteringMap;
		setVariableFilteringMap: SetState<VariableFilteringMap>;
	};
	setData?: { name: string; id: string };
	customFormContext: {
		getValues: () => DynamicFormValues;
		setValue: (name: string, value: DynamicFormValue) => void;
	};
	onResetFields?: (values: DynamicFormValues) => void;
	dataTestIdEntryNumber?: number;
}

export function DependenciesMapChecker({
	variableVisibilityMapState: { variableVisibilityMap, setVariableVisibilityMap },
	variableFilteringMapState: { variableFilteringMap, setVariableFilteringMap },
	setData,
	onResetFields,
	customFormContext: { setValue, getValues }
}: DependenciesMapCheckerProps) {
	const isOnUpdateEntryRoute = useRouteMatch([ROUTES.UpdateEntry]);

	const [{ data: entry }] = useEntry();

	const [{ subEntryId }] = useSelectedSeriesEntry();

	const [
		{
			data: { variablesMap },
			fetched: areVariablesFetched
		}
	] = useVariables({ initial: true, lazy: true });

	const [
		{
			data: { active, dependencies, dependenciesBySetName },
			fetched: areDependenciesFetched
		}
	] = useDependencies({
		lazy: true
	});

	const [{ data: revision }] = useRevision({ lazy: true });

	function getScope() {
		const scope = {
			active,
			dependencies
		};

		if (setData) {
			scope.active = true;
			scope.dependencies = [];

			const setDependenciesData = dependenciesBySetName[setData?.name];

			if (setDependenciesData) {
				scope.active = setDependenciesData.active;
				scope.dependencies = setDependenciesData.dependencies;
			}
		}

		return scope;
	}

	const { active: scopeActive, dependencies: scopeDependencies } = getScope();

	const dependenciesMap = buildDependenciesMap(scopeDependencies);
	const dependencyNames = scopeDependencies.map(d => d.dependencyName);
	const dependencyNamesByVariableName = buildDependencyNamesByVariableName(scopeDependencies);

	function runDependenciesCheck(
		dependencyNames: string[],
		options?: { shouldResetFields?: boolean }
	) {
		const { shouldResetFields = true } = options ?? {};

		const initialVisibilityMap = cloneDeep(
			Object.keys(variableVisibilityMap).length ? variableVisibilityMap : {}
		);
		const initialFilteringMap = cloneDeep(
			Object.keys(variableFilteringMap).length ? variableFilteringMap : {}
		);

		// Get all affected variables including chains
		const getVisibilityChainDependencies = (deps: string[]): string[] => {
			const result = new Set<string>();
			const processDependency = (depName: string) => {
				if (!result.has(depName) && dependenciesMap[depName]) {
					result.add(depName);
					// Only process visibility dependencies
					const dep = dependenciesMap[depName];
					if (dep.dependencyType === DependencyType.Visibility) {
						const chainedDeps = getDependantVariables([dep]);
						chainedDeps.forEach(d => {
							if (dependenciesMap[d]) {
								processDependency(d);
							}
						});
					}
				}
			};
			deps.forEach(d => {
				if (dependenciesMap[d]) {
					processDependency(d);
				}
			});
			return Array.from(result);
		};

		const affectedVisibilityVariables = getVisibilityChainDependencies(dependencyNames);

		const affectedVariables = getDependantVariables(
			dependencyNames.map(name => dependenciesMap[name])
		);

		[...affectedVariables, ...affectedVisibilityVariables].forEach(name => {
			if (name in initialFilteringMap) {
				delete initialFilteringMap[name];
			}
			if (name in initialVisibilityMap) {
				delete initialVisibilityMap[name];
			}
		});

		const newVariableVisibilityMap: BooleanMap = cloneDeep(initialVisibilityMap);
		const newVariableFilteringMap: StringArrayMap = cloneDeep(initialFilteringMap);
		let formValues = cloneDeep(getValues());
		const fieldsToReset: string[] = [];

		const allDependencies = dependencyNames.map(d => dependenciesMap[d]);
		const { filteringDependencies, visibilityDependencies } = sortDependencies(allDependencies);

		// Accumulate visibility with chain dependencies
		const accumulatedVisibilityMap: BooleanMap = {};
		const processedDependencies = new Set<string>();

		const processVisibilityChain = (dependencies: typeof visibilityDependencies) => {
			for (const dep of dependencies) {
				const { supplierVariableName, dependantVariables } = dep;
				const supplierVariable = variablesMap[supplierVariableName];
				if (!supplierVariable || !(supplierVariableName in formValues)) continue;

				const formValue = formValues[supplierVariableName];
				const isSupplierVisible =
					supplierVariableName in accumulatedVisibilityMap
						? accumulatedVisibilityMap[supplierVariableName]
						: true;

				dependantVariables.forEach(dependant => {
					const { dependantVariableName, supplierValueCondition, operator } = dependant;
					const dependantVariable = variablesMap[dependantVariableName];
					if (!dependantVariable) return;

					const conditionMatched = isConditionMatched(
						supplierVariable,
						formValue,
						supplierValueCondition,
						operator
					);

					const currentVisibility =
						accumulatedVisibilityMap[dependantVariableName] ?? false;
					const newVisibility =
						(conditionMatched && isSupplierVisible) || currentVisibility;
					accumulatedVisibilityMap[dependantVariableName] = newVisibility;

					if (
						!processedDependencies.has(dependantVariableName) &&
						dependenciesMap[dependantVariableName]
					) {
						processedDependencies.add(dependantVariableName);
						const chainDeps = sortDependencies([
							dependenciesMap[dependantVariableName]
						]).visibilityDependencies;
						processVisibilityChain(chainDeps);
					}
				});
			}
		};

		processVisibilityChain(visibilityDependencies);

		// Apply visibility results
		Object.entries(accumulatedVisibilityMap).forEach(([name, isVisible]) => {
			newVariableVisibilityMap[name] = isVisible;
			if (shouldResetFields && !isVisible) {
				if (!fieldsToReset.includes(name)) {
					setValue(name, getFieldDefaultValue(name));
					formValues = cloneDeep(getValues());
				}
			}
		});

		const filteringResults = new Map<string, FilteringResult>();

		// First pass: Collect all filtering results
		for (const dep of filteringDependencies) {
			const { supplierVariableName, dependantVariables } = dep;
			const supplierVariable = variablesMap[supplierVariableName];

			if (!supplierVariable || !(supplierVariableName in formValues)) continue;

			const formValue = formValues[supplierVariableName];

			dependantVariables.forEach(dependant => {
				const { dependantVariableName, supplierValueCondition, operator, filteredValues } =
					dependant;
				const dependantVariable = variablesMap[dependantVariableName];

				if (!dependantVariable || !(dependantVariableName in formValues)) return;

				const conditionMatched = isConditionMatched(
					supplierVariable,
					formValue,
					supplierValueCondition,
					operator
				);

				if (conditionMatched) {
					const existingResult = filteringResults.get(dependantVariableName);
					const currentValue = formValues[dependantVariableName];

					if (!existingResult) {
						filteringResults.set(dependantVariableName, {
							dependantVariableName,
							filteredValues,
							currentValue
						});
					} else {
						// Combine filtered values using union logic
						existingResult.filteredValues = Array.from(
							new Set([...existingResult.filteredValues, ...filteredValues])
						);
					}
				}
			});
		}

		// Second pass: Apply filtering results and reset fields if needed
		for (const [dependantVariableName, result] of filteringResults.entries()) {
			const { filteredValues, currentValue } = result;
			const dependantVariable = variablesMap[dependantVariableName];

			// Update filtering map
			newVariableFilteringMap[dependantVariableName] = filteredValues;

			if (shouldResetFields) {
				if (dependantVariable.type === VariableType.CategoryMultiple) {
					const currentValues = currentValue as string[];
					const newValues = currentValues.filter(value => filteredValues.includes(value));

					if (!isEqual(currentValues, newValues)) {
						setValue(dependantVariableName, newValues);
						formValues[dependantVariableName] = newValues;
					}
				} else {
					const currentSingleValue = currentValue as string;
					const shouldResetField =
						currentSingleValue !== '' && !filteredValues.includes(currentSingleValue);

					if (shouldResetField) {
						setValue(
							dependantVariableName,
							getFieldDefaultValue(dependantVariableName)
						);
						formValues = cloneDeep(getValues());
					}
				}
			}
		}
		// Extra check because category multiple does not properly reset as it needs to behave like a category
		const visibilityRulesToReset = Object.keys(newVariableVisibilityMap).filter(
			variable => newVariableVisibilityMap[variable] === false
		);

		const uniqueFieldsToReset = [...new Set([...fieldsToReset, ...visibilityRulesToReset])];
		/**
		 * Reset the fields that are:
		 * - not visible anymore
		 * - category values are not included in the filtering options
		 */
		resetFields(uniqueFieldsToReset);

		if (!isEqual(variableVisibilityMap, newVariableVisibilityMap)) {
			setVariableVisibilityMap(newVariableVisibilityMap);
		}
		if (!isEqual(variableFilteringMap, newVariableFilteringMap)) {
			setVariableFilteringMap(newVariableFilteringMap);
		}
	}

	function resetFields(variableNames: string[]) {
		const newValues = variableNames.reduce(
			(acc, curr) => ({
				...acc,
				[curr]: getFieldDefaultValue(curr)
			}),
			{} as DynamicFormValues
		);

		onResetFields?.(newValues);
	}

	function getFieldDefaultValue(variableName: string): DynamicFormValue {
		const isCategoryMultiple =
			variablesMap[variableName].type === VariableType.CategoryMultiple;

		return isCategoryMultiple ? [] : '';
	}

	////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////

	// INIT RULES
	useEffect(() => {
		// VARIABLES AND DEPENDENCIES ARE NOT FETCHED - DO NOTHING
		if (!(areVariablesFetched && areDependenciesFetched)) return;

		// UPDATE ENTRY MODE AND ENTRIES ARE NOT FETCHED - DO NOTHING
		if (isOnUpdateEntryRoute) return;

		// Rules are disabled - do nothing
		if (!scopeActive) return;

		// DEBOUNCE IN JS CALL STACK
		const timeout = setTimeout(buildInitialDependenciesCheck);

		return () => clearTimeout(timeout);
	}, [areVariablesFetched, areDependenciesFetched, isOnUpdateEntryRoute, scopeActive]);

	const prevRevision = usePrevious(revision);
	useEffect(() => {
		if (!scopeActive) return;

		if (prevRevision === undefined) return;

		if (isEqual(prevRevision, revision)) return;

		if (!(revision && revision.changes.data !== null)) return;

		// DEBOUNCE IN JS CALL STACK
		const timeout = setTimeout(buildInitialDependenciesCheck);

		return () => clearTimeout(timeout);
	}, [revision, scopeActive]);

	const prevEntry = usePrevious(entry);
	useEffect(() => {
		if (!scopeActive) return;

		if (entry && !prevEntry) return buildInitialDependenciesCheck();

		if (isEqual(prevEntry, entry)) return;

		if (!entry) return;

		// DEBOUNCE IN JS CALL STACK
		const timeout = setTimeout(buildInitialDependenciesCheck);

		return () => clearTimeout(timeout);
	}, [entry, scopeActive]);

	const prevSubEntryId = usePrevious(subEntryId);
	useEffect(() => {
		if (!setData) return;
		if (!scopeActive) return;

		if (subEntryId && !prevSubEntryId) return buildInitialDependenciesCheck();
		if (prevSubEntryId === undefined) return;

		if (isEqual(prevSubEntryId, subEntryId)) return;

		// DEBOUNCE IN JS CALL STACK
		const timeout = setTimeout(buildInitialDependenciesCheck);

		return () => clearTimeout(timeout);
	}, [setData, subEntryId, scopeActive]);

	// RE-RUN DEPENDENCIES CHECK EVENT AFTER ENTRY DRAFT WAS APPLIED
	useCustomEventListener(entryFormDependenciesCheckEvent, {
		onListen: payload => {
			const { fieldNames } = payload;

			const rawDependenciesToRerun: string[] = [];

			fieldNames.forEach(fieldName => {
				const dependencyNames = dependencyNamesByVariableName[fieldName];

				if (dependencyNames) rawDependenciesToRerun.push(...dependencyNames);
			});

			const uniqueDependenciesToRerun = [...new Set(rawDependenciesToRerun)];

			const dependenciesToRerun: string[] = dependencyNames.filter(dependencyName =>
				uniqueDependenciesToRerun.includes(dependencyName)
			);

			if (uniqueDependenciesToRerun.length) {
				runDependenciesCheck(dependenciesToRerun, { shouldResetFields: true });
			}
		},
		listen: areVariablesFetched && areDependenciesFetched && scopeActive
	});

	function buildInitialDependenciesCheck() {
		runDependenciesCheck(dependencyNames, { shouldResetFields: true });
	}

	function buildDependenciesMap(dependencies: Dependency[]): GenericMap<Dependency> {
		const map: GenericMap<Dependency> = {};

		dependencies.forEach(dependency => (map[dependency.dependencyName] = dependency));

		return map;
	}

	return null;
}

/**
 *
 * @param arr dependencies array
 * @returns topologically sorted dependencies (dependants present in another supplier are always present before)
 *
 */
function sortDependencies(arr: Dependency[]): {
	visibilityDependencies: Dependency[];
	filteringDependencies: Dependency[];
} {
	// Build graph as a map from supplier to its dependants
	const graph = new Map<string, Set<string>>();
	const dependantsCount = new Map<string, number>(); // Tracks in-degrees for topological sorting

	// Populate the graph
	for (const item of arr) {
		const { supplierVariableName, dependantVariables } = item;

		// Ensure supplier is part of the graph
		if (!graph.has(supplierVariableName)) {
			graph.set(supplierVariableName, new Set());
		}
		dependantsCount.set(supplierVariableName, dependantsCount.get(supplierVariableName) || 0);

		// Iterate over dependant variables and update graph and in-degree count
		for (const dep of dependantVariables) {
			const depName = dep.dependantVariableName;

			// Add dependant to the supplier's set
			graph.get(supplierVariableName)!.add(depName);

			// Update in-degree count for dependant
			dependantsCount.set(depName, (dependantsCount.get(depName) || 0) + 1);

			// Ensure all dependants are part of the graph
			if (!graph.has(depName)) {
				graph.set(depName, new Set());
			}
		}
	}

	// Perform topological sort
	const sortedNodes: string[] = [];
	const stack: string[] = [];

	// Initialize stack with nodes having 0 in-degree (i.e., no dependencies)
	for (const [node, count] of dependantsCount.entries()) {
		if (count === 0) {
			stack.push(node);
		}
	}

	while (stack.length > 0) {
		const node = stack.pop()!;
		sortedNodes.push(node);

		// Reduce in-degree of dependants and add to stack if in-degree becomes 0
		for (const dependant of graph.get(node) || []) {
			dependantsCount.set(dependant, dependantsCount.get(dependant)! - 1);
			if (dependantsCount.get(dependant) === 0) {
				stack.push(dependant);
			}
		}
	}

	// Map sorted nodes to their original array order and format
	const nodeIndexMap = new Map<string, number>();
	sortedNodes.forEach((node, index) => {
		nodeIndexMap.set(node, index);
	});

	// Sort dependencies based on the topological order of their suppliers
	const sortedDependencies = arr.sort((a, b) => {
		return (
			(nodeIndexMap.get(a.supplierVariableName) || 0) -
			(nodeIndexMap.get(b.supplierVariableName) || 0)
		);
	});

	// Split sorted dependencies into visibility and filtering dependencies
	return sortedDependencies.reduce(
		(acc, item) => {
			if (item.dependencyType === DependencyType.Visibility) {
				acc.visibilityDependencies.push(item);
			} else {
				acc.filteringDependencies.push(item);
			}
			return acc;
		},
		{
			visibilityDependencies: [] as Dependency[],
			filteringDependencies: [] as Dependency[]
		}
	);
}

function isConditionMatched(
	supplierVariable: Variable,
	formValue: string | string[],
	supplierValueCondition: string,
	operator: DependencyOperators
): boolean {
	const supplierValue = formValue as string;
	const isSupplierValueValid = supplierValue !== '';

	const supplierValues = formValue as string[];
	const areSupplierValuesValid = supplierValues.length > 0;

	const isSupplierValueConditionValid = supplierValueCondition !== '';

	const supplierVariableType = supplierVariable.type;

	const isIntegerVariableType = supplierVariableType === VariableType.Integer;
	const isFloatVariableType = supplierVariableType === VariableType.Float;
	const isNumericVariableType = isIntegerVariableType || isFloatVariableType;
	const isTimeDurationType =
		supplierVariableType === VariableType.TimeDuration && supplierVariable.durationFormat;
	const isStringVariableType = supplierVariableType === VariableType.String;
	const isDateVariableType = [VariableType.Date, VariableType.DateTime].includes(
		supplierVariableType
	);
	const isCategoryVariableType = supplierVariableType === VariableType.Category;
	const isCategoryMultipleVariableType = supplierVariableType === VariableType.CategoryMultiple;

	// TIME DURATION
	if (isTimeDurationType) {
		const parsedNumber = getMicrosecondsFromTimeDurationString(
			supplierValue,
			supplierVariable.durationFormat as TimeDurationKey[]
		);

		const parsedCondition = getMicrosecondsFromTimeDurationString(
			supplierValueCondition,
			supplierVariable.durationFormat as TimeDurationKey[]
		);

		const areValuesValid = isSupplierValueValid && isSupplierValueConditionValid;

		// <
		if (operator === DependencyOperators.LESS_THAN) {
			return areValuesValid && parsedNumber < parsedCondition;
		}
		// <=
		if (operator === DependencyOperators.LESS_OR_EQUAL_TO) {
			return areValuesValid && parsedNumber <= parsedCondition;
		}
		// ===
		if (operator === DependencyOperators.EQUAL_TO) {
			return areValuesValid && parsedNumber === parsedCondition;
		}
		// >=
		if (operator === DependencyOperators.GREATER_OR_EQUAL_TO) {
			return areValuesValid && parsedNumber >= parsedCondition;
		}
		// >
		if (operator === DependencyOperators.GREATER_THAN) {
			return areValuesValid && parsedNumber > parsedCondition;
		}
	}

	// NUMERIC (INTEGER / FLOAT)
	if (isNumericVariableType) {
		const parseNumber = isIntegerVariableType ? parseInt : parseFloat;

		const areValuesValid = isSupplierValueValid && isSupplierValueConditionValid;

		// <
		if (operator === DependencyOperators.LESS_THAN) {
			return (
				areValuesValid && parseNumber(supplierValue) < parseNumber(supplierValueCondition)
			);
		}
		// <=
		if (operator === DependencyOperators.LESS_OR_EQUAL_TO) {
			return (
				areValuesValid && parseNumber(supplierValue) <= parseNumber(supplierValueCondition)
			);
		}
		// ===
		if (operator === DependencyOperators.EQUAL_TO) {
			return (
				areValuesValid && parseNumber(supplierValue) === parseNumber(supplierValueCondition)
			);
		}
		// >=
		if (operator === DependencyOperators.GREATER_OR_EQUAL_TO) {
			return (
				areValuesValid && parseNumber(supplierValue) >= parseNumber(supplierValueCondition)
			);
		}
		// >
		if (operator === DependencyOperators.GREATER_THAN) {
			return (
				areValuesValid && parseNumber(supplierValue) > parseNumber(supplierValueCondition)
			);
		}
	}

	// STRING
	if (isStringVariableType) {
		return supplierValue.includes(supplierValueCondition);
	}

	// DATE
	if (isDateVariableType) {
		const areValuesValid = isSupplierValueValid && isSupplierValueConditionValid;

		const left = new Date(supplierValue);
		const right = new Date(supplierValueCondition);

		// BEFORE DATE
		if (operator === DependencyOperators.LESS_THAN) {
			return areValuesValid && isBefore(left, right);
		}
		// EXACT DATE
		if (operator === DependencyOperators.EQUAL_TO) {
			return areValuesValid && isDateEqual(left, right);
		}
		// AFTER DATE
		if (operator === DependencyOperators.GREATER_THAN) {
			return areValuesValid && isAfter(left, right);
		}
	}

	// CATEGORY
	if (isCategoryVariableType) {
		const areValuesValid = isSupplierValueValid && isSupplierValueConditionValid;

		return areValuesValid && supplierValue === supplierValueCondition;
	}

	// CATEGORY MULTIPLE
	if (isCategoryMultipleVariableType) {
		const areValuesValid = areSupplierValuesValid && isSupplierValueConditionValid;

		return areValuesValid && supplierValues.includes(supplierValueCondition);
	}

	return false;
}
