import loginImage from './images/image.png';
import avatar from './images/avatar.jpg';
import chrome from './images/chrome.png';
import dragIcon from './images/icn-drag.png';
import printIcon from './images/icn-print.png';
import logoLarge from './images/logo-large.png';
import logoPrimary from './images/logo-primary.png';
import logoSecondary from './images/logo-secondary.png';
import logoSmall from './images/logo-small.png';
import logoForms from './images/logo-forms.png';
import norway from './images/norway.png';
import uk from './images/uk.png';
import noUsersForSharedProject from './images/no-users-for-shared-project.png';
import mfaHelpStepOne from './images/mfa-help-step-one.png';
import mfaHelpStepTwo from './images/mfa-help-step-two.png';

export const Images = {
	loginImage,
	avatar,
	chrome,
	dragIcon,
	printIcon,
	logoLarge,
	logoPrimary,
	logoSecondary,
	logoSmall,
	logoForms,
	norway,
	uk,
	noUsersForSharedProject,
	mfaHelpStepOne,
	mfaHelpStepTwo
};
