import { Avatar } from 'components/UI/Interactables/Avatar/Avatar';
import { UserResult } from './UserSearchInput';
import { DepartmentIcon, OrganizationIcon } from '@icons';

export function UserCard(props: { user: UserResult; active?: boolean }) {
	const {
		userFirstName,
		userSirName,
		userid,
		emailAddress,
		phoneNumber,
		organization,
		department
	} = props.user;

	const name = `${userFirstName} ${userSirName}`;
	return (
		<div className={`flex items-center gap-6 p-6 ${props.active ? 'bg-blue-50' : ''}`}>
			<div className="flex-shrink-0">
				<Avatar
					size={s => s.m}
					userId={userid}
					data={{
						userFirstName,
						userSirName
					}}
				/>
			</div>
			<div className="flex flex-col text-sm gap-1">
				<div>
					<span className="font-bold text-sm inline-block">{name}</span>
					{phoneNumber && <span>{` (${phoneNumber})`}</span>}
				</div>
				<span>{emailAddress}</span>

				<div className="mt-1 flex gap-[1.6rem]">
					<div className="flex items-center gap-2">
						<OrganizationIcon height={20} width={20} />
						<span>{organization}</span>
					</div>

					{department && (
						<div className="flex items-center gap-2">
							<DepartmentIcon height={20} width={20} />
							<span>{department}</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
