import { useQuery } from '@tanstack/react-query';
import { makeRequest } from '../makeRequest';
import { StatusDefinition } from '../../types';

export type GetStatusesResponse = {
	statuses: StatusDefinition[];
};

export const useGetStatusesQuery = ({ projectId }: { projectId: string }) => {
	return useQuery({
		queryKey: ['statuses', projectId],
		queryFn: () =>
			makeRequest<GetStatusesResponse>({
				method: 'getStatuses',
				service: 'stats',
				data: {
					projectId: parseInt(projectId)
				}
			})
	});
};

export const NONE_STATUS_NAME = 'none_generated_by_ledidi';
