import { ProjectStatus } from 'types/data/projects/constants';
import { useMatchProjects } from 'hooks/navigation';
import { LocalOperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { setActiveStatus } from 'store/data/projects';

export function useActiveProjectStatus(): LocalOperationResult<
	ProjectStatus | null,
	(status: ProjectStatus | null) => void
> {
	const matchProjects = useMatchProjects();
	const dispatch = useDispatch();
	const data = useSelector(state => state.data.projects.filters.projects.status);

	function handler(status: ProjectStatus | null) {
		dispatch(setActiveStatus({ status, isProject: matchProjects }));
	}
	return [data, handler];
}
