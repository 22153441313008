import { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { VariableSet } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import { DraggableVariableCardType } from 'store/data/variables';
import { SetState } from 'types/index';
import { VariableCardPayload } from '../../../Cards/CommonCard';
import { VariableSetConfirmDragAndDropActionModal } from 'components/Variables/VariablesGrid/ConfirmDragAndDropActionModal';
import { Svgs } from 'environment';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { Button } from 'components/UI/Interactables/Button';
import {
	isVariableOrderItem,
	isGroupOrderItem,
	groupHasForbiddenVariableType,
	itemIsType
} from 'helpers/variables';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useProjectId,
	useVariables,
	useAddVariableToSet,
	useAddVariableGroupToSet,
	useImportVariableSet
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';

interface Props {
	variableSet: VariableSet;
	setShowEmptySetHover: SetState<boolean>;
	setIsOverCurrent: SetState<boolean>;
}

// Need to fully remove this component from the DOM and add it back in order
// for it's ref to fully reset

export function VariableSetDrawerItemsDropzone({
	variableSet,
	setShowEmptySetHover,
	setIsOverCurrent
}: Props) {
	const { translate } = useTranslation();
	const { setError: setErrorNotification } = useAlerts();
	const { navigate, routes } = useNavigation();
	const [projectId] = useProjectId();
	const [
		{
			data: { variablesMap, groupsMap }
		}
	] = useVariables();

	const { setName, setOrder } = variableSet;

	const variableSetItemNames: string[] = setOrder.map(item => {
		if (isVariableOrderItem(item)) return item.variable;

		if (isGroupOrderItem(item)) return item.group;

		return '';
	});

	const [, addVariableToSet] = useAddVariableToSet();
	const [, addVariableGroupToSet] = useAddVariableGroupToSet();

	const [, { setIsImportVariableSet, setImportVariableSetName }] = useImportVariableSet();

	const [confirmAction, setConfirmAction] = useState<VariableCardPayload | null>(null);
	const confirmActionModal = {
		open: (item: VariableCardPayload) => setConfirmAction(item),
		close: () => setConfirmAction(null)
	};

	const [{ isOverCurrent, isSysGenVariable }, drop] = useDrop<any, any, any>({
		accept: [DraggableVariableCardType.Variable, DraggableVariableCardType.Group],
		collect: monitor => {
			const draggedVariable: VariableCardPayload = monitor.getItem();
			const isSysGenVariable = draggedVariable && draggedVariable.systemGenerated;
			return {
				isOverCurrent: monitor.isOver({ shallow: true }),
				isSysGenVariable
			};
		},
		drop: (item: VariableCardPayload, monitor) => {
			if (!monitor.isOver({ shallow: true })) return;
			if (isSysGenVariable)
				return setErrorNotification({
					message: translate(dict => dict.variablesPage.errorVariableToSeries)
				});

			// PRJCTS-7037: https://ledidi.atlassian.net/browse/PRJCTS-7037
			if (item.isGroup) {
				const preventDrop = groupHasForbiddenVariableType(
					groupsMap[item.name],
					variablesMap,
					[VariableType.File]
				);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}

				if (groupsMap[item.name].variablesBelongingToGroup.length === 0) {
					return setErrorNotification({
						message: translate(dict => dict.variables.move.emptyGroup)
					});
				}
			} else {
				const preventDrop = itemIsType(variablesMap[item.name], [VariableType.File]);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			}
			confirmActionModal.open(item);
		},
		hover: (item: VariableCardPayload, monitor) => {
			//  ALLOW ONLY VARIABLES AND GROUPS FROM OUTSIDE THE VARIABLE SET
			if (item.parentGroup || item.parentSet) return;

			if (monitor.isOver({ shallow: true })) setShowEmptySetHover(true);
		}
	});

	useEffect(() => {
		setIsOverCurrent(isOverCurrent);
	}, [isOverCurrent]);

	function handleDrop(item: VariableCardPayload) {
		const isItemInCurrentSet = variableSetItemNames.includes(item.name);

		if (isItemInCurrentSet) return;

		// GROUP CARD
		if (item.isGroup) {
			return addVariableGroupToSet({
				groupName: item.name,
				setName
			});
		}

		// VARIABLE CARD
		addVariableToSet({
			variableName: item.name,
			setName
		});
	}

	function onImportVariableSet() {
		if (projectId) navigate(routes.projects.import(projectId));
		setIsImportVariableSet(true);
		setImportVariableSetName(variableSet.setName);
	}

	return (
		<>
			<div
				ref={drop}
				style={{
					flex: 1,
					overflowY: 'auto',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				{setOrder.length === 0 && (
					<Flex column fullWidth wrap paddingOffset={{ x: 1.6 }} align={a => a.center}>
						<Svgs.ImportCreateVariables />
						<Typography.Paragraph fontweight={f => f.medium}>
							{translate(
								dict =>
									dict.importVariableSet.editVariableSetDrawer
										.importVariableSetTitle
							)}
						</Typography.Paragraph>
						<Typography.Caption marginOffset={{ top: 0.8 }}>
							{translate(
								dict =>
									dict.importVariableSet.editVariableSetDrawer
										.importVariableSetDescription
							)}
						</Typography.Caption>
						<Button
							variant={v => v.link}
							title={translate(
								dict =>
									dict.importVariableSet.editVariableSetDrawer
										.importVariableSetButton
							)}
							marginOffset={{ top: 1.6 }}
							onClick={onImportVariableSet}
						/>
					</Flex>
				)}
			</div>

			{/* CONFIRM DRAG-N-DROP ACTION MODAL */}
			{confirmAction && (
				<VariableSetConfirmDragAndDropActionModal
					item={confirmAction}
					direction="to"
					onConfirm={() => handleDrop(confirmAction)}
					onClose={confirmActionModal.close}
				/>
			)}
		</>
	);
}
