import clsx from 'clsx';
import { PropsWithChildren, ButtonHTMLAttributes } from 'react';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	onClick: () => void;
	className?: string;
}

export const IconButton = ({
	children,
	onClick,
	className,
	...props
}: PropsWithChildren<Props>) => {
	return (
		<button
			type="button"
			className={clsx('rounded-full hover:bg-gray-500/10 p-4', className)}
			onClick={onClick}
			{...props}
		>
			{children}
		</button>
	);
};
