import { useQuery } from '@tanstack/react-query';
import { makeRequest } from 'features/entry-form-v2/data/makeRequest';

export const useGetUserQuery = () =>
	useQuery<{ user: User }>({
		queryKey: ['user'],
		queryFn: () =>
			makeRequest({
				service: 'user',
				method: 'getUser'
			})
	});

type User = {
	userid: string;
	userFirstName: string;
	userSirName: string;
	emailAddress: string;
	organization: string;
	department: string;
	phoneNumber: string;
};
