import { z } from 'zod';

/**
 * THROTTLE FAST ACTIONS - SAVE A LOT OF API CALLS
 */
export function throttleActivity({ timeout }: { timeout: number }) {
	const lastThrottledActivity = { id: '' };

	function register({ activityId }: { activityId: string }) {
		lastThrottledActivity.id = activityId;
	}

	function throttle({ activityId, callback }: { activityId: string; callback: () => void }) {
		setTimeout(() => {
			if (lastThrottledActivity.id === activityId) callback();
		}, timeout);
	}

	return { register, throttle };
}

/**
 * Should be used when passing params that are typeof `array` or `object`
 * otherwise the memoization wont work.
 *
 * `reselect` library uses shallow equality comparison between previous and next values
 */
export const selectParams = {
	/**
	 * JSON stringifies the `params`
	 */
	encode: <T>(params: T): string => JSON.stringify(params),
	/**
	 * JSON parses the `params` and casts to the correct type
	 */
	decode: <T = never>(params: string): T => JSON.parse(params)
};

/**
 * executes asnychronous functions in sequence
 * @param funcs array of functions that return promises
 * @returns promise with results of the fulfilled functions
 * @example
 * const apiCall1 = async () => api.get(...);
 * const apiCall2 = async () => api.get(...);
 *
 * const calls = [apiCall1, apiCall2]
 * const [result1, result2] = await executePromisesInSequence(calls);
 */
export const executePromisesInSequence = <T = any | void>(funcs: (() => Promise<T>)[]) =>
	funcs.reduce<Promise<T[]>>(
		(promise, func) => promise.then(result => func().then(Array.prototype.concat.bind(result))),
		Promise.resolve([])
	);

const errorWithMessageSchema = z.object({
	message: z.string()
});

const isErrorWithMessage = (error: unknown): error is { message: string } =>
	errorWithMessageSchema.safeParse(error).success;

const errorWithConstructorMessageSchema = z.object({
	constructor: z.function()
});

const isErrorWithConstructorMessage = (
	error: unknown
): error is { constructor: { message: string } } =>
	errorWithConstructorMessageSchema.safeParse(error).success &&
	'message' in (error as { constructor: Record<string, unknown> }).constructor &&
	typeof (error as { constructor: Record<string, unknown> }).constructor.message === 'string';

export const unknownErrorMessage = 'Unknown error';

export function getMessageFromError(error: unknown) {
	if (typeof error === 'string') {
		return error;
	}
	if (isErrorWithMessage(error) && error.message.length > 0) {
		return error.message;
	}
	if (isErrorWithConstructorMessage(error) && error.constructor.message.length > 0) {
		return error.constructor.message;
	}
	return unknownErrorMessage;
}
