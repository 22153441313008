import { useState } from 'react';
import { makeRequest } from './makeRequest';

export const useDeleteDataEntryMutation = () => {
	const [data, setData] = useState<{
		wasDeleted: true;
	}>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error>();

	async function mutate({
		projectId,
		entryId,
		setName
	}: {
		projectId: string;
		entryId: string;
		setName?: string;
	}) {
		setLoading(true);

		try {
			await makeRequest({
				method: 'deleteDataEntry',
				service: 'cde',
				data: {
					projectId: parseInt(projectId),
					datasetentryid: entryId,

					...(setName ? { set: { setName } } : {})
				}
			});

			setData({ wasDeleted: true });
		} catch (e) {
			setError(e as Error);

			throw e;
		} finally {
			setLoading(false);
		}
	}

	return { data, loading, error, mutate };
};
