import { useEffect, useState } from 'react';
import {
	ForgotPasswordForm,
	LoginForm,
	NewPasswordForm,
	OneTimePasswordForm,
	ResetPasswordForm,
	SetupAccountInfoForm,
	SetupOneTimePasswordForm,
	HickupsWarning
} from 'components/Login';
import { Images, Svgs } from 'environment';
import { StorageKeys } from 'types/index';
import { LoginSteps } from 'store/auth';
import { Container, Card, ChromeLogo, LogoWrapper } from './LoginPage.style';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { useNavigation } from 'hooks/navigation';
import { useTranslation, useResetStore, useAccount, useLogin, useLoginStep } from 'hooks/store';
import { useTracking } from 'app/tracking/TrackingProvider';
import { safeClearLocalStorage } from 'features/helpers/safeClearLocalStorage';

export function LoginPage() {
	const { routes, replace } = useNavigation();
	const { translate } = useTranslation();
	const { track } = useTracking();

	const resetStore = useResetStore();
	const [
		{
			data: { details: account, isProfileIncomplete },
			fetched: isAccountFetched
		}
	] = useAccount();
	const [{ data: loggedIn }] = useLogin();
	const [loginStep] = useLoginStep();

	const [step, setStep] = useState<LoginSteps | null>(loginStep);

	const {
		newPassword,
		forgotPassword,
		resetPassword,
		oneTimePassword,
		setupOneTimePassword,
		setupAccountInfo
	} = LoginSteps;

	useEffect(() => {
		loginStep && setStep(loginStep);
	}, [loginStep]);

	useEffect(() => {
		if (loggedIn && isAccountFetched) {
			if (isProfileIncomplete) {
				localStorage.setItem(StorageKeys.LoginStep, setupAccountInfo);
				setStep(setupAccountInfo);
			} else {
				localStorage.removeItem(StorageKeys.LoginStep);
				replace(routes.projects.list);

				track({
					eventName: 'login'
				});
			}
		}
	}, [loggedIn, isAccountFetched, isProfileIncomplete]);

	useEffect(() => {
		if (localStorage.getItem('SSOProvider')) {
			// We don't want to clear the local storage if the SSOProvider
			// is set, we need this to know which federated login provider we are using in the
			// the amplify.ts
			return;
		}
		if (!loggedIn || !isProfileIncomplete) safeClearLocalStorage();
	}, []);

	function handleBack() {
		resetStore();
		setStep(null);
	}

	function isStepActive(value: LoginSteps) {
		return step === value;
	}

	return (
		<>
			<HickupsWarning />

			<Container>
				<Card>
					<LogoWrapper>
						<Svgs.LedidiLogoLogin style={{ height: 30 }} />
					</LogoWrapper>

					{!step && <LoginForm />}
					{isStepActive(newPassword) && <NewPasswordForm handleBack={handleBack} />}
					{isStepActive(forgotPassword) && <ForgotPasswordForm handleBack={handleBack} />}
					{isStepActive(resetPassword) && <ResetPasswordForm handleBack={handleBack} />}
					{isStepActive(oneTimePassword) && (
						<OneTimePasswordForm handleBack={handleBack} />
					)}
					{isStepActive(setupOneTimePassword) && (
						<SetupOneTimePasswordForm handleBack={handleBack} />
					)}
					{isStepActive(setupAccountInfo) && account && (
						<SetupAccountInfoForm account={account} />
					)}

					{!step && (
						<Flex align={a => a.center} marginOffset={{ top: 10 }}>
							<ChromeLogo
								src={Images.chrome}
								alt={translate(dict => dict.login.chromeLogoAlt)}
							/>
							<Typography.Caption marginOffset={{ left: 0.8 }} uppercase>
								{translate(dict => dict.login.browserInfo)}
							</Typography.Caption>
						</Flex>
					)}
				</Card>
			</Container>
		</>
	);
}
