import { useActivity } from 'hooks/store';
import { OperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { ActionTypes, getProject, selectProject, Project } from 'store/data/projects';

interface Handlers {
	handler: () => void;
	resetError: () => void;
}

type Data = Project | null;

export function useProject(): OperationResult<Data, Handlers> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectProject(state.data.projects));
	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_PROJECT);

	function handler() {
		dispatch(getProject());
	}

	const handlers: Handlers = {
		handler,
		resetError
	};

	return [{ data, loading, error }, handlers];
}
