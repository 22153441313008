import { cloneDeep } from 'lodash';

import { prepareApiForm } from 'helpers/forms';
import { navigationHubEvent } from 'helpers/navigation';
import { hydrateData } from 'store/data/variables';
import { Thunk, ActionPayload } from 'store/types';
import { createActivity } from 'store/ui/activities';
import { StringMap } from 'types/index';

import {
	ActionTypes,
	GetFormAction,
	GetFormsAction,
	CreateFormAction,
	UpdateFormAction,
	AddFormElementAction,
	OrderFormElementAction,
	RemoveFormElementAction,
	SetFormElementOrientationAction,
	SetFormElementTypeAction,
	SetFormElementLabelAction,
	SetFormElementTextAction,
	ToggleFormTitleAction,
	SetFormTitleAction,
	ResetFormAction,
	ToggleActiveFormAction,
	DeactivateFormsAction,
	SetFormVariableSearchTermAction,
	AppendFormElementAction,
	RenameFormAction,
	SetFormIdAction,
	DeleteFormAction,
	GroupFormElementsAction,
	UnGroupFormElementsAction,
	AddFormGroupAction,
	AppendFormGroupAction,
	RemoveFormGroupAction,
	SetFormGroupLabelAction,
	ActivateFormAction,
	DeactivateFormAction,
	MoveFormAction,
	AppendFormSetAction,
	RemoveFormSetAction,
	AddFormSetAction,
	SetFormSetLabelAction,
	SetFormsFiltersAction,
	SetFormsSectionCollapsedAction,
	SetRefetchFormsAction,
	Form,
	GetLatestFormAction,
	SetFormElementDisplayTypeAction,
	SetFormElementSliderValuesAction,
	SetFormElementSliderTypeAction,
	SetFormElementScaleIntervalAction,
	SetFormElementDisplaySelectedValueAction,
	SetFormElementMapValuesWithMoodsAction,
	SetFormErrorsAction
} from './types';
import { buildVariablesDataFromStoreData, getMapDifferences } from 'helpers/variables';
import { getMessageFromError, throttleActivity, unknownErrorMessage } from 'store/utils';
import { track } from 'app/tracking/TrackingProvider';

/*
	THE AMOUNT OF TIME IN MILLISECONDS TO CALL `callback` FUNCTION AFTER AN ACTION HAS FINISHED
	IF NO OTHER SIMILAR ACTION HAS FIRED WITHIN THE TIME IN MILLISECONDS
	--- NOTE: TO BE ADJUSTED ACCORDING TO UX FEEDBACK ---
*/
const throttleTimeouts = {
	movements: 1000
};

const throttleMovementActivities = throttleActivity({ timeout: throttleTimeouts.movements });

export const getFormAction = (payload: ActionPayload<GetFormAction>): GetFormAction => ({
	type: ActionTypes.GET_FORM,
	payload
});

export const getForm = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_FORM, dispatch });

	try {
		activity.begin();

		const {
			data: {
				projects: { projectId },
				forms: { formId }
			}
		} = getState();

		if (projectId && formId) {
			const form = await context.api.data.forms().getForm({
				projectId: Number(projectId),
				formId: Number(formId)
			});

			dispatch(getFormAction({ form }));
		}
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
		});
	} finally {
		activity.end();
	}
};

export const getFormsAction = (payload: ActionPayload<GetFormsAction>): GetFormsAction => ({
	type: ActionTypes.GET_FORMS,
	payload
});

export const getForms =
	(abortController?: AbortController): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_FORMS, dispatch });

		const {
			data: {
				projects: { projectId }
			}
		} = getState();

		try {
			activity.begin({ payload: { projectId } });

			if (projectId) {
				const { formsData } = await context.api.data.forms().getForms(
					{
						projectId: Number(projectId)
					},
					{ signal: abortController?.signal }
				);

				dispatch(getFormsAction({ projectId, formsData }));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				payload: { projectId },
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

const createFormAction = (payload: ActionPayload<CreateFormAction>): CreateFormAction => ({
	type: ActionTypes.CREATE_FORM,
	payload
});

export const createForm =
	(form: Form): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.CREATE_FORM, dispatch });

		try {
			activity.begin();

			const { projectId } = getState().data.forms;

			if (projectId) {
				const apiForm = prepareApiForm(form);

				// Delete generated `form.id`
				// @ts-ignore
				delete apiForm.formId;

				const { formId: newFormId } = await context.api.data.forms().createForm({
					projectId: Number(projectId),
					...apiForm,
					...(form.setName !== undefined && { set: { setName: form.setName } })
				});

				track({
					eventName: 'form_created'
				});

				// Apply real `form.id`
				const newForm = cloneDeep(form);
				newForm.id = newFormId;

				dispatch(createFormAction({ form: newForm }));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// GET FRESH LIST
			dispatch(getForms());
		}
	};

const updateFormAction = (): UpdateFormAction => ({
	type: ActionTypes.UPDATE_FORM
});

export const updateForm = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.UPDATE_FORM, dispatch });

	try {
		activity.begin();

		const { projectId, byId, formId } = getState().data.forms;

		if (projectId && formId && byId[formId]) {
			const form = byId[formId].current;
			const apiForm = prepareApiForm(form);

			await context.api.data.forms().updateForm({
				projectId: Number(projectId),
				...apiForm,
				...(form.setName !== undefined && { set: { setName: form.setName } })
			});

			dispatch(updateFormAction());
		}
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
		});
	} finally {
		activity.end();

		// GET FRESH LIST
		dispatch(getForms());
	}
};

export const renameFormAction = (payload: ActionPayload<RenameFormAction>): RenameFormAction => ({
	type: ActionTypes.RENAME_FORM,
	payload
});

export const renameForm =
	(formName: string, formId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.RENAME_FORM, dispatch });

		try {
			activity.begin();

			const { projectId, byId } = getState().data.forms;

			if (projectId && byId[formId]) {
				const form = cloneDeep(byId[formId].current);

				form.name = formName;

				const apiForm = prepareApiForm(form);

				await context.api.data.forms().updateForm({
					projectId: Number(projectId),
					...apiForm,
					...(form.setName !== undefined && { set: { setName: form.setName } })
				});

				dispatch(renameFormAction({ formName, formId }));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// GET FRESH LIST
			dispatch(getForms());
		}
	};

export const deleteFormAction = (payload: ActionPayload<DeleteFormAction>): DeleteFormAction => ({
	type: ActionTypes.DELETE_FORM,
	payload
});

export const deleteForm =
	(formId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.DELETE_FORM, dispatch });

		try {
			activity.begin();

			const { projectId, byId } = getState().data.forms;

			if (projectId) {
				const form = byId[formId].initial;

				await context.api.data.forms().deleteForm({
					projectId: Number(projectId),
					formId: Number(formId),
					...(form.setName !== undefined && { set: { setName: form.setName } })
				});

				dispatch(deleteFormAction({ formId }));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// GET FRESH LIST
			dispatch(getForms());
		}
	};

const deactivateFormsAction = (
	payload: ActionPayload<DeactivateFormsAction>
): DeactivateFormsAction => ({
	type: ActionTypes.DEACTIVATE_FORMS,
	payload
});

export const deactivateForms =
	(formIds: string[]): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.DEACTIVATE_FORMS, dispatch });

		try {
			activity.begin();

			const { projectId } = getState().data.forms;

			if (projectId) {
				await context.api.data.forms().deactivateForms({
					projectId: Number(projectId),
					formIds
				});

				dispatch(deactivateFormsAction({ formIds }));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

const deactivateFormAction = (
	payload: ActionPayload<DeactivateFormAction>
): DeactivateFormAction => ({
	type: ActionTypes.DEACTIVATE_FORM,
	payload
});

const activateFormAction = (payload: ActionPayload<ActivateFormAction>): ActivateFormAction => ({
	type: ActionTypes.ACTIVATE_FORM,
	payload
});

export const toggleFormById =
	(formId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.TOGGLE_ACTIVE_FORM, dispatch });

		try {
			activity.begin({ payload: formId });

			const { byId, projectId } = getState().data.forms;

			const form = byId[formId].initial;

			if (form.active) {
				await context.api.data.forms().deactivateForms({
					projectId: Number(projectId),
					formIds: [form.id]
				});

				dispatch(deactivateFormAction({ formId }));
			} else {
				await context.api.data.forms().activateForms({
					projectId: Number(projectId),
					formIds: [form.id]
				});

				dispatch(activateFormAction({ formId }));
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

const moveFormAction = (payload: ActionPayload<MoveFormAction>): MoveFormAction => ({
	type: ActionTypes.MOVE_FORM,
	payload
});

export const moveForm =
	(payload: ActionPayload<MoveFormAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.MOVE_FORM, dispatch });

		const { formId, sourceIndex, destinationIndex } = payload;

		try {
			activity.begin({ payload: formId });

			// REGISTER ACTIVITY TO THROTTLE
			throttleMovementActivities.register({ activityId: activity.id });

			const { projectId, byId, byProjectId } = getState().data.forms;

			if (projectId && projectId in byProjectId) {
				const form = byId[formId].current;
				const { ids, bySetName } = byProjectId[projectId];

				const computedIds = form.setName ? bySetName[form.setName].ids : ids;

				const isSourceIndexCorrect = computedIds.indexOf(formId) === sourceIndex;

				// Incorrect source index
				if (!isSourceIndexCorrect) throw new Error();

				// PREDICTIVE UPDATES (IMPROVE UX)
				dispatch(moveFormAction(payload));

				await context.api.data.forms().moveForm({
					projectId: Number(projectId),
					formId: Number(formId),
					sourceIndex,
					destinationIndex,
					...(form.setName !== undefined && { set: { setName: form.setName } })
				});
			}
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				payload: formId,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttleMovementActivities.throttle({
				activityId: activity.id,
				callback: () => dispatch(getForms())
			});
		}
	};

const getLatestFormAction = (payload: ActionPayload<GetLatestFormAction>): GetLatestFormAction => ({
	type: ActionTypes.GET_LATEST_FORM,
	payload
});

/**
 * Fetches the latest form version
 *
 * Calls `hydrateData` in case it detects created variables extracted from the form.
 *
 * Handles deleted form case -> trigger error + cleanup store
 */
export const getLatestForm = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_LATEST_FORM, dispatch });

	try {
		activity.begin();

		const {
			projects: { projectId },
			forms: { formId, byId: formsById },
			variables: { byProjectId: variablesByProjectId }
		} = getState().data;

		if (projectId && formId) {
			const formSetName = formId in formsById ? formsById[formId].initial.setName : undefined;

			const storeVariablesData = variablesByProjectId[projectId].initial;
			const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

			const form = await context.api.data.forms().getForm({
				projectId: Number(projectId),
				formId: Number(formId),
				...(formSetName !== undefined && { set: { setName: formSetName } }),
				callbacks: {
					errorCallback: () => {
						activity.error({
							error: `Form does not exist anymore`,
							toast: { display: true, message: `Form does not exist anymore` }
						});

						dispatch(deleteFormAction({ formId }));
						dispatch(setFormId({ formId: null }));
						navigationHubEvent().dispatch({
							replace: ({ routes }) => routes.projects.forms.list(projectId)
						});
					}
				}
			});

			// Apply `setName` because the BE does not return the reference
			if (formSetName !== undefined) form.setName = formSetName;

			const initialVariableNamesMap = Object.keys(
				variablesData.variablesMap
			).reduce<StringMap>((acc, variableName) => {
				acc[variableName] = variableName;

				return acc;
			}, {});
			const variableNamesMap = form.usedVariables.reduce<StringMap>((acc, variableName) => {
				acc[variableName] = variableName;

				return acc;
			}, {});

			const initialGroupNamesMap = Object.keys(variablesData.groupsMap).reduce<StringMap>(
				(acc, groupName) => {
					acc[groupName] = groupName;

					return acc;
				},
				{}
			);
			const groupNamesMap = form.usedGroups.reduce<StringMap>((acc, groupName) => {
				acc[groupName] = groupName;

				return acc;
			}, {});

			const initialSetNamesMap = Object.keys(variablesData.variableSetsMap).reduce<StringMap>(
				(acc, setName) => {
					acc[setName] = setName;

					return acc;
				},
				{}
			);
			const setNamesMap = form.usedSets.reduce<StringMap>((acc, setName) => {
				acc[setName] = setName;

				return acc;
			}, {});

			const variableNamesDifferences = getMapDifferences(
				initialVariableNamesMap,
				variableNamesMap
			);
			const groupNamesDifferences = getMapDifferences(initialGroupNamesMap, groupNamesMap);
			const setNamesDifferences = getMapDifferences(initialSetNamesMap, setNamesMap);

			/**
			 * if [variables | sets] got created => hydrate data
			 */
			if (
				variableNamesDifferences.created.length ||
				groupNamesDifferences.created.length ||
				setNamesDifferences.created.length
			) {
				await dispatch(hydrateData());
			}

			dispatch(getLatestFormAction({ form }));
		}
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
		});
	} finally {
		activity.end();
	}
};

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const setFormId = (payload: ActionPayload<SetFormIdAction>): SetFormIdAction => ({
	type: ActionTypes.SET_FORM_ID,
	payload
});

export const resetForm = (): ResetFormAction => ({
	type: ActionTypes.RESET_FORM
});

export const setFormSearchTerm = (
	payload: ActionPayload<SetFormVariableSearchTermAction>
): SetFormVariableSearchTermAction => ({
	type: ActionTypes.SET_FORM_VARIABLE_SEARCH_TERM,
	payload
});

export const setFormsFilters = (
	payload: ActionPayload<SetFormsFiltersAction>
): SetFormsFiltersAction => ({
	type: ActionTypes.SET_FORMS_FILTERS,
	payload
});

export const setFormsSectionCollapsed = (
	payload: ActionPayload<SetFormsSectionCollapsedAction>
): SetFormsSectionCollapsedAction => ({
	type: ActionTypes.SET_FORMS_SECTION_COLLAPSED,
	payload
});

export const setRefetchForms = (): SetRefetchFormsAction => ({
	type: ActionTypes.SET_REFETCH_FORMS
});

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const addFormElement = (
	payload: ActionPayload<AddFormElementAction>
): AddFormElementAction => ({
	type: ActionTypes.ADD_FORM_ELEMENT,
	payload
});

export const addFormGroup = (payload: ActionPayload<AddFormGroupAction>): AddFormGroupAction => ({
	type: ActionTypes.ADD_FORM_GROUP,
	payload
});

export const addFormSet = (payload: ActionPayload<AddFormSetAction>): AddFormSetAction => ({
	type: ActionTypes.ADD_FORM_SET,
	payload
});

export const appendFormElement = (
	payload: ActionPayload<AppendFormElementAction>
): AppendFormElementAction => ({
	type: ActionTypes.APPEND_FORM_ELEMENT,
	payload
});

export const appendFormGroup = (
	payload: ActionPayload<AppendFormGroupAction>
): AppendFormGroupAction => ({
	type: ActionTypes.APPEND_FORM_GROUP,
	payload
});

export const appendFormSet = (
	payload: ActionPayload<AppendFormSetAction>
): AppendFormSetAction => ({
	type: ActionTypes.APPEND_FORM_SET,
	payload
});

export const removeFormElement = (
	payload: ActionPayload<RemoveFormElementAction>
): RemoveFormElementAction => ({
	type: ActionTypes.REMOVE_FORM_ELEMENT,
	payload
});

export const removeFormGroup = (
	payload: ActionPayload<RemoveFormGroupAction>
): RemoveFormGroupAction => ({
	type: ActionTypes.REMOVE_FORM_GROUP,
	payload
});

export const removeFormSet = (
	payload: ActionPayload<RemoveFormSetAction>
): RemoveFormSetAction => ({
	type: ActionTypes.REMOVE_FORM_SET,
	payload
});

export const groupFormElements = (
	payload: ActionPayload<GroupFormElementsAction>
): GroupFormElementsAction => ({
	type: ActionTypes.GROUP_FORM_ELEMENTS,
	payload
});

export const unGroupFormElements = (
	payload: ActionPayload<UnGroupFormElementsAction>
): UnGroupFormElementsAction => ({
	type: ActionTypes.UNGROUP_FORM_ELEMENTS,
	payload
});

export const orderFormElement = (
	payload: ActionPayload<OrderFormElementAction>
): OrderFormElementAction => ({
	type: ActionTypes.ORDER_FORM_ELEMENT,
	payload
});

export const setFormElementOrientation = (
	payload: ActionPayload<SetFormElementOrientationAction>
): SetFormElementOrientationAction => ({
	type: ActionTypes.SET_FORM_ELEMENT_ORIENTATION,
	payload
});

export const setFormElementType = (
	payload: ActionPayload<SetFormElementTypeAction>
): SetFormElementTypeAction => ({
	type: ActionTypes.SET_FORM_ELEMENT_TYPE,
	payload
});

export const setFormErrors = (
	payload: ActionPayload<SetFormErrorsAction>
): SetFormErrorsAction => ({
	type: ActionTypes.SET_FORM_ERRORS,
	payload
});

export const setFormElementLabel = (
	payload: ActionPayload<SetFormElementLabelAction>
): SetFormElementLabelAction => ({
	type: ActionTypes.SET_FORM_ELEMENT_LABEL,
	payload
});

export const setFormGroupLabel = (
	payload: ActionPayload<SetFormGroupLabelAction>
): SetFormGroupLabelAction => ({
	type: ActionTypes.SET_FORM_GROUP_LABEL,
	payload
});

export const setFormSetLabel = (
	payload: ActionPayload<SetFormSetLabelAction>
): SetFormSetLabelAction => ({
	type: ActionTypes.SET_FORM_SET_LABEL,
	payload
});

export const setFormElementDisplayType = (
	payload: ActionPayload<SetFormElementDisplayTypeAction>
): SetFormElementDisplayTypeAction => ({
	type: ActionTypes.SET_FORM_ELEMENT_DISPLAY_TYPE,
	payload
});

export const setFormElementSliderValues = (
	payload: ActionPayload<SetFormElementSliderValuesAction>
): SetFormElementSliderValuesAction => ({
	type: ActionTypes.SET_FORM_ELEMENT_SLIDER_VALUES,
	payload
});

export const setFormElementSliderType = (
	payload: ActionPayload<SetFormElementSliderTypeAction>
): SetFormElementSliderTypeAction => ({
	type: ActionTypes.SET_FORM_ELEMENT_SLIDER_TYPE,
	payload
});

export const setFormElementScaleInterval = (
	payload: ActionPayload<SetFormElementScaleIntervalAction>
): SetFormElementScaleIntervalAction => ({
	type: ActionTypes.SET_FORM_ELEMENT_SCALE_INTERVAL,
	payload
});

export const setFormElementDisplaySelectedValue = (
	payload: ActionPayload<SetFormElementDisplaySelectedValueAction>
): SetFormElementDisplaySelectedValueAction => ({
	type: ActionTypes.SET_FORM_ELEMENT_DISPLAY_SELECTED_VALUE,
	payload
});

export const setFormElementMapValuesWithMoods = (
	payload: ActionPayload<SetFormElementMapValuesWithMoodsAction>
): SetFormElementMapValuesWithMoodsAction => ({
	type: ActionTypes.SET_FORM_ELEMENT_MAP_VALUES_WITH_MOODS,
	payload
});

//DT
export const setFormElementText = (
	payload: ActionPayload<SetFormElementTextAction>
): SetFormElementTextAction => ({
	type: ActionTypes.SET_FORM_ELEMENT_TEXT,
	payload
});

export const toggleActiveForm = (): ToggleActiveFormAction => ({
	type: ActionTypes.TOGGLE_FORM_ACTIVE
});

export const toggleFormTitle = (): ToggleFormTitleAction => ({
	type: ActionTypes.TOGGLE_FORM_TITLE
});

export const setFormTitle = (payload: ActionPayload<SetFormTitleAction>): SetFormTitleAction => ({
	type: ActionTypes.SET_FORM_TITLE,
	payload
});
