export function extractFileExtension(fileName: string): string {
	// Return empty string if fileName is empty or doesn't contain a dot
	if (!fileName || !fileName.includes('.')) {
		return '';
	}

	// Split the filename by dots and get the last element
	// This handles cases like 'document.pdf' and 'my.complex.file.txt'
	const extension = fileName.split('.').pop();

	// Return the extension in lowercase, or empty string if somehow undefined
	return extension?.toLowerCase() || '';
}
