import { useQuery } from '@tanstack/react-query';
import { Entry, Variable } from '../types';
import { makeRequest } from './makeRequest';

export type GetRepeatingSetRowsV2Data = {
	dataRows: Entry[];
	variables: Variable[];
	totalCount: number;
};

export const useGetRepeatingSetRowsV2Query = ({
	entryId,
	projectId,
	setName
}: {
	entryId: string;
	projectId: string;
	setName: string;
}) => {
	return useQuery({
		queryKey: ['repeatingDataSetRows', entryId, setName],

		queryFn: () =>
			makeRequest<GetRepeatingSetRowsV2Data>({
				method: 'getRepeatingSetRowsV2',
				service: 'stats',

				data: {
					projectId: parseInt(projectId),
					parentDatasetEntryId: entryId,

					set: {
						setName
					},

					sorting: {
						variableName: 'lastmodifieddate',
						direction: 'ASC'
					}
				}
			})
	});
};
