import { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { variableCardPulse } from 'events/variables';
import { DraggableVariableCardType, MergeVariablesIntoGroup } from 'store/data/variables';
import { VariableCardPayload } from '../../../Cards/CommonCard';
import { VariableSetConfirmDragAndDropActionModal } from 'components/Variables/VariablesGrid/ConfirmDragAndDropActionModal';
import {
	CardReplacement,
	DropAbove,
	DropBelow,
	LineMarkerHorizontal,
	VariableCardContainer,
	HoverOver,
	Label,
	LoadingContainer
} from '../../../Cards/VariableCard/VariableCard.style';
import { Asterisk } from 'components/UI/Asterisk';
import { Flex } from 'components/UI/Flex';
import { Loader } from 'components/UI/Loader';
import { Typography } from 'components/UI/Typography';
import { useCustomEventListener } from 'helpers/events';
import { groupHasForbiddenVariableType, itemIsType } from 'helpers/variables';
import {
	useMoveGroup,
	useMoveVariable,
	useTranslation,
	useVariables,
	useMoveVariableBetweenSets,
	useAddVariableToSet,
	useAddVariableGroupToSet
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	index: number;
	name: string;
	title: string;
	subtitle: string;
	required: boolean;
	parentSet: string;
	readOnly: boolean;
	preventDrag: boolean;
	onClick: () => void;
	mergeVariables: MergeVariablesIntoGroup;
}

export function VariableCardInSet({
	index,
	name,
	title,
	subtitle,
	required,
	parentSet,
	readOnly,
	preventDrag,
	onClick,
	mergeVariables
}: Props) {
	const { setError: setErrorNotification } = useAlerts();
	const [, moveGroup] = useMoveGroup();
	const [, moveVariable] = useMoveVariable();
	const { translate } = useTranslation();
	const [
		{
			data: { variablesMap, groupsMap }
		}
	] = useVariables();

	const [{ loading: movingVariableBetweenSets }] = useMoveVariableBetweenSets({
		variableName: name
	});
	const [{ loading: addingVariableToSet }, addVariableToSet] = useAddVariableToSet({
		variableName: name
	});
	const [, addVariableGroupToSet] = useAddVariableGroupToSet();

	const loading = addingVariableToSet || movingVariableBetweenSets;

	const mainRef = useRef<HTMLDivElement>(null);
	const aboveRef = useRef<HTMLDivElement>(null);
	const belowRef = useRef<HTMLDivElement>(null);

	const [dropFunctionToCall, setDropFunctionToCall] = useState<
		('handleDrop' | 'handleDropAbove' | 'handleDropBelow') | null
	>(null);

	const [confirmAction, setConfirmAction] = useState<VariableCardPayload | null>(null);
	const confirmActionModal = {
		open: (item: VariableCardPayload) => setConfirmAction(item),
		close: () => {
			setConfirmAction(null);
			setDropFunctionToCall(null);
		}
	};

	const [pulseCard, setPulseCard] = useState(false);

	useCustomEventListener(variableCardPulse, {
		onListen: payload => {
			if (payload.variableName === name) setPulseCard(true);
		}
	});

	// DRAG - CARD
	const [{ isDragging }, drag, dragPreview] = useDrag({
		type: DraggableVariableCardType.Variable,
		item: (): VariableCardPayload => ({ name, index, parentSet }),
		canDrag: !preventDrag,
		collect: monitor => ({ isDragging: monitor.isDragging() })
	});

	// DROP - CARD
	const [{ handlerId, isOverCurrent, isSysGenVariable }, drop] = useDrop<any, any, any>({
		accept: DraggableVariableCardType.Variable,
		collect: monitor => {
			const draggedVariable: VariableCardPayload = monitor.getItem();
			const isSysGenVariable = draggedVariable && draggedVariable.systemGenerated;
			return {
				handlerId: monitor.getHandlerId(),
				isOverCurrent: monitor.isOver({ shallow: true }),
				isSysGenVariable
			};
		},
		drop(item: VariableCardPayload, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			if (isSysGenVariable)
				return setErrorNotification({
					message: translate(dict => dict.variablesPage.errorVariableToSeries)
				});

			// PRJCTS-7037: https://ledidi.atlassian.net/browse/PRJCTS-7037
			if (item.isGroup) {
				const preventDrop = groupHasForbiddenVariableType(
					groupsMap[item.name],
					variablesMap,
					[VariableType.File]
				);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			} else {
				const preventDrop = itemIsType(variablesMap[item.name], [VariableType.File]);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			}

			if (shouldTriggerConfirmModal(item)) {
				confirmActionModal.open(item);
				setDropFunctionToCall('handleDrop');

				return;
			}

			handleDrop(item);
		}
	});

	// DROP - ABOVE
	const [{ aboveHandlerId, isOverCurrentAbove }, dropAbove] = useDrop<any, any, any>({
		accept: [DraggableVariableCardType.Variable, DraggableVariableCardType.Group],
		collect: monitor => ({
			aboveHandlerId: monitor.getHandlerId(),
			isOverCurrentAbove: monitor.isOver({ shallow: true })
		}),
		drop(item: VariableCardPayload, monitor) {
			if (!monitor.isOver({ shallow: true })) return;
			if (isSysGenVariable)
				return setErrorNotification({
					message: translate(dict => dict.variablesPage.errorVariableToSeries)
				});

			// PRJCTS-7037: https://ledidi.atlassian.net/browse/PRJCTS-7037
			if (item.isGroup) {
				const preventDrop = groupHasForbiddenVariableType(
					groupsMap[item.name],
					variablesMap,
					[VariableType.File]
				);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
				if (groupsMap[item.name].variablesBelongingToGroup.length === 0) {
					return setErrorNotification({
						message: translate(dict => dict.variables.move.emptyGroup)
					});
				}
			} else {
				const preventDrop = itemIsType(variablesMap[item.name], [VariableType.File]);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			}

			if (shouldTriggerConfirmModal(item)) {
				confirmActionModal.open(item);
				setDropFunctionToCall('handleDropAbove');

				return;
			}

			handleDropAbove(item);
		}
	});

	// DROP - BELOW
	const [{ belowHandlerId, isOverCurrentBelow }, dropBelow] = useDrop<any, any, any>({
		accept: [DraggableVariableCardType.Variable, DraggableVariableCardType.Group],
		collect: monitor => ({
			belowHandlerId: monitor.getHandlerId(),
			isOverCurrentBelow: monitor.isOver({ shallow: true })
		}),
		drop(item: VariableCardPayload, monitor) {
			if (!monitor.isOver({ shallow: true })) return;
			if (isSysGenVariable)
				return setErrorNotification({
					message: translate(dict => dict.variablesPage.errorVariableToSeries)
				});

			// PRJCTS-7037: https://ledidi.atlassian.net/browse/PRJCTS-7037
			if (item.isGroup) {
				const preventDrop = groupHasForbiddenVariableType(
					groupsMap[item.name],
					variablesMap,
					[VariableType.File]
				);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}

				if (groupsMap[item.name].variablesBelongingToGroup.length === 0) {
					return setErrorNotification({
						message: translate(dict => dict.variables.move.emptyGroup)
					});
				}
			} else {
				const preventDrop = itemIsType(variablesMap[item.name], [VariableType.File]);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			}

			if (shouldTriggerConfirmModal(item)) {
				confirmActionModal.open(item);
				setDropFunctionToCall('handleDropBelow');

				return;
			}

			handleDropBelow(item);
		}
	});

	function handleDrop(item: VariableCardPayload) {
		// CREATE GROUP OUT OF 2 VARIABLES

		// DROP VARIABLE CARD FROM WITHIN SET
		if (item.parentSet) {
			return mergeVariables({
				targetVariable: {
					index,
					name
				},
				draggedVariable: {
					index: item.index,
					name: item.name
				},
				setName: parentSet
			});
		}

		// DROP VARIABLE CARD FROM MAIN LIST
		confirmActionModal.close();

		mergeVariables({
			targetVariable: {
				index,
				name
			},
			draggedVariable: {
				index: item.index,
				name: item.name
			},
			setName: parentSet,
			from: {
				mainList: {
					variableName: item.name
				}
			}
		});
	}

	function handleDropAbove(item: VariableCardPayload) {
		const sourceIndex = item.index;
		const destinationIndex = index;

		// MOVE ITEMS INSIDE VARIABLE SET
		if (item.parentSet) {
			// GROUP CARD
			if (item.isGroup) {
				moveGroup({
					groupName: item.name,
					sourceIndex,
					destinationIndex,
					setName: parentSet
				});
			}
			// VARIABLE CARD
			else {
				moveVariable({
					variableName: item.name,
					sourceIndex,
					destinationIndex,
					setName: parentSet
				});
			}
			return;
		}

		// GROUP CARD
		if (item.isGroup) {
			addVariableGroupToSet({
				groupName: item.name,
				destinationIndex,
				setName: parentSet
			});
		}
		// VARIABLE CARD
		else {
			addVariableToSet({
				variableName: item.name,
				destinationIndex,
				setName: parentSet
			});
		}
	}

	function handleDropBelow(item: VariableCardPayload) {
		const sourceIndex = item.index;
		const destinationIndex = index < sourceIndex ? index + 1 : index;

		// MOVE ITEMS INSIDE VARIABLE SET
		if (item.parentSet) {
			// GROUP CARD
			if (item.isGroup) {
				moveGroup({
					groupName: item.name,
					sourceIndex,
					destinationIndex,
					setName: parentSet
				});
			}
			// VARIABLE CARD
			else {
				moveVariable({
					variableName: item.name,
					sourceIndex,
					destinationIndex,
					setName: parentSet
				});
			}
			return;
		}

		// GROUP CARD
		if (item.isGroup) {
			addVariableGroupToSet({
				groupName: item.name,
				destinationIndex: index + 1,
				setName: parentSet
			});
		}
		// VARIABLE CARD
		else {
			addVariableToSet({
				variableName: item.name,
				destinationIndex: index + 1,
				setName: parentSet
			});
		}
	}

	function handleFinalDrop(item: VariableCardPayload) {
		if (!dropFunctionToCall) return;

		if (dropFunctionToCall === 'handleDrop') handleDrop(item);
		if (dropFunctionToCall === 'handleDropAbove') handleDropAbove(item);
		if (dropFunctionToCall === 'handleDropBelow') handleDropBelow(item);
	}

	function shouldTriggerConfirmModal(item: VariableCardPayload) {
		if (item.parentSet) return false;

		return true;
	}

	drop(drag(mainRef));
	////////////////////
	dropAbove(aboveRef);
	dropBelow(belowRef);

	const isFirstItem = index === 0;

	if (isDragging) return <CardReplacement ref={dragPreview} />;

	return (
		<>
			<VariableCardContainer
				{...(pulseCard && {
					className: 'pulse-vibrant-green'
				})}
				onAnimationEnd={() => setPulseCard(false)}
				//
				ref={mainRef}
				isDragging={isDragging}
				data-handler-id={handlerId}
				isHovered={isOverCurrent}
				onClick={readOnly ? undefined : onClick}
				id={title?.replaceAll(' ', '').toLowerCase()}
				disabled={loading}
				readOnly={readOnly}
				data-scroll-id={name}
			>
				<Flex align={a => a.center}>
					<Typography.Paragraph fontweight={w => w.normal} title={title} ellipsis>
						{title}
					</Typography.Paragraph>
					{required && (
						<Typography.Paragraph>
							<Asterisk paddingLeft />
						</Typography.Paragraph>
					)}
				</Flex>
				<Flex>
					<Typography.Caption title={subtitle} ellipsis>
						{subtitle}
					</Typography.Caption>
				</Flex>

				<HoverOver systemGenerated={isSysGenVariable}>
					{!isSysGenVariable && (
						<Label>{translate(dict => dict.variablesPage.createGroup)}</Label>
					)}
				</HoverOver>

				{isFirstItem && (
					<DropAbove
						ref={aboveRef}
						data-handler-id={aboveHandlerId}
						isHovered={isOverCurrentAbove}
					>
						<LineMarkerHorizontal />
					</DropAbove>
				)}
				<DropBelow
					ref={belowRef}
					data-handler-id={belowHandlerId}
					isHovered={isOverCurrentBelow}
				>
					<LineMarkerHorizontal />
				</DropBelow>

				{loading && (
					<LoadingContainer>
						<Loader />
					</LoadingContainer>
				)}
			</VariableCardContainer>

			{/* CONFIRM DRAG-N-DROP ACTION MODAL */}
			{confirmAction && (
				<VariableSetConfirmDragAndDropActionModal
					item={confirmAction}
					direction="to"
					onConfirm={() => handleFinalDrop(confirmAction)}
					onClose={confirmActionModal.close}
				/>
			)}
		</>
	);
}
