import { ModulesPermissions } from 'components/Collaborators';
import { ModulesPermissions as ModulesPermissionsType } from 'store/data/collaborators';

interface Props {
	permissions: ModulesPermissionsType;
	disabled: boolean;
	isProjectOwner: boolean;
	isSelf: boolean;
	onChange: (permissions: ModulesPermissionsType) => void;
}

export function Views({ permissions, disabled, isProjectOwner, isSelf, onChange }: Props) {
	return (
		<ModulesPermissions
			permissions={permissions}
			disabled={disabled}
			isProjectOwner={isProjectOwner && isSelf}
			onChange={onChange}
		/>
	);
}
