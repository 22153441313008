export const ConfirmChanges = () => {
	return (
		<div className="flex flex-col">
			<h3 className="text-base font-bold">Review pending changes</h3>

			<p className="text-base mt-6">
				We now require you to confirm the changes you have made before exiting a form. The
				changes will be presented like this, and you can either save the changes, continue
				editing the form or discard the changes if you do not intend to keep them.
			</p>

			<img
				className="mt-6 w-1/2 self-center"
				src="/img/entry-form-1-5-change-log/review_pending_changes.png"
				alt="Showing the review pending changes dialog"
			/>
		</div>
	);
};
