import { makeRequest } from './makeRequest';
import { useQuery } from '@tanstack/react-query';

export type NamesByUserIds = Record<string, string>;
export type GetNamesFromUserIdsResponse = {
	namesFromUserIds: NamesByUserIds;
};

export const useGetNamesFromUserIdsQuery = (
	{
		userIds
	}: {
		userIds: string[];
	},
	{ enabled }: { enabled?: boolean } = { enabled: true }
) => {
	return useQuery({
		queryKey: ['namesFromUserIds', userIds],
		enabled,
		queryFn: () =>
			makeRequest<GetNamesFromUserIdsResponse>({
				method: 'getNamesFromUserIds',
				service: 'user',
				data: {
					userIds
				}
			})
	});
};
