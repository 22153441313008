import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { SelectMultiple } from 'components/UI/Interactables/SelectMultiple';
import { useTranslation } from 'hooks/store';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Offset } from 'types';

type Props = {
	items: { label: string; value: string }[];
	onApply: (values: string[]) => void;
	onClear: () => void;
	selectedItems: string[];
	setVisible: (visible: boolean) => void;
	tableRef: React.RefObject<HTMLTableElement>;
};

export const Filter = (props: Props) => {
	const { items, onApply, onClear, setVisible, tableRef } = props;

	const [selectedItems, setSelectedItems] = useState(props.selectedItems);
	const [position, setPosition] = useState<Offset | null>(null);
	const [filterRef, setFilterRef] = useState<HTMLDivElement | null>(null);
	const [open, setOpen] = useState(true);

	const { translate } = useTranslation();

	const onSelect = (item: { label: string; value: string }) => {
		if (selectedItems.includes(item.value)) {
			setSelectedItems(selectedItems.filter(value => value !== item.value));
		} else {
			setSelectedItems([...selectedItems, item.value]);
		}
	};

	useEffect(() => {
		if (filterRef) {
			calculatePosition(filterRef);
		}
	}, [filterRef]);

	function calculatePosition(ref: HTMLDivElement | null) {
		if (tableRef.current && ref) {
			const tablePos = tableRef.current.getBoundingClientRect();
			const refPos = ref.getBoundingClientRect();

			if (refPos.left + refPos.width > tablePos.right) {
				setPosition({ right: 0 });
				return;
			}

			setPosition({ left: 0 });
		}
	}

	return (
		<Floating
			ref={e => setFilterRef(e)}
			style={{ left: position?.left, right: position?.right }}
		>
			<Flex>
				<WideCollapsibleCard open={open} onToggle={() => setOpen(!open)}>
					<SelectMultiple
						items={items}
						selectedItems={selectedItems}
						onSelect={onSelect}
					/>

					<Flex marginOffset={{ top: 1.6 }} fullWidth>
						<Flex align={align => align.center}>
							<Button
								onClick={() => {
									onClear();
									setVisible(false);
								}}
								size="small"
								title={translate(state => state.projects.history.filter.clear)}
								variant={variant => variant.link}
							/>
						</Flex>
						<Flex fullWidth justify={j => j.end}>
							<Button
								size="small"
								onClick={() => setVisible(false)}
								title={translate(state => state.projects.history.filter.cancel)}
								variant={variant => variant.secondary}
								marginOffset={{ right: 1.6 }}
							/>
							<Button
								size="small"
								onClick={() => {
									onApply(selectedItems);
									setVisible(false);
								}}
								disabled={false}
								title={translate(state => state.projects.history.filter.apply)}
								variant={variant => variant.primary}
							/>
						</Flex>
					</Flex>
				</WideCollapsibleCard>
			</Flex>
		</Floating>
	);
};

const Floating = styled.div`
	position: absolute;
	min-width: 30rem;
	z-index: 99;
`;

const WideCollapsibleCard = styled(CollapsibleCard)`
	width: 100%;
`;
