import { useMatchProjects } from 'hooks/navigation';
import { LocalOperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { setTableFilters, TableFilters, selectProjectsTableFilters } from 'store/data/projects';

export function useProjectsTableFilters(): LocalOperationResult<
	TableFilters,
	(options: TableFilters) => void
> {
	const dispatch = useDispatch();

	const projectFilterData = useSelector(state => selectProjectsTableFilters(state.data.projects));
	const matchProjects = useMatchProjects();

	let data: TableFilters = { filters: [], pageIndex: 0, pageSize: 0, sortBy: [] };

	if (matchProjects) {
		data = projectFilterData;
	}

	function handler(options: TableFilters) {
		dispatch(setTableFilters({ options }));
	}

	return [data, handler];
}
