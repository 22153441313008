import { addMinutes, format } from 'date-fns';

import { DATE_TIME_FORMAT, DATE_TIME_PICKER_TIMEZONE_FORMAT } from 'consts';
import { debuggerLog } from 'helpers/generic';
import { zeroPadDate } from 'helpers/entries';

// SET TO `true` TO SEE THE LOGS
const DEBUGGER = false;
const log = debuggerLog(DEBUGGER);

export function convertToUTCTime(value: string | Date) {
	log('convertToUTCTime()', { value });

	const parsedValue = typeof value === 'string' ? zeroPadDate(value) : value;

	return new Date(parsedValue).toISOString().split('.')[0] + 'Z';
}

export function getDateTime(value: string) {
	log('getDateTime()', { value });
	const dateString = value.includes('GMT')
		? new Date(value).toISOString()
		: new Date(zeroPadDate(value)).toISOString();

	const localTime = convertToLocalTime(dateString);

	const [date, time] = localTime.split(' ');

	return { date, time };
}

function convertToLocalTime(value: string) {
	log('convertToLocalTime()', { value });
	const parsedValue = typeof value === 'string' ? zeroPadDate(value) : value;

	return format(new Date(parsedValue), DATE_TIME_PICKER_TIMEZONE_FORMAT);
}

export function convertToGlobalStartDate(date: number) {
	const dateType = new Date(date);
	const result = addMinutes(dateType, dateType.getTimezoneOffset());
	return format(result, DATE_TIME_FORMAT);
}
