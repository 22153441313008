import { StorageKeys } from 'types/index';
import { localLogIn, resetStore } from 'store/auth';
import { useDispatch, useEffectOnce } from 'hooks/utils';

export function useLoginSwitch() {
	const dispatch = useDispatch();

	function handleStorageChange({ key, newValue }: StorageEvent) {
		const isOnLoginPage =
			document.location.pathname.startsWith('/login') || document.location.pathname === '/';

		const canLoginAllTabs =
			key === StorageKeys.LoginCompleted && newValue === 'true' && isOnLoginPage;

		const canLogoutAllTabs =
			!(
				localStorage.getItem(StorageKeys.LoginCompleted) &&
				localStorage.getItem(StorageKeys.IdToken)
			) && !isOnLoginPage;

		// USER logged out -> LOGOUT SYNC ALL TABS
		if (canLogoutAllTabs) {
			dispatch(resetStore());
		}

		// USER logged in -> LOGIN SYNC ALL TABS
		if (canLoginAllTabs) {
			dispatch(localLogIn());
		}
	}

	useEffectOnce(() => {
		window.addEventListener('storage', handleStorageChange);

		return () => window.removeEventListener('storage', handleStorageChange);
	});
}
