import { Table } from 'components/UI/Table';
import { HistoryPageHeader } from './HistoryPageHeader';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Grid } from 'components/UI/Grid';
import { Pagination } from 'components/UI/Pagination';
import styled from 'styled-components';
import { Typography } from 'components/UI/Typography';
import { Spacer } from 'components/UI/Spacer';
import { usePagination, useTable, Row, Column } from 'react-table';
import {
	HistoryResponse,
	UserAction,
	UserActions,
	isSeries,
	useGetHistoryData
} from './useGetHistoryData';
import { useNavigation } from 'hooks/navigation/useNavigation';
import { Loader } from 'components/UI/Loader/Loader';
import { FilterIconButton } from './components/FilterIconButton';
import { toast } from 'react-toastify';
import { useTranslation } from 'hooks/store/ui/useTranslation';
import { makeRequest } from 'features/entry-form-v2/data/makeRequest';
import { LedidiRequestError } from 'features/entry-form-v2/data/makeRequest';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useEntryV15Release } from 'features/entry-form-v2/utils/isV1_5project';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';

export function HistoryPage() {
	const tableRef = useRef<HTMLTableElement | null>(null);
	const { id } = useParams();

	const [pageSize, setPageSize] = useState(10);
	const [pageIndex, setPageIndex] = useState(0);

	const { routes, navigate } = useNavigation();

	const [filterOptions, setFilterOptions] = useState<{ users: string[]; actions: string[] }>({
		users: [],
		actions: []
	});

	const { data, isError, isLoading, getHistory } = useGetHistoryData();

	const { translate } = useTranslation();

	const setFilteredUsers = (users: string[]) => {
		setFilterOptions(prev => ({ ...prev, users }));
		setPageIndex(0);
	};

	const setFilteredActions = (actions: string[]) => {
		setFilterOptions(prev => ({ ...prev, actions }));
		setPageIndex(0);
	};

	const entryV15Release = useEntryV15Release();
	const shouldRouteToV1_5 = entryV15Release === 'fullyEnabled';

	useEffect(() => {
		if (!id) return;

		const projectIdNumber = Number(id);

		getHistory({
			projectId: projectIdNumber,
			pageIndex,
			pageSize,
			filterOptions
		});
	}, [id, pageIndex, pageSize, filterOptions]);

	const columns = useMemo<Column<AuditEvent>[]>(
		() => [
			{
				Header: translate(state => state.projects.history.columns.timestamp),
				accessor: 'timestamp'
			},
			{
				Header: () => {
					return (
						<FilterableColumn>
							<span>{translate(state => state.projects.history.columns.user)}</span>

							<FilterIconButton
								tableRef={tableRef}
								items={
									data?.users.map(user => ({
										label: user.username,
										value: user.id
									})) ?? []
								}
								onApply={values => setFilteredUsers(values)}
								onClear={() => setFilteredUsers([])}
								selectedItems={filterOptions.users}
							/>
						</FilterableColumn>
					);
				},
				accessor: 'username'
			},
			{
				Header: () => {
					return (
						<FilterableColumn>
							<span>{translate(state => state.projects.history.columns.action)}</span>

							<FilterIconButton
								tableRef={tableRef}
								items={UserActions.filter(
									action => action !== UserAction.DEFAULT
								).map(action => ({
									label: formatAction(action),
									value: action.toString()
								}))}
								onApply={values => setFilteredActions(values)}
								onClear={() => setFilteredActions([])}
								selectedItems={filterOptions.actions}
							/>
						</FilterableColumn>
					);
				},
				accessor: 'userAction'
			},
			{
				Header: translate(state => state.projects.history.columns.originalId),
				accessor: 'originalId'
			},
			{
				Header: translate(state => state.projects.history.columns.revisionId),
				accessor: 'entryId'
			}
		],
		[data, filterOptions]
	);

	const formatAction = (action: AuditEvent['userAction']): string => {
		switch (action) {
			case UserAction.DEFAULT:
				return translate(state => state.projects.history.actions.DEFAULT);
			case UserAction.ENTRY_CREATION:
				return translate(state => state.projects.history.actions.ENTRY_CREATION);
			case UserAction.ENTRY_UPDATE:
				return translate(state => state.projects.history.actions.ENTRY_UPDATE);
			case UserAction.ENTRY_DELETION:
				return translate(state => state.projects.history.actions.ENTRY_DELETION);
			case UserAction.PROJECT_COPY:
				return translate(state => state.projects.history.actions.PROJECT_COPY);
			case UserAction.STATUS_UPDATE:
				return translate(state => state.projects.history.actions.STATUS_UPDATE);
			case UserAction.AGGREGATION_RECALCULATION:
				return translate(state => state.projects.history.actions.AGGREGATION_RECALCULATION);
			case UserAction.DEFAULT_STATUS_SET:
				return translate(state => state.projects.history.actions.DEFAULT_STATUS_SET);
			case UserAction.IMPORT_FULL:
				return translate(state => state.projects.history.actions.IMPORT_FULL);
			case UserAction.IMPORT_ADD_ENTRIES:
				return translate(state => state.projects.history.actions.IMPORT_ADD_ENTRIES);
			case UserAction.CALCULATED_VALUES_UPDATE:
				return translate(state => state.projects.history.actions.CALCULATED_VALUES_UPDATE);
			case UserAction.TIME_DURATION_RECALCULATION:
				return translate(
					state => state.projects.history.actions.TIME_DURATION_RECALCULATION
				);
			case UserAction.UNIQUE_VALUES_SET:
				return translate(state => state.projects.history.actions.UNIQUE_VALUES_SET);
			case UserAction.CATEGORY_VALUES_UPDATE:
				return translate(state => state.projects.history.actions.CATEGORY_VALUES_UPDATE);
			case UserAction.IMPORT_ADD_COLUMNS:
				return translate(state => state.projects.history.actions.IMPORT_ADD_COLUMNS);
			case UserAction.VARIABLE_TYPE_UPDATE:
				return translate(state => state.projects.history.actions.VARIABLE_TYPE_UPDATE);
			case UserAction.ENTRY_OWNERSHIP_TRANSFER:
				return translate(state => state.projects.history.actions.ENTRY_OWNERSHIP_TRANSFER);
			case UserAction.UNIQUE_VALUES_REGENERATION:
				return translate(
					state => state.projects.history.actions.UNIQUE_VALUES_REGENERATION
				);
			case UserAction.FILE_COPY:
				return translate(state => state.projects.history.actions.FILE_COPY);
			case UserAction.SERIES_DELETE:
				return translate(state => state.projects.history.actions.SERIES_DELETE);
		}
	};

	const formatDescription = (entry: AuditEvent) => {
		if (entry.seriesLabel) {
			const seriesDescription = translate(state => state.projects.history.seriesDescription);

			if (entry.userAction === UserAction.SERIES_DELETE) {
				return `${formatAction(entry.userAction)} '${entry.seriesLabel}'`;
			}

			return `${formatAction(entry.userAction)} ${seriesDescription} '${entry.seriesLabel}'`;
		}

		return formatAction(entry.userAction);
	};

	const onRowClick = async (row: Row<AuditEvent>) => {
		try {
			// Navigate to the main entry if the entry is a series
			const entryId = isSeries(row.original)
				? row.original.parentEntryId
				: row.original.entryId;

			const response = await makeRequest<{ latestentryid: string }>({
				method: 'getLatestDataEntryVersion',
				service: 'cde',
				data: {
					datasetentryid: entryId,
					projectId: id
				}
			});

			if (id) {
				if (shouldRouteToV1_5) {
					navigate(
						ROUTE_MAP.project.byId.dataset.update.createPath({
							projectId: id,
							entryId: response.latestentryid,
							formId: null
						})
					);
				} else {
					navigate(routes.projects.dataset.update(id, response.latestentryid));
				}
			}
		} catch (error) {
			if (error instanceof LedidiRequestError) {
				if (error.errors[0].code === 'error.entryNotFound') {
					toast.error(
						<Typography.Notification>
							{translate(state => state.projects.history.toast.entryDeleted)}
						</Typography.Notification>,
						{ position: 'top-center', closeOnClick: false }
					);
					return;
				}
			}

			toast.error(
				<Typography.Notification>
					{translate(state => state.projects.history.toast.unknownError)}
				</Typography.Notification>,
				{ position: 'top-center', closeOnClick: false }
			);
		}
	};

	const { page, headerGroups, pageOptions, getTableProps, getTableBodyProps, prepareRow, rows } =
		useTable(
			{
				columns,
				manualPagination: true,
				pageCount: data?.pageCount ?? 0,
				data: data?.auditLog ?? EMPTY_LIST
			},
			usePagination
		);

	return (
		<>
			<HistoryPageHeader title={translate(state => state.projects.history.title)} />

			{isLoading ? (
				<Loader primary />
			) : isError ? (
				<ErrorState />
			) : (
				<>
					<Grid.Container>
						<Pagination
							totalCount={data?.totalCount ?? 0}
							filteredCount={rows.length}
							pageIndex={data?.pageIndex ?? 0}
							pageSize={data?.pageSize ?? 0}
							pagesCount={pageOptions.length}
							changePage={pageIndex => setPageIndex(pageIndex)}
							changePageSize={pageSize => {
								setPageIndex(0);
								setPageSize(pageSize);
							}}
						/>
						<Spacer size={s => s.xs} />
						<Table.Responsive fullHeight>
							<Table fullWidth {...getTableProps()} hoverEffect tableRef={tableRef}>
								<Table.Head>
									{headerGroups.map(headerGroup => (
										<Table.Row
											{...headerGroup.getHeaderGroupProps()}
											key={headerGroup.getHeaderGroupProps().key}
										>
											{headerGroup.headers.map(column => (
												<Table.Column
													{...column.getHeaderProps()}
													key={column.getHeaderProps().key}
												>
													{column.render('Header')}
												</Table.Column>
											))}
										</Table.Row>
									))}
								</Table.Head>

								<Table.Body {...getTableBodyProps()}>
									{rows.length === 0 ? (
										<Table.Row>
											<Table.Cell colSpan={columns.length}>
												{translate(
													state => state.projects.history.noEntries
												)}
											</Table.Cell>
										</Table.Row>
									) : (
										page.map(row => {
											prepareRow(row);
											const entry = row.original;
											const timestamp = DateTime.fromJSDate(
												new Date(entry.timestamp)
											).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);

											return (
												<Table.Row
													key={entry.entryId}
													onClick={() => onRowClick(row)}
													style={{
														cursor: 'pointer'
													}}
												>
													<ShrinkedCell>{timestamp}</ShrinkedCell>
													<ShrinkedCell>{entry.username}</ShrinkedCell>
													<Cell>{formatDescription(entry)}</Cell>
													<ShrinkedCell>{entry.originalId}</ShrinkedCell>
													<ShrinkedCell>{entry.entryId}</ShrinkedCell>
												</Table.Row>
											);
										})
									)}
								</Table.Body>
							</Table>
						</Table.Responsive>
					</Grid.Container>
				</>
			)}
		</>
	);
}

const EMPTY_LIST: Array<AuditEvent> = [];

const ErrorState = () => {
	const { translate } = useTranslation();

	return (
		<Container>
			<Spacer size={s => s.s} />
			<Typography.H3>
				{translate(state => state.projects.history.pageError.title)}
			</Typography.H3>
			<Typography.Paragraph>
				{translate(state => state.projects.history.pageError.description)}
			</Typography.Paragraph>
			<Spacer size={s => s.s} />
		</Container>
	);
};

type AuditEvent = HistoryResponse['auditLog'][number];

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const FilterableColumn = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const ShrinkedCell = styled(Table.Cell)`
	min-width: 0;
	width: 1px;
	white-space: nowrap;
`;

const Cell = styled(Table.Cell)`
	white-space: nowrap;
`;
