import { useController } from 'react-hook-form';
import { FormControl } from '../EntryForm';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { OptionalDescriptionTooltip } from '../component/OptionalDescriptionTooltip';
import { CheckboxGroup } from '../component/CheckboxGroup';
import { CategoryMultipleNonFixedFormItem } from '../data/useProjectData/useProjectData';
import clsx from 'clsx';

interface Props {
	control: FormControl;
	item: CategoryMultipleNonFixedFormItem;
}

export const CategoryMultipleVariableInput = ({ item, control }: Props) => {
	const variable = item.variable;

	const { field, fieldState } = useController({
		name: variable.variableName,
		control
	});

	const options = item.variable.categories.map(category => ({
		label: category,
		value: category
	}));

	if (item.layoutType === 'dropdown') {
		return (
			<CreatableSelect
				className={clsx(item.hideFromUi && 'hidden')}
				label={item.variable.variableLabel}
				placeholder={variable.obligatory ? 'Select at least one' : 'Select'}
				values={field.value ? field.value.map((v: any) => ({ label: v, value: v })) : []}
				items={options}
				error={fieldState.error?.message?.toString()}
				onBlur={field.onBlur}
				LabelSuffix={<OptionalDescriptionTooltip description={variable.description} />}
				onValuesSelected={values => field.onChange(values)}
				required={variable.obligatory}
				hasMultipleValues
				allowCreate={true}
			/>
		);
	}

	return (
		<CheckboxGroup
			className={clsx(item.hideFromUi && 'hidden')}
			categories={options}
			allowCreate={true}
			control={control}
			label={item.variable.variableLabel}
			variableName={variable.variableName}
			obligatory={variable.obligatory}
			description={variable.description}
			orientation={item.layoutType}
		/>
	);
};
