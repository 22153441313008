import { nanoid as generate } from 'nanoid';

import { Modal } from 'components/UI/Modal';
import { useUpdateBillingAddress } from 'hooks/store/account/payments/useUpdateBillingAddress';
import { BillingAddress, PaymentAddress, ActionTypes, PaymentMethod } from 'store/account/payments';

import { BillingAddressForm } from './BillingAddressForm';
import { setError } from 'store/ui/activities';
import { useTranslation, useBillingAddressFormValues, useSelectedPaymentMethod } from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { useCompletedAction, useDispatch } from 'hooks/utils';

interface Props {
	billingAddress: BillingAddress;
	primaryPaymentSourceAddress: PaymentAddress;
	onClose: () => void;
}

export function BillingAddressModal({
	billingAddress,
	primaryPaymentSourceAddress,
	onClose
}: Props) {
	const { translate } = useTranslation();

	const {
		data: { initialValues, billingAddressValidationSchema, useCompanyInfo, usePaymentInfo },
		toggleUseCompanyInfo,
		toggleUsePaymentInfo
	} = useBillingAddressFormValues(billingAddress, primaryPaymentSourceAddress);

	const {
		Form,
		FormProvider: BillingAddressFormProvider,
		formProviderProps: billingAddressFormProps,
		handleSubmit: handleBillingChanged,
		isDirtyAndValid,
		getValues
	} = useReactForm({
		initialValues: initialValues,
		validationSchema: billingAddressValidationSchema
	});

	const { data: selectedPaymentMethod } = useSelectedPaymentMethod();

	const [
		{ loading: updatingBillingAddress, error: errorUpdatingBillingAddress },
		updateBillingAddress
	] = useUpdateBillingAddress();

	useCompletedAction(updatingBillingAddress, errorUpdatingBillingAddress, onClose);

	const dispatch = useDispatch();

	const onChangeBillingAddress = handleBillingChanged(_ => {
		if (usePaymentInfo) {
			// in case the primary payment address is missing some mandatory fields
			// for billing address show an error
			if (
				!primaryPaymentSourceAddress.firstName ||
				!primaryPaymentSourceAddress.lastName ||
				!primaryPaymentSourceAddress.country ||
				!primaryPaymentSourceAddress.line1 ||
				!primaryPaymentSourceAddress.city
			) {
				//
				dispatch(
					setError({
						type: ActionTypes.UPDATE_USER_BILLING_ADDRESS,
						error: 'Error updating billing address',
						toast: {
							display: true,
							message: translate(
								dict => dict.accountUM.billing.updatePrimaryPaymentAddress
							)
						},
						uuid: generate(),
						timeout: 5000
					})
				);
				return;
			}
		}
		updateBillingAddress(getValues());
	});

	const isPaymentMethodInvoice = selectedPaymentMethod === PaymentMethod.INVOICE;

	return (
		<Modal
			visible
			title={translate(dict => dict.accountUM.billing.modalBillingTitle)}
			primary={{
				label: translate(dict => dict.buttons.change),
				loading: updatingBillingAddress,
				disabled: !isDirtyAndValid,
				onClick: onChangeBillingAddress
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			close
		>
			<BillingAddressFormProvider {...billingAddressFormProps}>
				<Form onSubmit={onChangeBillingAddress}>
					<BillingAddressForm
						primaryPaymentSourceAddress={primaryPaymentSourceAddress}
						billingAddress={billingAddress}
						usePaymentInfo={isPaymentMethodInvoice ? false : usePaymentInfo}
						useCompanyInfo={useCompanyInfo}
						toggleUsePaymentInfo={
							isPaymentMethodInvoice ? undefined : toggleUsePaymentInfo
						}
						toggleUseCompanyInfo={toggleUseCompanyInfo}
					/>
				</Form>
			</BillingAddressFormProvider>
		</Modal>
	);
}
