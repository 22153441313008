import styled, { css } from 'styled-components';
import { Colors, Shadows } from 'environment';

interface PageProps {
	center?: boolean;
}

export const Image = styled.img`
	width: 100%;
	height: 100%;
	max-height: 100vh;
	padding: 0 10rem;
	object-fit: contain;
`;

export const NoPreviewTitle = styled.p`
	text-align: center;
	color: ${Colors.gray.medium};
	font-weight: 500;
`;

export const NoPreview = styled.div`
	height: 70vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const TextPreview = styled.p`
	padding: 0 15rem;
`;

export const PageBackground = styled.div<PageProps>`
	display: flex;
	${({ center }) =>
		center &&
		css`
			justify-content: center;
		`}
	background: white;
	box-shadow: ${Shadows.normal};
	width: 90%;
	height: 90%;
	padding: 5rem 5rem;
`;

export const ViewDocumentContainer = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: calc(100vh - 6rem);
	/* TODO: replace with colors from styleguide */
	background-color: #7c7c89;
	overflow-y: auto;
`;
