import { useEffect, useMemo, useState } from 'react';
import { isEmpty, keys } from 'lodash';

import {
	Analysis,
	CrosstabResults,
	CorrelationsResults,
	DensityPlotResults,
	PlotNumericScatterResults,
	PlotNumericResults,
	PlotNumericAnalysis,
	PlotNumericColumnsSingle,
	PlotNumericColumnsGrouped,
	PlotNumericBoxplotSingle,
	PlotNumericBoxplotGrouped,
	ComparePairedResults,
	GetLogisticRegressionOutput,
	CompareNumericResultsV1,
	CompareNumericResultsV2,
	TimeCourseGroupedV1,
	TimeCourseAnalysisV1,
	TimeCourseSingleV1,
	TimeCourseSingleV2,
	PlotNumericResultsV2,
	GetLogisticRegressionOutputV2,
	KaplanMeierAnalysisV2,
	AnalysisError,
	FrequenciesAnalysisV2,
	DisplayError,
	DensityPlotResultsV2,
	TimeCourseResultsV2,
	TimeCourseGroupedValuesV2,
	ExploreResultsV2,
	CorrelationsResultsV2,
	CrosstabAnalysisV2,
	AnalysisOutputData,
	ComparePairedAnalysisV2
} from 'api/data/analyses';
import { ActionTypes } from 'store/data/analyses';

import { ErrorContainer, ErrorMessage, Icon } from './AnalysisErrorWrapper.style';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import {
	useTranslation,
	useAreFiltersValid,
	useHasErrored,
	useActivity,
	useActivities
} from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';
import { Dictionary } from 'environment';

interface Props {
	children: React.ReactNode;
	analysis: Analysis;
	analysisType: AnalysisType;
	ignoreAnalysisData?: boolean;
	plotNumericType?: number;
}

export function AnalysisErrorWrapper({
	children,
	analysis,
	analysisType,
	ignoreAnalysisData,
	plotNumericType
}: Props) {
	const {
		id,
		output: { dataset }
	} = analysis;

	const {
		output: { grouping: timeCourseGrouping }
	} = analysis as TimeCourseAnalysisV1;
	const {
		output: { grouping: plotNumericGrouping }
	} = analysis as PlotNumericAnalysis;

	const [serverError, setServerError] = useState(false);
	const [noResults, setNoResults] = useState(false);
	const [apiError, setApiError] = useState<AnalysisError | null>(null);

	const { translate } = useTranslation();
	const filtersNotValid = !useAreFiltersValid();

	// errorFrequencies = useHasErrored(ActionTypes.GET_FREQUENCIES, id);
	const errorFrequenciesV2 = useHasErrored(ActionTypes.GET_FREQUENCIES_V2, id);
	// errorCompare = useHasErrored(ActionTypes.GET_COMPARE_NUMERIC, id);
	const errorExplore = useHasErrored(ActionTypes.GET_EXPLORE, id);
	const errorExplore2 = useHasErrored(ActionTypes.GET_EXPLORE_V2, id);
	// errorExplore = useHasErrored(ActionTypes.GET_EXPLORE, id);
	const errorCompareV1 = useHasErrored(ActionTypes.GET_COMPARE_NUMERIC_V1, id);
	const errorCompareV2 = useHasErrored(ActionTypes.GET_COMPARE_NUMERIC_V2, id);
	const errorComparePaired = useHasErrored(ActionTypes.GET_COMPARE_PAIRED, id);
	const errorComparePairedV2 = useHasErrored(ActionTypes.GET_COMPARE_PAIRED_V2, id);
	const errorCrosstab = useHasErrored(ActionTypes.GET_CROSSTAB, id);
	const errorCrosstabV2 = useHasErrored(ActionTypes.GET_CROSSTAB_V2, id);
	const errorKaplan = useHasErrored(ActionTypes.GET_KAPLAN_MEIER, id);
	const errorCorrelationsV1 = useHasErrored(ActionTypes.GET_CORRELATIONS_V1, id);
	const errorCorrelationsV2 = useHasErrored(ActionTypes.GET_CORRELATIONS_V2, id);
	const errorLogistic = useHasErrored(ActionTypes.GET_LOGISTIC_REGRESSION, id);
	const errorLogisticV2 = useHasErrored(ActionTypes.GET_LOGISTIC_REGRESSION_V2, id);

	const errorColumns = useHasErrored(ActionTypes.GET_PLOT_NUMERIC_COLUMNS, id);
	const errorBoxplot = useHasErrored(ActionTypes.GET_PLOT_NUMERIC_BOXPLOT, id);
	const errorScatter = useHasErrored(ActionTypes.GET_PLOT_NUMERIC_SCATTER, id);

	const errorColumnsV2 = useHasErrored(ActionTypes.GET_PLOT_NUMERIC_COLUMNS_V2, id);
	const errorBoxplotV2 = useHasErrored(ActionTypes.GET_PLOT_NUMERIC_BOXPLOT_V2, id);
	const errorScatterV2 = useHasErrored(ActionTypes.GET_PLOT_NUMERIC_SCATTER_V2, id);

	const [{ loading: loadingColumns }] = useActivity(ActionTypes.GET_PLOT_NUMERIC_COLUMNS, id);
	const [{ loading: loadingBoxplot }] = useActivity(ActionTypes.GET_PLOT_NUMERIC_BOXPLOT, id);
	const [{ loading: loadingScatter }] = useActivity(ActionTypes.GET_PLOT_NUMERIC_SCATTER, id);

	const [{ loading: loadingDensity }] = useActivity(ActionTypes.GET_DENSITY_PLOT, id);

	const errorDensity = useHasErrored(ActionTypes.GET_DENSITY_PLOT, id);
	const errorDensityV2 = useHasErrored(ActionTypes.GET_DENSITY_PLOT_V2, id);
	const errorTimeCourse = useHasErrored(ActionTypes.GET_TIME_COURSE_V1, id);
	const errorTimeCourseV2 = useHasErrored(ActionTypes.GET_TIME_COURSE_V2, id);

	const [{ loading, error }] = useActivities(
		[
			ActionTypes.GET_FREQUENCIES_V2,
			ActionTypes.GET_EXPLORE,
			ActionTypes.GET_EXPLORE_V2,
			ActionTypes.GET_CROSSTAB,
			ActionTypes.GET_KAPLAN_MEIER,
			ActionTypes.GET_CORRELATIONS_V1,
			ActionTypes.GET_CORRELATIONS_V2,
			ActionTypes.GET_COMPARE_PAIRED,
			ActionTypes.GET_COMPARE_PAIRED_V2,
			ActionTypes.GET_PLOT_NUMERIC_COLUMNS,
			ActionTypes.GET_PLOT_NUMERIC_BOXPLOT,
			ActionTypes.GET_PLOT_NUMERIC_SCATTER,
			ActionTypes.GET_DENSITY_PLOT,
			ActionTypes.GET_COMPARE_NUMERIC_V1,
			ActionTypes.GET_COMPARE_NUMERIC_V2,
			ActionTypes.GET_DENSITY_PLOT_V2,
			ActionTypes.GET_TIME_COURSE_V2,
			ActionTypes.GET_TIME_COURSE_V1,
			ActionTypes.GET_LOGISTIC_REGRESSION,
			ActionTypes.GET_LOGISTIC_REGRESSION_V2
		],
		id
	);

	useEffect(() => {
		if (!ignoreAnalysisData) {
			if (analysisType === AnalysisType.FrequenciesV2) {
				setServerError(errorFrequenciesV2);

				const {
					output: {
						dataset: { error }
					}
				} = analysis as FrequenciesAnalysisV2;

				if (error) {
					setApiError(error);
				}
			}

			if (analysisType === AnalysisType.Explore) {
				setServerError(errorExplore);
			}
			if (analysisType === AnalysisType.ExploreV2) {
				const { error } = dataset as ExploreResultsV2;
				if (error) setApiError(error);
				setServerError(errorExplore2);
			}

			if (analysisType === AnalysisType.Crosstab) {
				setServerError(errorCrosstab);
				const data = dataset as CrosstabResults;
				const {
					rows: { rows },
					columns: { categoryLabels }
				} = data;
				if (!isEmpty(rows) && !isEmpty(categoryLabels)) {
					if (isEmpty(rows) || isEmpty(categoryLabels)) {
						setNoResults(true);
					}
				}
			}

			if (analysisType === AnalysisType.CrosstabV2) {
				setServerError(errorCrosstabV2);
				const {
					output: {
						dataset: { error },
						statistics: {
							chiSquare: { error: chiSquareError },
							fisher: { error: fisherError },
							mcNemar: { error: mcNemarError }
						}
					}
				} = analysis as CrosstabAnalysisV2;
				if (error) setApiError(error);
				if (chiSquareError) setApiError(chiSquareError);
				if (mcNemarError) setApiError(mcNemarError);
				if (fisherError) setApiError(fisherError);
			}

			if (analysisType === AnalysisType.CompareNumericV1) {
				setServerError(errorCompareV1);
				const data = dataset as CompareNumericResultsV1;
				if (isEmpty(data)) {
					setNoResults(true);
				}
			}

			if (analysisType === AnalysisType.CompareNumericV2) {
				setServerError(errorCompareV2);
				const { data, error } = dataset as CompareNumericResultsV2;
				if (error) setApiError(error);
				if (isEmpty(data)) {
					setNoResults(true);
				}
			}

			if (analysisType === AnalysisType.Kaplan) {
				setServerError(errorKaplan);
			}

			if (analysisType === AnalysisType.KaplanV2) {
				setServerError(errorKaplan);
				const {
					input: {
						statistics: { logRankV2 }
					},
					output: {
						dataset: { error },
						statistics: {
							logRank: { error: logRankError }
						}
					}
				} = analysis as KaplanMeierAnalysisV2;

				// main analysis error;
				if (error) {
					return setApiError(error);
				}

				if (logRankV2 && logRankError) {
					setApiError(logRankError);
				}
			}
			if (analysisType === AnalysisType.CorrelationsV1) {
				setServerError(errorCorrelationsV1);
				const data = dataset as CorrelationsResults; //?????????????????
				if (!isEmpty(data)) {
					let results = false;
					data.forEach(object => {
						if (!isEmpty(object.xValues) && !isEmpty(object.yValues)) {
							results = true;
						}
					});
					setNoResults(!results);
				} else {
					setNoResults(true);
				}
			}
			if (analysisType === AnalysisType.CorrelationsV2) {
				setServerError(errorCorrelationsV2);
				const { data, error } = dataset as CorrelationsResultsV2;
				if (error) setApiError(error);
				if (!isEmpty(data)) {
					let results = false;
					data?.forEach(object => {
						if (!isEmpty(object.xValues) && !isEmpty(object.yValues)) {
							results = true;
						}
					});
					setNoResults(!results);
				} else {
					setNoResults(true);
				}
			}
			if (analysisType === AnalysisType.LogisticRegression) {
				setServerError(errorLogistic);
				const data = dataset as GetLogisticRegressionOutput;
				if (!isEmpty(data)) {
					let results = false;
					if (Array.isArray(data)) {
						data.forEach(object => {
							if (
								object.logisticRegression &&
								!isEmpty(object.logisticRegression.xValues) &&
								!isEmpty(object.logisticRegression.yValues)
							) {
								results = true;
							}
						});
					} else {
						if (!isEmpty(data.xValues) && !isEmpty(data.yValues)) {
							results = true;
						}
					}
					setNoResults(!results);
					setServerError(!results);
				}
			}

			if (analysisType === AnalysisType.LogisticRegressionV2) {
				setServerError(errorLogisticV2);
				const { data, error } = dataset as GetLogisticRegressionOutputV2;

				if (error) {
					setApiError(error);
				}

				if (!isEmpty(data) && !errorLogisticV2) {
					let results = false;
					if (Array.isArray(data)) {
						data.forEach(object => {
							if (
								object.logisticRegression &&
								!isEmpty(object.logisticRegression.xValues) &&
								!isEmpty(object.logisticRegression.yValues)
							) {
								results = true;
							}
						});
					} else {
						if (!isEmpty(data.xValues) && !isEmpty(data.yValues)) {
							results = true;
						}
					}
					setNoResults(!results);
					setServerError(!results);
				}
			}
			if (analysisType === AnalysisType.PlotNumeric) {
				const { columns, boxplot, scatter } = dataset as PlotNumericResults;
				if (plotNumericType === 0 && !loadingColumns) {
					setServerError(errorColumns);

					if (plotNumericGrouping) {
						const data = columns as PlotNumericColumnsGrouped;
						if (!isEmpty(data)) {
							let results = false;
							data.forEach(object => {
								object.groupedCalculations &&
									object.groupedCalculations.forEach(value => {
										if (value.meanOrMedian !== 'N/A') results = true;
									});
							});
							setNoResults(!results);
						}
					} else {
						const data = columns as PlotNumericColumnsSingle;
						if (!isEmpty(data)) {
							let results = false;
							data &&
								data.forEach(object => {
									if (object.meanOrMedian !== 'N/A') {
										results = true;
									}
								});
							setNoResults(!results);
						}
					}
				} else if (plotNumericType === 1 && !loadingBoxplot) {
					setServerError(errorBoxplot);

					if (plotNumericGrouping) {
						const data = boxplot as PlotNumericBoxplotGrouped;
						if (!isEmpty(data)) {
							let results = false;
							data.forEach(({ groupedCalculations }) => {
								groupedCalculations &&
									groupedCalculations.forEach(object => {
										if (
											object.median !== 'N/A' &&
											object.errorValueMin !== 'N/A' &&
											object.errorValueMax !== 'N/A' &&
											object.IQRLower !== 'N/A' &&
											object.IQRUpper !== 'N/A'
										) {
											results = true;
										}
									});
							});
							setNoResults(!results);
						}
					} else {
						const data = boxplot as PlotNumericBoxplotSingle;
						if (!isEmpty(data)) {
							let results = false;
							data.forEach(object => {
								if (
									object.median !== 'N/A' &&
									object.errorValueMin !== 'N/A' &&
									object.errorValueMax !== 'N/A' &&
									object.IQRLower !== 'N/A' &&
									object.IQRUpper !== 'N/A'
								) {
									results = true;
								}
							});
							setNoResults(!results);
						}
					}
				} else if (plotNumericType === 2 && !loadingScatter) {
					setServerError(errorScatter);
					const data = scatter as PlotNumericScatterResults;
					if (!isEmpty(data)) {
						let results = false;
						data.forEach(object => {
							if (
								object.mean !== 'N/A' &&
								object.median !== 'N/A' &&
								!isEmpty(object.yValues)
							) {
								results = true;
							}
						});
						setNoResults(!results);
					}
				}
			}

			if (analysisType === AnalysisType.PlotNumericV2) {
				const {
					boxplot: { error: boxplotError },
					columns: { error: columnsError },
					scatter: { error: scatterError }
				} = dataset as PlotNumericResultsV2;

				setApiError(scatterError || boxplotError || columnsError || null);
			}
			if (analysisType === AnalysisType.DensityPlot) {
				setServerError(errorDensity);
				const data = dataset as DensityPlotResults;
				if (!isEmpty(data)) {
					let results = false;
					data.forEach(object => {
						if (!isEmpty(object.densityPlot) && !isEmpty(object.histogramColumns)) {
							results = true;
						}
					});
					setNoResults(!results);
				}
				if (isEmpty(data) && !loadingDensity) {
					setNoResults(true);
				}
			}

			if (analysisType === AnalysisType.PlotNumericV2) {
				const { columns, boxplot, scatter } = dataset as PlotNumericResultsV2;

				if (plotNumericType === 0 && !loadingColumns) {
					setServerError(errorColumnsV2);

					const { data, error } = columns as PlotNumericResultsV2['columns'];
					if (error) setApiError(error);
					if (!isEmpty(data)) {
						let results = false;
						data &&
							data.forEach(object => {
								if (object.mean !== null && object.median !== null) {
									results = true;
								}
							});
						setNoResults(!results);
					}
				} else if (plotNumericType === 1 && !loadingBoxplot) {
					setServerError(errorBoxplotV2);
					const { data, error } = boxplot as PlotNumericResultsV2['boxplot'];
					if (error) setApiError(error);
					if (!isEmpty(data)) {
						const results = true;
						setNoResults(!results);
					}
				} else if (plotNumericType === 2 && !loadingScatter) {
					setServerError(errorScatterV2);
					const { data, error } = scatter as PlotNumericResultsV2['scatter'];
					if (error) setApiError(error);
					if (!isEmpty(data)) {
						let results = false;
						data?.forEach(object => {
							if (
								object.mean !== null &&
								object.median !== null &&
								!isEmpty(object.numericValues)
							) {
								results = true;
							}
						});
						setNoResults(!results);
					}
				}
			}
			if (analysisType === AnalysisType.DensityPlot) {
				setServerError(errorDensity);
				const data = dataset as DensityPlotResults;
				if (!isEmpty(data)) {
					let results = false;
					data.forEach(object => {
						if (!isEmpty(object.densityPlot) && !isEmpty(object.histogramColumns)) {
							results = true;
						}
					});
					setNoResults(!results);
				}
				if (isEmpty(data) && !loadingDensity) {
					setNoResults(true);
				}
			}

			if (analysisType === AnalysisType.DensityPlotV2) {
				setServerError(errorDensityV2);
				const { data, error } = dataset as DensityPlotResultsV2;

				if (error) {
					setApiError(error);
				}
				if (!isEmpty(data)) {
					let results = false;
					data?.forEach(object => {
						if (!isEmpty(object.densityPlot) && !isEmpty(object.histogramColumns)) {
							results = true;
						}
					});
					setNoResults(!results);
				}
				if (isEmpty(data) && !loadingDensity) {
					setNoResults(true);
				}
			}
			if (analysisType === AnalysisType.TimeCourseV1) {
				setServerError(errorTimeCourse);

				if (timeCourseGrouping) {
					const data = dataset as TimeCourseGroupedV1;
					if (!isEmpty(data)) {
						let results = false;
						data.groupedTimeCourse.forEach(value => {
							if (value.timeCourse && value.timeCourse.yValues.length) {
								results = true;
							}
						});
						setNoResults(!results);
					}
				} else {
					const data = dataset as TimeCourseSingleV1;
					if (!isEmpty(data)) {
						let results = false;
						if (data.timeCourse && data.timeCourse.yValues.length) {
							results = true;
						}
						setNoResults(!results);
					}
				}
			}

			if (analysisType === AnalysisType.TimeCourseV2) {
				setServerError(errorTimeCourseV2);

				const { error } = dataset as TimeCourseResultsV2;

				if (error) setApiError(error);

				if (timeCourseGrouping) {
					const { data } = dataset as TimeCourseResultsV2;
					if (!isEmpty(data)) {
						let results = false;
						(data as TimeCourseGroupedValuesV2).groupedTimeCourse.forEach(value => {
							if (value.timeCourse && value.timeCourse.yValues.length) {
								results = true;
							}
						});
						setNoResults(!results);
					}
				} else {
					const { data } = dataset as AnalysisOutputData<TimeCourseSingleV2>;
					if (!isEmpty(data)) {
						let results = false;
						if (data.timeCourse && data.timeCourse.yValues.length) {
							results = true;
						}
						setNoResults(!results);
					}
				}
			}
			if (analysisType === AnalysisType.DensityPlot) {
				setServerError(errorDensity);
				const data = dataset as DensityPlotResults;
				if (!isEmpty(data)) {
					let results = false;
					data.forEach(object => {
						if (!isEmpty(object.densityPlot) && !isEmpty(object.histogramColumns)) {
							results = true;
						}
					});
					setNoResults(!results);
				}
				if (isEmpty(data) && !loadingDensity) {
					setNoResults(true);
				}
			}
			if (analysisType === AnalysisType.ComparePaired) {
				setServerError(errorComparePaired);
				const { data } = dataset as ComparePairedResults;
				if (data && !isEmpty(data)) {
					let results = false;
					if (
						data.delta_stats.median !== 'N/A' &&
						data.delta_stats.IQRLower !== 'N/A' &&
						data.delta_stats.IQRUpper !== 'N/A'
					) {
						results = true;
					}
					setNoResults(!results);
				}
			}

			if (analysisType === AnalysisType.ComparePairedV2) {
				setServerError(errorComparePairedV2);

				const {
					output: {
						dataset: { data, error }
					}
				} = analysis as ComparePairedAnalysisV2;

				if (error) setApiError(error);

				if ((data && !isEmpty(data)) || !data) {
					let results = false;
					if (data?.length) {
						results = true;
					}
					setNoResults(!results);
				}
			}
		}
	}, [loading, error, ignoreAnalysisData]);

	const displayError = useMemo<DisplayError>(() => {
		const genericError = {
			header: translate(({ analysis }) => analysis.errors.noAnalyses),
			body: translate(({ analysis }) => analysis.errors.serverError)
		};

		if (apiError) {
			// known translatable error message
			const translateKey = apiError.code.split('.')[1];

			if (keys(Dictionary.analysis.analyses.error).includes(translateKey)) {
				return {
					header:
						translate(
							dict => {
								// @ts-ignore
								return dict.analysis.analyses.error[translateKey]?.header;
							},
							false,
							apiError.values
						) ?? genericError.header,
					body:
						translate(
							dict =>
								// @ts-ignore
								dict.analysis.analyses.error[translateKey]?.body,
							false,
							apiError.values
						) ?? genericError.body
				};
			}
		}

		if (!loading && serverError) {
			return {
				...genericError,
				body: translate(({ analysis }) => analysis.errors.serverError)
			};
		} else if (filtersNotValid) {
			return {
				...genericError,
				body: translate(({ analysis }) => analysis.errors.filtersAreNotValid)
			};
		} else if (noResults) {
			return {
				...genericError,
				body: translate(({ analysis }) => analysis.errors.noResults)
			};
		}

		return genericError;
	}, [serverError, apiError, filtersNotValid, loading, noResults]);

	if ((!loading && serverError) || filtersNotValid || noResults || apiError) {
		return (
			<ErrorContainer>
				<Icon />
				<Spacer size={m => m.m} />
				<Typography.H3 alignCenter>{displayError.header}</Typography.H3>
				<Spacer size={s => s.xs} />
				<ErrorMessage>{displayError.body}</ErrorMessage>
			</ErrorContainer>
		);
	}

	return <>{children}</>;
}
