import { LanguageType, StorageKeys } from 'types/index';
import { State } from './types';

const language = localStorage.getItem(StorageKeys.UserLanguage);

const initialState: State = {
	language: (language as LanguageType) || LanguageType.English,
	translations: null
};

export default initialState;
