import { useRef } from 'react';
import { nanoid as generate } from 'nanoid';
import { Colors, Svgs } from 'environment';
import { StoredEntryFile } from 'store/data/entries';
import { selectProjectFileToView } from 'store/data/documents';
import { FileMimeType, FileExtension, StringMap } from 'types/index';
import { CsvToTable } from './CsvToTable/CsvToTable';
import {
	Image,
	NoPreview,
	NoPreviewTitle,
	TextPreview,
	PageBackground,
	ViewDocumentContainer
} from './PreviewDocument.style';
import { Icon } from 'components/UI/Icons';
import { Loader } from 'components/UI/Loader';
import { useTranslation, useProjectId, useConvertS3Document } from 'hooks/store';
import { useEffectOnce, useSelector } from 'hooks/utils';

interface Props {
	entryFile?: StoredEntryFile;
}

export function PreviewDocument({ entryFile }: Props) {
	const { translate } = useTranslation();
	const [projectId] = useProjectId();

	const ref = useRef<HTMLDivElement>(null);

	const projectFile = useSelector(state => selectProjectFileToView(state.data.documents));

	const file = entryFile ? entryFile : projectFile;

	const [{ data: csvFile }, parseCsvFromS3] = useConvertS3Document();

	useEffectOnce(() => {
		if (file) {
			const displayAsTextorTable =
				file.extendedMimeType === FileMimeType.CSV ||
				file.extendedMimeType === FileMimeType.XLS ||
				file.extendedMimeType === FileMimeType.XLSX ||
				file.extendedMimeType === FileMimeType.TXT;

			if (displayAsTextorTable && projectId) {
				parseCsvFromS3(file.signedS3Url);
			}
		}
	});

	const pictureTypes = [
		FileMimeType.JPG,
		FileMimeType.PNG,
		FileMimeType.BMP,
		FileMimeType.GIF,
		FileMimeType.TIFF,
		//
		FileExtension.JPEG,
		FileExtension.JPG,
		FileExtension.PNG,
		FileExtension.GIF,
		FileExtension.BMP,
		FileExtension.TIFF
	] as string[];

	const tableTypes = [
		FileMimeType.CSV,
		FileMimeType.XLS,
		FileMimeType.XLSX,
		//
		FileExtension.CSV,
		FileExtension.XLS,
		FileExtension.XLSX
	] as string[];

	return (
		<ViewDocumentContainer id="print-table" className="scrollContainer">
			{(() => {
				if (file) {
					const { extendedMimeType, mimeType } = file;
					if (
						pictureTypes.indexOf(extendedMimeType) > -1 ||
						pictureTypes.indexOf(mimeType) > -1
					)
						return (
							<Image
								src={file.signedS3Url}
								alt={translate(dict => dict.login.chromeLogoAlt)}
							/>
						);

					if (
						tableTypes.indexOf(extendedMimeType) > -1 ||
						tableTypes.indexOf(mimeType) > -1
					) {
						if (csvFile) {
							const header = csvFile[0];
							const [, ...rows] = csvFile;
							let unnamedColumn = 0;

							const formattedHeader = header.map(headerItem => {
								const headerAccessor =
									headerItem === ''
										? `${translate(
												dict => dict.terms.unnamed
										  )} ${unnamedColumn}`
										: headerItem;

								unnamedColumn++;

								return {
									Header: headerAccessor,
									accessor: generate()
								};
							});

							const formattedRows = rows.map(row => {
								const newRow: StringMap = {};

								formattedHeader.forEach(({ accessor }, index) => {
									newRow[accessor] = row[index];
								});

								return newRow;
							});

							return (
								<PageBackground ref={ref}>
									<CsvToTable
										containerRef={ref}
										columns={formattedHeader}
										data={formattedRows}
									/>
								</PageBackground>
							);
						} else
							return (
								<PageBackground center>
									<Loader />
								</PageBackground>
							);
					}

					if (extendedMimeType === FileMimeType.TXT || mimeType === FileExtension.TXT)
						return (
							<PageBackground center>
								<TextPreview>{csvFile}</TextPreview>
							</PageBackground>
						);

					return (
						<NoPreview>
							<Icon svg={Svgs.File} colors={{ color: Colors.gray.medium }} />
							<NoPreviewTitle>
								{translate(dict => dict.documents.view.noPreview)}
							</NoPreviewTitle>
						</NoPreview>
					);
				}
			})()}
		</ViewDocumentContainer>
	);
}
