import { Dialog } from 'features/entry-form-v2/component/Dialog';
import { FileExtension, FileMimeType } from 'types/enums';
import { RefObject, useRef } from 'react';
import { CsvTable } from './CsvTable';
import { useQuery } from '@tanstack/react-query';
import { useParseFile } from './useParseFile';
import { Loader } from 'components/UI/Loader/Loader';

type Props = {
	onClose: () => void;
	fileUrl: string;
	projectId: string;
};

export const PreviewDocument = (props: Props) => {
	const { onClose, fileUrl, projectId } = props;

	const containerRef = useRef<HTMLDivElement>(null);

	const fileBlobQuery = useQuery({
		queryKey: ['file', fileUrl],
		queryFn: () => fetch(fileUrl).then(res => res.blob())
	});

	return (
		<Dialog open onClose={onClose} title="Preview Document">
			<div
				ref={containerRef}
				className="h-full min-h-[80vh] min-w-[80vw] max-h-[80vh] max-w-[80vw]"
			>
				{(() => {
					if (!fileBlobQuery.data) return null;

					if (fileBlobQuery.isLoading) return <Loader className="h-full w-full" />;

					if (fileBlobQuery.isError) return <p>Error loading file</p>;

					if (TABLE_TYPES.includes(fileBlobQuery.data.type)) {
						return (
							<PreviewCsv
								blob={fileBlobQuery.data}
								containerRef={containerRef}
								projectId={projectId}
							/>
						);
					} else if (PICTURE_TYPES.includes(fileBlobQuery.data.type)) {
						return (
							<div className="h-full w-full flex items-center justify-center">
								<img
									className="w-auto h-auto max-w-full max-h-full object-contain"
									src={fileUrl}
									alt=""
								/>
							</div>
						);
					} else if (fileBlobQuery.data.type === FileMimeType.PDF) {
						return (
							<embed
								src={fileUrl}
								type="application/pdf"
								className="h-[80vh] w-full"
							/>
						);
					} else {
						return <p>Unsupported file type</p>;
					}
				})()}
			</div>
		</Dialog>
	);
};

const PreviewCsv = (props: {
	blob: Blob;
	containerRef: RefObject<HTMLDivElement>;
	projectId: string;
}) => {
	const { blob, containerRef, projectId } = props;

	const { data, isLoading, isError } = useParseFile({ blob, projectId });

	return (
		<div className="h-full w-full overflow-auto">
			{isLoading ? (
				<Loader className="h-full w-full" />
			) : isError ? (
				<p>Error loading file</p>
			) : data ? (
				<CsvTable columns={data.header} data={data.rows} containerRef={containerRef} />
			) : null}
		</div>
	);
};

const PICTURE_TYPES = [
	FileMimeType.JPG,
	FileMimeType.PNG,
	FileMimeType.BMP,
	FileMimeType.GIF,
	FileMimeType.TIFF,
	//
	FileExtension.JPEG,
	FileExtension.JPG,
	FileExtension.PNG,
	FileExtension.GIF,
	FileExtension.BMP,
	FileExtension.TIFF
] as string[];

const TABLE_TYPES = [
	FileMimeType.CSV,
	FileMimeType.XLS,
	FileMimeType.XLSX,
	//
	FileExtension.CSV,
	FileExtension.XLS,
	FileExtension.XLSX
] as string[];
