import { addTimeUnits } from './formatMicroSeconds';
import { InputType } from 'types';
import { TimeUnit } from '../../types';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { TIME_UNIT_DICT, TimeUnitKey } from './dict';
import clsx from 'clsx';
import { Input } from 'features/entry-form-v2/component/Input';

interface Props {
	value: string | null;
	maxTimeUnit: TimeUnit;
	minTimeUnit: TimeUnit;
	label: string;
	obligatory: boolean;
	onChange: (value: string | null) => void;
	onBlur: () => void;
	error?: string;
	onError: (error: string | undefined) => void;
	description?: string;
	hideFromUi?: boolean;
	variableName: string;
}

export const TimeDurationInput = forwardRef<HTMLInputElement, Props>(
	(
		{
			value,
			maxTimeUnit,
			minTimeUnit,
			label,
			obligatory,
			onChange,
			onBlur,
			error,
			onError,
			description,
			hideFromUi,
			variableName
		},
		ref
	) => {
		const [editing, setEditing] = useState(false);

		if (editing) {
			return (
				<EditTimeDurationInput
					value={value}
					label={label}
					onCancel={() => setEditing(false)}
					maxTimeUnit={maxTimeUnit}
					minTimeUnit={minTimeUnit}
					obligatory={obligatory}
					variableName={variableName}
					onSubmit={value => {
						onChange(value);
						setEditing(false);
						onBlur();
					}}
					onError={onError}
					error={error}
					description={description}
				/>
			);
		}

		return (
			<Input
				className={clsx(hideFromUi && 'hidden')}
				label={label}
				ref={ref}
				description={description}
				labelHint={formatLabelHint({
					maxTimeUnit,
					minTimeUnit
				})}
				id={variableName}
				obligatory={obligatory}
				value={addTimeUnits({
					colonSeparatedValues: value,
					maxTimeUnit,
					minTimeUnit,
					variableName
				})}
				onFocus={() => {
					setEditing(true);
				}}
				error={error}
				onChange={() => {
					// do nothing just stop complaining
					// the actual edit is handled by rendering a different input
				}}
			/>
		);
	}
);

TimeDurationInput.displayName = 'TimeDurationInput';

const EditTimeDurationInput = ({
	value,
	label,
	maxTimeUnit,
	minTimeUnit,
	onCancel,
	onSubmit,
	obligatory,
	onError,
	error,
	description,
	variableName
}: {
	value: string | null;
	label: string;
	maxTimeUnit: TimeUnit;
	minTimeUnit: TimeUnit;
	onCancel: () => void;
	onSubmit: (value: string | null) => void;
	obligatory: boolean;
	onError: (error: string | undefined) => void;
	error: string | undefined;
	description?: string;
	variableName: string;
}) => {
	const ref = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (ref.current) {
			ref.current.focus();
			ref.current.value = value || '';
		}
	}, [ref]);

	return (
		<Input
			type={InputType.Text}
			label={label}
			ref={ref}
			labelHint={formatLabelHint({
				maxTimeUnit,
				minTimeUnit
			})}
			id={variableName}
			description={description}
			obligatory={obligatory}
			error={error}
			onChange={() => {
				onError(undefined);
			}}
			onBlur={() => {
				onError(undefined);

				const value = ref.current?.value;

				if (!value) {
					onCancel();
					return;
				}

				const input = {
					input: value,
					maxTimeUnit,
					minTimeUnit
				};

				const error = validateInput(input);

				if (error) {
					onError(error);
					return;
				}

				onSubmit(value);
			}}
		/>
	);
};

const formatLabelHint = ({
	maxTimeUnit,
	minTimeUnit
}: {
	maxTimeUnit: TimeUnit;
	minTimeUnit: TimeUnit;
}) => {
	const intervals: TimeUnit[] = [];

	const intervalKeys = Object.keys(TIME_UNIT_DICT.full);

	const startIndex = intervalKeys.indexOf(maxTimeUnit);
	const endIndex = intervalKeys.indexOf(minTimeUnit);

	for (let i = startIndex; i <= endIndex; i++) {
		intervals.push(intervalKeys[i] as TimeUnit);
	}

	return `(${intervals.map(interval => TIME_UNIT_DICT.prefix[interval]).join(':')})`;
};

export function validateInput({
	input,
	maxTimeUnit,
	minTimeUnit
}: {
	input: string;
	maxTimeUnit: TimeUnitKey;
	minTimeUnit: TimeUnitKey;
}): string | undefined {
	if (input === '0') {
		return undefined;
	}

	// Validate input is only numbers and colons
	if (!/^(\d+:)*\d+$/.test(input)) {
		return 'Input must contain only numbers and colons';
	}

	const parts = input.split(':');
	const TIME_UNITS: TimeUnitKey[] = [
		'weeks',
		'days',
		'hours',
		'minutes',
		'seconds',
		'milliseconds',
		'microseconds'
	];

	const maxIndex = TIME_UNITS.indexOf(maxTimeUnit);
	const minIndex = TIME_UNITS.indexOf(minTimeUnit);

	if (maxIndex === -1 || minIndex === -1) {
		return 'Invalid time unit specified';
	}

	if (maxIndex > minIndex) {
		return 'maxTimeUnit must be larger than minTimeUnit';
	}

	const expectedUnits = minIndex - maxIndex + 1;
	if (parts.length !== expectedUnits) {
		return `Expected ${expectedUnits} units, got ${parts.length}`;
	}

	return undefined;
}
