import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual, isEmpty, omit } from 'lodash';
import { OptionalObjectSchema } from 'yup/lib/object';
import { format } from 'date-fns';

import { VariableUniquenessType } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import { RevisionChangesModal } from 'components/Dataset/Revisions/Modal';
import { PermissionTag } from 'components/UI/PermissionTag';
import { SYSTEM_GENERATED_STATUS_NAME } from 'consts';
import { Colors, Svgs } from 'environment';
import { printEntry } from 'helpers/printEntry';
import { StatusPermissions } from 'store/data/collaborators';
import {
	ActionTypes as EntriesActionTypes,
	DynamicFormFiles,
	DynamicFormValues,
	EntryStatusValue,
	EntryValues,
	FormFieldPageLocationByName,
	FormGroupPaginationDataByname,
	SetGroupPaginationDataInput,
	VariableFilteringMap,
	Entry
} from 'store/data/entries';
import { BooleanMap, EntryDrawerType, InputType } from 'types/index';

import {
	AddEditInputsAndGroups,
	AddEditInputsAndGroupsFormDesigner,
	DependenciesMapChecker,
	OpenCustomsMapChecker
} from 'components/Dataset/AddEditForm';

import { EntryDrawer } from './EntryDrawer';
import { useFormsDrawer } from './FormsListDrawer/useFormsDrawer';
import { SeriesEntryForm } from './SeriesEntryForm';
import { Container, Content } from './AddEditForm.style';
import { prepareEntryValues } from './helpers';

import { ViewPermissionModal } from '../Modals/ViewPermissionModal/ViewPermissionModal';
import { Revision, RevisionChanges } from 'store/data/revisions';
import { useSetRevisionId } from 'hooks/store/data/revisions/useSetRevisionId';
import { RestoreEntryModal } from '../Modals/RestoreEntryModal/RestoreEntryModal';
import { UnrestoredRevisionModal } from '../Modals/UnrestoredRevisionModal/UnrestoredRevisionModal';
import { Dropdown } from 'components/UI/Dropdown';
import { Flex } from 'components/UI/Flex';
import { HistoryPagination } from 'components/UI/HistoryPagination';
import { Button } from 'components/UI/Interactables/Button';
import { SplitButton } from 'components/UI/Interactables/SplitButton';
import { Tooltip } from 'components/UI/Interactables/Tooltip';
import { Modal } from 'components/UI/Modal';
import { PromptToSave } from 'components/UI/PromptToSave';
import { Typography } from 'components/UI/Typography';
import { Icon } from 'components/UI/Icons';
import { Header } from 'components/Header';
import {
	parseFormValues,
	entryFormReadOnlyModalEvent,
	entryFormChangePageEvent,
	withCustomSuffix
} from 'helpers/entries';
import { useCustomEventListener } from 'helpers/events';
import { loseFocus } from 'helpers/generic';
import { isValidDate } from 'helpers/isValidDate';
import { objectDifference } from 'helpers/objects';
import { Input } from 'components/UI/Inputs/Input';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useActivity,
	useUpdateEntry,
	useCreateEntry,
	useDeleteEntry,
	useCurrentRevision,
	useSelectedSeriesEntry,
	useProjectId,
	useProject,
	useEntry,
	useEntryDraft,
	useSaveEntryDraft,
	useVariablesData,
	useRevisionId,
	useRevisions,
	useRevision,
	useCurrentChangeOfRevision,
	useFormId,
	useForms,
	useEntryGoToFirstFieldError,
	useEntryGoToChange,
	useEntryFiles,
	useEntryStatus,
	useStatuses,
	useStatusPermissions,
	useEntryWriteAccess,
	useApplyEntryDraft,
	useEntryFormGroupsState,
	useForm,
	useEntryDeleteAccess,
	useOrganizationsByCollaborator,
	useUsername,
	useOrganizationById,
	useCollaborators,
	useConflictedData,
	usePermissions,
	useForbidPersonalData
} from 'hooks/store';
import { useModalState, useReactForm, useWindowSize } from 'hooks/ui';
import { useStatic, useRender, usePrevious, useKeyPress, useCompletedAction } from 'hooks/utils';
import { EntryMobileDrawer } from './EntryDrawer/EntryMobileDrawer';
import { AssignProjectOrganizationModal } from '../Modals';
import { ConflictsModal, MergedFormValues } from './ConflictsModal/ConflictsModal';
import PersonalDataWarning from 'components/Projects/PersonalDataWarning/PersonalDataWarning';
import { unstable_batchedUpdates } from 'react-dom';
import { useTracking } from 'app/tracking/TrackingProvider';

const NO_ORGANIZATION_ID = '-1';

enum VIEWS {
	Details = 'details',
	History = 'revisions',
	Status = 'status'
}

interface Props {
	isEdit: boolean;
	initialValues: DynamicFormValues;
	validationSchema: OptionalObjectSchema<any>;
	files: DynamicFormFiles;
}

export interface UpdateData {
	conflictsFormValues?: EntryValues;
	selectedStatus?: EntryStatusValue;
	shouldUpdateStatus?: boolean;
}
const VERSION_DATE_YEAR_TIME_FORMAT = 'MMM dd, yyyy, h:mm a';

export function AddEditForm({ isEdit, validationSchema, initialValues, files }: Props) {
	const { translate } = useTranslation();
	const { routes, navigate } = useNavigation();

	const { track } = useTracking();

	const [tooltipContainer, setTooltipContainer] = useState<HTMLDivElement | null>(null);
	const getTooltipContainer = (node: HTMLDivElement | null) => {
		node && setTooltipContainer(node);
	};
	const username = useUsername();

	/*
	 ** ASSIGN GROUP STATE
	 */
	useCollaborators({ lazy: false });
	const userOrganizations = useOrganizationsByCollaborator(username ?? undefined);
	const [showAssignOrganizationModal, setShowAssignOrganizationModal] = useState(false);
	const [assignedOrganizationId, setAssignedOrganizationId] =
		useState<string>(NO_ORGANIZATION_ID);
	const assignedOrganization = useOrganizationById(assignedOrganizationId ?? undefined);
	const { hasDatasetWriteOrgAccess } = usePermissions();
	const selectableOrganizations = useMemo(() => {
		if (hasDatasetWriteOrgAccess) return userOrganizations;
		return [];
	}, [userOrganizations, hasDatasetWriteOrgAccess]);

	const userOrganizationId = useMemo(() => {
		let userOrganizationId = NO_ORGANIZATION_ID; // NO GROUPS BASE CASE
		if (selectableOrganizations.length === 1) {
			userOrganizationId = selectableOrganizations[0].id;
		}
		if (selectableOrganizations.length > 1) {
			userOrganizationId = assignedOrganizationId;
		}
		return userOrganizationId;
	}, [userOrganizations, selectableOrganizations, assignedOrganizationId]);

	const [{ data: entry }] = useEntry();
	const currentEntryStatus = useEntryStatus(true);

	const [{ loading: loadingSeriesEntries }] = useActivity(EntriesActionTypes.GET_SERIES_ENTRIES);
	const [{ loading: loadingLatestEntry }] = useActivity(EntriesActionTypes.GET_LATEST_ENTRY);

	const [{ loading: updatingEntry, error: errorUpdatingEntry }, updateEntry] = useUpdateEntry();
	const [{ loading: creatingEntry, error: errorCreatingEntry }, createEntry] = useCreateEntry();
	const [{ loading: deletingEntry, error: errorDeletingEntry }, deleteEntry] = useDeleteEntry();

	function handleDeleteEntry() {
		setConfirmDeleteEntryInput('');
		deleteEntry();
	}

	// data submitted in conflicts scenario
	const [conflictDraft, setConflictDraft] = useState<MergedFormValues>({
		values: omit(entry, 'status_column') as Entry,
		status: currentEntryStatus
	});
	const [conflictedData, setConflictedData] = useConflictedData();

	const [currentRevision, setCurrentRevision] = useCurrentRevision();
	const [initialRevision, setInitialRevision] = useState<Revision | null>();

	const [selectedSeriesEntry] = useSelectedSeriesEntry();

	const [unrestoredRevisionModalVisible, setUnrestoredRevisionModalVisible] = useState(false);

	const [projectId] = useProjectId();

	const { forbidPersonalData } = useForbidPersonalData(projectId ?? '');

	const [{ data: project }] = useProject();

	const formsDrawer = useFormsDrawer();

	const [{ data: entryDraft, loading: loadingEntryDraft, fetched: fetchedEntryDraft }] =
		useEntryDraft();
	const [{ loading: savingEntryDraft, error: errorSavingEntryDraft }, saveEntryDraft] =
		useSaveEntryDraft();

	const variablesData = useVariablesData({ initial: true });

	const { variablesMap, variableSetsMap } = variablesData;

	const [seriesRevisionId] = useSetRevisionId();

	const [revisionId, setRevisionId] = useRevisionId();
	const [lazyRevisions, setLazyRevisions] = useState(true);
	const [{ data: revisions, loading: loadingRevisions, fetched: areRevisionsFetched }] =
		useRevisions({
			lazy: lazyRevisions
		});
	const [{ data: revision, loading: loadingRevisionData }] = useRevision({ lazy: true });
	const [currentChange] = useCurrentChangeOfRevision();

	const [formId, setFormId] = useFormId();
	const [{ data: form }] = useForm({ lazy: true });
	const [{ data: forms, fetched: areFormsFetched }] = useForms({
		lazy: true
	});

	const { goToFirstFieldError } = useEntryGoToFirstFieldError({ form });
	const { goToChange } = useEntryGoToChange({ form });

	useEntryFiles({ entryFiles: files });

	const [uniqueFieldsError, setUniqueFieldsError] = useState<string[]>([]);
	const [saveAsDraft, setSaveAsDraft] = useState(false);
	const [openCustomsMap, setOpenCustomsMap] = useState<BooleanMap>({});
	const [variableVisibilityMap, setVariableVisibilityMap] = useState<BooleanMap>({});
	const [variableFilteringMap, setVariableFilteringMap] = useState<VariableFilteringMap>({});
	// CONFLICTS
	const [conflictModalVisible, setConflictModalVisible] = useState(false);
	useEffect(() => {
		if (!conflictModalVisible) setConflictModalVisible(true);
	}, [conflictedData]);

	// DELETE ENTRY MODAL STATE
	const [deleteEntryModalVisible, setDeleteEntryModalVisible] = useState(false);
	const deleteEntryModal = {
		visible: deleteEntryModalVisible,
		open: () => setDeleteEntryModalVisible(true),
		close: () => setDeleteEntryModalVisible(false)
	};
	const [confirmDeleteEntryInput, setConfirmDeleteEntryInput] = useState('');
	const deleteEntryConfirmationTarget = translate(
		dict => dict.addEditForm.deleteEntryConfirmationTarget
	);
	const isValidDeleteConfirmation = confirmDeleteEntryInput === deleteEntryConfirmationTarget;

	// RESTORE ENTRY MODAL STATE
	const [restoreEntryModalVisible, setRestoreEntryModalVisible] = useState(false);
	const restoreEntryModal = {
		visible: restoreEntryModalVisible,
		open: () => setRestoreEntryModalVisible(true),
		close: () => setRestoreEntryModalVisible(false)
	};

	const [entryDrawerOpen, setEntryDrawerOpen] = useState(false);

	// READ ONLY MODAL STATE
	const readOnlyModal = useModalState({
		onOpen: loseFocus
	});

	// REVISIONS STATES
	const [revisionsModalVisible, setRevisionsModalVisible] = useState(false);
	const [nextRevisionCreationDate, setNextRevisionCreationDate] = useState<string | undefined>();

	// FORM STATE
	const {
		Form,
		FormProvider,
		handleSubmit,
		getValues,
		setValue,
		reset,
		formProviderProps,
		isDirty: _formHasChanges,
		isValid,
		dirtyFields,
		isSubmitting,
		errors
	} = useReactForm({
		initialValues,
		validationSchema,
		// set `shouldFocusError` to `false` to enable custom scroll-into-view with sorted errors
		shouldFocusError: false,
		enableReinitialize: true,
		registerInitialValues: true
	});

	// STATUS STATE
	const entryStatus = revision ? revision.statusChanges.data : currentEntryStatus;

	const [
		{
			data: { statuses, statusesMap, hasStatuses }
		}
	] = useStatuses({ lazy: true });

	const initialStatus = useMemo(() => entryStatus, [entryStatus]);
	const [selectedStatus, setSelectedStatus] = useState(initialStatus);
	const [entryStatusHasChanges, setEntryStatusHasChanges] = useState(
		!isEqual(entryStatus, selectedStatus)
	);

	const onlyStatusChanged = !_formHasChanges && !isEqual(initialStatus, selectedStatus);

	const { statusPermissionsMap } = useStatusPermissions();

	const entryWriteAccess = useEntryWriteAccess();
	const entryDeleteAccess = useEntryDeleteAccess();

	//DEVICE SIZES
	const { width } = useWindowSize();

	const isMobileDevice = width < 768;

	const isDesktopDevice = width > 1024;

	// CHECK IF USER CAN SET ONE OF THE STATUSES -> GIVE WRITE ACCESS
	const hasStatusToSet = Object.entries(statusPermissionsMap).some(
		([statusName, statusPermissions]) =>
			statusName !== SYSTEM_GENERATED_STATUS_NAME && statusPermissions.setStatus
	);

	// CHECK IF USER CAN MODIFY FORM DATA WITH THE CURRENT SELECTED STATUS
	const canModifyFormDataByStatus = getCanModifyFormDataByStatus();

	function getCanModifyFormDataByStatus() {
		if (!isEdit) return true;

		if (!hasStatuses) return true;

		if (!entryStatus) {
			const noStatusPermissions = statusPermissionsMap[SYSTEM_GENERATED_STATUS_NAME];

			if (noStatusPermissions) return noStatusPermissions.editData;

			return false;
		}

		const statusPermissions = statusPermissionsMap[entryStatus.variableName];

		return !!statusPermissions && statusPermissions.editData;
	}

	// CHECK IF USER CAN AT LEAST SET STATUS OR FORM DATA OF ENTRY
	const globalStatusWriteAccess = hasStatuses
		? hasStatusToSet || canModifyFormDataByStatus
		: true;

	const hasWriteAccess = isEdit ? entryWriteAccess && globalStatusWriteAccess : true;
	const hasDeleteAccess = isEdit ? entryDeleteAccess : true;

	const { viewData: hasViewAccessByStatus, editData: hasWriteAccessByStatus } =
		getEntryStatusPermissions();

	function getEntryStatusPermissions() {
		const permissions: StatusPermissions = {
			viewData: true,
			editData: true,
			setStatus: true
		};

		const data = { permissions };

		if (hasStatuses) {
			const statusPermissions =
				statusPermissionsMap[entryStatus?.variableName ?? SYSTEM_GENERATED_STATUS_NAME];

			if (!statusPermissions) {
				data.permissions = {
					viewData: false,
					editData: false,
					setStatus: false
				};
			} else {
				data.permissions = statusPermissions;
			}
		}

		return data.permissions;
	}

	/**
	 * USED FOR:
	 * - STAYING ON THE SAME PAGE AFTER CREATE / UPDATE
	 * - CREATE ENTRY WITHOUT REDIRECT + RESET FORM
	 */
	const [shouldStayOnSamePage, setStayOnSamePage] = useStatic(true);
	const [shouldCreateAnotherEntry, setCreateAnotherEntry] = useStatic(false);

	const [disablePromptToSave, setDisablePromptToSave] = useState(false);

	const [drawerHistoryView, setDrawerHistoryView] = useState<VIEWS | null>(null);

	const [getFormFieldPageLocationByName, setFormFieldPageLocationByName] =
		useStatic<FormFieldPageLocationByName>({});
	const [getGroupPaginationDataByName, setGroupPaginationDataByName] =
		useStatic<FormGroupPaginationDataByname>({});

	const [targetRevisionId, setTargetRevisionId] = useStatic<string | null>(null);

	const [, triggerRender] = useRender();

	const hasDraft = !!entryDraft?.values;

	const draftHasDifferences =
		!!entryDraft?.differenceFromActiveEntry?.length ||
		(hasDraft && !isEqual(entryDraft?.values, parseFormValues({ ...initialValues })));

	const formHasChanges = _formHasChanges || draftHasDifferences;

	const canSubmitForm = formHasChanges && isValid;

	const isRevisionSelected = useMemo(() => !!revision, [revision]);

	const isSetRevisionSelected = useMemo(() => !!seriesRevisionId, [seriesRevisionId]);

	const isLatestRevisionSelected = useMemo(() => {
		return (
			revisions?.length &&
			revision?.creationDate === revisions[revisions.length - 1].creationDate
		);
	}, [revision, revisions]);

	const selectedVariableSet = variableSetsMap[selectedSeriesEntry.setName ?? ''] ?? null;

	// use case: can bypass validation in case only statuses changed
	// (statuses data are not stored in the form hook anyway, hence it is not validated and it doesn't need to trigger the validation for the rest of the fields)
	function handleSubmitSuccessCallback() {
		if (!isRevisionSelected && (!(canSubmitForm || entryStatusHasChanges) || !hasWriteAccess)) {
			resetCreateAnother();
			resetStayOnSamePage();
			resetDisablePromptToSave();
			return;
		}

		// UPDATE ENTRY
		if (isEdit) {
			handleUpdateEntry();
		}
		// CREATE ENTRY
		else {
			// check if we need to assign a group first;
			if (userOrganizationId === NO_ORGANIZATION_ID && selectableOrganizations.length > 1) {
				setShowAssignOrganizationModal(true);
				return;
			}
			handleCreateEntry();
		}
	}

	const handleOnSubmit = () => {
		// Bypass validation when changing just the status
		// statuses are not part of form state anyway
		const shouldValidate = !isEdit;

		onlyStatusChanged && !shouldValidate
			? new Promise<void>((resolve, _) => {
					handleSubmitSuccessCallback();
					resolve();
			  })
			: handleSubmit(
					// successful submit => no errors
					() => {
						setDisablePromptToSave(true);
						return new Promise<DynamicFormValues>(resolve => {
							handleSubmitSuccessCallback();
							resolve({} as DynamicFormValues);
						});
					},
					// failed submit => has errors
					errors => {
						resetCreateAnother();
						resetStayOnSamePage();
						resetDisablePromptToSave();
						goToFirstFieldError({
							fieldNames: Object.keys(errors),
							formFieldPageLocationByName: getFormFieldPageLocationByName()
						});
						setDisablePromptToSave(false);
					}
			  )();
	};

	function handleMergeConflicts({ values: entry, status }: MergedFormValues) {
		const values = entry as DynamicFormValues;
		reset({ ...getValues(), ...values });
		setSelectedStatus(status !== undefined ? status : selectedStatus);
		handleOnSubmit();
	}

	// APPLY DRAFT VALUES (IF DRAFT EXISTS)
	useApplyEntryDraft({
		reactFormState: { values: getValues() },
		reactFormMethods: { reset },
		setOpenCustomsMap
	});

	const { groupsExpanded, setGroupExpanded, collapseGroups, expandGroups, canExpand } =
		useEntryFormGroupsState({
			errors,
			isSubmitting
		});

	// SYNC `selectedStatus` STATE
	useEffect(() => {
		if (!isEqual(selectedStatus, entryStatus)) setSelectedStatus(entryStatus);
	}, [entryStatus]);

	// improve status changes tracking when creating new entry
	const prevEntryStatus = usePrevious(entryStatus);
	const prevLoadingCreating = usePrevious(creatingEntry);
	useEffect(() => {
		if (!creatingEntry && prevLoadingCreating && !prevEntryStatus) {
			setEntryStatusHasChanges(false);
		} else {
			if (selectedStatus === null || entryStatus === null) {
				return setEntryStatusHasChanges(!isEqual(entryStatus, selectedStatus));
			}

			setEntryStatusHasChanges(false);
		}
	}, [prevEntryStatus, selectedStatus, creatingEntry]);

	useKeyPress(
		{
			onEscapeKeyPress: goToDataset,
			onDeleteKeyPress: canDelete() ? deleteEntryModal.open : undefined
		},
		{
			noModalsOpened: true,
			listen: !(selectedVariableSet || entryDrawerOpen)
		}
	);

	// LISTEN FOR CUSTOM READ ONLY EVENT
	useCustomEventListener(entryFormReadOnlyModalEvent, {
		onListen: () => {
			if (!isRevisionSelected && !isSetRevisionSelected) readOnlyModal.open();
		}
	});

	// CREATE EFFECT
	useCompletedAction(
		creatingEntry,
		errorCreatingEntry,
		// SUCCESS CALLBACK
		() => {
			setAssignedOrganizationId(NO_ORGANIZATION_ID);
			if (shouldStayOnSamePage()) {
				resetStayOnSamePage();

				if (shouldCreateAnotherEntry()) {
					reset(initialValues);
					resetCreateAnother();
					// RESET PAGINATION
					entryFormChangePageEvent().dispatch({ pageIndex: 0 });
				}
			} else {
				setDisablePromptToSave(true);
				triggerRender();
				setTimeout(goToDataset, 100);
			}
		},
		// ERROR CALLBACK
		() => {
			resetStayOnSamePage();
			setAssignedOrganizationId(NO_ORGANIZATION_ID);
		}
	);

	// UPDATE EFFECT
	useCompletedAction(
		updatingEntry || !(isEmpty(conflictedData?.values) && isEmpty(conflictedData?.statuses)),
		errorUpdatingEntry,
		// SUCCESS CALLBACK
		() => {
			setAssignedOrganizationId(NO_ORGANIZATION_ID);

			if (shouldStayOnSamePage()) {
				resetStayOnSamePage();
			} else {
				if (!uniqueFieldsError.length) {
					setDisablePromptToSave(true);
					setTimeout(goToDataset, 100);
				}
			}
		},
		// ERROR CALLBACK
		() => {
			resetStayOnSamePage();
			setAssignedOrganizationId(NO_ORGANIZATION_ID);
		}
	);

	// DELETE EFFECT
	useCompletedAction(deletingEntry, errorDeletingEntry, () => {
		setDisablePromptToSave(true);
		setTimeout(goToDataset, 100);
	});

	// SAVE ENTRY DRAFT EFFECT
	useCompletedAction(savingEntryDraft, errorSavingEntryDraft, () => {
		if (!shouldStayOnSamePage()) {
			goToDataset();
		} else {
			setDisablePromptToSave(true);
		}
	});

	// SWITCH TO FIRST FORM WITH ERRORS
	const wasSubmitting = usePrevious(isSubmitting);
	useEffect(() => {
		const fieldsWithErrors = Object.keys(errors);
		const hasError = fieldsWithErrors.length > 0;

		if (!(form && areFormsFetched && wasSubmitting && hasError)) return;

		const { usedVariables: formVariables } = form;

		const errorNotInCurrentForm =
			formVariables.filter(
				variableName => !!fieldsWithErrors.find(error => error === variableName)
			).length === 0;

		if (errorNotInCurrentForm) {
			const firstErroredField = fieldsWithErrors[0];
			const firstFormIdWithError = forms.find(
				({ active, usedVariables }) =>
					active && usedVariables.find(variableName => variableName === firstErroredField)
			)?.id;

			if (firstFormIdWithError) setFormId(firstFormIdWithError);
		}
	}, [form, areFormsFetched, errors, isSubmitting]);

	/*
		CHECK IF SELECTED REVISION HAS CHANGES AND SET SPECIFIC FORM ACTIVE
		WHERE CHANGES ARE FOUND IF NOT PRESENT CURRENT ACTIVE FORM
	*/
	const isFormActive = formId !== null;
	useEffect(() => {
		if (revision && areFormsFetched && isFormActive) {
			let formIdWhereChangesArePresent: string | null = null;
			let hasChangesInInactiveForm = false;

			revision.changes.variableNames.forEach(variableName => {
				if (formIdWhereChangesArePresent !== null) return;

				forms.forEach(form => {
					if (formIdWhereChangesArePresent !== null) return;

					const isChangeInForm = form.usedVariables.includes(variableName);

					if (isChangeInForm) {
						if (form.active) {
							formIdWhereChangesArePresent = form.id;
						} else {
							hasChangesInInactiveForm = true;
						}
					}
				});
			});

			// CHANGES NOT PRESENT IN CURRENT FORM => SET ACTIVE FORM WHERE CHANGES ARE PRESENT
			if (formIdWhereChangesArePresent !== null && formId !== formIdWhereChangesArePresent) {
				setFormId(formIdWhereChangesArePresent);
			}
			// CHANGES PRESENT IN AN INACTIVE FORM
			if (hasChangesInInactiveForm) {
				// do something here (eg: show a notification so the user knows)
			}
		}
	}, [revision, forms, areFormsFetched, isFormActive]);

	const checkForms = useMemo(() => {
		let checkForms = false;
		if (revision && areFormsFetched) {
			revision.changes.variableNames.forEach(variableName => {
				if (checkForms) return;

				forms.forEach(form => {
					if (checkForms) return;

					const isChangeInForm = form.usedVariables.includes(variableName);

					if (isChangeInForm) {
						checkForms = true;
					}
				});
			});
		}
		if (revision?.statusChanges.from !== revision?.statusChanges.to) {
			checkForms = true;
		}
		return checkForms;
	}, [revision, forms, areFormsFetched]);

	useEffect(() => {
		if (revision && areFormsFetched) {
			const orderedVariableNames: string[] = [];
			const list: RevisionChanges[] = [];
			forms.forEach(form => {
				if (form.active) {
					revision.changes.variableNames.map(variableName => {
						if (form.usedVariables.includes(variableName)) {
							orderedVariableNames.push(variableName);
							list.push(
								revision.changes.list.filter(
									variable => variable.variableName === variableName
								)[0]
							);
						}
					});
				}
			});
			if (orderedVariableNames.length && list.length) {
				setCurrentRevision({
					...revision,
					changes: {
						...revision.changes,
						variableNames: orderedVariableNames,
						list: list
					}
				});
			}
			if (!initialRevision) {
				setInitialRevision(revision);
			}
		}
		if (!revision && areFormsFetched) {
			setInitialRevision(null);
		}
	}, [revision, forms, areFormsFetched]);

	// FORM HANDLERS
	function handleCreateEntry() {
		if (uniqueFieldsError.length > 0) setUniqueFieldsError([]);

		const formValues = parseFormValues({ ...getValues() });
		const shouldSetEntryId = shouldCreateAnotherEntry() ? false : !!shouldStayOnSamePage();
		const shouldUpdateStatus = hasStatuses && entryStatusHasChanges;

		// Filter out autogenerated unique var payload;
		const values = prepareEntryValues(variablesMap, formValues);

		createEntry(
			{
				entryValues: values,
				entryStatus: selectedStatus,
				organizationId: userOrganizationId,
				options: {
					setEntryId: shouldSetEntryId,
					updateStatus: shouldUpdateStatus,
					canViewCreatedEntry: hasViewAccessByStatus
				},
				...(entryDraft && {
					draftEntryId: entryDraft.draftEntryId
				})
			},
			{
				onUniqueError: variableNames => setUniqueFieldsError(variableNames)
			}
		);
	}

	function handleUpdateEntry() {
		if (uniqueFieldsError.length > 0) {
			setUniqueFieldsError([]);
		}

		const formValues = parseFormValues({ ...getValues() });

		const shouldUpdateEntry =
			formHasChanges || isRevisionSelected || !isEmpty(entryDraft?.differenceFromActiveEntry);
		const applyRevisionStatus =
			revision && !isEqual(currentEntryStatus, revision.statusChanges.data);
		const shouldUpdateStatus = hasStatuses && (entryStatusHasChanges || applyRevisionStatus);

		const values = prepareEntryValues(variablesMap, formValues);

		updateEntry(
			{
				entryValues: values,
				entryStatus:
					conflictedData?.statuses.current !== undefined
						? conflictDraft?.status
						: selectedStatus,
				options: {
					updateEntry: shouldUpdateEntry,
					updateStatus: shouldUpdateStatus
				},
				...(entryDraft && {
					draftEntryId: entryDraft.draftEntryId
				}),
				...(revision && {
					ignoreInputValidation: true
				})
			},
			{
				onUniqueError: variableNames => setUniqueFieldsError(variableNames)
			}
		);
	}

	const handleSaveEntryDraft = handleSubmit(
		// successful submit => no errors
		() => {
			if (uniqueFieldsError.length > 0) {
				setUniqueFieldsError([]);
			}
			if (!(canSubmitForm && hasWriteAccess)) return;

			const values = parseFormValues({ ...getValues() });

			/**
			 * - INCLUDE ONLY MODIFIED FIELDS
			 * - FILTER OUT `file` VARIABLE TYPE FIELDS
			 */
			const validValues = Object.entries(values).reduce<EntryValues>((acc, [key, value]) => {
				const customKey = withCustomSuffix(key);

				const draftHadValue = entry
					? entryDraft &&
					  key in entryDraft.values &&
					  entryDraft.values[key] !== null &&
					  entryDraft.values[key] !== entry[key]
					: entryDraft && key in entryDraft.values && entryDraft.values[key] !== null;

				const fieldIsDirty = !!(
					entryDraft?.differenceFromActiveEntry?.[key] || dirtyFields[key]
				);
				const customFieldIsDirty = !!(
					entryDraft?.differenceFromActiveEntry?.[customKey] || dirtyFields[customKey]
				);

				const fieldIsModified = fieldIsDirty || customFieldIsDirty || draftHadValue;
				const fieldIsNotFileType = variablesMap[key].type !== VariableType.File;
				const isUnique =
					variablesMap[key].type === VariableType.Unique &&
					(variablesMap[key].uniquenessType === VariableUniquenessType.Sequence ||
						variablesMap[key].uniquenessType === VariableUniquenessType.UUID);

				if (!isUnique && fieldIsModified && fieldIsNotFileType) acc[key] = value;

				return acc;
			}, {});

			saveEntryDraft(
				{
					entryValues: validValues,
					...(entryDraft && {
						draftEntryId: entryDraft.draftEntryId
					})
				},
				{
					onUniqueError: variableNames => setUniqueFieldsError(variableNames)
				}
			);
		},
		// failed submit => has errors
		errors => {
			goToFirstFieldError({
				fieldNames: Object.keys(errors),
				formFieldPageLocationByName: getFormFieldPageLocationByName()
			});
		}
	);

	function onSelectRevision(id: string) {
		const nextRevisionId = id === revisionId ? null : id;
		if (!nextRevisionId) {
			return;
		}

		if (revisions) {
			const nextRevDate = revisions.find(
				rev => rev.revisionId === nextRevisionId
			)?.creationDate;
			setNextRevisionCreationDate(nextRevDate);
		}

		if (formHasChanges) {
			setTargetRevisionId(nextRevisionId);
			setRevisionsModalVisible(true);
		} else {
			setRevisionId(nextRevisionId);
		}
	}

	function setGroupPaginationData(input: SetGroupPaginationDataInput) {
		const { groupName, paginationData } = input;

		setGroupPaginationDataByName(state => {
			state[groupName] = paginationData;
		});
	}
	function handleSaveAndClose() {
		setStayOnSamePage(false);
		setDisablePromptToSave(true);
		if (!isEmpty(conflictedData?.values) || !isEmpty(conflictedData?.statuses)) {
			return handleMergeConflicts(conflictDraft);
		}
		handleOnSubmit();

		if (!(formHasChanges || entryStatusHasChanges)) goToDataset();
	}

	function handleSaveDraftAndClose() {
		{
			resetStayOnSamePage();
			handleSaveEntryDraft();
		}
	}

	function onSaveClick() {
		setStayOnSamePage(true);
		if (!isEmpty(conflictedData?.values) || !isEmpty(conflictedData?.statuses)) {
			return handleMergeConflicts(conflictDraft);
		}
		handleOnSubmit();
	}

	function handleSaveAndCreateNew() {
		setStayOnSamePage(true);
		setCreateAnotherEntry(true);
		if (!isEmpty(conflictedData?.values) || !isEmpty(conflictedData?.statuses)) {
			return handleMergeConflicts(conflictDraft);
		}
		handleOnSubmit();
	}

	function resetStayOnSamePage() {
		setStayOnSamePage(false);
	}

	function resetDisablePromptToSave() {
		setDisablePromptToSave(true);
	}

	function resetCreateAnother() {
		setCreateAnotherEntry(false);
	}

	function resetEntryStatus() {
		setSelectedStatus(initialStatus);
	}

	function goToDataset() {
		if (projectId) navigate(routes.projects.dataset.view(projectId));
	}

	const closeAddEditForm = () => {
		if (!revisionId || unrestoredRevisionModalVisible || isLatestRevisionSelected) {
			if (projectId) navigate(routes.projects.dataset.view(projectId));
			return;
		}
		if (!unrestoredRevisionModalVisible && !isLatestRevisionSelected) {
			setUnrestoredRevisionModalVisible(true);
		}
	};

	function handleRevisionModalPrimaryClick() {
		reset();
		resetEntryStatus();
		setRevisionId(targetRevisionId());
		closeRevisionModal();
	}

	function closeRevisionModal() {
		setTargetRevisionId(null);
		if (setRevisionsModalVisible) setRevisionsModalVisible(false);
	}

	const prevFetched = usePrevious(fetchedEntryDraft);

	const promptToSave: boolean = useMemo(() => {
		if (disablePromptToSave) return false;
		// first arriving on page with a draft;
		if (prevFetched && fetchedEntryDraft && !isEmpty(entryDraft?.differenceFromActiveEntry))
			return true;

		if (entryDraft) {
			const values = parseFormValues({ ...getValues() });
			const parsedInitialValues = parseFormValues({ ...initialValues });

			const modifiedValues = objectDifference(parsedInitialValues, values);
			return !isEqual(entryDraft.values, modifiedValues);
		}

		if (entryStatusHasChanges) return true;

		return formHasChanges;
	}, [
		disablePromptToSave,
		entryDraft,
		initialValues,
		formHasChanges,
		_formHasChanges,
		fetchedEntryDraft,
		entryStatusHasChanges,
		prevFetched
	]);

	function canDelete() {
		const writeAccess = isEdit && hasWriteAccess && hasWriteAccessByStatus;
		const loading =
			updatingEntry ||
			loadingEntryDraft ||
			savingEntryDraft ||
			loadingRevisionData ||
			loadingSeriesEntries ||
			loadingLatestEntry;

		return writeAccess && !loading;
	}
	function handlePrintEntry() {
		void track({
			eventName: 'print_entry_clicked',
			data: {
				entryType: 'entry'
			}
		});
		printEntry({ entry, form, project, variablesData });
	}
	const AddEditInputsAndGroupsGeneralProps = {
		...(tooltipContainer ? { tooltipContainer } : {}),
		openCustomsMap,
		variableVisibilityMap,
		variableFilteringMap,
		groupsExpanded,
		setGroupExpanded,
		revision: form ? currentRevision ?? undefined : revision,
		currentChange: currentChange,
		isRevisionSelected: isRevisionSelected,
		readOnly: !hasWriteAccess || !canModifyFormDataByStatus || isRevisionSelected,
		selectedStatus,
		setSelectedStatus,
		uniqueFieldsError,
		setFormFieldPageLocationByName,
		getGroupPaginationDataByName,
		setGroupPaginationData,
		setStayOnSamePage,
		initialValues
	};

	const loadingAction = creatingEntry || updatingEntry;

	const freezeForm = creatingEntry || updatingEntry || loadingLatestEntry || loadingRevisionData;
	// CLOSE RESTORE MODAL AFTER CREATION
	useCompletedAction(loadingAction, errorUpdatingEntry, restoreEntryModal.close);

	function goToChangedVariable(variableNames: string[], currentChangeLocal: number) {
		goToChange({
			fieldNames: variableNames,
			formFieldPageLocationByName: getFormFieldPageLocationByName(),
			currentChangeLocal
		});
	}
	const getHeaderLabel = () => {
		if (revision && revision.creationDate && initialRevision) {
			const headerValidDate = isValidDate(revision.creationDate)
				? revision.creationDate.replace(/\s/g, 'T')
				: revision.creationDate;

			return format(new Date(headerValidDate), VERSION_DATE_YEAR_TIME_FORMAT);
		}
		if (isEdit) {
			return translate(dict => dict.addEditForm.updateEntry);
		} else {
			return translate(dict => dict.addEditForm.createEntry);
		}
	};
	const onResetFields = useCallback(
		(values: DynamicFormValues) => {
			unstable_batchedUpdates(() => {
				Object.entries(values).forEach(([key, value]) => setValue(key, value));
			});
		},
		[initialValues, reset]
	);

	return (
		<>
			{!drawerHistoryView && (
				<>
					<Header.Main
						leftComponent={
							<Flex align={a => a.center}>
								<Typography.H6 ellipsis>{getHeaderLabel()}</Typography.H6>
							</Flex>
						}
						rightComponent={
							<Flex align={a => a.center}>
								{/* READ ONLY PERMISSION TAG */}
								{(!hasWriteAccess || !canModifyFormDataByStatus) && (
									<Flex
										marginOffset={{ right: 2.4 }}
										// tooltip props
										data-tip={translate(
											dict => dict.dataset.addEntryForm.viewTip
										)}
										data-for="read-only-entry"
									>
										<PermissionTag
											title={translate(
												dict => dict.dataset.addEntryForm.viewOnly
											)}
										/>
										<Tooltip
											id="read-only-entry"
											delayShow={250}
											place="bottom"
											html
										/>
									</Flex>
								)}
								{/* DELETE */}
								{isEdit &&
									hasWriteAccess &&
									hasWriteAccessByStatus &&
									hasDeleteAccess &&
									isDesktopDevice && (
										<Flex
											marginOffset={{ right: 0.8 }}
											// tooltip props
											data-tip={translate(
												dict => dict.dataset.addEntryForm.deleteTip
											)}
											data-for="delete-entry"
										>
											<Icon
												svg={Svgs.Delete}
												variant={v => v.buttonActive}
												disabled={!canDelete() || !hasDeleteAccess}
												active={deleteEntryModal.visible}
												onClick={
													loadingAction
														? undefined
														: deleteEntryModal.open
												}
											/>
											<Tooltip
												id="delete-entry"
												delayShow={250}
												place="bottom"
											/>
										</Flex>
									)}

								{/* FORMS DRAWER */}
								{formsDrawer.enabled && (
									<Flex
										marginOffset={{ right: 0.8 }}
										// tooltip props
										data-tip={`${
											formsDrawer.visible
												? translate(dict => dict.dataset.addEntryForm.hide)
												: translate(dict => dict.dataset.addEntryForm.show)
										} ${translate(dict => dict.dataset.addEntryForm.forms)}`}
										data-for="forms-drawer"
									>
										<Icon
											title={`${
												formsDrawer.visible
													? translate(
															dict => dict.dataset.addEntryForm.hide
													  )
													: translate(
															dict => dict.dataset.addEntryForm.show
													  )
											} ${translate(
												dict => dict.dataset.addEntryForm.forms
											)}`}
											svg={Svgs.FileText}
											variant={v => v.buttonActive}
											active={formsDrawer.visible}
											onClick={
												loadingAction
													? undefined
													: formsDrawer.toggleVisible
											}
										/>
										<Tooltip id="forms-drawer" delayShow={250} place="bottom" />
									</Flex>
								)}

								{/* OPTIONS */}
								<Flex marginOffset={!isMobileDevice && { right: 2.4 }}>
									<Dropdown
										toggleComponent={({ ref, open, toggle }) => (
											<Icon
												variant={v => v.buttonActive}
												svg={Svgs.More}
												ref={ref}
												active={open}
												onClick={toggle}
											/>
										)}
										width={15}
										offset={{ top: 20, right: 0 }}
									>
										{isMobileDevice && hasWriteAccess && isRevisionSelected && (
											<Dropdown.Item
												disabled={!!isLatestRevisionSelected}
												title={translate(({ buttons }) => buttons.restore)}
												onClick={
													loadingAction
														? undefined
														: restoreEntryModal.open
												}
											/>
										)}

										{isMobileDevice &&
											hasWriteAccess &&
											!isRevisionSelected && (
												<>
													<Dropdown.Item
														dataTestId="save"
														title={translate(
															({ buttons }) => buttons.save
														)}
														onClick={
															loadingAction ? undefined : onSaveClick
														}
													/>

													{!isEdit && (
														<Dropdown.Item
															dataTestId="save-and-create-new"
															title={translate(
																({ addEditForm }) =>
																	addEditForm.saveNew
															)}
															onClick={
																loadingAction
																	? undefined
																	: handleSaveAndCreateNew
															}
														/>
													)}

													<Dropdown.Item
														disabled={!hasViewAccessByStatus}
														dataTestId="save-and-close"
														title={translate(
															dict =>
																dict.dataset.addEntryForm
																	.saveAndClose
														)}
														onClick={
															loadingAction
																? undefined
																: handleSaveAndClose
														}
													/>
												</>
											)}

										{/* PRINT */}
										<Dropdown.Item
											title={translate(dict => dict.buttons.print)}
											onClick={handlePrintEntry}
										/>

										{/* Expand/collapse - groups */}
										{canExpand && (
											<>
												<Dropdown.Item
													title={translate(
														dict => dict.addEditEntry.expandGroups
													)}
													onClick={expandGroups}
												/>
												<Dropdown.Item
													title={translate(
														dict => dict.addEditEntry.collapseGroups
													)}
													onClick={collapseGroups}
												/>
											</>
										)}
										{canDelete() && hasDeleteAccess && (
											<Dropdown.Item
												title={translate(
													dict => dict.addEditForm.deleteEntry
												)}
												onClick={
													loadingAction
														? undefined
														: deleteEntryModal.open
												}
											/>
										)}
									</Dropdown>
								</Flex>

								{!isMobileDevice && (
									<>
										{hasWriteAccess ? (
											!isRevisionSelected ? (
												<SplitButton
													menuWidth={16}
													title={translate(({ buttons }) => buttons.save)}
													loading={loadingAction || savingEntryDraft}
													onClick={onSaveClick}
													options={[
														...(!isEdit
															? [
																	{
																		label: translate(
																			({ addEditForm }) =>
																				addEditForm.saveNew
																		),
																		dataTestId:
																			'save-and-create-new',
																		onClick: loadingAction
																			? undefined
																			: handleSaveAndCreateNew
																	}
															  ]
															: []),
														{
															label: translate(
																dict =>
																	dict.dataset.addEntryForm
																		.saveAndClose
															),
															disabled: !hasViewAccessByStatus,
															dataTestId: 'save-and-close',
															onClick: loadingAction
																? undefined
																: handleSaveAndClose
														}
													].flat()}
												/>
											) : (
												<Button
													onClick={restoreEntryModal.open}
													title={translate(
														({ buttons }) => buttons.restore
													)}
													disabled={!!isLatestRevisionSelected}
												/>
											)
										) : null}
									</>
								)}

								<Icon
									dataTestId="close-entry"
									svg={Svgs.Close}
									marginOffset={{ left: 2.4 }}
									variant={v => v.buttonActive}
									onClick={loadingAction ? undefined : closeAddEditForm}
								/>
							</Flex>
						}
					/>
				</>
			)}

			{forbidPersonalData && (
				<>
					<Flex
						justify={j => j.center}
						paddingOffset={{ top: 1.6 }}
						style={{
							backgroundColor: formsDrawer.component
								? Colors.background.disabled
								: Colors.white
						}}
					>
						<PersonalDataWarning
							bgType={type => (formsDrawer.component ? type.white : type.grey)}
							width={65.2}
						/>
					</Flex>
				</>
			)}
			{formsDrawer.component}
			<Container ref={getTooltipContainer}>
				{isMobileDevice && isEdit && entry && (
					<EntryMobileDrawer
						entryType={EntryDrawerType.AddEditForm}
						historyView={{
							disabled: false,
							loading: !!(areRevisionsFetched && loadingRevisions),

							revisions: revisions ?? [],
							revisionId,
							onSelect: onSelectRevision,
							onOpen: () => {
								setLazyRevisions(false);
							},
							onClose: () => {
								setLazyRevisions(true);
								setRevisionId(null);
							},

							view: drawerHistoryView,
							setView: setDrawerHistoryView,
							formsDrawer: formsDrawer
						}}
						statusView={{ statuses }}
						statusesMap={statusesMap}
						detailsView={{ entry }}
						disableModal={!!isLatestRevisionSelected}
					/>
				)}

				<Content disabled={freezeForm}>
					<FormProvider {...formProviderProps}>
						<OpenCustomsMapChecker
							openCustomsMap={openCustomsMap}
							setOpenCustomsMap={setOpenCustomsMap}
						/>
						<DependenciesMapChecker
							onResetFields={onResetFields}
							variableVisibilityMapState={{
								variableVisibilityMap,
								setVariableVisibilityMap
							}}
							variableFilteringMapState={{
								variableFilteringMap,
								setVariableFilteringMap
							}}
							customFormContext={formProviderProps}
						/>

						<Form onSubmit={handleOnSubmit}>
							{form ? (
								// CUSTOM FORM
								<AddEditInputsAndGroupsFormDesigner
									isHistoryPagination={
										isRevisionSelected && (form ? checkForms : true)
									}
									form={form}
									variablesData={variablesData}
									{...AddEditInputsAndGroupsGeneralProps}
								/>
							) : (
								// DEFAULT FORM
								<AddEditInputsAndGroups
									isHistoryPagination={
										isRevisionSelected && (form ? checkForms : true)
									}
									{...AddEditInputsAndGroupsGeneralProps}
								/>
							)}
						</Form>
					</FormProvider>
				</Content>

				{!isMobileDevice && entry && isEdit && (
					<EntryDrawer
						entryType={EntryDrawerType.AddEditForm}
						detailsView={{
							entry
						}}
						historyView={{
							revisions: revisions ?? [],
							revisionId,
							loading: loadingRevisions,
							disabled: updatingEntry || (!!areRevisionsFetched && loadingRevisions),
							onSelect: onSelectRevision,
							onOpen: () => {
								setLazyRevisions(false);
							},
							onClose: () => {
								setLazyRevisions(true);
								setRevisionId(null);
							}
						}}
						statusView={{
							statuses
						}}
						statusesMap={statusesMap}
						onOpen={() => setEntryDrawerOpen(true)}
						onClose={() => setEntryDrawerOpen(false)}
						listenToEscape={!selectedSeriesEntry.subEntryId}
						disableModal={!!isLatestRevisionSelected}
					/>
				)}
			</Container>

			{/* SERIES ENTRY FORM */}
			{selectedVariableSet && (
				<SeriesEntryForm
					subEntryId={selectedSeriesEntry.subEntryId}
					hasWriteAccess={hasWriteAccess}
					hasDeleteAccess={hasDeleteAccess}
					canModify={hasWriteAccess && canModifyFormDataByStatus}
					isRevisionSelected={isSetRevisionSelected}
				/>
			)}
			<Flex fullWidth align={a => a.center} justify={j => j.center}>
				{isRevisionSelected && (form ? checkForms : true) && (
					<HistoryPagination
						goToChangedVariable={(variableNames, currentChangeLocal) =>
							goToChangedVariable(variableNames, currentChangeLocal)
						}
						revision={form ? currentRevision ?? undefined : revision}
					/>
				)}
			</Flex>

			{/* 
				==============
					MODALS 
				==============
			*/}

			{/* DELETE ENTRY MODAL */}
			<Modal
				size={s => s.s}
				visible={deleteEntryModal.visible}
				title={translate(dict => dict.promptToDelete.title)}
				primary={{
					label: translate(dict => dict.buttons.delete),
					loading: deletingEntry,
					warning: true,
					disabled: !isValidDeleteConfirmation,
					onClick: handleDeleteEntry
				}}
				neutral={{
					label: translate(dict => dict.buttons.cancel),
					onClick: deleteEntryModal.close
				}}
				onClose={deleteEntryModal.close}
				enterAsPrimaryOnClick
				close
			>
				<Typography.Paragraph>
					{translate(dict => dict.promptToDelete.description.entry)}
				</Typography.Paragraph>
				<Typography.Paragraph
					marginOffset={{ top: 2.4, bottom: 2.4 }}
					color={Colors.text.error}
					fontweight={w => w.bold}
				>
					{translate(dict => dict.promptToDelete.description.undoneWarning)}
				</Typography.Paragraph>
				<Input
					type={InputType.Text}
					label={translate(dict => dict.addEditForm.deleteEntryConfirmationLabel).replace(
						'__deleteEntryConfirmationTarget__',
						deleteEntryConfirmationTarget
					)}
					value={confirmDeleteEntryInput}
					onChange={e => setConfirmDeleteEntryInput(e.target.value)}
					placeholder={deleteEntryConfirmationTarget}
				/>
			</Modal>

			{/* RESTORE ENTRY MODAL */}
			<RestoreEntryModal
				loadingRestore={loadingAction}
				visible={restoreEntryModal.visible}
				onPrimaryClick={onSaveClick}
				onNeutralClick={restoreEntryModal.close}
				creationDate={nextRevisionCreationDate}
			/>

			{/* CONFLICTS MODAL */}
			{conflictedData && (
				<ConflictsModal
					setConflictDraft={setConflictDraft}
					conflictDraft={conflictDraft}
					onClose={() => {
						setConflictModalVisible(false);
						setConflictedData(null);
					}}
					onContinue={handleMergeConflicts}
					open={conflictModalVisible}
					conflictedData={conflictedData}
				/>
			)}

			{/* REVISIONS MODAL - DISCARD CHANGES */}
			<RevisionChangesModal
				visible={!!revisionsModalVisible}
				onPrimaryClick={handleRevisionModalPrimaryClick}
				onNeutralClick={closeRevisionModal}
			/>
			<UnrestoredRevisionModal
				visible={!!unrestoredRevisionModalVisible}
				onPrimaryClick={goToDataset}
				onNeutralClick={() => setUnrestoredRevisionModalVisible(false)}
				latestRevisionDate={
					revisions && revisions[0] ? revisions[revisions.length - 1].creationDate : ''
				}
			/>
			{/* UNSAVED CHANGES MODAL */}
			{!disablePromptToSave && (
				<PromptToSave
					saving={loadingAction || savingEntryDraft}
					when={promptToSave}
					onSave={saveAsDraft ? handleSaveDraftAndClose : handleSaveAndClose}
					onDiscard={goToDataset}
					onClose={() => setSaveAsDraft(false)}
				/>
			)}
			{/* ASSIGN GROUP MODAL */}
			<AssignProjectOrganizationModal
				visible={showAssignOrganizationModal}
				onContinue={() => {
					setShowAssignOrganizationModal(false);
					saveAsDraft ? handleSaveEntryDraft() : handleOnSubmit();
				}}
				onClose={() => {
					setShowAssignOrganizationModal(false);
					setAssignedOrganizationId(NO_ORGANIZATION_ID);
					setSaveAsDraft(false);
				}}
				organization={assignedOrganization}
				onSelect={id => setAssignedOrganizationId(id)}
				userOrganizations={selectableOrganizations}
			/>
			{/* VIEW PERMISSION MODAL */}
			{readOnlyModal.visible && !isRevisionSelected && !isSetRevisionSelected && (
				<ViewPermissionModal onClose={readOnlyModal.close} />
			)}
		</>
	);
}
