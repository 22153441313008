import { useController } from 'react-hook-form';
import { FormControl } from '../EntryForm';
import { RadioGroup } from '../component/RadioGroup';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { OptionalDescriptionTooltip } from '../component/OptionalDescriptionTooltip';
import { CategoryNonFixedFormItem } from '../data/useProjectData/useProjectData';
import clsx from 'clsx';

interface Props {
	control: FormControl;
	item: CategoryNonFixedFormItem;
}

export const CategoryVariableInput = ({ item, control }: Props) => {
	const variable = item.variable;

	const {
		field: { value, onChange },
		fieldState
	} = useController({
		name: variable.variableName,
		control
	});

	const options = variable.categories.map(category => ({
		label: category,
		value: category
	}));

	if (item.layoutType === 'dropdown') {
		return (
			<CreatableSelect
				className={clsx(item.hideFromUi && 'hidden')}
				label={item.variable.variableLabel}
				items={options}
				required={variable.obligatory}
				value={
					value
						? {
								label: value,
								value
						  }
						: null
				}
				onValueSelected={value => onChange(value ? value : '')}
				allowCreate={variable.fixedCategories === 'no'}
				error={fieldState.error?.message?.toString()}
				disabled={variable.entryType === 'calculated'}
				LabelSuffix={<OptionalDescriptionTooltip description={variable.description} />}
			/>
		);
	}

	return (
		<RadioGroup
			className={clsx(item.hideFromUi && 'hidden')}
			categories={options}
			description={variable.description}
			allowCreate={variable.fixedCategories === 'no'}
			control={control}
			disabled={variable.entryType === 'calculated'}
			label={variable.variableLabel}
			variableName={variable.variableName}
			orientation={item.layoutType}
			obligatory={variable.obligatory}
		/>
	);
};
