import { Modal } from 'components/UI/Modal';
import { Button } from 'features/entry-form-v2/component/Button';
import { downloadFileFromUrl } from 'helpers/generic';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ExportDatasetsMutationArgs, useExportDatasetsMutation } from './exportDatasetsMutation';

interface Props {
	onClose: () => void;
	visible: boolean;
	seriesLabel: string;
}

export const ExportSeriesModal = ({ onClose, visible, seriesLabel }: Props) => {
	const params = useParams();
	const projectId = params['projectId'] as string;
	const seriesName = params['seriesName'] as string;

	const exportDatasetsMutation = useExportDatasetsMutation();

	return (
		<Modal title={`Export series ${seriesLabel}`} visible={visible} onClose={onClose}>
			<ExportForm
				onCloseClicked={onClose}
				isSubmitting={exportDatasetsMutation.isLoading}
				onSubmit={async data => {
					const result = await exportDatasetsMutation.mutateAsync({
						projectId,
						setNames: [seriesName],
						...data
					});

					downloadFileFromUrl(result.singedUrl);

					onClose();
				}}
			/>
		</Modal>
	);
};

const ExportForm = ({
	onSubmit,
	isSubmitting,
	onCloseClicked
}: {
	onSubmit: (data: FormValues) => void;
	isSubmitting: boolean;
	onCloseClicked: () => void;
}) => {
	const form = useForm<FormValues>({
		defaultValues: {
			amountOfData: 'allData',
			removeLineShifts: false,
			categoryLabels: false,
			exportFormat: 'csv'
		}
	});

	return (
		<form
			className="flex flex-col gap-10 grow self-stretch"
			onSubmit={form.handleSubmit(onSubmit)}
		>
			<fieldset>
				<legend className="text-base font-semibold">File Format</legend>

				<div className="mt-4 flex gap-4">
					<label className="flex gap-1">
						<input type="radio" {...form.register('exportFormat')} value="csv" />
						<p className="text-base">CSV</p>
					</label>

					<label className="flex gap-1">
						<input type="radio" {...form.register('exportFormat')} value="excel" />
						<p className="text-base">Excel</p>
					</label>
				</div>
			</fieldset>

			<fieldset>
				<legend className="text-base font-semibold">Export Options</legend>

				<div className="mt-4 flex flex-col gap-4">
					<label className="flex gap-2">
						<input
							type="radio"
							className="self-start"
							value="allData"
							{...form.register('amountOfData')}
						/>
						<Description
							{...form.register('amountOfData')}
							label="All Data"
							info="Includes all data and metadata"
						/>
					</label>

					<label className="flex gap-2">
						<input
							type="radio"
							className="self-start"
							value="importable"
							{...form.register('amountOfData')}
						/>
						<Description
							label="Importable"
							info="Includes only variables cereated by the user (without author, date, metadata)"
						/>
					</label>

					<label className="flex gap-2">
						<input
							type="radio"
							className="self-start"
							value="justData"
							{...form.register('amountOfData')}
						/>

						<Description
							label="Just Data"
							info="Includes only values entered by the user and ID of the variable to which they belong"
						/>
					</label>
				</div>
			</fieldset>

			<div>
				<label className="flex gap-2 items-start">
					<input
						{...form.register('categoryLabels')}
						type="checkbox"
						id="categoryLabels"
					/>

					<label htmlFor="categoryLabels" className="flex flex-col gap-1">
						<p className="text-base">Show category labels</p>
						<p className="text-sm text-gray-700">
							{`Selecting this exports the exact category names or labels, like "Red", "Blue" and "Green". If not selected, either numerical or textual values will be assigned to categories. For example, "Red" might be represented as 1, "Blue" as 2, etc`}
						</p>
					</label>
				</label>
			</div>

			<label className="flex gap-2">
				<input
					{...form.register('removeLineShifts')}
					type="checkbox"
					id="removeLineShifts"
				/>

				<label htmlFor="removeLineShifts" className="text-base">
					{' '}
					Exclude line shifts in the description
				</label>
			</label>

			<div className="flex-row-reverse flex gap-4 self-end mt-auto">
				<Button title="Export" loading={isSubmitting} />
				<Button title="Close" type="button" onClick={onCloseClicked} variant="secondary" />
			</div>
		</form>
	);
};

type FormValues = Pick<
	ExportDatasetsMutationArgs,
	'amountOfData' | 'removeLineShifts' | 'categoryLabels' | 'exportFormat'
>;

const Description = ({ label, info }: { label: string; info: string }) => {
	return (
		<div className="flex flex-col">
			<p className="text-base ">{label}</p>
			<p className="text-sm text-gray-700">{info}</p>
		</div>
	);
};
