import { Skeleton } from '../component/Skeleton';
import { useGetDataEntryQuery } from '../data/useGetDataEntryQuery';
import { useGetNamesFromUserIdsQuery } from '../data/useGetNamesFromUserIdsQuery';
import { DateTime } from 'luxon';
import { useGetProjectQuery } from '../data/useGetProjectQuery';
import { useEffect, useMemo } from 'react';
import { Entry, OurInternalCustomEntryFields } from '../types';
import { useTracking } from 'app/tracking/TrackingProvider';
import { Dialog } from '../component/Dialog';
import { Button } from '../component/Button';
import { parseErrors } from '../ErrorModal';

interface Props {
	open: boolean;
	onClose: () => void;
	entryId: string;
	projectId: string;
	seriesName?: string;
}

export const EntryDetailsModal = ({ open, onClose, entryId, projectId, seriesName }: Props) => {
	const { track } = useTracking();
	useEffect(() => {
		if (open) {
			track({ eventName: 'entry_details_viewed' });
		}
	}, [open]);

	const {
		data,
		error,
		isLoading: loading
	} = useEntryDetailsData({ entryId, projectId, seriesName });

	return (
		<Dialog title="Entry Details" open={open} onClose={onClose}>
			<div className="flex flex-col w-[600px] h-[720px]">
				<div className="flex flex-col flex-1 overflow-y-auto">
					{loading && (
						<ul className="flex flex-col gap-10">
							{new Array(6).fill(null).map((_, index) => (
								<li key={index} className="flex flex-col ">
									<Skeleton className="w-32 h-6" />
									<Skeleton className="w-48 h-6 mt-1" />
								</li>
							))}
						</ul>
					)}

					{error && <p className="text-red-500">{parseErrors(error)}</p>}

					{data && (
						<ul className="flex flex-col gap-10">
							<ListItem header="Entry ID" text={data.entryId} />
							<ListItem header="Owned by" text={data.ownedByUserFullName} />
							<ListItem header="Group" text={data.groupName} />
							<ListItem
								header="Last modified by"
								text={data.lastModifiedByUserFullName}
							/>
							<ListItem header="Created date" text={formatDate(data.createdAt)} />
							<ListItem
								header="Last modified date"
								text={formatDate(data.lastModifiedAt)}
							/>
						</ul>
					)}
				</div>

				<Button onClick={onClose} title="Close" className="self-end" />
			</div>
		</Dialog>
	);
};

const formatDate = (date: string) =>
	DateTime.fromJSDate(new Date(date)).toLocaleString(DateTime.DATE_MED);

const ListItem = ({ header, text }: { header: string; text: string }) => (
	<li>
		<p className="text-base font-semibold">{header}</p>

		<p className="text-base">{text}</p>
	</li>
);

const useEntryDetailsData = ({
	entryId,
	projectId,
	seriesName
}: {
	entryId: string;
	projectId: string;
	seriesName?: string;
}) => {
	const entryQuery = useGetDataEntryQuery({
		entryId,
		projectId,
		setName: seriesName
	});
	const getProjectQuery = useGetProjectQuery({
		projectId
	});

	const userIds = entryQuery.data?.entry
		? [entryQuery.data.entry.ownedbyuser, entryQuery.data.entry.enteredbyuser]
		: [];
	const getNamesFromUserIdsQuery = useGetNamesFromUserIdsQuery(
		{
			userIds
		},
		{ enabled: userIds.length !== 0 }
	);

	const isLoading =
		entryQuery.isLoading || getNamesFromUserIdsQuery.isLoading || getProjectQuery.isLoading;
	const error = entryQuery.error || getNamesFromUserIdsQuery.error || getProjectQuery.error;

	const data: FormattedEntryDetails | undefined = useMemo(() => {
		if (!entryQuery.data || !getNamesFromUserIdsQuery.data || !getProjectQuery.data) {
			return undefined;
		}

		const typedEntry = entryQuery.data.entry as Entry & OurInternalCustomEntryFields;

		const projectGroup = getProjectQuery.data.project.organizations.find(
			organization => organization.organizationId === typedEntry.userProjectOrgId
		);

		return {
			entryId,
			ownedByUserFullName:
				getNamesFromUserIdsQuery.data.namesFromUserIds[entryQuery.data.entry.ownedbyuser] ||
				'',
			groupName: projectGroup?.organizationName || 'Error fetching group name',
			lastModifiedByUserFullName:
				getNamesFromUserIdsQuery.data.namesFromUserIds[typedEntry.enteredbyuser] || '',
			createdAt: typedEntry.creationdate,
			lastModifiedAt: typedEntry.lastmodifieddate
		};
	}, [entryQuery.data, getNamesFromUserIdsQuery.data, getProjectQuery.data]);

	return {
		isLoading,
		data,
		error
	};
};

type FormattedEntryDetails = {
	entryId: string;
	ownedByUserFullName: string;
	groupName: string;
	lastModifiedByUserFullName: string;
	createdAt: string;
	lastModifiedAt: string;
};
