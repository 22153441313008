import { makeRequest } from './makeRequest';
import { useQuery } from '@tanstack/react-query';

export type GetDataEntryHistoryResponse = {
	revisionsWithChanges: RevisionWithChanges[];
	statusChanges: StatusChange[][];
};

export const useGetDataEntryHistoryQuery = ({
	entryId,
	projectId,
	setName
}: {
	entryId: string;
	projectId: string;
	setName?: string;
}) => {
	return useQuery({
		queryKey: ['entryHistory', entryId],
		queryFn: () =>
			makeRequest<GetDataEntryHistoryResponse>({
				method: 'getDataEntryHistory',
				service: 'cde',
				data: {
					datasetentryid: entryId,
					projectId,
					...(setName ? { set: { setName } } : {})
				}
			})
	});
};

export type RevisionWithChanges = {
	active: boolean;
	changes: {
		variableName: string;
		from: any;
		to: any;
	}[];
	creationDate: string;
	revisionId: string;
	userName: string;
};

type StatusNotChanged = {};
type StatusChanged = {
	variableName: string;
	from: StatusRevision | null;
	to: StatusRevision | null;
};
export const isStatusChanged = (statusChange: StatusChange): statusChange is StatusChanged => {
	return 'variableName' in statusChange;
};

export type StatusChange = StatusNotChanged | StatusChanged;

type StatusRevision = {
	value: boolean;
	comment: string | null;
	lastmodified: string;
	lastmodifiedbyuser: string;
};
