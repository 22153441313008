import { EditProject, ProjectMetadataType, ProjectFormValues } from 'store/data/projects';
import { InputType } from 'types/index';
import { Column, Row } from './EditProjectModal.style';
import { Modal } from 'components/UI/Modal';
import { Input } from 'components/UI/Inputs/Input';
import { Spacer } from 'components/UI/Spacer';
import { RadioGroupUncontrolled } from 'components/UI/Interactables/Uncontrolled';
import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import {
	useTranslation,
	useProjectById,
	usePermissions,
	useIsProjectOwner,
	useUpdateProject,
	useProjectMetadataDefinition
} from 'hooks/store';
import { useCompletedAction, useKeyPress } from 'hooks/utils';
import {
	getInitialMetadataFormValues,
	parseFormValuesToEditedProject,
	transforMetadataParametersToFormRows,
	validateProjectForm
} from 'helpers/projects/projectMetadata';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { InfoTooltip } from 'components/UI/Interactables/InfoTooltip';
import { ProjectStatus, ProjectType } from 'types/data/projects/constants';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'hooks/ui/useReactForm';
import { InfoMessage } from 'components/UI/InfoMessage';

interface Props {
	projectId: string;
	deleteModalVisible: boolean;
	onDelete: () => void;
	onClose: (success?: boolean) => void;
}

export function EditProjectModal({ projectId, deleteModalVisible, onDelete, onClose }: Props) {
	const { translate } = useTranslation();

	const [{ data: projectMetadataDefinition }] = useProjectMetadataDefinition();
	const [modalElement, setModalElement] = useState<HTMLElement | null>(null);

	const getModalRef = useCallback((node: HTMLElement | null) => {
		if (!node) return;
		const modalBody = node.querySelector('.modal-body');
		if (modalBody) {
			setModalElement(modalBody as HTMLElement);
		}
	}, []);

	const project = useProjectById(projectId);
	const [endDateError, setEndDateError] = useState<string>(() => {
		if (!project || !project.projectStartDate || !project.projectEndDate) return '';

		const startDate = new Date(project.projectStartDate);
		startDate.setHours(0, 0, 0, 0);
		const projectEndDate = new Date(new Date(project.projectEndDate).setHours(0, 0, 0, 0));
		if (projectEndDate < startDate) {
			return translate(dict => dict.createProject.projectEndDateError);
		}

		return '';
	});

	const { hasEditProjectWriteAccess } = usePermissions({
		projectId: project?.projectId
	});
	const isProjectOwner = useIsProjectOwner(project?.projectId);

	const [{ loading: updatingProject, error: errorUpdatingProject }, updateProject] =
		useUpdateProject();

	const initialMetadataValues = getInitialMetadataFormValues(projectMetadataDefinition, project);

	const initialValues: ProjectFormValues = {
		projectId: project?.projectId ?? '',
		projectName: project?.projectName ?? '',
		givenProjectNumber: project?.givenProjectNumber ?? '',
		status: project?.status ?? ProjectStatus.Ongoing,
		description: project?.description ?? '',
		slideFolderURL: project?.slideFolderURL ?? '',
		projectType: ProjectType.CORE,
		projectEndDate: project?.projectEndDate ?? '',
		...initialMetadataValues
	};

	const {
		register,
		setValue,
		getValues,
		handleSubmit,
		formState: { isDirty, isValid },
		control,
		watch
	} = useForm({
		defaultValues: initialValues,
		reValidateMode: 'onChange',
		mode: 'onChange',
		criteriaMode: 'all'
	});

	const { errors, requiredFields } = validateProjectForm(
		projectMetadataDefinition,
		watch,
		translate
	);

	const formHasChanges = isDirty && isValid && Object.keys(errors).length === 0;

	const handleOnSubmit = handleSubmit(() => {
		if (formHasChanges && !updatingProject && hasEditProjectWriteAccess) {
			trimFields();

			const values = { ...getValues() };

			const parsedValues = { ...values };

			if (!['1', '2'].includes(String(values['metadata_projectType']))) {
				parsedValues['metadata_archiveNumber'] = '';
			}

			updateProject(parseFormValuesToEditedProject(parsedValues, projectMetadataDefinition));
		}
	});

	function trimFields() {
		const values = { ...getValues() };

		setValue('projectName', values.projectName.toString().trim());
		setValue('description', values.description.toString().trim());
		setValue('givenProjectNumber', values.givenProjectNumber.toString().trim());

		projectMetadataDefinition.metadataDefinition &&
			projectMetadataDefinition.metadataDefinition.forEach(metadataParameter => {
				if (values[metadataParameter.name])
					setValue(
						`${metadataParameter.name}`,
						values[metadataParameter.name].toString().trim()
					);
			});
	}

	// close modal on project update
	useCompletedAction(updatingProject, errorUpdatingProject, () => onClose(true));

	useKeyPress(
		{
			onEnterKeyPress: deleteModalVisible ? undefined : handleOnSubmit,
			onDeleteKeyPress: () => (isProjectOwner ? onDelete() : undefined)
		},
		{ listen: !updatingProject }
	);

	function checkProjectEndDate(dateStr: string) {
		if (!project?.projectStartDate) return;

		if (dateStr === '' && endDateError !== '') {
			setEndDateError('');
			return;
		}
		const date = new Date(dateStr);
		date.setHours(0, 0, 0, 0);
		const projectEndDate = new Date(new Date(project?.projectStartDate).setHours(0, 0, 0, 0));
		if (date < projectEndDate) {
			setEndDateError(translate(dict => dict.createProject.projectEndDateError));
		} else {
			if (endDateError.length > 0) {
				setEndDateError('');
			}
		}
	}

	const baseDictKey = EditProject.project;

	const statusOptions = [
		{
			label: translate(dict => dict[baseDictKey].status.values.ongoing),
			value: ProjectStatus.Ongoing
		},
		{
			label: translate(dict => dict[baseDictKey].status.values.onHold),
			value: ProjectStatus.OnHold
		},
		{
			label: translate(dict => dict[baseDictKey].status.values.ended),
			value: ProjectStatus.Ended
		}
	];

	const formMetadataParameters = transforMetadataParametersToFormRows(projectMetadataDefinition);

	return (
		<Modal
			title={translate(dict => dict[baseDictKey].modalTitle)}
			primary={
				hasEditProjectWriteAccess && {
					label: translate(({ buttons }) =>
						formHasChanges ? buttons.update : buttons.done
					),
					loading: updatingProject,
					disabled: formHasChanges ? !isDirty && isValid : false,
					onClick: formHasChanges ? handleOnSubmit : onClose
				}
			}
			neutral={
				formHasChanges && {
					label: translate(({ buttons }) => buttons.cancel),
					onClick: onClose
				}
			}
			modalRef={getModalRef}
			onClose={onClose}
			visible
			close
		>
			<Form onSubmit={handleOnSubmit}>
				<Input
					{...register('projectName')}
					type={InputType.Text}
					label={translate(dict => dict[baseDictKey].title)}
					error={errors.projectName?.message as string}
					readOnly={!hasEditProjectWriteAccess}
					onBlur={trimFields}
					required
				/>
				<Spacer size={s => s.s} />
				<Input
					{...register('description')}
					type={InputType.Textarea}
					label={translate(dict => dict[baseDictKey].description)}
					rows={4}
					readOnly={!hasEditProjectWriteAccess}
					onBlur={trimFields}
				/>
				<Spacer size={s => s.s} />
				<Row>
					<Column>
						<Input
							{...register('givenProjectNumber')}
							type={InputType.Text}
							label={translate(dict => dict[baseDictKey].number)}
							readOnly={!hasEditProjectWriteAccess}
							onBlur={trimFields}
						/>
					</Column>
					<Column>
						<Controller
							control={control}
							name="projectEndDate"
							render={({ field: { value } }) => (
								<Input
									openDateDownwards
									type={InputType.Date}
									value={value as number}
									onBlur={trimFields}
									label={translate(dict => dict[baseDictKey].endDate)}
									error={endDateError}
									onDateChange={({ formattedDate }) => {
										checkProjectEndDate(formattedDate);
										setValue('projectEndDate', formattedDate, {
											shouldDirty: true
										});
									}}
									readOnly={!hasEditProjectWriteAccess}
								/>
							)}
						/>
					</Column>
				</Row>
				<Spacer size={s => s.s} />
				<Controller
					name="status"
					control={control}
					render={({ field: { name, value, onBlur } }) => (
						<RadioGroupUncontrolled
							name="status"
							label={translate(dict => dict[baseDictKey].status.label)}
							options={statusOptions}
							disabled={!hasEditProjectWriteAccess}
							// CONTROLLED PROPS
							value={value as string}
							onBlur={onBlur}
							onChange={newValue =>
								setValue(name, newValue as ProjectStatus, {
									shouldDirty: true
								})
							}
						/>
					)}
				/>

				{formMetadataParameters.length > 0 && (
					<>
						<Spacer size={s => s.s} />
						{formMetadataParameters.map((metadataParametersRow, i) => {
							return (
								<div key={i}>
									<Spacer size={s => s.s} />
									<Row>
										{metadataParametersRow.map(metadataParameter => (
											<Column key={metadataParameter.name}>
												{metadataParameter.type ===
													ProjectMetadataType.String && (
													<Input
														{...register(`${metadataParameter.name}`)}
														placeholder={translate(
															() => metadataParameter.placeholder
														)}
														type={InputType.Text}
														label={translate(
															() => metadataParameter.label
														)}
														error={
															errors[metadataParameter.name]?.message
														}
														tooltipComponent={
															<InfoTooltip
																marginOffset={{
																	left: 0.4,
																	bottom: 0.4
																}}
																iconVisible
																text={translate(
																	() =>
																		metadataParameter.description
																)}
																renderContext={
																	modalElement ?? undefined
																}
															/>
														}
														onBlur={trimFields}
														readOnly={!hasEditProjectWriteAccess}
														required={requiredFields.has(
															metadataParameter.name
														)}
													/>
												)}

												{metadataParameter.type ===
													ProjectMetadataType.Dropdown && (
													<Controller
														name={`${metadataParameter.name}`}
														control={control}
														render={({ field: { value, onBlur } }) => (
															<CreatableSelect
																label={translate(
																	() => metadataParameter.label
																)}
																tooltipPlace="top"
																tooltipComponent={
																	<InfoTooltip
																		marginOffset={{
																			left: 0.4,
																			bottom: 0.4
																		}}
																		iconVisible
																		text={translate(
																			() =>
																				metadataParameter.description
																		)}
																		renderContext={
																			modalElement ??
																			undefined
																		}
																	/>
																}
																placeholder={translate(
																	() =>
																		metadataParameter.placeholder
																)}
																value={
																	value
																		? {
																				value: value.toString(),
																				label: translate(
																					() =>
																						metadataParameter.categories.find(
																							p =>
																								p.id ===
																								value
																						)?.name ??
																						''
																				)
																		  }
																		: undefined
																}
																items={metadataParameter.categories.map(
																	option => ({
																		label: translate(
																			() => option.name
																		),
																		value: `${option.id}`
																	})
																)}
																error={
																	errors[metadataParameter.name]
																		?.message
																}
																onClear={() => {
																	setValue(
																		`${metadataParameter.name}`,
																		'',
																		{
																			shouldDirty: true
																		}
																	);
																}}
																borderError={
																	!!errors[metadataParameter.name]
																		?.message
																}
																required={requiredFields.has(
																	metadataParameter.name
																)}
																onValueSelected={selectedValue =>
																	selectedValue &&
																	setValue(
																		`${metadataParameter.name}`,
																		selectedValue,
																		{
																			shouldDirty: true
																		}
																	)
																}
																readOnly={
																	!hasEditProjectWriteAccess
																}
																hint={translate(
																	() =>
																		metadataParameter.categories.find(
																			p => p.id === value
																		)?.description ?? ''
																)}
																onBlur={onBlur}
																scrollIntoView
															/>
														)}
													/>
												)}
											</Column>
										))}
									</Row>
									<Spacer size={s => s.s} />
									<InfoMessage
										message={
											<div>
												<p>
													{translate(
														dict => dict.projectMetadata.hso.info
													)}
												</p>
												<p>
													{translate(
														dict =>
															dict.projectMetadata.hso.byProceeding
													)}
												</p>
											</div>
										}
									/>
								</div>
							);
						})}
					</>
				)}
			</Form>

			{isProjectOwner && (
				<Flex flex={1} align={a => a.end} marginOffset={{ top: 2.4 }}>
					<Button
						title={translate(dict => dict[baseDictKey].delete)}
						variant={v => v.link}
						paddingOffset={{ all: 0 }}
						onClick={onDelete}
					/>
				</Flex>
			)}
		</Modal>
	);
}
