import { makeRequest } from './makeRequest';
import { useQuery } from '@tanstack/react-query';

export type GetProjectData = {
	project: {
		organizations: {
			organizationId: number;
			organizationName: string;
		}[];
		projectName: string;

		projectOwnerDetails: {
			organization: string;
		};

		userAccess: {
			projectOrganizationAccesses: {
				organizationId: number;
				writeAccess: boolean;
			}[];

			statusTypeAccesses?: {
				statusTypeVariableName: string;
				viewData: boolean;
				editData: boolean;
				setStatus: boolean;
			}[];
		};

		metadata?: Record<string, any>;
	};
};

export const useGetProjectQuery = (
	{ projectId }: { projectId: string },
	{
		enabled
	}: {
		enabled?: boolean;
	} = {
		enabled: true
	}
) => {
	return useQuery({
		queryKey: ['project', projectId],
		enabled,
		queryFn: () =>
			makeRequest<GetProjectData>({
				method: 'getProject',
				service: 'projects',
				data: {
					project: {
						projectId: parseInt(projectId)
					},
					retrieveProjectOwnerDetails: true
				}
			})
	});
};
