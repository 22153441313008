import { useEffect, useMemo, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { isEqual } from 'lodash';
import { VariableCategory } from 'api/data/variables';
import { Builder, DependenciesSidebar } from 'components/Dependencies';
import { DependenciesContext } from 'contexts';
import {
	isDependencyValid,
	buildDependenciesIndexByName,
	areDependenciesEqual
} from 'helpers/dependencies';
import { DragAndDropTypes, GenericMap } from 'types/index';
import { getDependencyActions } from './getDependencyActions';
import { Container, SettingsContainer, NoDependenciesContainer } from './DependenciesPage.style';
import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { Grid } from 'components/UI/Grid';
import { Button } from 'components/UI/Interactables/Button';
import { Switch } from 'components/UI/Interactables/Switch';
import { Modal } from 'components/UI/Modal';
import { PromptToSave } from 'components/UI/PromptToSave';
import { Spacer } from 'components/UI/Spacer';
import { Suspend } from 'components/UI/Suspend';
import { Typography } from 'components/UI/Typography';
import { debuggerLog } from 'helpers/generic';
import { buildVariableCategoriesMap } from 'helpers/variables';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	usePermissions,
	useProjectId,
	useProject,
	useVariables,
	useDependencies,
	useUpdateDependencies
} from 'hooks/store';
import { useModalState } from 'hooks/ui';
import { useMutableState, useStatic, usePrevious, useCompletedAction } from 'hooks/utils';
import { Dropdown } from 'components/UI/Dropdown';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { DependenciesViewOptions } from 'store/data/dependencies';

export function DependenciesPage() {
	// SET TO `true` TO SEE THE LOGS
	const DEBUGGER = false;
	const log = debuggerLog(DEBUGGER);

	const { translate } = useTranslation();
	const { routes, navigate } = useNavigation();
	const [openModal, setOpenModal] = useState(false);
	const {
		fetched: arePermissionsFetched,
		loading: loadingPermissions,
		hasVariablesWriteAccess,
		hasModulesAccess
	} = usePermissions();

	const [projectId] = useProjectId();
	const [{ loading: loadingProject }] = useProject();
	const [viewOption, setViewOption] = useState(DependenciesViewOptions.GRID);
	const isGridView = viewOption === DependenciesViewOptions.GRID;

	const [
		{
			data: { variables, variablesMap, hasVariables },
			loading: loadingVariables,
			fetched: areVariablesFetched
		}
	] = useVariables({ initial: true });

	const [
		{ data: dependenciesData, loading: loadingDependencies, fetched: areDependenciesFetched }
	] = useDependencies({
		lazy: !areVariablesFetched
	});
	const [
		{ loading: updatingDependencies, error: errorUpdatingDependencies },
		updateDependencies
	] = useUpdateDependencies();

	const { active, dependencies } = dependenciesData;

	const [context, setContext] = useState<string | null>(null);

	const [initialDraftActive, setInitialDraftActive] = useState(active);
	const [draftActive, setDraftActive] = useState(active);
	const [initialDraftDependencies, setInitialDraftDependencies] = useMutableState(dependencies);
	const [draftDependencies, setDraftDependencies] = useMutableState(dependencies);
	const [areRulesExpanded, expandAllRules] = useState(true);

	// SAVE POSITION OF DEPENDENCY RULES - PERFORMANT ACCESS WHEN MODIFYING STATE
	const [getDependenciesIndexByName, setDependenciesIndexByName] = useStatic(
		buildDependenciesIndexByName(dependencies)
	);

	const { dependencyActions } = getDependencyActions({
		draftDependenciesState: {
			draftDependencies,
			setDraftDependencies
		},
		dependenciesIndexByName: {
			getDependenciesIndexByName,
			setDependenciesIndexByName
		},
		variables,
		variablesMap
	});

	const hasChanges = useMemo(
		() =>
			!areDependenciesEqual(initialDraftDependencies, draftDependencies, variablesMap) ||
			initialDraftActive !== draftActive,
		[initialDraftDependencies, draftDependencies, initialDraftActive, draftActive]
	);

	const areDependenciesValid = useMemo(() => {
		let valid = true;

		const invalidDependencyNames: string[] = [];

		draftDependencies.forEach(dependency => {
			const validDependency = isDependencyValid(dependency);

			if (!validDependency) {
				valid = false;

				// TODO: MAYBE IN THE FUTURE WE INDICATE WITH A WARNING ICON WHICH RULE HAS PROBLEMS
				invalidDependencyNames.push(dependency.supplierVariableName);
			}
		});

		return valid;
	}, [draftDependencies]);

	const canUpdate = useMemo(
		() => hasChanges && areDependenciesValid,
		[hasChanges, areDependenciesValid]
	);

	const categoriesMapByVariableName = useMemo(() => {
		const dict: GenericMap<GenericMap<VariableCategory>> = {};

		variables.forEach(variable => {
			const categoriesMap = buildVariableCategoriesMap(variable.categories);

			dict[variable.name] = categoriesMap;
		});

		return dict;
	}, [variables]);

	const promptToSaveModal = useModalState<{ context: string | null }>();

	const scopeDependenciesData = useMemo(() => {
		const { active, dependencies, dependenciesBySetName } = dependenciesData;

		const scope = {
			active,
			dependencies
		};

		if (context) {
			scope.active = true;
			scope.dependencies = [];

			const setDependenciesData = dependenciesBySetName[context];

			if (setDependenciesData) {
				scope.active = setDependenciesData.active;
				scope.dependencies = setDependenciesData.dependencies;
			}
		}

		return scope;
	}, [dependenciesData, context]);

	// HYDRATE STATES
	const prevScopeDependenciesData = usePrevious(scopeDependenciesData);
	useEffect(() => {
		if (prevScopeDependenciesData === undefined) return;

		if (!isEqual(prevScopeDependenciesData, scopeDependenciesData)) {
			log('hydrate states: ', { scopeDependenciesData });

			const { active, dependencies } = scopeDependenciesData;

			setDraftActive(active);
			setInitialDraftActive(active);
			setDraftDependencies(dependencies);
			setInitialDraftDependencies(dependencies);
			setDependenciesIndexByName(buildDependenciesIndexByName(dependencies));
		}
	}, [scopeDependenciesData]);

	// UPDATE CATEGORIES NOTIFICATIONS
	useCompletedAction(
		updatingDependencies,
		errorUpdatingDependencies,
		// SUCCESS CALLBACK
		() => {
			if (promptToSaveModal.payload) {
				setContext(promptToSaveModal.payload.context);

				promptToSaveModal.close();
			}
		}
	);

	function handleUpdateDependencies() {
		if (updatingDependencies) return;

		if (!canUpdate) return;

		updateDependencies({
			active: draftActive,
			dependencies: draftDependencies,
			setName: context ?? undefined
		});
	}

	function onDragEnd({ source, destination, draggableId }: DropResult) {
		if (
			destination &&
			source.droppableId.includes(DragAndDropTypes.DraggableDependenciesVarList) &&
			destination.droppableId.includes(DragAndDropTypes.DroppableDependenciesVarList)
		) {
			dependencyActions.createDependency({ variableName: draggableId });
		}
	}

	function createNewVariable() {
		if (projectId) navigate(routes.projects.variables.create(projectId));
	}

	function discardChanges() {
		setDraftDependencies(initialDraftDependencies);
		setDependenciesIndexByName(buildDependenciesIndexByName(initialDraftDependencies));
	}

	function handleSelectContext(value: string | null) {
		if (value === context) return;

		if (hasChanges) return promptToSaveModal.open({ context: value });

		setContext(value);
	}

	function handlePromptToSaveDiscard() {
		if (promptToSaveModal.payload) {
			setContext(promptToSaveModal.payload.context);

			promptToSaveModal.close();
		}
	}

	const loadingDepdenciesInitial = loadingDependencies && !areDependenciesFetched;

	const loading =
		loadingDepdenciesInitial || loadingVariables || loadingProject || loadingPermissions;
	const immediate = !(areDependenciesFetched && areVariablesFetched && arePermissionsFetched);

	function toggleView() {
		setViewOption(isGridView ? DependenciesViewOptions.TABLE : DependenciesViewOptions.GRID);
	}

	const onClose = () => {
		setOpenModal(false);
	};

	const collapseAllRules = () => {
		expandAllRules(false);
	};

	const handleEnableRules = () => {
		setDraftActive(!draftActive);

		if (!isGridView) {
			updateDependencies({
				active: !draftActive,
				dependencies: draftDependencies,
				setName: context ?? undefined
			});
		}
	};

	const hasMinimumTwoVariables = hasVariables && variables.length > 1;

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={
					hasVariablesWriteAccess &&
					(hasChanges && isGridView ? (
						<Button
							title={translate(({ buttons }) => buttons.update)}
							disabled={!canUpdate}
							loading={updatingDependencies}
							onClick={handleUpdateDependencies}
						/>
					) : !isGridView && hasMinimumTwoVariables ? (
						<Button
							title={translate(dict => dict.dependencies.builder.tableView.addRule)}
							onClick={() => setOpenModal(true)}
						/>
					) : null)
				}
			/>
			<Header.Title
				title={translate(dict => dict.dependencies.dependenciesPage.headerTitle)}
				component={
					areVariablesFetched &&
					areDependenciesFetched &&
					hasVariablesWriteAccess && (
						<Flex align={a => a.center}>
							<Switch
								label={translate(
									dict => dict.dependencies.dependenciesPage.enableRules
								)}
								onChange={() => handleEnableRules()}
								on={isGridView ? draftActive : active}
								reverse
							/>
							<Icon
								marginOffset={{ left: 1.5, right: 1.5 }}
								title={
									isGridView
										? translate(dict => dict.terms.tableView)
										: translate(dict => dict.terms.gridView)
								}
								svg={isGridView ? Svgs.ViewTable : Svgs.ViewGrid}
								variant={v => v.buttonActive}
								onClick={toggleView}
							/>
							<Container>
								<Dropdown
									toggleComponent={({ ref, open, toggle }) => (
										<Icon
											variant={v => v.button}
											ref={ref}
											svg={Svgs.More}
											active={open}
											onClick={toggle}
										/>
									)}
									width={18}
									offset={{ top: 20, right: 0 }}
								>
									<>
										<Dropdown.Item
											title={translate(
												dict =>
													dict.dependencies.dependenciesPage.expandRules
											)}
											disabled={!dependencies}
											onClick={() => expandAllRules(true)}
										/>
										<Dropdown.Item
											title={translate(
												dict =>
													dict.dependencies.dependenciesPage.collapseRules
											)}
											disabled={!dependencies}
											onClick={() => expandAllRules(false)}
										/>
									</>
								</Dropdown>
							</Container>
						</Flex>
					)
				}
			/>

			<Suspend loading={loading} immediate={immediate}>
				<Grid.Container>
					{hasMinimumTwoVariables ? (
						<DragDropContext onDragEnd={onDragEnd}>
							<DependenciesContext.Provider
								value={{
									actions: dependencyActions,
									data: {
										categoriesMapByVariableName
									}
								}}
							>
								<Container>
									{isGridView && (
										<SettingsContainer>
											<DependenciesSidebar
												context={context}
												onSelectContext={handleSelectContext}
											/>
										</SettingsContainer>
									)}

									<Builder
										scopeDependenciesData={scopeDependenciesData}
										isGridView={isGridView}
										context={context}
										dependencies={draftDependencies}
										collapseAllRules={collapseAllRules}
										areRulesExpanded={areRulesExpanded}
										openModal={openModal}
										onClose={onClose}
									/>
								</Container>
							</DependenciesContext.Provider>
						</DragDropContext>
					) : (
						<NoDependenciesContainer>
							<Spacer size={s => s.xxl} />
							<Svgs.EmptyStatesNoDependencies style={{ minHeight: 240 }} />
							<Spacer size={s => s.m} />
							<Typography.H3>
								{translate(
									({ variableDependencies }) =>
										variableDependencies.noDependencies
								)}
							</Typography.H3>

							{hasModulesAccess.projectDesign && hasVariablesWriteAccess && (
								<>
									<Spacer size={s => s.xs} />
									<Typography.Paragraph>
										{translate(
											({ variableDependencies }) =>
												variableDependencies.createCategoryVariables
										)}
									</Typography.Paragraph>
									<Button
										title={translate(
											({ addVariable }) => addVariable.addVariableLabel
										)}
										marginOffset={{ y: 3 }}
										onClick={createNewVariable}
									/>
								</>
							)}
						</NoDependenciesContainer>
					)}
				</Grid.Container>
			</Suspend>

			<PromptToSave
				saving={updatingDependencies}
				when={hasChanges}
				onSave={handleUpdateDependencies}
				onDiscard={discardChanges}
			/>

			{/* PROMPT TO SAVE MODAL - CONTEXT */}
			{promptToSaveModal.payload && (
				<Modal
					size={s => s.s}
					title={translate(dict => dict.promptToSave.title)}
					primary={{
						label: translate(dict => dict.buttons.save),
						loading: updatingDependencies,
						onClick: handleUpdateDependencies
					}}
					neutral={{
						label: translate(dict => dict.buttons.discard),
						onClick: handlePromptToSaveDiscard
					}}
					onClose={promptToSaveModal.close}
					enterAsPrimaryOnClick
					visible
					close
				>
					<Typography.Paragraph>
						{translate(dict => dict.promptToSave.unsavedChanges)}
					</Typography.Paragraph>
				</Modal>
			)}
		</>
	);
}
