import { MediaQueries } from 'environment';
import { Flex } from 'components/UI/Flex';
import { Select } from 'components/UI/Interactables/Select';
import { NavTab as Tab, NormalTab, Text } from '../Tab.style';
import { useNavigation } from 'hooks/navigation';
import { useTranslation } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

const tabsProps = {
	width: 19.1,
	min_width: 19.1,
	underline_width: 11.9
};

export function EnterpriseAdminTabs() {
	const { translate } = useTranslation();
	const { routes, navigate } = useNavigation();

	const { enterpriseAdminProjectsFlag, enterpriseAdminRolesFlag, enterpriseAdminSettingsFlag } =
		useFlags();

	const isPhone = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	const projectsTab = [
		{
			label: translate(dict => dict.enterpriseAdmin.header.menuTabs.projects),
			value: routes.enterpriseAdmin.projects
		}
	];
	const rolesTab = [
		{
			label: translate(dict => dict.enterpriseAdmin.header.menuTabs.roles),
			value: routes.enterpriseAdmin.roles
		}
	];
	const settingsTab = [
		{
			label: translate(dict => dict.enterpriseAdmin.header.menuTabs.settings),
			value: routes.enterpriseAdmin.settings
		}
	];

	const menuTabs = [
		{
			label: translate(dict => dict.enterpriseAdmin.header.menuTabs.users),
			value: routes.enterpriseAdmin.users
		},
		...(enterpriseAdminProjectsFlag ? projectsTab : []),
		{
			label: translate(dict => dict.enterpriseAdmin.header.menuTabs.subscription),
			value: routes.enterpriseAdmin.subscription
		},
		...(enterpriseAdminRolesFlag ? rolesTab : []),
		...(enterpriseAdminSettingsFlag ? settingsTab : [])
	];

	return (
		<Flex>
			{isPhone ? (
				<NormalTab>
					<Select
						title={translate(({ accountUM }) => accountUM.userDrawer.headers.profile)}
						className="dropdown-tab"
						items={menuTabs}
						type={select => select.Tab(true)}
						onSelect={item => navigate(item.value as string)}
					/>
				</NormalTab>
			) : (
				menuTabs.map(item => (
					<Tab
						data-testid={'menu-tab_' + item.label}
						to={item.value as string}
						key={item.label}
						{...tabsProps}
					>
						<div>
							<Text>{item.label}</Text>
						</div>
					</Tab>
				))
			)}
		</Flex>
	);
}
