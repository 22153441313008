import { useState } from 'react';
import { upperFirst } from 'lodash';

import { Variable } from 'api/data/variables';
import { Colors, Svgs } from 'environment';
import { variableCardPulse } from 'events/variables';
import { useTranslation } from 'hooks/store';

import { CenteredCheckbox } from '../styles';
import { Table } from 'components/UI/Table';
import { Asterisk } from 'components/UI/Asterisk';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { useCustomEventListener } from 'helpers/events';
import { translateVariableTypeMap } from 'helpers/generic';

interface Props {
	variable: Variable;
	rowNumber?: number;
	isNested?: { secondLevel: boolean } | boolean;
	checkedData: {
		checked: boolean;
		onCheck: () => void;
	};
	readOnly: boolean;
	hasError: boolean;
	id: string;
	onClick: () => void;
}

export function VariableRow({
	variable,
	rowNumber,
	isNested,
	checkedData: { checked, onCheck },
	readOnly,
	hasError,
	id,
	onClick
}: Props) {
	const { translate } = useTranslation();

	const translatedVariableTypeMap = translateVariableTypeMap(translate);
	const translatedVariableType = translatedVariableTypeMap[variable.type];

	const dataType = `${translatedVariableType} ${variable.uniquenessType ?? ''}`.trim();

	let marginLeftOffset = isNested ? 4 : 0;

	if (typeof isNested === 'object' && isNested.secondLevel) marginLeftOffset += 2;

	const [pulse, setPulse] = useState(false);

	useCustomEventListener(variableCardPulse, {
		onListen: payload => {
			if (payload.variableName === variable.name) setPulse(true);
		}
	});

	return (
		<Table.Row
			{...(pulse && {
				className: 'pulse-vibrant-green'
			})}
			id={id}
			onAnimationEnd={() => setPulse(false)}
			//
			data-scroll-id={variable.name}
			onClick={readOnly ? undefined : onClick}
			active={checked}
			clickable={!readOnly}
			hasError={hasError}
			data-testid={`variable-${variable.name}`}
		>
			<Table.Cell
				onClick={e => {
					e.stopPropagation();
					onCheck();
				}}
			>
				{!readOnly && (
					<CenteredCheckbox
						dataTestId={`variable-${variable.name}-checkbox`}
						checked={checked}
						onClick={onCheck}
					/>
				)}
			</Table.Cell>
			<Table.Cell textCenter>
				<Typography.Caption>{rowNumber}</Typography.Caption>
			</Table.Cell>
			<Table.Cell minWidth={20} maxWidth={30} title={variable.label}>
				<Flex>
					<Typography.Paragraph
						marginOffset={isNested && { left: marginLeftOffset }}
						ellipsis
					>
						{variable.label}
					</Typography.Paragraph>
					{variable.obligatory && <Asterisk paddingLeft />}
					{hasError && (
						<Icon
							svg={Svgs.Information}
							size={s => s.m}
							colors={{ color: Colors.text.error }}
							marginOffset={{ left: 0.8 }}
						/>
					)}
				</Flex>
			</Table.Cell>
			<Table.Cell title={upperFirst(variable.entryType)}>
				{upperFirst(variable.entryType)}
			</Table.Cell>
			<Table.Cell title={dataType} color={hasError ? Colors.text.error : ''}>
				{dataType}
			</Table.Cell>
			<Table.Cell minWidth={20} maxWidth={40} title={variable.description}>
				<Typography.Paragraph ellipsis>{variable.description}</Typography.Paragraph>
			</Table.Cell>
		</Table.Row>
	);
}
