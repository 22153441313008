export enum AnalysisType {
	CorrelationsV1 = 'analysis.analyses.correlations.name',
	CorrelationsV2 = 'analysis.analyses.correlationsV2.name',
	CompareNumericV1 = 'analysis.analyses.compare.name',
	CompareNumericV2 = 'analysis.analyses.compare.name_V2',
	Crosstab = 'analysis.analyses.crosstab.name',
	CrosstabV2 = 'analysis.analyses.crosstab.nameV2',
	Frequencies = 'analysis.analyses.frequencies.name',
	FrequenciesV2 = 'analysis.analyses.frequencies.nameV2',
	Explore = 'analysis.analyses.explore.name',
	ExploreV2 = 'analysis.analyses.explore.nameV2',
	Kaplan = 'analysis.analyses.kaplan.name',
	KaplanV2 = 'analysis.analyses.kaplan.nameV2',
	PlotNumeric = 'analysis.analyses.plotNumeric.name',
	PlotNumericV2 = 'analysis.analyses.plotNumeric.nameV2',
	DensityPlot = 'analysis.analyses.densityPlot.name',
	DensityPlotV2 = 'analysis.analyses.densityPlot.nameV2',
	TimeCourseV1 = 'analysis.analyses.timeCourse.name',
	TimeCourseV2 = 'analysis.analyses.timeCourse.name_V2',
	ComparePaired = 'analysis.analyses.comparePaired.name',
	ComparePairedV2 = 'analysis.analyses.comparePaired.nameV2',
	LogisticRegression = 'analysis.analyses.logisticRegression.name',
	LogisticRegressionV2 = 'analysis.analyses.logisticRegression.nameV2',
	NumberPlotXY = 'analysis.analyses.numberPlotXY.name'
}

export type AnalysisTypeV1 =
	| AnalysisType.CorrelationsV1
	| AnalysisType.CompareNumericV1
	| AnalysisType.Crosstab
	| AnalysisType.Frequencies
	| AnalysisType.Explore
	| AnalysisType.Kaplan
	| AnalysisType.PlotNumeric
	| AnalysisType.DensityPlot
	| AnalysisType.TimeCourseV1
	| AnalysisType.ComparePaired
	| AnalysisType.LogisticRegression;

export type AnalysisTypeV2 =
	| AnalysisType.CorrelationsV2
	| AnalysisType.CompareNumericV2
	| AnalysisType.CrosstabV2
	| AnalysisType.FrequenciesV2
	| AnalysisType.ExploreV2
	| AnalysisType.KaplanV2
	| AnalysisType.PlotNumericV2
	| AnalysisType.DensityPlotV2
	| AnalysisType.TimeCourseV2
	| AnalysisType.ComparePairedV2
	| AnalysisType.LogisticRegressionV2
	| AnalysisType.NumberPlotXY;

export const DEPRECATED_ANALYSIS_TYPES = [AnalysisType.NumberPlotXY];
