import { FormControl } from '../EntryForm';
import { useController } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { InputError } from '../component/InputError';
import { Label } from '../component/Label';
import { OptionalDescriptionTooltip } from '../component/OptionalDescriptionTooltip';
import { StringFormItem } from '../data/useProjectData/useProjectData';
import clsx from 'clsx';
import { mergeRefs } from '../utils/mergeRefs';

interface Props {
	control: FormControl;
	formItem: StringFormItem;
}

const MIN_HEIGHT = 40;

export const StringVariableInput = ({ control, formItem }: Props) => {
	const { field, fieldState } = useController({
		name: formItem.variable.variableName,
		control
	});

	const textareaRef = useRef<HTMLTextAreaElement>(null);

	const adjustHeight = () => {
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = '0px';
			const scrollHeight = textarea.scrollHeight;

			// Needed to increase it by a small margin in order to avoid having the field become slightly scrollable
			textarea.style.height = Math.max(scrollHeight + 2, MIN_HEIGHT) + 'px';
		}
	};

	// Adjust height on mount
	useEffect(() => {
		adjustHeight();
	}, []);

	return (
		<div className={clsx('flex flex-col', formItem.hideFromUi && 'hidden')}>
			<div className="flex items-center gap-1">
				<Label
					htmlFor={formItem.variable.variableName}
					label={formItem.variable.variableLabel}
					required={formItem.variable.obligatory}
				/>
				<OptionalDescriptionTooltip description={formItem.variable.description} />
			</div>

			<textarea
				{...field}
				ref={mergeRefs(field.ref, textareaRef)}
				id={formItem.variable.variableName}
				className="rounded-md border border-gray-400 p-3 pt-[8px] h-[40px] text-base resize-none no-scrollbar"
				disabled={formItem.variable.entryType === 'calculated'}
				onChange={e => {
					field.onChange(e);
					adjustHeight();
				}}
			/>

			<InputError error={fieldState.error?.message?.toString()} />
		</div>
	);
};
