export const ProjectName = () => {
	return (
		<div>
			<h3 className="text-base font-bold">Project name visibility</h3>

			<p className="text-base mt-6">
				You can now always see the name of the project you&apos;re working on in the
				navigation bar.
			</p>

			<img
				className="mt-6"
				src="/img/entry-form-1-5-change-log/project_name.png"
				alt="Showing the project name in the navbar"
			/>
		</div>
	);
};
