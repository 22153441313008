import { makeRequest } from './makeRequest';

export type GetFileUploadUrlResponse = {
	fileId: string;
	url: string;
	fields: Record<string, string>;
};

export const getFileUploadUrl = async ({
	projectId,
	entryId,
	setName,
	fileName,
	fileVariableName
}: {
	projectId: string;
	entryId: string;
	setName?: string;
	fileName: string;
	fileVariableName: string;
}) => {
	return makeRequest<GetFileUploadUrlResponse>({
		method: 'getFileUploadUrl',
		service: 'cde',
		data: {
			projectId,
			entryId,
			fileName,
			fileVariableName,
			...(setName ? { set: { setName } } : {})
		}
	});
};
