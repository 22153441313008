import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useGetLatestDataEntryVersionQuery } from '../data/useGetLatestDataEntryVersionQuery';
import { useProjectData } from '../data/useProjectData/useProjectData';
import { PrintEntryForm } from '../PrintEntryForm';
import { useReactToPrint } from 'react-to-print';
import { useEffect, useRef } from 'react';
import { Loader } from 'components/UI/Loader';
import { Svgs } from 'environment/theme/Svgs';
import { Icon } from 'components/UI/Icons';
import { ROUTE_MAP } from '../utils/routeMap';
import { Button } from '../component/Button';

export const PrintEntryPage = () => {
	const params = useParams();
	const projectId = params.projectId as string;
	const entryIdFromParams = params.entryId as string;
	const seriesName = params.seriesName as string;
	const seriesEntryIdFromParams = params.seriesEntryId as string;

	// If series name is present, assume we want to print a series entry
	const entryId = seriesName ? seriesEntryIdFromParams : entryIdFromParams;

	const [searchParams] = useSearchParams();
	const formId = searchParams.get('formId');

	const contentRef = useRef<HTMLDivElement>(null);

	const reactToPrint = useReactToPrint({
		contentRef,
		pageStyle: PRINT_PAGE_STYLE,
		onAfterPrint: () => {
			window.close();
		}
	});

	const dataEntryQueryEnabled = !!entryId;
	const dataEntryQuery = useGetLatestDataEntryVersionQuery(
		{
			entryId,
			projectId,
			setName: seriesName
		},
		{
			enabled: dataEntryQueryEnabled
		}
	);

	const projectDataQuery = useProjectData({
		projectId,
		selectedFormId: formId,
		seriesName
	});

	const isFetching = dataEntryQuery.isFetching || projectDataQuery.isFetching;
	const refetch = () => {
		dataEntryQuery.refetch();
		projectDataQuery.refetch();
	};

	const isLoading =
		(dataEntryQuery.isLoading && dataEntryQueryEnabled) || projectDataQuery.isLoading;

	useEffect(() => {
		if (!isLoading && contentRef.current) {
			reactToPrint();
		}
	}, [isLoading, contentRef]);

	if (isLoading) {
		return (
			<div className="flex justify-center items-center h-screen">
				<Loader center />
			</div>
		);
	}

	return projectDataQuery.data ? (
		<div className="hidden print:block">
			<div className="hidden print:block">
				<PrintEntryForm
					projectData={projectDataQuery.data}
					initialEntry={dataEntryQuery.data?.entry}
					contentRef={contentRef}
				/>
			</div>
		</div>
	) : (
		<div>
			<div className="v-[100vw] h-[100vh] flex items-center justify-center flex-col">
				<Icon svg={Svgs.LedidiLogo} customSize={5} />

				<p className="text-base font-bold mt-20">Error printing entry</p>

				<div className="flex flex-col gap-4 mt-10">
					<Button
						title="Reload"
						variant="primary"
						loading={isFetching}
						onClick={refetch}
					/>

					<Link
						className="text-sm underline self-center"
						to={ROUTE_MAP.project.byId.dataset.createPath({ projectId })}
					>
						Back to dataset
					</Link>
				</div>
			</div>
		</div>
	);
};

const PRINT_PAGE_STYLE = `
@media print {
    body {
        height: auto !important;
    }

    textarea {
        box-sizing: content-box !important;
    }
}
`;
