import { useCallback } from 'react';
import * as yup from 'yup';

import { AdminAccount } from 'api/account/admin';
import { PHONE_REGEX_WITH_PLUS_PREFIX } from 'consts';
import { InputType, LanguageType } from 'types/index';

import { AdminUserAvatar } from './AdminUserAvatar';
import { Container, FlexElement, Row } from './AdminUserForm.style';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Input } from 'components/UI/Inputs/Input';
import { HSpacer } from 'components/UI/HSpacer';
import { CountrySelector } from 'components/UI/Interactables/CountrySelector';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { RadioGroup } from 'components/UI/Interactables/RadioGroup';
import { Switch } from 'components/UI/Interactables/Switch';
import { StickyFooter } from 'components/UI/StickyFooter';
import { formatPhoneNumber } from 'helpers/auth';
import { withMemo } from 'helpers/HOCs';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useLicenceModels,
	useAdminCreateUser,
	useAdminUpdateUser
} from 'hooks/store';
import { useReactForm } from 'hooks/ui';

interface Props {
	user: AdminAccount;
	createMode?: boolean;
	setRemoveUserModal?: (value: boolean) => void;
}

export function Component({ user, createMode, setRemoveUserModal }: Props) {
	const { translate } = useTranslation();
	const { navigate, routes } = useNavigation();

	const [
		{
			data: { allLicenceOptions },
			loading: loadingLicenceModels
		}
	] = useLicenceModels();

	const [{ loading: creatingUser }, addUser] = useAdminCreateUser();
	const [{ loading: updatingUser }, updateUser] = useAdminUpdateUser();

	const creatingOrUpdatingUser = creatingUser || updatingUser;

	const validationSchema = yup.object({
		userFirstName: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.firstNameError)),
		userSirName: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.lastNameError)),
		emailAddress: createMode
			? yup
					.string()
					.trim()
					.required(translate(({ accountUM }) => accountUM.validation.emailAddressError))
					.email(
						translate(({ accountUM }) => accountUM.validation.emailAddressErrorValid)
					)
			: yup.string().trim(),
		phoneNumber: yup.lazy(value =>
			value
				? yup
						.string()
						.min(
							8,
							translate(({ accountUM }) => accountUM.validation.phoneNumberErrorValid)
						)
						.max(
							15,
							translate(({ accountUM }) => accountUM.validation.phoneNumberErrorValid)
						)
						.matches(
							PHONE_REGEX_WITH_PLUS_PREFIX,
							translate(({ accountUM }) => accountUM.validation.phoneNumberErrorValid)
						)
				: yup.string()
		)
	});

	const {
		Form,
		Controller,
		register,
		handleSubmit,
		setValue,
		handleTrimOnBlur,
		control,
		errors,
		isDirty,
		isDirtyAndValid: canSubmitForm
	} = useReactForm({
		initialValues: user,
		validationSchema,
		enableReinitialize: !createMode
	});

	const onFormSubmit = handleSubmit(data => {
		if (isDirty && !creatingOrUpdatingUser) createMode ? addUser(data) : updateUser(data);
	});

	function handleValue(
		name:
			| keyof AdminAccount
			| 'userPreferences.chosenLanguageCode'
			| 'userPreferences.dateTimeFormat',
		value: string
	) {
		setValue(name, value, { shouldDirty: true });
	}

	const handlePhoneNumber = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			handleValue('phoneNumber', formatPhoneNumber(e.target.value));
		},
		[]
	);

	function getLicenceOption(value: string) {
		return allLicenceOptions.find(licence => licence.value === value);
	}

	const primaryButtonLoading = creatingOrUpdatingUser;
	const primaryButtonDisabled = !canSubmitForm;

	return (
		<>
			<Container>
				<Form onSubmit={onFormSubmit}>
					<AdminUserAvatar control={control} />

					<Spacer size={s => s.s} />
					<Typography.H6>
						{translate(({ admin }) => admin.users.details.form.fields.personalInfo)}
					</Typography.H6>
					<Spacer size={s => s.s} />
					{!createMode && (
						<>
							<Row>
								<Input
									{...register('cognitoCreatedDate')}
									type={InputType.Text}
									label={translate(
										({ admin }) => admin.users.details.form.fields.createdDate
									)}
									readOnly
								/>
								<HSpacer mobileHeight={h => h.s} />
								<Input
									{...register('cognitoUserStatus')}
									type={InputType.Text}
									label={translate(
										({ admin }) => admin.users.details.form.fields.status
									)}
									readOnly
								/>
							</Row>

							<Spacer size={s => s.s} />
							<Input
								{...register('userid')}
								type={InputType.Text}
								label={translate(
									({ admin }) => admin.users.details.form.fields.userId
								)}
								placeholder={translate(
									({ admin }) => admin.users.details.form.fields.userId
								)}
								readOnly
							/>
						</>
					)}
					<Spacer size={s => s.m} />
					<Row>
						<Input
							{...register('userFirstName', {
								onBlur: handleTrimOnBlur
							})}
							type={InputType.Text}
							error={errors.userFirstName?.message ?? ''}
							label={translate(dict => dict.accountUM.userDetails.firstName)}
							placeholder={translate(dict => dict.accountUM.userDetails.firstName)}
							required
						/>
						<HSpacer mobileHeight={h => h.s} />
						<Input
							{...register('userSirName', {
								onBlur: handleTrimOnBlur
							})}
							type={InputType.Text}
							error={errors.userSirName?.message ?? ''}
							label={translate(dict => dict.accountUM.userDetails.lastName)}
							placeholder={translate(dict => dict.accountUM.userDetails.lastName)}
							required
						/>
					</Row>
					<Spacer size={s => s.s} />
					<Row>
						<Input
							{...register('emailAddress', {
								onBlur: handleTrimOnBlur
							})}
							type={InputType.Email}
							error={errors.emailAddress?.message ?? ''}
							label={translate(dict => dict.accountUM.userDetails.emailAddress)}
							placeholder={translate(dict => dict.accountUM.userDetails.emailAddress)}
							readOnly={!createMode}
							required={createMode}
						/>
						<HSpacer mobileHeight={h => h.s} />
						<Input
							{...register('phoneNumber', {
								onChange: handlePhoneNumber
							})}
							type={InputType.Text}
							error={errors.phoneNumber?.message ?? ''}
							label={translate(dict => dict.accountUM.userDetails.phoneNumber)}
							placeholder={translate(dict => dict.accountUM.userDetails.phoneNumber)}
						/>
					</Row>
					<Spacer size={s => s.m} />
					<Row>
						<Input
							{...register('city', {
								onBlur: handleTrimOnBlur
							})}
							type={InputType.Text}
							error={errors.city?.message ?? ''}
							label={translate(dict => dict.accountUM.userDetails.city)}
							placeholder={translate(dict => dict.accountUM.userDetails.city)}
						/>
						<HSpacer mobileHeight={h => h.s} />
						<Controller
							control={control}
							name="country"
							render={({ field: { name, value } }) => (
								<CountrySelector
									value={value}
									label={translate(dict => dict.accountUM.userDetails.country)}
									placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
									onSelect={country => handleValue(name, country?.ISO_2 ?? '')}
								/>
							)}
						/>
					</Row>

					<Spacer size={s => s.l} />
					<Typography.H6>
						{translate(({ admin }) => admin.users.details.form.fields.workInfo)}
					</Typography.H6>
					<Spacer size={s => s.s} />
					<Row>
						<Input
							{...register('position', {
								onBlur: handleTrimOnBlur
							})}
							type={InputType.Text}
							error={errors.position?.message ?? ''}
							label={translate(dict => dict.accountUM.userDetails.position)}
							placeholder={translate(dict => dict.accountUM.userDetails.position)}
						/>
						<HSpacer mobileHeight={h => h.s} />
						<Input
							{...register('department', {
								onBlur: handleTrimOnBlur
							})}
							type={InputType.Text}
							error={errors.department?.message ?? ''}
							label={translate(dict => dict.accountUM.userDetails.department)}
							placeholder={translate(dict => dict.accountUM.userDetails.department)}
						/>
					</Row>
					<Spacer size={s => s.s} />
					<Row>
						<Input
							{...register('workplace', {
								onBlur: handleTrimOnBlur
							})}
							type={InputType.Text}
							error={errors.workplace?.message ?? ''}
							label={translate(dict => dict.accountUM.userDetails.workplace)}
							placeholder={translate(dict => dict.accountUM.userDetails.workplace)}
						/>
					</Row>

					<Spacer size={s => s.l} />
					<Typography.H6>
						{translate(({ admin }) => admin.users.details.form.fields.system)}
					</Typography.H6>
					<Spacer size={s => s.s} />
					<Row>
						<Controller
							control={control}
							name="licenceModel"
							render={({ field: { name, value } }) => (
								<CreatableSelect
									value={getLicenceOption(value)}
									label={translate(
										({ admin }) => admin.users.details.form.fields.licence
									)}
									disabled={loadingLicenceModels}
									items={allLicenceOptions}
									onValueSelected={value => handleValue(name, value ?? '')}
									canClear={false}
									scrollIntoView
								/>
							)}
						/>
						{!createMode && (
							<>
								<HSpacer mobileHeight={h => h.s} />
								<Controller
									control={control}
									name="subscriptionId"
									render={({ field: { name, value } }) => (
										<Input
											label={translate(
												({ admin }) =>
													admin.users.details.form.fields.subscriptionId
											)}
											readOnly
											name={name}
											type={InputType.Text}
											value={value}
										/>
									)}
								/>
							</>
						)}
					</Row>
					<Spacer size={s => s.m} />
					<Row>
						<Controller
							control={control}
							name="userPreferences.chosenLanguageCode"
							render={({ field: { name, value } }) => (
								<FlexElement>
									<RadioGroup
										label={translate(
											({ admin }) => admin.users.details.form.fields.language
										)}
										selected={value}
										items={[LanguageType.English, LanguageType.Norwegian]}
										onSelect={item => handleValue(name, item)}
									/>
								</FlexElement>
							)}
						/>
						<HSpacer mobileHeight={h => h.s} />
						<Controller
							control={control}
							name="userPreferences.dateTimeFormat"
							render={({ field: { name, value } }) => (
								<FlexElement>
									<RadioGroup
										label={translate(
											({ admin }) =>
												admin.users.details.form.fields.dateTimeFormat
										)}
										selected={value}
										items={['24', '12']}
										onSelect={item => handleValue(name, item)}
									/>
								</FlexElement>
							)}
						/>
					</Row>
					<Spacer size={s => s.m} />
					<Controller
						control={control}
						name="status"
						render={({ field: { value, name } }) => {
							const isActive = value === 'ACTIVE';

							return (
								<Switch
									label={translate(
										({ admin }) => admin.users.details.form.fields.activeStatus
									)}
									on={isActive}
									onChange={() => {
										const newStatus = isActive ? 'INACTIVE' : 'ACTIVE';
										setValue(name, newStatus, {
											shouldDirty: true
										});
									}}
								/>
							);
						}}
					/>
				</Form>
				{user.userCredentials && (
					<>
						<Spacer size={s => s.l} />
						<Typography.H6>
							{translate(
								({ admin }) => admin.users.details.form.fields.userCredentials
							)}
						</Typography.H6>
						<Spacer size={s => s.s} />

						<Row>
							<Input
								readOnly
								type={InputType.Text}
								value={user.userCredentials.temporaryPasswordValidityDays}
								label={translate(
									({ admin }) =>
										admin.users.list.columns.temporaryPasswordValidityDays
								)}
							/>
							<HSpacer mobileHeight={h => h.s} />
							<Input
								readOnly
								type={InputType.Text}
								value={String(user.userCredentials.phoneNumberVerified)}
								label={translate(
									({ admin }) => admin.users.list.columns.phoneNumberVerified
								)}
							/>
						</Row>
						<Spacer size={s => s.s} />
						<Row>
							<Input
								readOnly
								type={InputType.Text}
								value={String(user.userCredentials.forceChangePassword)}
								label={translate(
									({ admin }) => admin.users.list.columns.forceChangePassword
								)}
							/>
							<HSpacer mobileHeight={h => h.s} />
							<Input
								readOnly
								type={InputType.Text}
								value={String(user.userCredentials.mfaEnabled)}
								label={translate(
									({ admin }) => admin.users.list.columns.mfaEnabled
								)}
							/>
						</Row>
					</>
				)}
			</Container>

			<StickyFooter
				primary={{
					label: translate(({ buttons }) =>
						createMode ? buttons.create : buttons.update
					),
					loading: primaryButtonLoading,
					disabled: primaryButtonDisabled,
					onClick: onFormSubmit
				}}
				neutral={{
					label: translate(({ buttons }) => buttons.cancel),
					onClick: primaryButtonLoading ? undefined : () => navigate(routes.admin.view)
				}}
				danger={
					!createMode && {
						label: translate(({ buttons }) => buttons.delete),
						onClick: primaryButtonLoading
							? undefined
							: () => setRemoveUserModal && setRemoveUserModal(true)
					}
				}
				maxWidth={76}
			/>
		</>
	);
}

export const AdminUserForm = withMemo(Component, ['user']);
