import { useState, useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { cloneDeep } from 'lodash';
import { VariableType } from 'types/data/variables/constants';
import { VariablesDataArray } from 'store/data/variables';
import { DragAndDropTypes, SelectItem } from 'types/index';
import { CollapsibleGroup } from './CollapsibleGroup';
import { DraggableDependencyVariableCard } from './DraggableDependencyVariableCard';
import { Line } from './DependenciesSidebar.style';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Flex } from 'components/UI/Flex';
import { Gap } from 'components/UI/Gap';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Pagination } from 'components/UI/Pagination';
import { Spacer } from 'components/UI/Spacer';
import {
	buildVariableSetVariablesData,
	buildVariablesRichData,
	isGroupData,
	isVariableSetData,
	filterVariablesDataArrayBySearchTerm,
	variablesDataArrayJSXIterator
} from 'helpers/variables';
import { useTranslation, useVariablesData, useVariables } from 'hooks/store';
import { usePaginate } from 'hooks/utils';

interface Props {
	context: string | null;
	onSelectContext: (context: string | null) => void;
}

export function DependenciesSidebar({ context, onSelectContext }: Props) {
	const { translate } = useTranslation();

	const variablesData = useVariablesData({ initial: true });
	const [
		{
			data: { variablesDataArray, variableSets }
		}
	] = useVariables({
		lazy: true,
		initial: true,
		omit: {
			restricted: true,
			types: [VariableType.File, VariableType.Unique, VariableType.TimeDuration]
		}
	});

	const [searchTerm, setSearchTerm] = useState('');

	const filteredVariablesDataArray = useMemo(() => {
		let scopeVariablesDataArray = variablesDataArray;

		if (context !== null) {
			const variableSetVariablesData = buildVariableSetVariablesData({
				setName: context,
				variablesData
			});

			const { variablesDataArray: setVariablesDataArray } = buildVariablesRichData(
				variableSetVariablesData,
				{
					omit: {
						restricted: true,
						types: [VariableType.File, VariableType.Unique, VariableType.TimeDuration]
					}
				}
			);

			scopeVariablesDataArray = setVariablesDataArray;
		}

		let filtered: VariablesDataArray = cloneDeep(scopeVariablesDataArray);

		filtered = filtered.filter(item => {
			// GROUP DATA
			if (isGroupData(item)) {
				const groupData = item;

				groupData.groupVariables = groupData.groupVariables.filter(groupVariable => {
					const isCategory = [
						VariableType.Category,
						VariableType.CategoryMultiple
					].includes(groupVariable.type);
					const hasFixedCategories = groupVariable.fixedCategories;

					if (isCategory && !hasFixedCategories) return false;

					return true;
				});

				if (groupData.groupVariables.length > 0) return true;

				return false;
			}

			// VARIABLE SET DATA - OMIT
			if (isVariableSetData(item)) return false;

			// VARIABLE
			const variable = item;

			const isCategory = [VariableType.Category, VariableType.CategoryMultiple].includes(
				variable.type
			);
			const hasFixedCategories = variable.fixedCategories;

			if (isCategory && !hasFixedCategories) return false;

			return true;
		});

		// APPLY SEARCH TERM FILTER
		filtered = filterVariablesDataArrayBySearchTerm(filtered, searchTerm, {
			translate
		});

		return filtered;
	}, [variablesData, variablesDataArray, context, searchTerm]);

	const { pageIndex, pageSize, pagesCount, shouldPaginate, page, changePage, resetPage } =
		usePaginate(filteredVariablesDataArray, {
			threshold: 20,
			pageSize: 10
		});

	function handleChangeTerm(term: string) {
		resetPage();
		setSearchTerm(term);
	}

	function getContextSelectItems() {
		const items: SelectItem[] = [];

		const mainLevelItem: SelectItem = {
			label: translate(dict => dict.dependencies.dependenciesSidebar.mainLevel),
			value: ''
		};

		items.push(mainLevelItem);

		variableSets.forEach(variableSet => {
			const { setLabel, setName } = variableSet;

			const item: SelectItem = {
				label: setLabel,
				value: setName
			};

			items.push(item);
		});

		return items;
	}

	const contextSelectItems = useMemo(getContextSelectItems, [variableSets]);

	return (
		<Flex marginOffset={{ bottom: 2 }} column>
			<CreatableSelect
				label={translate(dict => dict.dependencies.dependenciesSidebar.selectContext)}
				hint={translate(dict => dict.dependencies.dependenciesSidebar.hint)}
				items={contextSelectItems}
				value={
					contextSelectItems.find(item => item.value === context) ?? contextSelectItems[0]
				}
				onValueSelected={value => onSelectContext(value || null)}
				canClear={false}
			/>

			<Spacer size={s => s.m} />
			<Line />
			<Spacer size={s => s.m} />

			<SearchInput
				term={searchTerm}
				onChangeTerm={handleChangeTerm}
				placeholder={translate(dict => dict.terms.search)}
			/>
			<Spacer size={s => s.m} />

			{shouldPaginate && (
				<Flex marginOffset={{ bottom: 0.8 }}>
					<Pagination
						pageIndex={pageIndex}
						pageSize={pageSize}
						pagesCount={pagesCount}
						changePage={changePage}
						openPageInputOnRight
						simpleVersion
					/>
				</Flex>
			)}

			<Droppable droppableId={DragAndDropTypes.DraggableDependenciesVarList} isDropDisabled>
				{provided => (
					<div ref={provided.innerRef}>
						<Gap marginGap={{ bottom: 0.8 }}>
							{variablesDataArrayJSXIterator(
								page,
								/**
								 * VARIABLE
								 */
								(variable, index) => (
									<DraggableDependencyVariableCard
										key={`variable-${variable.name}`}
										index={index}
										variable={variable}
									/>
								),
								/**
								 * GROUP DATA
								 */
								(groupData, index) => (
									<CollapsibleGroup
										key={`group-${groupData.groupName}_index-${index}`}
										index={index}
										groupData={groupData}
									/>
								),
								/**
								 * VARIABLE SET DATA
								 */
								() => null
							)}
						</Gap>

						<div
							style={{
								visibility: 'hidden',
								height: 0,
								width: 0
							}}
						>
							{provided.placeholder}
						</div>
					</div>
				)}
			</Droppable>
		</Flex>
	);
}
