import { useMutation } from '@tanstack/react-query';
import { makeRequest } from 'features/entry-form-v2/data/makeRequest';

type Result = {
	singedUrl: string;
};

export type ExportDatasetsMutationArgs = {
	projectId: string;
	setNames: string[];
	amountOfData: 'justData' | 'importable' | 'allData';
	exportFormat: 'csv' | 'excel';
	categoryLabels: boolean;
	removeLineShifts: boolean;
};

export const useExportDatasetsMutation = () => {
	return useMutation({
		mutationFn: async ({
			projectId,
			setNames,
			amountOfData,
			exportFormat,
			categoryLabels
		}: ExportDatasetsMutationArgs) => {
			const result: Result = await makeRequest({
				method: 'exportDatasets',
				service: 'stats',
				data: {
					projectId,
					filters: [],
					exportFormat,
					amountOfData,
					datasets: setNames.map(datasetName => ({ datasetName })),
					categoryLabels
				}
			});

			return result;
		}
	});
};
