import { createSelector } from 'reselect';

import { State } from './types';

function getAllEnterpriseOwnedProjects(state: State) {
	return state.allEnterpriseProjects;
}

export const selectAllEnterpriseOwnedProjects = createSelector(
	[getAllEnterpriseOwnedProjects],
	allEnterpriseProjects => allEnterpriseProjects
);

function getOrganizationAccessRights(state: State) {
	return state.organizationAccessRights;
}

export const selectOrganizationAccessRights = createSelector(
	[getOrganizationAccessRights],
	organizationAccessRights => organizationAccessRights
);
