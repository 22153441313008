import type { Action } from 'types/store/types';

export interface State {
	activities: Activity[];
	errors: ActivityError[];
}

export interface Activity {
	uuid: string;
	type: string;
	payload?: any;
}

export type EndActivity = Pick<Activity, 'uuid' | 'type'>;

export interface ActivityError {
	uuid: string;
	type: string | null;
	error: string | null;
	toast: {
		display: boolean;
		message?: string;
	};
	payload?: any;
	timeout?: number;
}

export type ActivityClearError = Pick<ActivityError, 'uuid' | 'type'>;

export enum ActionTypes {
	BEGIN_ACTIVITY = 'ui/activities/BEGIN_ACTIVITY',
	END_ACTIVITY = 'ui/activities/END_ACTIVITY',
	ERROR = 'ui/activities/ERROR',
	CLEAR_ERROR = 'ui/activities/CLEAR_ERROR',
	CLEAR_ACTION_TYPE_ERROR = 'ui/activities/CLEAR_ACTION_TYPE_ERROR',
	///////////////////////////////////////////////////////////
	DO_AFTER_TRANSACTION = 'ui/activities/DO_AFTER_TRANSACTION'
}

export type BeginActivityAction = Action<ActionTypes.BEGIN_ACTIVITY, Activity>;

export type EndActivityAction = Action<ActionTypes.END_ACTIVITY, EndActivity>;

export type ActivityErrorAction = Action<ActionTypes.ERROR, ActivityError>;

export type ActivityClearErrorAction = Action<ActionTypes.CLEAR_ERROR, ActivityClearError>;

export type ActivityClearActionTypeErrorAction = Action<
	ActionTypes.CLEAR_ACTION_TYPE_ERROR,
	string
>;

export type Actions =
	| BeginActivityAction
	| EndActivityAction
	| ActivityErrorAction
	| ActivityClearErrorAction
	| ActivityClearActionTypeErrorAction;
