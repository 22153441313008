import type { User } from 'api/auth';
import type { Action } from 'types/store/types';

export interface State {
	loginStep: LoginSteps | null;
	oneTimePassword: {
		enabled: boolean;
		secret: string | null;
	};
	loggedIn: boolean;
	username: string | null;
	user: User | null;
	errors: {
		email?: string;
		credentials?: string;
	};
}

export enum LoginSteps {
	newPassword = 'newPassword',
	forgotPassword = 'forgotPassword',
	resetPassword = 'resetPassword',
	oneTimePassword = 'oneTimePassword',
	setupOneTimePassword = 'setupOneTimePassword',
	setupAccountInfo = 'myProfileIsIncomplete'
}

export enum ActionTypes {
	LOGIN = 'auth/LOGIN',
	RESET_LOGIN_ERRORS = 'auth/RESET_LOGIN_ERRORS',
	SET_LOGIN_STEP = 'auth/SET_LOGIN_STEP',
	LOGOUT = 'auth/LOGOUT',
	COMPLETE_FIRST_LOGIN = 'auth/COMPLETE_FIRST_LOGIN',
	FORGOT_PASSWORD = 'auth/FORGOT_PASSWORD',
	RESET_PASSWORD = 'auth/RESET_PASSWORD',
	COMPLETE_RESET_PASSWORD = 'auth/COMPLETE_RESET_PASSWORD',
	SET_ONE_TIME_PASSWORD = 'auth/SET_ONE_TIME_PASSWORD',
	VERIFY_ONE_TIME_PASSWORD = 'auth/VERIFY_ONE_TIME_PASSWORD',
	SUBMIT_ONE_TIME_PASSWORD = 'auth/SUBMIT_ONE_TIME_PASSWORD',
	CLEAR_ONE_TIME_PASSWORD = 'auth/CLEAR_ONE_TIME_PASSWORD',
	RESET_MULTI_FACTOR_AUTH = 'auth/RESET_MULTI_FACTOR_AUTH',
	RESET_STORE = 'auth/RESET_STORE',
	LOCAL_LOG_OUT = 'auth/LOCAL_LOG_OUT',
	LOCAL_LOGIN = 'auth/LOCAL_LOGIN',
	SET_USERNAME = 'auth/SET_USERNAME',
	TOKEN_EXPIRED_ERROR = '/auth/TOKEN_EXPIRED_ERROR'
}

export interface LoginInput {
	loginStep?: LoginSteps;
	loggedIn?: boolean;
	user?: User;
	errors?: {
		email?: string;
		credentials?: string;
		newPasswordRequired?: boolean;
	};
}

export type LoginAction = Action<ActionTypes.LOGIN, LoginInput>;

export type ResetLoginErrorsAction = Action<ActionTypes.RESET_LOGIN_ERRORS>;

export type SetLoginStepAction = Action<
	ActionTypes.SET_LOGIN_STEP,
	{
		step: LoginSteps;
	}
>;

export type ForgotPasswordAction = Action<ActionTypes.FORGOT_PASSWORD>;

export type ResetPasswordAction = Action<
	ActionTypes.RESET_PASSWORD,
	{
		username: string;
	}
>;

export type SetOneTimePasswordAction = Action<
	ActionTypes.SET_ONE_TIME_PASSWORD,
	{
		secret: string;
	}
>;

export type VerifyOneTimePasswordAction = Action<ActionTypes.VERIFY_ONE_TIME_PASSWORD>;

export type SubmitOneTimePasswordAction = Action<ActionTypes.SUBMIT_ONE_TIME_PASSWORD>;

export type ClearOneTimePasswordAction = Action<ActionTypes.CLEAR_ONE_TIME_PASSWORD>;

export type ResetMultiFactorAuthAction = Action<ActionTypes.RESET_MULTI_FACTOR_AUTH>;

export type ResetStoreAction = Action<ActionTypes.RESET_STORE>;

export type LocalLogOutAction = Action<ActionTypes.LOCAL_LOG_OUT>;

export type LocalLogInAction = Action<ActionTypes.LOCAL_LOGIN>;

export type SetUsernameAction = Action<ActionTypes.SET_USERNAME, { username: string }>;

export type Actions =
	| LoginAction
	| ResetLoginErrorsAction
	| SetLoginStepAction
	| ForgotPasswordAction
	| ResetPasswordAction
	| SetOneTimePasswordAction
	| VerifyOneTimePasswordAction
	| SubmitOneTimePasswordAction
	| ClearOneTimePasswordAction
	| ResetMultiFactorAuthAction
	| ResetStoreAction
	| LocalLogOutAction
	| LocalLogInAction
	| SetUsernameAction;
