import produce from 'immer';
import initialState from './initialState';
import { State, Actions, ActionTypes } from './types';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case ActionTypes.GET_ALL_ENTERPRISE_OWNED_PROJECTS: {
			const { projects } = action.payload;

			return produce(state, draft => {
				draft.allEnterpriseProjects = projects;
			});
		}
		case ActionTypes.SET_ORGANIZATION_ACCESS_RIGHTS: {
			const { organizationAccessRights } = action.payload;

			return produce(state, draft => {
				draft.organizationAccessRights = organizationAccessRights;
			});
		}

		default: {
			return state;
		}
	}
};
