import { useCallback, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DraggableVariableCardType, MergeVariablesIntoGroup } from 'store/data/variables';
import { VariableCard } from '../VariableCard';
import { VariableGroupCard } from '..';
import { VariableSetCard } from '../VariableSetCard';
import { VariableSetConfirmDragAndDropActionModal } from '../../ConfirmDragAndDropActionModal';
import { CardTypes, CardTypesE, DragProps, VariableCardPayload } from './types';
import {
	CardReplacement,
	DropToLeft,
	DropToRight,
	HoverOver,
	Label,
	LineMarker
} from '../VariableCard/VariableCard.style';
import {
	useTranslation,
	useMoveGroup,
	useMoveVariable,
	useMoveVariableSet,
	useRemoveVariableFromGroup,
	useRemoveVariableFromSet,
	useRemoveVariableGroupFromSet,
	useDragVariableContext
} from 'hooks/store';

export interface CommonCardProps {
	title: string;
	name: string;
	index: number;
	listLength: number;
	readOnly: boolean;
	systemGenerated?: boolean;
	preventDrag: boolean;
	isListHovered: boolean;
	cardType: CardTypes;
	hasError?: boolean;
	onClick: (name: string) => void;
	// This is only needed for VariableCard
	mergeVariables: MergeVariablesIntoGroup;
}

export function CommonCard({
	title,
	name,
	index,
	listLength,
	readOnly,
	systemGenerated,
	preventDrag,
	isListHovered,
	cardType,
	hasError,
	mergeVariables,
	onClick
}: CommonCardProps) {
	const invisRefRight = useRef<HTMLDivElement>(null);
	const invisRefLeft = useRef<HTMLDivElement>(null);
	const mainRef = useRef<HTMLDivElement>(null);
	const { translate } = useTranslation();

	const [, moveGroup] = useMoveGroup();
	const [, moveVariable] = useMoveVariable();
	const [, moveVariableSet] = useMoveVariableSet();

	const [, removeVariableFromGroup] = useRemoveVariableFromGroup();
	const [, removeVariableFromSet] = useRemoveVariableFromSet();
	const [, removeVariableGroupFromSet] = useRemoveVariableGroupFromSet();

	const { setDraggingVariable } = useDragVariableContext();

	const isVariable = cardType.type === CardTypesE.Variable;
	const isGroup = cardType.type === CardTypesE.Group;
	const isVariableSet = cardType.type === CardTypesE.Set;

	const [dropFunctionToCall, setDropFunctionToCall] = useState<
		('handleDropLeft' | 'handleDropRight') | null
	>(null);

	const [confirmAction, setConfirmAction] = useState<VariableCardPayload | null>(null);
	const confirmActionModal = {
		open: (item: VariableCardPayload) => setConfirmAction(item),
		close: () => {
			setConfirmAction(null);
			setDropFunctionToCall(null);
		}
	};

	// DRAG FOR VARIABLE / GROUP / VARIABLE SET MAIN CARD
	const [{ isDragging }, drag, dragPreview] = useDrag({
		type: isGroup
			? DraggableVariableCardType.Group
			: isVariableSet
			? DraggableVariableCardType.VariableSet
			: DraggableVariableCardType.Variable,
		canDrag: !preventDrag,
		item: (): VariableCardPayload => ({
			name,
			index,
			isGroup,
			isVariableSet,
			systemGenerated
		}),
		collect: monitor => ({ isDragging: monitor.isDragging() })
	});

	// UPDATE REDUX WITH DRAGGING VARIABLE NAME;
	useEffect(() => {
		if (isDragging) {
			setDraggingVariable(name);

			return () => setDraggingVariable('');
		}
	}, [isDragging]);

	// DROP FOR LEFT
	const [{ leftHandlerId, isOverCurrentLeft, isSysGenVariable }, dropLeft] = useDrop<
		any,
		any,
		any
	>({
		accept: [
			DraggableVariableCardType.Variable,
			DraggableVariableCardType.Group,
			DraggableVariableCardType.VariableSet
		],
		collect: monitor => {
			const draggedVariable: VariableCardPayload = monitor.getItem();
			const isSysGenVariable = draggedVariable && draggedVariable.systemGenerated;
			return {
				leftHandlerId: monitor.getHandlerId(),
				isOverCurrentLeft: monitor.isOver({ shallow: true }),
				isSysGenVariable
			};
		},
		drop(item: VariableCardPayload, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			if (item.parentSet) {
				confirmActionModal.open(item);
				setDropFunctionToCall('handleDropLeft');

				return;
			}

			handleDropLeft(item);
		}
	});

	// DROP FOR RIGHT
	const [{ rightHandlerId, isOverCurrentRight }, dropRight] = useDrop<any, any, any>({
		accept: [
			DraggableVariableCardType.Variable,
			DraggableVariableCardType.Group,
			DraggableVariableCardType.VariableSet
		],
		collect: monitor => ({
			rightHandlerId: monitor.getHandlerId(),
			isOverCurrentRight: monitor.isOver({ shallow: true })
		}),
		drop(item: VariableCardPayload, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			if (item.parentSet) {
				confirmActionModal.open(item);
				setDropFunctionToCall('handleDropRight');

				return;
			}

			handleDropRight(item);
		}
	});

	const dragMainProps: DragProps = {
		ref: mainRef,
		isDragging,
		drag
	};

	dropLeft(invisRefLeft);
	dropRight(invisRefRight);

	function handleDropLeft(item: VariableCardPayload) {
		const sourceIndex = item.index;
		const destinationIndex = index;

		// DROP CARD FROM VARIABLE SET DRAWER
		if (item.parentSet) {
			// VARIABLE CARD FROM GROUP DRAWER
			if (item.parentGroup) {
				return removeVariableFromSet({
					variableName: item.name,
					setName: item.parentSet,
					destinationIndex,
					parentGroup: item.parentGroup
				});
			}

			// GROUP CARD
			if (item.isGroup) {
				return removeVariableGroupFromSet({
					groupName: item.name,
					setName: item.parentSet,
					destinationIndex
				});
			}

			// VARIABLE CARD
			return removeVariableFromSet({
				variableName: item.name,
				setName: item.parentSet,
				destinationIndex
			});
		}

		// DROP VARIABLE CARD FROM VARIABLE GROUP DRAWER
		if (item.parentGroup) {
			return removeVariableFromGroup({
				variableName: item.name,
				groupName: item.parentGroup,
				destinationIndex
			});
		}

		// GROUP CARD
		if (item.isGroup) {
			return moveGroup({
				groupName: item.name,
				sourceIndex,
				destinationIndex
			});
		}

		// VARIABLE SET CARD
		if (item.isVariableSet) {
			return moveVariableSet({
				setName: item.name,
				sourceIndex,
				destinationIndex
			});
		}

		// VARIABLE CARD
		moveVariable({
			variableName: item.name,
			sourceIndex,
			destinationIndex
		});
	}

	function handleDropRight(item: VariableCardPayload) {
		const sourceIndex = item.index;
		const destinationIndex = index < sourceIndex ? index + 1 : index;

		// DROP CARD FROM VARIABLE SET DRAWER
		if (item.parentSet) {
			// VARIABLE CARD FROM GROUP DRAWER
			if (item.parentGroup) {
				return removeVariableFromSet({
					variableName: item.name,
					setName: item.parentSet,
					destinationIndex: index + 1,
					parentGroup: item.parentGroup
				});
			}

			// GROUP CARD
			if (item.isGroup) {
				return removeVariableGroupFromSet({
					groupName: item.name,
					setName: item.parentSet,
					destinationIndex: index + 1
				});
			}

			// VARIABLE CARD
			return removeVariableFromSet({
				variableName: item.name,
				setName: item.parentSet,
				destinationIndex: index + 1
			});
		}

		// DROP VARIABLE CARD FROM VARIABLE GROUP DRAWER
		if (item.parentGroup) {
			return removeVariableFromGroup({
				variableName: item.name,
				groupName: item.parentGroup,
				destinationIndex: index + 1
			});
		}

		// GROUP CARD
		if (item.isGroup) {
			return moveGroup({
				groupName: item.name,
				sourceIndex,
				destinationIndex
			});
		}

		// VARIABLE SET CARD
		if (item.isVariableSet) {
			return moveVariableSet({
				setName: item.name,
				sourceIndex,
				destinationIndex
			});
		}

		// VARIABLE CARD
		moveVariable({
			variableName: item.name,
			sourceIndex,
			destinationIndex
		});
	}

	function handleFinalDrop(item: VariableCardPayload) {
		if (!dropFunctionToCall) return;

		if (dropFunctionToCall === 'handleDropLeft') handleDropLeft(item);
		if (dropFunctionToCall === 'handleDropRight') handleDropRight(item);
	}

	const getDropOverCardMessage = useCallback(() => {
		if (isVariable) return translate(dict => dict.variablesPage.createGroupOrSeries);
		if (isGroup) return translate(dict => dict.variablesPage.addToGroup);
		if (isVariableSet) return translate(dict => dict.variablesPage.addToSeries);
	}, [isVariable, isGroup, isVariableSet]);

	const isFirstItem = index === 0;
	const isLastItem = index === listLength - 1;

	const showMarkOnListHover = isLastItem && isListHovered;

	const commonChildren = (
		<>
			<HoverOver systemGenerated={systemGenerated || isSysGenVariable}>
				{!(systemGenerated || isSysGenVariable) && (
					<Label>{getDropOverCardMessage()}</Label>
				)}
			</HoverOver>

			{isFirstItem && (
				<DropToLeft
					ref={invisRefLeft}
					data-handler-id={leftHandlerId}
					isHovered={isOverCurrentLeft}
				>
					<LineMarker />
				</DropToLeft>
			)}

			<DropToRight
				ref={invisRefRight}
				data-handler-id={rightHandlerId}
				isHovered={showMarkOnListHover || isOverCurrentRight}
			>
				<LineMarker />
			</DropToRight>
		</>
	);

	function renderCardType(cardType: CardTypes) {
		if (cardType) {
			if (cardType.type === CardTypesE.Variable) {
				const { name, subtitle, required } = cardType.payload;

				return (
					<VariableCard
						dragMainProps={dragMainProps}
						index={index}
						name={name}
						title={title}
						subtitle={subtitle}
						required={required}
						readOnly={readOnly}
						systemGenerated={!!systemGenerated}
						onClick={() => onClick(name)}
						mergeVariables={mergeVariables}
						hasError={hasError}
					>
						{commonChildren}
					</VariableCard>
				);
			}

			if (cardType.type === CardTypesE.Group) {
				const { name, selected, numberOfVariables } = cardType.payload;

				return (
					<VariableGroupCard
						name={name}
						title={title}
						selected={selected}
						//
						dragMainProps={dragMainProps}
						numberOfVariables={numberOfVariables}
						hasError={hasError}
						//
						onClick={() => onClick(name)}
					>
						{commonChildren}
					</VariableGroupCard>
				);
			}

			if (cardType.type === CardTypesE.Set) {
				const { name, selected, numberOfVariables } = cardType.payload;

				return (
					<VariableSetCard
						name={name}
						title={title}
						selected={selected}
						//
						dragMainProps={dragMainProps}
						numberOfVariables={numberOfVariables}
						//
						onClick={() => onClick(name)}
					>
						{commonChildren}
					</VariableSetCard>
				);
			}
		}

		return null;
	}

	if (isDragging) {
		return (
			<CardReplacement ref={dragPreview}>
				<DropToLeft isHovered={true}>
					<LineMarker />
				</DropToLeft>
			</CardReplacement>
		);
	}

	return (
		<>
			{renderCardType(cardType)}

			{/* CONFIRM DRAG-N-DROP ACTION MODAL */}
			{confirmAction && (
				<VariableSetConfirmDragAndDropActionModal
					item={confirmAction}
					direction="from"
					onConfirm={() => handleFinalDrop(confirmAction)}
					onClose={confirmActionModal.close}
				/>
			)}
		</>
	);
}
