import { CloseIcon } from '@icons';
import { useState } from 'react';

export const SafariUserWarning = () => {
	const [hasClosed, setHasClosed] = useState(
		localStorage.getItem(SAFARI_USER_WARNING_KEY) === 'true'
	);

	if (!isSafari() || hasClosed) {
		return null;
	}

	return (
		<div className="fixed top-20 left-1/2 -translate-x-1/2 p-8 pr-12 rounded-md bg-warning-300 shadow-normal z-50">
			<button
				className="absolute top-2 right-2 p-2 rounded-full hover:bg-warning-800/10"
				onClick={() => {
					localStorage.setItem(SAFARI_USER_WARNING_KEY, 'true');
					setHasClosed(true);
				}}
			>
				<CloseIcon className="text-icon-200 text-warning-800" />
			</button>

			<p className="text-base text-warning-800 ">
				We have detected that you use Safari. For optimal performance, we recommend{' '}
				<a
					className="underline font-semibold"
					target="_blank"
					rel="noreferrer"
					href="https://www.microsoft.com/nb-no/edge/?ch=1&cs=2199494592&form=MA13FJ"
				>
					Microsoft Edge
				</a>{' '}
				or{' '}
				<a
					className="underline font-semibold"
					href="https://www.google.com/chrome/"
					target="_blank"
					rel="noreferrer"
				>
					Google Chrome
				</a>
			</p>
		</div>
	);
};

export const SAFARI_USER_WARNING_KEY = 'safari-user-warning-closed';

const isSafari = () => {
	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};
