import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { EntryHeader } from '../../component/EntryHeader';
import { ROUTE_MAP } from '../../utils/routeMap';
import { useGetProjectQuery } from '../../data/useGetProjectQuery';

import { useEffect, useMemo, useState } from 'react';
import { Svgs } from 'environment';
import { Icon } from 'components/UI/Icons';
import {
	SeriesEntryBody,
	SeriesEntryBodyRow,
	TableSkeleton,
	formatSystemVariableValue
} from '../../smart-components/series-entry-body/SeriesEntryBody';
import { SeriesFormItem, useProjectData } from '../../data/useProjectData/useProjectData';
import { ErrorLoadingEntry, HeaderSkeleton } from '../../update-entry/UpdateEntryPageV1_5';
import { Button } from '../../component/Button';
import { ColumnFilterDropdown } from '../ColumnFilterDropdown';
import {
	SYSTEM_VARIABLES_BY_VARIABLE_NAME,
	useSeriesTablesDataQuery
} from '../../smart-components/series-entry-body/useSeriesTablesDataQuery';
import { useGetRepeatingSetRowsV2Query } from '../../data/useGetRepeatingSetRowsV2Query';
import { createFilterByFreeText } from '../../smart-components/series-entry-body/entriesFilter';
import { Skeleton } from '../../component/Skeleton';
import { Menu } from '@headlessui/react';
import { ExportSeriesModal } from '../export-series-modal/ExportSeriesModal';
import { AggregationRulesDetails } from '../../smart-components/AggregationRulesDetails';
import { useVariablesQuery } from 'features/entry-form-v2/data/useVariablesQuery/useVariablesQuery';
import { useGetLatestDataEntryVersionQuery } from 'features/entry-form-v2/data/useGetLatestDataEntryVersionQuery';
import {
	BackendEntryValue,
	isCategoryFixedVariable,
	isCategoryMultipleFixedVariable,
	isEmptyBackendEntryValue,
	isSystemVariable,
	Variable
} from 'features/entry-form-v2/types';
import { formatMicroseconds } from 'features/entry-form-v2/inputs/time-duration/formatMicroSeconds';
import { useGetNamesFromUserIdsQuery } from 'features/entry-form-v2/data/useGetNamesFromUserIdsQuery';
import { CloseIcon } from '@icons';
import { IconButton } from 'features/entry-form-v2/component/IconButton';
import { DateTime } from 'luxon';
import { useGetFilesInfoQuery } from 'features/entry-form-v2/data/useGetFilesInfoQuery';

export const SeriesDetailsPage = () => {
	const params = useParams();
	const projectId = params['projectId'] as string;
	const seriesName = params['seriesName'] as string;
	const entryId = params['entryId'] as string;

	const navigate = useNavigate();

	const [filteredVariables, setFilteredVariables] = useState<Set<string>>(new Set());

	const [freeTextSearch, setFreeTextSearch] = useState('');

	const projectDataQuery = useProjectData({
		projectId
	});

	// needed to preload aggregation rules display
	const variablesDataQuery = useVariablesQuery({
		projectId
	});
	// needed to preload aggregation rules display
	const dataEntryQuery = useGetLatestDataEntryVersionQuery({
		entryId,
		projectId
	});
	useEffect(() => {
		// Important to update url with latest entry id, e.g. when navigating back after creating/updating a series entry where change triggers an aggregation rule recalculation
		if (dataEntryQuery.data && dataEntryQuery.data.entry.datasetentryid !== entryId) {
			navigate(
				ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.createPath({
					projectId,
					entryId: dataEntryQuery.data.entry.datasetentryid,
					seriesName
				}),
				{ replace: true }
			);
		}
	}, [dataEntryQuery.data]);

	const seriesFormItem = projectDataQuery.data?.formItems.find(
		formItem => formItem.type === 'series' && formItem.name === seriesName
	) as SeriesFormItem | undefined;

	const processedColumns = useSeriesTablesDataQuery({ projectId, entryId, seriesName });

	const processedRows = useProcessSeriesEntryBodyRows({
		entryId,
		projectId,
		seriesName
	});

	if (
		processedRows.loading ||
		processedColumns.isLoading ||
		projectDataQuery.isLoading ||
		variablesDataQuery.isLoading ||
		dataEntryQuery.isLoading
	) {
		return (
			<div className="md:px-20 pt-40 flex flex-col">
				<HeaderSkeleton />

				<div className="mx-auto w-full px-10 lg:p-0 lg:w-[1024px] flex flex-col">
					<Skeleton className="h-12 w-80 rounded-md self-end mb-4" />
					<TableSkeleton />
				</div>
			</div>
		);
	}

	if (!processedColumns.data || !processedRows.data || !seriesFormItem) {
		return (
			<ErrorLoadingEntry
				isFetching={processedColumns.isFetching}
				refetch={processedColumns.refetch}
				message="Error loading series details, please try again. Contact support if the problem persists."
			/>
		);
	}

	const filteredColumns =
		filteredVariables.size > 0
			? processedColumns.data.columns.filter(column => {
					if (column.type === 'group') {
						return column.group.variablesBelongingToGroup.some(variable =>
							filteredVariables.has(variable)
						);
					}

					return filteredVariables.has(column.variable.variableName);
			  })
			: processedColumns.data?.columns;

	const withFilteredGroups = filteredColumns.map(column => {
		if (column.type !== 'group') {
			return column;
		}

		return {
			...column,
			group: {
				...column.group,
				variablesBelongingToGroup:
					filteredVariables.size === 0
						? column.group.variablesBelongingToGroup
						: column.group.variablesBelongingToGroup.filter(variable =>
								filteredVariables.has(variable)
						  )
			}
		};
	});

	return (
		<div className="md:px-20 pt-40 flex flex-col">
			<Header seriesLabel={seriesFormItem.label} seriesName={seriesName} />

			<div className="flex flex-col mx-auto">
				<AggregationRulesDetails className="mb-10" />

				<SearchBar
					freeTextSearch={freeTextSearch}
					onFreeTextSearchChanged={setFreeTextSearch}
					seriesLabel={seriesFormItem?.label || 'Error loading series label'}
				/>

				<div className="mt-4 self-end">
					{processedColumns.data?.columns && processedColumns.data && (
						<ColumnFilterDropdown
							variables={processedColumns.data.variables}
							columns={processedColumns.data.columns}
							filteredVariables={filteredVariables}
							onFilteredVariablesChange={setFilteredVariables}
						/>
					)}
				</div>

				<div className="mt-10 lg:min-w-[1024px] max-w-[100vw] md:max-w-[calc(100vw-80px)] overflow-auto">
					<SeriesEntryBody
						projectId={projectId}
						entryId={entryId}
						seriesName={seriesName}
						columns={withFilteredGroups || []}
						rows={processedRows.data.filter(entry => {
							if (!freeTextSearch) {
								return true;
							}

							return createFilterByFreeText(freeTextSearch)(entry);
						})}
						variables={processedColumns.data.variables}
					/>
				</div>
			</div>
		</div>
	);
};

const SearchBar = ({
	freeTextSearch,
	onFreeTextSearchChanged,
	seriesLabel
}: {
	onFreeTextSearchChanged: (search: string) => void;
	freeTextSearch: string;
	seriesLabel: string;
}) => {
	return (
		<div className="flex self-end">
			<div className="rounded-md border border-gray-300 relative flex items-center px-4">
				<div>
					<Icon svg={Svgs.Search} propagate size={s => s.m} />
				</div>

				<input
					onChange={e => onFreeTextSearchChanged(e.target.value)}
					value={freeTextSearch}
					placeholder="Search entries"
					className="p-4 text-base"
				/>
			</div>

			<SettingsMenu seriesLabel={seriesLabel} />
		</div>
	);
};

const Header = ({ seriesLabel, seriesName }: { seriesLabel: string; seriesName: string }) => {
	const params = useParams();
	const projectId = params['projectId'] as string;
	const entryId = params['entryId'] as string;
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const formId = searchParams.get('formId');

	const getProjectQuery = useGetProjectQuery({ projectId });

	return (
		<EntryHeader
			breadCrumbs={[
				{
					title: getProjectQuery.data?.project.projectName,
					url: ROUTE_MAP.project.byId.dataset.createPath({
						projectId
					})
				},
				{
					title: 'Update Entry',
					url: ROUTE_MAP.project.byId.dataset.update.createPath({
						projectId,
						entryId,
						formId
					})
				}
			]}
			title={seriesLabel}
		>
			<div className="flex gap-4">
				<Link
					to={ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.create.createPath(
						{
							projectId,
							entryId,
							seriesName,
							formId
						}
					)}
				>
					<Button title="Create New +" />
				</Link>

				<IconButton onClick={() => navigate(-1)}>
					<CloseIcon className="text-icon-base" />
				</IconButton>
			</div>
		</EntryHeader>
	);
};

const SettingsMenu = ({ seriesLabel }: { seriesLabel: string }) => {
	const [showExportSeriesModal, setShowExportSeriesModal] = useState(false);

	return (
		<div className="relative">
			<Menu>
				<Menu.Button>
					<Icon svg={Svgs.More} propagate size={s => s.l} variant={v => v.button} />
				</Menu.Button>

				<Menu.Items className="absolute overflow-hidden top-14 right-0 w-48 bg-white rounded-lg z-50 shadow-normal flex flex-col items-stretch">
					<Menu.Item>
						<button
							className="hover:text-white hover:bg-primary-500 p-4 text-start text-base"
							type="button"
							onClick={() => setShowExportSeriesModal(true)}
						>
							Export series
						</button>
					</Menu.Item>
				</Menu.Items>
			</Menu>

			<ExportSeriesModal
				onClose={() => setShowExportSeriesModal(false)}
				visible={showExportSeriesModal}
				seriesLabel={seriesLabel}
			/>
		</div>
	);
};

export const useProcessSeriesEntryBodyRows = ({
	entryId,
	projectId,
	seriesName
}: {
	entryId: string;
	projectId: string;
	seriesName: string;
}) => {
	const getRepeatingDataSetRowsV2Query = useGetRepeatingSetRowsV2Query({
		entryId,
		projectId,
		setName: seriesName
	});

	const projectData = useProjectData({
		projectId,
		seriesName
	});

	const namesByUserIdQuery = useGetNamesFromUserIdsQuery(
		{
			userIds:
				getRepeatingDataSetRowsV2Query.data?.dataRows.map(row => row.enteredbyuser) || []
		},
		{
			enabled: !!getRepeatingDataSetRowsV2Query.data
		}
	);

	const allFileVariables = useMemo(() => {
		if (!projectData.data) {
			return [];
		}

		return Object.values(projectData.data.variables).filter(
			variable => variable.variableType === 'file'
		);
	}, [projectData.data]);

	const allFileIds = useMemo(() => {
		if (!getRepeatingDataSetRowsV2Query.data) {
			return [];
		}

		return getRepeatingDataSetRowsV2Query.data.dataRows.flatMap(row =>
			allFileVariables.map(variable => row[variable.variableName])
		);
	}, [getRepeatingDataSetRowsV2Query.data, allFileVariables]);

	const filesInfoQuery = useGetFilesInfoQuery(
		{
			entryId,
			projectId,
			fileIds: allFileIds
		},
		{
			enabled: !!getRepeatingDataSetRowsV2Query.data && allFileIds.length > 0
		}
	);

	const processedRows = useMemo(() => {
		if (
			projectData.isLoading ||
			namesByUserIdQuery.isLoading ||
			getRepeatingDataSetRowsV2Query.isLoading ||
			filesInfoQuery.isLoading
		) {
			return [];
		}

		return getRepeatingDataSetRowsV2Query.data?.dataRows.map((row): SeriesEntryBodyRow => {
			const formattedValues: Record<string, { label: string; value: BackendEntryValue }> = {};

			const customVariables = projectData.data?.variables.reduce((acc, variable) => {
				acc[variable.variableName] = variable;

				return acc;
			}, {} as Record<string, Variable>);
			const systemVariables = SYSTEM_VARIABLES_BY_VARIABLE_NAME;
			const allVariables = { ...customVariables, ...systemVariables };

			for (const [variableName, value] of Object.entries(row)) {
				const variable = allVariables[variableName];

				if (!variable) {
					if (!NON_VISUAL_SYSTEM_VARIABLES.includes(variableName)) {
						console.error(new Error('Variable not found'), {
							variableName
						});
					}

					continue;
				}

				if (isSystemVariable(variable)) {
					formattedValues[variableName] = {
						value,
						label: formatSystemVariableValue({
							variable: variable,
							value,
							namesByUserId: namesByUserIdQuery.data?.namesFromUserIds || {}
						})
					};
					continue;
				}

				if (variable.variableType === 'file') {
					const fileInfo = filesInfoQuery.data?.doc[value];

					const fileLabel = fileInfo?.metadata.fileLabel || 'Empty';

					formattedValues[variableName] = {
						value: fileLabel,
						label: fileLabel
					};
					continue;
				}

				formattedValues[variableName] = {
					value,
					label: formatVariableValue({
						variable,
						value
					})
				};
			}

			return {
				fieldsByVariableName: formattedValues,
				datasetentryid: row.datasetentryid
			};
		});
	}, [
		filesInfoQuery.data?.doc,
		filesInfoQuery.isLoading,
		getRepeatingDataSetRowsV2Query.data?.dataRows,
		getRepeatingDataSetRowsV2Query.isLoading,
		namesByUserIdQuery.data?.namesFromUserIds,
		namesByUserIdQuery.isLoading,
		projectData.data?.variables,
		projectData.isLoading
	]);

	return {
		loading:
			getRepeatingDataSetRowsV2Query.isLoading ||
			namesByUserIdQuery.isLoading ||
			filesInfoQuery.isLoading ||
			projectData.isLoading,
		error:
			getRepeatingDataSetRowsV2Query.error ||
			namesByUserIdQuery.error ||
			filesInfoQuery.error ||
			projectData.error,
		data: processedRows
	};
};

const NON_VISUAL_SYSTEM_VARIABLES = ['original_id', 'parentsetrevisionid', 'userProjectOrgId'];

const formatVariableValue = ({
	variable,
	value
}: {
	variable: Variable;
	value: BackendEntryValue;
}): string => {
	switch (variable.variableType) {
		case 'float':
		case 'integer':
		case 'string':
		case 'userDefinedUnique': {
			return value === null ? 'Empty' : value.toString();
		}

		case 'category': {
			if (isEmptyBackendEntryValue(value)) {
				return 'Empty';
			}

			if (typeof value !== 'string') {
				console.error(new Error('Category value is not a string'));
				return 'Empty';
			}

			if (isCategoryFixedVariable(variable)) {
				return variable.allowedCategories.find(c => c.value === value)?.label || value;
			}

			return value;
		}

		case 'categoryMultiple': {
			if (isEmptyBackendEntryValue(value)) {
				return 'Empty';
			}

			if (!Array.isArray(value)) {
				console.error(new Error('Category multiple value is not an array'));
				return 'Empty';
			}

			if (isCategoryMultipleFixedVariable(variable)) {
				return variable.allowedCategories
					.filter(c => value.includes(c.value))
					.map(c => c.label)
					.join(', ');
			}

			return value.join(', ');
		}

		case 'date': {
			if (!value) {
				return 'Empty';
			}

			if (typeof value !== 'string') {
				console.error(new Error('Date value is not a string'));
				return 'Empty';
			}

			return DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED);
		}

		case 'datetime': {
			if (isEmptyBackendEntryValue(value)) {
				return 'Empty';
			}

			if (typeof value !== 'string') {
				console.error(new Error('Datetime value is not a string'));
				return 'Empty';
			}

			return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED);
		}

		case 'file':
			console.error('File cannot be handled in formatVariableValue'); // Needs info from filesInfoQuery etc, handle it outside
			return 'Empty';

		case 'timeDuration': {
			if (isEmptyBackendEntryValue(value)) {
				return 'Empty';
			}

			if (typeof value !== 'number') {
				console.error(new Error('Time duration value is not a number'));
				return 'Empty';
			}

			return formatMicroseconds({
				microseconds: value,
				showUnitLabel: true,
				maxTimeUnit: variable.durationFormat.maxTimeUnit,
				minTimeUnit: variable.durationFormat.minTimeUnit
			});
		}
	}
};
