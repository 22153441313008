import { useCallback, useState } from 'react';
import * as yup from 'yup';
import { Colors } from 'environment';
import { PHONE_REGEX_WITH_PLUS_PREFIX } from 'consts';
import { InputType } from 'types/index';
import { Row, Column } from './SetupAccountInfo.style';
import { Title } from './styles';
import { Typography } from 'components/UI/Typography';
import { Input } from 'components/UI/Inputs/Input';
import { Spacer } from 'components/UI/Spacer';
import { CountrySelector } from 'components/UI/Interactables/CountrySelector';
import { RequiredFields } from 'components/UI/RequiredFields';
import { Button } from 'components/UI/Interactables/Button';
import { formatPhoneNumber } from 'helpers/auth';

import { useTranslation, useUpdateAccount } from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { useTracking } from 'app/tracking/TrackingProvider';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { Flex } from 'components/UI/Flex';

import './SetupAccountInfoForm.css';

interface UserInfoLogin {
	userFirstName: string;
	userSirName: string;
	phoneNumber?: string;
	position: string;
	department: string;
	workplace: string;
	city: string;
	country: string;
}

interface Props {
	account: UserInfoLogin;
}

export function SetupAccountInfoForm({ account }: Props) {
	const { translate } = useTranslation();

	const { track } = useTracking();

	const [{ loading: updatingLoginInfo }, updateAccount] = useUpdateAccount();

	const [tosAccepted, setTosAccepted] = useState(false);

	const validationSchema = yup.object({
		userFirstName: yup
			.string()
			.trim()
			.required(translate(dict => dict.loginSetWorkInfo.error)),
		userSirName: yup
			.string()
			.trim()
			.required(translate(dict => dict.loginSetWorkInfo.error)),
		phoneNumber: yup
			.string()
			.min(
				8,
				translate(({ accountUM }) => accountUM.validation.phoneNumberErrorValid)
			)
			.max(
				15,
				translate(({ accountUM }) => accountUM.validation.phoneNumberErrorValid)
			)
			.matches(
				PHONE_REGEX_WITH_PLUS_PREFIX,
				translate(({ accountUM }) => accountUM.validation.phoneNumberErrorValid)
			)
			.required(translate(({ accountUM }) => accountUM.validation.phoneNumberError)),
		workplace: yup
			.string()
			.trim()
			.required(translate(dict => dict.loginSetWorkInfo.error)),
		department: yup
			.string()
			.trim()
			.required(translate(dict => dict.loginSetWorkInfo.error))
	});

	const {
		Form,
		FormProvider,
		Controller,
		handleSubmit,
		register,
		handleTrimOnBlur,
		setValue,
		formProviderProps,
		control,
		errors,
		isDirty,
		getValues
	} = useReactForm({
		initialValues: account,
		enableReinitialize: true,
		validationSchema
	});

	const hasEmptyRequiredFields = useCallback(() => {
		const values = getValues();
		return (
			!values.userFirstName?.trim() ||
			!values.userSirName?.trim() ||
			!values.phoneNumber?.trim() ||
			!values.workplace?.trim() ||
			!values.department?.trim()
		);
	}, [getValues]);

	const handleFormSubmit = handleSubmit(data => {
		if (isDirty && !updatingLoginInfo) {
			updateAccount(data, '', true);
			track({
				eventName: 'signup_completed'
			});
		}
	});

	const handlePhoneNumber = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setValue('phoneNumber', formatPhoneNumber(e.target.value), {
				shouldDirty: true,
				shouldValidate: true
			});
		},
		[]
	);

	return (
		<FormProvider {...formProviderProps}>
			<Form onSubmit={handleFormSubmit}>
				<Title>{translate(dict => dict.loginSetWorkInfo.title)}</Title>

				<Typography.Caption uppercase>
					{translate(dict => dict.loginSetWorkInfo.personalSubtitle)}
				</Typography.Caption>
				<Spacer size={s => s.s} />

				<Row>
					<Column>
						<Input
							{...register('userFirstName', {
								onBlur: handleTrimOnBlur
							})}
							label={translate(dict => dict.loginForm.firstname)}
							type={InputType.Text}
							placeholder={translate(dict => dict.loginForm.firstname)}
							error={errors.userFirstName?.message}
							autoFocus
							required
						/>
					</Column>
					<Column>
						<Input
							{...register('userSirName', {
								onBlur: handleTrimOnBlur
							})}
							label={translate(dict => dict.loginForm.lastname)}
							type={InputType.Text}
							placeholder={translate(dict => dict.loginForm.lastname)}
							error={errors.userSirName?.message}
							required
						/>
					</Column>
				</Row>
				<Spacer size={s => s.m} />
				<Input
					{...register('phoneNumber', {
						onChange: handlePhoneNumber
					})}
					label={translate(dict => dict.newPasswordForm.phoneNumber)}
					type={InputType.Text}
					placeholder={translate(dict => dict.newPasswordForm.phoneNumber)}
					error={errors.phoneNumber?.message}
					required
				/>

				<Spacer size={s => s.l} />
				<Typography.Caption uppercase>
					{translate(dict => dict.loginSetWorkInfo.workSubtitle)}
				</Typography.Caption>
				<Spacer size={s => s.s} />

				<Input
					{...register('workplace', {
						onBlur: handleTrimOnBlur
					})}
					label={translate(dict => dict.accountUM.userDetails.workplace)}
					type={InputType.Text}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					error={errors.workplace?.message}
					required
				/>
				<Spacer size={s => s.m} />
				<Row>
					<Column>
						<Controller
							name="country"
							control={control}
							render={({ field: { value } }) => (
								<CountrySelector
									value={value}
									label={translate(dict => dict.accountUM.userDetails.country)}
									placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
									onSelect={country =>
										setValue('country', country?.ISO_2 ?? '', {
											shouldDirty: true,
											shouldValidate: true
										})
									}
								/>
							)}
						/>
					</Column>
					<Column>
						<Input
							{...register('city', {
								onBlur: handleTrimOnBlur
							})}
							label={translate(dict => dict.accountUM.userDetails.city)}
							type={InputType.Text}
							placeholder={translate(dict => dict.accountUM.userDetails.city)}
						/>
					</Column>
				</Row>
				<Spacer size={s => s.m} />
				<Input
					{...register('position', {
						onBlur: handleTrimOnBlur
					})}
					label={translate(dict => dict.loginForm.position)}
					type={InputType.Text}
					placeholder={translate(dict => dict.loginForm.position)}
				/>
				<Spacer size={s => s.m} />
				<Input
					{...register('department', {
						onBlur: handleTrimOnBlur
					})}
					label={translate(dict => dict.loginForm.department)}
					type={InputType.Text}
					placeholder={translate(dict => dict.loginForm.department)}
					error={errors.department?.message}
					required
				/>
				<Spacer size={s => s.m} />
				<Flex>
					<Checkbox
						className="tos-accept"
						checked={tosAccepted}
						onClick={() => setTosAccepted(!tosAccepted)}
					/>
					<Typography.Paragraph>
						{translate(dict => dict.loginForm.iAcceptTerms)}{' '}
						<a
							href="https://ledidi.com/trust-centre/terms-of-service"
							target="_blank"
							rel="noreferrer"
							style={{ color: Colors.primary.hover, textDecoration: 'underline' }}
						>
							{translate(dict => dict.loginForm.termsOfService)}
						</a>
						<span style={{ color: Colors.text.error }}>*</span>
					</Typography.Paragraph>
				</Flex>

				<Spacer size={s => s.m} />
				<RequiredFields />
				<Spacer size={s => s.m} />

				<Button
					type="submit"
					title={translate(({ buttons }) => buttons.finish)}
					loading={updatingLoginInfo}
					hasFullWidth
					hasPointer={!updatingLoginInfo}
					disabled={
						!tosAccepted || Object.keys(errors).length > 0 || hasEmptyRequiredFields()
					}
				/>

				<Spacer size={s => s.m} />

				<Typography.Paragraph color={Colors.text.error}>
					{translate(dict => dict.loginSetWorkInfo.hint)}
				</Typography.Paragraph>
			</Form>
		</FormProvider>
	);
}
