import clsx from 'clsx';
import { forwardRef, useEffect, useRef } from 'react';
import { InputError } from './InputError';
import { Label } from './Label';
import { mergeRefs } from '../utils/mergeRefs';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	label?: string;
	className?: string;
	id: string;
	disabled?: boolean;
	error?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
	({ label, className, id, disabled, value, onChange, error, ...props }, ref) => {
		const textareaRef = useRef<HTMLTextAreaElement>(null);

		const adjustHeight = () => {
			if (textareaRef.current) {
				textareaRef.current.style.height = '0px';
				const scrollHeight = textareaRef.current.scrollHeight;
				textareaRef.current.style.height = scrollHeight + 'px';
			}
		};

		// Adjust height on mount and when content changes
		useEffect(() => {
			adjustHeight();
		}, [value]);

		return (
			<div className={clsx('flex flex-col')}>
				{label && (
					<div className="flex items-center gap-1">
						<Label htmlFor={id} label={label} />
					</div>
				)}

				<textarea
					ref={mergeRefs(ref, textareaRef)}
					id={id}
					className="rounded-md border border-gray-400 p-3 pt-[12px] text-base resize-none no-scrollbar min-h-[72px]"
					disabled={disabled}
					value={value}
					onChange={e => {
						onChange?.(e);
					}}
					{...props}
				/>

				<InputError error={error} />
			</div>
		);
	}
);
