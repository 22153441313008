import { isEqual, values } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { CollaboratorsHeader } from 'components/Collaborators';
import { CollaboratorsGrid } from 'components/Collaborators/Grid';
import {
	AddToOrganizationModal,
	CollaboratorModal,
	DeleteBulkCollaboratorsDataModal,
	DeleteOrganizationModal,
	OrganizationModal,
	RemoveCollaboratorModal,
	RemoveFromOrganizationModal
} from 'components/Collaborators/Modals';
import { CollaboratorsTable } from 'components/Collaborators/Table';
import {
	Collaborator,
	CollaboratorsDataArray,
	CollaboratorsOrderItem,
	CollaboratorsViewOptions,
	Organization
} from 'store/data/collaborators';
import { BooleanMap, TableName } from 'types/index';
import { useCollaboratorsTableCheckedData } from './CollaboratorsPage.controller';
import { Grid } from 'components/UI/Grid';
import { Spacer } from 'components/UI/Spacer';
import { Suspend } from 'components/UI/Suspend';
import { Typography } from 'components/UI/Typography';
import {
	buildCollaboratorsRichData,
	collaboratorsDataArrayIterator,
	filterCollaboratorsDataArrayBySearchTerm,
	isCollaborator,
	isOrganizationData,
	orderCollaboratorsDataArray
} from 'helpers/collaborators';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useStatuses,
	useIsProjectOwner,
	useProjectOwnerDetails,
	useProjectId,
	useCollaboratorsSearchTerm,
	useAccount,
	useRoles,
	useCollaborators,
	useCollaboratorsViewOption,
	useTableSort,
	useRefetchCollaborators,
	usePermissions
} from 'hooks/store';
import { useMutableState, useKeyPress } from 'hooks/utils';
import { Flex } from 'components/UI/Flex';
import { PageWrapper } from './style';
import { Tag } from 'components/UI/Tags';
import { hasMatches } from 'helpers/strings';
import { CollaboratorsHistoryTable } from './CollaboratorsHistoryTable/CollaboratorsHistoryTable';
import { useFlags } from 'launchdarkly-react-client-sdk';

export enum FilterCollaborators {
	Collaborators = 'collaborators',
	Groups = 'groups'
}

export function CollaboratorsPage() {
	const { translate } = useTranslation();
	// Needs to be here for: setting all statuses so user can set permissions to each of them
	useStatuses();

	// Automatically refetch collaborators (eg when creating statuses)
	useRefetchCollaborators();

	const { navigate, routes } = useNavigation();

	const isProjectOwner = useIsProjectOwner();
	const projectOwnerDetails = useProjectOwnerDetails();

	const [projectId] = useProjectId();

	const { collaboratorHistory } = useFlags();

	const [searchTerm] = useCollaboratorsSearchTerm();

	const [
		{
			data: { username }
		}
	] = useAccount();

	const [
		{
			data: { rolesMap }
		}
	] = useRoles();

	const [
		{
			data: { collaboratorsMap, collaboratorsDataArray, organizationsMap },
			loading: loadingCollaborators,
			fetched: areCollaboratorsFetched
		}
	] = useCollaborators();

	const [viewOption] = useCollaboratorsViewOption();
	const [historyToggle, setHistoryToggle] = useState(false);

	const isTableView = viewOption === CollaboratorsViewOptions.TABLE;

	// FILTER
	const [filter, setFilter] = useState(FilterCollaborators.Collaborators);

	// SORT
	const {
		activeSort: activeSortColumn,
		handleSort,
		sortIcon
	} = useTableSort(TableName.Collaborators);

	const computedCollaboratorsDataArray = useMemo(() => {
		let draft: CollaboratorsDataArray = [];

		// BY SEARCH TERM
		draft = filterCollaboratorsDataArrayBySearchTerm(collaboratorsDataArray, searchTerm);

		if (filter === FilterCollaborators.Collaborators) {
			// const collaboratorsSet = new Set<Collaborator>();
			const collaboratorsMap: Record<string, Collaborator> = {};

			for (const item of draft) {
				if (isCollaborator(item)) {
					collaboratorsMap[item.userId] = item;
				} else {
					const keywords: string[] = [item.name];

					if (
						hasMatches({
							searchTerm,
							keywords
						})
					) {
						item.collaborators.forEach(coll => (collaboratorsMap[coll.userId] = coll));
					}
				}
			}

			draft = values(collaboratorsMap);
		} else {
			draft = draft.filter(item => !isCollaborator(item));
		}

		// SORT
		if (activeSortColumn) {
			draft = orderCollaboratorsDataArray(draft, activeSortColumn, {
				rolesMap
			});
		}

		// ADD USER_ROLE
		draft = draft.map(item => {
			if (isCollaborator(item)) {
				const collaborator = item as Collaborator;
				if (collaborator.projectRoleId) {
					collaborator.userRole = rolesMap[collaborator.projectRoleId]?.name;
				} else if ('userRole' in collaborator) {
					delete collaborator.userRole;
				}
				return collaborator;
			}
			return item;
		});

		return draft;
	}, [collaboratorsDataArray, searchTerm, activeSortColumn, filter, rolesMap]);

	// filtered rich data for checked data logic;
	const filteredRichData = useMemo(() => {
		const fitleredCollaboratorsMap = computedCollaboratorsDataArray.reduce((map, current) => {
			if (isCollaborator(current)) map[current.userId] = current;
			return map;
		}, {} as typeof collaboratorsMap);

		const fitleredOrganizationsMap = computedCollaboratorsDataArray.reduce((map, current) => {
			if (isOrganizationData(current)) {
				map[current.id] = {
					...current,
					collaborators: current.collaborators.map(s => s.userId)
				};
			}
			return map;
		}, {} as typeof organizationsMap);

		const filteredOrder: CollaboratorsOrderItem[] = computedCollaboratorsDataArray.map(item => {
			if (isOrganizationData(item))
				return { organization: item.id } as CollaboratorsOrderItem;
			return { collaborator: item.userId } as CollaboratorsOrderItem;
		});

		return buildCollaboratorsRichData({
			collaboratorsMap: fitleredCollaboratorsMap,
			order: filteredOrder,
			organizationsMap: fitleredOrganizationsMap
		});
	}, [computedCollaboratorsDataArray, collaboratorsMap, organizationsMap]);

	const { checkedMap, checkedState, resetChecked, toggleChecked, toggleAllChecked } =
		useCollaboratorsTableCheckedData({ collaboratorsData: filteredRichData });

	const [expandedRows, setExpandedRows] = useMutableState<BooleanMap>({});

	useKeyPress(
		{
			onEscapeKeyPress: () => {
				if (checkedState.inTotal > 0) resetChecked();
			},
			onDeleteKeyPress: () => {
				if (checkedState.inTotal > 0) handleDeleteSelected();
			}
		},
		{ noModalsOpened: true, listen: isTableView }
	);

	const [collaboratorModalState, setCollaboratorModalState] = useState<Collaborator | null>(null);
	const collaboratorModal = {
		collaborator: collaboratorModalState,
		open: (collaboratorId: string) =>
			collaboratorsMap[collaboratorId] &&
			setCollaboratorModalState(collaboratorsMap[collaboratorId]),
		close: (success?: boolean) => {
			if (success) resetChecked();
			setCollaboratorModalState(null);
		}
	};

	const [organizationModalState, setOrganizationModalState] = useMutableState<{
		visible: boolean;
		organization?: Organization;
	}>({
		visible: false
	});
	const organizationModal = {
		visible: organizationModalState.visible,
		organization: organizationModalState.organization,
		open: (organizationId?: string) => {
			setOrganizationModalState(state => {
				state.visible = true;

				if (organizationId && organizationId in organizationsMap) {
					state.organization = organizationsMap[organizationId];
				}
			});
		},
		close: () => setOrganizationModalState({ visible: false })
	};

	// SYNC `organizationModal`
	useEffect(() => {
		if (!organizationModal.organization) return;

		const exists = organizationModal.organization.id in organizationsMap;

		if (!exists) return organizationModal.close();

		const hasChanges = !isEqual(
			organizationModal.organization,
			organizationsMap[organizationModal.organization.id]
		);

		if (hasChanges) organizationModal.open(organizationModal.organization.id);
	}, [organizationsMap]);

	const [deleteOrganizationModalState, setDeleteOrganizationModalState] =
		useState<Organization | null>(null);
	const deleteOrganizationModal = {
		organization: deleteOrganizationModalState,
		open: (organizationId: string) =>
			organizationsMap[organizationId] &&
			setDeleteOrganizationModalState(organizationsMap[organizationId]),
		close: (success?: boolean) => {
			if (success) organizationModal.close();
			setDeleteOrganizationModalState(null);
		}
	};

	const [removeCollaboratorModalState, setRemoveCollaboratorModalState] =
		useState<Collaborator | null>(null);
	const removeCollaboratorModal = {
		collaborator: removeCollaboratorModalState,
		open: (collaboratorId: string) =>
			collaboratorsMap[collaboratorId] &&
			setRemoveCollaboratorModalState(collaboratorsMap[collaboratorId]),
		close: (success?: boolean) => {
			if (success) collaboratorModal.close();
			setRemoveCollaboratorModalState(null);
		}
	};

	const [addToOrganizationModalState, setAddToOrganizationModalState] = useState<{
		collaboratorIds: string[];
	} | null>(null);
	const addToOrganizationModal = {
		data: addToOrganizationModalState,
		open: (collaboratorIds: string[]) => setAddToOrganizationModalState({ collaboratorIds }),
		close: (success?: boolean) => {
			if (success) resetChecked();
			setAddToOrganizationModalState(null);
		}
	};

	const [removeFromOrganizationModalState, setRemoveFromOrganizationModalState] = useState<{
		collaboratorIds: string[];
		organization: Organization;
	} | null>(null);
	const removeFromOrganizationModal = {
		data: removeFromOrganizationModalState,
		open: (input: { organizationId: string; collaboratorIds: string[] }) => {
			const { organizationId, collaboratorIds } = input;

			const organization = organizationsMap[organizationId];

			if (organization) {
				setRemoveFromOrganizationModalState({
					organization,
					collaboratorIds
				});
			}
		},
		close: (success?: boolean) => {
			if (success) resetChecked();
			setRemoveFromOrganizationModalState(null);
		}
	};

	const [deleteBulkModalVisible, setDeleteBulkModalVisible] = useState(false);
	const deleteBulkModal = {
		visible: deleteBulkModalVisible,
		open: () => setDeleteBulkModalVisible(true),
		close: () => setDeleteBulkModalVisible(false)
	};

	const filteredCount = useMemo(() => {
		let collaborators = 0;
		let organizations = 0;

		collaboratorsDataArrayIterator(
			computedCollaboratorsDataArray,
			// COLLABORATOR - OMIT
			() => collaborators++,
			// ORGANIZATION
			organization => {
				organizations++;

				organization.collaborators.forEach(() => collaborators++);
			}
		);

		return { collaborators, organizations };
	}, [computedCollaboratorsDataArray]);

	function handleDeleteSelected() {
		if (checkedState.isSelf || checkedState.isDefaultOrganization) return;

		// 1 COLLABORATOR SELECTED
		if (checkedState.one.collaborator) {
			const collaboratorId = checkedState.checked.collaborators[0];

			return removeCollaboratorModal.open(collaboratorId);
		}

		// 1 ORGANIZATION SELECTED
		if (checkedState.one.organization) {
			const organizationId = checkedState.checked.organizations[0];

			return deleteOrganizationModal.open(organizationId);
		}

		// 2 OR MORE SELECTED - TRIGGER DELETE BULK MODAL
		deleteBulkModal.open();
	}

	function toggleExpandedRow(rowName: string) {
		setExpandedRows(state => {
			state[rowName] = !(state[rowName] ?? true);
		});
	}

	function handleInviteCollaborator() {
		if (projectId) navigate(routes.projects.collaborators.invite(projectId));
	}

	function isSelf(collaboratorId: string) {
		return collaboratorId === username;
	}

	function isProjectOwnerById(collaboratorId: string) {
		return projectOwnerDetails?.userId === collaboratorId;
	}

	function getCount() {
		const hasCollaborators = filteredCount.collaborators > 0;
		const hasOrganizations = filteredCount.organizations > 0;
		const hasBoth = hasCollaborators && hasOrganizations;
		const hasNone = !hasCollaborators && !hasOrganizations;

		if (hasNone) return translate(dict => dict.collaborators.collaboratorsPage.noResults);

		const collaboratorsCount = hasCollaborators
			? `${filteredCount.collaborators} ${translate(
					dict => dict.collaborators.collaboratorsPage.collaborator
			  )}${getPlural(
					filteredCount.collaborators,
					translate(dict => dict.collaborators.collaboratorsPage.plural)
			  )}`
			: '';
		const organizationsCount = hasOrganizations
			? `${filteredCount.organizations} ${translate(
					dict => dict.collaborators.collaboratorsPage.group
			  )}${getPlural(
					filteredCount.organizations,
					translate(dict => dict.collaborators.collaboratorsPage.plural)
			  )}`
			: '';

		const and = hasBoth ? translate(dict => dict.collaborators.collaboratorsPage.and) : '';

		return `${translate(
			dict => dict.collaborators.collaboratorsPage.showing
		)} ${organizationsCount} ${and} ${collaboratorsCount}`.trim();
	}

	function getPlural(count: number, pluralMessage: string) {
		return count === 1 ? '' : pluralMessage;
	}

	function getRoleNameById(roleId?: string) {
		if (roleId && rolesMap[roleId]) return rolesMap[roleId].name;

		return '-';
	}

	const suspendProps = {
		loading: loadingCollaborators && !areCollaboratorsFetched,
		immediate: !areCollaboratorsFetched
	};

	const showHistory = collaboratorHistory && historyToggle;

	const { canAddCollaborator } = usePermissions(projectId ? { projectId } : undefined);

	return (
		<PageWrapper>
			<CollaboratorsHeader
				onEditCollaborator={collaboratorModal.open}
				isDataFetched={!!areCollaboratorsFetched}
				checkedState={checkedState}
				onInviteCollaborator={handleInviteCollaborator}
				onCreateOrganization={organizationModal.open}
				onDeleteSelected={handleDeleteSelected}
				onMoveSelected={() =>
					addToOrganizationModal.open(checkedState.checked.collaborators)
				}
				setShowHistory={(value: boolean) => setHistoryToggle(value)}
				showHistory={showHistory}
				isHistoryFeatureFlagEnabled={collaboratorHistory}
			/>

			<Suspend loading={suspendProps.loading} immediate={suspendProps.immediate}>
				<Grid.Container>
					{!showHistory && (
						<>
							<Flex marginOffset={{ bottom: 1.6 }}>
								<Tag
									onClick={() => setFilter(FilterCollaborators.Collaborators)}
									title={translate(
										dict => dict.collaborators.collaboratorsTable.collaborators
									)}
									active={filter === FilterCollaborators.Collaborators}
									marginOffset={{ right: 1.6 }}
								/>
								<Tag
									dataTestid="groups-tag"
									onClick={() => setFilter(FilterCollaborators.Groups)}
									title={translate(
										dict => dict.collaborators.collaboratorsTable.groups
									)}
									active={filter === FilterCollaborators.Groups}
								/>
							</Flex>
							<Flex justify={j => j.start} align={a => a.center}>
								<Typography.Caption>{getCount()}</Typography.Caption>
							</Flex>

							<Spacer size={s => s.xs} />
						</>
					)}

					{/* TABLE VIEW */}
					{!showHistory && viewOption === CollaboratorsViewOptions.TABLE && (
						<CollaboratorsTable
							filter={filter}
							collaboratorsDataArray={computedCollaboratorsDataArray}
							columnsData={{
								sort: {
									handleSort,
									sortIcon
								}
							}}
							expandedRowsData={{
								expandedRows,
								toggleExpandedRow
							}}
							checkedRowsData={{
								checkedMap,
								checkedState,
								toggleChecked,
								toggleAllChecked
							}}
							collaboratorRowData={{
								isSelf,
								isProjectOwner: isProjectOwnerById,
								getRoleName: getRoleNameById
							}}
							canSelectRows={
								isProjectOwner && filter === FilterCollaborators.Collaborators
							}
							onCollaboratorClick={collaboratorModal.open}
							onOrganizationClick={organizationModal.open}
							onRemoveOrganization={deleteOrganizationModal.open}
							onRemoveCollaboratorFromOrganization={removeFromOrganizationModal.open}
							onRemoveCollaborator={removeCollaboratorModal.open}
						/>
					)}
					{/* GRID VIEW */}
					{!showHistory && viewOption === CollaboratorsViewOptions.GRID && (
						<CollaboratorsGrid
							collaboratorsDataArray={computedCollaboratorsDataArray}
							collaboratorCardData={{
								isSelf,
								isProjectOwner: isProjectOwnerById,
								clickable: isProjectOwner
							}}
							onCollaboratorClick={collaboratorModal.open}
							onOrganizationClick={organizationModal.open}
						/>
					)}

					{showHistory && <CollaboratorsHistoryTable />}
				</Grid.Container>
			</Suspend>

			{/* MODALS */}
			{collaboratorModal.collaborator && (
				<CollaboratorModal
					writeAccess={isProjectOwner || !!canAddCollaborator}
					collaborator={collaboratorModal.collaborator}
					removeModalVisible={!!removeCollaboratorModal.collaborator}
					onRemove={removeCollaboratorModal.open}
					onRemoveCollaborator={({ organizationId, collaboratorId }) =>
						removeFromOrganizationModal.open({
							organizationId,
							collaboratorIds: [collaboratorId]
						})
					}
					onClose={collaboratorModal.close}
					projectId={projectId ?? undefined}
				/>
			)}
			{organizationModal.visible && (
				<OrganizationModal
					writeAccess={isProjectOwner || !!canAddCollaborator}
					organization={organizationModal.organization}
					removeModalVisible={!!deleteOrganizationModal.organization}
					onDelete={deleteOrganizationModal.open}
					onRemoveCollaborator={({ organizationId, collaboratorId }) =>
						removeFromOrganizationModal.open({
							organizationId,
							collaboratorIds: [collaboratorId]
						})
					}
					onClose={organizationModal.close}
				/>
			)}
			{deleteOrganizationModal.organization && (
				<DeleteOrganizationModal
					organization={deleteOrganizationModal.organization}
					onClose={deleteOrganizationModal.close}
				/>
			)}
			{removeCollaboratorModal.collaborator && (
				<RemoveCollaboratorModal
					collaborator={removeCollaboratorModal.collaborator}
					onClose={removeCollaboratorModal.close}
				/>
			)}
			{addToOrganizationModal.data && (
				<AddToOrganizationModal
					collaboratorIds={addToOrganizationModal.data.collaboratorIds}
					onClose={addToOrganizationModal.close}
				/>
			)}
			{removeFromOrganizationModal.data && (
				<RemoveFromOrganizationModal
					organization={removeFromOrganizationModal.data.organization}
					collaboratorIds={removeFromOrganizationModal.data.collaboratorIds}
					onClose={removeFromOrganizationModal.close}
				/>
			)}
			{deleteBulkModal.visible && (
				<DeleteBulkCollaboratorsDataModal
					organizationIds={checkedState.checked.organizations}
					collaboratorIds={checkedState.checked.collaborators}
					onClose={deleteBulkModal.close}
				/>
			)}
		</PageWrapper>
	);
}
