import { Fragment, useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { cloneDeep, isEqual } from 'lodash';
import { ModulesContainer, FormSearchVariables } from 'components/Forms';
import { VariablesDataArray } from 'store/data/variables';
import { DragAndDropTypes } from 'types/index';
import { TextRegular, VariablesContainer } from './DraggableFormVariablesAndGroups.style';
import { DraggableFormVariableCard } from './DraggableFormVariableCard';
import { DraggableFormGroupCard } from './DraggableFormGroupCard';
import { DraggableFormVariableSetCard } from './DraggableFormVariableSetCard';
import { Flex } from 'components/UI/Flex';
import { Pagination } from 'components/UI/Pagination';
import { Spacer } from 'components/UI/Spacer';
import {
	buildVariableSetVariablesData,
	buildVariablesRichData,
	isGroupData,
	isVariableSetData,
	filterVariablesDataArrayBySearchTerm,
	variablesDataArrayJSXIterator
} from 'helpers/variables';
import {
	useTranslation,
	useVariables,
	useVariablesData,
	useFormSearchTerm,
	useForm
} from 'hooks/store';
import { usePaginate } from 'hooks/utils';

interface DesignerProps {
	writeAccess: boolean;
}

export function DraggableFormVariablesAndGroups({ writeAccess }: DesignerProps) {
	const { translate } = useTranslation();

	const [
		{
			data: { variablesDataArray, groupsMap, variableSetsMap }
		}
	] = useVariables({
		lazy: true,
		initial: true
	});

	const variablesData = useVariablesData({ initial: true });

	const [{ data: form }] = useForm({ lazy: true });

	const usedFormVariables = useMemo(() => form?.usedVariables ?? [], [form]);
	const usedFormGroups = useMemo(() => form?.usedGroups ?? [], [form]);
	const usedFormSets = useMemo(() => form?.usedSets ?? [], [form]);

	const [searchTerm] = useFormSearchTerm();

	const filteredVariablesDataArray = useMemo(() => {
		let computedVariablesDataArray = variablesDataArray;

		if (form?.setName && form.setName in variableSetsMap) {
			const variableSetVariablesData = buildVariableSetVariablesData({
				setName: form.setName,
				variablesData
			});

			const { variablesDataArray: variableSetVariablesDataArray } =
				buildVariablesRichData(variableSetVariablesData);

			computedVariablesDataArray = variableSetVariablesDataArray;
		}

		let filtered: VariablesDataArray = cloneDeep(computedVariablesDataArray);

		// FILTER USED VARIABLES AND GROUPS
		filtered = filtered.filter(item => {
			// GROUP DATA
			if (isGroupData(item)) {
				const group = item;

				// FILTER USED GROUP VARIABLES
				group.groupVariables = group.groupVariables.filter(
					variable => !usedFormVariables.includes(variable.name)
				);

				const isGroupUsed = usedFormGroups.includes(group.groupName);

				if (isGroupUsed) return false;

				return true;
			}

			// VARIABLE SET DATA
			if (isVariableSetData(item)) {
				const variableSet = item;

				const isVariableSetUsed = usedFormSets.includes(variableSet.setName);

				if (isVariableSetUsed) return false;

				return true;
			}

			// VARIABLE
			const variable = item;

			const isVariableUsed = usedFormVariables.includes(variable.name);

			if (isVariableUsed) return false;

			return true;
		});

		// APPLY SEARCH TERM FILTER
		filtered = filterVariablesDataArrayBySearchTerm(filtered, searchTerm, {
			translate
		});

		return filtered;
	}, [form, variablesDataArray, usedFormVariables, usedFormGroups, usedFormSets, searchTerm]);

	const { pageIndex, pageSize, pagesCount, shouldPaginate, page, changePage, resetPage } =
		usePaginate(filteredVariablesDataArray, {
			threshold: 20,
			pageSize: 10
		});

	function isGroupBroken(groupName: string): boolean {
		const group = groupsMap[groupName];

		// FILTER USED GROUP VARIABLES
		const filteredUsedGroupVariables = group.variablesBelongingToGroup.filter(
			variableName => !usedFormVariables.includes(variableName)
		);

		const groupedVariablesChanged = !isEqual(
			group.variablesBelongingToGroup,
			filteredUsedGroupVariables
		);

		return groupedVariablesChanged;
	}

	return (
		<Droppable droppableId={DragAndDropTypes.DroppableFormVariable} isDropDisabled>
			{provided => (
				<ModulesContainer ref={provided.innerRef}>
					<VariablesContainer>
						<FormSearchVariables onChangeCallback={resetPage} />
						<Spacer size={s => s.m} />

						{shouldPaginate && (
							<Flex marginOffset={{ bottom: 0.8 }}>
								<Pagination
									pageIndex={pageIndex}
									pageSize={pageSize}
									pagesCount={pagesCount}
									changePage={changePage}
									openPageInputOnRight
									simpleVersion
								/>
							</Flex>
						)}

						{variablesDataArrayJSXIterator(
							page,
							/**
							 * VARIABLE
							 */
							(variable, index) => (
								<Fragment key={`variable-${variable.name}`}>
									<Draggable
										key={variable.name}
										draggableId={variable.name}
										index={index}
										isDragDisabled={!writeAccess}
									>
										{provided => (
											<DraggableFormVariableCard
												provided={provided}
												variable={variable}
												canAppend={writeAccess}
											/>
										)}
									</Draggable>
								</Fragment>
							),
							/**
							 * GROUP DATA
							 */
							(groupData, index) => (
								<Fragment key={`group-${groupData.groupName}`}>
									<Draggable
										key={`${DragAndDropTypes.DraggableFormGroup}${groupData.groupName}`}
										draggableId={`${DragAndDropTypes.DraggableFormGroup}${groupData.groupName}`}
										index={index}
										isDragDisabled={
											!writeAccess || isGroupBroken(groupData.groupName)
										}
									>
										{provided => (
											<DraggableFormGroupCard
												key={groupData.groupName}
												groupData={groupData}
												canAppend={writeAccess}
												groupBroken={isGroupBroken(groupData.groupName)}
												provided={provided}
											/>
										)}
									</Draggable>
								</Fragment>
							),
							/**
							 * VARIABLE SET DATA
							 */
							(variableSetData, index) => (
								<Fragment key={`variable_set-${variableSetData.setName}`}>
									<Draggable
										key={`${DragAndDropTypes.DraggableFormSet}${variableSetData.setName}`}
										draggableId={`${DragAndDropTypes.DraggableFormSet}${variableSetData.setName}`}
										index={index}
										isDragDisabled={!writeAccess}
									>
										{provided => (
											<DraggableFormVariableSetCard
												provided={provided}
												variableSetData={variableSetData}
												canAppend={writeAccess}
											/>
										)}
									</Draggable>
								</Fragment>
							)
						)}

						{/* NO RESULTS */}
						{filteredVariablesDataArray.length === 0 && (
							<TextRegular>
								{translate(({ formDesigner }) => formDesigner.noVariablesInList)}
							</TextRegular>
						)}
					</VariablesContainer>
					{provided.placeholder}
				</ModulesContainer>
			)}
		</Droppable>
	);
}
