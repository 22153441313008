import { useEffect, useMemo, useState } from 'react';
import { nanoid as generate } from 'nanoid';

import { Modal } from 'components/UI/Modal';
import { TagInput } from 'components/UI/TagInput';
import { Typography } from 'components/UI/Typography';
import { Spacer } from 'components/UI/Spacer';
import { EMAIL_REGEX, keyMaps } from 'consts';
import { useTranslation, useUsersByEmails } from 'hooks/store';
import { useCompletedAction, useDispatch } from 'hooks/utils';
import { setError } from 'store/ui/activities';

import type { CopyProjectNewOwner } from '../CopyProjectModal/CopyProjectModal';
import { UserCard } from 'components/UI/UserCard';
import { DarkBgContainer } from './ChangeOwnerModal.style';

interface Props {
	projectId: string;
	currentOwnerEmail: string;
	onClose: (newOwner?: CopyProjectNewOwner) => void;
}

enum ChangeOwnerSteps {
	Invite = 'Invite',
	Confirm = 'Confirm'
}

export function ChangeOwnerModal({ projectId, currentOwnerEmail, onClose }: Props) {
	const { translate } = useTranslation();
	const dispatch = useDispatch();

	const [newOwnerEmailAddress, setNewOwnerEmailAddress] = useState<string>();
	const [confirmedOwner, setConfirmedOwner] = useState(false);

	const isCorrectInputEmail = useMemo(
		() => newOwnerEmailAddress && newOwnerEmailAddress.match(EMAIL_REGEX),
		[newOwnerEmailAddress]
	);

	const [
		{
			data: { newUsersData },
			loading: gettingUsersByEmails,
			error: errorGettingUsersByEmails
		},
		getUsersByEmails
	] = useUsersByEmails(isCorrectInputEmail && newOwnerEmailAddress ? [newOwnerEmailAddress] : []);

	const [goToConfirm, setGoToConfirm] = useState(false);

	useCompletedAction(gettingUsersByEmails, errorGettingUsersByEmails, () => {
		setGoToConfirm(!!isNewUserFetched);
	});

	const [step, setStep] = useState(ChangeOwnerSteps.Invite);

	const isSameEmailAddress = useMemo(
		() => currentOwnerEmail === newOwnerEmailAddress,
		[newOwnerEmailAddress, currentOwnerEmail]
	);
	const isNewUserFetched = useMemo(
		() =>
			newUsersData &&
			newUsersData.filter(user => user.emailAddress === newOwnerEmailAddress).length,
		[newUsersData, newOwnerEmailAddress]
	);

	function handlePrimaryButtonAction() {
		if (step === ChangeOwnerSteps.Invite) {
			if (newOwnerEmailAddress) {
				if (isSameEmailAddress) {
					dispatch(
						setError({
							type: null,
							error: translate(
								({ copyProject }) => copyProject.changeOwner.sameOwnerError,
								false,
								{ user: currentOwnerEmail }
							),
							uuid: generate(),
							toast: {
								display: true,
								message: translate(
									({ copyProject }) => copyProject.changeOwner.sameOwnerError,
									false,
									{ user: currentOwnerEmail }
								)
							}
						})
					);
				} else if (isNewUserFetched) {
					setGoToConfirm(true);
				} else getUsersByEmails([newOwnerEmailAddress], projectId);
			}
		}
		if (step === ChangeOwnerSteps.Confirm) {
			if (newUsersData) {
				const user = newUsersData[0];
				onClose({
					emailAddress: user.emailAddress,
					userFirstName: user.userFirstName,
					userSirName: user.userSirName,
					userId: user.userid ?? ''
				});
			}
		}
	}

	useEffect(() => {
		if (goToConfirm) setStep(ChangeOwnerSteps.Confirm);
		else setStep(ChangeOwnerSteps.Invite);
	}, [goToConfirm]);

	useCompletedAction(
		gettingUsersByEmails,
		errorGettingUsersByEmails,
		// SUCCESS CALLBACK
		() => {
			if (
				newUsersData &&
				!newUsersData.filter(user => user.emailAddress === newOwnerEmailAddress).length
			)
				dispatch(
					setError({
						type: null,
						error: translate(
							({ copyProject }) => copyProject.changeOwner.noUserFoundError
						),
						uuid: generate(),
						toast: {
							display: true,
							message: translate(
								({ copyProject }) => copyProject.changeOwner.noUserFoundError
							)
						}
					})
				);
		}
	);

	const primaryButtonDisabled =
		!isCorrectInputEmail || (step === ChangeOwnerSteps.Confirm && !confirmedOwner);

	const primaryButtonLoading = gettingUsersByEmails;
	const primaryButtonLabel =
		ChangeOwnerSteps.Invite === step
			? translate(({ buttons }) => buttons.continue)
			: translate(({ buttons }) => buttons.confirm);

	return (
		<Modal
			size={s => s.m}
			title={translate(dict => dict.copyProject.changeOwner.changeOwnerModalTitle)}
			primary={{
				label: primaryButtonLabel,
				loading: primaryButtonLoading,
				warning: true,
				disabled: primaryButtonDisabled,
				onClick: handlePrimaryButtonAction
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<TagInput
				label={translate(({ copyProject }) => copyProject.changeOwner.addOwnerToProject)}
				items={newOwnerEmailAddress ? [newOwnerEmailAddress] : []}
				placeholder={
					!newOwnerEmailAddress
						? translate(({ copyProject }) => copyProject.changeOwner.typeOneMail)
						: ''
				}
				error={
					newOwnerEmailAddress &&
					!isCorrectInputEmail &&
					translate(({ collaborators }) => collaborators.errors.emailInputError)
				}
				delimiters={[keyMaps.comma]}
				onChange={values => setNewOwnerEmailAddress(!values.length ? '' : values[0])}
				toLowerCase
				onSubmit={
					!primaryButtonDisabled && !primaryButtonLoading
						? handlePrimaryButtonAction
						: undefined
				}
				autoFocus
				allowSingleValue
			/>

			<Spacer size={s => s.m} />

			{step === ChangeOwnerSteps.Confirm && (
				<>
					<Typography.Paragraph fontweight={f => f.semiBold}>
						{translate(({ copyProject }) => copyProject.changeOwner.confirmToProject)}
					</Typography.Paragraph>
					<Spacer size={s => s.xs} />
					{newUsersData && newUsersData.length === 1 && (
						<DarkBgContainer>
							<UserCard.DropdownItem
								key={newUsersData[0].userid}
								userData={newUsersData[0]}
								checked={confirmedOwner}
								onCheck={() => setConfirmedOwner(prev => !prev)}
							/>
						</DarkBgContainer>
					)}
				</>
			)}
		</Modal>
	);
}
