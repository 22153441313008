import { useParams } from 'react-router-dom';
import { useGetStatusesQuery } from '../data/statuses/useGetStatusesQuery';
import { useGetLatestDataEntryVersionQuery } from '../data/useGetLatestDataEntryVersionQuery';
import clsx from 'clsx';
import { Dialog } from '../component/Dialog';
import { useState } from 'react';
import { SelectEntryStatus } from './SelectEntryStatus';
import { StatusDefinition } from '../types';
import { Button } from '../component/Button';
import {
	useDeleteEntryStatusMutation,
	useUpdateEntryStatusMutation
} from '../data/statuses/useUpdateEntryStatusMutation';

export const SelectStatusButton = () => {
	const params = useParams();
	const projectId = params.projectId as string;
	const entryId = params.entryId as string;

	const [showStatusDialog, setShowStatusDialog] = useState(false);

	const getStatusesQuery = useGetStatusesQuery({ projectId });
	const dataEntryQuery = useGetLatestDataEntryVersionQuery({
		entryId,
		projectId
	});

	const activeStatusByStatusName = Object.entries(dataEntryQuery.data?.statuses || {}).find(
		([_statusName, status]) => status.value === true
	);
	const activeStatusName = activeStatusByStatusName?.[0];
	const activeStatus = activeStatusByStatusName?.[1];

	const activeStatusDefinition = getStatusesQuery.data?.statuses.find(
		status => status.statusName === activeStatusByStatusName?.[0]
	);

	const statusesActive = getStatusesQuery.data && getStatusesQuery.data.statuses.length > 0;
	if (dataEntryQuery.isLoading || !statusesActive) {
		return null;
	}

	return (
		<>
			<button
				onClick={() => setShowStatusDialog(true)}
				className="rounded-xl border border-gray-700 p-4 flex gap-2 items-center hover:bg-gray-500/20"
				type="button"
			>
				<span className="text-sm font-semibold">
					{activeStatusDefinition?.statusLabel || 'Change status'}
				</span>

				{activeStatusDefinition && (
					<div
						className={clsx(
							'h-8 aspect-square rounded-full',
							!activeStatusDefinition &&
								'bg-gray-200 border-2 border-dashed border-gray-300'
						)}
						style={{
							background: activeStatusDefinition?.statusColor
						}}
					/>
				)}
			</button>

			<SelectStatusDialog
				open={showStatusDialog}
				onClose={() => setShowStatusDialog(false)}
				initialStatus={{
					name: activeStatusName,
					comment: activeStatus?.comment,
					dueDate: activeStatus?.dueTimeStamp
				}}
				key={activeStatus ? JSON.stringify(activeStatus) : null}
				onSubmit={async () => {
					await dataEntryQuery.refetch();
				}}
			/>
		</>
	);
};

const SelectStatusDialog = ({
	open,
	onClose,
	initialStatus,
	onSubmit
}: {
	open: boolean;
	onClose: () => void;
	initialStatus: {
		name: string | undefined;
		comment: string | undefined;
		dueDate: string | undefined;
	};
	onSubmit: () => Promise<void>;
}) => {
	const params = useParams();
	const projectId = params.projectId as string;
	const entryId = params.entryId as string;

	const [comment, setComment] = useState<string>(initialStatus.comment || '');
	const [statusName, setStatusName] = useState<string | undefined>(initialStatus.name);
	const [dueDate, setDueDate] = useState<Date | undefined>(
		initialStatus.dueDate ? new Date(initialStatus.dueDate) : undefined
	);

	const getStatusesQuery = useGetStatusesQuery({ projectId });
	const activeStatusDefinition = getStatusesQuery.data?.statuses.find(
		status => status.statusName === statusName
	);

	const closeAndResetForm = () => {
		if (initialStatus) {
			setComment(initialStatus.comment || '');
			setStatusName(initialStatus.name);

			if (initialStatus.dueDate) {
				setDueDate(new Date(initialStatus.dueDate));
			}
		} else {
			setComment('');
			setStatusName(undefined);
			setDueDate(undefined);
		}

		onClose();
	};

	const updateEntryStatusMutation = useUpdateEntryStatusMutation();
	const deleteEntryStatusMutation = useDeleteEntryStatusMutation();

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const submit = async () => {
		try {
			setError(undefined);
			setSubmitting(true);

			if (statusName === undefined) {
				if (!initialStatus.name) {
					return closeAndResetForm();
				}

				await deleteEntryStatusMutation.mutateAsync({
					projectId,
					entryId,
					statusName: initialStatus.name
				});
			} else {
				await updateEntryStatusMutation.mutateAsync({
					projectId,
					entryId,
					oldStatusName: initialStatus.name,
					newStatusName: statusName,
					comment
				});
			}
			await onSubmit();
			closeAndResetForm();
		} catch (error) {
			console.error(error);

			setError(
				'Could not update status, please try again. Contact support if the problem persists.'
			);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Dialog title="Update Entry Status" onClose={closeAndResetForm} open={open}>
			<form
				className="w-[600px]"
				onSubmit={e => {
					e.preventDefault();

					// Need to stop propagation because react does not do this even if it is rendered in a portal, which means not stopping propagation will cause the EntryForm to be submitted as well
					e.stopPropagation();

					submit();
				}}
			>
				<SelectEntryStatus
					statusCommentInitiallyExpanded
					comment={comment}
					onCommentChanged={setComment}
					selectedStatusName={statusName}
					onStatusNameChanged={statusName => {
						setStatusName(statusName);

						const status = getStatusesQuery.data?.statuses.find(
							status => status.statusName === statusName
						);

						if (status) {
							setDueDate(computeStatusDueDate(status));
						} else {
							setDueDate(undefined);
							setComment('');
						}
					}}
					dueDate={dueDate}
					statusColor={activeStatusDefinition?.statusColor}
				/>

				{error && (
					<p className="text-red-500 font-semibold text-sm text-center mt-6">{error}</p>
				)}

				<div className="flex gap-2 mt-12 flex-row-reverse">
					<Button title="Save changes" loading={submitting} />

					<Button
						title="Cancel"
						variant="secondary"
						type="button"
						onClick={closeAndResetForm}
					/>
				</div>
			</form>
		</Dialog>
	);
};

export const computeStatusDueDate = (status: StatusDefinition) => {
	const now = new Date();

	if (status.dueTimeAmount && status.dueTimeUnit) {
		const dueDate = new Date(now);

		if (status.dueTimeUnit === 'weeks') {
			dueDate.setDate(dueDate.getDate() + status.dueTimeAmount * 7);
		} else if (status.dueTimeUnit === 'days') {
			dueDate.setDate(dueDate.getDate() + status.dueTimeAmount);
		} else if (status.dueTimeUnit === 'hours') {
			dueDate.setHours(dueDate.getHours() + status.dueTimeAmount);
		}

		return dueDate;
	}

	return undefined;
};
