import { Link, useNavigate, useParams } from 'react-router-dom';
import { EntryHeader } from '../../component/EntryHeader';
import { useGetProjectQuery } from '../../data/useGetProjectQuery';
import { ROUTE_MAP } from '../../utils/routeMap';

import { createSubmitEvent, EntryForm, NextAction } from 'features/entry-form-v2/EntryForm';
import { Button } from 'features/entry-form-v2/component/Button';
import { Entry } from 'features/entry-form-v2/types';
import { StrictMode, useEffect, useRef, useState } from 'react';
import { ErrorModal } from 'features/entry-form-v2/ErrorModal';
import { track, useTracking } from 'app/tracking/TrackingProvider';
import { Menu } from '@headlessui/react';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import {
	getLatestEntryVersion,
	useGetLatestDataEntryVersionQuery
} from 'features/entry-form-v2/data/useGetLatestDataEntryVersionQuery';
import { useUpdateDataEntryMutation } from 'features/entry-form-v2/data/useUpdateDataEntryMutation';
import {
	isSeriesFormItem,
	useProjectData
} from 'features/entry-form-v2/data/useProjectData/useProjectData';
import {
	DataPendingSubmission,
	EntryFormSkeleton,
	ErrorLoadingEntry,
	OptionsMenuButton,
	SubmitDiffModal
} from 'features/entry-form-v2/update-entry/UpdateEntryPageV1_5';
import { SelectForm, useFormSelector } from 'features/entry-form-v2/smart-components/SelectForm';
import { useGetFormsQuery } from 'features/entry-form-v2/data/useGetFormsQuery/useGetFormsQuery';
import { EntryDetailsModal } from 'features/entry-form-v2/smart-components/EntryDetailsModal';
import { CloseIcon, MoreIcon } from '@icons';
import { SplitButton } from 'features/entry-form-v2/component/SplitButton';
import { AggregationRulesDetails } from 'features/entry-form-v2/smart-components/AggregationRulesDetails';
import { SeriesEntryNavigation } from 'features/entry-form-v2/smart-components/series-navigation/SeriesNavigation';
import { useGetRepeatingSetRowsV2Query } from 'features/entry-form-v2/data/useGetRepeatingSetRowsV2Query';
import { IconButton } from 'features/entry-form-v2/component/IconButton';
import { useVariablesQuery } from 'features/entry-form-v2/data/useVariablesQuery/useVariablesQuery';
import { SelectGroup } from 'features/entry-form-v2/smart-components/select-group/SelectGroup';
import { EntryHistoryModal } from 'features/entry-form-v2/smart-components/EntryHistoryModal';
import { DeleteEntryModal } from 'features/entry-form-v2/smart-components/DeleteEntryModal';

export const UpdateSeriesEntryPageV1_5 = () => {
	const { track } = useTracking();
	const params = useParams();
	const navigate = useNavigate();

	const [formKeyManualIdentifier, setFormKeyManualIdentifier] = useState(0);

	const entryId = params.entryId as string;
	const projectId = params.projectId as string;
	const seriesName = params.seriesName as string;
	const seriesEntryId = params.seriesEntryId as string;

	const allowLeavePageOverrideRef = useRef(false);

	const [selectedGroupId, setSelectedGroupId] = useState<string>();

	const projectDataQuery = useProjectData({
		projectId
	});
	const seriesFormItem = projectDataQuery.data?.formItems
		.filter(isSeriesFormItem)
		.find(formItem => formItem.name === seriesName);

	const {
		formId,
		shouldShowFormSelector: showFormSelector,
		toggleShowFormSelector,
		setSelectedFormId,
		getFormsQueryIsLoading,
		defaultFormId
	} = useFormSelector({
		projectId,
		seriesName
	});

	const seriesProjecDataQuery = useProjectData({
		projectId,
		seriesName,
		selectedFormId: formId
	});

	const getLatestDataEntryVersionQuery = useGetLatestDataEntryVersionQuery({
		entryId: seriesEntryId,
		projectId,
		setName: seriesName
	});
	useEffect(() => {
		// Make sure the url matches the latest entry id so updates are most likely to work
		if (
			getLatestDataEntryVersionQuery.data &&
			getLatestDataEntryVersionQuery.data.entry.datasetentryid !== entryId
		) {
			navigate(
				ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.update.createPath({
					projectId,
					entryId,
					seriesName,
					seriesEntryId: getLatestDataEntryVersionQuery.data.entry.datasetentryid,
					// defaultFormId is needed because of a race condition where this is called without formId before the search params are updated in useFormSelector
					formId: formId || defaultFormId
				}),
				{ replace: true }
			);
		}
	}, [getLatestDataEntryVersionQuery.data]);

	const updateDataEntryMutation = useUpdateDataEntryMutation();
	const [dataPendingSubmission, setDataPendingSubmission] = useState<DataPendingSubmission>();

	const [entrySubmissionError, setEntrySubmissionError] = useState<string>();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const submitSeriesEntry = async (args: { entry: Entry; action?: NextAction }) => {
		if (!seriesProjecDataQuery.data) {
			console.error(
				"onSubmit called but projectDataQuery.data doesn't exist, this is a no-op"
			);
			return;
		}

		try {
			setEntrySubmissionError(undefined);
			setIsSubmitting(true);

			const updatedSeriesEntry = await updateDataEntryMutation.mutate({
				projectId,
				entryId: seriesEntryId,
				entry: args.entry,
				setName: seriesName
			});

			const latestMainLevelEntry = await getLatestEntryVersion({
				entryId,
				projectId
			});

			track({
				eventName: 'entry_updated',
				data: {
					entryHasStatus: false,
					type: 'series-entry',
					action: args.action
				}
			});
			allowLeavePageOverrideRef.current = true;

			if (args.action?.type === 'saveAndNavigate') {
				return navigate(args.action.nextNavLocation);
			}

			if (args.action?.type === 'saveAndCreateNew') {
				return navigate(
					ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.create.createPath({
						projectId,
						entryId: latestMainLevelEntry.entry.datasetentryid,
						seriesName,
						formId
					})
				);
			}

			if (args.action?.type === 'saveAndClose') {
				// Bumping this is important, as if we dont reset the form state the user will get a diff modal on the next page
				setFormKeyManualIdentifier(prev => prev + 1);
				return navigate(-1);
			}

			navigate(
				ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.update.createPath({
					projectId,
					entryId: latestMainLevelEntry.entry.datasetentryid,
					seriesName,
					seriesEntryId: updatedSeriesEntry.updatedEntry.datasetentryid,
					formId
				}),
				{ replace: true }
			);
		} catch (e) {
			// Error is handled in the ErrorModal
		} finally {
			setDataPendingSubmission(undefined);
			allowLeavePageOverrideRef.current = false;
			setIsSubmitting(false);
		}
	};

	// Needed to not render SeriesNavigation before it has loaded
	const getRepeatingDataSetRowsV2Query = useGetRepeatingSetRowsV2Query({
		entryId,
		projectId,
		setName: seriesName
	});

	// Needed to prevent AggregationRulesDetails from possibly layout shifting after the initial page load
	const variablesDataQuery = useVariablesQuery({
		projectId
	});
	// Needed to prevent AggregationRulesDetails from possibly layout shifting after the initial page load
	const _getLatestDataEntryVersionQuery = useGetLatestDataEntryVersionQuery({
		entryId,
		projectId
	});
	const isPreloadingAggregationRules =
		variablesDataQuery.isLoading || _getLatestDataEntryVersionQuery.isLoading;

	const errorLoadingEntry = seriesProjecDataQuery.error || getLatestDataEntryVersionQuery.error;
	const loading =
		seriesProjecDataQuery.isLoading ||
		getLatestDataEntryVersionQuery.isLoading ||
		getFormsQueryIsLoading ||
		getRepeatingDataSetRowsV2Query.isLoading ||
		isPreloadingAggregationRules;

	if (errorLoadingEntry) {
		return (
			<ErrorLoadingEntry
				isFetching={seriesProjecDataQuery.isFetching}
				refetch={seriesProjecDataQuery.refetch}
			/>
		);
	}

	return (
		<StrictMode>
			{/** Same height as the header */}
			<div className="h-[60px]" />

			<div className="flex flex-col mt-20">
				{!loading && (
					<SelectForm
						expanded={showFormSelector}
						onFormSelected={setSelectedFormId}
						selectedFormId={formId}
						seriesName={seriesName}
					/>
				)}

				<div className="flex flex-col md:w-[756px] md:mx-auto">
					<ErrorModal
						onClose={updateDataEntryMutation.reset}
						error={updateDataEntryMutation.error}
					/>

					{loading && <EntryFormSkeleton />}

					{entrySubmissionError && (
						<p className="font-semibold text-base text-error-700/75 bg-error-700/10 border border-error-700/75 rounded-md p-4 text-center mb-10">
							{entrySubmissionError}
						</p>
					)}

					{!loading &&
						seriesProjecDataQuery.data &&
						getLatestDataEntryVersionQuery.data && (
							<>
								{!selectedGroupId && (
									<SelectGroup
										onGroupSelected={setSelectedGroupId}
										projectId={projectId}
									/>
								)}

								<AggregationRulesDetails className="mb-10" />

								<SubmitDiffModal
									dataPendingSubmission={dataPendingSubmission}
									initialEntry={getLatestDataEntryVersionQuery.data.entry}
									onClose={() => setDataPendingSubmission(undefined)}
									onConfirm={submitSeriesEntry}
									onDelete={() =>
										navigate(
											ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.createPath(
												{
													projectId,
													entryId,
													seriesName
												}
											)
										)
									}
									submitting={isSubmitting}
									variables={seriesProjecDataQuery.data.variables}
								/>

								<SeriesEntryNavigation />

								<EntryForm
									key={`${getLatestDataEntryVersionQuery.data.entry.datasetentryid}-${formId}-${formKeyManualIdentifier}`}
									Header={
										<Header
											isSubmitting={isSubmitting}
											seriesLabel={seriesFormItem?.label}
											formSelectorIsShowing={showFormSelector}
											onShowFormSelectorClicked={toggleShowFormSelector}
											onEntryDeleted={() => {
												allowLeavePageOverrideRef.current = true;

												navigate(
													ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.createPath(
														{ projectId, entryId, seriesName }
													)
												);
											}}
										/>
									}
									onSubmitForConfirmation={async ({ nextAction, dirtyData }) => {
										setDataPendingSubmission({
											entry: dirtyData,
											actionType: nextAction.type
										});
									}}
									onSubmitForSubmission={async ({ nextAction, dirtyData }) => {
										await submitSeriesEntry({
											entry: dirtyData,
											action: nextAction
										});
									}}
									projectData={seriesProjecDataQuery.data}
									initialEntry={getLatestDataEntryVersionQuery.data.entry}
									allowLeavePageOverrideRef={allowLeavePageOverrideRef}
									submitting={isSubmitting}
								/>
							</>
						)}
				</div>
			</div>
		</StrictMode>
	);
};

const Header = ({
	isSubmitting,
	seriesLabel,
	formSelectorIsShowing,
	onShowFormSelectorClicked,
	onEntryDeleted
}: {
	seriesLabel?: string;
	isSubmitting: boolean;
	formSelectorIsShowing: boolean;
	onShowFormSelectorClicked: () => void;
	onEntryDeleted: () => void;
}) => {
	const params = useParams();
	const navigate = useNavigate();

	const projectId = params.projectId as string;
	const entryId = params.entryId as string;
	const seriesName = params.seriesName as string;
	const seriesEntryId = params.seriesEntryId as string;

	const { formId } = useFormSelector({
		projectId
	});

	const getProjectQuery = useGetProjectQuery({ projectId });

	const dataEntryQuery = useGetLatestDataEntryVersionQuery({
		entryId,
		projectId
	});

	const getFormsQuery = useGetFormsQuery({
		projectId,
		seriesName
	});

	const submitToForm = (e: React.MouseEvent<HTMLButtonElement>) => {
		const target = e.target as HTMLElement;
		const form = target.closest('form');

		const submitEvent = createSubmitEvent(target);

		form?.dispatchEvent(submitEvent);
	};

	return (
		<>
			<EntryHeader
				breadCrumbs={[
					{
						title: getProjectQuery.data?.project.projectName,
						url: ROUTE_MAP.project.byId.dataset.createPath({
							projectId
						})
					},
					{
						title: 'Update Entry',
						url: ROUTE_MAP.project.byId.dataset.update.createPath({
							projectId,
							entryId,
							formId: null
						})
					},
					{
						title: seriesLabel,
						url: ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.createPath({
							projectId,
							entryId,
							seriesName
						})
					}
				]}
				title="Update Series Entry"
			>
				<div className="flex gap-4 items-center">
					<Link
						to={ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.create.createPath(
							{
								projectId,
								entryId,
								seriesName,
								formId
							}
						)}
					>
						<Button variant="secondary" title="Create Series Entry" type="button" />
					</Link>

					{getFormsQuery.data && getFormsQuery.data.activeForms.length > 1 && (
						<button
							onClick={onShowFormSelectorClicked}
							className="self-end"
							type="button"
						>
							<Icon
								svg={Svgs.FileText}
								active={formSelectorIsShowing}
								propagate
								variant={v => v.button}
							/>
						</button>
					)}

					<OptionsMenu
						onEntryDeleted={onEntryDeleted}
						entryId={seriesEntryId}
						projectId={projectId}
						hasDeleteAccess={!!dataEntryQuery.data?.entryAccess.delete}
						seriesName={seriesName}
						handlePrint={() => {
							window.open(
								ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.update.print.createPath(
									{
										projectId,
										entryId,
										seriesName,
										seriesEntryId,
										formId
									}
								),
								'_blank'
							);
						}}
					/>
					<div className="flex gap-[1px]">
						<Button
							loading={isSubmitting}
							type="submit"
							className="col-start-4 col-span-2 rounded-r-none"
							data-action="save"
							title="Save"
							variant="primary"
						/>

						<SplitButton className="rounded-l-none">
							<SplitButton.Item
								data-action="saveAndClose"
								title="Save and close"
								onClick={submitToForm}
							/>
							<SplitButton.Item
								data-action="saveAndCreateNew"
								title="Save and create new"
								onClick={submitToForm}
							/>
						</SplitButton>
					</div>

					<IconButton onClick={() => navigate(-1)}>
						<CloseIcon
							className="text-icon-base"
							data-testid="entry-form-header_close-icon"
						/>
					</IconButton>
				</div>
			</EntryHeader>
		</>
	);
};

const OptionsMenu = ({
	entryId,
	projectId,
	onEntryDeleted,
	hasDeleteAccess,
	seriesName,
	handlePrint
}: {
	entryId: string;
	projectId: string;
	onEntryDeleted: () => void;
	hasDeleteAccess: boolean;
	seriesName: string;
	handlePrint: () => void;
}) => {
	const [showDeleteEntryModal, setShowDeleteEntryModal] = useState(false);
	const [showEntryHistoryModal, setShowEntryHistoryModal] = useState(false);
	const [showEntryDetailsModal, setShowEntryDetailsModal] = useState(false);

	return (
		<div className="relative">
			<Menu>
				<Menu.Button className="rounded-full p-2 hover:bg-gray-700/10">
					<MoreIcon className="text-icon-base" aria-label="More options" />
				</Menu.Button>

				<Menu.Items className="absolute overflow-hidden top-14 right-0 w-48 bg-white rounded-lg z-50 shadow-normal flex flex-col items-stretch">
					<Menu.Item>
						<OptionsMenuButton
							ariaLabel="Print entry"
							onClick={() => {
								handlePrint();
								track({
									eventName: 'print_entry_clicked'
								});
							}}
						>
							Print entry
						</OptionsMenuButton>
					</Menu.Item>

					<Menu.Item>
						<OptionsMenuButton
							onClick={() => setShowEntryDetailsModal(true)}
							ariaLabel="Entry details"
						>
							Entry details
						</OptionsMenuButton>
					</Menu.Item>

					<Menu.Item>
						<OptionsMenuButton
							onClick={() => setShowEntryHistoryModal(true)}
							ariaLabel="Entry history"
						>
							Entry history
						</OptionsMenuButton>
					</Menu.Item>

					{hasDeleteAccess && (
						<Menu.Item>
							<OptionsMenuButton
								ariaLabel="Delete entry"
								onClick={() => setShowDeleteEntryModal(true)}
							>
								Delete entry
							</OptionsMenuButton>
						</Menu.Item>
					)}
				</Menu.Items>
			</Menu>

			{hasDeleteAccess && (
				<DeleteEntryModal
					entryId={entryId}
					projectId={projectId}
					show={showDeleteEntryModal}
					seriesName={seriesName}
					onClose={() => setShowDeleteEntryModal(false)}
					onEntryDeleted={onEntryDeleted}
					title="Delete Series Entry"
					description="Are you sure you want to delete this series entry?"
				/>
			)}

			<EntryDetailsModal
				open={showEntryDetailsModal}
				onClose={() => setShowEntryDetailsModal(false)}
				entryId={entryId}
				projectId={projectId}
				seriesName={seriesName}
			/>

			<EntryHistoryModal
				open={showEntryHistoryModal}
				onClose={() => setShowEntryHistoryModal(false)}
				entryId={entryId}
				projectId={projectId}
				seriesName={seriesName}
			/>
		</div>
	);
};
