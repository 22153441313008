import { makeRequest } from 'features/entry-form-v2/data/makeRequest';

export const confirmFileUpload = async (args: { fileId: string; projectId: string }) => {
	return makeRequest<Response>({
		method: 'confirmFileUpload',
		service: 'projects',
		data: {
			fileId: args.fileId,
			project: {
				projectId: args.projectId
			}
		}
	});
};
