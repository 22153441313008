import { useMutation } from '@tanstack/react-query';
import { makeRequest } from '../makeRequest';

export const useUpdateEntryStatusMutation = () => {
	return useMutation({
		mutationFn: ({
			projectId,
			entryId,
			oldStatusName,
			newStatusName,
			comment
		}: {
			projectId: string;
			entryId: string;
			oldStatusName?: string;
			newStatusName: string;
			comment?: string;
		}) => {
			const observationData: UpdateEntryStatusObservationData = {};

			observationData[newStatusName] = {
				comment,
				value: true
			};

			if (oldStatusName && oldStatusName !== newStatusName) {
				observationData[oldStatusName] = {
					value: false,
					comment: ''
				};
			}

			return makeRequest({
				method: 'updateEntryStatus',
				service: 'cde',
				data: {
					projectId: parseInt(projectId),
					datasetentryid: entryId,
					observationData
				}
			});
		}
	});
};

export const useDeleteEntryStatusMutation = () => {
	return useMutation({
		mutationFn: ({
			projectId,
			entryId,
			statusName
		}: {
			projectId: string;
			entryId: string;
			statusName: string;
		}) => {
			const observationData: UpdateEntryStatusObservationData = {};
			observationData[statusName] = {
				value: false,
				comment: ''
			};

			return makeRequest({
				method: 'updateEntryStatus',
				service: 'cde',
				data: { projectId, datasetentryid: entryId, observationData }
			});
		}
	});
};

export type UpdateEntryStatusObservationData = Record<string, { comment?: string; value: boolean }>;
