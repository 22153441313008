import { Dictionary } from 'environment';
import { createActivity } from 'store/ui/activities';
import { ActionPayload, Thunk } from 'store/types';

import {
	ActionTypes,
	ShowOnlyUnreadNotificationsAction,
	GetTopicNameAction,
	GetNotificationHistoryAction,
	SetNotificationsAsReadAction,
	SetNotificationActionStateAction,
	NotificationActionState,
	GetProjectsLabelsAction,
	GetProjectNotificationSettingsAction,
	UpdateProjectNotificationSettingsAction,
	ProjectNotificationSettings,
	SetNewestNotificationsFromQueueAction,
	AddQueueNotificationAction
} from './types';
import { getMessageFromError, unknownErrorMessage } from 'store/utils';

export const getTopicNameAction = (
	payload: ActionPayload<GetTopicNameAction>
): GetTopicNameAction => ({
	type: ActionTypes.GET_TOPIC_NAME,
	payload
});

export const getTopicName = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({ type: ActionTypes.GET_TOPIC_NAME, dispatch });

	try {
		activity.begin();

		const topicName = await context.api.data.notifications().getTopicName();
		dispatch(getTopicNameAction({ topicName }));
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
		});
	} finally {
		activity.end();
	}
};

export const addNotificationHistoryAction = (
	payload: ActionPayload<GetNotificationHistoryAction>
): GetNotificationHistoryAction => ({
	type: ActionTypes.GET_NOTIFICATION_HISTORY,
	payload
});

export const setNewestNotificationsFromQueueAction = (
	payload: ActionPayload<SetNewestNotificationsFromQueueAction>
): SetNewestNotificationsFromQueueAction => ({
	type: ActionTypes.SET_NEWEST_NOTIFICATIONS_FROM_QUEUE,
	payload
});

export const addQueueNotificationAction = (
	payload: ActionPayload<AddQueueNotificationAction>
): AddQueueNotificationAction => ({
	type: ActionTypes.ADD_QUEUE_NOTIFICATION,
	payload
});

export const getNotificationHistory =
	(resetOffset: boolean): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_NOTIFICATION_HISTORY, dispatch });
		try {
			activity.begin();
			const { notificationHistory } = getState().data.notifications;

			const offset =
				notificationHistory.byNotificationId && !resetOffset
					? Object.keys(notificationHistory.byNotificationId).length
					: 0;

			const { notifications, totalNumber } = await context.api.data
				.notifications()
				.getNotificationHistory(offset);

			// TODO: till the backend changes date format from .0 to +0 we apply the change here
			notifications.forEach(notif => (notif.timestamp = notif.timestamp.replace('.', '+')));

			dispatch(
				addNotificationHistoryAction({
					notifications,
					clearPrevious: resetOffset ?? false,
					fullNotificationHistoryFetched: offset + notifications.length >= totalNumber
				})
			);
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

export const showOnlyUnreadNotificationsAction = (
	payload: ActionPayload<ShowOnlyUnreadNotificationsAction>
): ShowOnlyUnreadNotificationsAction => ({
	type: ActionTypes.SHOW_ONLY_UNREAD_NOTIFICATIONS,
	payload
});

export const setNotificationActionStateAction = (
	payload: ActionPayload<SetNotificationActionStateAction>
): SetNotificationActionStateAction => ({
	type: ActionTypes.SET_NOTIFICATION_ACTION_STATE,
	payload
});

export const setNotificationsAsReadAction = (
	payload: ActionPayload<SetNotificationsAsReadAction>
): SetNotificationsAsReadAction => ({
	type: ActionTypes.SET_NOTIFICATIONS_AS_READ,
	payload
});

export const setNotificationsAsRead =
	(notificationIds: string[]): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.SET_NOTIFICATIONS_AS_READ, dispatch });

		try {
			activity.begin();
			// in order for user to see the changes right away, first the store is
			// updated and in case api request fails we revert the changes
			dispatch(setNotificationsAsReadAction({ notificationIds, areRead: true }));
			await context.api.data.notifications().setNotificationsAsRead(notificationIds);
		} catch (e: any) {
			dispatch(setNotificationsAsReadAction({ notificationIds, areRead: false }));
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

export const acceptSubcriptionInvitationNotification =
	(notificationIds: string[], userId: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.ACCEPT_SUBSCRIPTION_INVITATION_NOTIFICATION,
			dispatch
		});

		try {
			activity.begin({ payload: notificationIds });

			await context.api.account.subscription().respondToSubscriptionInvitation({
				isSubscriptionAccepted: true,
				userId
			});

			await context.api.data
				.notifications()
				.setNotificationActionState(
					notificationIds,
					NotificationActionState.ActionAccepted
				);

			dispatch(
				setNotificationActionStateAction({
					actionState: NotificationActionState.ActionAccepted,
					notificationIds
				})
			);
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				payload: notificationIds,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

export const rejectSubscriptionInvitationNotification =
	(notificationIds: string[], userId: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.REJECT_SUBSCRIPTION_INVITATION_NOTIFICATION,
			dispatch
		});

		try {
			activity.begin({ payload: notificationIds });

			await context.api.account.subscription().respondToSubscriptionInvitation({
				isSubscriptionAccepted: false,
				userId
			});

			await context.api.data
				.notifications()
				.setNotificationActionState(
					notificationIds,
					NotificationActionState.ActionRejected
				);

			dispatch(
				setNotificationActionStateAction({
					actionState: NotificationActionState.ActionRejected,
					notificationIds
				})
			);
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				payload: notificationIds,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
			});
		} finally {
			activity.end();
		}
	};

export const getProjectsLabelsActions = (
	payload: ActionPayload<GetProjectsLabelsAction>
): GetProjectsLabelsAction => ({
	type: ActionTypes.GET_PROJECTS_LABELS,
	payload
});

export const getProjectsLabels = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_PROJECTS_LABELS,
		dispatch
	});

	try {
		activity.begin();

		const projectsLabels = await context.api.data.notifications().getProjectsLabels();
		dispatch(getProjectsLabelsActions({ projectsLabels }));
	} catch (e: any) {
		const errorMessage = getMessageFromError(e);
		activity.error({
			error: errorMessage,
			toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage }
		});
	} finally {
		activity.end();
	}
};

export const getProjectNotificationSettingsAction = (
	payload: ActionPayload<GetProjectNotificationSettingsAction>
): GetProjectNotificationSettingsAction => ({
	type: ActionTypes.GET_PROJECT_NOTIFICATION_SETTINGS,
	payload
});

export const getProjectNotificationSettings =
	(projectId: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_PROJECT_NOTIFICATION_SETTINGS,
			dispatch
		});

		try {
			activity.begin({ payload: projectId });

			const settings = await context.api.data
				.notifications()
				.getProjectNotificationSettings(projectId);
			dispatch(getProjectNotificationSettingsAction({ projectId, settings }));
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: projectId
			});
		} finally {
			activity.end();
		}
	};

export const updateProjectNotificationSettingsAction = (
	payload: ActionPayload<UpdateProjectNotificationSettingsAction>
): UpdateProjectNotificationSettingsAction => ({
	type: ActionTypes.UPDATE_PROJECT_NOTIFICATION_SETTINGS,
	payload
});

export const updateProjectNotificationSettings =
	(projectId: string, settings: ProjectNotificationSettings): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_PROJECT_NOTIFICATION_SETTINGS,
			dispatch
		});

		try {
			activity.begin({ payload: projectId });

			await context.api.data
				.notifications()
				.updateProjectNotificationSettings(projectId, settings);

			dispatch(updateProjectNotificationSettingsAction({ projectId, settings }));
		} catch (e: any) {
			const errorMessage = getMessageFromError(e);
			activity.error({
				error: errorMessage,
				toast: { display: errorMessage !== unknownErrorMessage, message: errorMessage },
				payload: projectId
			});
		} finally {
			activity.end();
		}
	};
