import { useMemo } from 'react';
import { ROUTES } from 'types/navigation';
import { useRouteMatch } from './utils';

export function useMatchProjects() {
	const projectRoutes = [
		ROUTES.Projects,
		ROUTES.CreateProject,
		ROUTES.ImportDataset,
		ROUTES.Analysis,
		ROUTES.Dataset,
		ROUTES.EntryStatuses,
		ROUTES.CreateEntry,
		ROUTES.UpdateEntry,
		ROUTES.Variables,
		ROUTES.CreateVariable,
		ROUTES.Dependencies,
		ROUTES.Templates,
		ROUTES.Forms,
		ROUTES.Form,
		ROUTES.Documents,
		ROUTES.Collaborators,
		ROUTES.AddCollaborators,
		ROUTES.Roles,
		ROUTES.DatasetHistory
	];

	return useRouteMatch(projectRoutes);
}

export function useMatchAccountUM() {
	const accountUMRoutes = [
		ROUTES.AccountUMSubscription,
		ROUTES.AccountUMPayment,
		ROUTES.AccountUMBillingHistory
	];

	return useRouteMatch(accountUMRoutes);
}

export function useMatchUsersAdmin() {
	const adminRootPath = '/admin';
	const adminRoutes = [
		`${adminRootPath}${ROUTES.AdminUsers}`,
		`${adminRootPath}${ROUTES.AdminEnterprise}`,
		`${adminRootPath}${ROUTES.AdminRoles}`
	];

	return useRouteMatch(adminRoutes);
}

export function useMatchEnterpriseAdmin() {
	const adminRootPath = '/enterprise-admin';
	const adminRoutes = [
		`${adminRootPath}${ROUTES.EnterpriseAdminUsers}`,
		`${adminRootPath}${ROUTES.EnterpriseAdminProjects}`,
		`${adminRootPath}${ROUTES.EnterpriseAdminSubscription}`,
		`${adminRootPath}${ROUTES.EnterpriseAdminRoles}`,
		`${adminRootPath}${ROUTES.EnterpriseAdminSettings}`
	];

	return useRouteMatch(adminRoutes);
}

export function useIsRouteWithProjectId() {
	const matchProjects = useMatchProjects();

	const isRouteWithProjectId = useMemo(() => matchProjects, [matchProjects]);

	return { isRouteWithProjectId };
}

export function useMatchTemplateRoles() {
	return useRouteMatch([ROUTES.TemplateRoles]);
}
