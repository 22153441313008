import { useState } from 'react';
import produce from 'immer';
import {
	CollaboratorPermissions as CollaboratorPermissionsType,
	GeneralPermissions as GeneralPermissionsType,
	ModulesPermissions as ModulesPermissionsType,
	StatusTypeAccesses,
	GeneralPermissionFlags
} from 'store/data/collaborators';
import { GeneralPermissions, StatusesPermissions } from 'components/Collaborators';
import { Status } from 'store/data/statuses';

import { Tab } from './CollaboratorPermissions.style';
import { MediaQueries } from 'environment';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { useOrganizationAccessRights, usePermissions, useTranslation } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';

enum Views {
	General = 'general',
	Statuses = 'statuses',
	Modules = 'modules'
}

interface Props {
	metadata: {
		isSelf: boolean;
		isProjectOwner: boolean;
		projectId?: string;
	};
	statuses: Status[];
	disabled?: boolean;
	permissions: CollaboratorPermissionsType;
	onChange(permissions: CollaboratorPermissionsType): void;
}

export function CollaboratorPermissions({
	metadata: { isSelf, isProjectOwner, projectId },
	statuses,
	permissions,
	disabled: _disabled,
	onChange
}: Props) {
	const { translate } = useTranslation();
	const [view, setView] = useState(Views.General);

	const [
		{
			data: { accessExportDelegation }
		}
	] = useOrganizationAccessRights();

	const hasStatuses = statuses.length > 0;

	const viewItems = [
		{
			title: translate(dict => dict.collaborators.collaboratorPermissions.general),
			type: Views.General,
			active: view === Views.General
		},
		hasStatuses
			? {
					title: translate(dict => dict.collaborators.collaboratorPermissions.statuses),
					type: Views.Statuses,
					active: view === Views.Statuses
			  }
			: []
	].flat();

	const { canAddCollaborator } = usePermissions(projectId ? { projectId } : undefined);

	const disabled = !canAddCollaborator || _disabled;

	const isMobileDevice = useMediaQuery(
		`${translate(dict => dict.collaborators.collaboratorPermissions.onlyScreen)} ${translate(
			dict => dict.collaborators.collaboratorPermissions.and
		)} ${MediaQueries.minWidth.xs} ${translate(
			dict => dict.collaborators.collaboratorPermissions.and
		)} ${MediaQueries.maxWidth.sm}`
	);

	const onChangePermissions = {
		general(generalPermissions: GeneralPermissionsType) {
			const updatedPermissions = produce(permissions, draft => {
				for (const key in generalPermissions) {
					const value = generalPermissions[key as GeneralPermissionFlags];

					draft[key as GeneralPermissionFlags] = value;
				}
			});

			onChange(updatedPermissions);
		},
		statuses(statusesPermissions: StatusTypeAccesses) {
			const updatedPermissions = produce(permissions, draft => {
				draft.statusTypeAccesses = statusesPermissions;
			});

			onChange(updatedPermissions);
		},
		modules(modulesPermissions: ModulesPermissionsType) {
			const updatedPermissions = produce(permissions, draft => {
				draft.modules = modulesPermissions;
			});

			onChange(updatedPermissions);
		}
	};

	return (
		<Flex flex={1} column={isMobileDevice}>
			{/* TABS */}
			<Flex
				css={`
					width: ${isMobileDevice ? '100%' : '14rem'};
					min-width: ${isMobileDevice ? '100%' : '14rem'};
					margin-bottom: ${isMobileDevice ? '2rem' : '0'};
				`}
				column={!isMobileDevice}
			>
				{viewItems.map(view => (
					<Tab key={view.type} active={view.active} onClick={() => setView(view.type)}>
						<Typography.Paragraph title={view.title}>{view.title}</Typography.Paragraph>
					</Tab>
				))}
			</Flex>

			{/* TABS CONTENT */}
			<Flex flex={1} paddingOffset={{ x: 1.6, y: 0.8 }}>
				{view === Views.General && (
					<GeneralPermissions
						permissions={permissions}
						disabled={disabled}
						isProjectOwner={isProjectOwner && isSelf}
						onChange={onChangePermissions.general}
						hasOrganizationExportAccess={accessExportDelegation}
					/>
				)}

				{view === Views.Statuses && permissions.statusTypeAccesses && (
					<StatusesPermissions
						statuses={statuses}
						permissions={permissions.statusTypeAccesses}
						disabled={disabled}
						onChange={onChangePermissions.statuses}
					/>
				)}
			</Flex>
		</Flex>
	);
}
