// consts

export enum ZingChartExportTypes {
	FrequenciesColumns = 'frequencies-columns-',
	FrequenciesPie = 'frequencies-pie-',
	CrosstabGrouped = 'crosstab-grouped-',
	CrosstabStacked = 'crosstab-stacked-',
	CrosstabSunburst = 'crosstab-sunburst-',
	KaplanMeier = 'kaplan-meier-',
	Correlations = 'correlations-',
	PlotNumericColumns = 'plot-numeric-columns-',
	PlotNumericBoxPlot = 'plot-numeric-boxplot-',
	PlotNumericScatter = 'plot-numeric-scatter-',
	DensityPlot = 'density-plot-',
	TimeCourse = 'time-course-',
	NumberPltoXY = 'number-plot-xy-',
	LogisticRegression = 'logistic-regression-'
}

export enum ZingChartTypes {
	Area = 'area',
	AreaVertical = 'varea',
	Area3D = 'area3d',
	Bullet = 'bullet',
	Bar = 'bar',
	BarVertical = 'vbar',
	BarHorizontal = 'hbar',
	Bar3D = 'bar3d',
	Bar3DVertical = 'vbar3d',
	Bar3DHorizontal = 'hbar3d',
	BoxPlot = 'boxplot',
	BoxPlotHorizontal = 'hboxplot',
	Line = 'line',
	LineVertical = 'vline',
	Line3D = 'line3d',
	Mixed = 'mixed',
	Pie = 'pie',
	PieNav = 'navpie',
	Pie3D = 'pie3d',
	PieDonut = 'ring',
	PieDonut3D = 'ring3d',
	Range = 'range',
	Scatter = 'scatter',
	Sunburst = 'sunburst'
}

// zingchart opts object

interface ChartLegend {
	toggleAction?: string;
	highlightPlot?: boolean;
	item?: {
		maxChars?: number | null;
		text?: string;
	};
	tooltip?: {
		text: string;
		visible?: boolean;
		maxWidth?: string;
	} | null;
	header?: {
		text: string | undefined;
	};
	marker?: {
		type: 'circle';
	};
	visible?: boolean;
}

interface ChartPreview {
	preserveZoom?: boolean;
	active?: {
		alpha: number;
		alphaArea: number;
	};
	live?: boolean;
	adjustLayout?: boolean;
}

interface ChartPlot {
	barWidth?: number;
	hoverState?: {
		visible?: boolean;
		alpha?: number;
	};
	goal?: {
		border: string;
		height: number;
	};
	error?: {
		lineWidth: number;
		size: number;
	};
	aspect?: string;
	backgroundColor?: string;
	lineWidth?: number;
	marker?: {
		backgroundColor?: string;
		visible?: boolean;
		size?: number;
		borderColor?: string;
		borderAlpha?: number;
	};
	decimals?: number;
	maxTrackers?: number;
	stacked?: boolean;
	tooltip?: {
		backgroundColor?: string;
		align?: string;
		text?: string;
	};
	valueBox?: {
		placement?: string;
		fontFamily?: string;
		text: string;
		visible: boolean;
	};
}

interface ChartPlotarea {
	marginLeft?: number | string | undefined;
	marginRight?: number | string | undefined;
	width?: string;
	height?: string;
	margin?: string;
}

interface ChartScale {
	label?: { text: string; maxChars: number } | null;
	minValue?: number;
	maxValue?: number;
	itemsOverlap?: boolean;
	maxItems?: number | undefined;
	offset?: number | undefined;
	step?: number | string | null;
	exponent?: boolean;
	values?: string[] | string;
	labels?: string[] | string;
	visible?: boolean;
	zooming?: boolean;
	utc?: boolean;
	transform?: {
		all?: string;
		type?: string;
		uniform?: boolean;
	};
	tooltip?: {
		text: string;
		borderRadius: number;
		borderWidth: number;
		padding: number;
		borderColor: string;
		maxWidth: string;
	} | null;
	guide?: {
		lineColor: string;
		lineWidth: number;
		lineStyle: string;
	};
	item?: {
		fontAngle?: number | null;
		maxChars?: number | null;
		visible?: boolean;
	};
	tick?: {
		visible: boolean;
	};
}

interface ChartTooltip {
	text?: string;
	backgroundColor?: string;
	visible?: boolean;
}

interface ChartOptions {
	palette?: string[];
	slice?: number;
	space?: number;
	lineMedianLevel?: {
		lineColor: string;
		lineWidth: number;
	};
}

interface ChartCrosshair {
	lineStyle: string;
	lineColor: string;
	plotLabel: {
		borderRadius: number;
		borderWidth: number;
		borderColor: string;
		padding: number;
		fontWeight: string;
	};
	scaleLabel: {
		visible: false;
	};
}

export interface Options {
	legend?: ChartLegend;
	crosshairX?: ChartCrosshair;
	crossHairY?: ChartCrosshair;
	tooltip?: ChartTooltip;
	options?: ChartOptions;
	scaleX?: ChartScale;
	scaleY?: ChartScale;
	plotarea?: ChartPlotarea;
	plot?: ChartPlot;
	preview?: ChartPreview;
}
