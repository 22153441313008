import { makeRequest } from './makeRequest';

export const confirmFileUpload = async ({
	projectId,
	fileId
}: {
	projectId: string;
	fileId: string;
}) => {
	return makeRequest({
		method: 'confirmFileUpload',
		service: 'cde',
		data: {
			projectId,
			fileId
		}
	});
};
