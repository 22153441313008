import { SeriesEntryBodyRow } from './SeriesEntryBody';

export const createFilterByFreeText = (freeText: string) => {
	const lowerCasedFreeText = freeText.toLowerCase();

	return (entryRow: SeriesEntryBodyRow) => {
		return Object.values(entryRow.fieldsByVariableName).some(value => {
			return value.label.toLowerCase().includes(lowerCasedFreeText);
		});
	};
};
