import { useEffect, useMemo, useState } from 'react';

import { Svgs } from 'environment';
import {
	toggleFreeLicenceLimitationModalEvent,
	LicenceLimitationErrorTypes
} from 'helpers/licences';
import { Link } from 'components/UI/Link';
import { Flex } from 'components/UI/Flex';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useCustomEventListener } from 'helpers/events';
import { openNewTabSubscriptionPage } from 'helpers/navigation';
import { NavigationRoutes, useNavigation, useRouteMatch } from 'hooks/navigation';
import { useTranslation, useProjectId, useSubscription } from 'hooks/store';
import { ROUTES } from 'types/index';
import { useTracking } from 'app/tracking/TrackingProvider';

type NavigationType = keyof NavigationRoutes;

export function LicenceLimitationModal() {
	const { translate } = useTranslation();
	const { navigate, routes } = useNavigation();
	const { track } = useTracking();
	const [projectId] = useProjectId();

	const [licenceLimitationModalVisible, setLicenceLimitationModalVisible] = useState(false);

	const [message, setMessage] = useState('');
	const [redirectRoute, setRedirectRoute] = useState<NavigationType | null>(null);
	const [isCurrentUsersLicenceError, setIsCurrentUsersLicenceError] = useState(true);

	const isOnCollaboratorsPage = useRouteMatch([ROUTES.Collaborators, ROUTES.AddCollaborators]);

	const [
		{
			data: {
				subscriptionTypes: { isLedidiCollaborator }
			},
			loading: loadingSubscription
		}
	] = useSubscription();

	useCustomEventListener(toggleFreeLicenceLimitationModalEvent, {
		onListen: errorCode => {
			if (
				errorCode === LicenceLimitationErrorTypes.createProject ||
				errorCode === LicenceLimitationErrorTypes.copyProject
			) {
				setRedirectRoute(routes.projects.list as NavigationType);
				setMessage(translate(({ errors }) => errors.api.projects.licenceError));
				setIsCurrentUsersLicenceError(true);
				setLicenceLimitationModalVisible(true);
			}

			if (errorCode === LicenceLimitationErrorTypes.transferProject) {
				setMessage(
					translate(({ errors }) => errors.api.projects.licenceErrorProjectNewOwner)
				);
				setIsCurrentUsersLicenceError(false);
				setLicenceLimitationModalVisible(true);
			}

			if (errorCode === LicenceLimitationErrorTypes.collaboratorShareProject) {
				setMessage(
					translate(({ collaborators }) => collaborators.errors.notEligibleLicence)
				);
				projectId &&
					setRedirectRoute(
						routes.projects.collaborators.view(projectId) as NavigationType
					);
				setIsCurrentUsersLicenceError(false);
				setLicenceLimitationModalVisible(true);
			}

			if (errorCode === LicenceLimitationErrorTypes.createEntry) {
				projectId &&
					setRedirectRoute(routes.projects.dataset.view(projectId) as NavigationType);
				setMessage(translate(({ errors }) => errors.api.entries.licenceError));
				setIsCurrentUsersLicenceError(true);
				setLicenceLimitationModalVisible(true);
			}

			if (errorCode === LicenceLimitationErrorTypes.collaboratorCreateEntry) {
				projectId &&
					setRedirectRoute(routes.projects.dataset.view(projectId) as NavigationType);
				setMessage(translate(({ errors }) => errors.api.entries.collaboratorLicenceError));
				setIsCurrentUsersLicenceError(false);
				setLicenceLimitationModalVisible(true);
			}
		}
	});

	function manageSubscription() {
		track({
			eventName: 'licence_limitation_modal_upgrade_clicked'
		});
		isCurrentUsersLicenceError && openNewTabSubscriptionPage(true);
		onClose();
	}

	function onDone() {
		if (redirectRoute) navigate(redirectRoute);
		else {
			if (!isOnCollaboratorsPage) navigate(routes.projects.list);
			else projectId && navigate(routes.projects.collaborators.view(projectId));
		}
		onClose();
	}

	function onClose() {
		setLicenceLimitationModalVisible(false);
	}

	const modalTitle = useMemo(() => {
		if (!isCurrentUsersLicenceError || isLedidiCollaborator)
			return translate(dict => dict.timeToUpgradeModal.licenceLimitationHeader);
		else return translate(dict => dict.timeToUpgradeModal.header);
	}, [isCurrentUsersLicenceError, isLedidiCollaborator, loadingSubscription, translate]);

	const modalPrimaryButton = useMemo(() => {
		return {
			label: translate(dict => dict.buttons.upgrade),
			onClick: manageSubscription
		};
	}, [isCurrentUsersLicenceError, isLedidiCollaborator, loadingSubscription, translate]);

	return (
		<Modal
			title={modalTitle}
			visible={licenceLimitationModalVisible}
			primary={modalPrimaryButton}
			onClose={onDone}
			close
		>
			<Flex column align={a => a.center}>
				<Track />

				<Spacer size={s => s.xl} />
				<Svgs.UpgradePlan />
				<Spacer size={s => s.m} />
				{isCurrentUsersLicenceError ? (
					isLedidiCollaborator ? (
						<>
							<Typography.H3 multiline alignCenter>
								{translate(dict => dict.timeToUpgradeModal.licenceLimitationHeader)}
							</Typography.H3>
							<Spacer size={s => s.m} />
							<Typography.Paragraph multiline alignCenter>
								{translate(dict => dict.timeToUpgradeModal.descriptionCollaborator)}
								<Link href="mailto:support@ledidi.no">{`support@ledidi.no`}</Link>
							</Typography.Paragraph>
						</>
					) : (
						<>
							<Typography.H3 multiline alignCenter>
								{translate(dict => dict.timeToUpgradeModal.title)}
							</Typography.H3>
							<Spacer size={s => s.m} />
							<Typography.Paragraph multiline alignCenter>
								{translate(dict => dict.timeToUpgradeModal.description)}
							</Typography.Paragraph>
						</>
					)
				) : (
					<>
						<Typography.Paragraph multiline alignCenter>
							{message}
						</Typography.Paragraph>
					</>
				)}
			</Flex>
		</Modal>
	);
}

const Track = () => {
	const { track } = useTracking();

	useEffect(() => {
		track({
			eventName: 'licence_limitation_modal_shown'
		});
	}, []);

	return null;
};
