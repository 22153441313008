import { useController } from 'react-hook-form';
import { FileFormItem } from '../data/useProjectData/useProjectData';
import { FormControl } from '../EntryForm';
import { FileInput } from './file/FileInput';
import clsx from 'clsx';

interface Props {
	control: FormControl;
	formItem: FileFormItem;
	onError: (name: string, error: string | undefined) => void;
}

export const FileVariableInput = ({ control, formItem, onError }: Props) => {
	const {
		field: { onChange, value },
		fieldState
	} = useController({
		control,
		name: formItem.variable.variableName
	});

	return (
		<FileInput
			className={clsx(formItem.hideFromUi && 'hidden')}
			label={formItem.variable.variableLabel}
			variableName={formItem.variable.variableName}
			onValueChange={frontendFile => onChange(frontendFile)}
			required={formItem.variable.obligatory}
			initialFile={value}
			onError={error => onError(formItem.variable.variableName, error)}
			error={fieldState.error?.message}
			description={formItem.variable.description}
		/>
	);
};
