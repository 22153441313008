import Papa from 'papaparse';
import { useEffect, useState } from 'react';
import { FileMimeType, StringMap } from 'types';
import api from 'api/data/projects/api';
import { nanoid as generate } from 'nanoid';
import { Column } from './CsvTable';

const parseCsvData = (csvAsText: string) => {
	const parseResult = Papa.parse<string[]>(csvAsText, {
		skipEmptyLines: true
	});

	if (parseResult.errors.length > 0) {
		console.error('CSV parse failed. Please check the format of the imported file.');
	}

	return parseResult.data;
};

const formatHeadersAndRows = (parsedData: string[][]) => {
	const [header, ...rows] = parsedData;

	const formattedHeader = header.map(header => ({
		Header: header,
		accessor: generate()
	}));

	const formattedRows = rows.map(row => {
		const newRow: StringMap = {};

		formattedHeader.forEach(({ accessor }, index) => {
			newRow[accessor] = row[index];
		});

		return newRow;
	});

	return { header: formattedHeader, rows: formattedRows };
};

const parseBlob = async (
	blob: Blob,
	projectId: string
): Promise<{ header: Column[]; rows: StringMap[] }> => {
	const text = await blob.text();

	if (blob.type === FileMimeType.TXT) {
		const data = text as unknown as string[][];
		return formatHeadersAndRows(data);
	}

	if (blob.type === FileMimeType.CSV) {
		return formatHeadersAndRows(parseCsvData(text));
	}

	// Handle Excel files
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsBinaryString(blob);

		reader.onload = async () => {
			const readerResult = reader.result as string;
			try {
				const readerResultBase64 = btoa(readerResult);
				const response = await api().convertXlsToCsv({
					body: {
						projectId: parseInt(projectId, 10),
						Base64EncodedXlsx: readerResultBase64
					}
				});

				if (response) {
					resolve(formatHeadersAndRows(parseCsvData(response)));
				} else {
					reject(new Error('Failed to convert Excel file'));
				}
			} catch (error) {
				reject(error);
			}
		};

		reader.onerror = error => reject(error);
	});
};

export const useParseFile = (props: { blob: Blob; projectId: string }) => {
	const { blob, projectId } = props;

	const [data, setData] = useState<{ header: Column[]; rows: StringMap[] }>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);

	useEffect(() => {
		const handleFileParsing = async () => {
			setIsLoading(true);

			try {
				const parsedData = await parseBlob(blob, projectId);
				setData(parsedData);
				setIsError(false);
			} catch (error) {
				console.error(error);
				setIsError(true);
			} finally {
				setIsLoading(false);
			}
		};

		handleFileParsing();
	}, []);

	return { data, isLoading, isError };
};
