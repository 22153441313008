import { ProjectOwnership, ProjectStatus } from 'types/data/projects/constants';
import { State, ProjectSort, ProjectsViewOptions, ProjectTableViewData } from './types';

const initialState: State = {
	projectId: null,
	isValid: false,
	byId: {},
	initialProjectIds: [],
	projects: {
		all: [],
		byStatus: {
			[ProjectStatus.Ongoing]: [],
			[ProjectStatus.OnHold]: [],
			[ProjectStatus.Ended]: []
		},
		byOwnership: {
			[ProjectOwnership.Own]: [],
			[ProjectOwnership.SharedWithMe]: [],
			[ProjectOwnership.All]: []
		},
		fetched: false
	},
	import: {
		headerDelimiter: ',',
		helpText: null,
		csv: null,
		originalContent: null,
		isBinary: false,
		convertedCsvData: null,
		dateFormats: [],
		dateTimeFormats: [],
		isExcelOrigin: false,
		timeZones: [],
		suggestedVariableTypes: null,
		initialSuggestions: null,
		importPercentage: 0,
		assignedOrganizationId: null,
		file: {
			fileId: '',
			uploaded: false
		},
		formattedVariables: [],
		previousMapping: {
			importType: null,
			formattedVariables: [],
			columnToMatch: null,
			variableToMatch: null,
			columnToMatchWithMainLevel: null,
			variableToMatchOnMainLevel: null
		},
		dataToEntries: {
			oldFormattedVariables: [],
			columnToMatch: null,
			variableToMatch: null
		},
		importVariableSet: {
			isImportVariableSet: false,
			importVariableSetName: '',
			columnToMatchWithMainLevel: null,
			variableToMatchOnMainLevel: null
		}
	},
	filters: {
		projects: {
			term: '',
			status: ProjectStatus.Ongoing,
			ownership: ProjectOwnership.All,
			sort: ProjectSort.byNumber
		}
	},
	tableFilters: {
		projects: {
			filters: [],
			pageIndex: 0,
			pageSize: 10,
			sortBy: []
		}
	},
	avatars: {
		byProjectId: {}
	},
	entriesCount: {
		byProjectId: {}
	},
	metadata: {
		viewOption: ProjectsViewOptions.GRID,
		columnSettings: {
			visible: [
				ProjectTableViewData.title,
				ProjectTableViewData.collaborators,
				ProjectTableViewData.metadataArchiveNumber,
				ProjectTableViewData.metadataProjectType
			]
		},
		refetch: false
	},
	copiedProjectId: null,
	projectMetadataDefinition: null
};

export default initialState;
