import clsx from 'clsx';
import { Asterisk } from 'components/UI/Asterisk';

interface Props {
	required?: boolean;
	label: string;
	htmlFor: string;
}

export const Label = ({ required, label, htmlFor }: Props) => {
	return (
		<label className={clsx('text-sm font-semibold flex gap-1')} htmlFor={htmlFor}>
			{label}

			{required && <Asterisk />}
		</label>
	);
};
