import { LedidiRequestError } from '../makeRequest';

export async function uploadFileToS3(args: {
	file: File;
	uploadUrl: string;
	fields: Record<string, string>;
}) {
	const formData = new FormData();

	Object.entries(args.fields).forEach(([key, value]) => {
		formData.append(key, value);
	});

	formData.append('file', args.file);

	const result = await fetch(args.uploadUrl, {
		method: 'POST',
		body: formData
	});

	if (!result.ok) {
		const errorText = await result.text();

		// Check if it's an XML error response
		if (errorText.includes('<?xml') && errorText.includes('<Error>')) {
			// Parse XML to JSON
			const parser = new DOMParser();
			const xmlDoc = parser.parseFromString(errorText, 'text/xml');

			const errorNode = xmlDoc.getElementsByTagName('Error')[0];
			const errorCode = errorNode.getElementsByTagName('Code')[0]?.textContent;
			const errorMessage = errorNode.getElementsByTagName('Message')[0]?.textContent;

			// Handle known error codes
			if (errorCode === 'EntityTooLarge') {
				throw new LedidiRequestError({
					service: 's3',
					method: 'uploadFile',
					errors: [
						{
							code: S3_ERRORS.FILE_TOO_LARGE
						}
					]
				});
			} else {
				throw new Error(`Upload failed: ${errorCode} - ${errorMessage}`);
			}
		} else {
			throw new Error(`Failed to upload file`);
		}
	}

	return result;
}

export const S3_ERRORS = {
	FILE_TOO_LARGE: 'error.fileTooLarge'
};
