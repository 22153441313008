import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useIsRouteWithProjectId, useNavigation } from 'hooks/navigation';
import { useProject } from '../data/projects/useProject';
import { useProjectId } from '../data/projects/useProjectId';
import { usePrevious } from 'hooks/utils';

export function useWithProject() {
	const { id } = useParams<{ id: string }>();

	const { replace, routes } = useNavigation();

	const [projectId, setProjectId] = useProjectId();
	const [{ loading, error }, { handler: getProject, resetError }] = useProject();

	// ROUTES WITH PROJECT ID
	const { isRouteWithProjectId } = useIsRouteWithProjectId();

	// SET PROJECT ID
	useEffect(() => {
		if (isRouteWithProjectId && projectId === null && id !== undefined) {
			setProjectId(id);
		}
	}, [isRouteWithProjectId, projectId, id]);

	// FETCH PROJECT
	const previousProjectId = usePrevious(projectId);
	useEffect(() => {
		if (
			projectId &&
			previousProjectId !== projectId &&
			isRouteWithProjectId &&
			!loading &&
			!error
		) {
			getProject();
		}
	}, [projectId, previousProjectId, isRouteWithProjectId, loading, error]);

	// HANDLE ERROR
	useEffect(() => {
		if (!error) return resetError();

		replace(routes.projects.list);
		setProjectId(null);
	}, [error]);
}
