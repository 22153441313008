import { useState, useEffect, useCallback, useMemo } from 'react';
import { intersection, includes, without, isEqual } from 'lodash';
import {
	InviteCollaborators,
	ConfirmCollaborators,
	SetPermissionsCollaborators
} from 'components/Collaborators';
import { DefaultCollaboratorPermissions, EMAIL_REGEX } from 'consts';
import { buildInitialPermissions } from 'helpers/collaborators';
import {
	CollaboratorPermissions,
	CollaboratorEmailAddressWithPermissions
} from 'store/data/collaborators';
import { Container, Wrapper } from './CollaboratorsInvitePage.style';
import { Header } from 'components/Header';
import { StickyFooter } from 'components/UI/StickyFooter';
import { Suspend } from 'components/UI/Suspend';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useStatuses,
	useProject,
	useIsProjectValid,
	useProjectOwnerDetails,
	useEligibleForInviteEmails,
	useProjectId,
	useCollaborators,
	useInvalidUsers,
	useUsersByEmails,
	useAddCollaborators,
	useUpdateCollaboratorsPermissions
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useCompletedAction, usePrevious, useKeyPress } from 'hooks/utils';

enum SharingSteps {
	Invite = 'Invite',
	Confirm = 'Confirm',
	SetPermissions = 'SetPermissions'
}

export function CollaboratorsInvitePage() {
	const { translate } = useTranslation();
	const { navigate, routes } = useNavigation();
	const { setNotification, setError } = useAlerts();
	const [step, setStep] = useState(SharingSteps.Invite);
	const [termsOfUse, setTermsOfUse] = useState(false);
	const [newUsers, setNewUsers] = useState<string[]>([]);
	const [confirmedUsers, setConfirmedUsers] = useState<string[]>([]);

	// Needs to be here for: setting all stautses so user can set permissions to each of them
	// NOTE: no need to show loader until variables are fetched here since its a multi-step process and setting permissions is the last one
	const [
		{
			data: { statuses }
		}
	] = useStatuses({ omitSystemGenerated: false });

	const initialPermissions = useMemo(
		() => buildInitialPermissions(DefaultCollaboratorPermissions, statuses),
		[statuses]
	);

	const [permissions, setPermissions] = useState(initialPermissions);

	const [{ loading: loadingProject }] = useProject();
	const isProjectValid = useIsProjectValid();
	const projectOwnerDetails = useProjectOwnerDetails();
	const eligibleForInviteEmails = useEligibleForInviteEmails();
	const [projectId] = useProjectId();
	const [
		{
			data: { collaborators },
			loading: loadingCollaborators
		}
	] = useCollaborators();

	const [invalidUsers, clearInvalidUsers] = useInvalidUsers();

	// USED FOR TESTING ONLY
	if (newUsers.includes('error@bad.com')) {
		throw Error(translate(dict => dict.collaborators.collaboratorsInvitePage.error));
	}

	const [
		{
			data: { invitedUsersByEmail, newUsersData, notAllowedDueToDomainConstraintViolation },
			loading: gettingUsersByEmails,
			error: errorGettingUsersByEmails
		},
		getUsersByEmails
	] = useUsersByEmails(newUsers);
	const [{ loading: addingCollaborators, error: errorAddingCollaborators }, addCollaborators] =
		useAddCollaborators();
	const [
		{ loading: updatingCollaboratorsPermissions, error: errorUpdatingCollaboratorsPermissions },
		updateCollaboratorsPermissions
	] = useUpdateCollaboratorsPermissions();

	// REMOVE INVALID USERS FROM THE LIST
	useEffect(() => {
		if (!invalidUsers.length) return;

		setNewUsers(prevState => prevState.filter(user => !invalidUsers.includes(user)));
	}, [invalidUsers]);

	// CONFIRM NEW USERS EFFECT
	useCompletedAction(gettingUsersByEmails, errorGettingUsersByEmails, () => {
		if (
			invalidUsers.length &&
			invalidUsers.length > notAllowedDueToDomainConstraintViolation.length &&
			!(newUsersData?.length || eligibleForInviteEmails.length)
		) {
			return clearInvalidUsers();
		}

		setStep(SharingSteps.Confirm);
	});

	// SHARE WITH NEW COLLABORATORS EFFECT
	useCompletedAction(
		addingCollaborators,
		errorAddingCollaborators,
		// SUCCESS CALLBACK
		() => {
			setStep(SharingSteps.SetPermissions);
		},
		// ERROR CALLBACK
		() => setStep(SharingSteps.Invite)
	);

	// SET PERMISSIONS EFFECT
	useCompletedAction(
		updatingCollaboratorsPermissions,
		errorUpdatingCollaboratorsPermissions,
		// SUCCESS CALLBACK
		() => {
			goToCollaboratorsList();
			setNotification({
				message:
					(invitedUsersByEmail?.length
						? `${translate(
								({ collaborators }) => collaborators.notifications.addUsers.invite
						  )} `
						: `${translate(
								({ collaborators }) => collaborators.notifications.addUsers.project
						  )} `) + confirmedUsers.map(user => user).join(', ')
			});
		}
	);

	const prevNewUsers = usePrevious(newUsers);
	useEffect(() => {
		if (!prevNewUsers || isEqual(prevNewUsers, newUsers)) return;

		const existingCollaboratorsEmail = collaborators
			? collaborators.map(collaborator => collaborator.emailAddress)
			: [];
		const isProjectOwnerIncluded = includes(newUsers, projectOwnerDetails?.emailAddress);

		const invalidUsers: string[] = [];

		if (isProjectOwnerIncluded && projectOwnerDetails) {
			invalidUsers.push(projectOwnerDetails.emailAddress.trim().toLowerCase());
			setError({
				message: translate(dict => dict.collaborators.collaboratorsInvitePage.message)
			});
		}

		const alreadySharedWith = intersection(newUsers, existingCollaboratorsEmail);

		if (alreadySharedWith.length) {
			invalidUsers.push(...alreadySharedWith);
			setError({
				message: `${translate(
					({ collaborators }) => collaborators.notifications.existingUsers.project
				)} ${alreadySharedWith
					.map(user => user)
					.join(', ')
					.toLowerCase()}`
			});
		}

		const uniqueUserEmails = [...new Set(newUsers)];

		const newUsersWithoutInvalid = without(uniqueUserEmails, ...invalidUsers);
		setNewUsers(newUsersWithoutInvalid.map(user => user.toLowerCase()));
	}, [newUsers]);

	const handleUpdatePermissions = useCallback(
		(permissions: CollaboratorPermissions) => setPermissions(permissions),
		[]
	);

	function handleTermsOfUse() {
		setTermsOfUse(state => !state);
	}

	function handleConfirmUser(email: string) {
		setConfirmedUsers(prevState => {
			if (prevState.includes(email)) {
				const newEmails = [...prevState];
				newEmails.splice(prevState.indexOf(email), 1);
				return newEmails;
			} else {
				return [...prevState, email];
			}
		});
	}

	function fetchNewCollaborators() {
		getUsersByEmails(newUsers);
	}

	function shareWithNewCollaborators() {
		addCollaborators(confirmedUsers);
	}

	function updateNewCollaboratorsPermissions() {
		const newCollaboratorsWithPermissions: CollaboratorEmailAddressWithPermissions[] =
			confirmedUsers.map(emailAddress => ({ emailAddress, permissions }));
		updateCollaboratorsPermissions(newCollaboratorsWithPermissions);
	}

	function handlePrimaryButtonAction() {
		if (step === SharingSteps.Invite) fetchNewCollaborators();
		if (step === SharingSteps.Confirm) shareWithNewCollaborators();
		if (step === SharingSteps.SetPermissions) updateNewCollaboratorsPermissions();
	}

	function handleSecondaryButtonAction() {
		if (step === SharingSteps.Invite || step === SharingSteps.SetPermissions) {
			goToCollaboratorsList();
		}
		if (step === SharingSteps.Confirm) {
			/**
			 * IF INVITED USERS TO THE PLATFORM - DONT ALLOW GOING BACK TO 1ST STEP
			 * => REDIRECT TO COLLABORATORS LIST
			 * SEE PRJCTS-4677 COMMENTS FOR CLARIFICATIONS
			 */
			if (invitedUsersByEmail?.length) goToCollaboratorsList();

			// RESET FIELDS: accept terms of use; confirmed users list
			// FOR BETTER UX WHEN CLICKING BACK
			setTermsOfUse(false);
			setConfirmedUsers([]);
			setStep(SharingSteps.Invite);
		}
	}

	function goToCollaboratorsList() {
		if (projectId) navigate(routes.projects.collaborators.view(projectId));
	}

	const primaryButtonLoading =
		gettingUsersByEmails || addingCollaborators || updatingCollaboratorsPermissions;

	function getAreAllInputsEmails() {
		return newUsers.every(email => email.match(EMAIL_REGEX));
	}

	function getButtonsState() {
		let primaryButtonDisabled = false;
		let primaryButtonTitle = '';
		let secondaryButtonTitle = '';

		const areAllInputsEmails = getAreAllInputsEmails();

		if (step === SharingSteps.Invite) {
			primaryButtonTitle = translate(({ buttons }) => buttons.continue);
			secondaryButtonTitle = translate(({ buttons }) => buttons.cancel);
			primaryButtonDisabled = !(newUsers.length && areAllInputsEmails);
		}
		if (step === SharingSteps.Confirm) {
			primaryButtonTitle = translate(({ buttons }) => buttons.continue);
			secondaryButtonTitle = translate(({ buttons }) =>
				/**
				 * IF INVITED USERS TO THE PLATFORM - DONT ALLOW GOING BACK TO 1ST STEP
				 * => SHOW `Cancel` BUTTON INSTEAD OF `Back`
				 * SEE PRJCTS-4677 COMMENTS FOR CLARIFICATIONS
				 */
				invitedUsersByEmail?.length ? buttons.cancel : buttons.back
			);
			primaryButtonDisabled = !termsOfUse || !confirmedUsers.length;
		}
		if (step === SharingSteps.SetPermissions) {
			primaryButtonTitle = invitedUsersByEmail?.length
				? translate(({ sharingConfirmNewMembers }) => sharingConfirmNewMembers.inviteUser)
				: translate(({ collaborators }) => collaborators.add.addNumberOfUsers, false, {
						numberOfUsers: confirmedUsers.length
				  });
			secondaryButtonTitle = translate(({ buttons }) => buttons.cancel);
		}

		return {
			primaryButtonTitle,
			primaryButtonDisabled: primaryButtonDisabled,
			secondaryButtonTitle
		};
	}

	const { primaryButtonTitle, primaryButtonDisabled, secondaryButtonTitle } = getButtonsState();

	useKeyPress(
		{
			onEnterKeyPress: handlePrimaryButtonAction
		},
		{
			noModalsOpened: true,
			listen: !(primaryButtonLoading || primaryButtonDisabled)
		}
	);

	useKeyPress(
		{
			onEscapeKeyPress: handleSecondaryButtonAction
		},
		{ noModalsOpened: true, listen: !primaryButtonLoading }
	);

	const loading = loadingProject || loadingCollaborators;
	const immediate = !isProjectValid;

	const hidePrimaryButton =
		invitedUsersByEmail?.length === 0 &&
		newUsersData?.length === 0 &&
		step === SharingSteps.Confirm;

	return (
		<Suspend loading={loading} immediate={immediate}>
			<Header.Main />

			<Wrapper>
				<Container>
					{step === SharingSteps.Invite && (
						<InviteCollaborators
							newUsersState={{ newUsers, setNewUsers }}
							areAllInputsEmails={getAreAllInputsEmails()}
							onSubmit={() => !primaryButtonDisabled && handlePrimaryButtonAction()}
						/>
					)}
					{step === SharingSteps.Confirm && (
						<ConfirmCollaborators
							newUsersData={newUsersData ?? []}
							newInvitedUsers={invitedUsersByEmail ?? []}
							confirmedUsers={confirmedUsers}
							eligibleForInviteEmails={eligibleForInviteEmails.filter(email =>
								notAllowedDueToDomainConstraintViolation?.includes(email)
									? false
									: true
							)}
							termsOfUse={termsOfUse}
							handleTermsOfUse={handleTermsOfUse}
							handleConfirmUser={handleConfirmUser}
							notAllowedDueToDomainConstraintViolation={
								notAllowedDueToDomainConstraintViolation
							}
						/>
					)}
					{step === SharingSteps.SetPermissions && (
						<SetPermissionsCollaborators
							statuses={statuses}
							permissions={permissions}
							onChange={handleUpdatePermissions}
							projectId={projectId ?? undefined}
						/>
					)}
				</Container>
			</Wrapper>

			<StickyFooter
				primary={
					!hidePrimaryButton && {
						label: primaryButtonTitle,
						loading: primaryButtonLoading,
						disabled: primaryButtonDisabled,
						onClick: handlePrimaryButtonAction
					}
				}
				neutral={{
					label: secondaryButtonTitle,
					onClick: primaryButtonLoading ? undefined : handleSecondaryButtonAction
				}}
			/>
		</Suspend>
	);
}
