import {
	CreateProject,
	Project,
	ProjectFormValues,
	ProjectMetadata,
	ProjectMetadataDefinition,
	ProjectMetadataParameter,
	ProjectMetadataType,
	UpdateProject
} from 'store/data/projects';
import { TranslateFunction } from 'hooks/store/ui/useTranslation';
import { ProjectStatus, ProjectType } from 'types/data/projects/constants';
import { parseFromApiMetadata, parseToApiMetadata } from 'store/data/projects/parsers';
import { UseFormWatch } from 'react-hook-form';

import Ajv from 'ajv';
const ajv = new Ajv({
	keywords: ['label', 'placeholder', 'categories'],
	allErrors: true
});

export function getInitialMetadataFormValues(
	metadataDefinition: ProjectMetadataDefinition,
	project?: Project
) {
	const initialMetadataValues: ProjectMetadata = {};
	const parsedMetadata = project ? parseFromApiMetadata(project.metadata) : undefined;
	if (!metadataDefinition.metadataDefinition) return initialMetadataValues;

	metadataDefinition.metadataDefinition.forEach(metadataParameter => {
		if (parsedMetadata && parsedMetadata[metadataParameter.name]) {
			initialMetadataValues[metadataParameter.name] = parsedMetadata[metadataParameter.name];
		} else {
			initialMetadataValues[metadataParameter.name] =
				getDefaultMetadataParameterValue(metadataParameter);
		}
	});
	return initialMetadataValues;
}

function getDefaultMetadataParameterValue(parameter: ProjectMetadataParameter) {
	switch (parameter.type) {
		case ProjectMetadataType.Dropdown:
			return '';
		case ProjectMetadataType.String:
			return '';
		default:
			return '';
	}
}

/**
 *
 * @param definition Creates a matrix of rows and columns in order to
 * display correctly the metadata definition elements in the UI form
 * Returns rows of two columns each.
 * @returns
 */
export function transforMetadataParametersToFormRows(parameters: ProjectMetadataDefinition) {
	const formRows: ProjectMetadataParameter[][] = [];

	if (!parameters.metadataDefinition) return formRows;

	for (let i = 0; i < parameters.metadataDefinition.length; i = i + 2) {
		const formRow = [];
		formRow.push(parameters.metadataDefinition[i]);
		if (i + 1 < parameters.metadataDefinition.length)
			formRow.push(parameters.metadataDefinition[i + 1]);
		formRows.push(formRow);
	}
	return formRows;
}

export function parseFormValuesToEditedProject(
	values: ProjectFormValues,
	projectMetadataDefinition: ProjectMetadataDefinition
): UpdateProject {
	const project = parseFormValuesToProject(values, projectMetadataDefinition);
	return {
		...project,
		projectId: values.projectId?.toString() ?? ''
	};
}

export function parseFormValuesToProject(
	values: ProjectFormValues,
	projectMetadataDefinition: ProjectMetadataDefinition
) {
	const project: CreateProject = {
		projectName: values.projectName?.toString() ?? '',
		givenProjectNumber: values.givenProjectNumber?.toString() ?? '',
		status: values.status as ProjectStatus,
		description: values.description?.toString() ?? '',
		slideFolderURL: values.slideFolderURL?.toString() ?? '',
		projectType: values.projectType as ProjectType,
		projectEndDate: values.projectEndDate?.toString() ?? ''
	};

	if (projectMetadataDefinition && projectMetadataDefinition.metadataDefinition) {
		projectMetadataDefinition.metadataDefinition.forEach(definition => {
			if (!project.metadata) project.metadata = {};
			project.metadata[definition.name] = values[definition.name]?.toString() ?? '';
		});
	}

	project.metadata = parseToApiMetadata(project.metadata);
	return project;
}

export function validateProjectForm(
	metadataDefinition: ProjectMetadataDefinition,
	watch: UseFormWatch<ProjectFormValues>,
	translate: TranslateFunction
) {
	const schema = metadataDefinition.metadataSchema;
	const validate_metadata = ajv.compile(schema || {});

	validate_metadata({
		projectType: watch('metadata_projectType'),
		archiveNumber: watch('metadata_archiveNumber')
	});
	const errors: Record<string, any> = {};
	const requiredFields = new Set(schema ? schema.required.map(f => `metadata_${f}`) : []);

	if (schema && schema.if && schema.if.properties) {
		const matchingKeys = Object.keys(schema.if.properties).every(
			key =>
				watch(`metadata_${key}`) &&
				schema.if?.properties[key as keyof typeof schema.if.properties].enum.includes(
					watch(`metadata_${key}`) as string
				)
		);
		if (matchingKeys && schema.then?.required) {
			schema.then.required.forEach(field => requiredFields.add(`metadata_${field}`));
		}
	}

	if (!watch('projectName') || watch('projectName') === '') {
		errors['projectName'] = {
			type: 'required',
			message: translate(dict => dict.createProject.projectTitleError)
		};
	}

	validate_metadata.errors?.map(error => {
		if (
			error.keyword === 'required' &&
			(watch(`metadata_${error.params.missingProperty as string}`) === undefined ||
				watch(`metadata_${error.params.missingProperty as string}`) === '')
		) {
			errors[`metadata_${error.params.missingProperty as string}`] = {
				type: 'required',
				message: translate(dict => dict.projectMetadata.general.mandatoryField)
			};
		}
		if (error.keyword === 'pattern') {
			const patternOK = !new RegExp(error.params.pattern.test).test(
				watch(`metadata_${error.params.missingProperty as string}`) as string
			);
			if (!patternOK) {
				errors[`metadata_${error.instancePath.replace('/', '') as string}`] = {
					type: 'pattern',
					message: translate(dict => dict.projectMetadata.hso.archiveNumber.regex)
				};
			}
		}
	});

	requiredFields.forEach(field => {
		if (!watch(field)) {
			errors[field] = {
				type: 'required',
				message: translate(dict => dict.projectMetadata.general.mandatoryField)
			};
		}
	});

	return { errors, requiredFields };
}
