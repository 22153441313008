import { Role } from 'store/data/roles';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useDeleteRole } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	role: Role;
	onClose: (success?: boolean) => void;
}

export function DeleteRoleModal({ role, onClose }: Props) {
	const { translate } = useTranslation();

	const [{ loading, error }, deleteRole] = useDeleteRole();

	useCompletedAction(loading, error, () => {
		onClose(true);
	});

	function handleDelete() {
		const { id } = role;
		deleteRole(id);
	}

	const title = `${translate(dict => dict.terms.delete)} ${role.name}?`;

	return (
		<>
			<Modal
				size={s => s.s}
				title={title}
				primary={{
					label: translate(dict => dict.buttons.delete),
					warning: true,
					loading,
					disabled: loading,
					onClick: handleDelete
				}}
				neutral={{
					label: translate(dict => dict.buttons.cancel),
					onClick: onClose
				}}
				onClose={onClose}
				enterAsPrimaryOnClick
				visible
				close
			>
				<Typography.Paragraph>
					{translate(dict => dict.rolesPage.deleteRoleModal.message)}
				</Typography.Paragraph>
			</Modal>
		</>
	);
}
