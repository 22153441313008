import { StorageKeys } from 'types/index';
import { State, LoginSteps } from './types';

const loginStep = localStorage.getItem(StorageKeys.LoginStep) as LoginSteps;

const initialState: State = {
	loginStep,
	oneTimePassword: {
		enabled: !!localStorage.getItem(StorageKeys.OneTimePassword),
		secret: null
	},
	loggedIn: !!localStorage.getItem(StorageKeys.LoginCompleted),
	user: null,
	username: localStorage.getItem(StorageKeys.Username),
	errors: {}
};

export default initialState;
